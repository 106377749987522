import classnames from 'classnames';
import * as React from 'react';
import Dropzone from 'react-dropzone';

import { msg, Server } from '../../../utils';
import { ModalCropComponent } from './ModalCropComponent';
import cssStyles from './Upload.module.css';
import { styles } from './UploadStyles';
import { IProps, IState } from './UploadTypes';

export default class UploadComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      status: '',
      loaded: 0,
      total: 0,
      progress: 0,
      filename: '',
      showModal: false,
      imageSrc: null
    };
  }

  onUploadProgress = (event: any) => {
    this.setState({
      status: 'PENDING',
      loaded: event.loaded,
      total: event.total,
      progress: event.loaded ? Math.round((event.loaded / event.total) * 100) : 0
    });
  };

  onDrop = (file: any, rejected: any) => {
    if (rejected.length) {
      return this.setState({ status: 'REJECTED', progress: 0 });
    }
    this.setState({ filename: file[0].name });
    if (this.props.crop) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({ imageSrc: reader.result }));
      if (file) {
        reader.readAsDataURL(file[0]);
      }
      this.toggleModal();
    } else {
      let data = new FormData();
      file.forEach((item: any) => {
        data.append('file', item);
      });
      if (this.props.extraData) {
        for (const el in this.props.extraData) {
          if (this.props.extraData.hasOwnProperty(el)) {
            data.append(el, this.props.extraData[el]);
          }
        }
      }
      return this.uploadToServer(data);
    }
  };

  uploadToServer = (data: any) => {
    return Server.post(this.props.url, data, false, true, this.onUploadProgress)
      .then((res: any) => {
        if (this.props.onSuccess) {
          this.props.onSuccess(res);
        }
        this.setState({ status: 'COMPLETE', progress: 100 }, () =>
          setTimeout(() => this.setState({ status: '', progress: 0 }), 2000)
        );
      })
      .catch(error => {
        if (error.response?.data?.ERROR?.code === 'INVALID_FORMAT') {
          this.setState({ status: 'REJECTED', progress: 0 }, () =>
            setTimeout(() => this.setState({ status: '' }), 2000)
          );
        } else {
          this.setState({ status: 'ERROR', progress: 0 }, () => setTimeout(() => this.setState({ status: '' }), 2000));
        }
      });
  };

  onDragEnter = () => {
    this.setState({ status: 'ENTER', progress: 0 });
  };

  onDragLeave = () => {
    this.setState({ status: '' });
  };

  onDropRejected = () => {
    this.setState({ status: 'REJECTED', progress: 0 });
  };

  getMessageByStatus() {
    // temporary removing the progress bar as it is not well positioned in the new react-dropzone
    let { status } = this.state;
    const { messages } = this.props;
    switch (status) {
      case 'PENDING':
        return (
          <div style={styles.message}>
            <div>
              <i className="material-icons" style={styles.icon}>
                cloud_queue
              </i>
            </div>
            <div>{msg('general.uploading', 'Uploading...')}</div>
          </div>
        );
      case 'COMPLETE':
        return (
          <div style={styles.message}>
            <div>
              <i className="material-icons" style={styles.icon}>
                cloud_done
              </i>
            </div>
            {msg('general.doneUploading', 'Uploading done!')}
          </div>
        );
      case 'ERROR':
        return (
          <div style={styles.message}>
            <div>
              <i className="material-icons" style={styles.icon}>
                cloud_off
              </i>
            </div>
            {msg('general.uploadError', 'Upload error.')}
          </div>
        );
      case 'REJECTED':
        return (
          <div style={styles.message}>
            <div>
              <i className="material-icons" style={styles.icon}>
                cloud_off
              </i>
            </div>
            {msg('general.wrongFileType', 'Wrong file type.')}
          </div>
        );
      default:
        return (
          <div style={styles.message}>
            <div>
              <i className="material-icons" style={styles.icon}>
                cloud_upload
              </i>
            </div>
            {messages && messages.default && <div>{messages && messages.default}</div>}
          </div>
        );
    }
  }

  changeStylesByStatus() {
    const { status } = this.state;
    switch (status) {
      case 'COMPLETE':
        styles.dropzone.backgroundColor = '#7ac29a6b';
        styles.dropzone.border = '1px solid #7ac29a';
        styles.progress.backgroundColor = 'transparent';
        break;
      case 'PENDING':
        styles.dropzone.backgroundColor = 'transparent';
        styles.dropzone.border = '1px solid #f3bb45';
        styles.progress.backgroundColor = '#f3bb458c';
        break;
      case 'ERROR':
        styles.dropzone.backgroundColor = '#eb5e286b';
        styles.dropzone.border = '1px solid #eb5e28';
        styles.progress.backgroundColor = 'transparent';
        break;
      case 'REJECTED':
        styles.dropzone.backgroundColor = '#eb5e286b';
        styles.dropzone.border = '1px solid #eb5e28';
        styles.progress.backgroundColor = 'transparent';
        break;
      case 'ENTER':
        styles.dropzone.backgroundColor = '#e6e5e570';
        styles.dropzone.border = '1px solid #e6e5e5';
        styles.progress.backgroundColor = 'transparent';
        break;
      default:
        styles.dropzone.backgroundColor = 'transparent';
        styles.dropzone.border = '1px dashed #e6e5e5';
        styles.progress.backgroundColor = 'transparent';
    }
  }

  toggleModal = () => {
    const show = this.state.showModal;
    let imageSrc = this.state.imageSrc;
    if (show) {
      imageSrc = null;
    }
    this.setState({
      showModal: !show,
      imageSrc
    });
  };

  render() {
    const { progress, imageSrc } = this.state;
    const { multiple, cropAspect } = this.props;
    this.changeStylesByStatus();
    return (
      <div className={classnames(cssStyles.upload, 'upload')} style={this.props.disabled ? { opacity: 0.5 } : {}}>
        {this.props.crop && this.state.showModal && (
          <ModalCropComponent
            image={imageSrc}
            aspect={cropAspect}
            saveImage={this.uploadToServer}
            toggleModal={this.toggleModal}
          />
        )}
        <div className="dropzone">
          <Dropzone
            disabled={this.props.disabled}
            onDrop={this.onDrop}
            onDragEnter={this.onDragEnter}
            onDragLeave={this.onDragLeave}
            onDropRejected={this.onDropRejected}
            accept={this.props.accept}
            multiple={multiple ? multiple : false}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div style={{ ...styles.progress, width: `${progress}%` }} />
                <div>{this.getMessageByStatus()}</div>
                {this.props.recommendedResolution && (
                  <div style={{ ...styles.message, fontSize: 14 }}>
                    {msg('cropper.recommendedResolution', 'Recommended resolution:')} {this.props.recommendedResolution}
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }
}
