import moment from 'moment';

import { ScheduleDTO } from './';
import { SelectValueDTO } from './selectValueDTO';

export enum EventType {
  EVENT,
  PROMOTION,
  CAMPAIGN,
  NEWS
}

export enum EventForType {
  CURRENT,
  PARTNERS
}

export enum RewardType {
  DISCOUNT,
  PRODUCT,
  VOUCHER
}

export enum TriggerStatus {
  UNTRIGGERED,
  UNCLAIMED,
  CLAIMED,
  USED,
  SPECIAL,
  HIDDEN,
  PARTIAL
}

export enum ParticipantsType {
  ALL,
  SELECTED,
  RANDOM
}

export class EventDTO {
  _id: string = '';
  title: string = '';
  content: string = '';
  startDate: string = moment()
    .add(60 - moment().minutes(), 'minutes')
    .format();
  endDate: string = moment()
    .add(60 - moment().minutes(), 'minutes')
    .add(1, 'days')
    .format();
  places: Array<SelectValueDTO> = [];
  placeNames: Array<string> = [];
  memberIds?: Array<string>;
  rewards: Array<IEventReward> = [];
  type: EventType = EventType.EVENT;
  visibleType: EventType = EventType.EVENT;
  isActive: boolean;
  placeId: string = '';
  dispatch: string = moment().format();
  target: Array<number> = [];
  channels: Array<number> = [];
  currentCoverPhoto: {
    createdAt: string;
    id: string;
    type: string;
    url: string;
  };
  sent: boolean = false;
  for: number = EventForType.CURRENT;
  participantsType: number = ParticipantsType.ALL;
  raffle?: RaffleDTO;
  initialStartDate?: string;
  initialEndDate?: string;
  hideDates?: boolean;
  nativeNotifications: boolean = false;

  constructor(event?: EventDTO) {
    if (event) {
      Object.assign(this, event);
      this.visibleType = event.hideDates && event.type === EventType.EVENT ? EventType.NEWS : event.type;
      this.for = event.places.length ? EventForType.PARTNERS : EventForType.CURRENT;
    }
  }
}

export class RaffleDTO {
  totalPrizes: number;
  raffleStart: string;
  raffleEnd: string;
  schedule: ScheduleDTO[] = [
    new ScheduleDTO(),
    new ScheduleDTO(),
    new ScheduleDTO(),
    new ScheduleDTO(),
    new ScheduleDTO(),
    new ScheduleDTO(),
    new ScheduleDTO()
  ];
}

export class RaffleIntervalDTO {
  index: number;
  date: string;
  day: number;
  prizes: number;
  participants?: number;
  winners?: number;
  reallocated?: number;
  used?: number;
}

export interface IEventReward {
  title?: string;
  type?: RewardType;
  media?: any;
  amount?: number;
  minimumAmount?: number;
  partialRedeem?: boolean;
  total?: number;
}
