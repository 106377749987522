import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AppStore, AuthStore, PaySettlementsStore } from '../../../appRedux';
import { PaginationDTO, PaySettlementDTO } from '../../../appRedux/types';
import { colors } from '../../../assets/styles';
import { msg, parseAmount } from '../../../utils';
import { CardComponent, InfiniteScrollListComponent } from '../../shared';

interface IProps {
  pay_settlements_list: PaginationDTO<PaySettlementDTO>;
  pay_settlements_list_pending: boolean;
  pay_settlements_list_error: string | null;
  show_settlements_without_amount: boolean;
  getPaySettlementsAction(
    payBatchId: string,
    showWithoutAmount: boolean,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  downloadPaySettlementAction(payBatchId: string, paySettlementId: string, placeName: string, created: string): any;
  downloadPaySettlementReportAction(payBatchId: string, paySettlementId: string, placeName: string): any;
  clearPaySettlementsError(): any;
  setShowSettlementsWithoutAmountAction(show: boolean): Promise<void>;
}

const PaySettlementsListContainer = (props: IProps & RouteComponentProps<{ payBatchId: string }>) => {
  const listRef = useRef<any>();

  useEffect(() => {
    return () => {
      props.clearPaySettlementsError();
    };
    // tslint:disable-next-line: align
  }, []);

  const getData = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    const payBatchId = props.match?.params?.payBatchId || '';
    props.getPaySettlementsAction(payBatchId, props.show_settlements_without_amount, limit, skip, sort, criteria);
  };

  const parseList = (list: PaginationDTO<PaySettlementDTO>) => {
    return {
      ...list,
      results: list?.results?.length
        ? list?.results.map((item: PaySettlementDTO) => ({
            ...item,
            created: moment(item.created).format('DD.MM.YYYY'),
            endDate: moment(item.endDate).format('DD.MM.YYYY HH:mm'),
            paymentsNo: item.paymentIds.length,
            amount: parseAmount(item.amount),
            totalAmount: parseAmount(item.totalAmount),
            feeAmount: parseAmount(item.feeAmount),
            emailSent: {
              type: 'custom',
              render: () => {
                return (
                  <div
                    className="badge"
                    style={{
                      backgroundColor: item.emailSent ? colors.green : colors.gray
                    }}
                  >
                    {item.emailSent ? msg('general.yes', 'Yes') : msg('general.no', 'No')}
                  </div>
                );
              }
            }
          }))
        : []
    };
  };

  const downloadSettlement = (paySettlement: { _id: string; place: { name: string }; created: string }) => {
    const payBatchId = props.match?.params?.payBatchId || '';
    props.downloadPaySettlementAction(payBatchId, paySettlement._id, paySettlement.place.name, paySettlement.created);
  };

  const downloadPaySettlementReport = (paySettlement: { _id: string; place: { name: string }; created: string }) => {
    const payBatchId = props.match?.params?.payBatchId || '';
    props.downloadPaySettlementReportAction(payBatchId, paySettlement._id, paySettlement.place.name);
  };

  const handleShowSettlementsCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setShowSettlementsWithoutAmountAction(!!event.target.checked);
    listRef.current?.setSearchByValue();
  };

  return (
    <CardComponent
      title={msg('sidebar.paymentSettlements', 'Payment settlements')}
      error={!!props.pay_settlements_list_error}
      headerIcon="credit_score"
      needsTitle={true}
      checkboxes={[
        {
          label: msg('paymentSettlements.showWithoutAmount', 'Show places without amount'),
          checked: props.show_settlements_without_amount,
          onChange: handleShowSettlementsCheckbox
        }
      ]}
    >
      <InfiniteScrollListComponent
        wrappedComponentRef={(ref: any) => (listRef.current = ref)}
        fields={{
          uniqueNo: msg('paymentSettlements.uniqueNo', 'Unique number'),
          'place.name': msg('paymentSettlements.placeName', 'Place'),
          endDate: msg('paymentSettlements.endDate', 'End Date'),
          paymentsNo: msg('paymentSettlements.paymentsNo', 'Number of Payments'),
          totalAmount: msg('paymentSettlements.totalAmount', 'Total Amount'),
          feeAmount: msg('paymentSettlements.feeAmount', 'Fee Amount'),
          amount: msg('paymentSettlements.amount', 'Settlement Amount'),
          emailSent: msg('paymentSettlements.emailSent', 'Email Sent')
        }}
        list={parseList(props.pay_settlements_list)}
        pending={props.pay_settlements_list_pending}
        error={props.pay_settlements_list_error}
        get={getData}
        limit={30}
        actions={[
          {
            label: msg('paymentSettlements.download', 'Download settlement'),
            btn: 'btn-primary',
            icon: 'file_download',
            returnFields: ['_id', 'place', 'created'],
            onClick: downloadSettlement
          },
          {
            label: msg('paymentSettlements.downloadSettlementReport', 'Download settlement report'),
            btn: 'btn-info',
            icon: 'sim_card_download',
            returnFields: ['_id', 'place'],
            onClick: downloadPaySettlementReport
          }
        ]}
        search={['place.name']}
        sort={{
          fields: ['uniqueNo', 'place.name', 'paymentsNo', 'amount', 'totalAmount'],
          default: 'uniqueNo,-1'
        }}
      />
    </CardComponent>
  );
};

function mapStateToProps(state: AppStore.states) {
  return {
    pay_settlements_list: state.paySettlements.pay_settlements_list,
    pay_settlements_list_pending: state.paySettlements.pay_settlements_list_pending,
    pay_settlements_list_error: state.paySettlements.pay_settlements_list_error,
    show_settlements_without_amount: state.auth.show_settlements_without_amount
  };
}

const dispatchToProps = {
  getPaySettlementsAction: PaySettlementsStore.actions.getPaySettlementsAction,
  downloadPaySettlementAction: PaySettlementsStore.actions.downloadPaySettlementAction,
  downloadPaySettlementReportAction: PaySettlementsStore.actions.downloadPaySettlementReportAction,
  clearPaySettlementsError: PaySettlementsStore.actions.clearPaySettlementsError,
  setShowSettlementsWithoutAmountAction: AuthStore.actions.setShowSettlementsWithoutAmountAction
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(PaySettlementsListContainer));
