import moment from 'moment';
import React from 'react';

import FormLabel from '@material-ui/core/FormLabel';

import { AnnouncementConditionDTO, AnnouncementDTO, PlaceDTO } from '../../../../appRedux/types';
import { IValidator, msg, Validator } from '../../../../utils';
import {
    ButtonComponent, CardComponent, DateTimePickerComponent, TextFieldComponent
} from '../../../shared';
import JoditComponent from '../../../shared/JoditComponent';
import { AnnouncementConditionComponent } from './';

interface IProps {
  match: any;
  announcement: AnnouncementDTO | null;
  announcement_pending: boolean;
  places_list: PlaceDTO[];
  addAnnouncementAction: (announcement: any) => any;
  getAnnouncementAction?: (announcementId: string) => any;
  editAnnouncementAction?: (announcement: any) => any;
  getPlacesListAction: () => any;
}
interface IState {
  announcement: AnnouncementDTO;
  errors: {
    title: string;
    content: string;
    message: string;
    dispatch: string;
  };
}

class AnnouncementContentComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      announcement: new AnnouncementDTO(),
      errors: {
        title: '',
        content: '',
        message: '',
        dispatch: ''
      }
    };
  }

  componentDidMount() {
    if (this.props.getAnnouncementAction && this.props.match.params && this.props.match.params.announcementId) {
      this.props.getAnnouncementAction(this.props.match.params.announcementId);
    }
    this.props.getPlacesListAction();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.announcement_pending && !this.props.announcement_pending && this.props.announcement) {
      this.setState({
        announcement: this.props.announcement
      });
    }
  }

  inputHandler = (event: any) => {
    const fieldKey = event.target.getAttribute('id');
    const fieldValue = event.target.value;
    const announcement = this.state.announcement;
    announcement[fieldKey] = fieldValue;
    this.setState({ announcement });
  };

  handleChangeDescription = (value: string) => {
    const announcement = this.state.announcement;
    announcement.content = value;
    this.setState({ announcement });
  };

  getEventDate = (value: string) => {
    const announcement = this.state.announcement;
    announcement.dispatch = value;
    this.setState({ announcement });
  };

  onConditionChange = (event: any, index: number) => {
    const announcement = this.state.announcement;
    announcement.conditions[index][event.name] = event.value;
    this.setState({ announcement });
  };

  onPlaceSelected = (places: { label: string; value: string }[], index: number) => {
    const announcement = this.state.announcement;
    announcement.conditions[index].selectedValues = places?.map(item => item.value);
    this.setState({ announcement });
  };

  onConditionDelete = (index: number) => {
    const announcement = this.state.announcement;
    announcement.conditions.splice(index, 1);
    this.setState({ announcement });
  };

  addConditionAction = () => {
    const announcement = this.state.announcement;
    if (!announcement.conditions) {
      announcement.conditions = [];
    }
    announcement.conditions.push(new AnnouncementConditionDTO());
    this.setState({ announcement });
  };

  saveHandler = () => {
    const { isValid } = this.props.validator!;
    if (isValid()) {
      const announcement = {
        ...this.state.announcement,
        dispatch: moment(this.state.announcement.dispatch).valueOf()
      };
      if (announcement._id && this.props.editAnnouncementAction) {
        this.props.editAnnouncementAction(announcement);
      } else {
        this.props.addAnnouncementAction(announcement);
      }
    }
  };

  render() {
    const { announcement } = this.state;
    const { errors } = this.props.validator!;
    return (
      <CardComponent
        title={msg('announcement.cardTitle', 'Announcements')}
        headerIcon="notifications"
        needsTitle={true}
        pending={this.props.announcement_pending}
      >
        <div className="row">
          <div className="col-sm-3">
            <TextFieldComponent
              id="title"
              inType="text"
              label={msg('announcement.title', 'Title')}
              minLength={1}
              maxLength={50}
              value={announcement.title}
              onChange={this.inputHandler}
              required={true}
              ref="title"
              formatError={errors.title}
              validator={[
                {
                  type: 'isLength',
                  msg: msg('formValidation.fieldRequired', 'Field required!'),
                  params: { min: 2 }
                }
              ]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-9">
            <TextFieldComponent
              id="message"
              inType="textarea"
              label={msg('announcement.message', 'Message')}
              minLength={1}
              value={announcement.message}
              onChange={this.inputHandler}
              ref="message"
              formatError={errors.message}
              validator={[
                {
                  type: 'isLength',
                  msg: msg('formValidation.fieldRequired', 'Field required!'),
                  params: { min: 5 }
                }
              ]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <FormLabel>{msg('announcement.content', 'Content:')}</FormLabel>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-9">
            <JoditComponent
              content={announcement.content}
              onChange={this.handleChangeDescription}
              config={{
                mediaUrl: 'announcements/media?type=ANNOUCEMENT',
                style: { fontSize: 28, lineHeight: 36, height: 700 }
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <FormLabel style={{ marginTop: 10 }}>
              {msg('announcement.dispatchDate', 'Choose the dispatch date:')}
            </FormLabel>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <DateTimePickerComponent
              date={announcement.dispatch}
              label=""
              getSelectedDate={this.getEventDate}
              ref="dispatchDate"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <FormLabel style={{ marginTop: 25 }}>{msg('announcement.conditions', 'Conditions:')}</FormLabel>
          </div>
        </div>
        {announcement.conditions &&
          announcement.conditions.map((condition: AnnouncementConditionDTO, index: number) => {
            return (
              <AnnouncementConditionComponent
                listItem={condition}
                key={index}
                index={index}
                options={this.props.places_list}
                onChange={this.onConditionChange}
                onDelete={this.onConditionDelete}
                onChangeMultiField={this.onPlaceSelected}
              />
            );
          })}
        <div className="row">
          <div className="col-md-6">
            <div className="form-group form-button">
              <ButtonComponent
                label={msg('announcement.conditionButton', 'Add new condition')}
                action={this.addConditionAction}
                pending={false}
              />
            </div>
          </div>
        </div>
        <div className="text-center mt-8">
          <ButtonComponent
            label={announcement._id ? msg('general.edit', 'Edit') : msg('announcement.send', 'Send')}
            action={this.saveHandler}
            pending={false}
          />
        </div>
      </CardComponent>
    );
  }
}

export default Validator(AnnouncementContentComponent);
