import { TagsStore } from '.';
import { PaginationDTO } from '../types';

function tagsReducer(state: TagsStore.IState = TagsStore.initialState, action: IAction<any>): TagsStore.IState {
  switch (action.type) {
    case TagsStore.ActionTypes.GET_TAGS: {
      return {
        ...state,
        tags_list_pending: true,
        tags_list_error: null
      };
    }
    case TagsStore.ActionTypes.GET_TAGS_SUCCESS: {
      return {
        ...state,
        tags_list: action.payload,
        tags_list_pending: false,
        tags_list_error: null
      };
    }
    case TagsStore.ActionTypes.GET_TAGS_FAILED: {
      return {
        ...state,
        tags_list: new PaginationDTO(),
        tags_list_pending: false,
        tags_list_error: action.payload
      };
    }
    case TagsStore.ActionTypes.ADD_TAG: {
      return {
        ...state,
        add_tag_pending: true,
        add_tag_error: null
      };
    }
    case TagsStore.ActionTypes.ADD_TAG_SUCCESS: {
      return {
        ...state,
        add_tag_pending: false,
        add_tag_error: null
      };
    }
    case TagsStore.ActionTypes.ADD_TAG_FAILED: {
      return {
        ...state,
        add_tag_pending: false,
        add_tag_error: action.payload
      };
    }
    case TagsStore.ActionTypes.EDIT_TAG: {
      return {
        ...state,
        edit_tag_pending: true,
        edit_tag_error: null
      };
    }
    case TagsStore.ActionTypes.EDIT_TAG_SUCCESS: {
      return {
        ...state,
        edit_tag_pending: false,
        edit_tag_error: null
      };
    }
    case TagsStore.ActionTypes.EDIT_TAG_FAILED: {
      return {
        ...state,
        edit_tag_pending: false,
        edit_tag_error: action.payload
      };
    }
    case TagsStore.ActionTypes.DELETE_TAG: {
      return {
        ...state,
        delete_tag_pending: true,
        delete_tag_error: null
      };
    }
    case TagsStore.ActionTypes.DELETE_TAG_SUCCESS: {
      return {
        ...state,
        delete_tag_pending: false,
        delete_tag_error: null
      };
    }
    case TagsStore.ActionTypes.DELETE_TAG_FAILED: {
      return {
        ...state,
        delete_tag_pending: false,
        delete_tag_error: action.payload
      };
    }
    case TagsStore.ActionTypes.CLEAR_TAGS: {
      return {
        ...state,
        tags_list: new PaginationDTO()
      };
    }
    default:
      return state;
  }
}

export default tagsReducer;
