import { Point } from 'chart.js';
import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

import { Hidden } from '@material-ui/core';

import { getFormatDate, msg } from '../../../../../utils';
import { CardComponent } from '../../../../shared';
import { colors } from '../colorsForCharts';

interface IProps {
  type: string;
  agg_statistics: any;
  agg_statistics_error: string | null;
  agg_statistics_pending: any;
}

const TransactionsTab = (props: IProps) => {
  const coinsByPeriod = useRef<string[]>([]);
  const transactionsChart = useRef<ChartJSOrUndefined<'line', (number | Point | null)[], unknown>>(null);

  const parseData = (list: any) => {
    let paymentsPeriod: number[] = [];
    let amountByPeriod: number[] = [];
    let labels: string[] = [];
    list?.forEach((item: any) => {
      paymentsPeriod.push(item?.paymentsNo || 0);
      amountByPeriod.push(item?.paymentsTotalAmount ? Number((item.paymentsTotalAmount / 100).toFixed(2)) : 0);
      coinsByPeriod.current.push(item?.paymentsTotalCoins || 0);
      let date = getFormatDate(item.startDate, 'date');
      if (props.type !== 'DAY') {
        date += ` - ${getFormatDate(item.endDate, 'date')}`;
      }
      labels.push(date);
    });

    return {
      labels,
      datasets: [
        {
          label: msg('charts.totalPayments', 'Total number of payments'),
          backgroundColor: colors.color1Opac02,
          borderColor: colors.color1,
          borderWidth: 1,
          hoverBackgroundColor: colors.color1Opac04,
          hoverBorderColor: colors.color1,
          data: paymentsPeriod,
          yAxisID: 'number',
          fill: true,
          lineTension: 0.5
        },
        {
          label: msg('charts.totalPaymentsAmount', 'Total amount paid'),
          backgroundColor: colors.color6Opac02,
          borderColor: colors.color6,
          borderWidth: 1,
          hoverBackgroundColor: colors.color6Opac04,
          hoverBorderColor: colors.color6,
          data: amountByPeriod,
          yAxisID: 'amount',
          fill: true,
          lineTension: 0.5
        }
      ]
    };
  };

  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as 'index',
      intersect: false
    },
    stacked: false,
    scales: {
      number: {
        type: 'linear' as 'linear',
        display: 'auto' as 'auto',
        position: 'left' as 'left',
        grid: {
          drawOnChartArea: false
        }
      },
      amount: {
        type: 'linear' as 'linear',
        display: 'auto' as 'auto',
        position: 'right' as 'right'
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          afterLabel: (tooltipItem: any) => {
            const paidCoins = coinsByPeriod.current[tooltipItem.dataIndex];
            return tooltipItem.datasetIndex === 1
              ? `${msg('charts.totalPaymentsCoins', 'Total number of coins paid')}: ${paidCoins}`
              : '';
          }
        }
      },
      legend: {
        onClick: (_: any, legendItem: any) => {
          const lineChart: any = transactionsChart.current;
          if (lineChart) {
            const index = legendItem.datasetIndex;
            lineChart.config.data.datasets[index].hidden = !lineChart.config.data.datasets[index].hidden;
            const axisId = lineChart.config.data.datasets[index].yAxisID;
            lineChart.scales[axisId].options.display = !lineChart.scales[axisId].options.display;
            lineChart.scales.number.options.grid.drawOnChartArea =
              lineChart.scales.number?.options?.display && !lineChart.scales.amount?.options?.display;
            lineChart.update();
          }
        }
      }
    }
  };

  return (
    <div style={{ marginTop: 5 }}>
      <Hidden smDown={true}>
        <CardComponent
          title=""
          headerIcon=""
          error={!!props.agg_statistics_error}
          pending={props.agg_statistics_pending}
        >
          <div className="row" style={{ marginBottom: 30 }}>
            <h4 style={{ textAlign: 'center' }}>{msg('charts.paymentsAndAmount', 'Payments and amount paid')}</h4>
            <div className="chart">
              <Line ref={transactionsChart} data={parseData(props.agg_statistics)} height={80} options={options} />
            </div>
          </div>
        </CardComponent>
      </Hidden>
      <Hidden mdUp={true}>
        <CardComponent
          title=""
          headerIcon=""
          error={!!props.agg_statistics_error}
          pending={props.agg_statistics_pending}
        >
          <h4 style={{ textAlign: 'center' }}>{msg('charts.availableDesktop', 'Chart available only on desktop')}</h4>
        </CardComponent>
      </Hidden>
    </div>
  );
};

export default TransactionsTab;
