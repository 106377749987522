import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppStore, PlacesStore } from '../../../appRedux';
import { CardComponent, InfiniteScrollListComponent } from '../../shared';
import { msg, getFormatDate } from '../../../utils';
import { PaginationDTO, ReviewDTO, PlaceDTO } from '../../../appRedux/types';
import { FeedbackStore } from '../../../appRedux/feedback';
import { ReviewModalComponent } from '../reviews/components';

interface IProps {
  place: PlaceDTO | null;
  place_pending: boolean;
  placeId: string;
  feedback_list: PaginationDTO<any>;
  feedback_list_pending: boolean;
  feedback_list_error: string | null;
  getFeedbackAction(
    placeId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  changeFeedbackStatus(placeId: string, allowFeedback: boolean): any;
  redirectToPlaceDashboardAction(placeId: string): any;
  clearFeedbackError(): any;
}

const FeedbackListContainer = (props: IProps) => {
  const [showModal, setShowModal] = useState(false);
  const [currentFeedback, setCurrentFeedback] = useState(new ReviewDTO());
  const allowFeedback = props.place?.allowFeedbacks;

  useEffect(() => {
    if (!props.place_pending && props.place && !props.place?.featureFlags?.reviews) {
      props.redirectToPlaceDashboardAction(props.placeId);
    }
    return () => {
      props.clearFeedbackError();
    };
    // tslint:disable-next-line: align
  }, []);

  const getFeedback = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    props.getFeedbackAction(props.placeId, limit, skip, sort, criteria);
  };

  const parseFeedback = (list: PaginationDTO<ReviewDTO>): any => {
    const results: any[] = [];
    if (list.results) {
      list.results.forEach(item => {
        results.push({
          ...item,
          created: getFormatDate(item.created),
          userName:
            item.userId && item.user
              ? item.user.lastname + ' ' + item.user.firstname
              : msg('reviews.anonymous', 'Anonymous'),
          answer: {
            type: 'custom',
            render: () => {
              const value = item.answers[0]?.options[0]?.label || 'N/A';
              return (
                <div
                  style={{
                    maxWidth: '40vw',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {value}
                </div>
              );
            }
          }
        });
      });
    }
    return { ...list, results };
  };

  const toggleModal = () => {
    const show = showModal;
    setShowModal(!show);
  };

  const getActions = () => {
    return [
      {
        label: msg('reviews.seeAdditionalInfo', 'See additional information'),
        btn: 'btn-info',
        icon: 'info',
        returnFields: [''],
        onClick: (feedback: ReviewDTO) => {
          setCurrentFeedback(feedback);
          toggleModal();
        }
      }
    ];
  };

  const handleToggleChange = (event: any) => {
    const toggleValue = !!event.target.checked;
    props.changeFeedbackStatus(props.placeId, toggleValue);
  };

  return (
    <CardComponent
      title={msg('sidebar.feedback', 'Reviews')}
      error={!!props.feedback_list_error}
      headerIcon="thumbs_up_down"
      needsTitle={true}
      toggle={{
        label: msg('reviews.enableFeedback', 'Enable feedback'),
        onChange: handleToggleChange,
        checked: !!allowFeedback
      }}
    >
      {showModal && (
        <ReviewModalComponent
          title={msg('reviews.feedbackDetails', 'Review details')}
          review={currentFeedback}
          toggleModal={toggleModal}
        />
      )}
      <InfiniteScrollListComponent
        list={parseFeedback(props.feedback_list)}
        fields={{
          created: msg('reviews.data', 'Date and Time'),
          userName: msg('reviews.user', 'User'),
          answer: msg('reviews.answer', 'Answer')
        }}
        pending={props.feedback_list_pending}
        error={props.feedback_list_error}
        get={getFeedback}
        actions={getActions()}
        sort={{
          fields: ['created'],
          default: 'created,-1'
        }}
        search={['user.lastname', 'user.firstname']}
      />
    </CardComponent>
  );
};

function mapStateToProps(state: AppStore.states) {
  return {
    placeId: state.places.selected_place,
    place: state.places.place,
    place_pending: state.places.place_pending,
    feedback_list: state.feedback.feedback_list,
    feedback_list_pending: state.feedback.feedback_list_pending,
    feedback_list_error: state.feedback.feedback_list_error
  };
}

const dispatchToProps = {
  getFeedbackAction: FeedbackStore.actions.getFeedbackListAction,
  changeFeedbackStatus: FeedbackStore.actions.changeFeedbackStatus,
  clearFeedbackError: FeedbackStore.actions.clearPlaceFeedbackError,
  redirectToPlaceDashboardAction: PlacesStore.actions.redirectToPlaceDashboardAction
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(FeedbackListContainer));
