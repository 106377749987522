import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  AppStore,
  PlacesStore,
  StatisticsStore,
  MembersStore,
  CheckinsStore,
  RewardsStore,
  AutopilotStore,
  EventsStore,
  PartnersStore,
  reduxContainer
} from '../../../appRedux';
import { PlaceDashboardComponent } from './components';
import { partnerRoutes } from '../../layouts';

interface IProps
  extends PartnersStore.IState,
    PlacesStore.IState,
    StatisticsStore.IState,
    MembersStore.IState,
    CheckinsStore.IState,
    RewardsStore.IState,
    AutopilotStore.IState,
    EventsStore.IState {}
interface IState {}

class PartnerDashboardContainer extends React.Component<
  IProps &
    PlacesStore.IActions &
    StatisticsStore.IActions &
    MembersStore.IActions &
    CheckinsStore.IActions &
    RewardsStore.IActions &
    AutopilotStore.IActions &
    EventsStore.IActions,
  IState
> {
  render() {
    const placeDashboardProps = {
      place: this.props.place,
      place_statistics: this.props.place_statistics,
      agg_statistics: this.props.agg_statistics,
      agg_statistics_pending: this.props.agg_statistics_pending,
      agg_statistics_error: this.props.agg_statistics_error,
      getStatisticsForOnePlaceAction: this.props.getStatisticsForOnePlaceAction,
      getPlaceAction: this.props.getPlaceAction,
      getAllAggStatistics: this.props.getAllAggStatistics
    };

    const partnerId = this.props.selected_partner;
    const placeId = this.props.selected_place;

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Switch>
              <Route
                path={partnerRoutes.DASHBOARD.default}
                render={({ match }) => (
                  <PlaceDashboardComponent match={match} config={placeDashboardProps} partnerId={partnerId} />
                )}
              />
              <Redirect exact={true} from="/partner/dashboard" to={`/partner/dashboard/${placeId}`} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    place: state.places.place,
    place_pending: state.places.place_pending,
    place_error: state.places.place_error,
    place_statistics: state.statistics.place_statistics,
    place_statistics_pending: state.statistics.place_statistics_pending,
    place_statistics_error: state.statistics.place_statistics_error,
    selected_partner: state.partners.selected_partner,
    selected_place: state.places.selected_place,
    agg_statistics: state.statistics.agg_statistics,
    agg_statistics_pending: state.statistics.agg_statistics_pending,
    agg_statistics_error: state.statistics.agg_statistics_error
  };
}

const dispatchToProps = {
  getStatisticsForOnePlaceAction: StatisticsStore.actions.getStatisticsForOnePlaceAction,
  getPlaceAction: PlacesStore.actions.getPlaceAction,
  getAllAggStatistics: StatisticsStore.actions.getAllAggStatistics
};

export default reduxContainer(PartnerDashboardContainer, mapStateToProps, dispatchToProps);
