import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Toggle from 'react-toggle';
import { msg } from '../../../../../utils';
import { Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ScheduleDTO, IntervalDTO } from '../../../../../appRedux/types';
import { colors } from '../../../../../assets/styles';
interface IProps {
  onChange: (dayIndex: number, intervalIndex?: number, period?: string, type?: string) => (event: any) => void;
  addInterval?: (dayIndex: number) => any;
  deleteInterval?: (dayIndex: number, intervalIndex: number) => any;
  schedule?: ScheduleDTO[];
  classes?: any;
  forEvent?: boolean;
  disabled?: boolean;
}
interface IState {}

class PlaceScheduleComponent extends React.Component<IProps, IState> {
  week: string[];
  hours: string[];
  minutes: string[];
  constructor(props: IProps) {
    super(props);
    this.week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    this.minutes = this.generateTime('minutes');
    this.hours = this.generateTime('hours');
  }

  generateTime = (type: String) => {
    let period = [];
    const max = type === 'hours' ? 24 : 60;
    const pace = type === 'hours' ? 1 : 15;
    for (let i = 0; i < max; i = i + pace) {
      period[i] = i.toLocaleString(undefined, { minimumIntegerDigits: 2 });
    }
    return period;
  };

  startHourDisabled = (dayIndex: number, intervalIndex: number, hour: string) => {
    if (this.props.schedule) {
      const previousInterval = this.props.schedule[dayIndex].intervals[intervalIndex - 1];
      if (!previousInterval) {
        return false;
      }
      if (parseInt(hour, 10) < previousInterval.endHour) {
        return true;
      }
    }
    return false;
  };

  generateSelect = (
    value: IntervalDTO,
    intervalIndex: number,
    opened: boolean,
    classes: any,
    dayIndex: number,
    type: string
  ) => {
    return (
      <div style={{ padding: 0, display: 'flex-inline', alignItems: 'center', justifyContent: 'center' }}>
        <Select
          disabled={!opened || this.props.disabled}
          value={value && value[`${type}Hour`]}
          onChange={this.props.onChange(dayIndex, intervalIndex, 'Hour', type)}
          MenuProps={{ classes: { paper: classes.dropdownStyle } }}
        >
          {this.hours.map((hour, i) => {
            return (
              <MenuItem
                key={i}
                value={i}
                disabled={type === 'start' ? this.startHourDisabled(dayIndex, intervalIndex, hour) : false}
              >
                {hour}
              </MenuItem>
            );
          })}
        </Select>
        <span
          style={{
            fontWeight: 'bold',
            fontSize: 20,
            marginLeft: 2,
            marginRight: 2
          }}
        >
          :
        </span>
        <Select
          disabled={!opened || this.props.forEvent || this.props.disabled}
          value={value && value[`${type}Minutes`]}
          onChange={this.props.onChange(dayIndex, intervalIndex, 'Minutes', type)}
          MenuProps={{ classes: { paper: classes.dropdownStyle } }}
        >
          {this.minutes.map((minutes, i) => {
            return (
              <MenuItem key={i} value={i}>
                {minutes}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  };

  render() {
    const { schedule, classes, forEvent } = this.props;
    return (
      <div>
        {schedule?.map((day, dayIndex) => {
          return (
            <div key={dayIndex} className="row" style={{ marginBottom: 15 }}>
              <div className="col-sm-1 col-md-1" />
              <div style={{ padding: 0, marginTop: 8 }} className="col-sm-1 col-md-1">
                <label>{msg(`place.${this.week[dayIndex]}`, `${day}`)}</label>
              </div>
              <div className="col-sm-5 col-lg-3" style={{ minWidth: 300 }}>
                {day.intervals.map((interval: IntervalDTO, intervalIndex: number) => (
                  <div key={intervalIndex} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10, padding: 0 }}>
                      {interval && this.generateSelect(interval, intervalIndex, day.opened, classes, dayIndex, 'start')}
                      <div style={{ justifyContent: 'center', display: 'flex' }}>
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: 20,
                            marginLeft: 5,
                            marginRight: 5,
                            alignSelf: 'center'
                          }}
                        >
                          -
                        </span>
                      </div>
                      {interval && this.generateSelect(interval, intervalIndex, day.opened, classes, dayIndex, 'end')}
                    </div>
                    {!forEvent && this.props.deleteInterval && this.props.addInterval && (
                      <div style={{ marginLeft: 20 }}>
                        {intervalIndex > 0 ? (
                          <Tooltip disableHoverListener={false} title={msg('place.deleteInterval', 'Delete interval')}>
                            <div
                              onClick={day.opened ? this.props.deleteInterval(dayIndex, intervalIndex) : null}
                              style={{
                                padding: 6,
                                margin: 0,
                                color: colors.red,
                                cursor: day.opened ? 'pointer' : 'not-allowed'
                              }}
                            >
                              <i className="material-icons">delete</i>
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip disableHoverListener={false} title={msg('place.addInterval', 'Add a new interval')}>
                            <div
                              onClick={day.opened ? this.props.addInterval(dayIndex) : null}
                              style={{
                                padding: 6,
                                margin: 0,
                                color: colors.green,
                                cursor: day.opened ? 'pointer' : 'not-allowed'
                              }}
                            >
                              <i className="material-icons">add</i>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div
                style={{ justifyContent: 'center', display: 'flex', padding: 0, marginTop: 5 }}
                className="col-sm-2 col-md-2 col-lg-1 "
              >
                {schedule && schedule[dayIndex]?.opened ? (
                  <span
                    style={{ alignSelf: 'center', backgroundColor: 'transparent', color: colors.green }}
                    className="label label-success badge-pill pull-left"
                  >
                    {msg('place.open', 'Open')}
                  </span>
                ) : (
                  <span
                    style={{ alignSelf: 'center', backgroundColor: 'transparent', color: colors.red }}
                    className="label label-danger badge-pill pull-left"
                  >
                    {msg('place.closed', 'Closed')}
                  </span>
                )}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Toggle
                    onChange={this.props.onChange(dayIndex)}
                    checked={schedule && schedule[dayIndex]?.opened}
                    disabled={this.props.disabled}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const styles = {
  dropdownStyle: {
    maxHeight: 300
  }
};

export default withStyles(styles)(PlaceScheduleComponent);
