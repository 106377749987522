import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AppStore, AuthStore, reduxContainer, UsersStore } from '../../../appRedux';
import { adminRoutes, partnerRoutes } from '../../layouts';
import { ProfileEditComponent } from './components';

interface IProps extends UsersStore.IState, AuthStore.IState {}
interface IState {}

class ProfileContainer extends React.Component<IProps & UsersStore.IActions & AuthStore.IActions & IState> {
  render() {
    const userEditProps = {
      user: this.props.user,
      password_pending: this.props.user_change_password_pending,
      user_edit_pending: this.props.user_save_pending,
      edit: this.props.editProfileAction,
      changePass: this.props.changePasswordAction
    };

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Switch>
              <Route
                path={adminRoutes.PROFILE.path}
                render={({ match }) => <ProfileEditComponent config={userEditProps} forSAdmin={true} />}
              />
              <Route
                path={partnerRoutes.PROFILE.path}
                render={({ match }) => <ProfileEditComponent config={userEditProps} forSAdmin={false} />}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    user: state.auth.user,
    user_change_password_pending: state.users.user_change_password_pending,
    user_save_pending: state.users.user_save_pending
  };
}

const dispatchToProps = {
  editProfileAction: AuthStore.actions.editProfileAction,
  changePasswordAction: UsersStore.actions.changePasswordAction
};

export default reduxContainer(ProfileContainer, mapStateToProps, dispatchToProps);
