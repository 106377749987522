import React from 'react';

import { msg, tlist } from '../../../../../utils';
import { SimpleSelectComponent } from '../../../../shared';
import { triggerTypeOptions } from '../../../../../appRedux/autopilot/AutopilotSelections';
import { TimeElapsedCondition, DateCondition, LimitReachedCondition } from './auxilaries';
import { colors } from '../../../../../assets/styles';

function renderCondition(listItem: any, onCellChange: any, index: any, onClick: any, errors: any) {
  switch (listItem.type) {
    case 0:
      return (
        <TimeElapsedCondition
          listItem={listItem}
          onValueChange={onCellChange}
          index={index}
          onClick={onClick}
          errors={errors}
        />
      );
    case 1:
      return <DateCondition listItem={listItem} onValueChange={onCellChange} index={index} onClick={onClick} />;
    case 2:
    case 3:
    case 5:
    case 6:
      return (
        <LimitReachedCondition
          listItem={listItem}
          onValueChange={onCellChange}
          index={index}
          onClick={onClick}
          errors={errors}
        />
      );
    // removing Proximity Condition for the moment as it doesn't do much
    // case 7:
    //   return <ProximityCondition listItem={listItem} onValueChange={onCellChange} index={index} onClick={onClick} />;
    default:
      return undefined;
  }
}

export function AutopilotConditionForm({ listItem, onCellChange, index, onClick, errors }: any) {
  return (
    <div
      className="row"
      style={{
        backgroundColor: index % 2 === 0 ? colors.white : colors.lightGray,
        paddingTop: 6,
        paddingRight: 20,
        paddingLeft: 20,
        marginRight: -35,
        marginLeft: -35
      }}
    >
      <div className="col-sm-3" style={{ marginRight: 10 }}>
        <SimpleSelectComponent
          label={msg('autopilot.triggerType', 'Trigger type')}
          name="triggerType"
          options={index === 0 ? tlist(triggerTypeOptions) : tlist(triggerTypeOptions.slice(0, -1))}
          id={`triggerType${index}`}
          value={listItem.type}
          onChange={(e: any) => onCellChange(e.target.value, index, 'type')}
          required={true}
          needsAllLabel={false}
          arrayOptions={false}
          formatError={errors[listItem._id]}
        />
      </div>
      {renderCondition(listItem, onCellChange, index, onClick, errors)}
      <button
        type="button"
        data-rel="tooltip"
        className={`btn btn-danger`}
        onClick={onClick}
        style={{ width: 27, padding: 5, height: 27, marginTop: 18, marginLeft: -13 }}
        title={msg('general.delete', 'Delete')}
        id={listItem._id}
      >
        <i className="material-icons">delete</i>
      </button>
    </div>
  );
}
