import React from 'react';
import { UserDTO, PaginationDTO, ProfileDTO } from '../../../../appRedux/types';
import PlaceAdminsListComponent from './PlaceAdminListComponent';
import TabletProfilesListComponent from './TabletProfilesListComponent';

interface IProps {
  match: any;
  admins: {
    place_admins_list: Array<UserDTO>;
    place_admins_list_error: string | null;
    place_admins_list_pending: boolean;
    getAdminsForPlaceAction: (placeId: string) => any;
    redirectToAddPlaceAdminAction: (placeId: string, forSAdmin: boolean) => any;
    addPlaceAdminByEmail: (email: string, placeId: string) => any;
    removePlaceAdminAction: (placeId: string, userId: string) => any;
  };
  profiles: {
    profile_list: PaginationDTO<ProfileDTO>;
    profile_list_pending: boolean;
    profile_list_error: string | null;
    getTabletProfilesAction: (
      placeId: string,
      limit?: Number,
      skip?: Number,
      sort?: string,
      criteria?: { [key: string]: string }
    ) => any;
    addTabletProfileAction: (name: string, placeId: string) => any;
    editTabletProfileAction: (placeId: string, profileId: string, name: string) => any;
    deleteTabletProfileAction: (placeId: string, profileId: string) => any;
  };
  forSAdmin: boolean;
}
interface IState {}

export default class PlaceUsersComponent extends React.Component<IProps, IState> {
  renderPlaceAdmins = () => {
    return (
      <PlaceAdminsListComponent match={this.props.match} config={this.props.admins} forSAdmin={this.props.forSAdmin} />
    );
  };

  renderTabletProfiles = () => {
    return <TabletProfilesListComponent match={this.props.match} config={this.props.profiles} />;
  };

  render() {
    return (
      <div>
        {this.renderPlaceAdmins()}
        {this.renderTabletProfiles()}
      </div>
    );
  }
}
