import { uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';

import { PartnerEmailDTO } from '../../../../appRedux/types';
import { AlertUtil, msg } from '../../../../utils';
import {
    ButtonComponent, MultipleSelectComponent, TextFieldComponent, UploadComponent
} from '../../../shared';
import JoditComponent from '../../../shared/JoditComponent';

interface IProps {
  editMode: boolean;
  isSAdmin: boolean;
  names_of_partners_list: { label: string; value: string }[];
  partner_email: any;
  selected_partner: string;
  partner_email_pending: boolean;
  saveHandler(partnerEmail: PartnerEmailDTO): any;
  getPartnerEmailAction(isSAdmin: boolean, partnerId: string, partnerEmailId: string): any;
  deleteAttachmentAction(isSAdmin: boolean, partnerId: string, attachment: any): any;
}

const PartnerEmailForm = (props: IProps) => {
  const [partnerEmail, setPartnerEmail] = useState(props.partner_email || new PartnerEmailDTO());
  const [sendToAll, setSendToAll] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const stateRef: any = useRef();
  stateRef.current = partnerEmail;

  useEffect(() => {
    if (props.partner_email) {
      const sentTo = props.partner_email.sentTo
        .map((user: any) => user.partners)
        .flat()
        .map((item: any) => {
          return { label: item.partnerName, value: item.partnerId };
        });
      const newPartner = { ...props.partner_email, sentTo: uniqBy(sentTo, 'value') };
      setPartnerEmail(newPartner);
    }
    // tslint:disable-next-line:align
  }, [props.partner_email]);

  const inputHandler = (event: any) => {
    const fieldKey = event.target.getAttribute('id');
    const fieldValue = event.target.value;
    setPartnerEmail({ ...partnerEmail, [fieldKey]: fieldValue });
    // tslint:disable-next-line: no-unused-expression
    fieldKey === 'subject' && setErrors({ subject: null });
  };

  const changeDescriptionHandler = (content: string) => {
    setPartnerEmail({ ...stateRef.current, content });
  };

  const selectPartnerHandler = (values: { label: string; value: string }[]) => {
    setPartnerEmail({ ...partnerEmail, sentTo: values || [] });
    if (values && !sendToAll && values.length === props.names_of_partners_list.length) {
      setSendToAll(true);
    } else if (sendToAll) {
      setSendToAll(false);
    }
  };

  const sendToAllHandler = (event: any) => {
    setSendToAll(event.target.checked);
    const newPartner = {
      ...partnerEmail,
      sentTo: [...props.names_of_partners_list]
    };
    setPartnerEmail(newPartner);
  };

  const uploadHandler = (response: any) => {
    const newPartner = { ...partnerEmail, attachments: [...partnerEmail.attachments, response.data] };
    setPartnerEmail(newPartner);
  };

  const deleteAttachmentHandler = (index: number) => () => {
    const attachments = [...partnerEmail.attachments];
    props.deleteAttachmentAction(props.isSAdmin, props.selected_partner, attachments[index]);
    attachments.splice(index, 1);
    setPartnerEmail({ ...partnerEmail, attachments });
  };

  const saveHandler = () => {
    if (!partnerEmail.subject) {
      setErrors({ subject: msg('formValidation.fieldRequired', 'Field required!') });
    } else if (!partnerEmail.sentTo?.length) {
      AlertUtil.simple('You must choose a partner before you can send this email!', 'error');
    } else if (!partnerEmail.content?.length) {
      AlertUtil.simple('You must write the content before you can send this email!', 'error');
    } else {
      props.saveHandler(partnerEmail);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-6">
          <FormLabel>{msg('partnerEmail.subject', 'Subject')}</FormLabel>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-9">
          <TextFieldComponent
            id="subject"
            inType="text"
            minLength={1}
            value={partnerEmail.subject || ''}
            onChange={inputHandler}
            disabled={props.editMode}
            formatError={errors?.subject}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <FormLabel style={{ marginTop: 25 }}>{msg('partnerEmail.choosePartners', 'Choose partners')}</FormLabel>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-9">
          <MultipleSelectComponent
            label="Partners"
            options={props.names_of_partners_list}
            id="partnerId"
            value={partnerEmail.sentTo}
            onChange={selectPartnerHandler}
            disabled={props.editMode}
            maxToShow={10}
          />
        </div>
        <div className="col-sm-3">
          <div className="row">
            <Checkbox checked={sendToAll} onChange={sendToAllHandler} color="primary" disabled={props.editMode} />
            <FormLabel style={{ marginTop: 25 }}>{msg('partnerEmail.sendToAll', 'Send to all partners')}</FormLabel>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <FormLabel style={{ marginTop: 25, marginBottom: 25 }}>{msg('partnerEmail.content', 'Content:')}</FormLabel>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-9">
          <JoditComponent
            content={partnerEmail.content}
            onChange={changeDescriptionHandler}
            config={{
              mediaUrl: props.isSAdmin
                ? `admin/partner-emails/content-media?type=CONTENT_EMAIL`
                : `partners/${props.selected_partner}/emails/content-media?type=CONTENT_EMAIL`,
              style: { fontSize: 28, lineHeight: 36, height: 700 },
              disabled: props.editMode
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <FormLabel style={{ marginTop: 25, marginBottom: 25 }}>
            {msg('partnerEmail.attachments', 'Attachments:')}
          </FormLabel>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-9">
          {partnerEmail.attachments?.map((attachement: any, index: number) => (
            <div
              key={index}
              style={{
                padding: 10,
                marginBottom: 5,
                border: '1px solid lightgray',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div>{attachement.originalName}</div>
              {!props.editMode && (
                <div className="selectShowMore" style={{ marginRight: 15 }} onClick={deleteAttachmentHandler(index)}>
                  X
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {!props.editMode && (
        <div className="row">
          <div className="col-sm-9">
            <UploadComponent
              url={
                props.isSAdmin
                  ? `admin/partner-emails/attachments?type=ATTACHMENT`
                  : `partners/${props.selected_partner}/emails/attachments?type=ATTACHMENT`
              }
              accept={{
                'application/pdf': ['.doc', '.docx', '.xml'],
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document': []
              }}
              extraData={{
                partnerId: props.selected_partner
              }}
              messages={{
                default: msg('partnerEmail.uploadAttachment', 'Upload an attachment by drag-and-drop or click!')
              }}
              onSuccess={uploadHandler}
            />
          </div>
        </div>
      )}
      {!props.editMode && (
        <div className="row">
          <div className="text-center mt-8">
            <ButtonComponent
              label={msg('partnerEmail.sendButton', 'Send email')}
              icon="email"
              action={saveHandler}
              pending={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerEmailForm;
