import * as React from 'react';

import { msg } from '../../../../utils';
import { IndustryDTO } from '../../../../appRedux/types';
import { CardComponent } from '../../../shared';
import { IndustryFormComponent } from './forms';

interface IProps {
  config: {
    pending: boolean;
    error: string | null;
    loaded: IndustryDTO | null;
    load_pending: boolean;
    load_error: string | null;
    add: (industry: any) => any;
    get: (industryId: string) => any;
    edit: (industry: any) => any;
  };
  match?: any;
  editMode: boolean;
}
interface IState {
  industry: IndustryDTO;
}

export default class IndustryEditComponent extends React.Component<IProps, IState> {
  componentDidMount() {
    if (this.props.editMode && this.props.match?.params.industryId) {
      this.props.config.get(this.props.match.params.industryId);
    }
  }

  saveIndustryHandler = (industry: any) => {
    const { config } = this.props;
    if (!this.props.editMode) {
      config.add(industry);
    } else if (this.props.editMode) {
      config.edit(industry);
    }
  };

  industryChangeHandler = (industry: any) => {
    this.setState({ industry });
  };

  renderIndustryForm = () => {
    const propsConfig = this.props.config;
    const industryData = this.props.editMode ? propsConfig.loaded : new IndustryDTO();
    return (
      <CardComponent
        title={
          this.props.editMode
            ? msg('cardTitle.editIndustry', 'Edit new industry')
            : msg('cardTitle.addIndustry', 'Add new industry')
        }
        pending={propsConfig.load_pending}
        error={!!propsConfig.load_error}
        headerIcon={this.props.editMode ? 'edit' : 'add_circle_outline'}
        needsTitle={true}
      >
        <form className="form-horizontal">
          <div className="col-sm-12">
            <IndustryFormComponent
              industry={industryData}
              save={this.saveIndustryHandler}
              pending={propsConfig.pending}
              error={propsConfig.error}
              output={false}
              onChange={this.industryChangeHandler}
              editable={this.props.editMode}
              getIndustry={this.props.config.get}
            />
          </div>
        </form>
      </CardComponent>
    );
  };

  render() {
    return <div>{this.renderIndustryForm()}</div>;
  }
}
