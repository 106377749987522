import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AppStore, TranslationsStore } from '../../../appRedux';
import { PartnerEmailsStore } from '../../../appRedux/partner-emails';
import backgroundImage from '../../../assets/images/background.jpg';
import { msg } from '../../../utils';
import { FooterAuthComponent, NavAuthComponent } from './components';
import styles from './components/styles/Auth.module.css';

interface IProps {
  initial_language: any;
  read_partner_email_error: any;
  readEmailAction(emailId: string, userId: string): any;
  changeLanguageAction(): any;
}

const ReadEmailContainer = (props: IProps & RouteComponentProps<{ emailId: string; userId: string }>) => {
  const navAuthConfig = {
    title: 'ReGage Partner',
    initial_language: props.initial_language,
    changeLanguage: props.changeLanguageAction
  };

  useEffect(() => {
    const { emailId, userId } = props.match.params;
    if (emailId && userId) {
      props.readEmailAction(emailId, userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="partner-auth off-canvas-sidebar">
      <NavAuthComponent config={navAuthConfig} />
      <div className="wrapper wrapper-full-page">
        <div className="full-page login-page" filter-color="black">
          <div className="content" style={{ paddingTop: '30vh' }}>
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                  <div className="card card-login">
                    <div className="card-header text-center" data-background-color="green">
                      <h4 className="card-title">{msg('partnerEmail.readEmail', 'Read email')}</h4>
                    </div>
                    <div className={`card-content ${styles.content}`}>
                      <div className="form-group" style={{ marginLeft: 20, marginTop: -5, textAlign: 'center' }}>
                        {props.read_partner_email_error
                          ? msg('errors.errorOccured', 'An error has occured, please try again!')
                          : msg('partnerEmail.readEmailSuccess', 'We have successfully registered your action.')}
                      </div>
                      <div
                        className="form-group"
                        style={{ marginLeft: 20, marginTop: -5, textAlign: 'center', marginBottom: -10 }}
                      >
                        {!props.read_partner_email_error && msg('general.thankYou', 'Thank you!')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAuthComponent />
          <div className="full-page-background" style={{ backgroundImage: `url(${backgroundImage})` }} />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: AppStore.states) {
  return {
    initial_language: state.translations.initial_language,
    read_partner_email_error: state.partnerEmails.read_partner_email_error
  };
}

const dispatchToProps = {
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction,
  readEmailAction: PartnerEmailsStore.actions.readEmailAction
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(ReadEmailContainer));
