import React from 'react';

import { ListComponent, CardComponent, ButtonComponent, TextFieldComponent } from '../../../shared';
import { UserDTO } from '../../../../appRedux/types';
import { AlertUtil, msg, EMAIL_VALIDATION_REGEX } from '../../../../utils';

interface IProps {
  match: any;
  config: {
    place_admins_list: Array<UserDTO>;
    place_admins_list_error: string | null;
    place_admins_list_pending: boolean;
    getAdminsForPlaceAction: (placeId: string) => any;
    redirectToAddPlaceAdminAction: (placeId: string, forSAdmin: boolean) => any;
    removePlaceAdminAction: (placeId: string, userId: string) => any;
    addPlaceAdminByEmail: (email: string, placeId: string) => any;
  };
  forSAdmin: boolean;
}
interface IState {
  addPlaceAdminEmail: string;
  isValidEmail: boolean;
}

export default class PlaceAdminsListComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { addPlaceAdminEmail: '', isValidEmail: false };
  }

  componentDidMount() {
    this.props.config.getAdminsForPlaceAction(this.props.match.params.placeId);
  }

  addAction = () => {
    const placeId = this.props.match?.params?.placeId;
    this.props.config.redirectToAddPlaceAdminAction(placeId, this.props.forSAdmin);
  };

  addPlaceAdmin = () => {
    const email = this.state.addPlaceAdminEmail;
    const placeId = this.props.match?.params?.placeId;
    this.props.config.addPlaceAdminByEmail(email, placeId);
  };

  canAddPlaceAdmin() {
    return !!this.state.addPlaceAdminEmail && this.state.isValidEmail;
  }

  async removeAdminAction(event: any) {
    const id = event.currentTarget.getAttribute('id');
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.adminRightsRevokeConfimation', 'You are about to revoke the administrator rights of an user!')
    );
    if (hasConfirmation) {
      const placeId = this.props.match?.params?.placeId;
      this.props.config.removePlaceAdminAction(placeId, id);
    }
  }

  toPlaceAdminsListItem(item: UserDTO) {
    const role = item.partnerAdmin
      ? msg('place.partnerAdmin', 'Partner Admin')
      : item.placeAdmin
      ? msg('place.placeAdmin', 'Place Admin')
      : '';

    return { ...item, role };
  }

  getPlaceAdminsListData() {
    const { config } = this.props;

    return {
      fields: {
        lastname: msg('accountInfo.lastname', 'Last name'),
        firstname: msg('accountInfo.firstname', 'First name'),
        email: msg('accountInfo.email', 'Email'),
        role: msg('place.userType', 'Administrator type')
      },
      actions: [
        {
          label: msg('cardTitle.revokeRights', 'Revoke rights'),
          btn: 'btn-info',
          icon: 'remove_circle_outline',
          ref: 'removeAdmin',
          onClick: this.removeAdminAction.bind(this)
        }
      ],
      list: config.place_admins_list.map(this.toPlaceAdminsListItem),
      pending: config.place_admins_list_pending,
      error: config.place_admins_list_error
    };
  }

  render() {
    const usersListData = this.getPlaceAdminsListData();
    const ids = usersListData.list.filter(item => item.partnerAdmin).map(item => item._id);
    return (
      <CardComponent
        title={msg('cardTitle.placeAdmins', 'Place administrators')}
        error={!!this.props.config.place_admins_list_error}
        headerIcon="person_pin_circle"
        needsTitle={true}
      >
        <ListComponent data={usersListData} disabledIds={ids} />

        <div className="row" style={{ marginBottom: 6, marginTop: 6 }}>
          <div className="col-sm-6">
            <h4>{msg('searchOptions.searchExistentUser', 'Search existent user')}:</h4>
          </div>
        </div>

        <div className="row" style={{ marginTop: 6 }}>
          <div className="col-sm-4">
            <TextFieldComponent
              value={this.state.addPlaceAdminEmail}
              onChange={evt => {
                this.setState({
                  addPlaceAdminEmail: evt.target.value,
                  isValidEmail: EMAIL_VALIDATION_REGEX.test(evt.target.value)
                });
              }}
            />
          </div>
          <div className="col-sm-6">
            <div className="form-group form-button" style={{ top: -20 }}>
              <ButtonComponent
                label={msg('cardTitle.addPlaceAdmin', 'Add place admin')}
                action={this.addPlaceAdmin}
                disabled={!this.canAddPlaceAdmin()}
                icon="person"
              />
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 10, marginTop: 0 }}>
          <div className="col-md-3" />
          <div className="col-md-6" style={{ textAlign: 'center' }}>
            <div className="form-group form-button">
              <ButtonComponent
                label={msg('cardTitle.addNewPlaceAdmin', 'Add new place admin')}
                action={this.addAction}
                icon="person_add"
              />
            </div>
          </div>
          <div className="col-md-3" />
        </div>
      </CardComponent>
    );
  }
}
