import { AxiosResponse } from 'axios';
import { composedCriteriaBuilder, Server } from '../../utils';
import { PaginationDTO, EventDTO } from '../types';

type IFetchFavouriteMomentsResponse = PaginationDTO<EventDTO>;

// tslint:disable-next-line: max-line-length
export const fetchFavouriteMoments = async (userId: string, options?: any): Promise<IFetchFavouriteMomentsResponse> => {
  let url = `admin/user/${userId}/events`;
  if (options?.limit) {
    url += `?limit=${options?.limit}`;
  }
  if (options?.skip) {
    url += `&skip=${options.skip}`;
  }
  if (options?.sort) {
    url += `&sort=${options.sort}`;
  }
  if (options?.criteria) {
    url += composedCriteriaBuilder(options.criteria);
  }
  const response = (await Server.get(url)) as AxiosResponse<IFetchFavouriteMomentsResponse>;
  return response.data;
};
