import React, { ChangeEvent, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { Checkbox, FormLabel } from '@material-ui/core';

import { colors } from '../../../../assets/styles';
import { msg } from '../../../../utils';
import { ButtonComponent, MultipleSelectComponent } from '../../../shared';

interface IProps {
  places: Array<{ label: string; value: string }>;
  pending: boolean;
  error: string | { code: string; message: string } | null;
  toggleModal: () => void;
  createBatch: (placeIds: string[]) => void;
}

const CreateBatchModal = (props: IProps) => {
  const [allSelected, setAllSelected] = useState(true);
  const [currentPlaces, setCurrentPlaces] = useState<{ label: string; value: string }[]>(props.places);
  const [createClicked, setCreateClicked] = useState(false);

  const changeMultipleSelect = (values: { label: string; value: string }[]) => {
    if (values?.length === props.places.length) {
      setAllSelected(true);
    } else if (allSelected) {
      setAllSelected(false);
    }
    setCurrentPlaces(values || []);
  };

  const changeAllSelected = (event: ChangeEvent<HTMLInputElement>) => {
    if (currentPlaces.length === props.places.length) {
      setCurrentPlaces([]);
    } else {
      setCurrentPlaces(props.places);
    }
    setAllSelected(event.target.checked);
  };

  const createBatch = () => {
    setCreateClicked(true);
    const places = currentPlaces.map(place => place.value);
    props.createBatch(places);
  };

  return (
    <Modal show={true} onHide={props.toggleModal} dialogClassName="responsive-modal">
      <Modal.Header>
        <Modal.Title>{msg('paymentSettlements.generate', 'Generate settlement')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormLabel>{msg('paymentSettlements.choosePlaces', 'Choose places for settlement:')}</FormLabel>
        <MultipleSelectComponent
          id="settlementPlaces"
          label={msg('paymentSettlements.places', 'Places')}
          options={props.places || []}
          value={currentPlaces}
          onChange={changeMultipleSelect}
          maxToShow={10}
        />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
          <Checkbox checked={allSelected} onChange={changeAllSelected} color="primary" />
          <FormLabel style={{ marginBottom: 0 }}>{msg('paymentSettlements.allPlaces', 'Select all places')}</FormLabel>
        </div>
        <div style={{ margin: 5, height: 25 }}>
          {createClicked && !props.pending && props.error && (
            <span style={{ color: colors.red }}>
              {typeof props.error === 'object' && props.error.code === 'PLACE_NOT_CONFIGURED'
                ? props.error.message
                : msg(
                    'paymentSettlements.generateErrorMessage',
                    'Due to an error, the settlement could not be generated!'
                  )}
            </span>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-danger" onClick={props.toggleModal}>
          {msg('general.close', 'Close')}
        </Button>
        <ButtonComponent
          styles={{ marginTop: 0 }}
          label={msg('paymentSettlements.generateAction', 'Generate')}
          icon="post_add"
          pending={props.pending}
          disabled={!currentPlaces?.length}
          action={createBatch}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default CreateBatchModal;
