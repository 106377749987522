import { AgendaJobsStore } from '.';
import { PaginationDTO } from '../types';

function agendaJobsReducer(
  state: AgendaJobsStore.IState = AgendaJobsStore.initialState,
  action: IAction<any>
): AgendaJobsStore.IState {
  switch (action.type) {
    case AgendaJobsStore.ActionTypes.GET_AGENDA_JOBS: {
      return {
        ...state,
        agenda_jobs_list_pending: true,
        agenda_jobs_list_error: null
      };
    }
    case AgendaJobsStore.ActionTypes.GET_AGENDA_JOBS_SUCCESS: {
      return {
        ...state,
        agenda_jobs_list_pending: false,
        agenda_jobs_list_error: null,
        agenda_jobs_list: action.payload
      };
    }
    case AgendaJobsStore.ActionTypes.GET_AGENDA_JOBS_FAILED: {
      return {
        ...state,
        agenda_jobs_list_pending: false,
        agenda_jobs_list: new PaginationDTO(),
        agenda_jobs_list_error: action.payload
      };
    }
    case AgendaJobsStore.ActionTypes.CLEAR_AGENDA_JOBS_ERROR: {
      return {
        ...state,
        agenda_jobs_list_error: null
      };
    }
    default:
      return state;
  }
}

export default agendaJobsReducer;
