import { msg } from '../../../utils';
import { RewardType } from '../../../appRedux/types';
import {
  IAutopilotMemberStatusChangedEvent,
  IAutopilotMessageSentEvent,
  IAutopilotPointsGivenEvent,
  IAutopilotRewardGivenEvent,
  ICheckinBonusRedeemedEvent,
  ICheckinCoinsAllocatedEvent,
  ICheckinCreatedEvent,
  ICheckinPartialRedeemEvent,
  ICheckinRewardBoughtEvent,
  IMembershipCancelledEvent,
  IMembershipCreatedEvent,
  IMembershipRestoredEvent,
  IPaymentCoinsAllocatedEvent,
  IPaymentCoinsRefundedEvent,
  IPaymentCompletedEvent,
  IPaymentRefundedEvent,
  IReceiptApprovedEvent,
  IReceiptCoinsAllocatedEvent,
  IReviewCoinsAllocatedEvent,
  IUserTagAddedEvent
} from '../../../appRedux/activity';

enum MemberStatus {
  MEMBER_INACTIV,
  MEMBER_NEW,
  MEMBER_REGULAR,
  MEMBER_VIP,
  MEMBER_FLAGGED
}
export const MemberStatusDisplayMap = () => ({
  [MemberStatus.MEMBER_INACTIV]: msg('memberTypes.inactive', 'Inactive'),
  [MemberStatus.MEMBER_NEW]: msg('memberTypes.new', 'New'),
  [MemberStatus.MEMBER_REGULAR]: msg('memberTypes.regular', 'Regular'),
  [MemberStatus.MEMBER_VIP]: msg('memberTypes.vip', 'VIP'),
  [MemberStatus.MEMBER_FLAGGED]: msg('memberTypes.flagged', 'Flagged')
});

enum EventChannel {
  SMS,
  NOTIFICATION,
  EMAIL
}
export const EventChannelDisplayMap = () => ({
  [EventChannel.SMS]: msg('notificationChannels.sms', 'SMS'),
  [EventChannel.NOTIFICATION]: msg('notificationChannels.notification', 'In-app notification'),
  [EventChannel.EMAIL]: msg('notificationChannels.email', 'Email')
});

export const eventTypesFriendlyNames = () => [
  { _id: '', name: msg('activity.events.all', 'All') },
  {
    _id: 'USER_TAG_ADDED',
    name: msg('activity.events.userTagAdded', 'Add user level')
  },
  {
    _id: 'MEMBER_TAG_ADDED',
    name: msg('activity.events.memberTagAdded', 'Add member level')
  },
  {
    _id: 'RECEIPT_APPROVED',
    name: msg('activity.events.receiptApproved', 'Receipt approved')
  },
  {
    _id: 'RECEIPT_COINS_ALLOCATED',
    name: msg('activity.events.receiptCoinsAllocated', 'Receipt coins allocated')
  },
  { _id: 'PAYMENT_COINS_ALLOCATED', name: msg('activity.events.paymentCoinsAllocated', 'Payment coins allocated') },
  { _id: 'REVIEW_COINS_ALLOCATED', name: msg('activity.events.pollCoins', 'Poll coins allocated') },
  { _id: 'CHECKIN_COINS_ALLOCATED', name: msg('activity.events.coinsAllocated', 'Check-in coins allocated') },
  { _id: 'CHECKIN_REWARD_BOUGHT', name: msg('activity.events.rewardBought', 'Reward bought') },
  { _id: 'CHECKOUT', name: msg('activity.events.checkout', 'Checkout') },
  { _id: 'MEMBERSHIP_CREATED', name: msg('activity.events.membershipCreated', 'Member created') },
  {
    _id: 'MEMBER_TAG_REMOVED',
    name: msg('activity.events.memberTagRemoved', 'Remove member level')
  },
  {
    _id: 'USER_TAG_REMOVED',
    name: msg('activity.events.userTagRemoved', 'Remove user level')
  },
  { _id: 'PAYMENT_COMPLETED', name: msg('activity.events.payment', 'Payment') },
  { _id: 'MEMBERSHIP_CANCELED', name: msg('activity.events.membershipCancelled', 'Membership cancelled') },
  { _id: 'MEMBERSHIP_RESTORED', name: msg('activity.events.membershipRestored', 'Membership restored') },
  { _id: 'AUTOPILOT_ACTION_POINTS_GIVEN', name: msg('activity.events.pointsGiven', 'Points given') },
  { _id: 'CHECKIN_BONUS_REDEEMED', name: msg('activity.events.bonusRedeemed', 'Bonus redeemed') },
  { _id: 'AUTOPILOT_ACTION_REWARD_GIVEN', name: msg('activity.events.rewardGiven', 'Reward given') },
  { _id: 'PAYMENT_COINS_REFUNDED', name: msg('activity.events.paymentCoinsRefunded', 'Payment coins refunded') },
  {
    _id: 'AUTOPILOT_ACTION_MEMBER_STATUS_CHANGED',
    name: msg('activity.events.memberStatusChanged', 'Member status changed')
  },
  { _id: 'PAYMENT_REFUNDED', name: msg('activity.events.paymentRefunded', 'Payment refunded') },
  { _id: 'AUTOPILOT_ACTION_MESSAGE_SENT', name: msg('activity.events.messageSent', 'Message sent') },
  { _id: 'CHECKIN_PARTIAL_REDEEM', name: msg('activity.events.partialVoucherRedeem', 'Partial voucher redeem') },
  { _id: 'CHECKIN_CREATED', name: msg('activity.events.checkinCreated', 'Checkin') }
];

export const changeDisplayMap = {
  AUTOPILOT_ACTION_REWARD_GIVEN: (activity: IAutopilotRewardGivenEvent) =>
    `${msg('activity.changes.rewardGiven', `Received ${activity.rewardTitle}`, {
      reward: activity.rewardTitle
    })}`,
  AUTOPILOT_ACTION_POINTS_GIVEN: (activity: IAutopilotPointsGivenEvent) =>
    `${msg('activity.changes.pointsGiven', `Received ${activity.points} coins`, { points: activity.points + '' })}`,
  AUTOPILOT_ACTION_MEMBER_STATUS_CHANGED: (activity: IAutopilotMemberStatusChangedEvent) => {
    const status = MemberStatusDisplayMap()[activity.memberStatus];
    return `${msg('activity.changes.memberStatusChanged', `Status changed to ${status}`, {
      status
    })}`;
  },
  AUTOPILOT_ACTION_MESSAGE_SENT: (activity: IAutopilotMessageSentEvent) => {
    const channels = activity.channels.map((ch: number) => EventChannelDisplayMap()[ch]).join(', ');
    return `${msg('activity.changes.messageSent', `Sent message via ${channels}`, {
      channels
    })}`;
  },
  MEMBERSHIP_CREATED: (activity: IMembershipCreatedEvent) =>
    msg('activity.changes.membershipCreated', `Received ${activity.points} coins`, { points: activity.points + '' }),
  MEMBERSHIP_CANCELED: (activity: IMembershipCancelledEvent) =>
    msg('activity.changes.membershipCancelled', `Lost ${activity.points} coins`, { points: activity.points + '' }),
  MEMBERSHIP_RESTORED: (activity: IMembershipRestoredEvent) =>
    msg('activity.changes.membershipRestored', `Restored ${activity.points} coins`, { points: activity.points + '' }),
  CHECKIN_CREATED: (activity: ICheckinCreatedEvent) =>
    msg('activity.changes.checkinCreated', `Received ${activity.points} coins`, { points: activity.points + '' }),
  CHECKIN_REWARD_BOUGHT: (activity: ICheckinRewardBoughtEvent) =>
    msg('activity.changes.rewardBought', `Spent ${activity.price} coins on ${activity.reward}`, {
      price: activity.price + '',
      reward: activity.reward
    }),
  CHECKIN_BONUS_REDEEMED: (activity: ICheckinBonusRedeemedEvent) => {
    const availableAmount = (activity.amount - activity.usedAmount || 0) + '';
    return activity.rewardType === RewardType.VOUCHER && activity.requestedAmount
      ? msg(
          'activity.changes.partialVoucherRedeem',
          `Used ${activity.requestedAmount} RON from ${activity.bonus} (available ${availableAmount} RON)`,
          {
            title: activity.bonus,
            amount: activity.requestedAmount + '',
            availableAmount: availableAmount
          }
        )
      : `${msg('activity.changes.voucherRedeemed', `Used ${activity.bonus}`, {
          bonus: activity.bonus
        })}`;
  },
  CHECKIN_COINS_ALLOCATED: (activity: ICheckinCoinsAllocatedEvent) =>
    msg('activity.changes.coinsAllocated', `Received ${activity.coins} coins`, { coins: activity.coins + '' }),
  RECEIPT_APPROVED: (activity: IReceiptApprovedEvent) =>
    msg('activity.changes.receiptApproved', `Receipt amount: ${activity.receiptAmount} RON`, {
      amount: activity.receiptAmount + ''
    }),
  RECEIPT_COINS_ALLOCATED: (activity: IReceiptCoinsAllocatedEvent) =>
    msg(
      'activity.changes.receiptCoinsAllocated',
      `Received ${activity.coins} coins. Receipt amount: ${activity.receiptAmount} RON`,
      { coins: activity.coins + '', amount: activity.receiptAmount + '' }
    ),
  CHECKIN_PARTIAL_REDEEM: (activity: ICheckinPartialRedeemEvent) => {
    const availableAmount = (activity.amount - activity.usedAmount || 0) + '';
    return msg(
      'activity.changes.partialVoucherRedeem',
      `Used ${activity.usedAmount} RON from ${activity.voucherTitle} (available ${availableAmount} RON)`,
      {
        title: activity.voucherTitle,
        amount: activity.requestedAmount + '',
        availableAmount: availableAmount
      }
    );
  },
  REVIEW_COINS_ALLOCATED: (activity: IReviewCoinsAllocatedEvent) =>
    msg('activity.changes.pollCoinsAllocated', `Received ${activity.coins} coins from answering a poll`, {
      coins: activity.coins + ''
    }),
  PAYMENT_COMPLETED: (activity: IPaymentCompletedEvent) => {
    if (activity.usedCoins && activity.tipsAmount) {
      return msg(
        'activity.changes.amountPaidWithCoinsTips',
        `Paid ${activity.amount / 100} lei and ${activity.usedCoins} coins (total ${
          activity.requestedAmount / 100
        } lei + ${activity.tipsAmount / 100} lei tips).`,
        {
          amount: activity.amount / 100 + '',
          usedCoins: activity.usedCoins + '',
          total: activity.requestedAmount / 100 + '',
          tips: activity.tipsAmount / 100 + ''
        }
      );
    } else if (activity.usedCoins) {
      return msg(
        'activity.changes.amountPaidWithCoins',
        `Paid ${activity.amount / 100} lei and ${activity.usedCoins} coins (total ${
          activity.requestedAmount / 100
        } lei).`,
        {
          amount: activity.amount / 100 + '',
          usedCoins: activity.usedCoins + '',
          total: activity.requestedAmount / 100 + ''
        }
      );
    }
    if (activity.tipsAmount) {
      return msg(
        'activity.changes.amountPaidTips',
        `Paid ${activity.amount / 100} lei (+ ${activity.tipsAmount} lei tips).`,
        {
          amount: activity.amount / 100 + '',
          tips: activity.tipsAmount / 100 + ''
        }
      );
    }
    return msg('activity.changes.amountPaid', `Paid ${activity.amount / 100} lei.`, {
      amount: activity.amount / 100 + ''
    });
  },
  PAYMENT_REFUNDED: (activity: IPaymentRefundedEvent) => {
    if (activity.usedCoins && activity.tipsAmount) {
      return msg(
        'activity.changes.amountRefundedWithCoinsTips',
        `Refunded ${activity.amount / 100} lei and ${activity.usedCoins} coins (total ${
          activity.requestedAmount / 100
        } lei + ${activity.tipsAmount / 100} lei tips).`,
        {
          amount: activity.amount / 100 + '',
          tips: activity.tipsAmount / 100 + '',
          usedCoins: (activity.usedCoins || 0) + '',
          total: activity.requestedAmount / 100 + ''
        }
      );
    } else if (activity.usedCoins) {
      return msg(
        'activity.changes.amountRefundedWithCoins',
        `Refunded ${activity.amount / 100} lei and ${activity.usedCoins} coins (total ${
          activity.requestedAmount / 100
        } lei).`,
        {
          amount: activity.amount / 100 + '',
          usedCoins: (activity.usedCoins || 0) + '',
          total: activity.requestedAmount / 100 + ''
        }
      );
    }
    if (activity.tipsAmount) {
      return msg(
        'activity.changes.amountRefundedTips',
        `Refunded ${activity.amount / 100} lei (+ ${activity.tipsAmount} lei tips).`,
        {
          amount: activity.amount / 100 + '',
          tips: activity.tipsAmount / 100 + ''
        }
      );
    }
    return msg('activity.changes.amountRefunded', `Refunded ${activity.amount / 100} lei.`, {
      amount: activity.amount / 100 + ''
    });
  },
  PAYMENT_COINS_ALLOCATED: (activity: IPaymentCoinsAllocatedEvent) =>
    msg('activity.changes.paymentCoinsAllocated', `Received ${activity.coins} coins from a payment`, {
      coins: activity.coins + ''
    }),
  PAYMENT_COINS_REFUNDED: (activity: IPaymentCoinsRefundedEvent) =>
    msg('activity.changes.paymentCoinsRefunded', `Lost ${activity.coins} coins from a payment rollback`, {
      coins: activity.coins + ''
    }),
  USER_TAG_ADDED: (activity: IUserTagAddedEvent) =>
    msg('activity.changes.userTagAdded', `Added user level ${activity.tagName}`, {
      tag: activity.tagName
    }),
  USER_TAG_REMOVED: (activity: IUserTagAddedEvent) =>
    msg('activity.changes.userTagRemoved', `Removed user level ${activity.tagName}`, {
      tag: activity.tagName
    }),
  MEMBER_TAG_ADDED: (activity: IUserTagAddedEvent) =>
    msg('activity.changes.memberTagAdded', `Added member level ${activity.tagName}`, {
      tag: activity.tagName
    }),
  MEMBER_TAG_REMOVED: (activity: IUserTagAddedEvent) =>
    msg('activity.changes.memberTagRemoved', `Removed member level ${activity.tagName}`, {
      tag: activity.tagName
    })
};
