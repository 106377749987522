import * as React from 'react';
import { Bounce } from 'react-activity';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { AppStore, AuthStore, PartnersStore, PlacesStore, TranslationsStore } from './appRedux';
import {
  ActivationContainer,
  AuthContainer,
  ChoosePartnerContainer,
  ForgotPasswordContainer,
  NotFoundContainer,
  OnboardContainer,
  PasswordChangedContainer,
  ReadEmailContainer,
  ResetPasswordContainer,
  SentActivationEmailContainer,
  SentRecoveryEmailContainer,
  UnsubscribeContainer,
  ValidateEmailContainer
} from './modules/components';
import {
  AdminLayoutContainer,
  adminRoutes,
  authRoutes,
  PartnerLayoutContainer,
  PaymentsLayoutContainer
} from './modules/layouts';
import AuthUtil from './utils/authentication';

interface IProps {
  auth?: any;
  init?: any;
  translations?: any;
}
interface IState {}

class App extends React.Component<IProps & PartnersStore.IActions & PlacesStore.IActions & AuthStore.IActions, IState> {
  componentDidMount() {
    document.title = process.env.REACT_APP_NAME || '';
    const favicon: any = document.getElementById('favicon');
    if (favicon) {
      favicon.href = `/favicon-${process.env.REACT_APP_NAME?.toLowerCase().replace(' ', '-')}.ico`;
    }
    this.props.init();
    if (this.props.auth.user) {
      this.props.getUserAccess();
    }
  }

  render() {
    const translations = this.props.translations;

    return (
      (translations.initial_language && translations.hasDoneInit && (
        <Switch>
          <Redirect exact={true} from="/" to="/partner" />
          <Route
            path="/auth/choose-partner"
            render={() => {
              if (AuthUtil.isPartnerAdmin()) {
                return <ChoosePartnerContainer />;
              } else {
                return <Redirect to="/auth" />;
              }
            }}
          />
          <Route path={authRoutes.FORGOT_PASSWORD_EMAIL} render={() => <SentRecoveryEmailContainer />} />
          <Route path={authRoutes.FORGOT_PASSWORD} render={() => <ForgotPasswordContainer />} />
          <Route path={authRoutes.RESET_PASSWORD} render={() => <ResetPasswordContainer />} />
          <Route path={authRoutes.PASSWORD_CHANGED} render={() => <PasswordChangedContainer />} />
          <Route path={authRoutes.ACTIVATION} render={() => <ActivationContainer />} />
          <Route path={authRoutes.ACTIVATION_SENT} render={() => <SentActivationEmailContainer />} />
          <Route path={authRoutes.VALIDATE_EMAIL} render={() => <ValidateEmailContainer />} />
          <Route path={authRoutes.UNSUBSCRIBE} render={() => <UnsubscribeContainer />} />
          <Route path={authRoutes.ONBOARD} render={() => <OnboardContainer />} />
          <Route path={authRoutes.READ_EMAIL} render={() => <ReadEmailContainer />} />
          <Route
            path="/auth"
            render={() => {
              if (AuthUtil.isPaymentsAccount()) {
                return <Redirect to={adminRoutes.TRANSACTIONS.path} />;
              } else if (AuthUtil.isSuperAdmin()) {
                return <Redirect to={adminRoutes.DASHBOARD.path} />;
              } else if (AuthUtil.isPartnerAdmin()) {
                const partners = AuthUtil.getPartnersForUser();
                if (!AuthUtil.isUserOwner() && partners.length >= 2) {
                  return <Redirect to="/auth/choose-partner" />;
                } else {
                  this.props.selectPartnerLogin(partners[0]?._id, true);
                  this.props.selectPlaceLogin(partners[0]?.places[0]?._id);
                  return <Redirect to="/partner/dashboard" />;
                }
              } else if (AuthUtil.isPlaceAdmin()) {
                const places = AuthUtil.getPlacesForUser();
                this.props.selectPlaceLogin(places[0]?._id);
                this.props.selectPartnerLogin(places[0]?.partnerId, true);
                return <Redirect to={`/partner/dashboard/${places[0]?._id}`} />;
              } else {
                return <AuthContainer />;
              }
            }}
          />
          <Route
            path="/partner"
            render={() => {
              if (AuthUtil.isSuperAdmin()) {
                return <Redirect to="/admin/dashboard" />;
              } else if (AuthUtil.isPartnerAdmin() || AuthUtil.isPlaceAdmin()) {
                return <PartnerLayoutContainer />;
              } else {
                return <Redirect to="/auth" />;
              }
            }}
          />
          <Route
            path="/admin"
            render={() => {
              if (AuthUtil.isPaymentsAccount()) {
                return <PaymentsLayoutContainer />;
              } else if (AuthUtil.isSuperAdmin()) {
                return <AdminLayoutContainer />;
              } else {
                return <Redirect to="/auth" />;
              }
            }}
          />
          <Route path="*" render={() => <NotFoundContainer />} />
        </Switch>
      )) || (
        <div className="w-screen h-screen flex items-center justify-center">
          <Bounce color="#5eb562" />
        </div>
      )
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    auth: state.auth,
    translations: state.translations
  };
}

export default withRouter(
  connect<{}, {}, {}>(mapStateToProps, {
    init: TranslationsStore.actions.init,
    selectPartnerLogin: PartnersStore.actions.selectPartnerLogin,
    selectPlaceLogin: PlacesStore.actions.selectPlaceLogin,
    getUserAccess: AuthStore.actions.getUserAccess
  })(App) as any
);
