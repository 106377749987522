import * as React from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { msg } from '../../utils';

interface IProps {
  label?: any;
  onChange: (event: any) => void;
  options: any[];
  value: string;
  id: string;
  name: string;
  required?: boolean;
  inputWidth?: string;
  formatError?: string;
  needsAllLabel?: boolean;
  arrayOptions?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  style?: any;
  styleSelect?: any;
  classesSelect?: any;
  shrinkLabel?: boolean;
  needsFullLength?: boolean;
  validatorIgnore?: boolean;
  validator?: any;
  hasNameValue?: boolean;
  placeholder?: string;
}

interface IState {}
export default class SimpleSelectComponent extends React.Component<IProps, IState> {
  render() {
    const inputClass = this.props.inputWidth ? 'col-sm-' + this.props.inputWidth : 'col-sm-12';
    let optionItems = undefined;
    if (this.props.arrayOptions) {
      optionItems = this.props.options.map((option: any, key: any) => {
        let selected = false;
        if (this.props.hasNameValue) {
          if (this.props.value && this.props.value === option.value) {
            selected = true;
          }
          return (
            <MenuItem key={key} id={option.value} value={option.value} selected={selected}>
              {option.name}
            </MenuItem>
          );
        } else {
          if (this.props.value && this.props.value === option) {
            selected = true;
          }
          return (
            <MenuItem key={key} id={option} value={option} selected={selected}>
              {option}
            </MenuItem>
          );
        }
      });
    } else {
      optionItems = this.props.options.map((option: any, key: any) => {
        let selected = false;
        if (this.props.value && this.props.value === option._id) {
          selected = true;
        }
        return (
          <MenuItem key={key} id={option._id} value={option._id} selected={selected}>
            {option.name}
          </MenuItem>
        );
      });
    }
    return (
      <div className={this.props.needsFullLength ? inputClass : ''} style={this.props.style}>
        <div>
          <FormControl
            fullWidth={true}
            aria-describedby="selectText"
            error={this.props.formatError && this.props.formatError !== '' ? true : false}
            required={this.props.required}
            disabled={this.props.disabled}
          >
            {this.props.shrinkLabel ? (
              <InputLabel shrink={true} htmlFor={this.props.id}>
                {this.props.label}
              </InputLabel>
            ) : (
              <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
            )}
            <Select
              value={this.props.value === undefined ? '' : this.props.value}
              displayEmpty={true}
              onChange={this.props.onChange}
              classes={{ root: this.props.classesSelect?.root, icon: this.props.classesSelect?.icon }}
              style={this.props.styleSelect}
              inputProps={{
                name: this.props.name,
                id: this.props.id,
                readOnly: this.props.readOnly
              }}
            >
              {this.props.placeholder && (
                <MenuItem value="" disabled={true}>
                  {this.props.placeholder}
                </MenuItem>
              )}
              {this.props.needsAllLabel && (
                <MenuItem key="" id="" value="">
                  {msg('general.all', 'All')}
                </MenuItem>
              )}
              {optionItems}
            </Select>
            {this.props.formatError && (
              <FormHelperText id="selectText" error={true}>
                {this.props.formatError}
              </FormHelperText>
            )}
          </FormControl>
          <span className="help-block" />
          <span className="material-input" />
        </div>
      </div>
    );
  }
}
