import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { AnnouncementsListComponent, AnnouncementContentComponent } from './components';
import { reduxContainer, AppStore, AnnouncementsStore, PlacesStore } from '../../../appRedux';
import { PlaceDTO } from '../../../appRedux/types';
import { adminRoutes } from '../../layouts';

interface IProps extends AnnouncementsStore.IState {
  places_list: PlaceDTO[];
  getPlacesListAction(): any;
}
interface IState {}

class AnnouncementContainer extends React.Component<IProps & AnnouncementsStore.IActions, IState> {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path={adminRoutes.ANNOUNCEMENTS.path}
          render={() => (
            <AnnouncementsListComponent
              announcements={this.props.announcements_list}
              announcementsFail={this.props.announcements_list_error}
              announcementsPending={this.props.announcements_list_pending}
              delete_announcement_pending={this.props.delete_announcement_pending}
              delete_announcement_error={this.props.delete_announcement_error}
              getAnnouncements={this.props.getAnnouncementsAction}
              redirectToAnnouncementContent={this.props.redirectToAnnouncementContent}
              deleteAnnouncementAction={this.props.deleteAnnouncementAction}
            />
          )}
        />
        <Route
          exact={true}
          path={adminRoutes.ANNOUNCEMENTS.subroutes.ADD.path}
          render={({ match }) => (
            <AnnouncementContentComponent
              match={match}
              announcement={this.props.announcement}
              announcement_pending={this.props.announcement_pending}
              places_list={this.props.places_list}
              addAnnouncementAction={this.props.addAnnouncementAction}
              getPlacesListAction={this.props.getPlacesListAction}
            />
          )}
        />
        <Route
          exact={true}
          path={adminRoutes.ANNOUNCEMENTS.subroutes.EDIT.path}
          render={({ match }) => (
            <AnnouncementContentComponent
              match={match}
              announcement={this.props.announcement}
              announcement_pending={this.props.announcement_pending}
              places_list={this.props.places_list}
              addAnnouncementAction={this.props.addAnnouncementAction}
              getAnnouncementAction={this.props.getAnnouncementAction}
              editAnnouncementAction={this.props.editAnnouncementAction}
              getPlacesListAction={this.props.getPlacesListAction}
            />
          )}
        />
      </Switch>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    announcements_list: state.announcements.announcements_list,
    announcements_list_error: state.announcements.announcements_list_error,
    announcements_list_pending: state.announcements.announcements_list_pending,
    announcement: state.announcements.announcement,
    announcement_pending: state.announcements.announcement_pending,
    delete_announcement_pending: state.announcements.delete_announcement_pending,
    delete_announcement_error: state.announcements.delete_announcement_error,
    places_list: state.places.places_list
  };
}

const dispatchToProps = {
  getAnnouncementsAction: AnnouncementsStore.actions.getAnnouncementsAction,
  redirectToAnnouncementContent: AnnouncementsStore.actions.redirectToAnnouncementContent,
  addAnnouncementAction: AnnouncementsStore.actions.addAnnouncementAction,
  getAnnouncementAction: AnnouncementsStore.actions.getAnnouncementAction,
  editAnnouncementAction: AnnouncementsStore.actions.editAnnouncementAction,
  deleteAnnouncementAction: AnnouncementsStore.actions.deleteAnnouncementAction,
  getPlacesListAction: PlacesStore.actions.getPlacesListAction
};

export default reduxContainer(AnnouncementContainer, mapStateToProps, dispatchToProps);
