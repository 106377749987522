import { RewardType } from './eventDTO';
import { TagDTO } from './tagDTO';

export class RewardStocksDTO {
  dayStock: number = 0;
  weekStock: number = 0;
  monthStock: number = 0;
  dayCount: number = 0;
  weekCount: number = 0;
  monthCount: number = 0;
  dayUnlimited: boolean = true;
  weekUnlimited: boolean = true;
  monthUnlimited: boolean = true;

  constructor(stocks?: RewardStocksDTO) {
    Object.assign(this, stocks);
    this.dayUnlimited = !this.dayStock;
    this.weekUnlimited = !this.weekStock;
    this.monthUnlimited = !this.monthStock;
  }
}

export class RewardDTO {
  _id: string | null = null;
  partnerId: string;
  placeId?: string;
  title: string;
  description: string;
  points: number;
  isFree?: boolean;
  media: any[];
  amount?: number;
  dirty?: boolean = false;
  isActive: boolean;
  type: RewardType;
  stocks: RewardStocksDTO = new RewardStocksDTO();
  memberTags?: TagDTO[];
  userTags?: TagDTO[];
}
