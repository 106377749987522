export const colors = {
  blue: '#47b9bf',
  onboardingBlue: '#0bb0e3',
  darkGreen: '#458063',
  lightGreen: '#66bb6a',
  statsLightGreen: '#91baa2',
  green: '#4caf50',
  orange: '#ff9800',
  purple: '#9c27b0',
  red: '#f44336',
  darkRed: '#ab2f26',
  gray: '#b0b0b0',
  lightGray: '#eaefef',
  lightGray2: '#e6e6e6',
  white: '#ffffff',
  black: '#0e0b0b'
};
