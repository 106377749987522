import * as React from 'react';
import classnames from 'classnames';
import { Bounce } from 'react-activity';
import Toggle from 'react-toggle';
import Tooltip from '@material-ui/core/Tooltip';
import { Checkbox } from '@material-ui/core';
import { ButtonComponent } from '.';
import { msg } from '../../utils';
import { colors } from '../../assets/styles';

interface IProps {
  headerIcon: string;
  title: any;
  pending?: boolean;
  error?: boolean;
  type?: string;
  subtitle?: string;
  needsTitle?: boolean;
  disableAnimated?: boolean;
  style?: any;
  toggle?: {
    label: string;
    tooltip?: string;
    onChange: (event: any) => any;
    checked: boolean;
    disabled?: boolean;
  } | null;
  buttons?: Array<{
    label: string;
    icon?: string;
    onClick: () => any;
  }>;
  checkboxes?: Array<{
    label: string;
    checked: boolean;
    onChange: (event: any) => any;
  }>;
}
interface IState {
  showCard: boolean;
}

export default class CardComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showCard: false
    };
  }

  componentDidMount() {
    this.setState({ showCard: true });
  }

  render() {
    const isCardStats = this.props.type !== undefined && this.props.type === 'stats';
    let cardClasses = classnames('card', {
      'card-animated': !this.props.disableAnimated,
      'card-hidden': !this.state.showCard,
      'card-stats': isCardStats
    });
    return (
      <div className={cardClasses} style={this.props.style && this.props.style}>
        {this.props.needsTitle && (
          <div className="card-header card-header-text" data-background-color="green">
            {!isCardStats ? (
              <h4 className="card-title">
                <i className="material-icons">{this.props.headerIcon}</i>
                {this.props.title}
              </h4>
            ) : (
              <i className="material-icons">{this.props.headerIcon}</i>
            )}
          </div>
        )}
        <div className="card-content">
          {this.props.pending ? (
            <div style={{ textAlign: 'center', padding: 20 }}>
              <Bounce color={colors.lightGreen} />
            </div>
          ) : this.props.error ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 20,
                color: colors.red
              }}
            >
              <i className="material-icons" style={{ fontSize: 60, color: colors.red }}>
                error_outline
              </i>
              <h4>{msg('errors.errorOccured', 'An error has occured, please try again!')}</h4>
            </div>
          ) : isCardStats ? (
            <div>
              <p className="category">{this.props.subtitle}</p>
              <h3 className="card-title">{this.props.title}</h3>
            </div>
          ) : (
            <div>
              <div
                className={
                  this.props.toggle || this.props.buttons?.length || this.props.checkboxes ? 'card-config' : ''
                }
              >
                {this.props.checkboxes?.map((checkbox, index) => (
                  <div key={index} style={{ display: 'flex', margin: '5px 10px' }}>
                    <div
                      style={{
                        fontSize: 17.5,
                        alignSelf: 'center'
                      }}
                    >
                      {checkbox.label}
                    </div>
                    <Checkbox checked={checkbox.checked} onChange={checkbox.onChange} color="primary" />
                  </div>
                ))}
                {this.props.toggle && (
                  <div style={{ minHeight: 60, display: 'flex', alignItems: 'center', margin: '5px 20px' }}>
                    <div
                      style={{
                        margin: 6,
                        fontSize: 17.5,
                        alignSelf: this.props.buttons?.length ? 'center' : 'flex-start'
                      }}
                    >
                      {this.props.toggle.label}
                    </div>
                    <Tooltip title={this.props.toggle.tooltip || ''}>
                      <div style={{ margin: 4, alignSelf: this.props.buttons?.length ? 'center' : 'flex-start' }}>
                        <Toggle
                          onChange={this.props.toggle.onChange}
                          checked={this.props.toggle.checked}
                          disabled={this.props.toggle.disabled || false}
                        />
                      </div>
                    </Tooltip>
                  </div>
                )}
                {this.props.buttons?.map((button, index) => (
                  <div key={index} style={{ marginLeft: 6 }}>
                    <ButtonComponent label={button.label} action={button.onClick} icon={button.icon} />
                  </div>
                ))}
              </div>
              <div
                className={
                  this.props.toggle || this.props.buttons?.length || this.props.checkboxes ? 'card-children' : ''
                }
              >
                {this.props.children}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
