import { Dispatch } from 'redux';
import { PaginationDTO, PlaceStatisticsDTO, StatisticsSuperAdminDTO } from '../types';
import { StatisticsStore } from './';
import { Server, composedCriteriaBuilder, AlertUtil, msg, logger } from '../../utils';

/*
  IStatisticsActions interface definition, which contains every redux action asociated with Statistics State.
*/
/* tslint:disable */
export interface IStatisticsActions {
  /*
    Actions specific for place
  */
  getStatisticsForOnePlaceAction(placeId: string): any;

  getAllAggStatistics(placeId: string, startDate: any, endDate: any, type: any): any;
  getStatisticsPerPlaceForAdminAction(
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  getAllSuperAdminStatistics(): any;
}

/*
  class StatisticsActions that implements redux actions defined in IStatisticsActions interface
*/
class StatisticsActions implements IStatisticsActions {
  /*
    @function getStatisticsForOnePlaceAction => Redux action that gets basic statistics for a place
          (basic statistics = members count, checkins count, places count, rewards count, events count etc.)
      @accepts placeId : string representing the id of the place to get its statistics
               checkinPeriod : object that contains the startDate and endDate for the period you want to get stats
      @returns Promise
  */
  getStatisticsForOnePlaceAction(placeId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: StatisticsStore.ActionTypes.GET_PLACE_STATISTICS
      });
      logger.msg('Get statistics for one place action, route:/places/placeId/meta-data', 'GET');
      Server.get(`places/${placeId}/meta-data`)
        .then((response: any) => {
          dispatch({
            type: StatisticsStore.ActionTypes.GET_PLACE_STATISTICS_SUCCESS,
            payload: response.data
          });
        })
        .catch(error => {
          logger.err('Get statistics for one place action, route:/places/placeId/meta-data', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.statistics.getStatisticsError', 'Due to an error, the statistics could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: StatisticsStore.ActionTypes.GET_PLACE_STATISTICS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getAllAggStatistics(placeId: string, startDate: any, endDate: any, type: any) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: StatisticsStore.ActionTypes.GET_ALL_AGG_STATISTICS
      });
      logger.msg('Get All Agg Statistics, route:/places/placeId/agg-statistics?startDate&endDate&type', 'GET');
      Server.get(`places/${placeId}/agg-statistics?startDate=${startDate}&endDate=${endDate}&type=${type}`)
        .then((response: any) => {
          dispatch({
            type: StatisticsStore.ActionTypes.GET_ALL_AGG_STATISTICS_SUCCESS,
            payload: response.data
          });
        })
        .catch(error => {
          logger.err('Get all agg statistics, route:/places/placeId/agg-statistics?startDate&endDate&type', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.statistics.getStatisticsError', 'Due to an error, the statistics could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: StatisticsStore.ActionTypes.GET_ALL_AGG_STATISTICS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getStatisticsPerPlaceForAdminAction(
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: StatisticsStore.ActionTypes.GET_STATISTICS_PER_PLACE_SUPERADMIN
      });
      try {
        if (!limit) {
          limit = 30;
        }
        let url = `admin/place-statistics?limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get statistics for superadmin about places, route:/admin/place-statistics', 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: StatisticsStore.ActionTypes.GET_STATISTICS_PER_PLACE_SUPERADMIN_SUCCESS,
          payload: response.data as PaginationDTO<PlaceStatisticsDTO>
        });
      } catch (error) {
        logger.err('Get statistics for superadmin about places, route:/admin/place-statistics', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.statistics.getStatisticsError', 'Due to an error, the statistics could not be loaded!'),
          'error',
          2000
        );
        dispatch({
          type: StatisticsStore.ActionTypes.GET_STATISTICS_PER_PLACE_SUPERADMIN_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  getAllSuperAdminStatistics() {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: StatisticsStore.ActionTypes.GET_ALL_STATISTICS_SUPERADMIN
      });
      logger.msg('Get statistics for superadmin, route:/admin/all-statistics', 'GET');
      Server.get(`admin/all-statistics`)
        .then((response: any) => {
          dispatch({
            type: StatisticsStore.ActionTypes.GET_ALL_STATISTICS_SUPERADMIN_SUCCESS,
            payload: response.data as StatisticsSuperAdminDTO
          });
        })
        .catch(error => {
          logger.err('Get statistics for superadmin, route:/admin/all-statistics', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.statistics.getStatisticsError', 'Due to an error, the statistics could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: StatisticsStore.ActionTypes.GET_ALL_STATISTICS_SUPERADMIN_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }
}

const statisticsActions = new StatisticsActions();
export default statisticsActions;
