import { ReceiptDTO } from '.';

export class TransactionDTO {
  _id: string;
  uniqueNo: string;
  placeId: string;
  requestedAmount: number;
  amount?: number;
  tipsAmount: number;
  usedCoins?: number;
  conversion?: number;
  receivedCoins?: number;
  coins: number;
  status: TransactionStatusTypes;
  created: Date;
  receiptId?: string;
  receipt?: ReceiptDTO;
  mobileUser?: {
    email: string;
    firstname: string;
    lastname: string;
  };
  tabletUser?: {
    email: string;
    firstname: string;
    lastname: string;
  };
  tabletProfile?: {
    name: string;
  };
  payment?: PaymentDTO;
  payments?: PaymentDTO[];
  failedCode?: string;
  place?: {
    name: string;
  };
  mobileSignType: string;
  isSettled?: boolean;
}

export class PaymentDTO {
  created: string;
  externalId: string;
  internalStatus: string;
  externalStatus: string;
  actionCode?: number;
  actionCodeDescription?: string;
  errorCode?: number;
  errorMessage?: string;
  isSettled?: boolean;
}

export enum TransactionStatusTypes {
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
  APPROVED = 'APPROVED',
  AUTO_TIMEOUT = 'AUTO_TIMEOUT',
  TIMEOUT = 'TIMEOUT',
  FAILED = 'FAILED',
  ROLLED_BACK = 'ROLLED_BACK'
}

export enum PaymentInternalStatuses {
  PENDING = 'PENDING',
  WAITING_FOR_USER = 'WAITING_FOR_USER',
  FAILED = 'FAILED',
  CLOSED_BY_USER = 'CLOSED_BY_USER',
  CLOSED_BY_SYSTEM = 'CLOSED_BY_SYSTEM',
  DONE_BY_USER = 'DONE_BY_USER',
  FAILED_BY_USER = 'FAILED_BY_USER'
}

export enum PaymentExternalStatuses {
  CREATED = 'CREATED',
  DEPOSITED = 'DEPOSITED',
  REFUNDED = 'REFUNDED',
  DECLINED = 'DECLINED',
  VALIDATION_FINISHED = 'VALIDATION_FINISHED'
}
