import * as React from 'react';
import { Classes } from '@blueprintjs/core';
import { DateRange, DateRangePicker } from '@blueprintjs/datetime';

export interface IDateRangePickerExampleState {
  allowSingleDayRange?: boolean;
  singleMonthOnly?: boolean;
  closeOnSelection?: boolean;
  contiguousCalendarMonths?: boolean;
  dateRange?: any;
  reverseMonthAndYearMenus?: boolean;
  shortcuts?: boolean;
}

interface IProps {
  style?: any;
  defaultValue: any;
  getRangeData: (startDate: any, endDate: any) => any;
}

export class RangePickerComponent extends React.PureComponent<IProps, IDateRangePickerExampleState> {
  public state: IDateRangePickerExampleState = {
    allowSingleDayRange: true,
    contiguousCalendarMonths: false,
    dateRange: [null, null],
    reverseMonthAndYearMenus: false,
    shortcuts: true,
    singleMonthOnly: false,
    closeOnSelection: true
  };

  public getDateRange = (dateRange: any) => {
    if (dateRange.length === 2) {
      this.props.getRangeData(dateRange[0], dateRange[1]);
    }
  };

  public render() {
    const { ...props } = this.state;
    return (
      <DateRangePicker
        {...props}
        defaultValue={this.props.defaultValue}
        className={Classes.ELEVATION_1}
        onChange={dateRange => {
          this.handleDateChange(dateRange);
          this.getDateRange(dateRange);
        }}
      />
    );
  }

  private handleDateChange = (dateRange: DateRange) => this.setState({ dateRange });
}
