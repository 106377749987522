import { simpleAutopilotsSchema, AutopilotTriggerType, AutopilotActionType } from './simpleAutopilotsSchema';
import { AutopilotDTO } from '../../../appRedux/types';
import { getFormatDate } from '../../../utils';

class SimpleAutopilotsUtils {
  setFor = (trigger: any, schemaFor: any) => {
    switch (schemaFor.type) {
      case AutopilotTriggerType.TEXT:
        return schemaFor;
      case AutopilotTriggerType.DROPDOWN:
        schemaFor.value = trigger.value;
        return schemaFor;
      case AutopilotTriggerType.DROPDOWNTYPE:
        return {
          ...schemaFor,
          triggerType: trigger.type
        };
      case AutopilotTriggerType.VALUE:
        return {
          ...schemaFor,
          value: trigger.value
        };
      default:
        return schemaFor;
    }
  };

  setExpiration = (expServer: number): any => {
    return expServer / (1000 * 60 * 60 * 24);
  };

  getState = (serverAutopilots: any, disableCoins?: boolean): any => {
    const state: any[] = [];
    simpleAutopilotsSchema.forEach(schema => {
      serverAutopilots.forEach((server: any) => {
        if (server.title === schema.type) {
          state.push({
            ...schema,
            id: server._id,
            placeId: server.placeId,
            type: server.title,
            for: this.setFor(server.triggers[0], schema.for),
            expireSelected:
              server.expiration && server.actions[0]?.type === AutopilotActionType.REWARD
                ? this.setExpiration(server.expiration)
                : server.actions[0]?.type === AutopilotActionType.REWARD
                ? schema.expireSelected
                : disableCoins && server.actions[0]?.type === AutopilotActionType.POINTS
                ? 1
                : 0,
            reward: {
              type:
                disableCoins && server.actions[0].type === AutopilotActionType.POINTS
                  ? AutopilotActionType.REWARD
                  : server.actions[0] && server.actions[0].type,
              value:
                disableCoins && server.actions[0].type === AutopilotActionType.POINTS
                  ? ''
                  : server.actions[0] && server.actions[0].value,
              media: server.actions[0] && server.actions[0].media,
              message: server.actions[0] && server.actions[0].message
            },
            dirty: false,
            stats: {
              ...server.stats,
              date: getFormatDate(server.lastTriggered, 'hour')
            },
            isActive: server.isActive,
            updateAllRewards: false
          });
        }
      });
    });
    return state;
  };

  getServer = (stateAutopilots: any, serverAutopilots: any): AutopilotDTO[] => {
    const newServer: any[] = [];
    serverAutopilots.forEach((server: any) => {
      stateAutopilots.forEach((state: any) => {
        if (server.title === state.type && state.dirty) {
          const { triggers, actions } = server;
          if (state.for) {
            if (state.for.value) {
              if (triggers[0].type === 2) {
                triggers[0].value = parseInt(state.for.value, 10);
              } else {
                triggers[0].value = state.for.value;
              }
            } else if (state.for.triggerType) {
              triggers[0].type = state.for.triggerType;
            }
          }
          actions[0].media = state.reward.media;
          actions[0].message = state.reward.message;
          actions[0].type = parseInt(state.reward.type, 10);
          if (actions[0].type === AutopilotActionType.POINTS) {
            actions[0].value = parseInt(state.reward.value, 10);
          } else {
            actions[0].value = state.reward.value;
          }
          newServer.push({
            ...server,
            triggers,
            actions,
            message: state.message,
            expiration: state.expireSelected ? state.expireSelected * (1000 * 60 * 60 * 24) : null,
            isActive: state.isActive,
            updateAllRewards: state.updateAllRewards
          });
        }
      });
    });
    return newServer;
  };
}

const simpleAutopilotsUtils = new SimpleAutopilotsUtils();
export default simpleAutopilotsUtils;
