import agendaJobsActions, { IAgendaJobsActions } from './actions';
import agendaJobsReducer from './reducer';
import { AgendaJobDTO, PaginationDTO } from '../types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AgendaJobsStore {
  export type IState = {
    agenda_jobs_list: PaginationDTO<AgendaJobDTO>;
    agenda_jobs_list_pending: boolean;
    agenda_jobs_list_error: string | null;
  };

  export const initialState: IState = {
    agenda_jobs_list: new PaginationDTO(),
    agenda_jobs_list_pending: false,
    agenda_jobs_list_error: null
  };

  export enum ActionTypes {
    GET_AGENDA_JOBS = 'GET_AGENDA_JOBS',
    GET_AGENDA_JOBS_SUCCESS = 'GET_AGENDA_JOBS_SUCCESS',
    GET_AGENDA_JOBS_FAILED = 'GET_AGENDA_JOBS_FAILED',
    CLEAR_AGENDA_JOBS_ERROR = 'CLEAR_AGENDA_JOBS_ERROR'
  }

  export const actions = agendaJobsActions;
  export const reducer = agendaJobsReducer;
  export interface IActions extends IAgendaJobsActions {}
}

export * from './actions';
