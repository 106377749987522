import { Dispatch } from 'redux';
import { push } from 'connected-react-router';

import { IndustriesStore } from './';
import { Server, composedCriteriaBuilder, AlertUtil, msg, logger } from '../../utils';
import { IndustryDTO, PaginationDTO } from '../types';
import { adminRoutes } from '../../modules/layouts/routes';
import { generatePath } from 'react-router-dom';

/*
  IIndustriesActions interface definition, which contains every redux action asociated with Industries State.
*/
export interface IIndustriesActions {
  addIndustryAction(industry: any): any;
  deleteIndustryAction(industryId: string): any;
  editIndustryAction(industry: any): any;
  getIndustryAction(industryId: string): any;
  getIndustriesListAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }): any;
  redirectToEditAction(industryId: string): any;
  changeIndustryStatus(industry: any): any;
  getShortIndustriesListAction(): any;
}

/*
  class IndustriesActions that implements redux actions defined in IIndustriesActions interface
*/
class IndustriesActions implements IIndustriesActions {
  /*
    @function addIndustryAction => Redux action that adds a new industry in both redux and server
      @accepts industry : object containing all the information completed in the add industry form
      @returns Promise
  */
  addIndustryAction(industry: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: IndustriesStore.ActionTypes.SAVE_INDUSTRY
      });
      AlertUtil.simple(
        msg('reduxMessages.industries.addIndustryPending', 'The industry is being created, please wait!'),
        'info'
      );
      logger.msg('Add industry action, route:/industries', 'POST');
      await Server.post('industries', industry)
        .then((response: any) => {
          dispatch({
            type: IndustriesStore.ActionTypes.SAVE_INDUSTRY_SUCCESS,
            payload: response.data as IndustryDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.industries.addIndustrySuccess', 'The industry was successfully created!'),
            'success'
          );
          dispatch(push(adminRoutes.INDUSTRIES.path));
        })
        .catch(error => {
          logger.err('Add industry action, route:/industries', 'POST');
          AlertUtil.updateContent(
            msg('reduxMessages.industries.addIndustryError', 'Due to an error, the industry could not be created!'),
            'error'
          );
          dispatch({
            type: IndustriesStore.ActionTypes.SAVE_INDUSTRY_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function deleteIndustryAction => Redux action that deletes an industry
      @accepts industryId : string representing the id of the industry you want to delete
      @returns Promise
  */
  deleteIndustryAction(industryId: string) {
    AlertUtil.simple(msg('alertMessages.industryBeingDeleted', 'The industry is being deleted...'), 'info');
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: IndustriesStore.ActionTypes.DELETE_INDUSTRY
      });
      logger.msg('Delete industry action, route:/industries/industryId', 'DELETE');
      Server.delete(`industries/${industryId}`)
        .then(async (response: any) => {
          const getIndustriesResponse: any = await Server.get('industries');
          dispatch({
            type: IndustriesStore.ActionTypes.DELETE_INDUSTRY_SUCCESS,
            payload: getIndustriesResponse.data as any
          });
          AlertUtil.updateContent(
            msg('alertMessages.industryDeleted', 'The industry has been successfully deleted!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Delete industry action, route:/industries/industryId', 'DELETE');
          AlertUtil.updateContent(
            msg('reduxMessages.industries.deleteIndustryError', 'Due to an error, the industry could not be deleted!'),
            'error'
          );
          dispatch({
            type: IndustriesStore.ActionTypes.DELETE_INDUSTRY_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function editIndustryAction => Redux action that edits an existing industry from a partner
      @accepts industry : object containing the new information you want to overwrite for an industry
      @returns Promise
  */
  editIndustryAction(industry: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: IndustriesStore.ActionTypes.SAVE_INDUSTRY
      });
      AlertUtil.simple(
        msg('reduxMessages.industries.editIndustryPending', 'The industry is being updated, please wait!'),
        'info'
      );
      logger.msg('Edit industry action, route:/industries/industryId', 'PUT');
      await Server.put(`industries/${industry._id}`, industry)
        .then((response: any) => {
          dispatch({
            type: IndustriesStore.ActionTypes.SAVE_INDUSTRY_SUCCESS,
            payload: response.data as IndustryDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.industries.editIndustrySuccess', 'The industry was successfully updated!'),
            'success'
          );
          dispatch(push(adminRoutes.INDUSTRIES.path));
        })
        .catch(error => {
          logger.err('Edit industry action, route:/industries/industryId', 'PUT');
          AlertUtil.updateContent(
            msg('reduxMessages.industries.editIndustryError', 'Due to an error, the industry could not be updated!'),
            'error'
          );
          dispatch({
            type: IndustriesStore.ActionTypes.SAVE_INDUSTRY_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getIndustryAction => Redux action that gets the information of an existing industry
      @accepts industryId : string containing the id of the industry you want to get information about
      @returns Promise
  */
  getIndustryAction(industryId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: IndustriesStore.ActionTypes.GET_INDUSTRY
      });
      logger.msg('Get industry action, route:/industries?criteria={"_id": industryId}', 'GET');
      Server.get(`industries?criteria={"_id": "${industryId}"}`)
        .then((response: any) => {
          dispatch({
            type: IndustriesStore.ActionTypes.GET_INDUSTRY_SUCCESS,
            payload: response.data.results[0] as IndustryDTO
          });
        })
        .catch(error => {
          logger.err('Get industry action, route:/industries?criteria={"_id": industryId}', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.industries.getIndustryError', 'Due to an error, the industry data could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: IndustriesStore.ActionTypes.GET_INDUSTRY_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getIndustriesListAction => Redux action that gets the list of industries
      (optional) limit, skip, sort, criteria : params for pagination
      @returns Promise
  */
  getIndustriesListAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: IndustriesStore.ActionTypes.GET_INDUSTRIES });
      try {
        if (!limit) {
          limit = 20;
        }
        let url = `industries?limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        } else {
          url += `&sort=name,1`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get industries action, route:/industries', 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: IndustriesStore.ActionTypes.GET_INDUSTRIES_SUCCESS,
          payload: response.data as PaginationDTO<IndustryDTO>
        });
      } catch (error) {
        logger.err('Get industries action, route:/industries', 'GET');
        AlertUtil.simple(
          msg(
            'reduxMessages.industries.getIndustriesError',
            'Due to an error, the industries list could not be loaded!'
          ),
          'error',
          2000
        );
        dispatch({
          type: IndustriesStore.ActionTypes.GET_INDUSTRIES_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  /*
    @function redirectToEditAction => Redux action that redirects user to edit industry form page
      @accepts industryId : string that represents the id of the industry you want to edit
      @returns null
  */
  redirectToEditAction(industryId: string) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(adminRoutes.INDUSTRIES.subroutes.EDIT.path, { industryId });
      dispatch(push(path));
    };
  }

  /*
    @function changeIndustryStatus => Redux action that changes an existing industry status
      @accepts industry : object containing the new information you want to overwrite for an industry
      @returns Promise
  */
  changeIndustryStatus(industry: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: IndustriesStore.ActionTypes.CHANGE_INDUSTRY_STATUS
      });
      AlertUtil.simple(
        msg('reduxMessages.industries.editIndustryPending', 'The industry is being updated, please wait!'),
        'info'
      );
      logger.msg('Activate industry, route:/industries/industryId/active-industry', 'PUT');
      await Server.put(`industries/${industry._id}/active-industry`, industry)
        .then((response: any) => {
          dispatch({
            type: IndustriesStore.ActionTypes.CHANGE_INDUSTRY_STATUS_SUCCESS,
            payload: response.data as IndustryDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.industries.editIndustrySuccess', 'The industry was successfully updated!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Activate industry, route:/industries/industryId/active-industry', 'PUT');
          AlertUtil.updateContent(
            msg('reduxMessages.industries.editIndustryError', 'Due to an error, the industry could not be updated!'),
            'error'
          );
          dispatch({
            type: IndustriesStore.ActionTypes.CHANGE_INDUSTRY_STATUS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getIndustriesAction => Redux action that gets the list of industries, only id and name
      (optional) limit, skip, sort, criteria : params for pagination
      @returns Promise
  */
  getShortIndustriesListAction() {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: IndustriesStore.ActionTypes.GET_INDUSTRIES_SHORT_LIST });
      try {
        logger.msg('Get industries action, route:/info/industries', 'GET');
        const response: any = await Server.get('info/industries?onboarding=true', true);
        dispatch({
          type: IndustriesStore.ActionTypes.GET_INDUSTRIES_SHORT_LIST_SUCCESS,
          payload: response.data
        });
      } catch (error) {
        logger.err('Get industries action, route:/info/industries', 'GET');
        AlertUtil.simple(
          msg(
            'reduxMessages.industries.getIndustriesError',
            'Due to an error, the industries list could not be loaded!'
          ),
          'error',
          2000
        );
        dispatch({
          type: IndustriesStore.ActionTypes.GET_INDUSTRIES_SHORT_LIST_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }
}

const industriesActions = new IndustriesActions();
export default industriesActions;
