import tagsActions, { ITagsActions } from './actions';
import tagsReducer from './reducer';
import { PaginationDTO, TagDTO } from '../types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TagsStore {
  export type IState = {
    tags_list: PaginationDTO<TagDTO>;
    tags_list_pending: boolean;
    tags_list_error: string | null;
    add_tag_pending: boolean;
    add_tag_error: string | null;
    edit_tag_pending: boolean;
    edit_tag_error: string | null;
    delete_tag_pending: boolean;
    delete_tag_error: string | null;
  };

  export const initialState: IState = {
    tags_list: new PaginationDTO(),
    tags_list_pending: false,
    tags_list_error: null,
    add_tag_pending: false,
    add_tag_error: null,
    edit_tag_pending: false,
    edit_tag_error: null,
    delete_tag_pending: false,
    delete_tag_error: null
  };

  export enum ActionTypes {
    GET_TAGS = 'GET_TAGS',
    GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS',
    GET_TAGS_FAILED = 'GET_TAGS_FAILED',
    ADD_TAG = 'ADD_TAG',
    ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS',
    ADD_TAG_FAILED = 'ADD_TAG_FAILED',
    EDIT_TAG = 'EDIT_TAG',
    EDIT_TAG_SUCCESS = 'EDIT_TAG_SUCCESS',
    EDIT_TAG_FAILED = 'EDIT_TAG_FAILED',
    DELETE_TAG = 'DELETE_TAG',
    DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS',
    DELETE_TAG_FAILED = 'DELETE_TAG_FAILED',
    CLEAR_TAGS = 'CLEAR_TAGS'
  }

  export const actions = tagsActions;
  export const reducer = tagsReducer;
  export interface IActions extends ITagsActions {}
}

export * from './actions';
