import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ListPackagesContainer } from './ListPackages';
import { CreatePackageContainer } from './CreatePackage';
import { EditPackageContainer } from './EditPackage';
import { adminRoutes } from '../../layouts';

export const PackagesRouter = () => {
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <Switch>
            <Route path={adminRoutes.PACKAGES.subroutes.ADD.path} render={() => <CreatePackageContainer />} />
            <Route path={adminRoutes.PACKAGES.subroutes.EDIT.path} render={() => <EditPackageContainer />} />
            <Route exact={true} path={adminRoutes.PACKAGES.path} render={({ match }) => <ListPackagesContainer />} />
          </Switch>
        </div>
      </div>
    </div>
  );
};
