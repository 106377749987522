import * as React from 'react';

import { Hidden } from '@material-ui/core';

import { getFormatDate, msg } from '../../../../../utils';
import { CardComponent, ChartComponent } from '../../../../shared';
import { colors } from '../colorsForCharts';

interface IProps {
  agg_statistics_error: any;
  agg_statistics_pending: any;
  agg_statistics: any;
  type: string;
}

const VisitsTab = (props: IProps) => {
  const parseData = (list: any, type: string) => {
    let checkinsByPeriod: any = [];
    let membersByPeriod: any = [];
    let labels: any = [];
    list?.forEach((item: any) => {
      checkinsByPeriod.push(item?.checkins || 0);
      membersByPeriod.push(item?.newMembers || 0);
      let date = getFormatDate(item.startDate, 'date');
      if (props.type !== 'DAY') {
        date += ` - ${getFormatDate(item.endDate, 'date')}`;
      }
      labels.push(date);
    });

    return {
      labels,
      datasets: [
        {
          label: msg('charts.totalCheckins', 'Total Check-ins'),
          backgroundColor: colors.color6Opac02,
          borderColor: colors.color6,
          borderWidth: 1,
          hoverBackgroundColor: colors.color6Opac04,
          hoverBorderColor: colors.color6,
          data: checkinsByPeriod,
          fill: true,
          lineTension: 0.5
        },
        {
          label: msg('charts.totalMembers', 'Total Members'),
          backgroundColor: colors.color1Opac02,
          borderColor: colors.color1,
          borderWidth: 1,
          hoverBackgroundColor: colors.color1Opac04,
          hoverBorderColor: colors.color1,
          data: membersByPeriod,
          fill: true,
          lineTension: 0.5
        }
      ]
    };
  };

  return (
    <div style={{ marginTop: 5 }}>
      <Hidden smDown={true}>
        <CardComponent
          title={msg('charts.checkinsAtEveryPlace', 'Registered check-ins at every place')}
          error={!!props.agg_statistics_error}
          pending={props.agg_statistics_pending}
          headerIcon="compare_arrows"
        >
          <div className="row" style={{ marginBottom: 30 }}>
            <h4 style={{ textAlign: 'center' }}>
              {msg('charts.membersVSCheckins', 'Members vs. Check-ins comparison')}
            </h4>
            <ChartComponent data={parseData(props.agg_statistics, props.type)} chartType="LINE" chartHeight={80} />
          </div>
        </CardComponent>
      </Hidden>
      <Hidden mdUp={true}>
        <CardComponent
          title={msg('charts.checkinsAtEveryPlace', 'Registered check-ins at every place')}
          error={!!props.agg_statistics_error}
          pending={props.agg_statistics_pending}
          headerIcon="compare_arrows"
        >
          <h4 style={{ textAlign: 'center' }}>{msg('charts.availableDesktop', 'Chart available only on desktop')}</h4>
        </CardComponent>
      </Hidden>
    </div>
  );
};

export default VisitsTab;
