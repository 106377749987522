import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  AppStore,
  AuthStore,
  PartnersStore,
  EventsStore,
  PlacesStore,
  MembersStore,
  reduxContainer
} from '../../../appRedux';
import { EventsEditComponent, PlaceEventsListComponent } from './components';
import { PlaceDTO } from '../../../appRedux/types';
import { AuthUtil } from '../../../utils';
import { partnerRoutes } from '../../layouts';

interface IProps extends PartnersStore.IState, EventsStore.IState, PlacesStore.IState, MembersStore.IState {
  events_list_expired: boolean;
  fetchUnavailableMomentsDays: (placeId: PlaceDTO['_id']) => any;
  setEventsListExpiredAction: (expired: boolean) => any;
}
interface IState {}

class EventsContainer extends React.Component<
  IProps & EventsStore.IActions & PlacesStore.IActions & MembersStore.IActions,
  IState
> {
  componentDidMount() {
    if (AuthUtil.isUserOwner() && AuthUtil.isPartnerOwner(this.props.selected_partner)) {
      this.props.getNamesListForPlacesAction(this.props.selected_place || '');
    } else if (AuthUtil.isPartnerAdminForCurrentPlace(this.props.selected_partner) && this.props.selected_partner) {
      this.props.getPlacesForPartnerAction(this.props.selected_partner, 50);
    }
  }

  render() {
    const forOwner = AuthUtil.isUserOwner() && AuthUtil.isPartnerOwner(this.props.selected_partner);
    const forPartner = AuthUtil.isPartnerAdminForCurrentPlace(this.props.selected_partner);
    const placesList = forOwner
      ? this.props.names_of_places_list
      : forPartner
      ? this.props.partner_places_list?.results
      : [];

    const placeEventsEditProps = {
      place: this.props.place,
      pending: this.props.event_save_pending,
      loaded: this.props.event,
      load_pending: this.props.event_pending,
      load_error: this.props.event_error,
      add: this.props.addEventForPlaceAction,
      get: this.props.getEventForPlaceAction,
      edit: this.props.editEventForPlaceAction,
      places_list: placesList,
      members_list: this.props.members_list,
      members_list_pending: this.props.members_list_pending,
      members_list_error: this.props.members_list_error,
      getMembers: this.props.getMembersOnePlaceListAction,
      campaign_members: this.props.campaign_members,
      campaign_members_pending: this.props.campaign_members_pending,
      campaign_members_error: this.props.campaign_members_error,
      getMemberStatsForCampaign: this.props.getMemberStatsForCampaign,
      raffle_preview: this.props.raffle_preview,
      raffle_preview_pending: this.props.raffle_preview_pending,
      update_raffle_preview_pending: this.props.update_raffle_preview_pending,
      update_raffle_preview_error: this.props.update_raffle_preview_error,
      getRafflePreview: this.props.getRafflePreview,
      getRaffleIntervals: this.props.getRaffleIntervals,
      editRaffleIntervals: this.props.editRaffleIntervals,
      clearRaffleIntervals: this.props.clearRaffleIntervals,
      clearEventData: this.props.clearEventData,
      clearEventStatsModalData: this.props.clearEventStatsModalData,
      unavailableMomentsDays: this.props.unavailableMomentsDays,
      fetchUnavailableMomentsDays: this.props.fetchUnavailableMomentsDays
    };

    const placeEventsListProps = {
      place: this.props.place,
      get: this.props.getEventsForPlaceAction,
      list: this.props.events_list,
      pending: this.props.events_list_pending,
      events_list_expired: this.props.events_list_expired,
      save_pending: this.props.event_save_pending,
      error: this.props.events_list_error,
      cloneEventAction: this.props.cloneEventAction,
      redirectEdit: this.props.redirectToEditEventForPlaceAction,
      redirectAdd: this.props.redirectToAddEventForPlaceAction,
      event_change_status_pending: this.props.event_change_status_pending,
      event_delete_pending: this.props.event_delete_pending,
      delete: this.props.deleteEventForPlaceAction,
      editEventStatusAction: this.props.editEventStatusAction,
      clearPlaceEventsError: this.props.clearPlaceEventsError,
      exportInExcel: !!this.props.place?.featureFlags?.exportInExcel,
      export_events_pending: this.props.export_events_pending,
      export_events_error: this.props.export_events_error,
      exportEventsInExcel: this.props.exportEventsInExcel,
      campaign_members: this.props.campaign_members,
      campaign_members_pending: this.props.campaign_members_pending,
      campaign_members_error: this.props.campaign_members_error,
      getMemberStatsForCampaign: this.props.getMemberStatsForCampaign,
      partner_settlements: this.props.partner_settlements,
      partner_settlements_pending: this.props.partner_settlements_pending,
      partner_settlements_error: this.props.partner_settlements_error,
      download_settlements_pending: this.props.download_settlements_pending,
      settlement: this.props.settlement,
      getPartnerSettlements: this.props.getPartnerSettlements,
      sendSettlementEmail: this.props.sendSettlementEmail,
      sendAllSettlementEmails: this.props.sendAllSettlementEmails,
      approveSettlementEmail: this.props.approveSettlementEmail,
      downloadSettlementsPdf: this.props.downloadSettlementsPdf,
      raffle_preview: this.props.raffle_preview,
      raffle_preview_pending: this.props.raffle_preview_pending,
      update_raffle_preview_pending: this.props.update_raffle_preview_pending,
      update_raffle_preview_error: this.props.update_raffle_preview_error,
      getRaffleIntervals: this.props.getRaffleIntervals,
      editRaffleIntervals: this.props.editRaffleIntervals,
      setEventsListExpiredAction: this.props.setEventsListExpiredAction,
      clearRaffleIntervals: this.props.clearRaffleIntervals,
      clearEventStatsModalData: this.props.clearEventStatsModalData
    };

    const partnerId = this.props.selected_partner;
    let enableRoute = true;
    if (this.props.place && !this.props.place_pending && !this.props.place?.featureFlags?.moments) {
      enableRoute = false;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Switch>
              {enableRoute && (
                <Route
                  path={partnerRoutes.EVENTS.subroutes.ADD.path}
                  render={({ match }) => (
                    <EventsEditComponent
                      match={match}
                      editMode={false}
                      config={placeEventsEditProps}
                      partnerId={partnerId}
                      allowMultiplePlaces={forOwner || forPartner}
                    />
                  )}
                />
              )}
              {enableRoute && (
                <Route
                  path={partnerRoutes.EVENTS.subroutes.EDIT.path}
                  render={({ match }) => (
                    <EventsEditComponent
                      match={match}
                      editMode={true}
                      config={placeEventsEditProps}
                      partnerId={partnerId}
                      allowMultiplePlaces={forOwner || forPartner}
                    />
                  )}
                />
              )}
              {enableRoute && (
                <Route
                  exact={true}
                  path={partnerRoutes.EVENTS.default}
                  render={({ match }) => (
                    <PlaceEventsListComponent match={match} config={placeEventsListProps} partnerId={partnerId} />
                  )}
                />
              )}
              <Redirect from="*" to={`/partner/dashboard/${this.props.selected_place}`} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    selected_partner: state.partners.selected_partner,
    selected_place: state.places.selected_place,
    place: state.places.place,
    place_pending: state.places.place_pending,
    events_list: state.events.events_list,
    events_list_pending: state.events.events_list_pending,
    events_list_error: state.events.events_list_error,
    events_list_expired: state.auth.events_list_expired,
    event: state.events.event,
    event_pending: state.events.event_pending,
    event_error: state.events.event_error,
    event_save_pending: state.events.event_save_pending,
    event_delete_pending: state.events.event_delete_pending,
    event_change_status_pending: state.events.event_change_status_pending,
    names_of_places_list: state.places.names_of_places_list,
    partner_places_list: state.places.partner_places_list,
    unavailableMomentsDays: state.places.unavailableMomentsDays,
    export_events_pending: state.events.export_events_pending,
    export_events_error: state.events.export_events_error,
    members_list: state.members.members_list,
    members_list_pending: state.members.members_list_pending,
    members_list_error: state.members.members_list_error,
    campaign_members: state.events.campaign_members,
    campaign_members_pending: state.events.campaign_members_pending,
    campaign_members_error: state.events.campaign_members_error,
    partner_settlements: state.events.partner_settlements,
    partner_settlements_pending: state.events.partner_settlements_pending,
    partner_settlements_error: state.events.partner_settlements_error,
    download_settlements_pending: state.events.download_settlements_pending,
    settlement: state.events.settlement,
    raffle_preview: state.events.raffle_preview,
    raffle_preview_pending: state.events.raffle_preview_pending,
    update_raffle_preview_pending: state.events.update_raffle_preview_pending,
    update_raffle_preview_error: state.events.update_raffle_preview_error
  };
}

const dispatchToProps = {
  getEventsForPlaceAction: EventsStore.actions.getEventsForPlaceAction,
  getEventForPlaceAction: EventsStore.actions.getEventForPlaceAction,
  editEventForPlaceAction: EventsStore.actions.editEventForPlaceAction,
  addEventForPlaceAction: EventsStore.actions.addEventForPlaceAction,
  redirectToEditEventForPlaceAction: EventsStore.actions.redirectToEditEventForPlaceAction,
  redirectToAddEventForPlaceAction: EventsStore.actions.redirectToAddEventForPlaceAction,
  deleteEventForPlaceAction: EventsStore.actions.deleteEventForPlaceAction,
  getPlacesForPartnerAction: PlacesStore.actions.getPlacesForPartnerAction,
  getNamesListForPlacesAction: PlacesStore.actions.getNamesListForPlacesAction,
  editEventStatusAction: EventsStore.actions.editEventStatusAction,
  fetchUnavailableMomentsDays: PlacesStore.actions.fetchUnavailableMomentsDaysAction,
  exportEventsInExcel: EventsStore.actions.exportEventsInExcel,
  clearPlaceEventsError: EventsStore.actions.clearPlaceEventsError,
  getMembersOnePlaceListAction: MembersStore.actions.getMembersOnePlaceListAction,
  getMemberStatsForCampaign: EventsStore.actions.getMemberStatsForCampaign,
  getPartnerSettlements: EventsStore.actions.getPartnerSettlements,
  sendSettlementEmail: EventsStore.actions.sendSettlementEmail,
  sendAllSettlementEmails: EventsStore.actions.sendAllSettlementEmails,
  approveSettlementEmail: EventsStore.actions.approveSettlementEmail,
  downloadSettlementsPdf: EventsStore.actions.downloadSettlementsPdf,
  getRafflePreview: EventsStore.actions.getRafflePreview,
  getRaffleIntervals: EventsStore.actions.getRaffleIntervals,
  editRaffleIntervals: EventsStore.actions.editRaffleIntervals,
  cloneEventAction: EventsStore.actions.cloneEventAction,
  setEventsListExpiredAction: AuthStore.actions.setEventsListExpiredAction,
  clearEventData: EventsStore.actions.clearEventData,
  clearRaffleIntervals: EventsStore.actions.clearRaffleIntervals,
  clearEventStatsModalData: EventsStore.actions.clearEventStatsModalData
};

export default reduxContainer(EventsContainer, mapStateToProps, dispatchToProps);
