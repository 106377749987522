import React, { useState } from 'react';

import { Checkbox } from '@material-ui/core';

import { IPackage } from '../../../appRedux/types';
import { msg, Validator } from '../../../utils';
import { ButtonComponent, TextFieldComponent } from '../../shared';

interface IEditPackageFormProps {
  packageDetails: IPackage;
  onSave: (newPackage: IPackage) => void;
}
const _EditPackageForm = (props: IEditPackageFormProps) => {
  const { packageDetails } = props;
  const [name, setName] = useState(packageDetails.name);
  const [momentsDay, setMomentsDay] = useState(packageDetails.moments.day);
  const [momentsWeek, setMomentsWeek] = useState(packageDetails.moments.week);
  const [momentsMonth, setMomentsMonth] = useState(packageDetails.moments.month);
  const [priority, setPriority] = useState(packageDetails.priority ? packageDetails.priority + '' : '');
  const [comingSoon, setComingSoon] = useState(packageDetails.comingSoon);

  const onSave = () => {
    props.onSave({
      _id: packageDetails._id,
      name,
      moments: {
        day: momentsDay,
        week: momentsWeek,
        month: momentsMonth
      },
      priority: Number(priority),
      comingSoon
    });
  };

  const onMomentsChange = (setter: Function) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newMoments = parseInt(event.currentTarget.value, 10);
    if (!isNaN(newMoments)) {
      setter(newMoments);
    }
  };

  const onNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setName(event.currentTarget.value);
  };

  const onPriorityChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPriority(event.currentTarget.value);
  };

  const onComingSoonChange = (event: any) => {
    setComingSoon(!!event.target.checked);
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-4">
          <TextFieldComponent
            label={msg('package.name', 'Package name')}
            id="name"
            value={name}
            onChange={onNameChange}
            inType="text"
            minLength={1}
            maxLength={50}
            required={true}
            // ref="name"
            validator={[
              {
                type: 'checkIfAlpha',
                msg: msg('formValidation.alphaCharacters', ' Please enter an alphabetical string!'),
                params: name
              },
              { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } }
            ]}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-4 col-sm-4">
          <TextFieldComponent
            label={msg('package.momentsDay', 'Moments per day')}
            id="momentsPerDay"
            value={`${momentsDay}`}
            onChange={onMomentsChange(setMomentsDay)}
            inType="text"
            minLength={1}
            maxLength={50}
            required={true}
            // ref="name"
            validator={[
              {
                type: 'checkIfAlpha',
                msg: msg('formValidation.alphaCharacters', ' Please enter an alphabetical string!'),
                params: name
              },
              { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } }
            ]}
          />
        </div>
        <div className="col-4 col-sm-4">
          <TextFieldComponent
            label={msg('package.momentsWeek', 'Moments per week')}
            id="momentsPerWeek"
            value={`${momentsWeek}`}
            onChange={onMomentsChange(setMomentsWeek)}
            inType="text"
            minLength={1}
            maxLength={50}
            required={true}
            // ref="name"
            validator={[
              {
                type: 'checkIfAlpha',
                msg: msg('formValidation.alphaCharacters', ' Please enter an alphabetical string!'),
                params: name
              },
              { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } }
            ]}
          />
        </div>
        <div className="col-4 col-sm-4">
          <TextFieldComponent
            label={msg('package.momentsMonth', 'Moments per month')}
            id="momentsPerMonth"
            value={`${momentsMonth}`}
            onChange={onMomentsChange(setMomentsMonth)}
            inType="text"
            minLength={1}
            maxLength={50}
            required={true}
            // ref="name"
            validator={[
              {
                type: 'checkIfAlpha',
                msg: msg('formValidation.alphaCharacters', ' Please enter an alphabetical string!'),
                params: name
              },
              { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } }
            ]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <TextFieldComponent
            label={msg('package.priority', 'Package priority')}
            id="priority"
            value={priority}
            onChange={onPriorityChange}
            inType="text"
            minLength={1}
            maxLength={50}
            required={true}
            // ref="priority"
            validator={[
              {
                type: 'checkIfAlpha',
                msg: msg('formValidation.alphaCharacters', ' Please enter an alphabetical string!'),
                params: name
              }
            ]}
          />
        </div>
        <div className="col-sm-4">
          <div
            style={{
              fontSize: 17.5,
              alignSelf: 'center'
            }}
          >
            {msg('package.comingSoon', 'Coming soon')}
          </div>
          <Checkbox checked={comingSoon} onChange={onComingSoonChange} color="primary" />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12 text-center mt-4">
          <ButtonComponent
            label={msg('general.save', 'Save')}
            icon="save"
            action={onSave}
            // pending={props.pending}
          />
        </div>
      </div>
    </>
  );
};

export const EditPackageForm = Validator(React.forwardRef(_EditPackageForm));
