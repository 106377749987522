import payBatchesActions, { IPayBatchesActions } from './actions';
import payBatchesReducer from './reducer';
import { PaginationDTO, PayBatchDTO, PlaceDTO } from '../types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PayBatchesStore {
  export type IState = {
    pay_batches_list: PaginationDTO<PayBatchDTO>;
    pay_batches_list_pending: boolean;
    pay_batches_list_error: string | null;
    create_pay_batch_pending: boolean;
    create_pay_batch_error: string | null;
    download_pay_batch_pending: boolean;
    download_pay_batch_error: string | null;
    mark_pay_batch_sent_pending: boolean;
    mark_pay_batch_sent_error: string | null;
    add_pay_batch_confirmation_pending: boolean;
    add_pay_batch_confirmation_error: string | null;
    send_confirmation_email_pending: boolean;
    send_confirmation_email_error: string | null;
    delete_pay_batch_pending: boolean;
    delete_pay_batch_error: string | null;
    available_places_list: Array<PlaceDTO>;
    available_places_list_pending: boolean;
    available_places_list_error: string | null;
  };

  export const initialState: IState = {
    pay_batches_list: new PaginationDTO(),
    pay_batches_list_pending: false,
    pay_batches_list_error: null,
    create_pay_batch_pending: false,
    create_pay_batch_error: null,
    download_pay_batch_pending: false,
    download_pay_batch_error: null,
    mark_pay_batch_sent_pending: false,
    mark_pay_batch_sent_error: null,
    add_pay_batch_confirmation_pending: false,
    add_pay_batch_confirmation_error: null,
    send_confirmation_email_pending: false,
    send_confirmation_email_error: null,
    delete_pay_batch_pending: false,
    delete_pay_batch_error: null,
    available_places_list: [],
    available_places_list_pending: false,
    available_places_list_error: null
  };

  export enum ActionTypes {
    GET_PAY_BATCHES = 'GET_PAY_BATCHES',
    GET_PAY_BATCHES_SUCCESS = 'GET_PAY_BATCHES_SUCCESS',
    GET_PAY_BATCHES_FAILED = 'GET_PAY_BATCHES_FAILED',
    CREATE_PAY_BATCH = 'CREATE_PAY_BATCH',
    CREATE_PAY_BATCH_SUCCESS = 'CREATE_PAY_BATCH_SUCCESS',
    CREATE_PAY_BATCH_FAILED = 'CREATE_PAY_BATCH_FAILED',
    DOWNLOAD_PAY_BATCH = 'DOWNLOAD_PAY_BATCH',
    DOWNLOAD_PAY_BATCH_SUCCESS = 'DOWNLOAD_PAY_BATCH_SUCCESS',
    DOWNLOAD_PAY_BATCH_FAILED = 'DOWNLOAD_PAY_BATCH_FAILED',
    MARK_PAY_BATCH_SENT = 'MARK_PAY_BATCH_SENT',
    MARK_PAY_BATCH_SENT_SUCCESS = 'MARK_PAY_BATCH_SENT_SUCCESS',
    MARK_PAY_BATCH_SENT_FAILED = 'MARK_PAY_BATCH_SENT_FAILED',
    ADD_PAY_BATCH_CONFIRMATION = 'ADD_PAY_BATCH_CONFIRMATION',
    ADD_PAY_BATCH_CONFIRMATION_SUCCESS = 'ADD_PAY_BATCH_CONFIRMATION_SUCCESS',
    ADD_PAY_BATCH_CONFIRMATION_FAILED = 'ADD_PAY_BATCH_CONFIRMATION_FAILED',
    SEND_CONFIRMATION_EMAIL = 'SEND_CONFIRMATION_EMAIL',
    SEND_CONFIRMATION_EMAIL_SUCCESS = 'SEND_CONFIRMATION_EMAIL_SUCCESS',
    SEND_CONFIRMATION_EMAIL_FAILED = 'SEND_CONFIRMATION_EMAIL_FAILED',
    DELETE_PAY_BATCH = 'DELETE_PAY_BATCH',
    DELETE_PAY_BATCH_SUCCESS = 'DELETE_PAY_BATCH_SUCCESS',
    DELETE_PAY_BATCH_FAILED = 'DELETE_PAY_BATCH_FAILED',
    GET_AVAILABLE_PLACES = 'GET_AVAILABLE_PLACES',
    GET_AVAILABLE_PLACES_SUCCESS = 'GET_AVAILABLE_PLACES_SUCCESS',
    GET_AVAILABLE_PLACES_FAILED = 'GET_AVAILABLE_PLACES_FAILED',
    CLEAR_PAY_BATCHES_ERROR = 'CLEAR_PAY_BATCHES_ERROR'
  }

  export const actions = payBatchesActions;
  export const reducer = payBatchesReducer;
  export interface IActions extends IPayBatchesActions {}
}

export * from './actions';
