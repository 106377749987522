import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Bounce } from 'react-activity';
import { partnerRoutes } from '.';

import {
  SidebarComponent,
  TopbarComponent,
  FooterComponent,
  PartnerDashboardContainer,
  MembersContainer,
  RewardsContainer,
  PlacesContainer,
  PlaceAdminsContainer,
  AutopilotContainer,
  EventsContainer,
  ProfileContainer,
  ActivityRouter,
  ReviewsContainer,
  ReceiptsContainer,
  PartnerEmailRouter,
  FeedbackRouter,
  TransactionsRouter,
  TagsRouter
} from '../components';
import SimpleAutopilotsContainer from '../components/simpleAutopilots/SimpleAutopilotsContainer';
import {
  AppStore,
  AuthStore,
  PlacesStore,
  PartnersStore,
  TranslationsStore,
  reduxContainer
} from '../../appRedux/index';
import AuthUtil from '../../utils/authentication';
import { ExportModalComponent, ScrollToTopComponent } from '../shared';
import { msg } from '../../utils';
import { colors } from '../../assets/styles';

interface IProps extends AuthStore.IState, PlacesStore.IState, TranslationsStore.IState, PartnersStore.IState {}
interface IState {
  showExportModal: boolean;
}

class PartnerLayoutContainer extends React.Component<
  IProps & AuthStore.IActions & PlacesStore.IActions & TranslationsStore.IActions & PartnersStore.IActions,
  IState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showExportModal: false
    };
  }

  componentDidMount() {
    let partnerId: any = this.props.selected_partner;
    let placeId: any = this.props.selected_place;
    this.props.getPartnerAction(partnerId);
    this.props.getPlaceAction(placeId);
  }

  toggleSideBar = () => {
    let html = document.getElementsByTagName('html');
    let openSideBar = document.getElementById('openSideBar');
    if (!html[0].className.includes('nav-open')) {
      openSideBar?.classList.add('toggled');
      html[0].classList.add('nav-open');
    } else {
      openSideBar?.classList.remove('toggled');
      html[0].classList.remove('nav-open');
    }
  };

  toggleExportModal = () => {
    if (this.props.place?.featureFlags?.exportInExcel) {
      const show = this.state.showExportModal;
      this.setState({ showExportModal: !show });
    }
  };

  exportInExcel = (startDate: number, endDate: number) => {
    const partnerId = this.props.selected_partner || '';
    this.props.exportPartnersInExcel(partnerId, startDate, endDate);
  };

  render() {
    const sidebarConfig = {
      title: 'Partner',
      user: this.props.user,
      showAutopilots: false,
      place: this.props.place,
      origin: this.props.origin
    };
    const sidebarActions = {
      logOut: this.props.logoutAction,
      selected_place: this.props.selected_place,
      isUserOwner: AuthUtil.isUserOwner(),
      isSupport: AuthUtil.isSupport(),
      isPartnerOwner: AuthUtil.isPartnerOwner(this.props.selected_partner),
      toggleSideBar: this.toggleSideBar,
      toggleExportModal: this.toggleExportModal
    };
    const topbarActions = {
      partners: AuthUtil.getPartnersForUser(),
      places: AuthUtil.getPlacesForUser(),
      selected_place: this.props.selected_place,
      selected_partner: this.props.selected_partner,
      initial_language: this.props.initial_language,
      selectPlace: this.props.selectPlaceTopbar,
      selectPartner: this.props.selectPartnerLogin,
      changeLanguage: this.props.changeLanguageAction,
      toggleSideBar: this.toggleSideBar
    };
    const exportModalConfig = {
      title: msg('exportExcel.exportExcelPartners', 'Partner Reports'),
      pending: this.props.export_partners_pending,
      error: this.props.export_partners_error,
      toggleModal: this.toggleExportModal,
      exportInExcel: this.exportInExcel
    };
    return this.props.partner_pending && !this.props.place ? (
      <div className="w-screen h-screen flex items-center justify-center">
        <Bounce color={colors.lightGreen} />
      </div>
    ) : this.props.selected_partner && this.props.selected_place ? (
      <div className="App">
        <div className="wrapper">
          <SidebarComponent config={sidebarConfig} actions={sidebarActions} />
          <div className="main-panel">
            <TopbarComponent actions={topbarActions} />
            <ScrollToTopComponent />
            <div className="content">
              <div className="container-fluid">
                <Switch>
                  <Redirect exact={true} from="/partner" to={partnerRoutes.DASHBOARD.path} />
                  <Route path={partnerRoutes.DASHBOARD.path} component={PartnerDashboardContainer} />
                  <Route path={partnerRoutes.MEMBERS.path} component={MembersContainer} />
                  <Route path={partnerRoutes.ACTIVITY.path} component={ActivityRouter} />
                  <Route path={partnerRoutes.REWARDS.path} component={RewardsContainer} />
                  <Route path={partnerRoutes.REVIEWS.path} component={ReviewsContainer} />
                  <Route path={partnerRoutes.FEEDBACK.path} component={FeedbackRouter} />
                  <Route path={partnerRoutes.PLACE_ADMINS.path} component={PlaceAdminsContainer} />
                  <Route path={partnerRoutes.PLACE.path} component={PlacesContainer} />
                  <Route path={partnerRoutes.AUTOPILOT.path} component={AutopilotContainer} />
                  <Route path={partnerRoutes.SIMPLE_AUTOPILOTS.path} component={SimpleAutopilotsContainer} />
                  <Route path={partnerRoutes.EVENTS.path} component={EventsContainer} />
                  <Route path={partnerRoutes.PROFILE.path} component={ProfileContainer} />
                  <Route path={partnerRoutes.RECEIPTS.path} component={ReceiptsContainer} />
                  <Route path={partnerRoutes.EMAILS.path} component={PartnerEmailRouter} />
                  <Route path={partnerRoutes.TRANSACTIONS.path} component={TransactionsRouter} />
                  <Route path={partnerRoutes.TAGS.path} component={TagsRouter} />
                  <Redirect from="*" to={partnerRoutes.DASHBOARD.path} />
                </Switch>
                {this.state.showExportModal && <ExportModalComponent config={exportModalConfig} />}
              </div>
            </div>
            <FooterComponent />
          </div>
        </div>
      </div>
    ) : (
      <Redirect to="/auth/choose-partner" />
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    user: state.auth.user,
    place: state.places.place,
    selected_place: state.places.selected_place,
    initial_language: state.translations.initial_language,
    selected_partner: state.partners.selected_partner,
    partner_pending: state.partners.partner_pending,
    export_partners_pending: state.partners.export_partners_pending,
    export_partners_error: state.partners.export_partners_error,
    origin: state.auth.origin
  };
}

const dispatchToProps = {
  logoutAction: AuthStore.actions.logoutAction,
  getPlaceAction: PlacesStore.actions.getPlaceAction,
  selectPlaceTopbar: PlacesStore.actions.selectPlaceTopbar,
  selectPartnerLogin: PartnersStore.actions.selectPartnerLogin,
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction,
  getPartnerAction: PartnersStore.actions.getPartnerAction,
  exportPartnersInExcel: PartnersStore.actions.exportPartnersInExcel
};

export default reduxContainer(PartnerLayoutContainer, mapStateToProps, dispatchToProps);
