export class UserDTO {
  _id: string = '';
  firstname: string = '';
  lastname: string = '';
  email: string = '';
  phone?: string | null;
  password?: string;
  birthday: any;
  created?: string;
  deviceInfo?: UserDeviceDTO;
  media?: {
    id: string;
    url: string;
  }[];
  emailStatus?: string;
  phoneStatus?: string;
  malvenskyHash?: string;
  malvenskySalt?: string;
  referralCode?: string;
  referralsNo?: number;
  referrerCodeReceived?: boolean;
  registerType?: UserRegisterType;
  settings?: {
    language: string;
    permissions: PermissionsTypeDTO;
  };
  updatedAt?: string;
  placeAdmin?: boolean;
  partnerAdmin?: boolean;
  tags?: UserTagDTO[];
}

export class UserDeviceDTO {
  appVersion: string;
  buildNumber?: string;
  appServer: string;
  codePushVersion?: string;
  systemName: string;
  systemVersion: string;
  deviceModel: string;
  lastModified?: number;
}

export enum UserRegisterType {
  DEV,
  MOBILE,
  DASHBOARD,
  ONBOARDING
}

class PermissionsTypeDTO {
  camera: {
    status: string;
    timestamp: string;
    retry: boolean;
  };
  location: {
    status: string;
    timestamp: string;
    retry: boolean;
  };
  notifications: {
    status: string;
    timestamp: string;
    retry: boolean;
  };
  photos: {
    status: string;
    timestamp: string;
    retry: boolean;
  };
  bluetooth: {
    status: string;
    timestamp: string;
    retry: boolean;
  };
}

// tslint:disable-next-line:interface-name
export interface UserTagDTO {
  _id: string;
  name: string;
  color?: string;
  priority?: number;
  expires?: Date;
  updated?: Date;
}
