import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { PaginationDTO } from '../../../../../appRedux/types';
import { msg } from '../../../../../utils';
import { AdvancedListComponent } from '../../../../shared';

interface IProps {
  members: PaginationDTO<any>;
  eventMembers: string[];
  pending: boolean;
  error: string | null;
  placeId: string;
  getMembers: (placeId: string, limit?: number, skip?: number, sort?: string, criteria?: any) => any;
  setCustomMembers: (members: string[]) => any;
  toggleModal: () => any;
}

const SelectMembersModal = (props: IProps) => {
  const [selectedMembers, setSelectedMembers] = useState<string[]>();
  const [memberList, setMemberList] = useState<PaginationDTO<any>>(new PaginationDTO());

  useEffect(() => {
    if (props.members) {
      setMemberList(parseMembers(props.members));
    }
    // tslint:disable-next-line: align
  }, [props.members]);

  useEffect(() => {
    if (props.eventMembers.length) {
      setSelectedMembers(props.eventMembers);
    }
    // tslint:disable-next-line: align
  }, [props.eventMembers]);

  const getMembers = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    props.getMembers(props.placeId, limit, skip, sort, criteria);
  };

  const parseMembers = (list: PaginationDTO<any>): any => {
    if (!props.members?.results) {
      return props.members;
    }
    const results: any[] = [];
    if (list.results) {
      list.results.forEach(item => {
        const foundMember = selectedMembers?.find(existingMember => item._id === existingMember);
        results.push({
          _id: item._id,
          checked: !!foundMember,
          user: {
            lastname: item.user?.lastname + ' ' + item.user?.firstname,
            phone: item && item.user?.phone ? item.user?.phone : 'N/A',
            email: item && item.user?.email
          }
        });
      });
    }
    return { ...list, results };
  };

  const checkIfOneSelected = () => {
    const oneSelected = memberList.results?.find(item => selectedMembers?.find(itemId => item._id === itemId));
    return !!oneSelected;
  };

  const checkIfAllSelected = () => {
    const alreadySelectedOnPage =
      memberList.results?.filter(item => selectedMembers?.find(itemId => item._id === itemId)) || [];
    return !!selectedMembers?.length && alreadySelectedOnPage.length === memberList.results?.length;
  };

  const selectAllCurrentPage = () => {
    const alreadySelectedOnPage =
      memberList.results?.filter(item => selectedMembers?.find(itemId => item._id === itemId)) || [];
    const newMemberList = memberList.results?.map(item => {
      if (alreadySelectedOnPage.length && alreadySelectedOnPage?.find(selectedItem => item._id === selectedItem._id)) {
        item.checked = false;
      } else if (!alreadySelectedOnPage.length) {
        item.checked = true;
      }
      return item;
    });
    setMemberList({ ...memberList, results: newMemberList });
    if (selectedMembers?.length && alreadySelectedOnPage?.length) {
      const newSelectedMembers =
        selectedMembers?.filter(itemId => !newMemberList.find(member => itemId === member._id && !member.checked)) ||
        [];
      setSelectedMembers(newSelectedMembers);
    } else if (selectedMembers?.length) {
      const newSelectedMembers = selectedMembers.concat(newMemberList.map(member => member._id));
      setSelectedMembers(newSelectedMembers);
    } else {
      const newSelectedMembers = newMemberList.map(member => member._id);
      setSelectedMembers(newSelectedMembers);
    }
  };

  const onSelectItem = (itemId: string) => {
    const memberResults = memberList.results.map(item => {
      if (item._id === itemId) {
        const foundMember = selectedMembers?.find(existingMember => item._id === existingMember);
        item.checked = !foundMember;
        if (item.checked) {
          const membersSelected = selectedMembers || [];
          membersSelected?.push(item._id);
          setSelectedMembers(membersSelected);
        } else {
          const membersSelected = selectedMembers?.filter(memberId => memberId !== itemId);
          setSelectedMembers(membersSelected);
        }
      }
      return item;
    });
    setMemberList({ ...memberList, results: memberResults });
  };

  const setMembers = () => {
    props.setCustomMembers(selectedMembers || []);
    props.toggleModal();
  };

  return (
    <Modal show={true} onHide={props.toggleModal} dialogClassName="customModal" style={{ overflowY: 'auto' }}>
      <Modal.Header>
        <Modal.Title>{msg('event.selectMembers', 'Select Members')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: '60vh', overflowY: 'auto' }}>
          <AdvancedListComponent
            fields={{
              'user.lastname': msg('members.name', 'Name'),
              'user.phone': msg('members.phone', 'Phone'),
              'user.email': msg('members.email', 'Email')
            }}
            list={memberList}
            pending={props.pending}
            error={props.error}
            get={getMembers}
            limit={10}
            sort={{
              fields: ['user.lastname', 'user.email'],
              default: 'user.lastname,1'
            }}
            search={['user.lastname', 'user.firstname', 'user.phone', 'user.email']}
            checkbox={{
              selectedAll: checkIfAllSelected(),
              intermediate: checkIfOneSelected() && !checkIfAllSelected(),
              field: 'checked',
              onChange: onSelectItem,
              onSelectAll: selectAllCurrentPage
            }}
            tableClassName={'selectMembersTable'}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-danger" onClick={props.toggleModal}>
          {msg('general.close', 'Close')}
        </Button>
        <Button className="btn btn-success" onClick={setMembers}>
          {msg('event.done', 'Done')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectMembersModal;
