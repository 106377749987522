import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { adminRoutes } from '../../layouts';
import PaySettlementsListContainer from './PaySettlementsListContainer';
import PayBatchesListContainer from './PayBatchesListContainer';

const PayBatchesRouter = () => {
  return (
    <Switch>
      <Route
        exact={true}
        path={adminRoutes.BATCHES.subroutes.SETTLEMENTS.path}
        render={() => <PaySettlementsListContainer />}
      />
      <Route exact={true} path={adminRoutes.BATCHES.path} render={() => <PayBatchesListContainer />} />
    </Switch>
  );
};

export default PayBatchesRouter;
