import * as React from 'react';
import { DateTimePicker, DatePicker } from '@material-ui/pickers';
import moment from 'moment';

interface IProps {
  date: any;
  label: any;
  getSelectedDate: (date: any) => any;
  disablePast?: boolean;
  minDate?: any;
  disableFuture?: boolean;
  formatError?: any;
  validator?: any;
  shouldDisableDate?: (date: any) => any;
  maxDate?: any;
  disabled?: boolean;
  dateOnly?: boolean;
}

interface IState {
  date: any;
}
export class DateTimePickerComponent extends React.PureComponent<IProps, IState> {
  public state = {
    date: this.props.date ? moment(this.props.date).startOf('minute') : moment().startOf('minute')
  };

  componentDidUpdate(prevProps: IProps) {
    if (this.props.date && this.props.date !== prevProps.date) {
      this.setState({
        date: moment(this.props.date).startOf(this.props.dateOnly ? 'day' : 'minute')
      });
    }
  }

  public getDateRange = (date: any) => {
    this.props.getSelectedDate(date);
  };

  public render() {
    return (
      <div className="col-sm-12" style={{ padding: 0 }}>
        {this.props.dateOnly ? (
          <DatePicker
            disabled={this.props.disabled}
            value={this.state.date}
            onChange={(date: any) => {
              this.handleDateChange(date);
              this.getDateRange(date);
            }}
            label={this.props.label}
            shouldDisableDate={this.props.shouldDisableDate}
            disablePast={this.props.disablePast}
            disableFuture={this.props.disableFuture}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            format="DD.MM.YYYY"
            error={this.props.formatError ? true : false}
            helperText={this.props.formatError}
            fullWidth={true}
          />
        ) : (
          <DateTimePicker
            ampm={false}
            disabled={this.props.disabled}
            value={this.state.date}
            onChange={(date: any) => {
              this.handleDateChange(date);
              this.getDateRange(date);
            }}
            label={this.props.label}
            shouldDisableDate={this.props.shouldDisableDate}
            disablePast={this.props.disablePast}
            disableFuture={this.props.disableFuture}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            format="DD.MM.YYYY HH:mm"
            error={this.props.formatError ? true : false}
            helperText={this.props.formatError}
            fullWidth={true}
          />
        )}
      </div>
    );
  }

  private handleDateChange = (date: Date) => this.setState({ date });
}
