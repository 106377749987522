import { ReceiptType } from '../types';

export enum RewardType {
  DISCOUNT,
  PRODUCT,
  VOUCHER
}
export enum MemberStatus {
  MEMBER_INACTIV,
  MEMBER_NEW,
  MEMBER_REGULAR,
  MEMBER_VIP,
  MEMBER_FLAGGED
}
export enum EventChannel {
  SMS,
  NOTIFICATION,
  EMAIL
}

export interface IActivityEvent {
  date: string;
  eventType: string;
}

interface IUserActivity {
  userId: string;
  memberId: string;
  placeId: string;
  placeName: string;
  placeLogo: string;
  forMobile: boolean;
  read: boolean;
  priority: number;
  expirationDate?: number;
  isActive?: boolean; // for popups
  hidden?: boolean; // for history
}
interface IAutopilotActivity {
  autopilotId: string;
  autopilotTitle: string;
  triggerId: string;
  checkinId?: string;
  eventId?: string;
  eventTitle?: string;
}

export interface IAutopilotRewardGivenEvent extends IActivityEvent, IUserActivity, IAutopilotActivity {
  eventType: 'AUTOPILOT_ACTION_REWARD_GIVEN';
  rewardType: RewardType;
  rewardPoints: number;
  rewardTitle: string;
  media: string;
  message: string;
}
export interface IAutopilotPointsGivenEvent extends IActivityEvent, IUserActivity, IAutopilotActivity {
  eventType: 'AUTOPILOT_ACTION_POINTS_GIVEN';
  points: number;
  message: string;
}
export interface IAutopilotMemberStatusChangedEvent extends IActivityEvent, IAutopilotActivity {
  eventType: 'AUTOPILOT_ACTION_MEMBER_STATUS_CHANGED';
  memberId: string;
  userId: string;
  placeId: string;
  memberStatus: MemberStatus;
}
export interface IAutopilotMessageSentEvent extends IActivityEvent, IAutopilotActivity {
  eventType: 'AUTOPILOT_ACTION_MESSAGE_SENT';
  memberId: string;
  userId: string;
  placeId: string;
  message: string;
  channels: EventChannel[];
}
export interface IAutopilotCustomEvent extends IActivityEvent, IAutopilotActivity {
  eventType: 'AUTOPILOT_ACTION_MESSAGE_SENT';
  memberId: string;
  placeId: string;
  action: any;
}

export interface IMembershipCreatedEvent extends IActivityEvent {
  eventType: 'MEMBERSHIP_CREATED';
  userId: string;
  placeId: string;
  placeName: string;
  points: number;
}
export interface IMembershipCancelledEvent extends IActivityEvent {
  eventType: 'MEMBERSHIP_CANCELED';
  userId: string;
  placeId: string;
  placeName: string;
  points: number;
}
export interface IMembershipRestoredEvent extends IActivityEvent {
  eventType: 'MEMBERSHIP_RESTORED';
  userId: string;
  placeId: string;
  placeName: string;
  points: number;
}

export interface ICheckinCreatedEvent extends IActivityEvent, IUserActivity {
  eventType: 'CHECKIN_CREATED';
  checkinId: string;
  memberId: string;
  points: number;
  type: string;
  confirmationNo: string;
}
export interface ICheckinRewardBoughtEvent extends IActivityEvent, IUserActivity {
  eventType: 'CHECKIN_REWARD_BOUGHT';
  checkinId: string;
  reward: string;
  media: string;
  price: number;
}
export interface ICheckinBonusRedeemedEvent extends IActivityEvent, IUserActivity, IAutopilotActivity {
  eventType: 'CHECKIN_BONUS_REDEEMED';
  bonus: string;
  rewardType?: number;
  amount: number;
  usedAmount: number;
  requestedAmount?: number;
  media: string;
}
export interface ICheckinPartialRedeemEvent extends IActivityEvent, IUserActivity, IAutopilotActivity {
  eventType: 'CHECKIN_PARTIAL_REDEEM';
  voucherTitle: string;
  amount: number;
  requestedAmount: number;
  usedAmount: number;
  media: string;
}
export interface ICheckinCoinsAllocatedEvent extends IActivityEvent, IUserActivity {
  eventType: 'CHECKIN_COINS_ALLOCATED';
  checkinId: string;
  coins: number;
}
export interface IReceiptCoinsAllocatedEvent extends IActivityEvent, IUserActivity {
  eventType: 'RECEIPT_COINS_ALLOCATED';
  checkinId: string;
  receiptId: string;
  coins: number;
  receiptAmount: number;
  receiptType: ReceiptType;
}
export interface IReceiptApprovedEvent extends IActivityEvent, IUserActivity {
  eventType: 'RECEIPT_APPROVED';
  checkinId: string;
  receiptId: string;
  receiptAmount: number;
  receiptType: ReceiptType;
}
export interface IErrorEvent extends IActivityEvent, IUserActivity {
  eventType: 'ERROR';
  code: string;
  message: string;
}
export interface IAutopilotTriggerCreated extends IActivityEvent, IAutopilotActivity {
  eventType: 'AUTOPILOT_TRIGGER_CREATED';
  memberId: string;
  placeId: string;
  action: string;
}
export interface ICheckoutEvent extends IActivityEvent {
  eventType: 'CHECKOUT';
  userId: string;
  placeId: string;
  placeName: string;
  placeLogo: string;
  checkinId: string;
  memberId: string;
}
export interface IReviewCoinsAllocatedEvent extends IActivityEvent, IUserActivity {
  eventType: 'REVIEW_COINS_ALLOCATED';
  reviewId: string;
  coins: number;
  message: string;
}
export interface IPaymentCompletedEvent extends IActivityEvent, IUserActivity {
  eventType: 'PAYMENT_COMPLETED';
  checkinId: string;
  transactionId: string;
  requestedAmount: number;
  amount: number;
  tipsAmount?: number;
  usedCoins: number;
}
export interface IPaymentRefundedEvent extends IActivityEvent, IUserActivity {
  eventType: 'PAYMENT_REFUNDED';
  checkinId: string;
  transactionId: string;
  requestedAmount: number;
  amount: number;
  tipsAmount?: number;
  usedCoins: number;
}
export interface IPaymentCoinsAllocatedEvent extends IActivityEvent, IUserActivity {
  eventType: 'PAYMENT_COINS_ALLOCATED';
  checkinId: string;
  transactionId: string;
  coins: number;
}
export interface IPaymentCoinsRefundedEvent extends IActivityEvent, IUserActivity {
  eventType: 'PAYMENT_COINS_REFUNDED';
  checkinId: string;
  transactionId: string;
  coins: number;
}
export interface IUserTagAddedEvent extends IActivityEvent {
  eventType: 'USER_TAG_ADDED';
  userId: string;
  tagId: string;
  tagName: string;
}

export interface IUserTagRemovedEvent extends IActivityEvent {
  eventType: 'USER_TAG_REMOVED';
  userId: string;
  tagId: string;
  tagName: string;
}

export interface IMemberTagAddedEvent extends IActivityEvent, IUserActivity {
  eventType: 'MEMBER_TAG_ADDED';
  tagId: string;
  tagName: string;
  tagColor: string;
}
export interface IMemberTagRemovedEvent extends IActivityEvent, IUserActivity {
  eventType: 'MEMBER_TAG_REMOVED';
  tagId: string;
  tagName: string;
}

export type IAutopilotEvents =
  | IAutopilotRewardGivenEvent
  | IAutopilotPointsGivenEvent
  | IAutopilotMemberStatusChangedEvent
  | IUserTagAddedEvent
  | IAutopilotMessageSentEvent
  | IAutopilotCustomEvent;

export type IMembershipEvents = IMembershipCreatedEvent | IMembershipCancelledEvent | IMembershipRestoredEvent;

export type ICheckinEvents =
  | ICheckinCreatedEvent
  | ICheckinRewardBoughtEvent
  | ICheckinBonusRedeemedEvent
  | ICheckinPartialRedeemEvent
  | ICheckinCoinsAllocatedEvent
  | IReceiptCoinsAllocatedEvent
  | IReviewCoinsAllocatedEvent
  | IPaymentCompletedEvent
  | IPaymentRefundedEvent
  | IPaymentCoinsAllocatedEvent
  | IPaymentCoinsRefundedEvent;
