import moment from 'moment';
import React from 'react';

import { IndustryDTO } from '../../../../../appRedux/types';
import photoPlaceholder from '../../../../../assets/images/coverPlaceholder.jpg';
import { msg } from '../../../../../utils';
import { UploadComponent } from '../../../../shared';
import styles from '../../../../shared/photoStyles/Photos.module.css';

interface IProps {
  industry?: IndustryDTO | null;
  get: (industryId: string) => any;
}
interface IState {
  industry: IndustryDTO;
  industryPhoto: any;
}

export default class IndustryPhotoComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      industry: props.industry || new IndustryDTO(),
      industryPhoto: (props.industry && this.getPhoto(props.industry)) || {}
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.industry && this.props.industry !== prevProps.industry) {
      this.setState({ industry: this.props.industry });
    }
  }

  getPhoto = (industry: any) => {
    if (industry && industry.media) {
      const image = industry.media.sort((a: any, b: any) => {
        if (moment(a.createdAt).valueOf() < moment(b.createdAt).valueOf()) {
          return 1;
        } else {
          return -1;
        }
      });
      return image[0];
    } else {
      return {};
    }
  };

  render() {
    const { industry } = this.state;
    return (
      <div>
        <div className={styles.images + ' flex items-stretch'}>
          <div className="flex-2">
            <div className="row text-center">
              <legend>{msg('industry.photo', 'Industry Photo')}</legend>
            </div>
            <div className={styles.logo}>
              {this.props.industry &&
              this.props.industry.media.length > 0 &&
              this.state.industryPhoto &&
              this.state.industryPhoto.url ? (
                <img alt="" className={styles.img} src={this.state.industryPhoto.url} />
              ) : (
                <img alt="" className={styles.img} src={photoPlaceholder} />
              )}
            </div>
            <div className="flex justify-center">
              <div className="w-5/6">
                <UploadComponent
                  url={`industries/${industry._id}/media`}
                  accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
                  extraData={{
                    industryId: industry._id
                  }}
                  messages={{
                    default: msg('general.uploadImage', 'Upload images by drag-and-drop or click!')
                  }}
                  onSuccess={() => this.props.get(industry._id)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
