import activityActions, { IActivityActionCreators } from './actions';
import activityReducer from './reducer';
import { PaginationDTO } from '../types';
import { IFetchPlaceActivityResponse, IFetchUserActivityResponse, IFetchCheckinActivityResponse } from './api';

export namespace ActivityStore {
  export type IState = {
    place_activity_list: IFetchPlaceActivityResponse;
    place_activity_list_pending: boolean;
    place_activity_list_error: string | null;
    selected_checkin: string | null;
    checkin_activity_list: IFetchCheckinActivityResponse;
    checkin_activity_list_pending: boolean;
    checkin_activity_list_error: string | null;
    user_activity_list: IFetchUserActivityResponse;
    user_activity_list_pending: boolean;
    user_activity_list_error: string | null;
  };

  export const initialState: IState = {
    place_activity_list: new PaginationDTO() as IFetchPlaceActivityResponse,
    place_activity_list_pending: false,
    place_activity_list_error: null,
    selected_checkin: null,
    checkin_activity_list: new PaginationDTO() as IFetchCheckinActivityResponse,
    checkin_activity_list_pending: false,
    checkin_activity_list_error: null,
    user_activity_list: new PaginationDTO() as IFetchUserActivityResponse,
    user_activity_list_pending: false,
    user_activity_list_error: null
  };

  export enum ActionTypes {
    GET_PLACE_ACTIVITY = 'GET_PLACE_ACTIVITY',
    GET_PLACE_ACTIVITY_SUCCESS = 'GET_PLACE_ACTIVITY_SUCCESS',
    GET_PLACE_ACTIVITY_FAILED = 'GET_PLACE_ACTIVITY_FAILED',
    GET_CHECKIN_ACTIVITY = 'GET_CHECKIN_ACTIVITY',
    GET_CHECKIN_ACTIVITY_SUCCESS = 'GET_CHECKIN_ACTIVITY_SUCCESS',
    GET_CHECKIN_ACTIVITY_FAILED = 'GET_CHECKIN_ACTIVITY_FAILED',
    OPEN_CHECKIN_ACTIVITY = 'OPEN_CHECKIN_ACTIVITY',
    CLOSE_CHECKIN_ACTIVITY = 'CLOSE_CHECKIN_ACTIVITY',
    GET_USER_ACTIVITY = 'GET_USER_ACTIVITY',
    GET_USER_ACTIVITY_SUCCESS = 'GET_USER_ACTIVITY_SUCCESS',
    GET_USER_ACTIVITY_FAILED = 'GET_USER_ACTIVITY_FAILED',
    CLEAR_ACTIVITY_ERROR = 'CLEAR_ACTIVITY_ERROR'
  }

  export const actions = activityActions;
  export const reducer = activityReducer;
  export interface IActions extends IActivityActionCreators {}
}

export * from './actions';
export * from './activity.types';
