import Lodash from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import { AutopilotLogsStore, AutopilotStore } from '../../../appRedux';
import { msg } from '../../../utils';
import { CardComponent, InfiniteScrollListComponent } from '../../shared';

import { mapStateToProps } from './autopilot-logs.mapper';

const mapDispatchToProps = {
  FilterData: AutopilotLogsStore.actions.FilterAutopilotLogsData,
  runAutopilotVerification: AutopilotStore.actions.runAutopilotVerification
};

type TAutopilotLogsComponent = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export const AutopilotLogsComponent: React.FC<TAutopilotLogsComponent> = ({
  FilterData,
  runAutopilotVerification,
  list,
  error,
  loading,
  pagination
}) => {
  const fields = {
    date: msg('activity.dateTime', 'Date'),
    type: msg('event.type', 'Event type'),
    autopilot: msg('autopilot.title', 'Autopilot title'),
    place: msg('place.name', 'Place name'),
    user: msg('members.name', 'Member name'),
    reward: msg('activity.action', 'Action')
  };
  const filter = [
    {
      field: 'eventType',
      value: [
        { _id: '', name: msg('general.all', 'All') },
        {
          _id: AutopilotLogsStore.autopilotEvents.VerificationStart,
          name: msg('autopilotLogs.verificationStart', 'Verification start')
        },
        {
          _id: AutopilotLogsStore.autopilotEvents.VerificationEnd,
          name: msg('autopilotLogs.verificationEnd', 'Verification End')
        },
        {
          _id: AutopilotLogsStore.autopilotEvents.PromotionStart,
          name: msg('autopilotLogs.promotionStart', 'Promotion verification start')
        },
        {
          _id: AutopilotLogsStore.autopilotEvents.PromotionEnd,
          name: msg('autopilotLogs.promotionEnd', 'Promotion verification end')
        },
        {
          _id: AutopilotLogsStore.autopilotEvents.TriggerCreated,
          name: msg('autopilotLogs.triggerCreated', 'Trigger Created')
        },
        {
          _id: AutopilotLogsStore.autopilotEvents.RewardGiven,
          name: msg('activity.events.rewardGiven', 'Reward given')
        },
        {
          _id: AutopilotLogsStore.autopilotEvents.PointsGiven,
          name: msg('activity.events.pointsGiven', 'Points given')
        },
        {
          _id: AutopilotLogsStore.autopilotEvents.MessageSent,
          name: msg('activity.events.messageSent', 'Message sent')
        },
        {
          _id: AutopilotLogsStore.autopilotEvents.MemberStatusChanged,
          name: msg('activity.events.memberStatusChanged', 'Member status changed')
        }
      ]
    }
  ];
  const sortBy = {
    fields: ['date'],
    default: 'date,-1'
  };

  const DebouncedDataFiltered = Lodash.debounce(FilterData, 300);

  return (
    <div className="row">
      <div className="col-md-12">
        <CardComponent
          title={msg('sidebar.autopilotLogs', 'Autopilot Logs')}
          error={!!error}
          headerIcon="list"
          needsTitle={true}
          buttons={[
            {
              label: msg('autopilot.runVerification', 'Run verification'),
              onClick: runAutopilotVerification
            }
          ]}
        >
          <InfiniteScrollListComponent
            error={error}
            fields={fields}
            filters={filter}
            sort={sortBy}
            search={['search']}
            list={{
              results: list,
              page_number: pagination.page,
              limit: pagination.limit,
              skip: pagination.skip,
              total_record_count: pagination.total
            }}
            limit={30}
            pending={loading}
            get={(limit: any, skip: any, sort: any, criteria: any) => {
              DebouncedDataFiltered({
                sort: sort?.replace(' ', ''),
                limit,
                skip,
                criteria
              });
            }}
          />
        </CardComponent>
      </div>
    </div>
  );
};

export const AutopilotLogsContainer = connect(mapStateToProps, mapDispatchToProps)(AutopilotLogsComponent);
