// tslint:disable-next-line
export interface PayBatchDTO {
  _id: string;
  uniqueNo: number;
  amount: number;
  totalAmount: number;
  feeAmount: number;
  placesNo: number;
  places: string[];
  status: PayBatchStatus;
  attachment: PayBatchAttachment;
  emailStatus: EmailStatus;
  created: number;
  updatedAt: number;
  deleted: boolean;
}

// tslint:disable-next-line
export interface PayBatchAttachment {
  originalName: string;
  blobName: string;
}

export const enum PayBatchStatus {
  UNSETTLED,
  PENDING,
  SETTLED
}

export enum EmailStatus {
  NOT_SENT,
  PENDING,
  PARTIAL,
  SENT
}
