import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { msg } from '../../../../utils';
import { ReviewDTO, ReviewQuestionType } from '../../../../appRedux/types';
import { Bounce } from 'react-activity';
import { colors } from '../../../../assets/styles';

interface IProps {
  title: string;
  review: ReviewDTO | null;
  review_pending?: boolean;
  toggleModal: () => any;
}

export class ReviewModalComponent extends React.Component<IProps> {
  render() {
    const review = this.props.review;
    return (
      <Modal show={true} onHide={this.props.toggleModal}>
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: 700, overflowX: 'auto' }}>
          {this.props.review_pending ? (
            <div style={{ textAlign: 'center', padding: 20 }}>
              <Bounce color={colors.lightGreen} />
            </div>
          ) : (
            <div style={{ paddingLeft: 5, fontSize: 18, lineHeight: 1.5 }}>
              {review && (
                <div style={{ padding: 5 }}>
                  {msg('reviews.userAnswer', 'Answers given by ')}
                  <span style={{ fontWeight: 'bolder' }}>
                    {review.user
                      ? review.user.lastname + ' ' + review.user.firstname
                      : msg('reviews.anonymousUser', 'an anonymous user')}
                  </span>
                </div>
              )}
              {review &&
                review.answers.map((item: any, index: number) => {
                  return (
                    <div key={index} style={{ padding: 5 }}>
                      <div style={{ fontWeight: 600 }}>{item.label || ''}</div>
                      {item.type === ReviewQuestionType.TEXT &&
                        item.options &&
                        item.options.map((answer: any, index_option: number) => (
                          <div key={index_option}>
                            <div style={{ padding: 5, wordBreak: 'break-word' }}>{answer.label}</div>
                          </div>
                        ))}
                      {item.type === ReviewQuestionType.CHECKBOX &&
                        item.options &&
                        item.options.map((answer: any, index_option: number) => (
                          <div key={index_option} style={{ padding: 5 }}>
                            <div
                              style={{ display: 'inline-flex', float: 'left', alignItems: 'center', paddingRight: 5 }}
                            >
                              <i className="material-icons">
                                {answer.checked ? 'check_box' : 'check_box_outline_blank'}
                              </i>
                            </div>
                            <div>{answer.label}</div>
                          </div>
                        ))}
                      {item.type === ReviewQuestionType.RADIO &&
                        item.options &&
                        item.options.map((answer: any, index_option: number) => (
                          <div key={index_option} style={{ padding: 5 }}>
                            <div
                              style={{ display: 'inline-flex', float: 'left', alignItems: 'center', paddingRight: 5 }}
                            >
                              <i className="material-icons">
                                {answer.checked ? 'radio_button_checked' : 'radio_button_unchecked'}
                              </i>
                            </div>
                            <div>{answer.label}</div>
                          </div>
                        ))}
                      {item.type === ReviewQuestionType.TEXTAREA &&
                        item.options &&
                        item.options.map((answer: any, index_option: number) => (
                          <div
                            key={index_option}
                            style={{
                              padding: 5,
                              wordBreak: 'break-word',
                              border: '1px solid #ccc',
                              borderRadius: 3
                            }}
                          >
                            {answer.label}
                          </div>
                        ))}
                    </div>
                  );
                })}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-success" onClick={this.props.toggleModal}>
            {msg('general.close', 'Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
