import rewardsActions, { IRewardsActions } from './actions';
import rewardsReducer from './reducer';

/*
  RewardsStore definition as a namespace that contains actions, reducers, actionTypes and rewards state
*/
export namespace RewardsStore {
  export type IState = {
    rewards_list: Array<any>;
    rewards_list_pending: boolean;
    rewards_list_error: string | null;
    reward_saved: any | null;
    reward_save_pending: boolean;
    reward_save_error: string | null;
    reward_deleted: boolean;
    reward_delete_pending: boolean;
    reward_delete_error: string | null;
  };

  export const initialState: IState = {
    rewards_list: [],
    rewards_list_pending: false,
    rewards_list_error: null,
    reward_saved: null,
    reward_save_pending: false,
    reward_save_error: null,
    reward_deleted: false,
    reward_delete_pending: false,
    reward_delete_error: null
  };

  export enum ActionTypes {
    GET_REWARDS = 'GET_REWARDS',
    GET_REWARDS_SUCCESS = 'GET_REWARDS_SUCCESS',
    GET_REWARDS_FAILED = 'GET_REWARDS_FAILED',
    SAVE_REWARD = 'SAVE_REWARD',
    SAVE_REWARD_SUCCESS = 'SAVE_REWARD_SUCCESS',
    SAVE_REWARD_FAILED = 'SAVE_REWARD_FAILED',
    DELETE_REWARD = 'DELETE_REWARD',
    DELETE_REWARD_SUCCESS = 'DELETE_REWARD_SUCCESS',
    DELETE_REWARD_FAILED = 'DELETE_REWARD_FAILED'
  }

  export const actions = rewardsActions;
  export const reducer = rewardsReducer;
  export interface IActions extends IRewardsActions {}
}

export * from './actions';
