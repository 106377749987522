import * as React from 'react';

import { AppStore, UsersStore, IndustriesStore, PlacesStore, reduxContainer, StatisticsStore } from '../../../appRedux';
import { DashboardHeader, AdminDashboardStatistics } from './components';

interface IProps extends StatisticsStore.IState {}
interface IState {}

class AdminDashboardContainer extends React.Component<
  IProps & UsersStore.IActions & IndustriesStore.IActions & StatisticsStore.IActions & PlacesStore.IActions,
  IState
> {
  componentDidMount() {
    this.props.getAllSuperAdminStatistics();
  }

  render() {
    const dataForHeader = {
      name: 'Super Regage',
      forPartner: false,
      subtitle: 'Get Engage with your client!',
      shortname: 'regage_team',
      statistics: this.props.statistics_all_super_admin
    };
    const dataForStatistics = {
      list: this.props.statistics_super_admin,
      pending: this.props.statistics_super_admin_pending,
      error: this.props.statistics_super_admin_error,
      get: this.props.getStatisticsPerPlaceForAdminAction,
      redirect: this.props.redirectToEditPlaceAction
    };

    return (
      <div>
        <DashboardHeader config={dataForHeader} forSAdmin={true} />
        <AdminDashboardStatistics config={dataForStatistics} />
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    statistics_super_admin: state.statistics.statistics_super_admin,
    statistics_super_admin_pending: state.statistics.statistics_super_admin_pending,
    statistics_super_admin_error: state.statistics.statistics_super_admin_error,
    statistics_all_super_admin: state.statistics.statistics_all_super_admin
  };
}

const dispatchToProps = {
  getUsersListAction: UsersStore.actions.getUsersListAction,
  getIndustriesListAction: IndustriesStore.actions.getIndustriesListAction,
  getStatisticsPerPlaceForAdminAction: StatisticsStore.actions.getStatisticsPerPlaceForAdminAction,
  getAllSuperAdminStatistics: StatisticsStore.actions.getAllSuperAdminStatistics,
  redirectToEditPlaceAction: PlacesStore.actions.redirectToEditPlaceAction
};

export default reduxContainer(AdminDashboardContainer, mapStateToProps, dispatchToProps);
