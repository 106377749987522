import * as React from 'react';
import moment from 'moment';

import { AlertUtil, msg } from '../../../../utils';
import { CardComponent, AdvancedListComponent } from '../../../shared';
import { PaginationDTO, PartnerDTO, SelectValueDTO } from '../../../../appRedux/types';
import { AppStore, PartnersStore, IndustriesStore, reduxContainer } from '../../../../appRedux';

interface IProps extends PartnersStore.IState, IndustriesStore.IState {}
interface IState {}

class PartnersListComponent extends React.Component<
  IProps & PartnersStore.IActions & IndustriesStore.IActions,
  IState
> {
  isData() {
    return this.props.partners_list && this.props.partners_list.results && this.props.partners_list.results.length;
  }

  isIndustriesData() {
    return this.props.industries_short_list && this.props.industries_short_list.length;
  }

  parsePartners(list: PaginationDTO<PartnerDTO>): any {
    if (!this.isData()) {
      return [];
    }
    let data = {
      ...list,
      results: list.results.map(item => {
        return {
          name: item.name,
          phone: item.phone,
          _id: item._id,
          status: item.status,
          industry: item.industry?.name || '',
          updatedAt: moment(item.updatedAt).valueOf(),
          isActive: item.isActive
        };
      })
    };
    return data;
  }

  parseIndustries(list: SelectValueDTO[]): any {
    if (!this.isIndustriesData()) {
      return [];
    }
    let data = list
      .map(item => {
        return {
          _id: item._id,
          name: item.name
        };
      })
      .sort((a: any, b: any) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    data.unshift({ _id: '', name: msg('industry.allIndustries', 'All industries') });
    return data;
  }

  handleChangeActive = (itemId: string) => (event: any) => {
    const partner = this.parsePartners(this.props.partners_list).results.find(
      (item: PartnerDTO) => item._id === itemId
    );
    this.props.editPartnerStatusAction({ ...partner, isActive: event.target.checked });
  };

  handleChangeStatus = (itemId: string) => (event: any) => {
    const partner = this.parsePartners(this.props.partners_list).results.find(
      (item: PartnerDTO) => item._id === itemId
    );
    this.props.editPartnerAction({ _id: partner._id, status: event.target.value });
  };

  editAction = (event: any) => {
    this.props.redirectToEditAction(event.currentTarget.getAttribute('id'));
  };

  deleteAction = async (event: any) => {
    const id = event.currentTarget.getAttribute('id');
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.partnerDeleteConfirmation', 'You are about to delete a partner!')
    );
    if (hasConfirmation) {
      this.props.deletePartnerAction(id);
    }
  };

  getActions() {
    return [
      {
        label: msg('general.edit', 'Edit'),
        btn: 'btn-success',
        icon: 'edit',
        onClick: this.editAction
      },
      {
        label: msg('general.delete', 'Delete'),
        btn: 'btn-danger',
        icon: 'delete',
        ref: 'deletePartner',
        onClick: this.deleteAction
      }
    ];
  }

  render() {
    const list = this.parsePartners(this.props.partners_list);
    const industries = this.parseIndustries(this.props.industries_short_list);
    return (
      <CardComponent
        title={msg('sidebar.partners', 'Partners')}
        error={!!this.props.partners_list_error}
        headerIcon="list"
        needsTitle={true}
      >
        <AdvancedListComponent
          fields={{
            name: msg('partner.name', 'Name'),
            phone: msg('partner.phone', 'Phone'),
            industry: msg('partner.industry', 'Industry')
          }}
          actions={this.getActions()}
          list={list}
          pending={this.props.partners_list_pending}
          error={this.props.partners_list_error}
          get={this.props.getPartnersListAction}
          limit={30}
          sort={{
            fields: ['name'],
            default: 'updatedAt,-1'
          }}
          search={['name', 'phone']}
          filters={[
            {
              field: 'industry',
              value: industries
            }
          ]}
          toggles={[{ name: msg('place.isActive', 'Active'), onChange: this.handleChangeActive, field: 'isActive' }]}
          select={[
            {
              name: msg('partner.status', 'Status'),
              options: [
                { _id: 0, name: msg('partnerStatus.free', 'Free') },
                { _id: 1, name: msg('partnerStatus.basic', 'Basic') },
                { _id: 2, name: msg('partnerStatus.pro', 'Pro') }
              ],
              onChange: this.handleChangeStatus,
              field: 'status'
            }
          ]}
        />
      </CardComponent>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    partners_list: state.partners.partners_list,
    partners_list_pending: state.partners.partners_list_pending,
    partners_list_error: state.partners.partners_list_error,
    partner_save_pending: state.partners.partner_save_pending,
    partner_save_error: state.partners.partner_save_error,
    partner_delete_pending: state.partners.partner_delete_pending,
    industries_short_list: state.industries.industries_short_list
  };
}

const dispatchToProps = {
  getPartnersListAction: PartnersStore.actions.getPartnersListAction,
  editPartnerStatusAction: PartnersStore.actions.editPartnerStatusAction,
  editPartnerAction: PartnersStore.actions.editPartnerAction,
  redirectToEditAction: PartnersStore.actions.redirectToEditAction,
  deletePartnerAction: PartnersStore.actions.deletePartnerAction,
  getShortIndustriesListAction: IndustriesStore.actions.getShortIndustriesListAction
};

export default reduxContainer(PartnersListComponent, mapStateToProps, dispatchToProps);
