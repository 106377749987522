import classnames from 'classnames';
import * as React from 'react';
import { Bounce } from 'react-activity';

interface IProps {
  pending?: boolean;
  action: any;
  label: any;
  icon?: string;
  swapIconText?: boolean;
  id?: string;
  disabled?: boolean;
  styles?: any;
}

interface IState {}
export default class ButtonComponent extends React.Component<IProps, IState> {
  render() {
    const isPending = this.props.pending;
    const classes = classnames(
      `btn btn-success btn-green btn-wd btn-md`,
      {
        disabled: isPending
      },
      { disabled: this.props.disabled }
    );
    return (
      <div
        onClick={this.props.disabled ? () => {} : this.props.action}
        className={classes}
        style={this.props.styles}
        id={this.props.id ? this.props.id : ''}
      >
        {isPending ? (
          <Bounce color="white" />
        ) : (
          <>
            {this.props.icon && !this.props.swapIconText && (
              <i className="material-icons" style={{ marginRight: 10 }}>
                {this.props.icon}
              </i>
            )}
            <span>{this.props.label}</span>
            {this.props.icon && this.props.swapIconText && (
              <i className="material-icons" style={{ marginLeft: 10 }}>
                {this.props.icon}
              </i>
            )}
          </>
        )}
      </div>
    );
  }
}
