import { SocialDTO, GeolocationDTO, ScheduleDTO } from './';

export class PlaceSettingsDTO {
  pointsForCheckin: number;
  memberRegistration: number;
  convertAmountRule: number;
}

export class FeatureFlagsDTO {
  alternativeAddress?: boolean = false;
  becomeMember?: boolean = true; // disables members, activity, rewards, simple autopilots
  checkin?: boolean = true;
  useCoins?: boolean = true;
  simpleAutopilots?: boolean = true;
  moments?: boolean = true;
  news?: boolean = true;
  sendReceipts?: boolean = true;
  approveReceipts?: boolean = false;
  multipleReceipts?: boolean = false;
  reviews?: boolean = true;
  exportInExcel?: boolean = false;
  manualCheckout?: boolean = false;
  receiptsAfterCheckout?: boolean = false;
  staticQR?: boolean = false;
  integration?: boolean = false;
  nativeNotifications?: boolean = true;
  externalApp?: boolean = false;
  transactions?: boolean = false;
  transactionsWithCoins?: boolean = false;
  tips?: boolean = false;
  transactionReports?: boolean = false;
  tags?: boolean = false;
}

export class TabletFeatureFlagsDTO {
  qr: boolean = true;
  scan: boolean = true;
  redeems: boolean = true;
  receipts: boolean = false;
  transactions: boolean = false;
}

export class PlaceIntegrationDTO {
  api: string = '';
  username: string = '';
  password: string = '';
}

export class TransactionSettingsDTO {
  convertToCoinsRule: number = 0;
  maxDivisionCoinsRule: number = 0;
  ronToCoinsRule: number = 1;
  tipsType?: TipsType;
  tipsValues?: Array<number | string>;
  feePerTransactionAmount: number = 0;
  feePerTransactionPercent: number = 0;
  feePerSettlement: number = 0;
  settlementBuffer: number = 1;
  iban: string;
  cui: string;
  regCom: string;
  companyName: string;
  reportEmails?: string[];
}

export class PlaceDTO {
  _id: string;
  name: string = '';
  address: string = '';
  city: string = '';
  partnerId: string = '';
  phone: string = '';
  description: string = '';
  media: any[] = [];
  currentCoverPhoto: {
    createdAt: string;
    id: string;
    type: string;
    url: string;
  };
  currentLogoPhoto: {
    createdAt: string;
    id: string;
    type: string;
    url: string;
  };
  currentMapPhoto: string = '';
  links: SocialDTO = new SocialDTO();
  featureFlags: FeatureFlagsDTO = new FeatureFlagsDTO();
  tabletFeatureFlags: TabletFeatureFlagsDTO = new TabletFeatureFlagsDTO();
  vanityname: string = '';
  created: string = '';
  geolocation: GeolocationDTO | null;
  type: string = '';
  isActive: boolean;
  schedule: ScheduleDTO[] | null = [
    new ScheduleDTO(),
    new ScheduleDTO(),
    new ScheduleDTO(),
    new ScheduleDTO(),
    new ScheduleDTO(),
    new ScheduleDTO(),
    new ScheduleDTO()
  ];
  settings: PlaceSettingsDTO = new PlaceSettingsDTO();
  packageId: string;
  geoBoost: number = 0;
  geoMaxDistance: number = 10;
  geoClosedDistance: number = 10;
  allowReviews: boolean;
  allowFeedbacks: boolean;
  allowReceipts: boolean;
  industry: string = '';
  qrHash: string;
  uniqueNo: number;
  integration?: PlaceIntegrationDTO;
  externalApp?: string;
  transactionSettings?: TransactionSettingsDTO;
}

export enum TipsType {
  AMOUNT,
  PERCENT
}
