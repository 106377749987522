import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTopComponent = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const element = document.getElementById('scrollToElement');
    element?.scrollIntoView();
    // tslint:disable-next-line: align
  }, [pathname]);

  return null;
};

export default ScrollToTopComponent;
