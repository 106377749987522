import * as React from 'react';

import { AlertUtil, getFormatDate, msg } from '../../../../utils';
import { ButtonComponent, CardComponent, ListComponent } from '../../../shared';

interface IProps {
  config: {
    autopilot_delete_pending: boolean;
    autopilot_list: any;
    autopilot_list_pending: boolean;
    autopilot_list_error: string | null;
    getAutopilotListForPlaceAction: (placeId: string, partnerId: string) => any;
    deleteAutopilotRuleForPlaceAction: (autopilotId: string, placeId: string, partnerId: string) => any;
    updateAutopilotStatusForPlaceAction: (status: any) => any;
    redirectToAddAutopilotRuleActionForPlace: (placeId: string) => any;
    redirectToEditAutopilotRuleForPlaceAction: (autopilotId: string, palceId: string) => any;
    inheritAutopilotAction: (placeId: string, partnerId: string) => any;
    autopilot_save_pending: boolean;
  };
  match?: any;
  partnerId: string;
}
interface IState {}

export default class PlaceAutopilotListComponent extends React.Component<IProps, IState> {
  componentDidMount() {
    if (this.props.match?.params.placeId) {
      this.props.config.getAutopilotListForPlaceAction(this.props.match.params.placeId, this.props.partnerId);
    }
  }

  addAction = () => {
    if (this.props.match?.params.placeId) {
      this.props.config.redirectToAddAutopilotRuleActionForPlace(this.props.match.params.placeId);
    }
  };

  editAction = (event: any) => {
    if (this.props.match?.params.placeId) {
      this.props.config.redirectToEditAutopilotRuleForPlaceAction(
        event.currentTarget.getAttribute('id'),
        this.props.match.params.placeId
      );
    }
  };

  deleteAction = async (event: any) => {
    const id = event.currentTarget.getAttribute('id');
    if (id !== null) {
      const hasConfirmation = await AlertUtil.confirm(
        msg('alertMessages.autopilotDeleteConfirmation', 'You are about to delete an autopilot rule!')
      );
      if (hasConfirmation && this.props.match?.params.placeId) {
        this.props.config.deleteAutopilotRuleForPlaceAction(id, this.props.match?.params.placeId, this.props.partnerId);
      }
    }
  };

  inheritAutopilotAction = async (event: any) => {
    const hasConfirmation = await AlertUtil.inherit(
      msg(
        'autopilot.inheritTooltip',
        // tslint:disable-next-line:max-line-length
        'If you agree, the list will inherit the autopilot rules defined at partner level and will become active for the place immediately!'
      )
    );
    if (hasConfirmation && this.props.match?.params.placeId) {
      this.props.config.inheritAutopilotAction(this.props.match?.params.placeId, this.props.partnerId);
    }
  };

  isAutopilotsData() {
    return this.props.config.autopilot_list && this.props.config.autopilot_list.length;
  }

  parseAutopilots = (list: any) => {
    if (!this.isAutopilotsData()) {
      return [];
    }
    let data = list.map((item: any) => {
      const date = item.lastTriggered ? getFormatDate(item.lastTriggered, 'hour') : 'N/A';
      return {
        ...item,
        date,
        _id: item._id || item.autopilotId,
        triggered: item.stats.triggered,
        claims: item.stats.claims,
        used: item.stats.used
      };
    });
    return data;
  };

  render() {
    let placeId = '';
    if (this.props.match?.params.placeId) {
      placeId = this.props.match.params.placeId;
    }
    const autopilots = this.parseAutopilots(this.props.config.autopilot_list);
    const autopilotRulesListData = {
      fields: {
        title: msg('autopilot.title', 'Rule Title'),
        date: msg('autopilot.lastTriggered', 'Last Triggered On'),
        triggered: msg('autopilot.triggeredTimes', 'Triggered Times'),
        claims: msg('autopilot.claims', 'Member claims'),
        used: msg('autopilot.used', 'Member redeems'),
        isActive: msg('autopilot.isActive', 'Active')
      },
      actions: [
        {
          label: msg('general.edit', 'Edit'),
          btn: 'btn-success',
          icon: 'edit',
          onClick: this.editAction
        },
        {
          label: msg('general.delete', 'Delete'),
          btn: 'btn-danger',
          icon: 'delete',
          ref: 'deleteAutopilotRule',
          onClick: this.deleteAction
        }
      ],
      list: autopilots,
      pending: this.props.config.autopilot_list_pending,
      error: this.props.config.autopilot_list_error,
      idforChangeStatus: placeId,
      forPartnerAutopilot: true,
      handleChangeStatus: this.props.config.updateAutopilotStatusForPlaceAction,
      auxilaryId: this.props.partnerId
    };
    return (
      <CardComponent
        title={msg('cardTitle.autopilotRules', 'Autopilot Rules')}
        error={!!this.props.config.autopilot_list_error}
        pending={this.props.config.autopilot_list_pending}
        headerIcon="assignment"
        needsTitle={true}
      >
        {!this.props.config.autopilot_list_pending && <ListComponent data={autopilotRulesListData} />}
        <div className="row" style={{ margin: 10 }}>
          <div className={this.props.config.autopilot_list.length === 0 ? 'col-md-2' : 'col-md-3'} />
          {this.props.config.autopilot_list.length === 0 && (
            <div className="col-md-4" style={{ textAlign: 'center' }}>
              <div className="form-group form-button">
                <ButtonComponent
                  label={msg('autopilot.inheritPartnerAutopilots', 'Inherit Autopilot Rules')}
                  action={this.inheritAutopilotAction}
                  pending={this.props.config.autopilot_list_pending}
                />
              </div>
            </div>
          )}
          <div
            className={this.props.config.autopilot_list.length === 0 ? 'col-md-4' : 'col-md-6'}
            style={{ textAlign: 'center' }}
          >
            <div className="form-group form-button">
              <ButtonComponent
                label={msg('autopilot.addNewRule', 'Add new rule')}
                icon="library_add"
                action={this.addAction}
                pending={this.props.config.autopilot_list_pending}
              />
            </div>
          </div>
          <div className={this.props.config.autopilot_list.length === 0 ? 'col-md-2' : 'col-md-3'} />
        </div>
      </CardComponent>
    );
  }
}
