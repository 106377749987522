import { Dispatch } from 'redux';

import { RewardsStore } from './';
import { IndustriesStore } from '../industries';
import { Server, AlertUtil, msg, logger } from '../../utils';
import { RewardDTO } from '../types';

/*
  IRewardsActions interface definition, which contains every redux action asociated with Rewards State.
*/
export interface IRewardsActions {
  /*
    General actions and for super admin
  */
  deleteRewardAction(rewardId: string, industryId: string): any;
  updateRewardsAction(rewards: RewardDTO[], industryId: string): any;
  getRewardsListAsAdminAction(industryId: string): any;

  /*
    Actions specific for place
  */
  deleteRewardForPlaceAction(rewardId: string, placeId: string, partnerId: string): any;
  getRewardsListForPlaceAction(placeId: string): any;
  updateRewardsForPlaceAction(rewards: RewardDTO[], placeId: string): any;
}

/*
  class RewardsActions that implements redux actions defined in IRewardsActions interface
*/
class RewardsActions implements IRewardsActions {
  /*
    @function deleteRewardAction => Redux action that deletes an existing reward from an industry
      @accepts rewardId : string representing the id of the reward to be deleted
               industryId : string representing the id of the industry that has the soon to be deleted reward
      @returns Promise
  */
  deleteRewardAction(rewardId: string, industryId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: RewardsStore.ActionTypes.DELETE_REWARD
      });
      logger.msg('Delete default reward action, route:/rewards/delete/rewardId', 'DELETE');
      Server.delete(`rewards/delete/${rewardId}`)
        .then((response: any) => {
          dispatch({
            type: RewardsStore.ActionTypes.DELETE_REWARD_SUCCESS,
            payload: response.data
          });
          AlertUtil.simple(msg('alertMessages.rewardDeleted', 'The reward has been successfully deleted!'), 'success');
          dispatch(RewardsStore.actions.getRewardsListAsAdminAction(industryId));
        })
        .catch(error => {
          logger.err('Delete default reward action, route:/rewards/delete/rewardId', 'DELETE');
          AlertUtil.simple(
            msg('reduxMessages.rewards.deleteRewardError', 'Due to an error, the reward could not be deleted!'),
            'error',
            2000
          );
          dispatch({
            type: RewardsStore.ActionTypes.DELETE_REWARD_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function updateRewardsAction => Redux action that updates an existing reward for an industry
      @accepts rewards : Array of RewardDTO objects that contain information about rewards that will update existing one
               industryId : string representing the id of the industry that has the rewards that will be updated
      @returns Promise
  */
  updateRewardsAction(rewards: RewardDTO[], industryId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: RewardsStore.ActionTypes.SAVE_REWARD
      });
      AlertUtil.simple(
        msg('reduxMessages.rewards.editRewardsPending', 'The rewards list is being updated, please wait...'),
        'info'
      );
      logger.msg('Update default reward action, route:/industries/industryId/updateRewardsList', 'PUT');
      await Server.put(`industries/${industryId}/updateRewardsList`, rewards)
        .then((response: any) => {
          dispatch({
            type: RewardsStore.ActionTypes.SAVE_REWARD_SUCCESS,
            payload: response.data as RewardDTO
          });
          dispatch(IndustriesStore.actions.getIndustryAction(industryId));
          AlertUtil.updateContent(
            msg('reduxMessages.rewards.editRewardsSuccess', 'The rewards list was successfully updated!'),
            'success'
          );
          dispatch(RewardsStore.actions.getRewardsListAsAdminAction(industryId));
        })
        .catch(error => {
          logger.err('Update default rewards action, route:/industries/industryId/updateRewardsList', 'PUT');
          AlertUtil.updateContent(
            msg('reduxMessages.rewards.editRewardsError', 'Due to an error, the rewards list could not be updated!'),
            'error'
          );
          dispatch({
            type: RewardsStore.ActionTypes.SAVE_REWARD_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getRewardsListAsAdminAction => Redux action that gets all industry rewards rules
      @accepts partnerId : string that represents the id of the industry that has the rewards
      @returns Promise
  */
  getRewardsListAsAdminAction(industryId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: RewardsStore.ActionTypes.GET_REWARDS });
      try {
        logger.msg('Get rewards list as admin action, route:/industries/:id/rewards', 'GET');
        const response: any = await Server.get(`industries/${industryId}/rewards`);
        dispatch({
          type: RewardsStore.ActionTypes.GET_REWARDS_SUCCESS,
          payload: response.data
        });
      } catch (error) {
        logger.err('Get industries action, route:/industries', 'GET');
        AlertUtil.simple(
          msg(
            'reduxMessages.industries.getAutopilotListAsAdminError',
            'Due to an error, the rewards list could not be loaded!'
          ),
          'error',
          2000
        );
        dispatch({
          type: RewardsStore.ActionTypes.GET_REWARDS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  /*
    @function deleteRewardForPlaceAction => Redux action that deletes an existing reward from a place
      @accepts partnerId : string that represents the id of the partner that has the place
               rewardId : string that represents the id of the reward that will be deleted
               placeId : string that represents the id of the place that has the soon to be deleted reward
      @returns Promise
  */
  deleteRewardForPlaceAction(rewardId: string, placeId: string, partnerId: string) {
    AlertUtil.simple(msg('alertMessages.rewardBeingDeleted', 'The reward is being deleted...'), 'info');
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: RewardsStore.ActionTypes.DELETE_REWARD
      });
      logger.msg('Delete rewards for place action, route:/rewards/delete/rewardId?placeId&partnerId', 'DELETE');
      Server.delete(`rewards/delete/${rewardId}?placeId=${placeId}&partnerId=${partnerId}`)
        .then((response: any) => {
          dispatch({
            type: RewardsStore.ActionTypes.DELETE_REWARD_SUCCESS,
            payload: response.data
          });
          AlertUtil.updateContent(
            msg('alertMessages.rewardDeleted', 'The reward has been successfully deleted!'),
            'success'
          );
          dispatch(RewardsStore.actions.getRewardsListForPlaceAction(placeId));
        })
        .catch(error => {
          logger.err('Delete rewards for place action, route:/rewards/delete/rewardId?placeId&partnerId', 'DELETE');
          AlertUtil.updateContent(
            msg('reduxMessages.rewards.deleteRewardError', 'Due to an error, the reward could not be deleted!'),
            'error'
          );
          dispatch({
            type: RewardsStore.ActionTypes.DELETE_REWARD_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getRewardsListForPlaceAction => Redux action that gets all existing rewards of a place
      @accepts placeId : string that represents the id of the place that has the rewards
      @returns Promise
  */
  getRewardsListForPlaceAction(placeId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: RewardsStore.ActionTypes.GET_REWARDS });
      try {
        logger.msg('Get rewards list for place action, route:/places/placeId/rewards', 'GET');
        const response: any = await Server.get(`places/${placeId}/rewards`);
        dispatch({
          type: RewardsStore.ActionTypes.GET_REWARDS_SUCCESS,
          payload: response.data.results as Array<RewardDTO>
        });
      } catch (error) {
        logger.err('Get rewards list for place action, route:/places/placeId/rewards', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.rewards.getRewardsError', 'Due to an error, the rewards list could not be loaded!'),
          'error',
          2000
        );
        dispatch({
          type: RewardsStore.ActionTypes.GET_REWARDS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  /*
    @function updateRewardsForPlaceAction => Redux action that updates an existing reward for a place
      @accepts rewards : Array of RewardDTO objects that contain information about rewards that will update existing one
               placeId : string representing the id of the place that has the rewards that will be updated
      @returns Promise
  */
  updateRewardsForPlaceAction(rewards: RewardDTO[], placeId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: RewardsStore.ActionTypes.SAVE_REWARD
      });
      AlertUtil.simple(
        msg('reduxMessages.rewards.editRewardsPending', 'The rewards list is being updated, please wait...'),
        'info'
      );
      logger.msg('Update rewards for place action, route:/places/placeId/updateRewardsList', 'PUT');
      await Server.put(`places/${placeId}/updateRewardsList`, rewards)
        .then((response: any) => {
          dispatch({
            type: RewardsStore.ActionTypes.SAVE_REWARD_SUCCESS,
            payload: response.data as RewardDTO[]
          });
          AlertUtil.updateContent(
            msg('reduxMessages.rewards.editRewardsSuccess', 'The rewards list was successfully updated!'),
            'success'
          );
          dispatch(RewardsStore.actions.getRewardsListForPlaceAction(placeId));
        })
        .catch(error => {
          logger.err('Update rewards for place action, route:/places/placeId/updateRewardsList', 'PUT');
          AlertUtil.updateContent(
            msg('reduxMessages.rewards.editRewardsError', 'Due to an error, the rewards list could not be updated!'),
            'error'
          );
          dispatch({
            type: RewardsStore.ActionTypes.SAVE_REWARD_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }
}

const rewardsActions = new RewardsActions();
export default rewardsActions;
