export class PartnerEmailDTO {
  _id: string;
  subject: string;
  content: string;
  from: string;
  sentTo: any[];
  readBy: number;
  attachments: IAttachment[] = [];
  deleted: boolean;
  created: number;
}

interface IAttachment {
  url: string;
  mimetype: string;
  originalName: string;
  blobName: string;
}
