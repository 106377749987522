import * as React from 'react';
import { CardComponent } from '../../../shared';
import { ProfileFormComponent } from './forms';
import { msg } from '../../../../utils';

interface IProps {
  config: {
    user: any;
    edit: (user: any) => any;
    password_pending: boolean;
    user_edit_pending: boolean;
    changePass: (email: string, oldPassword: string, newPassword: string) => void;
  };
  forSAdmin: boolean;
}
interface IState {
  user: any;
}

export default class ProfileEditComponent extends React.Component<IProps, IState> {
  saveUserHandler = (user: any) => {
    const { config } = this.props;
    let dataToSend = {
      ...user,
      _id: this.props.config.user.id
    };
    config.edit(dataToSend);
  };

  userChangeHandler = (user: any) => {
    this.setState({ user });
  };

  renderProfileForm = () => {
    const propsConfig = this.props.config;
    const userData = propsConfig.user;
    return (
      <CardComponent title={msg('sidebar.editProfile', 'Edit Profile')} headerIcon="edit" needsTitle={true}>
        <form className="form-horizontal">
          <div className="col-sm-12">
            <ProfileFormComponent
              user={userData}
              save={this.saveUserHandler}
              output={false}
              onChange={this.userChangeHandler}
              changePass={this.props.config.changePass}
              password_pending={this.props.config.password_pending}
              user_edit_pending={this.props.config.user_edit_pending}
            />
          </div>
        </form>
      </CardComponent>
    );
  };

  render() {
    return (
      <div>
        <div>{this.renderProfileForm()}</div>
      </div>
    );
  }
}
