import * as React from 'react';
import SimpleAutopilotsComponent from './SimpleAutopilotsComponent';
import { AutopilotStore, PartnersStore, PlacesStore, reduxContainer } from '../../../appRedux';

interface IProps extends AutopilotStore.IState, PartnersStore.IState, PlacesStore.IState {
  redirectToPlaceDashboardAction: (placeId: string) => any;
}
interface IState {}

class SimpleAutopilotsContainer extends React.Component<IProps & AutopilotStore.IActions, IState> {
  render() {
    if (
      this.props.place &&
      !this.props.place_pending &&
      (!this.props.place?.featureFlags?.simpleAutopilots || !this.props.place?.featureFlags?.becomeMember)
    ) {
      this.props.redirectToPlaceDashboardAction(this.props.selected_place || '');
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <SimpleAutopilotsComponent config={this.props} showStatistics={true} showCheck={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    autopilot_list: state.autopilot.autopilot_list,
    autopilot_list_pending: state.autopilot.autopilot_list_pending,
    autopilot_list_error: state.autopilot.autopilot_list_error,
    place: state.places.place,
    place_pending: state.places.place_pending,
    selected_place: state.places.selected_place,
    selected_partner: state.partners.selected_partner,
    all_simple_autopilots_save_success: state.autopilot.all_simple_autopilots_save_success,
    all_simple_autopilots_save_pending: state.autopilot.all_simple_autopilots_save_pending,
    all_simple_autopilots_save_error: state.autopilot.all_simple_autopilots_save_error
  };
}

const dispatchToProps = {
  getAutopilotListForPlaceAction: AutopilotStore.actions.getAutopilotListForPlaceAction,
  updateAllSimpleAutopilotsRuleForPlaceAction: AutopilotStore.actions.updateAllSimpleAutopilotsRuleForPlaceAction,
  redirectToPlaceDashboardAction: PlacesStore.actions.redirectToPlaceDashboardAction
};

export default reduxContainer(SimpleAutopilotsContainer, mapStateToProps, dispatchToProps);
