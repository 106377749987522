import { Dispatch } from 'redux';
import { push } from 'connected-react-router';

import { EventsStore } from './';
import {
  Server,
  composedCriteriaBuilder,
  AlertUtil,
  msg,
  logger,
  queryStringToCriteria,
  ERROR_CODES
} from '../../utils';
import { PaginationDTO, EventDTO, SettlementDTO, ScheduleDTO, RaffleIntervalDTO } from '../types';
import moment from 'moment';
import { partnerRoutes } from '../../modules/layouts/routes';
import { generatePath } from 'react-router-dom';

/*
  IEventsActions interface definition, which contains every redux action asociated with Events State.
*/
export interface IEventsActions {
  /*
    Actions specific for a place
  */
  addEventForPlaceAction(event: any, placeId: string): any;
  deleteEventForPlaceAction(eventId: string, partnerId: string, placeId: string): any;
  editEventForPlaceAction(event: any, placeId: string): any;
  getEventForPlaceAction(eventId: string, placeId: string): any;
  getEventsForPlaceAction(
    placeId: string,
    showExpired: boolean,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  redirectToAddEventForPlaceAction(placeId: string): any;
  redirectToEditEventForPlaceAction(eventId: string, placeId: string): any;
  editEventStatusAction(placeId: string, eventId: string, active: any): any;
  exportEventsInExcel(placeId: string, startDate: number, endDate: number): any;
  getMemberStatsForCampaign(
    eventId: string,
    placeId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  getPartnerSettlements(
    partnerId: string,
    eventId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  editSettlement(partnerId: string, settlementId: string, settlement: SettlementDTO): any;
  sendSettlementEmail(partnerId: string, settlementId: string): any;
  approveSettlementEmail(partnerId: string, settlementId: string): any;
  sendAllSettlementEmails(partnerId: string, eventId: string): any;
  downloadSettlementsPdf(partnerId: string, eventId: string, settlementId?: string, placeId?: string): any;
  getRafflePreview(
    placeId: string,
    config: { raffleStart: string; raffleEnd: string; totalPrizes: number; schedule: ScheduleDTO[] }
  ): any;
  getRaffleIntervals(placeId: string, eventId: string): any;
  editRaffleIntervals(placeId: string, eventId: string, intervals: RaffleIntervalDTO[], editDate: number): any;
  cloneEventAction(eventId: string, placeId: string): any;
  clearEventData(): any;
  clearEventStatsModalData(): any;
  clearRaffleIntervals(): any;
  clearPlaceEventsError(): any;
}

/*
  class EventsActions that implements redux actions defined in IEventsActions interface
*/
class EventsActions implements IEventsActions {
  /*
    @function addEventForPlaceAction => Redux action that adds a new event for a place
      @accepts event : object containing all the information completed in the add event form
               placeId : string representing the id of the place to whom you add the event
      @returns Promise
  */
  addEventForPlaceAction(event: any, placeId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.SAVE_EVENT
      });
      AlertUtil.simple(
        msg('reduxMessages.events.addEventPending', 'The promotional material is being created, please wait!'),
        'info',
        3000,
        true
      );
      logger.msg('Add event for place action, route:/places/placeId/events', 'POST');
      await Server.post(`places/${placeId}/events`, event)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.SAVE_EVENT_SUCCESS,
            payload: response.data as any
          });
          const path = generatePath(partnerRoutes.EVENTS.default, { placeId });
          dispatch(push(path));
          AlertUtil.updateContent(
            msg('reduxMessages.events.addEventSuccess', 'The promotional material was successfully created!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Add event for place action, route:/places/placeId/events', 'POST');
          let errorMessage = msg(
            'reduxMessages.events.addEventError',
            'Due to an error, the promotional material could not be created!'
          );
          if (error.response?.data?.ERROR?.code === ERROR_CODES.UNAUTHORIZED) {
            errorMessage = msg(
              'reduxMessages.events.addEventUnathorizedError',
              'You do not have partner admin rights for one of the selected places!'
            );
          }
          AlertUtil.updateContent(errorMessage, 'error');
          dispatch({
            type: EventsStore.ActionTypes.SAVE_EVENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function deleteEventForPlaceAction => Redux action that deletes an event from a place
      @accepts eventId : string representing the id of the event you want to delete
               partnerId : string representing the id of the partner of which the place belongs to
               placeId : string representing the id of the place you want to delete the event from
      @returns Promise
  */
  deleteEventForPlaceAction(eventId: string, partnerId: string, placeId: string) {
    return async (dispatch: Dispatch<any>, getState: () => any) => {
      dispatch({
        type: EventsStore.ActionTypes.DELETE_EVENT
      });
      AlertUtil.simple(msg('alertMessages.eventBeingDeleted', 'Promotional material is being deleted...'), 'info');
      logger.msg('Delete event for place action, route:/places/placeId/events/eventsId', 'DELETE');
      await Server.delete(`places/${placeId}/events/${eventId}`)
        .then(() => {
          dispatch({
            type: EventsStore.ActionTypes.DELETE_EVENT_SUCCESS,
            payload: eventId
          });
          AlertUtil.updateContent(
            msg('alertMessages.eventDeleted', 'The promotional material was succesfully deleted!'),
            'success'
          );
          const state = getState();
          const { limit, page_number } = state?.events.events_list;
          const showExpired = state?.auth.events_list_expired;
          const newCriteria = queryStringToCriteria(window.location.search, ['title']);
          dispatch(
            EventsStore.actions.getEventsForPlaceAction(
              placeId,
              showExpired,
              limit * page_number,
              0,
              newCriteria.sort || 'created,-1',
              {
                filters: newCriteria.filters,
                search: newCriteria.search
              }
            )
          );
        })
        .catch(error => {
          logger.err('Delete event for place action, route:/places/placeId/events/eventsId', 'DELETE');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.events.deleteEventError',
              'Due to an error, the promotional material could not be deleted!'
            ),
            'error'
          );
          dispatch({
            type: EventsStore.ActionTypes.DELETE_EVENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function editEventForPlaceAction => Redux action that edits an existing event from a place
      @accepts event : object containing the new information you want to overwrite for an event
               placeId : string representing the id of the place from whom you edit the event
      @returns Promise
  */
  editEventForPlaceAction(event: any, placeId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.SAVE_EVENT
      });
      AlertUtil.simple(
        msg('reduxMessages.events.editEventPending', 'The promotional material is being updated, please wait!'),
        'info'
      );
      logger.msg('Edit event for place action, route:/places/placeId/events/eventsId', 'PUT');
      await Server.put(`places/${placeId}/events/${event._id}`, event)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.SAVE_EVENT_SUCCESS,
            payload: response.data as any
          });
          AlertUtil.updateContent(
            msg('reduxMessages.events.editEventSuccess', 'The promotional material was successfully updated!'),
            'success'
          );
          const path = generatePath(partnerRoutes.EVENTS.default, { placeId });
          dispatch(push(path));
        })
        .catch(error => {
          logger.err('Edit event for place action, route:/places/placeId/events/eventsId', 'PUT');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.events.editEventError',
              'Due to an error, the promotional material could not be updated!'
            ),
            'error'
          );
          dispatch({
            type: EventsStore.ActionTypes.SAVE_EVENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getEventForPlaceAction => Redux action that gets the information of an existing event from a place
      @accepts eventId : string containing the id of the event you want to get information about
               placeId : string representing the id of the place from whom you get the event info
      @returns Promise
  */
  getEventForPlaceAction(eventId: string, placeId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.GET_EVENT
      });
      logger.msg('Get event for place action, route:/places/placeId/events/eventId', 'GET');
      Server.get(`places/${placeId}/events/${eventId}`)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.GET_EVENT_SUCCESS,
            payload: response.data as EventDTO
          });
        })
        .catch(error => {
          logger.err('Get event for place action, route:/places/placeId/events/eventId', 'GET');
          AlertUtil.simple(
            msg(
              'reduxMessages.events.getEventError',
              'Due to an error, the promotional material data could not be loaded!'
            ),
            'error',
            2000
          );
          dispatch({
            type: EventsStore.ActionTypes.GET_EVENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getEventsForPlaceAction => Redux action that gets the list of events from a place
      @accepts placeId : string representing the id of the place from whom you get the list of events
      (optional) limit, skip, sort, criteria : params for pagination
      @returns Promise
  */
  getEventsForPlaceAction(
    placeId: string,
    showExpired: boolean,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string | { [key: string]: string | undefined } }
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: EventsStore.ActionTypes.GET_EVENTS });
      try {
        let url;
        if (!limit) {
          url = `places/${placeId}/events?expired=${showExpired}&pagination=false`;
        } else {
          url = `places/${placeId}/events?expired=${showExpired}&limit=${limit}`;
        }
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get events list for place action, route:/places/placeId/events', 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: EventsStore.ActionTypes.GET_EVENTS_SUCCESS,
          payload: response.data as PaginationDTO<EventDTO>
        });
      } catch (error) {
        logger.err('Get events list for place action, route:/places/placeId/events', 'GET');
        AlertUtil.simple(
          msg(
            'reduxMessages.events.getEventsError',
            'Due to an error, the promotional materials list could not be loaded!'
          ),
          'error',
          2000
        );
        dispatch({
          type: EventsStore.ActionTypes.GET_EVENTS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  /*
    @function redirectToAddEventForPlaceAction => Redux action that redirects user to add event form page
      @accepts placeId : string representing the id of the place you want to add the event to
      @returns null
  */
  redirectToAddEventForPlaceAction(placeId: string) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(partnerRoutes.EVENTS.subroutes.ADD.path, { placeId });
      dispatch(push(path));
    };
  }

  /*
    @function redirectToEditEventForPlaceAction => Redux action that redirects user to edit event form page
      @accepts placeId : string representing the id of the place of which the event belongs to
               eventId : string representing the id of the event you want to edit
      @returns null
  */
  redirectToEditEventForPlaceAction(eventId: string, placeId: string) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(partnerRoutes.EVENTS.subroutes.EDIT.path, { placeId, eventId });
      dispatch(push(path));
    };
  }

  editEventStatusAction(placeId: string, eventId: string, active: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.CHANGE_EVENT_STATUS
      });
      AlertUtil.simple(
        msg('reduxMessages.events.editEventPending', 'The promotional material is being updated, please wait!'),
        'info'
      );
      logger.msg('Edit event status action, route:/places/placeId/events/eventId', 'PUT');
      await Server.put(`places/${placeId}/events/${eventId}`, active)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.CHANGE_EVENT_STATUS_SUCCESS,
            payload: response.data as EventDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.events.editEventSuccess', 'The promotional material was successfully updated!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Edit event status action, route:/places/placeId/events/eventId', 'PUT');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.events.editEventStatusError',
              'Due to an error, the promotional material status could not be updated!'
            ),
            'error'
          );
          dispatch({
            type: EventsStore.ActionTypes.CHANGE_EVENT_STATUS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  exportEventsInExcel(placeId: string, startDate: number, endDate: number) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.EXPORT_EVENTS
      });
      const startDateFormat = moment(startDate).format('DD-MM-YYYY');
      const endDateFormat = moment(endDate).format('DD-MM-YYYY');
      const name = `Report Events ${startDateFormat} - ${endDateFormat}.xlsx`;
      let url = `/places/${placeId}/report-events?startDate=${startDate}&endDate=${endDate}`;
      logger.msg('Export events in excel action, route:/places/placeId/report-events', 'GET');
      Server.downloadReports(url, name)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.EXPORT_EVENTS_SUCCESS,
            payload: response
          });
        })
        .catch(error => {
          logger.err('Export events in excel action, route:/places/placeId/report-events', 'GET');
          dispatch({
            type: EventsStore.ActionTypes.EXPORT_EVENTS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getMemberStatsForCampaign(
    placeId: string,
    eventId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: EventsStore.ActionTypes.GET_CAMPAIGN_MEMBERS });
      if (!limit) {
        limit = 10;
      }
      let url = `places/${placeId}/event-triggers/${eventId}?limit=${limit}`;
      if (skip) {
        url += `&skip=${skip}`;
      }
      if (sort) {
        url += `&sort=${sort}`;
      }
      if (criteria) {
        url += composedCriteriaBuilder(criteria);
      }
      logger.msg('Get member statistics for campaign action, route:/places/placeId/event-triggers/eventId', 'GET');
      Server.get(url)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.GET_CAMPAIGN_MEMBERS_SUCCESS,
            payload: response.data as PaginationDTO<any>
          });
        })
        .catch(error => {
          logger.err('Get member statistics for campaign action, route:/places/placeId/event-triggers/eventId', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.members.getMembersError', 'Due to an error, the members list could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: EventsStore.ActionTypes.GET_CAMPAIGN_MEMBERS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getPartnerSettlements(
    partnerId: string,
    eventId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.PARTNER_SETTLEMENTS
      });
      if (!limit) {
        limit = 10;
      }
      let url = `partners/${partnerId}/settlements/${eventId}?limit=${limit}`;
      if (skip) {
        url += `&skip=${skip}`;
      }
      if (sort) {
        url += `&sort=${sort}`;
      }
      if (criteria) {
        url += composedCriteriaBuilder(criteria);
      }
      logger.msg('Get settlements action, route:/partners/partnerId/settlements/eventId', 'GET');
      Server.get(url)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.PARTNER_SETTLEMENTS_SUCCESS,
            payload: response.data as PaginationDTO<SettlementDTO>
          });
        })
        .catch(error => {
          logger.err('Get settlements action, route:/partners/partnerId/settlements/eventId', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.events.settlementsError', 'Due to an error, the settlements list could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: EventsStore.ActionTypes.PARTNER_SETTLEMENTS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  editSettlement(partnerId: string, settlementId: string, settlement: SettlementDTO) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.EDIT_SETTLEMENT
      });
      logger.msg('Edit settlement action, route:/partners/partnerId/settlements/settlementId', 'PUT');
      Server.put(`partners/${partnerId}/settlements/${settlementId}`, settlement)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.EDIT_SETTLEMENT_SUCCESS,
            payload: response.data as SettlementDTO
          });
        })
        .catch(error => {
          logger.err('Edit settlement action, route:/partners/partnerId/settlements/settlementId', 'PUT');
          AlertUtil.simple(
            msg('reduxMessages.events.editSettlementError', 'Due to an error, the settlement could not be updated!'),
            'error'
          );
          dispatch({
            type: EventsStore.ActionTypes.EDIT_SETTLEMENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  sendSettlementEmail(partnerId: string, settlementId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.SEND_SETTLEMENT_EMAIL
      });
      AlertUtil.simple(
        msg('reduxMessages.events.sendSettlementEmailPending', 'The email is being sent, please wait!'),
        'info'
      );
      logger.msg('Send settlement email action, route:/partners/partnerId/settlements/send/settlementId', 'GET');
      Server.get(`partners/${partnerId}/settlements/send/${settlementId}`)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.SEND_SETTLEMENT_EMAIL_SUCCESS,
            payload: response.data as SettlementDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.events.sendSettlementEmailSuccess', 'The email was successfully sent!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Send settlement email action, route:/partners/partnerId/settlements/send/settlementId', 'GET');
          AlertUtil.updateContent(
            msg('reduxMessages.events.sendSettlementEmailError', 'Due to an error, the email could not be sent!'),
            'error'
          );
          dispatch({
            type: EventsStore.ActionTypes.SEND_SETTLEMENT_EMAIL_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  sendAllSettlementEmails(partnerId: string, eventId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.SEND_ALL_EMAILS
      });
      AlertUtil.simple(
        msg('reduxMessages.events.sendAllEmailsPending', 'The emails are being sent, please wait!'),
        'info',
        3000
      );
      logger.msg('Send settlement email action, route:/partners/partnerId/settlements/send/settlementId', 'GET');
      Server.get(`partners/${partnerId}/settlements/send-all/${eventId}`)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.SEND_ALL_EMAILS_SUCCESS
          });
          AlertUtil.updateContent(
            msg('reduxMessages.events.sendAllEmailsSuccess', 'The emails were successfully sent!'),
            'success'
          );
          dispatch(EventsStore.actions.getPartnerSettlements(partnerId, eventId, 10, 0));
        })
        .catch(error => {
          if (error.response?.data?.ERROR?.code === 'NOT_VALID') {
            AlertUtil.updateContent(
              msg('reduxMessages.events.sendEmailsNotValid', 'Some places do not have a settled amount!'),
              'error'
            );
          } else {
            AlertUtil.updateContent(
              msg('reduxMessages.events.sendAllEmailsError', 'Due to an error, the emails could not be sent!'),
              'error'
            );
          }
          logger.err('Send settlement email action, route:/partners/partnerId/settlements/send/settlementId', 'GET');
          dispatch({
            type: EventsStore.ActionTypes.SEND_ALL_EMAILS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  approveSettlementEmail(partnerId: string, settlementId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.APPROVE_SETTLEMENT_EMAIL
      });
      logger.msg('Send settlement email action, route:/partners/partnerId/settlements/send/settlementId', 'GET');
      Server.get(`partners/${partnerId}/settlements/approve/${settlementId}`)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.APPROVE_SETTLEMENT_EMAIL_SUCCESS,
            payload: response.data as SettlementDTO
          });
        })
        .catch(error => {
          logger.err('Send settlement email action, route:/partners/partnerId/settlements/send/settlementId', 'GET');
          dispatch({
            type: EventsStore.ActionTypes.APPROVE_SETTLEMENT_EMAIL_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  downloadSettlementsPdf(partnerId: string, eventId: string, settlementId?: string, placeId?: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.DOWNLOAD_SETTLEMENTS_PDF
      });
      const name = `${msg('settlements.settlementsReport', 'Settlements Report')}.pdf`;
      let url = `/partners/${partnerId}/settlements/download/${eventId}`;
      if (settlementId) {
        url += `?settlementId=${settlementId}`;
      } else {
        url += `?placeId=${placeId}`;
      }
      logger.msg('Download settlement pdf action, route:/partners/partnerId/settlements/download/eventId', 'GET');
      Server.downloadReports(url, name)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.DOWNLOAD_SETTLEMENTS_PDF_SUCCESS,
            payload: response
          });
        })
        .catch(error => {
          logger.err('Download settlement pdf action, route:/partners/partnerId/settlements/download/eventId', 'GET');
          dispatch({
            type: EventsStore.ActionTypes.DOWNLOAD_SETTLEMENTS_PDF_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getRafflePreview(
    placeId: string,
    config: { raffleStart: string; raffleEnd: string; totalPrizes: number; schedule: ScheduleDTO[] }
  ) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.GET_RAFFLE_PREVIEW
      });
      logger.msg('Get raffle preview for event, route:/places/placeId/preview-raffle-schedule', 'POST');
      Server.post(`places/${placeId}/preview-raffle-schedule`, config)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.GET_RAFFLE_PREVIEW_SUCCESS,
            payload: response.data as RaffleIntervalDTO[]
          });
        })
        .catch(error => {
          logger.err('Get raffle preview for event, route:/places/placeId/preview-raffle-schedule', 'POST');
          dispatch({
            type: EventsStore.ActionTypes.GET_RAFFLE_PREVIEW_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getRaffleIntervals(placeId: string, eventId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.GET_RAFFLE_PREVIEW
      });
      logger.msg('Get raffle intervals for event, route:/places/placeId/raffle-intervals/eventId', 'GET');
      Server.get(`places/${placeId}/raffle-intervals/${eventId}`)
        .then((response: any) => {
          dispatch({
            type: EventsStore.ActionTypes.GET_RAFFLE_PREVIEW_SUCCESS,
            payload: response.data as RaffleIntervalDTO[]
          });
        })
        .catch(error => {
          logger.err('Get raffle intervals for event, route:/places/placeId/raffle-intervals/eventId', 'GET');
          dispatch({
            type: EventsStore.ActionTypes.GET_RAFFLE_PREVIEW_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  editRaffleIntervals(placeId: string, eventId: string, intervals: RaffleIntervalDTO[], editDate: number) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.UPDATE_RAFFLE_INTERVALS
      });
      logger.msg('Edit raffle intervals for event, route:/places/placeId/raffle-intervals/eventId', 'POST');
      Server.post(`places/${placeId}/raffle-intervals/${eventId}`, { intervals, date: editDate })
        .then((response: any) => {
          if (!response.data.ok && response.data.code === 'AGENDA_IS_RUNNING') {
            AlertUtil.simple(
              msg(
                'reduxMessages.events.editRaffleIntervalsFailed',
                'The intervals have been updated by the agenda and could not be edited! Please try again!'
              ),
              'info'
            );
            dispatch({
              type: EventsStore.ActionTypes.UPDATE_RAFFLE_INTERVALS_FAILED,
              payload: response.data.code
            });
            dispatch(EventsStore.actions.getRaffleIntervals(placeId, eventId));
          } else {
            dispatch({
              type: EventsStore.ActionTypes.UPDATE_RAFFLE_INTERVALS_SUCCESS,
              payload: response.data
            });
          }
        })
        .catch(error => {
          logger.err('Edit raffle intervals for event, route:/places/placeId/raffle-intervals/eventId', 'POST');
          dispatch({
            type: EventsStore.ActionTypes.UPDATE_RAFFLE_INTERVALS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  cloneEventAction(eventId: string, placeId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: EventsStore.ActionTypes.GET_EVENT
      });
      logger.msg('Get event for place action, route:/places/placeId/events/eventId', 'GET');
      Server.get(`places/${placeId}/events/${eventId}`)
        .then((response: any) => {
          const newEvent = response.data;
          newEvent.title += ' - Copy';
          [
            '_id',
            'attending',
            'autopilotStats',
            'created',
            'deleted',
            'isActive',
            'stats',
            'sent',
            'updatedAt',
            'startDate',
            'endDate',
            'dispatch',
            'hash',
            'sendReceipt'
          ].forEach(item => delete newEvent[item]);
          if (newEvent.raffle) {
            delete newEvent.raffle.cursor;
          }
          dispatch({
            type: EventsStore.ActionTypes.GET_EVENT_SUCCESS,
            payload: newEvent
          });
        })
        .catch(error => {
          logger.err('Get event for place action, route:/places/placeId/events/eventId', 'GET');
          AlertUtil.simple(
            msg(
              'reduxMessages.events.getEventError',
              'Due to an error, the promotional material data could not be loaded!'
            ),
            'error',
            2000
          );
          dispatch({
            type: EventsStore.ActionTypes.GET_EVENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  clearEventData() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: EventsStore.ActionTypes.CLEAR_EVENT_DATA });
    };
  }

  clearEventStatsModalData() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: EventsStore.ActionTypes.CLEAR_MODAL_DATA });
    };
  }

  clearRaffleIntervals() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: EventsStore.ActionTypes.CLEAR_RAFFLE_DATA });
    };
  }

  clearPlaceEventsError() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: EventsStore.ActionTypes.CLEAR_EVENTS_ERROR });
    };
  }
}

const eventsActions = new EventsActions();
export default eventsActions;
