import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { defaultReducer, history } from './reducers';

/*
  Redux store creation and Redux Devtools Extension Setup
*/
const routerMid = routerMiddleware(history);

const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(defaultReducer, composeEnhancer(applyMiddleware(thunk, routerMid)));
