import triggersActions, { ITriggersActions } from './actions';
import triggersReducer from './reducer';
import { PaginationDTO, TriggerDTO } from '../types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TriggersStore {
  export type IState = {
    triggers_list: PaginationDTO<TriggerDTO>;
    triggers_list_pending: boolean;
    triggers_list_error: string | null;
  };

  export const initialState: IState = {
    triggers_list: new PaginationDTO(),
    triggers_list_pending: false,
    triggers_list_error: null
  };

  export enum ActionTypes {
    GET_TRIGGERS = 'GET_TRIGGERS',
    GET_TRIGGERS_SUCCESS = 'GET_TRIGGERS_SUCCESS',
    GET_TRIGGERS_FAILED = 'GET_TRIGGERS_FAILED',
    CLEAR_TRIGGERS_ERROR = 'CLEAR_TRIGGERS_ERROR'
  }

  export const actions = triggersActions;
  export const reducer = triggersReducer;
  export interface IActions extends ITriggersActions {}
}

export * from './actions';
