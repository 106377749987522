import { MembersStore } from './';
import { PaginationDTO } from '../types';

/*
  membersReducer = reducer for all actionTypes for Members
*/
function membersReducer(
  state: MembersStore.IState = MembersStore.initialState,
  action: IAction<any>
): MembersStore.IState {
  switch (action.type) {
    case MembersStore.ActionTypes.GET_MEMBERS: {
      return {
        ...state,
        members_list_pending: true,
        members_list_error: null
      };
    }
    case MembersStore.ActionTypes.GET_MEMBERS_SUCCESS: {
      return {
        ...state,
        members_list_pending: false,
        members_list_error: null,
        members_list: action.payload
      };
    }
    case MembersStore.ActionTypes.GET_MEMBERS_FAILED: {
      return {
        ...state,
        members_list_pending: false,
        members_list: new PaginationDTO(),
        members_list_error: action.payload
      };
    }
    case MembersStore.ActionTypes.GET_MEMBER: {
      return {
        ...state,
        member_pending: true,
        member_error: null
      };
    }
    case MembersStore.ActionTypes.GET_MEMBER_SUCCESS: {
      return {
        ...state,
        member_pending: false,
        member_error: null,
        member: action.payload
      };
    }
    case MembersStore.ActionTypes.GET_MEMBER_FAILED: {
      return {
        ...state,
        member_pending: false,
        member: null,
        member_error: action.payload
      };
    }
    case MembersStore.ActionTypes.CHANGE_MEMBER_STATUS: {
      return {
        ...state,
        member_change_status_pending: true,
        member_change_status: null
      };
    }
    case MembersStore.ActionTypes.CHANGE_MEMBER_STATUS_SUCCESS: {
      // Action happened from place admin -> memberships
      if (state.members_list.results) {
        const index = (state.members_list?.results || [])
          .map(x => {
            return x._id;
          })
          .indexOf(action.payload._id);
        let new_list: PaginationDTO<any> = state.members_list;
        new_list.results[index] = action.payload;
        return {
          ...state,
          member_change_status: action.payload,
          member_change_status_pending: false,
          member_change_status_error: null,
          members_list: { ...new_list }
        };
      }

      // Action happened from superadmin -> users -> edit user
      if (state.memberships_for_user_list.results) {
        return {
          ...state,
          memberships_for_user_list_pending: false,
          memberships_for_user_list_error: null,
          memberships_for_user_list: {
            ...state.memberships_for_user_list,
            results: state.memberships_for_user_list.results.map(membership =>
              membership._id === action.payload._id
                ? { ...action.payload, placeName: membership.placeName }
                : membership
            )
          }
        };
      }

      return state;
    }
    case MembersStore.ActionTypes.CHANGE_MEMBER_STATUS_FAILED: {
      return {
        ...state,
        member_change_status: null,
        member_change_status_pending: false,
        member_change_status_error: action.payload
      };
    }
    case MembersStore.ActionTypes.GET_MEMBERSHIPS_FOR_USER: {
      return {
        ...state,
        memberships_for_user_list_pending: true,
        memberships_for_user_list_error: null
      };
    }
    case MembersStore.ActionTypes.GET_MEMBERSHIPS_FOR_USER_SUCCESS: {
      return {
        ...state,
        memberships_for_user_list: action.payload,
        memberships_for_user_list_pending: false,
        memberships_for_user_list_error: null
      };
    }
    case MembersStore.ActionTypes.GET_MEMBERSHIPS_FOR_USER_FAILED: {
      return {
        ...state,
        memberships_for_user_list: new PaginationDTO(),
        memberships_for_user_list_pending: false,
        memberships_for_user_list_error: action.payload
      };
    }
    case MembersStore.ActionTypes.GET_FAVOURITE_MOMENTS: {
      return {
        ...state,
        favouriteMomentsPending: true,
        favouriteMomentsError: null
      };
    }
    case MembersStore.ActionTypes.GET_FAVOURITE_MOMENTS_SUCCESS: {
      return {
        ...state,
        favouriteMoments: action.payload,
        favouriteMomentsPending: false,
        favouriteMomentsError: null
      };
    }
    case MembersStore.ActionTypes.GET_FAVOURITE_MOMENTS_FAILED: {
      return {
        ...state,
        favouriteMoments: new PaginationDTO(),
        favouriteMomentsPending: false,
        favouriteMomentsError: action.payload
      };
    }
    case MembersStore.ActionTypes.CLEAR_MEMBERS_ERROR: {
      return {
        ...state,
        members_list_error: null
      };
    }
    case MembersStore.ActionTypes.EXPORT_MEMBERS: {
      return {
        ...state,
        export_members_pending: true,
        export_members_error: null
      };
    }
    case MembersStore.ActionTypes.EXPORT_MEMBERS_SUCCESS: {
      return {
        ...state,
        export_members_pending: false,
        export_members_error: null
      };
    }
    case MembersStore.ActionTypes.EXPORT_MEMBERS_FAILED: {
      return {
        ...state,
        export_members_pending: false,
        export_members_error: action.payload
      };
    }
    default:
      return state;
  }
}

export default membersReducer;
