import * as React from 'react';

import { AppStore, UsersStore, reduxContainer } from '../../../../appRedux';
import { AdvancedListComponent, CardComponent } from '../../../shared';
import { AlertUtil, getFormatDate, msg } from '../../../../utils';
import { PaginationDTO } from '../../../../appRedux/types';

interface IProps extends UsersStore.IState {}
interface IState {}

class UsersListComponent extends React.Component<IProps & UsersStore.IActions, IState> {
  isData() {
    return this.props.users_list && this.props.users_list.results && this.props.users_list.results.length;
  }

  parseUsers(list: PaginationDTO<any>): any {
    if (!this.isData()) {
      return [];
    }
    return {
      ...list,
      results: list.results.map(item => {
        return {
          created: getFormatDate(item.created),
          lastname: item.lastname,
          firstname: item.firstname,
          phone: item.phone,
          email: item.email,
          emailStatus:
            item.emailStatus === 'REGISTERED'
              ? msg('userTypes.registered', 'Registered')
              : item.emailStatus === 'PENDING_ACTIVATION'
              ? msg('userTypes.pending', 'Pending Activation')
              : item.emailStatus === 'ACTIVE'
              ? msg('userTypes.active', 'Active')
              : item.emailStatus === 'SUSPENDED'
              ? msg('userTypes.suspended', 'Suspended')
              : item.emailStatus === 'DEACTIVATED'
              ? msg('userTypes.deactivated', 'Deactivated')
              : '',
          _id: item._id,
          deviceInfo:
            item.deviceInfo?.systemName && item.deviceInfo?.systemVersion
              ? `${item.deviceInfo?.systemName} ${item.deviceInfo?.systemVersion}`
              : 'N/A',
          appInfo:
            item.deviceInfo?.appVersion && item.deviceInfo?.buildNumber
              ? `${item.deviceInfo?.appVersion} (${item.deviceInfo?.buildNumber})`
              : 'N/A'
        };
      })
    };
  }

  editAction = (event: any) => {
    this.props.redirectToEditUserAction(event.currentTarget.getAttribute('id'));
  };

  deleteAction = async (event: any) => {
    const id = event.currentTarget.getAttribute('id');
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.userDeleteConfirmation', 'You are about to delete an user!')
    );
    if (hasConfirmation) {
      this.props.deleteActionForUser(id);
    }
  };

  getActions() {
    return [
      {
        label: msg('general.edit', 'Edit'),
        btn: 'btn-success',
        icon: 'edit',
        onClick: this.editAction
      },
      {
        label: msg('general.delete', 'Delete'),
        btn: 'btn-danger',
        icon: 'delete',
        ref: 'deleteUser',
        onClick: this.deleteAction
      }
    ];
  }

  render() {
    const users = this.parseUsers(this.props.users_list);
    return (
      <CardComponent
        title={msg('sidebar.users', 'Users')}
        error={!!this.props.users_list_error}
        headerIcon="list"
        needsTitle={true}
      >
        <AdvancedListComponent
          fields={{
            created: msg('accountInfo.created', 'Created'),
            lastname: msg('accountInfo.lastname', 'Last name'),
            firstname: msg('accountInfo.firstname', 'First name'),
            phone: msg('accountInfo.phone', 'Phone'),
            email: msg('accountInfo.email', 'Email'),
            emailStatus: msg('accountInfo.status', 'Status'),
            deviceInfo: 'Device',
            appInfo: 'App Info'
          }}
          actions={this.getActions()}
          list={users}
          pending={this.props.users_list_pending}
          error={this.props.users_list_error}
          get={this.props.getUsersListAction}
          limit={30}
          sort={{
            fields: ['created', 'lastname', 'firstname', 'email', 'emailStatus'],
            default: 'lastname,1'
          }}
          search={['email', 'lastname', 'firstname', 'phone']}
          filters={[
            {
              field: 'emailStatus',
              value: [
                {
                  _id: '',
                  name: msg('userTypes.allUsers', 'All users')
                },
                {
                  _id: 'REGISTERED',
                  name: msg('userTypes.registeredUsers', 'Registered Users')
                },
                {
                  _id: 'PENDING_ACTIVATION',
                  name: msg('userTypes.pendingUsers', 'Pending Users')
                },
                {
                  _id: 'ACTIVE',
                  name: msg('userTypes.activeUsers', 'Active Users')
                },
                {
                  _id: 'SUSPENDED',
                  name: msg('userTypes.suspendedUsers', 'Suspended Users')
                },
                {
                  _id: 'DEACTIVATED',
                  name: msg('userTypes.deactivatedUsers', 'Deactivated Users')
                }
              ]
            },
            {
              field: 'deviceInfo.systemName',
              value: [
                { _id: '', name: msg('accountInfo.allDevices', 'All devices') },
                { _id: 'iOS', name: 'iOS' },
                { _id: 'Android', name: 'Android' }
              ]
            },
            {
              field: 'registerType',
              value: [
                { _id: '', name: msg('userTypes.allRegisterTypes', 'All register types') },
                { _id: '0', name: 'Develop' },
                { _id: '1', name: 'Mobile' },
                { _id: '2', name: 'Dashboard' },
                { _id: '3', name: 'Onboarding' }
              ]
            }
          ]}
        />
      </CardComponent>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    users_list: state.users.users_list,
    users_list_pending: state.users.users_list_pending,
    users_list_error: state.users.users_list_error
  };
}

const dispatchToProps = {
  getUsersListAction: UsersStore.actions.getUsersListAction,
  redirectToEditUserAction: UsersStore.actions.redirectToEditUserAction,
  deleteActionForUser: UsersStore.actions.deleteActionForUser
};

export default reduxContainer(UsersListComponent, mapStateToProps, dispatchToProps);
