import React from 'react';

import { msg } from '../../../../../../../utils/index';
import { DateTimePickerComponent } from '../../../../../../shared/index';

export function DateCondition({ listItem, onValueChange, index }: any) {
  return (
    <div>
      <div className="col-sm-4" style={{ marginRight: 10 }}>
        <DateTimePickerComponent
          date={listItem.value}
          label={msg('general.chooseDate', 'Choose a date')}
          getSelectedDate={(date: any) => onValueChange(date, index, 'value')}
          disablePast={true}
        />
      </div>
    </div>
  );
}
