import {
  IAutopilotEventsBase,
  AutopilotEventType,
  IAutopilotEventsTriggerCreated,
  IAutopilotEventsRewardGiven,
  IAutopilotEventsPointsGiven,
  IAutopilotEventsMemberStatusChanged,
  IAutopilotEventsMessageSent,
  ICheckinCreated
} from './api.definitions';
import { IAutopilotLogsItem } from './reducer.definitions';
import { msg } from '../../utils';

/*
    Items provided by the API don't have the same property structure.
    In order for our data to be represented uniformly, we are mapping the event data to our own store structure
*/
export const ToAutopilotLogsListItem = (item: IAutopilotEventsBase): IAutopilotLogsItem => {
  if (item.eventType === AutopilotEventType.TriggerCreated) {
    const data = item as IAutopilotEventsTriggerCreated;
    return {
      date: data.date,
      type: data.eventType,
      placeId: data.placeId,
      autopilotId: data.autopilotId,
      memberId: data.memberId,
      reward: data.action
    };
  }

  if (item.eventType === AutopilotEventType.RewardGiven) {
    const data = item as IAutopilotEventsRewardGiven;
    return {
      date: data.date,
      type: data.eventType,
      placeId: data.placeId,
      autopilotId: data.autopilotId,
      memberId: data.memberId,
      reward: `Reward: ${data.rewardTitle}, coins: ${data.rewardPoints}`
    };
  }

  if (item.eventType === AutopilotEventType.PointsGiven) {
    const data = item as IAutopilotEventsPointsGiven;
    return {
      date: data.date,
      type: data.eventType,
      placeId: data.placeId,
      autopilotId: data.autopilotId,
      memberId: data.memberId,
      reward: `Coins: ${data.points}`
    };
  }

  if (item.eventType === AutopilotEventType.MemberStatusChanged) {
    const data = item as IAutopilotEventsMemberStatusChanged;
    return {
      date: data.date,
      type: data.eventType,
      placeId: data.placeId,
      autopilotId: data.autopilotId,
      memberId: data.memberId,
      reward: `Status: ${data.memberStatus}`
    };
  }

  if (item.eventType === AutopilotEventType.MessageSent) {
    const data = item as IAutopilotEventsMessageSent;
    return {
      date: data.date,
      type: data.eventType,
      placeId: data.placeId,
      autopilotId: data.autopilotId,
      memberId: data.memberId,
      reward: `Message: "${data.message}", channels: ${data.channels.join(', ')}`
    };
  }

  if (item.eventType === AutopilotEventType.CheckinCreated) {
    const data = item as ICheckinCreated;
    return {
      date: data.date,
      type: data.eventType,
      placeId: data.placeId,
      autopilotId: '',
      memberId: data.memberId,
      reward: msg('activity.changes.checkinCreated', `Received ${data.points} coins`, { points: `${data.points}` })
    };
  }

  // If nothing matches, then we will use the most basic details
  return {
    date: item.date,
    type: item.eventType,
    placeId: '',
    autopilotId: '',
    memberId: '',
    reward: ''
  };
};
