import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AuthStore, TranslationsStore, AppStore, reduxContainer } from '../../../appRedux';
import { NavAuthComponent, FooterAuthComponent, ValidateEmailComponent } from './components';

import backgroundImage from '../../../assets/images/background.jpg';

interface IProps extends AuthStore.IState, TranslationsStore.IState {
  match: any;
}
interface IState {}

class ValidateEmailContainer extends React.Component<
  IProps & RouteComponentProps & AuthStore.IActions & TranslationsStore.IActions,
  IState
> {
  render() {
    const navAuthConfig = {
      title: 'ReGage Partner',
      initial_language: this.props.initial_language,
      changeLanguage: this.props.changeLanguageAction
    };

    const validate = {
      pending: this.props.validate_email_pending,
      error: this.props.validate_email_error,
      action: this.props.validateEmailAction
    };

    return (
      <div className="partner-auth off-canvas-sidebar">
        <NavAuthComponent config={navAuthConfig} />
        <div className="wrapper wrapper-full-page">
          <div className="full-page login-page" filter-color="black">
            <div className="content" style={{ paddingTop: '28vh' }}>
              <ValidateEmailComponent match={this.props.match} validate={validate} />
            </div>
            <FooterAuthComponent />
            <div className="full-page-background" style={{ backgroundImage: `url(${backgroundImage})` }} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    validate_email_pending: state.auth.validate_email_pending,
    validate_email_error: state.auth.validate_email_error,
    initial_language: state.translations.initial_language
  };
}

const dispatchToProps = {
  validateEmailAction: AuthStore.actions.validateEmailAction,
  redirectAction: AuthStore.actions.redirectAction,
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction
};

export default reduxContainer(ValidateEmailContainer, mapStateToProps, dispatchToProps);
