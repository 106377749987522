import { Dispatch } from 'redux';
import { push } from 'connected-react-router';

import { Server, AlertUtil, msg, logger } from '../../utils';
import { AutopilotStore } from './';
import { AutopilotDTO } from '../types';
import { partnerRoutes } from '../../modules/layouts/routes';
import { generatePath } from 'react-router-dom';

/*
  IAutopilotActions interface definition, which contains every redux action asociated with Autopilot State.
*/
export interface IAutopilotActions {
  /*
    General actions and super admin actions
  */
  getAutopilotRuleAction(autopilotRuleId: string): any;
  runAutopilotVerification(): any;

  /*
    Actions specific for a place
  */
  addAutopilotRuleForPlaceAction(autopilotRule: AutopilotDTO, placeId: string): any;
  deleteAutopilotRuleForPlaceAction(autopilotRuleId: string, placeId: string, partnerId: string): any;
  getAutopilotListForPlaceAction(placeId: string, partnerId: string): any;
  redirectToAddAutopilotRuleActionForPlace(placeId: string): any;
  redirectToEditAutopilotRuleForPlaceAction(autopilotRuleId: string, placeId: string): any;
  updateAutopilotRuleForPlaceAction(autopilotRule: AutopilotDTO, placeId: string, partnerId: string): any;
  updateAutopilotStatusForPlaceAction(status: any): any;
  updateAllSimpleAutopilotsRuleForPlaceAction(autopilotRules: AutopilotDTO[], placeId: string): any;
}

/*
  class AutopilotActions that implements redux actions defined in IAutopilotActions interface
*/
class AutopilotActions implements IAutopilotActions {
  /*
    @function getAutopilotRuleAction => Redux action that gets a default autopilot rule from both redux and server
      @accepts autopilotRuleId : string representing the Id of the autopilot to be brought
      @returns Promise
  */
  getAutopilotRuleAction(autopilotRuleId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: AutopilotStore.ActionTypes.GET_AUTOPILOT
      });
      logger.msg('Get default autopilot action, route:/autopilot/autopilotId', 'GET');
      Server.get(`autopilot/${autopilotRuleId}`)
        .then((response: any) => {
          dispatch({
            type: AutopilotStore.ActionTypes.GET_AUTOPILOT_SUCCESS,
            payload: response.data as AutopilotDTO
          });
        })
        .catch(error => {
          logger.err('Get default autopilot action, route:/autopilot/autopilotId', 'GET');
          AlertUtil.simple(
            msg(
              'reduxMessages.autopilot.getAutopilotError',
              'Due to an error, the autopilot rule data could not be loaded!'
            ),
            'error',
            2000
          );
          dispatch({
            type: AutopilotStore.ActionTypes.GET_AUTOPILOT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function runAutopilotVerification => Redux action that tells the server to do a full autopilot trigger search
      @returns Promise
  */
  runAutopilotVerification() {
    return (dispatch: Dispatch<any>) => {
      logger.msg('Run autopilot verification action, route:/autopilot/run', 'GET');
      AlertUtil.simple(msg('alertMessages.verificationRunning', 'Autopilot verification is running!'), 'success');
      Server.get(`autopilot/run`).catch(error => {
        logger.err('Run autopilot verification action, route:/autopilot/run', 'GET');
        AlertUtil.updateContent(
          msg(
            'reduxMessages.autopilot.autopilotVerificationFailed',
            'Due to an error, the autopilot verification process failed!'
          ),
          'error'
        );
        dispatch({
          type: AutopilotStore.ActionTypes.AUTOPILOT_VERIFICATION_FAILED,
          payload: Server.errorParse(error)
        });
      });
    };
  }

  /*
    @function addAutopilotRuleForPlaceAction => Redux action that adds a new place autopilot rule
      @accepts autopilotRule : AutopilotDTO which contains all information needed for an autopilot rule
               placeId : string representing the id of the place where you want to add the autopilot rule
      @returns Promise
  */
  addAutopilotRuleForPlaceAction(autopilotRule: AutopilotDTO, placeId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: AutopilotStore.ActionTypes.SAVE_AUTOPILOT
      });
      AlertUtil.simple(
        msg('reduxMessages.autopilot.addAutopilotPending', 'The autopilot rule is being created, please wait!'),
        'info'
      );
      logger.msg('Add autopilot for place action, route:/autopilot', 'POST');
      await Server.post(`autopilot`, autopilotRule)
        .then((response: any) => {
          dispatch({
            type: AutopilotStore.ActionTypes.SAVE_AUTOPILOT_SUCCESS,
            payload: response.data as AutopilotDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.autopilot.addAutopilotSuccess', 'The autopilot rule was successfully created!'),
            'success'
          );
          const path = generatePath(partnerRoutes.AUTOPILOT.default, { placeId });
          dispatch(push(path));
        })
        .catch(error => {
          logger.err('Add autopilot for place action, route:/autopilot', 'POST');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.autopilot.addAutopilotError',
              'Due to an error, the autopilot rule could not be created!'
            ),
            'error'
          );
          dispatch({
            type: AutopilotStore.ActionTypes.SAVE_AUTOPILOT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function deleteAutopilotRuleForPlaceAction => Redux action that deletes a place autopilot rule
      @accepts autopilotRuleId : string representing the Id of the autopilot to be deleted
               partnerId : string representing the Id of the partner of which the place belongs to
               placeId: string representing the Id of the place from which the autopilot is being deleted
      @returns Promise
  */
  deleteAutopilotRuleForPlaceAction(autopilotRuleId: string, placeId: string, partnerId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: AutopilotStore.ActionTypes.DELETE_AUTOPILOT
      });
      AlertUtil.simple(msg('alertMessages.autopilotBeingDeleted', 'Autopilot rule is being deleted...'), 'info');
      logger.msg('Delete autopilot for place action, route:/autopilot/autopilotId?placeId&partnerId', 'DELETE');
      Server.delete(`autopilot/${autopilotRuleId}?placeId=${placeId}&partnerId=${partnerId}`)
        .then((response: any) => {
          dispatch({
            type: AutopilotStore.ActionTypes.DELETE_AUTOPILOT_SUCCESS,
            payload: response.data
          });
          AlertUtil.updateContent(
            msg('alertMessages.autopilotDeleted', 'Autopilot rule was succesfully deleted!'),
            'success'
          );
          dispatch(AutopilotStore.actions.getAutopilotListForPlaceAction(placeId, partnerId));
        })
        .catch(error => {
          logger.err('Delete autopilot for place action, route:/autopilot/autopilotId?placeId&partnerId', 'DELETE');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.autopilot.deleteAutopilotError',
              'Due to an error, the autopilot rule could not be deleted!'
            ),
            'error'
          );
          dispatch({
            type: AutopilotStore.ActionTypes.DELETE_AUTOPILOT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getAutopilotListForPlaceAction => Redux action that gets all place autopilot rules
      @accepts partnerId : string representing the Id of the partner of which the place belongs to
               placeId : string representing the Id of the place from which to get all autopilots
      @returns Promise
  */
  getAutopilotListForPlaceAction(placeId: string, partnerId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: AutopilotStore.ActionTypes.GET_AUTOPILOTS });
      try {
        logger.msg('Get autopilot list for place action, route:/places/placeId/autopilot?partnerId', 'GET');
        const response: any = await Server.get(`places/${placeId}/autopilot?partnerId=${partnerId}`);
        dispatch({
          type: AutopilotStore.ActionTypes.GET_AUTOPILOTS_SUCCESS,
          payload: response.data.results as Array<AutopilotDTO>
        });
      } catch (error) {
        logger.err('Get autopilot list for place action, route:/places/placeId/autopilot?partnerId', 'GET');
        AlertUtil.simple(
          msg(
            'reduxMessages.autopilot.getAutopilotsError',
            'Due to an error, the autopilot rules list could not be loaded!'
          ),
          'error',
          2000
        );
        dispatch({
          type: AutopilotStore.ActionTypes.GET_AUTOPILOTS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  /*
    @function redirectToAddAutopilotRuleActionForPlace => Redux action that redirects to Add Place Autopilot page
      @accepts placeId : string representing the Id of the place to whom you add the autopilot rule
      @returns null
  */
  redirectToAddAutopilotRuleActionForPlace(placeId: string) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(partnerRoutes.AUTOPILOT.subroutes.ADD.path, { placeId });
      dispatch(push(path));
    };
  }

  /*
    @function redirectToEditAutopilotRuleForPlaceAction => Redux action that redirects to Edit Place Autopilot page
      @accepts placeId : string representing the Id of the place to whom you edit the autopilot rule
               autopilotRuleId : string representing the Id of the autopilot you want to edit
      @returns null
  */
  redirectToEditAutopilotRuleForPlaceAction(autopilotRuleId: string, placeId: string) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(partnerRoutes.AUTOPILOT.subroutes.EDIT.path, {
        placeId,
        autopilotId: autopilotRuleId
      });
      dispatch(push(path));
    };
  }

  /*
    @function updateAutopilotRuleForPlaceAction => Redux action that updates a place autopilot rule
      @accepts autopilotRule : AutopilotDTO representing the new information you want to save about the autopilot
               partnerId : string representing the Id of the partner to whom the place belongs to
               placeId : string representing the Id of the place to whom you update the autopilot rule
      @returns Promise
  */
  updateAutopilotRuleForPlaceAction(autopilotRule: AutopilotDTO, placeId: string, partnerId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: AutopilotStore.ActionTypes.SAVE_AUTOPILOT
      });
      AlertUtil.simple(
        msg('reduxMessages.autopilot.updateAutopilotPending', 'The autopilot rule is being updated, please wait!'),
        'info'
      );
      logger.msg('Update autopilot for place action, route:/autopilot/autopilotId?placeId&partnerId', 'PUT');
      await Server.put(`autopilot/${autopilotRule._id}?placeId=${placeId}&partnerId=${partnerId}`, autopilotRule)
        .then((response: any) => {
          dispatch({
            type: AutopilotStore.ActionTypes.SAVE_AUTOPILOT_SUCCESS,
            payload: response.data as AutopilotDTO[]
          });
          AlertUtil.updateContent(
            msg('reduxMessages.autopilot.updateAutopilotSuccess', 'The autopilot rule was successfully updated!'),
            'success'
          );
          const path = generatePath(partnerRoutes.AUTOPILOT.default, { placeId });
          dispatch(push(path));
          dispatch(AutopilotStore.actions.getAutopilotListForPlaceAction(placeId, partnerId));
        })
        .catch(error => {
          logger.err('Update autopilot for place action, route:/autopilot/autopilotId?placeId&partnerId', 'PUT');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.autopilot.updateAutopilotError',
              'Due to an error, the autopilot rule could not be updated!'
            ),
            'error'
          );
          dispatch({
            type: AutopilotStore.ActionTypes.SAVE_AUTOPILOT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  updateAllSimpleAutopilotsRuleForPlaceAction(autopilotRules: AutopilotDTO[], placeId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: AutopilotStore.ActionTypes.SAVE_ALL_SIMPLE_AUTOPILOTS
      });
      AlertUtil.simple(
        msg(
          'reduxMessages.autopilot.updateSimpleAutopilotPending',
          'Simple autopilots rules is being updated, please wait!'
        ),
        'info'
      );
      logger.msg('Update all simple autopilots for place action, route:/places/simple-autopilots', 'PUT');
      await Server.put(`places/${placeId}/simple-autopilots`, autopilotRules)
        .then((response: any) => {
          dispatch({
            type: AutopilotStore.ActionTypes.SAVE_ALL_SIMPLE_AUTOPILOTS_SUCCESS,
            payload: response.data as AutopilotDTO[]
          });
          AlertUtil.updateContent(
            msg(
              'reduxMessages.autopilot.updateSimpleAutopilotSuccess',
              'Simple autopilots rules was successfully updated!'
            ),
            'success'
          );
        })
        .catch(error => {
          logger.err('Update all simple autopilots for place action, route:/places/simple-autopilots', 'PUT');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.autopilot.updateSimpleAutopilotError',
              'Due to an error, simple autopilots rules could not be updated!'
            ),
            'error'
          );
          dispatch({
            type: AutopilotStore.ActionTypes.SAVE_ALL_SIMPLE_AUTOPILOTS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function updateAutopilotStatusForPlaceAction => Redux action that updates the status of a place autopilot rule
      @accepts status : object containing the new status, id, placeId and partnerId of the autopilot
      @returns Promise
  */
  updateAutopilotStatusForPlaceAction(status: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: AutopilotStore.ActionTypes.SAVE_AUTOPILOT
      });
      AlertUtil.simple(
        msg('reduxMessages.updateAutopilotPending', 'The autopilot rule is being updated, please wait!'),
        'success'
      );
      logger.msg('Update autopilot status for place action, route:/autopilot/autopilotId?placeId&partnerId', 'PUT');
      await Server.put(`autopilot/${status._id}?placeId=${status.placeId}&partnerId=${status.partnerId}`, status)
        .then((response: any) => {
          dispatch({
            type: AutopilotStore.ActionTypes.SAVE_AUTOPILOT_SUCCESS,
            payload: response.data as AutopilotDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.autopilot.updateAutopilotSuccess', 'The autopilot rule was successfully updated!'),
            'success'
          );
          dispatch(AutopilotStore.actions.getAutopilotListForPlaceAction(status.placeId, status.partnerId));
        })
        .catch(error => {
          logger.err('Update autopilot status for place action, route:/autopilot/autopilotId?placeId&partnerId', 'PUT');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.autopilot.updateAutopilotStatusError',
              'Due to an error, the autopilot status could not be updated!'
            ),
            'error'
          );
          dispatch({
            type: AutopilotStore.ActionTypes.SAVE_AUTOPILOT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }
}

const autopilotActions = new AutopilotActions();
export default autopilotActions;
