import * as React from 'react';

import { AutopilotDTO } from '../../../../appRedux/types';
import { msg } from '../../../../utils';
import { CardComponent } from '../../../shared';
import { AutopilotFormComponent } from './forms';

interface IProps {
  config: {
    pending: boolean;
    loaded: AutopilotDTO;
    load_pending: boolean;
    load_error: string | null;
    addForPlace: (autopilot: any, placeId: string) => any;
    get: (autopilotId: string) => any;
    editForPlace: (autopilot: any, placeId: string, partnerId: string) => any;
    industries: any;
    rewards: any;
    getRewardsPlace: (placeId: string) => any;
  };
  match?: any;
  editMode: boolean;
  partnerId: string;
}
interface IState {
  autopilot: AutopilotDTO;
}

export default class AutopilotEditComponent extends React.Component<IProps, IState> {
  componentDidMount() {
    if (this.props.editMode && this.props.match?.params.autopilotId) {
      this.props.config.get(this.props.match.params.autopilotId);
    }
    if (!this.props.editMode) {
      this.props.config.getRewardsPlace(this.props.match.params.placeId);
    } else if (this.props.editMode) {
      this.props.config.getRewardsPlace(this.props.match?.params.placeId);
    }
  }

  saveAutopilotHandler = (autopilot: any) => {
    const { config } = this.props;
    let placeId: string = '';
    let dataToSend: any = {};
    placeId = this.props.match.params.placeId;
    dataToSend = {
      ...autopilot,
      partnerId: this.props.partnerId,
      placeId
    };
    if (!this.props.editMode) {
      config.addForPlace(dataToSend, placeId);
    } else if (this.props.editMode) {
      config.editForPlace(autopilot, placeId, this.props.partnerId);
    }
  };

  autopilotChangeHandler = (autopilot: any) => {
    this.setState({ autopilot });
  };

  renderAutopilotForm = () => {
    const propsConfig = this.props.config;
    const autopilotData = this.props.editMode ? propsConfig.loaded : new AutopilotDTO();
    return (
      <CardComponent
        title={
          this.props.editMode
            ? msg('cardTitle.editAutopilotRule', 'Edit autopilot rule')
            : msg('cardTitle.addAutopilotRule', 'Add autopilot rule')
        }
        pending={propsConfig.load_pending}
        error={!!propsConfig.load_error}
        headerIcon={this.props.editMode ? 'edit' : 'add_circle_outline'}
        needsTitle={true}
      >
        <form className="form-horizontal">
          <div className="col-sm-12">
            <AutopilotFormComponent
              autopilot={autopilotData}
              save={this.saveAutopilotHandler}
              pending={propsConfig.pending}
              output={false}
              onChange={this.autopilotChangeHandler}
              editable={this.props.editMode}
              auxilaries={this.props.config.rewards}
            />
          </div>
        </form>
      </CardComponent>
    );
  };

  render() {
    return <div>{this.renderAutopilotForm()}</div>;
  }
}
