import { AxiosResponse } from 'axios';

import { PaginationDTO } from '../types';
import { Server, IDictionary, composedCriteriaBuilder } from '../../utils';

// @TODO: expand different types of activity entries
export interface IActivity {
  _id: string;
  eventType: string;
  date: string;
  placeId: string;
  memberId?: string;
  userId?: any;
  coins?: number;
  checkinId?: string;
  points?: number;
  price?: number;
  rewardType?: number;
  eventId?: string;
  eventTitle?: string;
  autopilotId?: string;
  autopilotTitle?: string;
  bonus?: string;
  rewardTitle?: string;
  placeName: string;
  type?: string;
  confirmationNo?: string;
  tagId?: string;
  tagName?: string;
}

export interface IActivityMember {
  _id?: string;
  birthday?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
}

export interface IActivityPlace {
  _id: string;
  name: string;
}

const denormalizeMember = (members: IDictionary<IActivityMember>) => (
  activity: IActivity & { memberId: string }
): IActivity & { member: IActivityMember } => {
  return {
    ...activity,
    member: members[activity.memberId] || ({} as IActivityMember)
  };
};
const denormalizeUser = (users: IDictionary<IActivityMember>) => (
  activity: IActivity & { userId: string }
): IActivity & { member: IActivityMember } => {
  return {
    ...activity,
    member: users[activity.userId] || ({} as IActivityMember)
  };
};
const denormalizeActivityMember = (users: IDictionary<IActivityMember>, members: IDictionary<IActivityMember>) => (
  activity: IActivity
): IActivity & { member: IActivityMember } => {
  if (!!activity.userId && users) {
    return denormalizeUser(users)(activity as any);
  }
  if (!!activity.memberId && members) {
    return denormalizeMember(members)(activity as any);
  }
  return { member: {}, ...activity };
};
const denormalizePlace = (places: IDictionary<IActivityPlace>) => (
  activity: IActivity
): IActivity & { place: IActivityPlace } => {
  const place = activity.placeId ? places[activity.placeId] : ({} as IActivityPlace);

  return {
    ...activity,
    place
  };
};

/** Place activities */
type IFetchPlaceActivitiesServerResponse = PaginationDTO<IActivity> & { totalEarned?: number; totalSpent?: number } & {
  members: IDictionary<IActivityMember>;
} & { users: IDictionary<IActivityMember> };
export type IFetchPlaceActivityResponse = PaginationDTO<IActivity & { member: IActivityMember }>;
// tslint:disable-next-line: max-line-length
export const fetchPlaceActivity = async (
  isSAdmin: boolean,
  placeId: string,
  groupByCheckin: boolean,
  options?: any
): Promise<IFetchPlaceActivityResponse> => {
  let url;
  if (isSAdmin) {
    url = `admin/activities?groupByCheckin=${groupByCheckin}&pagination=true&limit=${options?.limit}`;
  } else {
    url = `places/${placeId}/place-activity?groupByCheckin=${groupByCheckin}&limit=${options?.limit}`;
  }
  if (options?.skip) {
    url += `&skip=${options.skip}`;
  }
  if (options?.sort) {
    url += `&sort=${options.sort}`;
  }
  if (options?.criteria) {
    url += composedCriteriaBuilder(options.criteria);
  }
  const response = (await Server.get(url)) as AxiosResponse<IFetchPlaceActivitiesServerResponse>;
  return {
    ...response.data,
    results: response.data.results.map(denormalizeActivityMember(response.data.users, response.data.members))
  };
};

/** Checkin activities */
type IFetchCheckinActivityServerResponse = PaginationDTO<IActivity> & { totalEarned: number; totalSpent: number } & {
  members: IDictionary<IActivityMember>;
} & { users: IDictionary<IActivityMember> };
export type IFetchCheckinActivityResponse = PaginationDTO<IActivity & { member: IActivityMember }> & {
  totalEarned: number;
  totalSpent: number;
};

// tslint:disable-next-line: max-line-length
export const fetchCheckinActivity = async (
  checkinId: string,
  options?: any
): Promise<IFetchCheckinActivityResponse> => {
  let url = `places/checkin-activity/${checkinId}?limit=${options?.limit}`;
  if (options?.skip) {
    url += `&skip=${options.skip}`;
  }
  if (options?.sort) {
    url += `&sort=${options.sort}`;
  }
  if (options?.criteria) {
    url += composedCriteriaBuilder(options.criteria);
  }
  const response = (await Server.get(url)) as AxiosResponse<IFetchCheckinActivityServerResponse>;
  return {
    ...response.data,
    results: response.data.results.map(denormalizeActivityMember(response.data.users, response.data.members))
    // .map(denormalizeMember(response.data.members))
    // .map(denormalizeUser(response.data.users))
  };
};

/** User activity */
type IFetchUserActivityServerResponse = PaginationDTO<IActivity> & { places: IDictionary<IActivityPlace> };
export type IFetchUserActivityResponse = PaginationDTO<IActivity & { place: IActivityPlace }>;

// tslint:disable-next-line: max-line-length
export const fetchUserActivity = async (userId: string, options?: any): Promise<IFetchUserActivityResponse> => {
  let url = `admin/user-activity/${userId}?limit=${options?.limit}`;
  if (options?.skip) {
    url += `&skip=${options.skip}`;
  }
  if (options?.sort) {
    url += `&sort=${options.sort}`;
  }
  if (options?.criteria) {
    url += composedCriteriaBuilder(options.criteria);
  }
  const response = (await Server.get(url)) as AxiosResponse<IFetchUserActivityServerResponse>;

  return {
    ...response.data,
    results: response.data.results.map(denormalizePlace(response.data.places))
  };
};
