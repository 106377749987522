import React from 'react';

import { PaginationDTO, ProfileDTO } from '../../../../appRedux/types';
import { AlertUtil, msg, parseAmount } from '../../../../utils';
import { CardComponent, InfiniteScrollListComponent } from '../../../shared';

interface IProps {
  match: any;
  config: {
    profile_list: PaginationDTO<ProfileDTO>;
    profile_list_pending: boolean;
    profile_list_error: string | null;
    getTabletProfilesAction: (
      placeId: string,
      limit?: Number,
      skip?: Number,
      sort?: string,
      criteria?: { [key: string]: string }
    ) => any;
    addTabletProfileAction: (name: string, placeId: string) => any;
    editTabletProfileAction: (placeId: string, profileId: string, name: string) => any;
    deleteTabletProfileAction: (placeId: string, profileId: string) => any;
  };
}

const TabletProfilesListComponent = (props: IProps) => {
  const getData = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    const placeId = props.match.params?.placeId || '';
    props.config.getTabletProfilesAction(placeId, limit, skip, sort, criteria);
  };

  const parseList = (list: PaginationDTO<ProfileDTO>) => {
    return {
      ...list,
      results: list?.results?.length
        ? list.results.map((item: ProfileDTO) => {
            return {
              ...item,
              referralsNo: item.referralsNo,
              activities: {
                buy: item?.activities?.buy || 0,
                redeems: item.activities?.redeems || 0,
                payments: item.activities?.payments || 0,
                tips: item.activities?.tips ? parseAmount(item.activities?.tips) : 0
              }
            };
          })
        : []
    };
  };

  const handleDelete = async (event: any) => {
    const profileId = event.currentTarget.getAttribute('id');
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.tabletProfileDeleteConfirmation', 'You are about to delete a profile!')
    );
    if (hasConfirmation) {
      const placeId = props.match.params?.placeId || '';
      props.config.deleteTabletProfileAction(placeId, profileId);
    }
  };

  const checkProfileName = (input: string) => {
    if (input.length < 3) {
      return msg('formValidation.inputLength', 'Input too short!');
    }
    return null;
  };

  const handleAddProfile = async () => {
    const profileName = await AlertUtil.fireWithInput(
      msg('alertMessages.addTabletProfile', 'Add new tablet profile'),
      '',
      msg('tabletProfiles.name', 'Name'),
      checkProfileName
    );
    if (profileName) {
      const placeId = props.match.params?.placeId || '';
      props.config.addTabletProfileAction(profileName, placeId);
    }
  };

  const handleEdit = async (event: any) => {
    const profileId = event.currentTarget.getAttribute('id');
    const newProfileName = await AlertUtil.fireWithInput(
      msg('alertMessages.editTabletProfile', 'Edit tablet profile'),
      '',
      msg('tabletProfiles.name', 'Name'),
      checkProfileName
    );
    if (newProfileName) {
      const placeId = props.match.params?.placeId || '';
      props.config.editTabletProfileAction(placeId, profileId, newProfileName);
    }
  };

  return (
    <CardComponent
      title={msg('tabletProfiles.cardTitle', 'Tablet Profiles')}
      headerIcon="manage_accounts"
      needsTitle={true}
      buttons={[
        {
          label: msg('tabletProfiles.addProfile', 'Add profile'),
          icon: 'person_add',
          onClick: handleAddProfile
        }
      ]}
    >
      <InfiniteScrollListComponent
        fields={{
          name: msg('tabletProfiles.name', 'Name'),
          referralCode: msg('tabletProfiles.referralCode', 'Referral Code'),
          referralsNo: msg('tabletProfiles.invites', 'Invites'),
          'activities.redeems': msg('tabletProfiles.redeems', 'Redeems'),
          'activities.buy': msg('tabletProfiles.buy', 'Buy'),
          'activities.payments': msg('tabletProfiles.transactions', 'Payments'),
          'activities.tips': msg('tabletProfiles.tips', 'Tips')
        }}
        list={parseList(props.config.profile_list)}
        pending={props.config.profile_list_pending}
        error={props.config.profile_list_error}
        get={getData}
        limit={30}
        search={['name']}
        sort={{
          fields: ['name'],
          default: 'name,1'
        }}
        actions={[
          {
            label: msg('general.edit', 'Edit'),
            btn: 'btn-success',
            icon: 'edit',
            onClick: handleEdit
          },
          {
            label: msg('general.delete', 'Delete'),
            btn: 'btn-danger',
            icon: 'delete',
            onClick: handleDelete
          }
        ]}
        dateInterval={{ field: 'interval', default: 6 }}
        fixedHeight={350}
      />
    </CardComponent>
  );
};

export default TabletProfilesListComponent;
