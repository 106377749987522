import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  SidebarComponent,
  TopbarComponent,
  FooterComponent,
  TransactionsRouter,
  PayBatchesRouter,
  ProfileContainer
} from '../components';
import { AppStore, AuthStore, TranslationsStore, reduxContainer } from '../../appRedux';
import { UserDTO } from '../../appRedux/types';
import { ScrollToTopComponent } from '../shared';
import { adminRoutes } from '.';

interface IProps {
  user: UserDTO;
  initial_language: string;
  origin: string;
  logoutAction: () => any;
  changeLanguageAction: (option: string) => any;
}
interface IState {}

class PaymentsLayoutContainer extends React.Component<IProps, IState> {
  toggleSideBar = () => {
    let html = document.getElementsByTagName('html');
    let openSideBar = document.getElementById('openSideBar');
    if (!html[0].className.includes('nav-open')) {
      openSideBar?.classList.add('toggled');
      html[0].classList.add('nav-open');
    } else {
      openSideBar?.classList.remove('toggled');
      html[0].classList.remove('nav-open');
    }
  };
  render() {
    const sidebarConfig = {
      user: this.props.user,
      origin: this.props.origin
    };
    const sidebarActions = {
      logOut: this.props.logoutAction,
      forSAdmin: true,
      isPaymentsAccount: true,
      toggleSideBar: this.toggleSideBar
    };
    const topbarActions = {
      initial_language: this.props.initial_language,
      changeLanguage: this.props.changeLanguageAction,
      toggleSideBar: this.toggleSideBar
    };

    return (
      <div className="App">
        <div className="wrapper">
          <SidebarComponent config={sidebarConfig} actions={sidebarActions} />
          <div className="main-panel">
            <TopbarComponent actions={topbarActions} />
            <ScrollToTopComponent />
            <div className="content" style={{ paddingTop: 3 }}>
              <div className="container-fluid">
                <Switch>
                  <Redirect exact={true} from="/admin" to={adminRoutes.TRANSACTIONS.path} />
                  <Route path={adminRoutes.PROFILE.path} component={ProfileContainer} />
                  <Route path={adminRoutes.TRANSACTIONS.path} component={TransactionsRouter} />
                  <Route path={adminRoutes.BATCHES.path} component={PayBatchesRouter} />
                  <Redirect from="*" to={adminRoutes.TRANSACTIONS.path} />
                </Switch>
              </div>
            </div>
            <FooterComponent />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    user: state.auth.user,
    initial_language: state.translations.initial_language,
    origin: state.auth.origin
  };
}

const dispatchToProps = {
  logoutAction: AuthStore.actions.logoutAction,
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction
};

export default reduxContainer(PaymentsLayoutContainer, mapStateToProps, dispatchToProps);
