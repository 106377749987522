import { Dispatch } from 'redux';
import { push } from 'connected-react-router';

import { MembersStore } from './';
import { Server, composedCriteriaBuilder, AlertUtil, msg, logger, queryStringToCriteria } from '../../utils';
import { PaginationDTO, MemberDTO, TagDTO } from '../types';
import { fetchFavouriteMoments } from './api';
import moment from 'moment';
import { partnerRoutes } from '../../modules/layouts/routes';
import { generatePath } from 'react-router-dom';

/*
  IMembersActions interface definition, which contains every redux action asociated with Members State.
*/
export interface IMembersActions {
  /*
    Actions specific for a place
  */
  getMemberFromPlaceAction(memberId: string, placeId: string): any;
  getMembersOnePlaceListAction(
    placeId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string },
    places?: string[]
  ): any;
  redirectToSeeActivityOnPlaceAction(placeId: string, memberEmail: any): any;
  changeMemberStatus(forSAdmin: boolean, placeId: string, memberId: string, status: number, tags?: TagDTO[]): any;
  getMembershipsForUser(
    userId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  fetchFavouriteMomentsAction(userId: string, options?: any): any;
  exportMembersInExcel(placeId: string, startDate: number, endDate: number): any;
  clearPlaceMembersError(): any;
}

/*
  class MembersActions that implements redux actions defined in IMembersActions interface
*/
class MembersActions implements IMembersActions {
  /*
    @function getMemberFromPlaceAction => Redux action that gets the information of an existing member from a place
      @accepts memberId : string that represents the id of the member of which you get information about
               placeId : string that represents the id of the place the member has a membership to
      @returns Promise
  */
  getMemberFromPlaceAction(memberId: string, placeId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: MembersStore.ActionTypes.GET_MEMBER
      });
      logger.msg('Get member from place action, route:/places/:placeId/get-member/:memberId', 'GET');
      Server.get(`places/${placeId}/get-member/${memberId}`)
        .then((response: any) => {
          dispatch({
            type: MembersStore.ActionTypes.GET_MEMBER_SUCCESS,
            payload: response.data
          });
        })
        .catch(error => {
          logger.err('Get member from place action, route:/places/:placeId/get-member/:memberId', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.members.getMemberError', 'Due to an error, the member data could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: MembersStore.ActionTypes.GET_MEMBER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getMembersOnePlaceListAction => Redux action that gets the list of members from a single place
      @accepts placeId : string that represents the id of the place you want to get the list of members from
      (optional) limit, skip, sort, criteria : params used for pagination
      @returns Promise
  */
  getMembersOnePlaceListAction(
    placeId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string },
    places?: string[]
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: MembersStore.ActionTypes.GET_MEMBERS });
      try {
        if (!limit) {
          limit = 30;
        }
        let url = `places/${placeId}/members?limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        if (places) {
          url += `&places=${JSON.stringify(places)}`;
        }
        logger.msg('Get members of one place action, route:/places/placeId/members', 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: MembersStore.ActionTypes.GET_MEMBERS_SUCCESS,
          payload: response.data as PaginationDTO<any>
        });
      } catch (error) {
        logger.err('Get members of one place action, route:/places/placeId/members', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.members.getMembersError', 'Due to an error, the members list could not be loaded!'),
          'error',
          2000
        );
        dispatch({
          type: MembersStore.ActionTypes.GET_MEMBERS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  /*
    @function redirectToSeeActivityOnPlaceAction => Action that redirects to the filtered checkins of a member for place
      @accepts memberEmail : string that represents the member email, used for filtering the checkins
               placeId : string representing the id of the place where the member made checkins
      @returns Promise
  */
  redirectToSeeActivityOnPlaceAction(placeId: string, memberEmail: any) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(partnerRoutes.ACTIVITY.default, { placeId }) + `?search=${memberEmail}`;
      dispatch(push(path));
    };
  }

  /*
    @function changeMemberStatus => Action that changes the member status and tags for a place
     @accepts member:
     @returns Promise
  */
  changeMemberStatus(forSAdmin: boolean, placeId: string, memberId: string, status: number, tags?: TagDTO[]) {
    return async (dispatch: Dispatch<any>, getState: () => any) => {
      dispatch({
        type: MembersStore.ActionTypes.CHANGE_MEMBER_STATUS
      });
      AlertUtil.simple(
        msg('reduxMessages.members.editMemberPending', 'The member is being updated, please wait...'),
        'info',
        2000
      );
      logger.msg('Change member status action, route:/places/:placeId/change-member-status/:memberId', 'PUT');
      await Server.put(`places/${placeId}/change-member-status/${memberId}`, { status, tags })
        .then((response: any) => {
          dispatch({
            type: MembersStore.ActionTypes.CHANGE_MEMBER_STATUS_SUCCESS,
            payload: response.data as MemberDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.members.editMemberSuccess', 'The member was successfully updated!'),
            'success'
          );
          if (!forSAdmin) {
            const { limit, page_number } = getState().members.members_list;
            const newCriteria = queryStringToCriteria(window.location.search, [
              'user.email',
              'user.firstname',
              'user.lastname',
              'user.phone'
            ]);
            dispatch(
              MembersStore.actions.getMembersOnePlaceListAction(placeId, limit * page_number, 0, newCriteria.sort, {
                filters: newCriteria.filters,
                search: newCriteria.search
              })
            );
          }
        })
        .catch(error => {
          logger.err('Change member status action, route:/places/:placeId/change-member-status/:memberId', 'PUT');
          AlertUtil.updateContent(
            msg('reduxMessages.partners.editMemberError', 'Due to an error, the member could not be updated!'),
            'error'
          );
          dispatch({
            type: MembersStore.ActionTypes.CHANGE_MEMBER_STATUS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
  @function getMembershipsForUser => Redux action that gets the list of memberships for a user
    @accepts userId : string representing the id of the user from whom to get the list of memberships
    (optional) limit, skip, sort, criteria : params used for pagination
    @returns Promise
*/
  getMembershipsForUser(
    userId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: MembersStore.ActionTypes.GET_MEMBERSHIPS_FOR_USER });
      try {
        if (!limit) {
          limit = 10;
        }
        let url = `admin/user/${userId}/memberships?limit=${limit}`;
        if (skip) {
          url += `skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get members for partner action, route:/admin/user/:id/memberships', 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: MembersStore.ActionTypes.GET_MEMBERSHIPS_FOR_USER_SUCCESS,
          payload: response.data as PaginationDTO<any>
        });
      } catch (error) {
        logger.err('Get members for partner action, route:/admin/user/:id/memberships', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.members.getMembersError', 'Due to an error, the members list could not be loaded!'),
          'error',
          2000
        );
        dispatch({
          type: MembersStore.ActionTypes.GET_MEMBERSHIPS_FOR_USER_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  fetchFavouriteMomentsAction(userId: string, options: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: MembersStore.ActionTypes.GET_FAVOURITE_MOMENTS });
      fetchFavouriteMoments(userId, options)
        .then(response => {
          dispatch({
            type: MembersStore.ActionTypes.GET_FAVOURITE_MOMENTS_SUCCESS,
            payload: response
          });
        })
        .catch(error => {
          logger.err('Get members for partner action, route:/partners/partnerId/members', 'GET');
          dispatch({
            type: MembersStore.ActionTypes.GET_FAVOURITE_MOMENTS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  exportMembersInExcel(placeId: string, startDate: number, endDate: number) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: MembersStore.ActionTypes.EXPORT_MEMBERS
      });
      const startDateFormat = moment(startDate).format('DD-MM-YYYY');
      const endDateFormat = moment(endDate).format('DD-MM-YYYY');
      const name = `Report Members ${startDateFormat} - ${endDateFormat}.xlsx`;
      let url = `/places/${placeId}/report-members?startDate=${startDate}&endDate=${endDate}`;
      logger.msg('Export members in excel action, route:/places/placeId/report-members', 'GET');
      Server.downloadReports(url, name)
        .then((response: any) => {
          dispatch({
            type: MembersStore.ActionTypes.EXPORT_MEMBERS_SUCCESS,
            payload: response
          });
        })
        .catch(error => {
          logger.err('Export members in excel action, route:/places/placeId/report-members', 'GET');
          dispatch({
            type: MembersStore.ActionTypes.EXPORT_MEMBERS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  clearPlaceMembersError() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: MembersStore.ActionTypes.CLEAR_MEMBERS_ERROR });
    };
  }
}

const membersActions = new MembersActions();
export default membersActions;
