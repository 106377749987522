import { Dispatch } from 'redux';
import { ActivityStore } from '.';
import {
  IFetchCheckinActivityResponse,
  IFetchPlaceActivityResponse,
  IFetchUserActivityResponse,
  fetchPlaceActivity,
  fetchCheckinActivity,
  fetchUserActivity
} from './api';
import { logger, AlertUtil, msg, Server } from '../../utils';

/** Place activity checkins */
interface IGetPlaceActivityAction {
  type: typeof ActivityStore.ActionTypes.GET_PLACE_ACTIVITY;
}
interface IGetPlaceActivitySuccessAction {
  type: typeof ActivityStore.ActionTypes.GET_PLACE_ACTIVITY_SUCCESS;
  payload: IFetchPlaceActivityResponse;
}
interface IGetPlaceActivityFailureAction {
  type: typeof ActivityStore.ActionTypes.GET_PLACE_ACTIVITY_FAILED;
  payload: any;
}
interface IClearPlaceActivityErrorAction {
  type: typeof ActivityStore.ActionTypes.CLEAR_ACTIVITY_ERROR;
}

export type IPlaceActivityActions =
  | IGetPlaceActivityAction
  | IGetPlaceActivitySuccessAction
  | IGetPlaceActivityFailureAction
  | IClearPlaceActivityErrorAction;

/** Checkin activity checkins */
interface IOpenCheckinActivityAction {
  type: typeof ActivityStore.ActionTypes.OPEN_CHECKIN_ACTIVITY;
  payload: string;
}
interface ICloseCheckinActivityAction {
  type: typeof ActivityStore.ActionTypes.CLOSE_CHECKIN_ACTIVITY;
}
interface IGetCheckinActivityAction {
  type: typeof ActivityStore.ActionTypes.GET_CHECKIN_ACTIVITY;
}
interface IGetCheckinActivitySuccessAction {
  type: typeof ActivityStore.ActionTypes.GET_CHECKIN_ACTIVITY_SUCCESS;
  payload: IFetchCheckinActivityResponse;
}
interface IGetCheckinActivityFailureAction {
  type: typeof ActivityStore.ActionTypes.GET_CHECKIN_ACTIVITY_FAILED;
  payload: any;
}
export type ICheckinActivityActions =
  | IOpenCheckinActivityAction
  | ICloseCheckinActivityAction
  | IGetCheckinActivityAction
  | IGetCheckinActivitySuccessAction
  | IGetCheckinActivityFailureAction;

interface IGetUserActivityAction {
  type: typeof ActivityStore.ActionTypes.GET_USER_ACTIVITY;
  // payload: any
}
interface IGetUserActivitySuccessAction {
  type: typeof ActivityStore.ActionTypes.GET_USER_ACTIVITY_SUCCESS;
  payload: IFetchUserActivityResponse;
}
interface IGetUserActivityFailureAction {
  type: typeof ActivityStore.ActionTypes.GET_USER_ACTIVITY_FAILED;
  payload: any;
}

export type IUserActivityActions =
  | IGetUserActivityAction
  | IGetUserActivitySuccessAction
  | IGetUserActivityFailureAction;

/** Action creators */
export interface IActivityActionCreators {
  // tslint:disable-next-line:max-line-length
  getPlaceActivityList: (
    isSAdmin: boolean,
    placeId: string,
    groupByCheckin: boolean
  ) => (dispatch: Dispatch<IPlaceActivityActions>) => Promise<void>;
  openCheckinActivityList: (checkinId: string) => (dispatch: Dispatch<IOpenCheckinActivityAction>) => Promise<void>;
  closeCheckinActivityList: () => (dispatch: Dispatch<ICloseCheckinActivityAction>) => Promise<void>;
  getCheckinActivityList: (
    checkinId: string,
    options?: any
  ) => (dispatch: Dispatch<ICheckinActivityActions>) => Promise<void>;
  fetchUserActivityAction(userId: string, options?: any): (dispatch: Dispatch<IUserActivityActions>) => Promise<void>;
  clearPlaceActivityError(): any;
}

class ActivityActions implements IActivityActionCreators {
  getPlaceActivityList(
    isSAdmin: boolean,
    placeId: string,
    groupByCheckin: boolean,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return async (dispatch: Dispatch<IPlaceActivityActions>) => {
      dispatch({
        type: ActivityStore.ActionTypes.GET_PLACE_ACTIVITY
      });
      if (!limit) {
        limit = 30;
      }
      const options = { limit, skip, sort, criteria };
      logger.msg(`Get place activity action, route:/places/${placeId}/place-activity`, 'GET');
      fetchPlaceActivity(isSAdmin, placeId, groupByCheckin, options)
        .then(response => {
          dispatch({
            type: ActivityStore.ActionTypes.GET_PLACE_ACTIVITY_SUCCESS,
            payload: response
          });
        })
        .catch(error => {
          logger.err(`Get place activity action, route:/activity/${placeId}`, 'GET');
          AlertUtil.simple(
            msg(
              'activity.getPlaceActivityError',
              'Due to an error, the activity list for this place could not be loaded!'
            ),
            'error',
            2000
          );
          dispatch({
            type: ActivityStore.ActionTypes.GET_PLACE_ACTIVITY_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  openCheckinActivityList(checkinId: string) {
    return async (dispatch: Dispatch<IOpenCheckinActivityAction>) => {
      dispatch({
        type: ActivityStore.ActionTypes.OPEN_CHECKIN_ACTIVITY,
        payload: checkinId
      });
    };
  }

  closeCheckinActivityList() {
    return async (dispatch: Dispatch<ICloseCheckinActivityAction>) => {
      dispatch({
        type: ActivityStore.ActionTypes.CLOSE_CHECKIN_ACTIVITY
      });
    };
  }

  getCheckinActivityList(checkinId: string, options?: any) {
    return async (dispatch: Dispatch<ICheckinActivityActions>) => {
      dispatch({
        type: ActivityStore.ActionTypes.GET_CHECKIN_ACTIVITY
      });
      logger.msg(`Get checkin activity action, route:/places/checkin-activity/${checkinId}`, 'GET');
      fetchCheckinActivity(checkinId, options)
        .then(response => {
          dispatch({
            type: ActivityStore.ActionTypes.GET_CHECKIN_ACTIVITY_SUCCESS,
            payload: response
          });
        })
        .catch(error => {
          logger.err(`Get checkin activity action, route:/activity/${checkinId}`, 'GET');
          AlertUtil.simple(
            msg(
              'activity.getCheckinActivityError',
              'Due to an error, the activity list for this checkin could not be loaded!'
            ),
            'error',
            2000
          );
          dispatch({
            type: ActivityStore.ActionTypes.GET_CHECKIN_ACTIVITY_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  fetchUserActivityAction(userId: string, options: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: ActivityStore.ActionTypes.GET_USER_ACTIVITY });
      fetchUserActivity(userId, options)
        .then(response => {
          dispatch({
            type: ActivityStore.ActionTypes.GET_USER_ACTIVITY_SUCCESS,
            payload: response
          });
        })
        .catch(error => {
          logger.err('Get members for partner action, route:/partners/partnerId/members', 'GET');
          dispatch({
            type: ActivityStore.ActionTypes.GET_USER_ACTIVITY_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }
  clearPlaceActivityError() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: ActivityStore.ActionTypes.CLEAR_ACTIVITY_ERROR });
    };
  }
}

export type IActivityActions = IPlaceActivityActions | ICheckinActivityActions | IUserActivityActions;

const activityActions = new ActivityActions();
export default activityActions;
