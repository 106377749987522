import statisticsActions, { IStatisticsActions } from './actions';
import statisticsReducer from './reducer';
import { PaginationDTO, PlaceStatisticsDTO, StatisticsSuperAdminDTO } from '../types';

/*
  StatisticsStore definition as a namespace that contains actions, reducers, actionTypes and statistics state
*/
export namespace StatisticsStore {
  export type IState = {
    statistics_list: Array<any>;
    statistics_list_pending: boolean;
    statistics_list_error: string | null;
    place_statistics: any;
    place_statistics_pending: boolean;
    place_statistics_error: string | null;
    personalized_statistics_list: any;
    personalized_statistics_list_pending: boolean;
    personalized_statistics_list_error: string | null;
    agg_statistics: Array<any>;
    agg_statistics_pending: boolean;
    agg_statistics_error: string | null;
    statistics_super_admin: PaginationDTO<PlaceStatisticsDTO>;
    statistics_super_admin_pending: boolean;
    statistics_super_admin_error: string | null;
    statistics_all_super_admin: StatisticsSuperAdminDTO;
    statistics_all_super_admin_pending: boolean;
    statistics_all_super_admin_error: string | null;
  };

  export const initialState: IState = {
    statistics_list: [],
    statistics_list_pending: false,
    statistics_list_error: null,
    place_statistics: [],
    place_statistics_pending: false,
    place_statistics_error: null,
    personalized_statistics_list: [],
    personalized_statistics_list_pending: false,
    personalized_statistics_list_error: null,
    agg_statistics: [],
    agg_statistics_pending: false,
    agg_statistics_error: null,
    statistics_super_admin: new PaginationDTO(),
    statistics_super_admin_pending: false,
    statistics_super_admin_error: null,
    statistics_all_super_admin: new StatisticsSuperAdminDTO(),
    statistics_all_super_admin_pending: false,
    statistics_all_super_admin_error: null
  };

  export enum ActionTypes {
    GET_STATISTICS = 'GET_STATISTICS',
    GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS',
    GET_STATISTICS_FAILED = 'GET_STATISTICS_FAILED',
    GET_PLACE_STATISTICS = 'GET_PLACE_STATISTICS',
    GET_PLACE_STATISTICS_SUCCESS = 'GET_PLACE_STATISTICS_SUCCESS',
    GET_PLACE_STATISTICS_FAILED = 'GET_PLACE_STATISTICS_FAILED',
    GET_PERSONALIZED_STATISTICS = 'GET_PERSONALIZED_STATISTICS',
    GET_PERSONALIZED_STATISTICS_SUCCESS = 'GET_PERSONALIZED_STATISTICS_SUCCESS',
    GET_PERSONALIZED_STATISTICS_FAILED = 'GET_PERSONALIZED_STATISTICS_FAILED',
    GET_ALL_AGG_STATISTICS = 'GET_ALL_AGG_STATISTICS',
    GET_ALL_AGG_STATISTICS_SUCCESS = 'GET_ALL_AGG_STATISTICS_SUCCESS',
    GET_ALL_AGG_STATISTICS_FAILED = 'GET_ALL_AGG_STATISTICS_FAILED',
    GET_STATISTICS_PER_PLACE_SUPERADMIN = 'GET_STATISTICS_PER_PLACE_SUPERADMIN',
    GET_STATISTICS_PER_PLACE_SUPERADMIN_SUCCESS = 'GET_STATISTICS_PER_PLACE_SUPERADMIN_SUCCESS',
    GET_STATISTICS_PER_PLACE_SUPERADMIN_FAILED = 'GET_STATISTICS_PER_PLACE_SUPERADMIN_FAILED',
    GET_ALL_STATISTICS_SUPERADMIN = 'GET_ALL_STATISTICS_SUPERADMIN',
    GET_ALL_STATISTICS_SUPERADMIN_SUCCESS = 'GET_ALL_STATISTICS_SUPERADMIN_SUCCESS',
    GET_ALL_STATISTICS_SUPERADMIN_FAILED = 'GET_ALL_STATISTICS_SUPERADMIN_FAILED'
  }

  export const actions = statisticsActions;
  export const reducer = statisticsReducer;
  export interface IActions extends IStatisticsActions {}
}

export * from './actions';
