import classnames from 'classnames';
import * as React from 'react';

import { msg } from '../../../../../utils';
import styles from '../styles/Auth.module.css';

interface IState {
  showCard: boolean;
  headerUp: boolean;
  visible: boolean;
}

interface IProps {
  match: any;
  activateAction: any;
  state: {
    error: any;
  };
}

export default class AccountActivationComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showCard: false,
      headerUp: false,
      visible: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showCard: true });
      // tslint:disable-next-line:align
    }, 800);
    setTimeout(() => {
      this.setState({ visible: true });
      // tslint:disable-next-line:align
    }, 1000);
    if (this.props.match && this.props.match.params.token) {
      this.props.activateAction(this.props.match.params.token);
    }
  }

  render() {
    let cardClasses = classnames(`card card-login ${styles.card}`, { 'card-hidden': !this.state.showCard });
    const { error } = this.props.state;
    let headerClasses = classnames(`card-header text-center ${styles.header}`, {
      [styles.up]: this.state.headerUp
    });
    let errorMsg;
    if (error) {
      errorMsg = msg(
        'accountActivationScreen.activationError',
        'Your account could not been activated. Please try again!'
      );
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form>
              <div className={cardClasses}>
                <div className={headerClasses} data-background-color="green">
                  <h4 className="card-title">
                    {error
                      ? msg('accountActivationScreen.activationAlreadyDone', 'Activation failed')
                      : msg('accountActivationScreen.title', 'Activation success')}
                  </h4>
                </div>
                <div className={`card-content ${styles.content}`}>
                  <div className="form-group" style={{ marginLeft: 20, marginTop: -5, textAlign: 'center' }}>
                    {error
                      ? errorMsg
                      : msg(
                          'accountActivationScreen.message',
                          // tslint:disable-next-line:max-line-length
                          `Congratulations! Your account was successfully activated. You can now start using the ${process.env.REACT_APP_NAME} app!`,
                          { name: process.env.REACT_APP_NAME || 'ReGage' }
                        )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
