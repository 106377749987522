import reviewsActions, { IReviewsActions } from './actions';
import reviewsReducer from './reducer';
import { ReviewDTO, ReviewConfigDTO, PaginationDTO } from '../types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReviewsStore {
  export type IState = {
    review: ReviewDTO | null;
    review_pending: boolean;
    review_error: string | null;
    reviews_list: PaginationDTO<any>;
    reviews_list_pending: boolean;
    reviews_list_error: string | null;
    review_configs_list: PaginationDTO<any>;
    review_configs_list_pending: boolean;
    review_configs_list_error: string | null;
    review_config: ReviewConfigDTO | null;
    review_config_pending: boolean;
    review_config_error: string | null;
    save_review_config_pending: boolean;
    save_review_config_error: string | null;
    delete_review_config_pending: boolean;
    delete_review_config_error: string | null;
    export_review_config_pending: boolean;
    export_review_config_error: string | null;
    allow_reviews: boolean | null;
    allow_reviews_pending: boolean;
    allow_reviews_error: any | null;
  };

  export const initialState: IState = {
    review: null,
    review_pending: false,
    review_error: null,
    reviews_list: new PaginationDTO(),
    reviews_list_pending: false,
    reviews_list_error: null,
    review_configs_list: new PaginationDTO(),
    review_configs_list_pending: false,
    review_configs_list_error: null,
    review_config: null,
    review_config_pending: false,
    review_config_error: null,
    save_review_config_pending: false,
    save_review_config_error: null,
    delete_review_config_pending: false,
    delete_review_config_error: null,
    export_review_config_pending: false,
    export_review_config_error: null,
    allow_reviews: null,
    allow_reviews_pending: false,
    allow_reviews_error: null
  };

  export enum ActionTypes {
    GET_REVIEW = 'GET_REVIEW',
    GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS',
    GET_REVIEW_FAILED = 'GET_REVIEW_FAILED',
    GET_REVIEWS_LIST = 'GET_REVIEWS_LIST',
    GET_REVIEWS_LIST_SUCCESS = 'GET_REVIEWS_LIST_SUCCESS',
    GET_REVIEWS_LIST_FAILED = 'GET_REVIEWS_LIST_FAILED',
    GET_REVIEW_CONFIG = 'GET_REVIEW_CONFIG',
    GET_REVIEW_CONFIG_SUCCESS = 'GET_REVIEW_CONFIG_SUCCESS',
    GET_REVIEW_CONFIG_FAILED = 'GET_REVIEW_CONFIG_FAILED',
    GET_REVIEW_CONFIGS_LIST = 'GET_REVIEW_CONFIGS_LIST',
    GET_REVIEW_CONFIGS_LIST_SUCCESS = 'GET_REVIEW_CONFIGS_LIST_SUCCESS',
    GET_REVIEW_CONFIGS_LIST_FAILED = 'GET_REVIEW_CONFIGS_LIST_FAILED',
    EDIT_CONFIG_STATUS = 'EDIT_CONFIG_STATUS',
    EDIT_CONFIG_STATUS_SUCCESS = 'EDIT_CONFIG_STATUS_SUCCESS',
    EDIT_CONFIG_STATUS_FAILED = 'EDIT_CONFIG_STATUS_FAILED',
    SAVE_REVIEW_CONFIG = 'SAVE_REVIEW_CONFIG',
    SAVE_REVIEW_CONFIG_SUCCESS = 'SAVE_REVIEW_CONFIG_SUCCESS',
    SAVE_REVIEW_CONFIG_FAILED = 'SAVE_REVIEW_CONFIG_FAILED',
    DELETE_REVIEW_CONFIG = 'DELETE_REVIEW_CONFIG',
    DELETE_REVIEW_CONFIG_SUCCESS = 'DELETE_REVIEW_CONFIG_SUCCESS',
    DELETE_REVIEW_CONFIG_FAILED = 'DELETE_REVIEW_CONFIG_FAILED',
    EXPORT_REVIEW_CONFIG = 'EXPORT_REVIEW_CONFIG',
    EXPORT_REVIEW_CONFIG_SUCCESS = 'EXPORT_REVIEW_CONFIG_SUCCESS',
    EXPORT_REVIEW_CONFIG_FAILED = 'EXPORT_REVIEW_CONFIG_FAILED',
    CLEAR_MODAL_DATA = 'CLEAR_MODAL_DATA',
    ALLOW_REVIEWS = 'ALLOW_REVIEWS',
    ALLOW_REVIEWS_SUCCESS = 'ALLOW_REVIEWS_SUCCESS',
    ALLOW_REVIEWS_FAILED = 'ALLOW_REVIEWS_FAILED',
    CLEAR_REVIEWS_ERROR = 'CLEAR_REVIEWS_ERROR'
  }

  export const actions = reviewsActions;
  export const reducer = reviewsReducer;
  export interface IActions extends IReviewsActions {}
}

export * from './actions';
