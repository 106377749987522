import { Dispatch } from 'redux';
import { FeedbackStore } from '.';
import { Server, AlertUtil, msg, logger, composedCriteriaBuilder } from '../../utils';
import { PaginationDTO, ReviewDTO } from '../types';
import { PlacesStore } from '..';

export interface IFeedbackActions {
  getFeedbackListAction(
    placeId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  changeFeedbackStatus(placeId: string, allowFeedback: boolean): any;
  clearPlaceFeedbackError(): any;
}

class FeedbackActions implements IFeedbackActions {
  getFeedbackListAction(
    placeId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: FeedbackStore.ActionTypes.GET_FEEDBACK_LIST
      });
      if (!limit) {
        limit = 30;
      }
      let url = `places/${placeId}/feedbacks?limit=${limit}`;
      if (skip) {
        url += `&skip=${skip}`;
      }
      if (sort) {
        url += `&sort=${sort}`;
      }
      if (criteria) {
        url += composedCriteriaBuilder(criteria);
      }
      logger.msg('Get feedbacks action, route:/places/placeId/feedbacks', 'GET');
      Server.get(url)
        .then((response: any) => {
          dispatch({
            type: FeedbackStore.ActionTypes.GET_FEEDBACK_LIST_SUCCESS,
            payload: response.data as PaginationDTO<ReviewDTO>
          });
        })
        .catch(error => {
          logger.err('Get feedbacks action, route:/places/placeId/feedbacks', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.reviews.getFeedbackError', 'Due to an error, the feedback list could not be loaded!'),
            'error'
          );
          dispatch({
            type: FeedbackStore.ActionTypes.GET_FEEDBACK_LIST_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  changeFeedbackStatus(placeId: string, allowFeedbacks: boolean) {
    return (dispatch: Dispatch<any>) => {
      logger.msg('Update feedbacks allowed action, route:/places/placeId/allow-feedbacks', 'PUT');
      AlertUtil.fireOnce(
        msg('reduxMessages.reviews.changeFeedbackStatusPending', 'The reviews status is being updated, please wait...'),
        'info'
      );
      Server.put(`places/${placeId}/allow-feedbacks`, { allowFeedbacks })
        .then((response: any) => {
          dispatch({
            type: FeedbackStore.ActionTypes.ALLOW_FEEDBACK_SUCCESS,
            payload: response.data as boolean
          });
          dispatch(PlacesStore.actions.setPlaceAction(response.data));
          AlertUtil.updateContent(
            msg('reduxMessages.reviews.changeFeedbackStatusSuccess', 'The reviews status was successfully updated!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Update feedbacks allowed action, route:/places/placeId/allow-feedbacks', 'PUT');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.reviews.changeFeedbackStatusError',
              'Due to an error, the reviews status could not be updated!'
            ),
            'error'
          );
          dispatch({
            type: FeedbackStore.ActionTypes.ALLOW_FEEDBACK_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  clearPlaceFeedbackError() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: FeedbackStore.ActionTypes.CLEAR_FEEDBACK_ERROR });
    };
  }
}

const feedbackActions = new FeedbackActions();
export default feedbackActions;
