import * as React from 'react';

import { LANGUAGES } from '../../../../../appRedux/translation/types';
import { msg } from '../../../../../utils/index';
import { SimpleSelectComponent } from '../../../../shared';
import styles from './NavAuth.module.css';

interface IProps {
  config: {
    title: string;
    initial_language: string;
    changeLanguage: (option: string) => any;
  };
}

const logoPath = `/images/${process.env.REACT_APP_NAME?.toLowerCase().replace(' ', '-')}-logo.png`;

export default class NavAuthComponent extends React.Component<IProps, {}> {
  handleLanguageChange = (event: any) => {
    const fieldValue = event.target.value;
    this.props.config.changeLanguage(fieldValue);
  };

  render() {
    return (
      <nav className={`navbar navbar-primary navbar-transparent navbar-absolute ${styles.navAuth}`}>
        <div className="container">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="navbar-header">
              <a href="/" className={`navbar-brand ${styles.brand}`}>
                <img src={logoPath} alt="logo" />
              </a>
            </div>
            <div className="col-sm-3 dark-theme" style={{ marginTop: 25 }}>
              <SimpleSelectComponent
                name="language"
                options={[
                  { _id: LANGUAGES.RO, name: msg('nationalities.romanian', 'Romanian') },
                  { _id: LANGUAGES.EN, name: msg('nationalities.english', 'English') }
                ]}
                id="language"
                value={this.props.config.initial_language}
                onChange={this.handleLanguageChange}
                required={false}
                needsAllLabel={false}
                arrayOptions={false}
                shrinkLabel={true}
                classesSelect={{ root: styles.select, icon: styles.icon }}
                needsFullLength={true}
              />
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
