import React from 'react';

import { msg } from '../../../../../../../utils/index';
import { TextFieldComponent } from '../../../../../../shared/index';

interface IProps {
  listItem: any;
  onValueChange: (target: any, index: any, type: string) => any;
  index: any;
  onClick: () => any;
  errors: any;
}

class LimitReachedCondition extends React.Component<IProps> {
  render() {
    const { errors, listItem } = this.props;
    return (
      <div>
        <div className="col-sm-4" style={{ marginRight: 10 }}>
          <TextFieldComponent
            label={msg('autopilot.triggerValue', 'Value')}
            id="triggerValue"
            value={this.props.listItem.value}
            onChange={(e: any) => {
              this.props.onValueChange(e.target.value, this.props.index, 'value');
            }}
            inType="text"
            minLength={1}
            maxLength={4}
            required={true}
            formatError={errors[listItem.type]}
            ref="triggerValue"
            validator={[
              { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 1 } }
            ]}
          />
        </div>
      </div>
    );
  }
}

export default LimitReachedCondition;
