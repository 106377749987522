import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppStore } from '../../../appRedux';
import { InfiniteScrollListComponent, CardComponent } from '../../shared';
import { msg, getFormatDate } from '../../../utils';
import { PaginationDTO, TriggerStatus, AutopilotActionType, TriggerDTO } from '../../../appRedux/types';
import { TriggersStore } from '../../../appRedux/triggers';
import moment from 'moment';
import { colors } from '../../../assets/styles';

interface IProps {
  triggers_list: PaginationDTO<TriggerDTO>;
  triggers_list_pending: boolean;
  triggers_list_error: string | null;
  getTriggersAction(
    expired: boolean,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  clearTriggersError(): any;
}

const TriggersListContainer = (props: IProps) => {
  const [listExpired, setListExpired] = useState(false);
  const listRef = useRef<any>();

  useEffect(() => {
    return () => {
      props.clearTriggersError();
    };
    // tslint:disable-next-line: align
  }, []);

  const getTriggers = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    props.getTriggersAction(listExpired, limit, skip, sort, criteria);
  };

  const getTriggerStatus = (status: number) => {
    switch (status) {
      case TriggerStatus.UNTRIGGERED:
        return msg('triggers.untriggered', 'Not sent');
      case TriggerStatus.UNCLAIMED:
      case TriggerStatus.CLAIMED:
      case TriggerStatus.SPECIAL:
      case TriggerStatus.HIDDEN:
        return msg('triggers.unused', 'Unused');
      case TriggerStatus.USED:
        return msg('triggers.used', 'Used');
      case TriggerStatus.PARTIAL:
        return msg('triggers.partial', 'Partial');
      default:
        return '';
    }
  };

  const getTriggerBadge = (status: number) => {
    switch (status) {
      case TriggerStatus.USED:
        return colors.green;
      case TriggerStatus.PARTIAL:
        return colors.blue;
      default:
        return colors.gray;
    }
  };

  const getListFilters = () => [
    {
      field: 'status',
      value: [
        {
          _id: '',
          name: msg('triggers.allTriggers', 'All triggers')
        },
        {
          _id: 'UNTRIGGERED',
          name: msg('triggers.untriggeredTriggers', 'Not sent')
        },
        {
          _id: 'UNUSED',
          name: msg('triggers.unusedTriggers', 'Unused')
        },
        {
          _id: 'PARTIAL',
          name: msg('triggers.partialTriggers', 'Partially used')
        },
        {
          _id: 'USED',
          name: msg('triggers.usedTriggers', 'Used')
        }
      ],
      default: ''
    }
  ];

  const getAutopilotReward = (autopilotAction: any) => {
    switch (autopilotAction?.type) {
      case AutopilotActionType.REWARD:
        return msg('triggers.reward', 'Reward') + ': ' + autopilotAction.value;
      case AutopilotActionType.POINTS:
        return msg('triggers.coins', 'Coins:') + ' ' + autopilotAction.value;
      case AutopilotActionType.MEMBER_STATUS:
        return msg('triggers.statusChanged', 'Status changed:') + ' ' + autopilotAction.value;
      case AutopilotActionType.MESSAGE:
        return msg('triggers.actionMessage', 'Message:') + ' ' + autopilotAction.value.message;
      case AutopilotActionType.CUSTOM:
        return autopilotAction.value;
      case AutopilotActionType.VOUCHER:
        return autopilotAction.value?.title;
      default:
        return 'N/A';
    }
  };

  const parseList = (list: PaginationDTO<TriggerDTO>) => {
    return {
      ...list,
      results: list?.results
        ? list.results.map((item: TriggerDTO) => {
            return {
              date: getFormatDate(item.date),
              status: {
                type: 'custom',
                render: () => {
                  const value = getTriggerStatus(item.status);
                  return (
                    <div
                      className="badge"
                      style={{ backgroundColor: getTriggerBadge(item.status), cursor: 'pointer' }}
                      onClick={() => setFilterHandler(item.status, 0)}
                    >
                      {value}
                    </div>
                  );
                }
              },
              user: {
                type: 'custom',
                render: () => {
                  const value =
                    item.user?.lastname && item.user?.firstname
                      ? item.user.lastname + ' ' + item.user.firstname
                      : item.user?.email || 'N/A';
                  return (
                    <div className="linkedData" onClick={() => setSearchHandler(item.user?.email)}>
                      {value}
                    </div>
                  );
                }
              },
              place: {
                type: 'custom',
                render: () => {
                  const value = item.place?.name;
                  return (
                    <div className="linkedData" onClick={() => setSearchHandler(item.place?.name)}>
                      {value}
                    </div>
                  );
                }
              },
              title: {
                type: 'custom',
                render: () => {
                  const value = msg(`simpleAutopilots.${item.autopilotSnapshot.title}`, item.autopilotSnapshot.title);
                  return (
                    <div className="linkedData" onClick={() => setSearchHandler(item.autopilotSnapshot.title)}>
                      {value}
                    </div>
                  );
                }
              },
              reward: getAutopilotReward(item.autopilotSnapshot.actions[0]),
              redeemDate: item.redeemDate ? getFormatDate(item.redeemDate) : 'N/A',
              expirationDate: item.expireDate ? moment(item.expireDate).format('DD.MM.YYYY HH:mm') : 'N/A'
              // delay: item.autopilotSnapshot.delay
              //   ? moment(item.autopilotSnapshot.delay).format('DD.MM.YYYY HH:mm')
              //   : 'N/A'
            };
          })
        : []
    };
  };

  const getFields = () => {
    const fields = {
      date: msg('triggers.date', 'Date'),
      user: msg('triggers.user', 'User'),
      place: msg('triggers.place', 'Place'),
      title: msg('triggers.autopilotTitle', 'Autopilot Title'),
      reward: msg('triggers.reward', 'Reward'),
      status: msg('triggers.status', 'Status'),
      redeemDate: msg('triggers.redeemDate', 'Redeem Date'),
      expirationDate: msg('triggers.expirationDate', 'Expiration Date')
      // delay: msg('triggers.delay', 'Delay')
    };
    return fields;
  };

  const setFilterHandler = (value: any, index: number) => {
    let returnValue;
    switch (value) {
      case TriggerStatus.UNTRIGGERED:
        returnValue = 'UNTRIGGERED';
        break;
      case TriggerStatus.UNCLAIMED:
      case TriggerStatus.CLAIMED:
      case TriggerStatus.SPECIAL:
      case TriggerStatus.HIDDEN:
        returnValue = 'UNUSED';
        break;
      case TriggerStatus.USED:
        returnValue = 'USED';
        break;
      case TriggerStatus.PARTIAL:
        returnValue = 'PARTIAL';
        break;
      default:
        returnValue = '';
    }
    listRef.current?.setFilterByValue(returnValue, index);
  };

  const setSearchHandler = (value?: string) => {
    listRef.current?.setSearchByValue(value);
  };

  const handleCheckbox = (event: any) => {
    setListExpired(!!event.target.checked);
    setFilterHandler('', 0);
  };

  return (
    <CardComponent
      title={msg('triggers.cardTitle', 'Triggers')}
      error={!!props.triggers_list_error}
      headerIcon="pending_actions"
      needsTitle={true}
      checkboxes={[
        {
          label: msg('triggers.listExpired', 'Show expired triggers'),
          checked: listExpired,
          onChange: handleCheckbox
        }
      ]}
    >
      <InfiniteScrollListComponent
        wrappedComponentRef={(ref: any) => (listRef.current = ref)}
        fields={getFields()}
        list={parseList(props.triggers_list)}
        pending={props.triggers_list_pending}
        error={props.triggers_list_error}
        get={getTriggers}
        limit={30}
        sort={{
          fields: ['date', 'redeemDate'],
          default: 'date,-1'
        }}
        filters={getListFilters()}
        search={['search']}
      />
    </CardComponent>
  );
};

function mapStateToProps(state: AppStore.states) {
  return {
    triggers_list: state.triggers.triggers_list,
    triggers_list_pending: state.triggers.triggers_list_pending,
    triggers_list_error: state.triggers.triggers_list_error
  };
}

const dispatchToProps = {
  getTriggersAction: TriggersStore.actions.getTriggersAction,
  clearTriggersError: TriggersStore.actions.clearTriggersError
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(TriggersListContainer));
