import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { TagsStore } from '.';
import { Server, AlertUtil, msg, logger } from '../../utils';
import { IDispatch, PaginationDTO, TagDTO } from '../types';

export interface ITagsActions {
  getTagsAction(forSAdmin: boolean, placeId?: string, includeUserTags?: boolean): IDispatch;
  addTagAction(forSAdmin: boolean, data: TagDTO, placeId?: string): IDispatch;
  editTagAction(forSAdmin: boolean, tagId: string, data: TagDTO, placeId?: string): IDispatch;
  deleteTagAction(forSAdmin: boolean, tagId: string, placeId?: string): IDispatch;
  clearTagsActions(): (dispatch: Dispatch) => void;
}

class TagsActions implements ITagsActions {
  getTagsAction(forSAdmin: boolean, placeId?: string, includeUserTags?: boolean) {
    return async (dispatch: Dispatch<any>) => {
      const url = forSAdmin
        ? 'admin/tags'
        : includeUserTags
        ? `places/${placeId}/tags?includeUserTags=true`
        : `places/${placeId}/tags`;
      dispatch({ type: TagsStore.ActionTypes.GET_TAGS });
      try {
        logger.msg(`Get tags action, route:/${forSAdmin ? 'admin/tags' : 'places/:placeId/tags'}`, 'GET');
        const response = (await Server.get(url)) as AxiosResponse;
        dispatch({
          type: TagsStore.ActionTypes.GET_TAGS_SUCCESS,
          payload: response.data as PaginationDTO<TagDTO>
        });
      } catch (error) {
        logger.err(`Get tags action, route:/${forSAdmin ? 'admin/tags' : 'places/:placeId/tags'}`, 'GET');
        AlertUtil.simple(
          msg('reduxMessages.tags.getTagsError', 'Due to an error, the tags list could not be loaded!'),
          'error'
        );
        dispatch({
          type: TagsStore.ActionTypes.GET_TAGS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  addTagAction = (forSAdmin: boolean, data: TagDTO, placeId?: string) => {
    return async (dispatch: Dispatch<any>) => {
      const url = forSAdmin ? 'admin/tags' : `places/${placeId}/tags`;
      logger.msg(`Add user tag action, route:/${forSAdmin ? 'admin/tags' : 'places/:placeId/tags'}`, 'POST');
      dispatch({ type: TagsStore.ActionTypes.ADD_TAG });
      await Server.post(url, data)
        .then(() => {
          dispatch({
            type: TagsStore.ActionTypes.ADD_TAG_SUCCESS
          });
          dispatch(TagsStore.actions.getTagsAction(forSAdmin, placeId));
        })
        .catch(error => {
          logger.err(`Add user tag action, route:/${forSAdmin ? 'admin/tags' : 'places/:placeId/tags'}`, 'POST');
          AlertUtil.simple(
            msg('reduxMessages.tags.addTagError', 'Due to an error, the tag could not be added!'),
            'error'
          );
          dispatch({
            type: TagsStore.ActionTypes.ADD_TAG_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  };

  editTagAction = (forSAdmin: boolean, tagId: string, data: TagDTO, placeId?: string) => {
    return async (dispatch: Dispatch<any>) => {
      const url = forSAdmin ? `admin/tags/${tagId}` : `places/${placeId}/tags/${tagId}`;
      logger.msg(
        `Edit user tag action, route:/${forSAdmin ? 'admin/tags/:tagId' : 'places/:placeId/tags/:tagId'}`,
        'POST'
      );
      dispatch({ type: TagsStore.ActionTypes.EDIT_TAG });
      await Server.put(url, data)
        .then(() => {
          dispatch({
            type: TagsStore.ActionTypes.EDIT_TAG_SUCCESS
          });
          dispatch(TagsStore.actions.getTagsAction(forSAdmin, placeId));
        })
        .catch(error => {
          logger.err(
            `Edit user tag action, route:/${forSAdmin ? 'admin/tags/:tagId' : 'places/:placeId/tags/:tagId'}`,
            'POST'
          );
          AlertUtil.simple(
            msg('reduxMessages.tags.editTagError', 'Due to an error, the tag could not be edited!'),
            'error'
          );
          dispatch({
            type: TagsStore.ActionTypes.EDIT_TAG_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  };

  deleteTagAction = (forSAdmin: boolean, tagId: string, placeId?: string) => {
    return async (dispatch: Dispatch<any>) => {
      const url = forSAdmin ? `admin/tags/${tagId}` : `places/${placeId}/tags/${tagId}`;
      logger.msg(
        `Delete user tags action, route:/${forSAdmin ? 'admin/tags/:tagId' : 'places/:placeId/tags/:tagId'}`,
        'DELETE'
      );
      dispatch({ type: TagsStore.ActionTypes.DELETE_TAG });
      await Server.delete(url)
        .then(() => {
          dispatch({
            type: TagsStore.ActionTypes.DELETE_TAG_SUCCESS
          });
          dispatch(TagsStore.actions.getTagsAction(forSAdmin, placeId));
        })
        .catch(error => {
          logger.err(
            `Delete tags action, route:/${forSAdmin ? 'admin/tags/:tagId' : 'places/:placeId/tags/:tagId'}`,
            'DELETE'
          );
          if (error.response?.data?.ERROR?.code === 'INVALID') {
            AlertUtil.simple(
              msg('reduxMessages.tags.deleteTagWithActionError', 'You cannot delete a tag with a configured action!'),
              'error'
            );
          } else {
            AlertUtil.simple(
              msg('reduxMessages.tags.deleteTagError', 'Due to an error, the tag could not be deleted!'),
              'error'
            );
          }
          dispatch({
            type: TagsStore.ActionTypes.DELETE_TAG_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  };

  clearTagsActions = () => {
    return (dispatch: Dispatch) => {
      dispatch({ type: TagsStore.ActionTypes.CLEAR_TAGS });
    };
  };
}

const paySettlementsActions = new TagsActions();
export default paySettlementsActions;
