import { AutopilotLogsStore } from '../../../appRedux/autopilot-logs';
import { msg, getFormatDate } from '../../../utils';
import { AppStore } from '../../../appRedux';

export const mapStateToProps = (state: AppStore.states) => {
  const logs = state.autopilotLogs;

  const items = logs.list.items.map(item => {
    let autopilot = 'N/A';
    let place = 'N/A';
    let user = 'N/A';

    item.date = getFormatDate(item.date);
    item.type = EventTypeToMessage(item.type);

    if (logs.autopilots[item.autopilotId]) {
      autopilot = logs.autopilots[item.autopilotId].title;
    }

    if (logs.places[item.placeId]) {
      place = logs.places[item.placeId].name;
    }

    if (logs.members[item.memberId]) {
      user = `${logs.members[item.memberId].firstname} ${logs.members[item.memberId].lastname}`;
    }

    return { ...item, autopilot, place, user };
  });

  return {
    list: items,
    loading: logs.list.loading,
    error: logs.list.error,
    pagination: logs.pagination
  };
};

export const EventTypeToMessage = (type: string): string => {
  const typeMessageMap = {
    [AutopilotLogsStore.autopilotEvents.VerificationStart]: msg(
      'autopilotLogs.verificationStart',
      'Verification start'
    ),
    [AutopilotLogsStore.autopilotEvents.VerificationEnd]: msg('autopilotLogs.verificationEnd', 'Verification End'),
    [AutopilotLogsStore.autopilotEvents.TriggerCreated]: msg('autopilotLogs.triggerCreated', 'Trigger Created'),
    [AutopilotLogsStore.autopilotEvents.RewardGiven]: msg('activity.events.rewardGiven', 'Reward given'),
    [AutopilotLogsStore.autopilotEvents.PointsGiven]: msg('activity.events.pointsGiven', 'Points given'),
    [AutopilotLogsStore.autopilotEvents.MessageSent]: msg('activity.events.messageSent', 'Message sent'),
    [AutopilotLogsStore.autopilotEvents.MemberStatusChanged]: msg(
      'activity.events.memberStatusChanged',
      'Member status changed'
    ),
    [AutopilotLogsStore.autopilotEvents.CheckinCreated]: msg('activity.events.checkinCreated', '!@#!@'),
    [AutopilotLogsStore.autopilotEvents.PromotionStart]: msg(
      'autopilotLogs.promotionStart',
      'Promotion verification start'
    ),
    [AutopilotLogsStore.autopilotEvents.PromotionEnd]: msg('autopilotLogs.promotionEnd', 'Promotion verification end')
  };

  return typeMessageMap[type] || 'N/A';
};
