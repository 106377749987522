export class AnnouncementDTO {
  _id: string;
  title: string = '';
  message: string = '';
  content: string = '';
  users: string[];
  player_ids: string[];
  dispatch: string;
  sent: boolean;
  stats: AnnouncementStatsDTO;
  conditions: AnnouncementConditionDTO[];
  createdAt: number;
  deleted?: boolean;
}

export class AnnouncementConditionDTO {
  type: string = '';
  operator?: string;
  value?: string;
  selectedValues?: string[];
}

export class AnnouncementStatsDTO {
  delivered: number;
  opens: number;
}

export enum AnnouncementConditionTypes {
  CHECKINSNO,
  MEMBERSHIPSNO,
  PLACES
}

export enum AnnouncementConditionOperators {
  $lt,
  $gt,
  $eq,
  $lte,
  $gte
}
