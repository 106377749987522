import { ReviewsStore } from '.';
import { PaginationDTO } from '../types';

function reviewsReducer(
  state: ReviewsStore.IState = ReviewsStore.initialState,
  action: IAction<any>
): ReviewsStore.IState {
  switch (action.type) {
    case ReviewsStore.ActionTypes.SAVE_REVIEW_CONFIG: {
      return {
        ...state,
        save_review_config_pending: true,
        save_review_config_error: null
      };
    }
    case ReviewsStore.ActionTypes.SAVE_REVIEW_CONFIG_SUCCESS: {
      return {
        ...state,
        save_review_config_pending: false,
        save_review_config_error: null,
        review_config: action.payload
      };
    }
    case ReviewsStore.ActionTypes.SAVE_REVIEW_CONFIG_FAILED: {
      return {
        ...state,
        save_review_config_pending: false,
        save_review_config_error: action.payload
      };
    }
    case ReviewsStore.ActionTypes.DELETE_REVIEW_CONFIG: {
      return {
        ...state,
        delete_review_config_pending: true,
        delete_review_config_error: null
      };
    }
    case ReviewsStore.ActionTypes.DELETE_REVIEW_CONFIG_SUCCESS: {
      return {
        ...state,
        delete_review_config_pending: false,
        delete_review_config_error: null
      };
    }
    case ReviewsStore.ActionTypes.DELETE_REVIEW_CONFIG_FAILED: {
      return {
        ...state,
        delete_review_config_pending: false,
        delete_review_config_error: action.payload
      };
    }
    case ReviewsStore.ActionTypes.EXPORT_REVIEW_CONFIG: {
      return {
        ...state,
        export_review_config_pending: true,
        export_review_config_error: null
      };
    }
    case ReviewsStore.ActionTypes.EXPORT_REVIEW_CONFIG_SUCCESS: {
      return {
        ...state,
        export_review_config_pending: false,
        export_review_config_error: null
      };
    }
    case ReviewsStore.ActionTypes.EXPORT_REVIEW_CONFIG_FAILED: {
      return {
        ...state,
        export_review_config_pending: false,
        export_review_config_error: action.payload
      };
    }
    case ReviewsStore.ActionTypes.GET_REVIEW_CONFIG: {
      return {
        ...state,
        review_config_pending: true,
        review_config_error: null
      };
    }
    case ReviewsStore.ActionTypes.GET_REVIEW_CONFIG_SUCCESS: {
      return {
        ...state,
        review_config_pending: false,
        review_config_error: null,
        review_config: action.payload
      };
    }
    case ReviewsStore.ActionTypes.GET_REVIEW_CONFIG_FAILED: {
      return {
        ...state,
        review_config_pending: false,
        review_config_error: action.payload,
        review_config: null
      };
    }
    case ReviewsStore.ActionTypes.EDIT_CONFIG_STATUS: {
      return {
        ...state,
        review_config_pending: true,
        review_config_error: null
      };
    }
    case ReviewsStore.ActionTypes.EDIT_CONFIG_STATUS_SUCCESS: {
      let index = -1;
      const results = state.review_configs_list?.results?.map((item, i) => {
        if (item._id === action.payload._id) {
          index = i;
        }
        return { ...item, isActive: false };
      });
      results[index] = action.payload;
      return {
        ...state,
        review_config_pending: false,
        review_config_error: null,
        review_config: action.payload,
        review_configs_list: { ...state.review_configs_list, results }
      };
    }
    case ReviewsStore.ActionTypes.EDIT_CONFIG_STATUS_FAILED: {
      return {
        ...state,
        review_config_pending: false,
        review_config_error: action.payload,
        review_config: null
      };
    }
    case ReviewsStore.ActionTypes.GET_REVIEW_CONFIGS_LIST: {
      return {
        ...state,
        review_configs_list_pending: true,
        review_configs_list_error: null
      };
    }
    case ReviewsStore.ActionTypes.GET_REVIEW_CONFIGS_LIST_SUCCESS: {
      return {
        ...state,
        review_configs_list_pending: false,
        review_configs_list_error: null,
        review_configs_list: action.payload
      };
    }
    case ReviewsStore.ActionTypes.GET_REVIEW_CONFIGS_LIST_FAILED: {
      return {
        ...state,
        review_configs_list_pending: false,
        review_configs_list_error: action.payload,
        review_configs_list: new PaginationDTO()
      };
    }
    case ReviewsStore.ActionTypes.GET_REVIEWS_LIST: {
      return {
        ...state,
        reviews_list_pending: true,
        reviews_list_error: null
      };
    }
    case ReviewsStore.ActionTypes.GET_REVIEWS_LIST_SUCCESS: {
      return {
        ...state,
        reviews_list_pending: false,
        reviews_list_error: null,
        reviews_list: action.payload
      };
    }
    case ReviewsStore.ActionTypes.GET_REVIEWS_LIST_FAILED: {
      return {
        ...state,
        reviews_list_pending: false,
        reviews_list_error: action.payload,
        reviews_list: new PaginationDTO()
      };
    }
    case ReviewsStore.ActionTypes.GET_REVIEW: {
      return {
        ...state,
        review_pending: true,
        review_error: null
      };
    }
    case ReviewsStore.ActionTypes.GET_REVIEW_SUCCESS: {
      return {
        ...state,
        review_pending: false,
        review_error: null,
        review: action.payload
      };
    }
    case ReviewsStore.ActionTypes.GET_REVIEW_FAILED: {
      return {
        ...state,
        review_pending: false,
        review_error: action.payload,
        review: null
      };
    }
    case ReviewsStore.ActionTypes.CLEAR_MODAL_DATA: {
      return {
        ...state,
        review_pending: false,
        review_error: null,
        review: null
      };
    }
    case ReviewsStore.ActionTypes.ALLOW_REVIEWS: {
      return {
        ...state,
        allow_reviews: null,
        allow_reviews_pending: true,
        allow_reviews_error: null
      };
    }
    case ReviewsStore.ActionTypes.ALLOW_REVIEWS_SUCCESS: {
      return {
        ...state,
        allow_reviews: action.payload,
        allow_reviews_pending: false,
        allow_reviews_error: null
      };
    }
    case ReviewsStore.ActionTypes.ALLOW_REVIEWS_FAILED: {
      return {
        ...state,
        allow_reviews: null,
        allow_reviews_pending: false,
        allow_reviews_error: null
      };
    }
    case ReviewsStore.ActionTypes.CLEAR_REVIEWS_ERROR: {
      return {
        ...state,
        reviews_list_error: null,
        review_configs_list_error: null
      };
    }
    default:
      return state;
  }
}

export default reviewsReducer;
