import * as React from 'react';
import Dropzone, { Accept } from 'react-dropzone';
import { Bounce } from 'react-activity';
import { Server } from '../../../utils';
import { styles } from './UploadStyles';
import { ModalCropComponent } from '../UploadComponent/ModalCropComponent';
import { colors } from '../../../assets/styles';

interface IProps {
  url: string;
  extraData: any;
  accept: Accept;
  media: any;
  disabled?: boolean;
  crop?: boolean;
  onSuccess?: (resp: any) => void;
}

interface IState {
  status: string;
  filename: string;
  showModal: boolean;
  imageSrc: any;
}

export default class UploadComponentReward extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      status: '',
      filename: '',
      showModal: false,
      imageSrc: null
    };
  }

  onUploadProgress = (event: any) => {
    this.setState({ status: 'PENDING' });
  };

  onDrop = (file: any, rejected: any) => {
    if (rejected.length) {
      return this.setState({ status: 'REJECTED' });
    }
    this.setState({ filename: file[0].name });
    if (this.props.crop) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({ imageSrc: reader.result }));
      if (file) {
        reader.readAsDataURL(file[0]);
      }
      this.toggleModal();
    } else {
      let data = new FormData();
      file.forEach((item: any) => {
        data.append('file', item);
      });
      if (this.props.extraData) {
        for (const el in this.props.extraData) {
          if (this.props.extraData.hasOwnProperty(el)) {
            data.append(el, this.props.extraData[el]);
          }
        }
      }
      return this.uploadToServer(data);
    }
  };

  uploadToServer = (data: any) => {
    return Server.post(this.props.url, data, false, true, this.onUploadProgress)
      .then((res: any) => {
        if (this.props.onSuccess) {
          this.props.onSuccess(res);
        }
        this.setState({ status: 'COMPLETE' }, () => setTimeout(() => this.setState({ status: '' }), 2000));
      })
      .catch(() => {
        this.setState({ status: 'ERROR' }, () => setTimeout(() => this.setState({ status: '' }), 2000));
      });
  };

  onDragEnter = () => {
    this.setState({ status: 'ENTER' });
  };

  onDragLeave = () => {
    this.setState({ status: '' });
  };

  onDropRejected = () => {
    this.setState({ status: 'REJECTED' });
  };

  getMessageByStatus() {
    switch (this.state.status) {
      case 'PENDING':
        return (
          <div style={styles.iconContainer}>
            <Bounce color={colors.lightGreen} />
          </div>
        );
      case 'COMPLETE':
        return (
          <div style={styles.iconContainer}>
            <i className="material-icons" style={styles.iconDone}>
              cloud_done
            </i>
          </div>
        );
      case 'ERROR':
        return (
          <div style={styles.iconContainer}>
            <i className="material-icons" style={styles.iconError}>
              error
            </i>
          </div>
        );
      case 'REJECTED':
        return (
          <div style={styles.iconContainer}>
            <i className="material-icons" style={styles.iconOff}>
              cloud_off
            </i>
          </div>
        );
      default:
        return (
          <div style={styles.iconContainer}>
            {this.props.media ? (
              <img src={this.props.media} alt="" style={styles.picture} />
            ) : (
              <i className="material-icons" style={{ fontSize: 50 }}>
                photo
              </i>
            )}
          </div>
        );
    }
  }

  toggleModal = () => {
    const show = this.state.showModal;
    let imageSrc = this.state.imageSrc;
    if (show) {
      imageSrc = null;
    }
    this.setState({
      showModal: !show,
      imageSrc
    });
  };

  render() {
    const disabledStyle = {
      cursor: this.props.disabled ? 'default' : 'pointer',
      opacity: this.props.disabled ? 0.5 : 1
    };
    return (
      <div style={{ ...disabledStyle }}>
        {this.props.crop && this.state.showModal && (
          <ModalCropComponent
            image={this.state.imageSrc}
            aspect={1 / 1}
            saveImage={this.uploadToServer}
            toggleModal={this.toggleModal}
          />
        )}
        <Dropzone
          disabled={this.props.disabled}
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onDropRejected={this.onDropRejected}
          accept={this.props.accept}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div>{this.getMessageByStatus()}</div>
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}
