import { ReviewQuestionType } from '.';

export class ReviewDTO {
  _id: string;
  placeId: string;
  userId?: string;
  memberId?: string;
  reviewConfigId: string;
  answers: ReviewAnswerDTO[];
  anonymous: boolean;
  user?: {
    lastname: string;
    firstname: string;
    phone: string;
    email: string;
  };
  created: number;
  deleted: boolean;
}

export class ReviewAnswerDTO {
  label: string;
  type: ReviewQuestionType;
  options: AnswerOptionDTO[];
}

export class AnswerOptionDTO {
  label: string;
  checked: boolean;
}
