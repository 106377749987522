export class Step1DTO {
  firstname: string = '';
  lastname: string = '';
  phone: string = '';
  tabletCode: string = '';
  email: string = '';
  password: string = '';
}

export class Step3SettingsDTO {
  memberRegistration?: number;
  pointsForCheckin?: number;
  convertAmountRule?: number;
}

export class Step3FlagsDTO {
  becomeMember?: boolean;
  checkin?: boolean;
  useCoins?: boolean;
  sendReceipts?: boolean;
  moments?: boolean;
  news?: boolean;
  simpleAutopilots?: boolean;
}

export class OnboardingConfig {
  partnerId: string;
  placeId: string;
  userId: string;
  placeActivated: boolean;
  emailSent: boolean;
  autopilotsCreated: boolean;
  rewardsCreated: boolean;
}
