import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Slider } from '@material-ui/core';
import { msg } from '../../../utils';
import ReactCrop from 'react-image-crop';

interface IProps {
  image: any;
  aspect?: number;
  saveImage: (file: any) => any;
  toggleModal: () => any;
}

interface IState {
  crop: any;
  imageRef: any;
  padding: number;
  sliderMax: number;
  horizontalPadding: boolean;
}

export class ModalCropComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      crop: {
        aspect: this.props.aspect
      },
      imageRef: null,
      padding: 0,
      sliderMax: 0,
      horizontalPadding: false
    };
  }

  onImageLoaded = (image: any) => {
    const aspect = this.props.aspect || 1;
    const horizontalPadding = image?.width / image?.height <= aspect;
    let sliderMax;
    if (horizontalPadding) {
      const zoomedImgMax = 800 - ((image?.width / image?.height) * 800) / aspect;
      const normalImgMax = Math.abs(image?.height * aspect - image?.width);
      sliderMax = zoomedImgMax > 0 ? Math.min(zoomedImgMax, normalImgMax) : normalImgMax;
    } else {
      const zoomedImgMax = 600 - ((image?.height / image?.width) * 600) / aspect;
      const normalImgMax = Math.abs(image?.width / aspect - image?.height);
      sliderMax = zoomedImgMax > 0 ? Math.min(zoomedImgMax, normalImgMax) : normalImgMax;
    }
    const crop = this.state.crop;
    if (horizontalPadding) {
      crop.height = image?.width + sliderMax;
    } else {
      crop.width = image?.height + sliderMax;
    }
    this.setState({
      crop,
      imageRef: image,
      padding: sliderMax / 2,
      sliderMax,
      horizontalPadding
    });
  };

  onCropChange = (crop: any) => {
    this.setState({ crop });
  };

  setPadding = (event: any, value: number) => {
    const { crop, imageRef, horizontalPadding, padding } = this.state;
    const currentHeight = imageRef.height + (horizontalPadding ? 0 : padding);
    const currentWidth = imageRef.width + (horizontalPadding ? padding : 0);
    const aspect = horizontalPadding ? imageRef.height / imageRef.width : imageRef.width / imageRef.height;
    if (crop.x + crop.width >= currentWidth && value !== padding) {
      crop.x = crop.x - Math.abs(padding - value) * aspect;
    }
    if (crop.y + crop.height >= currentHeight && value !== padding) {
      crop.y = crop.y - Math.abs(padding - value) * (imageRef.width / imageRef.height);
    }
    if ((crop.width + 1 >= currentWidth || crop.height + 1 >= currentHeight) && value < padding) {
      if (horizontalPadding) {
        crop.width = imageRef.width + value;
        crop.height = crop.width / crop.aspect;
      } else {
        crop.height = imageRef.height + value;
        crop.width = crop.height * crop.aspect;
      }
    }
    this.setState({ padding: value });
  };

  getCroppedImg = (image: any, crop: any) => {
    const { padding, horizontalPadding } = this.state;
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const context = canvas.getContext('2d');

    context?.drawImage(
      image,
      (crop.x - (horizontalPadding ? padding / 2 : 0)) * scaleX,
      (crop.y - (horizontalPadding ? 0 : padding / 2)) * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob: any) => {
        if (!blob) {
          return;
        }
        let data = new FormData();
        data.append('file', blob);
        resolve(data);
      });
    });
  };

  onSave = async () => {
    const { crop } = this.state;
    if (this.state.imageRef && crop.width && crop.height) {
      const data = await this.getCroppedImg(this.state.imageRef, crop);
      this.props.toggleModal();
      this.props.saveImage(data);
    }
  };

  render() {
    const { padding, horizontalPadding, sliderMax } = this.state;
    return (
      <Modal show={true} onHide={this.props.toggleModal} dialogClassName="cropperModal">
        <Modal.Header>
          <Modal.Title>{msg('cardTitle.cropImage', 'Crop image')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <div
            style={{
              height: 600,
              width: 800,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <ReactCrop
              src={this.props.image}
              crop={this.state.crop}
              onChange={this.onCropChange}
              onImageLoaded={this.onImageLoaded}
              imageStyle={{
                maxHeight: 600,
                maxWidth: 800,
                padding: horizontalPadding ? `0px ${padding / 2}px` : `${padding / 2}px 0px`
              }}
            />
          </div>
          {sliderMax ? (
            <div style={{ width: 780, marginLeft: 10, paddingTop: 5, textAlign: 'left' }}>
              <div>{msg('cropper.resize', 'Use the slider to resize the image')}</div>
              <Slider
                value={padding}
                min={0}
                max={sliderMax}
                onChange={this.setPadding}
                aria-labelledby="continuous-slider"
              />
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={this.props.toggleModal}>
            {msg('general.close', 'Close')}
          </Button>
          <Button className="btn btn-success" onClick={this.onSave}>
            {msg('general.save', 'Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
