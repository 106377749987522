/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { generatePath, NavLink } from 'react-router-dom';

import runtimeEnv from '@mars/heroku-js-runtime-env';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Business from '@material-ui/icons/Business';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Settings from '@material-ui/icons/Settings';
import Store from '@material-ui/icons/Store';

import { PlaceDTO } from '../../../appRedux/types';
import sidebarBackgroundImg from '../../../assets/images/bg-sidebar.jpg';
import { colors } from '../../../assets/styles';
import { getServerName, msg } from '../../../utils';
import { adminRoutes, partnerRoutes } from '../../layouts';
import styles from './SidebarComponent.module.css';

const env = runtimeEnv();

const herokuReleaseVersion = process.env.HEROKU_RELEASE_VERSION
  ? Number(process.env.HEROKU_RELEASE_VERSION.slice(1))
  : 0;

interface IProps {
  config: {
    user: any;
    place?: PlaceDTO | null;
    origin?: string;
  };
  actions: any;
}

interface IState {
  openAccountInfo: boolean;
  openPartners: boolean;
  openIndustries: boolean;
  openSettings: boolean;
}

const logoPath = `/images/${process.env.REACT_APP_NAME?.toLowerCase().replace(' ', '-')}-logo.png`;

export class SidebarComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openAccountInfo: false,
      openPartners: false,
      openIndustries: false,
      openSettings: false
    };
  }

  handleClickAccount = () => {
    this.setState(state => ({ openAccountInfo: !state.openAccountInfo }));
  };

  handleClickPartners = () => {
    this.setState(state => ({ openPartners: !state.openPartners }));
  };

  handleClickIndustries = () => {
    this.setState(state => ({ openIndustries: !state.openIndustries }));
  };

  handleClickSettings = () => {
    this.setState(state => ({ openSettings: !state.openSettings }));
  };

  handleRoutes = (routes: any, placeId?: string) => {
    let mappedRoutes: any = {};
    Object.keys(routes).forEach(item => {
      if (!placeId || !routes[item].default) {
        mappedRoutes[item] = routes[item].path;
      } else {
        mappedRoutes[item] = generatePath(routes[item].default, { placeId });
      }
    });
    return mappedRoutes;
  };

  render() {
    const { actions, config } = this.props;
    const { place } = config;
    const routesForPartners = actions.selected_place ? this.handleRoutes(partnerRoutes, actions.selected_place) : {};
    const routesForAdmin = actions.forSAdmin ? this.handleRoutes(adminRoutes) : {};
    return (
      <div className={`sidebar ${styles.sidebar}`} data-background-color="black" data-active-color="green">
        <div className="logo">
          <img
            src={
              place?.externalApp && !actions.forSAdmin
                ? `/images/${place.externalApp.split('.')[2]}-logo.png`
                : logoPath
            }
            alt="logo"
          />
          {actions.isUserOwner && <div className="owner">OWNER</div>}
        </div>
        <div className="sidebar-wrapper" style={{ height: 'calc(100vh - 100px)' }}>
          <div className="user">
            <div className="info">
              <ListItem button={true} onClick={this.handleClickAccount}>
                <ListItemIcon style={{ color: colors.white, marginRight: 1, marginLeft: 5 }}>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText
                  secondary={this.props.config.user.lastname + ' ' + this.props.config.user.firstname}
                  secondaryTypographyProps={{ style: { color: colors.white } }}
                />
                {this.state.openAccountInfo ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />}
              </ListItem>
              <Collapse in={this.state.openAccountInfo} timeout="auto" unmountOnExit={true}>
                <ul className="nav">
                  <li>
                    {actions.forSAdmin ? (
                      <NavLink
                        to={routesForAdmin.PROFILE}
                        onClick={this.props.actions.toggleSideBar}
                        activeClassName="active"
                        exact={true}
                      >
                        <i className="material-icons">edit</i>
                        <p>{msg('sidebar.editProfile', 'Edit profile')}</p>
                      </NavLink>
                    ) : (
                      <NavLink
                        to={routesForPartners.PROFILE}
                        onClick={this.props.actions.toggleSideBar}
                        activeClassName="active"
                        exact={true}
                      >
                        <i className="material-icons">edit</i>
                        <p>{msg('sidebar.editProfile', 'Edit profile')}</p>
                      </NavLink>
                    )}
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => {
                        this.props.actions.toggleSideBar();
                        this.props.actions.logOut();
                      }}
                    >
                      <i className="material-icons">offline_bolt</i>
                      <p>{msg('sidebar.logOut', 'Log out')}</p>
                    </a>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <ul className="nav">
            <li>
              {actions.forSAdmin && actions.isPaymentsAccount ? null : actions.forSAdmin ? (
                <NavLink
                  to={routesForAdmin.DASHBOARD}
                  onClick={this.props.actions.toggleSideBar}
                  activeClassName="active"
                  exact={true}
                >
                  <i className="material-icons">dashboard</i>
                  <p>{msg('sidebar.dashboard', 'Dashboard')}</p>
                </NavLink>
              ) : (
                <NavLink
                  to={routesForPartners.DASHBOARD}
                  onClick={this.props.actions.toggleSideBar}
                  activeClassName="active"
                  exact={true}
                >
                  <i className="material-icons">dashboard</i>
                  <p>{msg('sidebar.dashboard', 'Dashboard')}</p>
                </NavLink>
              )}
            </li>
            {actions.forSAdmin && actions.isPaymentsAccount ? (
              <>
                <li>
                  <NavLink
                    to={routesForAdmin.TRANSACTIONS}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">credit_card</i>
                    <p>{msg('sidebar.payments', 'Payments')}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesForAdmin.BATCHES}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">credit_score</i>
                    <p>{msg('sidebar.paymentSettlements', 'Settlements')}</p>
                  </NavLink>
                </li>
              </>
            ) : actions.forSAdmin ? (
              <>
                <li>
                  <ListItem button={true} onClick={this.handleClickPartners}>
                    <ListItemIcon style={{ color: colors.white, marginRight: 1, marginLeft: 5 }}>
                      <Business />
                    </ListItemIcon>
                    <ListItemText
                      secondary={msg('sidebar.partners', 'Partners')}
                      secondaryTypographyProps={{ style: { color: colors.white } }}
                    />
                    {this.state.openPartners ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />}
                  </ListItem>
                  <Collapse in={this.state.openPartners} timeout="auto" unmountOnExit={true}>
                    <ul className="nav">
                      <li>
                        <NavLink
                          to={routesForAdmin.PARTNERS}
                          onClick={this.props.actions.toggleSideBar}
                          activeClassName="active"
                          exact={true}
                        >
                          <i className="material-icons">list_alt</i>
                          <p>{msg('sidebar.partnersList', 'List of Partners')}</p>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/admin/partners/add"
                          onClick={this.props.actions.toggleSideBar}
                          activeClassName="active"
                          exact={true}
                        >
                          <i className="material-icons">person_add</i>
                          <p>{msg('sidebar.addPartner', 'Add Partner')}</p>
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li>
                  <ListItem button={true} onClick={this.handleClickIndustries}>
                    <ListItemIcon style={{ color: colors.white, marginRight: 1, marginLeft: 5 }}>
                      <Store />
                    </ListItemIcon>
                    <ListItemText
                      secondary={msg('sidebar.industries', 'Industries')}
                      secondaryTypographyProps={{ style: { color: colors.white } }}
                    />
                    {this.state.openIndustries ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />}
                  </ListItem>
                  <Collapse in={this.state.openIndustries} timeout="auto" unmountOnExit={true}>
                    <ul className="nav">
                      <li>
                        <NavLink
                          to={routesForAdmin.INDUSTRIES}
                          onClick={this.props.actions.toggleSideBar}
                          activeClassName="active"
                          exact={true}
                        >
                          <i className="material-icons">list</i>
                          <p>{msg('sidebar.industriesList', 'List of Industries')}</p>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/admin/industries/add"
                          onClick={this.props.actions.toggleSideBar}
                          activeClassName="active"
                          exact={true}
                        >
                          <i className="material-icons">add_circle</i>
                          <p>{msg('sidebar.addIndustry', 'Add Industry')}</p>
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                <li>
                  <NavLink
                    to={routesForAdmin.ONBOARDINGS}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">folder_shared</i>
                    <p>{msg('sidebar.onboardings', 'Onboardings')}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesForAdmin.USERS}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">group</i>
                    <p>{msg('sidebar.users', 'Users')}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesForAdmin.TAGS}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">local_offer</i>
                    <p>{msg('sidebar.tags', 'Tags')}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesForAdmin.ACTIVITY}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">where_to_vote</i>
                    <p>{msg('sidebar.activity', 'Activity')}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesForAdmin.TRIGGERS}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">pending_actions</i>
                    <p>{msg('sidebar.triggers', 'Triggers')}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={routesForAdmin.AUTOPILOT_LOGS} activeClassName="active" exact={true}>
                    <i className="material-icons">assignment</i>
                    <p>{msg('sidebar.autopilotLogs', 'Autopilot Logs')}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesForAdmin.AGENDA}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">work</i>
                    <p>{msg('sidebar.agenda', 'Agenda Jobs')}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesForAdmin.ANNOUNCEMENTS}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">event</i>
                    <p>{msg('sidebar.announcements', 'Announcements')}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesForAdmin.RECEIPTS}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">receipt</i>
                    <p>{msg('sidebar.receipts', 'Receipts')}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesForAdmin.TRANSACTIONS}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">credit_card</i>
                    <p>{msg('sidebar.payments', 'Payments')}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesForAdmin.BATCHES}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">credit_score</i>
                    <p>{msg('sidebar.paymentSettlements', 'Settlements')}</p>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={routesForAdmin.EMAILS}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                  >
                    <i className="material-icons">mail</i>
                    <p>{msg('sidebar.partnerEmails', 'Partner Emails')}</p>
                  </NavLink>
                </li>
                <li>
                  <ListItem button={true} onClick={this.handleClickSettings}>
                    <ListItemIcon style={{ color: colors.white, marginRight: 1, marginLeft: 5 }}>
                      <Settings />
                    </ListItemIcon>
                    <ListItemText
                      secondary={msg('sidebar.settings', 'Settings')}
                      secondaryTypographyProps={{ style: { color: colors.white } }}
                    />
                    {this.state.openSettings ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />}
                  </ListItem>
                  <Collapse in={this.state.openSettings} timeout="auto" unmountOnExit={true}>
                    <ul className="nav">
                      <li>
                        <NavLink
                          to={routesForAdmin.REWARDS}
                          onClick={this.props.actions.toggleSideBar}
                          activeClassName="active"
                          exact={true}
                        >
                          <i className="material-icons">card_giftcard</i>
                          <p>{msg('sidebar.rewards', 'Rewards')}</p>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={routesForAdmin.PACKAGES}
                          onClick={this.props.actions.toggleSideBar}
                          activeClassName="active"
                          exact={true}
                        >
                          <i className="material-icons">card_giftcard</i>
                          <p>{msg('sidebar.packages', 'Packages')}</p>
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              </>
            ) : (
              <div style={{ marginTop: '10px', paddingBottom: '50px' }}>
                <li className="sideBar-item">
                  <NavLink
                    to={routesForPartners.PLACE}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                    className="NavLink-elements"
                  >
                    <i className="material-icons">place</i>
                    <p>{msg('sidebar.myPlace', 'My Place')}</p>
                  </NavLink>
                </li>
                <li className="sideBar-item">
                  <NavLink
                    to={routesForPartners.PLACE_ADMINS}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                    className="NavLink-elements"
                  >
                    <i className="material-icons">manage_accounts</i>
                    <p>{msg('sidebar.placeAdmins', 'Place admins')}</p>
                  </NavLink>
                </li>
                {place?.featureFlags?.becomeMember ? (
                  <li className="sideBar-item">
                    <NavLink
                      to={routesForPartners.MEMBERS}
                      onClick={this.props.actions.toggleSideBar}
                      activeClassName="active"
                      exact={true}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">group</i>
                      <p>{msg('sidebar.members', 'Members')}</p>
                    </NavLink>
                  </li>
                ) : null}
                {/* <li className="sideBar-item">
                  <NavLink
                    to={placeCheckins}
                    onClick={this.props.actions.toggleSideBar}
                    activeClassName="active"
                    exact={true}
                    className="NavLink-elements"
                  >
                    <i className="material-icons">where_to_vote</i>
                    <p>{msg('sidebar.checkins', 'Checkins')}</p>
                  </NavLink>
                </li> */}
                {place?.featureFlags?.becomeMember ? (
                  <li className="sideBar-item">
                    <NavLink
                      to={routesForPartners.ACTIVITY}
                      activeClassName="active"
                      exact={true}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">where_to_vote</i>
                      <p>{msg('sidebar.activity', 'Activity')}</p>
                    </NavLink>
                  </li>
                ) : null}
                {place?.featureFlags?.approveReceipts ? (
                  <li className="sideBar-item">
                    <NavLink
                      to={routesForPartners.RECEIPTS}
                      onClick={this.props.actions.toggleSideBar}
                      activeClassName="active"
                      exact={true}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">receipt</i>
                      <p>{msg('sidebar.receipts', 'Receipts')}</p>
                    </NavLink>
                  </li>
                ) : null}
                {place?.featureFlags?.transactions ? (
                  <li className="sideBar-item">
                    <NavLink
                      to={routesForPartners.TRANSACTIONS}
                      onClick={this.props.actions.toggleSideBar}
                      activeClassName="active"
                      exact={true}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">credit_card</i>
                      <p>{msg('sidebar.payments', 'Payments')}</p>
                    </NavLink>
                  </li>
                ) : null}
                {place?.featureFlags?.moments ? (
                  <li className="sideBar-item">
                    <NavLink
                      to={routesForPartners.EVENTS}
                      onClick={this.props.actions.toggleSideBar}
                      activeClassName="active"
                      exact={true}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">event</i>
                      <p>{msg('sidebar.promotionalMaterials', 'Promotional Materials')}</p>
                    </NavLink>
                  </li>
                ) : null}
                {actions.isSupport && place?.featureFlags?.simpleAutopilots ? (
                  <li className="sideBar-item">
                    <NavLink
                      to={routesForPartners.AUTOPILOT}
                      onClick={this.props.actions.toggleSideBar}
                      activeClassName="active"
                      exact={true}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">assignment</i>
                      <p>{msg('sidebar.autopilot', 'Autopilot')}</p>
                    </NavLink>
                  </li>
                ) : null}
                {place?.featureFlags?.simpleAutopilots ? (
                  <li className="sideBar-item">
                    <NavLink
                      to={routesForPartners.SIMPLE_AUTOPILOTS}
                      onClick={this.props.actions.toggleSideBar}
                      activeClassName="active"
                      exact={true}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">assignment</i>
                      <p>{msg('sidebar.simpleAutopilots', 'Simple Autopilots')}</p>
                    </NavLink>
                  </li>
                ) : null}
                {place?.featureFlags?.checkin && place?.featureFlags?.useCoins ? (
                  <li className="sideBar-item">
                    <NavLink
                      to={routesForPartners.REWARDS}
                      onClick={this.props.actions.toggleSideBar}
                      activeClassName="active"
                      exact={true}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">card_giftcard</i>
                      <p>{msg('sidebar.rewards', 'Rewards')}</p>
                    </NavLink>
                  </li>
                ) : null}
                {place?.featureFlags?.reviews ? (
                  <li className="sideBar-item">
                    <NavLink
                      to={routesForPartners.REVIEWS}
                      onClick={this.props.actions.toggleSideBar}
                      activeClassName="active"
                      exact={true}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">poll</i>
                      <p>{msg('sidebar.reviews', 'Reviews')}</p>
                    </NavLink>
                  </li>
                ) : null}
                {place?.featureFlags?.reviews ? (
                  <li className="sideBar-item">
                    <NavLink
                      to={routesForPartners.FEEDBACK}
                      onClick={this.props.actions.toggleSideBar}
                      activeClassName="active"
                      exact={true}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">thumbs_up_down</i>
                      <p>{msg('sidebar.feedback', 'Feedback')}</p>
                    </NavLink>
                  </li>
                ) : null}
                {place?.featureFlags?.becomeMember && place?.featureFlags?.tags && (
                  <li className="sideBar-item">
                    <NavLink
                      to={routesForPartners.TAGS}
                      onClick={this.props.actions.toggleSideBar}
                      activeClassName="active"
                      exact={true}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">local_offer</i>
                      <p>{msg('sidebar.tags', 'Tags')}</p>
                    </NavLink>
                  </li>
                )}
                {actions.isUserOwner && actions.isPartnerOwner && (
                  <li className="sideBar-item">
                    <NavLink
                      to={routesForPartners.EMAILS}
                      onClick={this.props.actions.toggleSideBar}
                      activeClassName="active"
                      exact={true}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">mail</i>
                      <p>{msg('sidebar.partnerEmails', 'Partner Emails')}</p>
                    </NavLink>
                  </li>
                )}
                {actions.isUserOwner && actions.isPartnerOwner && (
                  <li className="sideBar-item">
                    <a
                      href="#"
                      style={{ padding: 10 }}
                      onClick={this.props.actions.toggleExportModal}
                      className="NavLink-elements"
                    >
                      <i className="material-icons">description</i>
                      <p>{msg('exportExcel.exportExcelPartners', 'Partner Reports')}</p>
                    </a>
                  </li>
                )}
                <li className="sideBar-item">
                  <a
                    href="#"
                    style={{ padding: 10, backgroundColor: '#11b3caa8' }}
                    onClick={() => {
                      window.open(
                        env.REACT_APP_NAME === 'EP Mall' ? 'http://ep-help.regage.io/#/' : 'https://help.regage.io/#/'
                      );
                    }}
                  >
                    <i className="material-icons">help</i>
                    <p>{msg('sidebar.help', 'Help')}</p>
                  </a>
                </li>
              </div>
            )}
          </ul>
        </div>
        <div
          style={{
            backgroundColor: '#070606',
            width: '100%',
            zIndex: 10,
            position: 'absolute',
            bottom: 0,
            paddingBottom: 5
          }}
        >
          <small
            style={{ marginLeft: '15px', color: colors.gray }}
            // tslint:disable-next-line: max-line-length
          >{`${env.REACT_APP_VERSION}-v${herokuReleaseVersion + 1}/${getServerName(
            this.props.config.origin || env.REACT_APP_API_URL
          )}`}</small>
        </div>
        <div className="sidebar-background" style={{ backgroundImage: `url(${sidebarBackgroundImg})` }} />
      </div>
    );
  }
}
