import React from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ButtonComponent, MultipleSelectComponent, TextFieldComponent } from '../../../../shared';
import { PaginationDTO, TagDTO, UserDTO, UserTagDTO } from '../../../../../appRedux/types';
import { msg, Validator, IValidator, getTagStylesForSelect } from '../../../../../utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as _ from 'lodash';
import { InputLabel } from '@material-ui/core';
interface IProps {
  user?: UserDTO | null;
  save?: (user: UserDTO) => void;
  pending?: boolean;
  output?: boolean;
  onChange?: (user: UserDTO) => void;
  error?: any;
  changePasswordAsAdmin?: (userId: string, password: string) => void;
  editMode?: boolean;
  tags_list?: PaginationDTO<TagDTO>;
}

interface IState {
  user: UserDTO;
  isDirty: boolean;
  date: any;
  message: {
    firstname: string;
    lastname: string;
    phone: string;
    email: string;
  };
  passwordChangeCheck: boolean;
  password: string;
}

class UserFormComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      user: props.user ? { ...props.user } : new UserDTO(),
      isDirty: false,
      date: props.user && props.user.birthday ? moment(props.user.birthday) : moment(),
      message: {
        firstname: '',
        lastname: '',
        phone: '',
        email: ''
      },
      passwordChangeCheck: false,
      password: ''
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.user && !_.isEqual(prevProps.user, this.props.user)) {
      this.setState({ user: this.props.user });
    }
    if (!prevProps.error && this.props.error?.code === 11000 && this.props.error?.name === 'MongoError') {
      toast.error(msg('register.phoneExists', 'There is already an account associated with the given phone!'));
    }
  }

  saveHandler = () => {
    const { save, validator, changePasswordAsAdmin } = this.props;
    const { user, password } = this.state;
    const { isValid } = validator!;
    if (this.props.editMode && password !== '' && changePasswordAsAdmin) {
      changePasswordAsAdmin(user._id, password);
    }
    if (!_.isEqual(this.props.user, user) && isValid && isValid() && save) {
      if (!this.props.editMode && password !== '' && this.state.passwordChangeCheck) {
        user.password = password;
      }
      if (!this.props.editMode && user.phone === '') {
        delete user.phone;
      } else if (user.phone === '') {
        user.phone = null;
      }
      save(user);
    }
  };

  handleChange = (event: any) => {
    const { isDirty } = this.props.validator!;
    const fieldKey = event.target.getAttribute('id');
    const fieldValue = event.target.value;
    let { user, password } = this.state;
    const message = this.state.message;
    isDirty(fieldKey);
    message[fieldKey] = '';
    if (fieldKey === 'newPassword') {
      password = fieldValue;
    } else {
      user[fieldKey] = fieldValue;
    }
    this.setState({ user, isDirty: true, password });
    if (this.props.output && this.props.onChange) {
      this.props.onChange(user);
    }
  };

  handlePasswordChangeCheck = () => {
    this.setState({
      passwordChangeCheck: !this.state.passwordChangeCheck
    });
  };

  handleUserTagsChange = (values: { label: string; value: string }[]) => {
    let userTags: UserTagDTO[] = [];
    if (values) {
      values.forEach((item: { label: string; value: string }) => {
        const existingTag = this.props.user?.tags?.find(userTag => userTag._id === item.value);
        if (existingTag) {
          userTags.push(existingTag);
        } else {
          const tag = this.props.tags_list?.results?.find(fullTag => fullTag._id === item.value);
          if (tag) {
            userTags.push({ name: tag.name, _id: tag._id || '', color: tag.color, priority: tag.priority });
          }
        }
      });
    }
    this.setState(prevState => ({ user: { ...prevState.user, tags: userTags } }));
  };

  showPassword() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <TextFieldComponent
              label={
                this.props.editMode
                  ? msg('passwords.newPassword', 'New password')
                  : msg('passwords.password', 'Password')
              }
              id="newPassword"
              value={this.state.password}
              onChange={this.handleChange}
              inType="password"
              minLength={1}
              maxLength={50}
              required={true}
              ref="newPassword"
            />
          </div>
        </div>
      </div>
    );
  }

  parseTagsForSelect = (tags: TagDTO[]) => {
    return tags?.map(tag => ({ label: tag.name, value: tag._id, color: tag.color }));
  };

  renderTags = () => (
    <div className="row" style={{ marginBottom: 15 }}>
      <div className="col-sm-1" />
      <div className="col-sm-10">
        <InputLabel shrink={true} htmlFor="tagsList" style={{ marginBottom: -10 }}>
          {msg('tags.userTags', 'User Tags')}
        </InputLabel>
        <MultipleSelectComponent
          label={msg('tags.userTags', 'User Tags')}
          options={this.parseTagsForSelect(this.props.tags_list?.results || [])}
          id="tagsList"
          value={this.parseTagsForSelect(this.state.user.tags || [])}
          onChange={this.handleUserTagsChange}
          selectStyle={getTagStylesForSelect()}
        />
      </div>
    </div>
  );

  render() {
    const { user } = this.state;
    const { errors } = this.props.validator!;
    const newPasswordContent = this.state.passwordChangeCheck ? this.showPassword() : null;
    return (
      <div>
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('accountInfo.lastname', 'Last name')}
              id="lastname"
              value={user.lastname}
              onChange={this.handleChange}
              inType="text"
              minLength={1}
              maxLength={30}
              required={true}
              formatError={errors.lastname}
              ref="lastname"
              validator={[
                { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } }
                // {
                //   type: 'checkIfAlpha',
                //   msg: msg('formValidation.alphaCharacters', 'Please enter an alphabetical string!'),
                //   params: user.lastname
                // }
              ]}
            />
          </div>
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('accountInfo.firstname', 'First name')}
              id="firstname"
              value={user.firstname}
              onChange={this.handleChange}
              inType="text"
              minLength={1}
              maxLength={30}
              required={true}
              formatError={errors.firstname}
              ref="firstname"
              validator={[
                // {
                //   type: 'checkIfAlpha',
                //   msg: msg('formValidation.alphaCharacters', ' Please enter an alphabetical string!'),
                //   params: user.firstname
                // },
                { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } }
              ]}
            />
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('accountInfo.email', 'Email address')}
              id="email"
              value={user.email}
              onChange={this.handleChange}
              inType="text"
              minLength={1}
              required={true}
              formatError={errors.email}
              ref="email"
              validator={[
                {
                  type: 'isEmail',
                  msg: msg('formValidation.emailFormat', 'Invalid email format!')
                }
              ]}
            />
          </div>
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('accountInfo.phone', 'Phone')}
              id="phone"
              value={user.phone || ''}
              onChange={this.handleChange}
              inType="text"
              minLength={10}
              maxLength={10}
              required={false}
              formatError={errors.phone}
              ref="phone"
              validator={[]}
            />
          </div>
          <div className="col-sm-1" />
        </div>
        {this.props.editMode && this.renderTags()}
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-md-3" style={{ marginBottom: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={this.state.passwordChangeCheck}
                  onChange={this.handlePasswordChangeCheck}
                  value="showPasswords"
                />
              }
              label={
                this.props.editMode
                  ? msg('passwords.changePassword', 'Change password')
                  : msg('passwords.addPassword', 'Add password')
              }
            />
          </div>
          <div className="col-md-8" />
        </div>
        {newPasswordContent}
        {!this.props.output && (
          <div>
            <div className="row" style={{ margin: 10 }}>
              <div className="col-md-3" />
              <div className="col-md-6" style={{ textAlign: 'center' }}>
                <div className="form-group form-button">
                  <ButtonComponent
                    label={msg('general.save', 'Save')}
                    icon="save"
                    action={this.saveHandler}
                    pending={this.props.pending}
                  />
                </div>
              </div>
              <div className="col-md-3" />
            </div>
            <div className="row">
              <div className="col-md-6 category form-category">
                <small>*</small>
                {msg('errors.fieldsRequired', 'Required fields')}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Validator(UserFormComponent);
