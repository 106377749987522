import { Dispatch } from 'redux';

import { TriggersStore } from '.';
import { Server, composedCriteriaBuilder, AlertUtil, msg, logger } from '../../utils';
import { PaginationDTO, TriggerDTO } from '../types';

export interface ITriggersActions {
  getTriggersAction(
    expired: boolean,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  clearTriggersError(): any;
}

class TriggersActions implements ITriggersActions {
  getTriggersAction(
    expired: boolean,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: TriggersStore.ActionTypes.GET_TRIGGERS });
      try {
        if (!limit) {
          limit = 30;
        }
        let url = `admin/triggers?expired=${expired}&pagination=true&limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg(`Get triggers action, route:/admin/triggers`, 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: TriggersStore.ActionTypes.GET_TRIGGERS_SUCCESS,
          payload: response.data as PaginationDTO<TriggerDTO>
        });
      } catch (error) {
        logger.err('Get triggers action, route:/admin/triggers', 'GET');
        AlertUtil.updateContent(
          msg('reduxMessages.triggers.getTriggersError', 'Due to an error, the triggers list could not be loaded!'),
          'error'
        );
        dispatch({
          type: TriggersStore.ActionTypes.GET_TRIGGERS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  clearTriggersError() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: TriggersStore.ActionTypes.CLEAR_TRIGGERS_ERROR });
    };
  }
}

const triggersActions = new TriggersActions();
export default triggersActions;
