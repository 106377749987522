import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { FeedbackListContainer } from './';
import { partnerRoutes } from '../../layouts';

const FeedbackRouter = () => {
  return (
    <Switch>
      <Route exact={true} path={partnerRoutes.FEEDBACK.default} render={() => <FeedbackListContainer />} />
    </Switch>
  );
};

export default FeedbackRouter;
