import receiptsActions, { IReceiptsActions } from './actions';
import receiptsReducer from './reducer';
import { PaginationDTO, ReceiptDTO } from '../types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReceiptsStore {
  export type IState = {
    receipt: ReceiptDTO | null;
    receipt_pending: boolean;
    receipt_error: string | null;
    receipt_list: PaginationDTO<ReceiptDTO>;
    receipt_list_pending: boolean;
    receipt_list_error: string | null;
    edit_receipt_pending: boolean;
    edit_receipt_error: string | null;
    delete_receipt: boolean;
    delete_receipt_pending: boolean;
    delete_receipt_error: string | null;
    delete_succcess: boolean;
    allow_receipts: boolean | null;
    allow_receipts_pending: boolean;
    allow_receipts_error: string | null;
    export_receipts_pending: boolean;
    export_receipts_error: any | null;
  };

  export const initialState: IState = {
    receipt: null,
    receipt_pending: false,
    receipt_error: null,
    receipt_list: new PaginationDTO(),
    receipt_list_pending: false,
    receipt_list_error: null,
    edit_receipt_pending: false,
    edit_receipt_error: null,
    delete_receipt: false,
    delete_receipt_pending: false,
    delete_receipt_error: null,
    delete_succcess: false,
    allow_receipts: null,
    allow_receipts_pending: false,
    allow_receipts_error: null,
    export_receipts_pending: false,
    export_receipts_error: null
  };

  export enum ActionTypes {
    GET_RECEIPT = 'GET_RECEIPT',
    GET_RECEIPT_SUCCESS = 'GET_RECEIPT_SUCCESS',
    GET_RECEIPT_FAILED = 'GET_RECEIPT_FAILED',
    GET_RECEIPT_LIST = 'GET_RECEIPT_LIST',
    GET_RECEIPT_LIST_SUCCESS = 'GET_RECEIPT_LIST_SUCCESS',
    GET_RECEIPT_LIST_FAILED = 'GET_RECEIPT_LIST_FAILED',
    EDIT_RECEIPT = 'EDIT_RECEIPT',
    EDIT_RECEIPT_SUCCESS = 'EDIT_RECEIPT_SUCCESS',
    EDIT_RECEIPT_FAILED = 'EDIT_RECEIPT_FAILED',
    EDIT_RECEIPT_FAILED_STATUS_CHANGED = 'EDIT_RECEIPT_FAILED_STATUS_CHANGED',
    DELETE_RECEIPT = 'DELETE_RECEIPT',
    DELETE_RECEIPT_SUCCESS = 'DELETE_RECEIPT_SUCCESS',
    DELETE_RECEIPT_FAILED = 'DELETE_RECEIPT_FAILED',
    CLEAR_RECEIPTS_ERROR = 'CLEAR_RECEIPTS_ERROR',
    ALLOW_RECEIPTS = 'ALLOW_RECEIPTS',
    ALLOW_RECEIPTS_SUCCESS = 'ALLOW_RECEIPTS',
    ALLOW_RECEIPTS_FAILED = 'ALLOW_RECEIPTS_FAILED',
    EXPORT_RECEIPTS = 'EXPORT_RECEIPTS',
    EXPORT_RECEIPTS_SUCCESS = 'EXPORT_RECEIPTS_SUCCESS',
    EXPORT_RECEIPTS_FAILED = 'EXPORT_RECEIPTS_FAILED'
  }

  export const actions = receiptsActions;
  export const reducer = receiptsReducer;
  export interface IActions extends IReceiptsActions {}
}

export * from './actions';
