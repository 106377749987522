import * as React from 'react';
import { Bounce } from 'react-activity';
import { Dispatch } from 'redux';
import { CardComponent, SimpleSelectComponent } from '../../../shared';
import { EditableListComponent } from './lists';
import { spinnerOverlayStyle } from './lists/EditableListStyles';
import { AlertUtil, msg } from '../../../../utils';
import {
  IDispatch,
  IndustryDTO,
  PaginationDTO,
  RewardDTO,
  RewardType,
  TagDTO,
  RewardStocksDTO
} from '../../../../appRedux/types';
import { colors } from '../../../../assets/styles';
import RewardSettingsComponent from './RewardSettingsComponent';

interface IProps {
  config: {
    rewards_list: RewardDTO[];
    rewards_list_pending?: boolean;
    rewards_list_error?: string | null;
    reward_save_pending?: boolean;
    reward_delete_pending?: boolean;
    industries?: IndustryDTO[];
    tags_list?: PaginationDTO<TagDTO>;
    getRewardsListAction?(id: string): IDispatch;
    updateRewardsAction?(rewards: RewardDTO[], industryId: string): IDispatch;
    deleteRewardAction?(rewardId: string, id: string, partnerId?: string): IDispatch;
    getIndustriesListAction?(): IDispatch;
    inheritRewardsAction?(placeId: string): IDispatch;
    getTagsAction?(forSAdmin: boolean, placeId: string, includeUserTags?: boolean): IDispatch;
    clearTagsActions?(): (dispatch: Dispatch) => void;
  };
  forSAdmin: boolean;
  forPlace: boolean;
  match?: any;
  partnerId?: string;
  output?: boolean;
  handleChange?: (event: any) => any;
  mediaURL?: string;
  showLabelInfo?: boolean;
  cardTitle?: boolean;
  cardStyle?: any;
}
interface IState {
  rewards: Array<RewardDTO>;
  industry: string;
  currentReward: RewardDTO | null;
  currentRewardIndex: number | null;
  showDetails: boolean;
  dirty: boolean;
}

export default class RewardsListComponent extends React.Component<IProps, IState> {
  childRef: any;
  user_tags: TagDTO[] = [];
  member_tags: TagDTO[] = [];
  constructor(props: IProps) {
    super(props);
    this.state = {
      rewards: props.config.rewards_list.length
        ? this.parseInitialRewardsList(this.props.config.rewards_list)
        : !props.forPlace && !props.forSAdmin
        ? [new RewardDTO(), new RewardDTO(), new RewardDTO()]
        : [],
      industry: '',
      currentReward: null,
      currentRewardIndex: null,
      showDetails: false,
      dirty: false
    };
    this.childRef = React.createRef();
  }

  componentDidMount() {
    this.getRewards();
    if (this.props.forPlace && this.props.config.getTagsAction) {
      this.props.config.getTagsAction(false, this.props.match.params.placeId, true);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.config.rewards_list_pending &&
      !this.props.config.rewards_list_pending &&
      this.props.config.rewards_list
    ) {
      this.setState({ rewards: this.parseInitialRewardsList(this.props.config.rewards_list), dirty: false });
    }
    if (!this.member_tags.length && !this.user_tags.length && this.props.config.tags_list?.results?.length) {
      this.props.config.tags_list?.results?.forEach(tag => {
        if (tag.placeId) {
          this.member_tags.push(tag);
        } else {
          this.user_tags.push(tag);
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.props.config.clearTagsActions) {
      this.props.config.clearTagsActions();
    }
  }

  getRewards = () => {
    if (this.props.forSAdmin && this.props.config.getIndustriesListAction) {
      this.props.config.getIndustriesListAction();
    } else if (
      !this.props.forSAdmin &&
      this.props.forPlace &&
      this.props.config.getRewardsListAction &&
      this.props.match.params.placeId
    ) {
      this.props.config.getRewardsListAction(this.props.match.params.placeId);
    }
  };

  parseInitialRewardsList = (rewards: RewardDTO[]) => {
    return rewards?.map(reward => ({ ...reward, isFree: !reward.points })) || [];
  };

  parseSaveRewardsList = (rewards: RewardDTO[]) => {
    return (
      rewards?.map(reward => ({
        ...reward,
        points: reward.isFree ? 0 : reward.points,
        stocks: reward.stocks
          ? {
              ...reward.stocks,
              dayStock: reward.stocks.dayUnlimited ? 0 : reward.stocks.dayStock,
              weekStock: reward.stocks.weekUnlimited ? 0 : reward.stocks.weekStock,
              monthStock: reward.stocks.monthUnlimited ? 0 : reward.stocks.monthStock
            }
          : new RewardStocksDTO()
      })) || []
    );
  };

  addRewardAction = () => {
    const rewards = this.state.rewards;
    let newReward = new RewardDTO();
    if (!this.props.forSAdmin) {
      newReward = {
        ...newReward,
        partnerId: this.props.partnerId || '',
        placeId: this.props.match?.params.placeId ? this.props.match.params.placeId : ''
      };
    }
    rewards.push(newReward);
    this.setState({ rewards });
    this.childRef?.current?.isDirty(`title${rewards.length - 1}`);
    this.childRef?.current?.isDirty(`coins${rewards.length - 1}`);
    this.childRef?.current?.isDirty(`type${rewards.length - 1}`);
    this.childRef?.current?.isDirty(`amount${rewards.length - 1}`);
  };

  saveAction = () => {
    const rewards = this.parseSaveRewardsList(
      this.state.rewards.filter((reward: RewardDTO) => reward._id || reward.dirty)
    );
    if (this.props.config.updateRewardsAction) {
      if (this.props.forSAdmin && !this.props.forPlace) {
        this.props.config.updateRewardsAction(rewards, this.state.industry);
      } else if (!this.props.forSAdmin && this.props.forPlace && this.props.match.params.placeId) {
        this.props.config.updateRewardsAction(rewards, this.props.match.params.placeId);
      }
    }
  };

  deleteAction = (index: number) => async (event: React.MouseEvent) => {
    const id = event.currentTarget.getAttribute('id');
    if (id) {
      const hasConfirmation = await AlertUtil.confirm(
        msg('alertMessages.rewardDeleteConfirmation', 'You are about to delete a reward!')
      );
      if (hasConfirmation && this.props.config.deleteRewardAction) {
        if (this.props.forSAdmin && !this.props.forPlace) {
          this.props.config.deleteRewardAction(id, this.state.industry);
        } else if (
          !this.props.forSAdmin &&
          this.props.forPlace &&
          this.props.match.params.placeId &&
          this.props.partnerId
        ) {
          this.props.config.deleteRewardAction(id, this.props.match.params.placeId, this.props.partnerId);
        }
        let rewards = [...this.state.rewards];
        rewards.splice(index, 1);
        this.setState({ rewards, dirty: true });
        if (this.props.handleChange) {
          this.props.handleChange(rewards);
        }
      }
    } else {
      let rewards = [...this.state.rewards];
      rewards.splice(index, 1);
      this.setState({ rewards, dirty: true });
      if (this.props.handleChange) {
        this.props.handleChange(rewards);
      }
    }
  };

  inheritRewardsAction = async () => {
    const hasConfirmation = await AlertUtil.inherit(
      msg(
        'reward.inheritTooltip',
        // tslint:disable-next-line:max-line-length
        'If you agree, the list will inherit the rewards defined at industry level and will become active immediately!'
      )
    );
    if (
      hasConfirmation &&
      !this.props.forSAdmin &&
      this.props.config.inheritRewardsAction &&
      this.props.match.params.placeId
    ) {
      this.props.config.inheritRewardsAction(this.props.match.params.placeId);
      AlertUtil.simple(msg('reduxMessages.rewards.rewardsInherited', 'Rewards are being inherited...'), 'info');
    }
  };

  handleIndustryInputChange = (event: any) => {
    if (this.props.config.getRewardsListAction) {
      this.props.config.getRewardsListAction(event.target.value);
      this.setState({ industry: event.target.value });
    }
  };

  handleChange = (value: any, index: number, dataType: string) => {
    const rewardsNewState = this.state.rewards.map((item, i) => {
      if (i === index) {
        switch (dataType) {
          case 'isActive':
            return { ...item, isActive: !item.isActive, dirty: true };
          case 'type':
            if (value !== RewardType.VOUCHER && item.type === RewardType.VOUCHER) {
              item.title = '';
              delete item.amount;
            } else if (value === RewardType.VOUCHER) {
              item.title = 'Voucher';
            }
            return { ...item, [dataType]: value, dirty: true };
          case 'amount':
            item.title = `Voucher ${value || '0'} lei`;
            return { ...item, [dataType]: value, dirty: true };
          case 'isFree':
            item.isFree = !item.isFree;
            return { ...item, dirty: true };
          default:
            return { ...item, [dataType]: value, dirty: true };
        }
      }
      return item;
    });
    this.setState({ rewards: rewardsNewState, dirty: true });
    if (this.props.handleChange) {
      this.props.handleChange(rewardsNewState);
    }
  };

  openRewardSettingsModal = (index: number, listItem: RewardDTO) => () => {
    this.setState({ currentRewardIndex: index, currentReward: listItem });
  };

  closeRewardSettingsModal = () => {
    this.setState({ currentReward: null });
  };

  saveRewardSettings = (index: number, reward: RewardDTO) => {
    const rewardsNewState = this.state.rewards.map((item, i) => (i === index ? reward : item));
    this.setState({ currentRewardIndex: null, currentReward: null, rewards: rewardsNewState, dirty: true });
  };

  getActions = () =>
    this.props.forPlace
      ? [
          {
            label: msg('reward.advancedSettings', 'Advanced settings'),
            btn: 'btn-success',
            icon: 'settings',
            isDisabled: (item: RewardDTO) => !!(item.title || item.points || item.type),
            onClick: this.openRewardSettingsModal
          },
          {
            label: msg('general.delete', 'Delete'),
            btn: 'btn-danger',
            icon: 'delete',
            ref: 'deleteReward',
            onClick: this.deleteAction
          }
        ]
      : [
          {
            label: msg('general.delete', 'Delete'),
            btn: 'btn-danger',
            icon: 'delete',
            ref: 'deleteReward',
            onClick: this.deleteAction
          }
        ];

  render() {
    const rewardsListData = {
      fields: {
        title: msg('reward.title', 'Title'),
        points: msg('reward.coins', 'Coins Cost'),
        isFree: msg('reward.free', 'Free'),
        type: msg('reward.type', 'Type'),
        amount: msg('reward.value', 'Value'),
        isActive: msg('reward.isActive', 'Active')
      },
      actions: this.getActions(),
      list: this.state.rewards,
      pending: !!this.props.config.rewards_list_pending,
      error: this.props.config.rewards_list_error,
      handleChange: this.handleChange
    };
    if (this.props.output) {
      // @ts-ignore
      delete rewardsListData.fields.isActive;
    }
    return (
      <CardComponent
        title={msg('reward.cardTitle', 'Set rewards')}
        headerIcon="card_giftcard"
        needsTitle={this.props.cardTitle ?? true}
        style={this.props.cardStyle}
        checkboxes={
          this.props.forPlace
            ? [
                {
                  label: msg('reward.showDetails', 'Show details'),
                  checked: this.state.showDetails,
                  onChange: () => {
                    this.setState({ showDetails: !this.state.showDetails });
                  }
                }
              ]
            : undefined
        }
      >
        {this.props.forPlace && this.state.currentReward && this.state.currentRewardIndex?.toString() && (
          <RewardSettingsComponent
            index={this.state.currentRewardIndex}
            reward={this.state.currentReward}
            user_tags={this.user_tags}
            member_tags={this.member_tags}
            saveReward={this.saveRewardSettings}
            toggleModal={this.closeRewardSettingsModal}
          />
        )}
        {this.props.forSAdmin ? (
          <div>
            <div className="row" style={{ marginBottom: 6 }}>
              <div className="col-sm-6">
                <h4>{msg('industry.selectIndustry', 'Select industry')}:</h4>
              </div>
            </div>
            <div className="row" style={{ marginBottom: 6 }}>
              <div className="col-sm-6">
                <SimpleSelectComponent
                  label={msg('industry.name', 'Industry name')}
                  name="industry.select"
                  options={this.props.config.industries || []}
                  value={this.state.industry}
                  onChange={this.handleIndustryInputChange}
                  id="id"
                  needsAllLabel={false}
                  arrayOptions={false}
                  required={false}
                />
              </div>
            </div>
          </div>
        ) : null}
        {this.props.forSAdmin && this.props.config.rewards_list_pending ? (
          <div style={{ ...spinnerOverlayStyle }}>
            <h4 style={{ color: colors.white }}>{msg('general.loading', 'Loading...')}</h4>
            <Bounce color={colors.white} />
          </div>
        ) : null}
        <EditableListComponent
          forSAdmin={this.props.forSAdmin}
          rewards={this.state.rewards}
          config={this.props.config}
          industry={this.state.industry}
          addRewardAction={this.addRewardAction}
          saveAction={this.saveAction}
          inheritRewardsAction={this.inheritRewardsAction}
          data={rewardsListData}
          onCellChange={this.handleChange}
          output={this.props.output}
          mediaURL={this.props.mediaURL}
          ref={this.childRef}
          showLabelInfo={this.props.showLabelInfo}
          showDetails={this.state.showDetails}
          dirty={this.state.dirty}
        />
      </CardComponent>
    );
  }
}
