import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AuthStore, TranslationsStore, AppStore, reduxContainer } from '../../../appRedux';
import { NavAuthComponent, FooterAuthComponent, AccountActivationComponent } from './components';

import backgroundImage from '../../../assets/images/background.jpg';

interface IProps extends AuthStore.IState, TranslationsStore.IState {
  auth?: any;
}
interface IState {}

class ActivationContainer extends React.Component<
  IProps & RouteComponentProps & AuthStore.IActions & TranslationsStore.IActions,
  IState
> {
  activateAccountHandler = (token: string) => {
    this.props.activationAction(token);
  };

  render() {
    const navAuthConfig = {
      title: 'ReGage Partner',
      initial_language: this.props.initial_language,
      changeLanguage: this.props.changeLanguageAction
    };

    const activationState = {
      error: this.props.auth.activation_error
    };

    return (
      <div className="partner-auth off-canvas-sidebar">
        <NavAuthComponent config={navAuthConfig} />
        <div className="wrapper wrapper-full-page">
          <div className="full-page login-page" filter-color="black">
            <div className="content" style={{ paddingTop: '28vh' }}>
              <AccountActivationComponent
                match={this.props.match}
                activateAction={this.activateAccountHandler}
                state={activationState}
              />
            </div>
            <FooterAuthComponent />
            <div className="full-page-background" style={{ backgroundImage: `url(${backgroundImage})` }} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    auth: state.auth,
    initial_language: state.translations.initial_language
  };
}

const dispatchToProps = {
  activationAction: AuthStore.actions.activationAction,
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction
};

export default reduxContainer(ActivationContainer, mapStateToProps, dispatchToProps);
