import onboardActions, { IOnboardActions } from './actions';
import onboardReducer from './reducer';
import { PaginationDTO } from '../types';

/*
  OnboardStore definition as a namespace that contains actions, reducers, actionTypes and onboard state
*/

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OnboardStore {
  export type IState = {
    send_onboarding_pending: boolean;
    send_onboarding_error: string | null;
    onboarding: any;
    get_all_onboardings_pending: boolean;
    get_all_onboardings_error: string | null;
    onboardings: PaginationDTO<any>;
    get_onboarding_pending: boolean;
    get_onboarding_error: string | null;
    update_onboarding_pending: boolean;
    update_onboarding_error: string | null;
    delete_onboarding_pending: boolean;
    delete_onboarding_error: string | null;
    create_onboarding_partner_pending: boolean;
    create_onboarding_partner_error: string | null;
    create_onboarding_place_pending: boolean;
    create_onboarding_place_error: string | null;
    create_onboarding_user_pending: boolean;
    create_onboarding_user_error: string | null;
    activate_onboarding_place_pending: boolean;
    activate_onboarding_place_error: string | null;
    send_email_to_partner_pending: boolean;
    send_email_to_partner_error: string | null;
    alternative_address_flag: boolean | null;
    onboarding_flags_pending: boolean;
    onboarding_flags_error: string | null;
  };

  export const initialState: IState = {
    send_onboarding_pending: false,
    send_onboarding_error: null,
    onboarding: null,
    get_all_onboardings_pending: false,
    get_all_onboardings_error: null,
    onboardings: new PaginationDTO(),
    get_onboarding_pending: false,
    get_onboarding_error: null,
    update_onboarding_pending: false,
    update_onboarding_error: null,
    delete_onboarding_pending: false,
    delete_onboarding_error: null,
    create_onboarding_partner_pending: false,
    create_onboarding_partner_error: null,
    create_onboarding_place_pending: false,
    create_onboarding_place_error: null,
    create_onboarding_user_pending: false,
    create_onboarding_user_error: null,
    activate_onboarding_place_pending: false,
    activate_onboarding_place_error: null,
    send_email_to_partner_pending: false,
    send_email_to_partner_error: null,
    alternative_address_flag: null,
    onboarding_flags_pending: false,
    onboarding_flags_error: null
  };

  export enum ActionTypes {
    SEND_ONBOARDING = 'SEND_ONBOARDING',
    SEND_ONBOARDING_SUCCESS = 'SEND_ONBOARDING_SUCCESS',
    SEND_ONBOARDING_FAILED = 'SEND_ONBOARDING_FAILED',
    GET_ALL_ONBOARDINGS = 'GET_ALL_ONBOARDINGS',
    GET_ALL_ONBOARDINGS_SUCCESS = 'GET_ALL_ONBOARDINGS_SUCCESS',
    GET_ALL_ONBOARDINGS_FAILED = 'GET_ALL_ONBOARDINGS_FAILED',
    GET_ONBOARDING = 'GET_ONBOARDING',
    GET_ONBOARDING_SUCCESS = 'GET_ONBOARDING_SUCCESS',
    GET_ONBOARDING_FAILED = 'GET_ONBOARDING_FAILED',
    UPDATE_ONBOARDING = 'UPDATE_ONBOARDING',
    UPDATE_ONBOARDING_SUCCESS = 'UPDATE_ONBOARDING_SUCCESS',
    UPDATE_ONBOARDING_FAILED = 'UPDATE_ONBOARDING_FAILED',
    DELETE_ONBOARDING = 'DELETE_ONBOARDING',
    DELETE_ONBOARDING_SUCCESS = 'DELETE_ONBOARDING_SUCCESS',
    DELETE_ONBOARDING_FAILED = 'DELETE_ONBOARDING_FAILED',
    CREATE_PARTNER_FROM_ONBOARDING = 'CREATE_PARTNER_FROM_ONBOARDING',
    CREATE_PARTNER_FROM_ONBOARDING_SUCCESS = 'CREATE_PARTNER_FROM_ONBOARDING_SUCCESS',
    CREATE_PARTNER_FROM_ONBOARDING_FAILED = 'CREATE_PARTNER_FROM_ONBOARDING_FAILED',
    CREATE_PLACE_FROM_ONBOARDING = 'CREATE_PLACE_FROM_ONBOARDING',
    CREATE_PLACE_FROM_ONBOARDING_SUCCESS = 'CREATE_PLACE_FROM_ONBOARDING_SUCCESS',
    CREATE_PLACE_FROM_ONBOARDING_FAILED = 'CREATE_PLACE_FROM_ONBOARDING_FAILED',
    CREATE_USER_FROM_ONBOARDING = 'CREATE_USER_FROM_ONBOARDING',
    CREATE_USER_FROM_ONBOARDING_SUCCESS = 'CREATE_USER_FROM_ONBOARDING_SUCCESS',
    CREATE_USER_FROM_ONBOARDING_FAILED = 'CREATE_USER_FROM_ONBOARDING_FAILED',
    ACTIVATE_PLACE_FROM_ONBOARDING = 'ACTIVATE_PLACE_FROM_ONBOARDING',
    ACTIVATE_PLACE_FROM_ONBOARDING_SUCCESS = 'ACTIVATE_PLACE_FROM_ONBOARDING_SUCCESS',
    ACTIVATE_PLACE_FROM_ONBOARDING_FAILED = 'ACTIVATE_PLACE_FROM_ONBOARDING_FAILED',
    SEND_EMAIL_TO_PARTNER = 'SEND_EMAIL_TO_PARTNER',
    SEND_EMAIL_TO_PARTNER_SUCCESS = 'SEND_EMAIL_TO_PARTNER_SUCCESS',
    SEND_EMAIL_TO_PARTNER_FAILED = 'SEND_EMAIL_TO_PARTNER_FAILED',
    GET_ONBOARDING_FLAGS = 'GET_ONBOARDING_FLAGS',
    GET_ONBOARDING_FLAGS_SUCCESS = 'GET_ONBOARDING_FLAGS_SUCCESS',
    GET_ONBOARDING_FLAGS_FAILED = 'GET_ONBOARDING_FLAGS_FAILED'
  }

  export const actions = onboardActions;
  export const reducer = onboardReducer;
  export interface IActions extends IOnboardActions {}
}

export * from './actions';
