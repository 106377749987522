import React, { CSSProperties } from 'react';

import { GoogleMap, Marker } from '@react-google-maps/api';

interface IProps {
  containerStyle: CSSProperties;
  lat: number;
  lng: number;
  defaultCenter: {
    lat: number;
    lng: number;
  };
  onClick: (event: google.maps.MapMouseEvent) => void;
  onDragEnd: (event: google.maps.MapMouseEvent) => void;
}

export const MapWithAMarker = (props: IProps) => (
  <GoogleMap
    mapContainerStyle={props.containerStyle}
    zoom={15}
    onClick={props.onClick}
    center={{ lat: props.lat, lng: props.lng } || props.defaultCenter}
  >
    <Marker position={{ lat: props.lat, lng: props.lng }} draggable={true} onDragEnd={props.onDragEnd} />
  </GoogleMap>
);

export default MapWithAMarker;
