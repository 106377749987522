import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { msg } from '../../../../../utils';
import { ButtonComponent, TextFieldComponent } from '../../../../shared';

const API_LIST = [
  { name: 'API-STAGE', value: 'https://api-stage.regage.io' },
  { name: 'API-DEV', value: 'https://api-dev.regage.io' },
  { name: 'API-PROD', value: 'https://api.regage.io' }
];

interface IProps {
  origin: string;
  closeModal: () => void;
  setOriginURLAction: (origin: string) => void;
}

export const OriginModalComponent = (props: IProps) => {
  const [currentOrigin, setCurrentOrigin] = React.useState({ name: '', value: '' });

  React.useEffect(() => {
    const listItem = API_LIST.find(item => item.value === props.origin);
    if (listItem) {
      setCurrentOrigin(listItem);
    } else {
      setCurrentOrigin({ name: 'custom', value: props.origin });
    }
    // tslint:disable-next-line: align
  }, [props.origin]);

  const handleChangeOrigin = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOrigin = event.target.value;
    const listItem = API_LIST.find(item => item.name === newOrigin);
    if (listItem) {
      setCurrentOrigin(listItem);
    } else {
      setCurrentOrigin({ name: 'custom', value: '' });
    }
  };

  const handleCustomOrigin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentOrigin({ name: 'custom', value: event.target.value });
  };

  const confirmOrigin = () => {
    props.setOriginURLAction(currentOrigin.value);
  };

  return (
    <Modal show={true} onHide={props.closeModal} dialogClassName="memberDetailsModal">
      <Modal.Header>
        <Modal.Title style={{ display: 'flex', justifyContent: 'space-between' }}>
          {msg('footer.setOrigin', 'Set new origin')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RadioGroup name="api" onChange={handleChangeOrigin} value={currentOrigin.name} row={false}>
          {API_LIST.map((endpoint: { name: string; value: string }, index: number) => {
            return (
              <FormControlLabel
                key={index}
                value={endpoint.name}
                control={<Radio color="primary" />}
                label={endpoint.name}
                style={{ width: 'auto', color: 'black' }}
              />
            );
          })}
          <FormControlLabel
            value="custom"
            control={<Radio color="primary" />}
            label={
              <TextFieldComponent
                onChange={handleCustomOrigin}
                value={currentOrigin.name === 'custom' ? currentOrigin.value : ''}
                style={{ width: 250, maxWidth: '100%' }}
              />
            }
            style={{ color: 'black' }}
          />
        </RadioGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-danger" onClick={props.closeModal}>
          {msg('general.close', 'Close')}
        </Button>
        <ButtonComponent styles={{ marginTop: 0 }} label={msg('general.save', 'Save')} action={confirmOrigin} />
      </Modal.Footer>
    </Modal>
  );
};
