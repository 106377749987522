import { IActivityMember, IActivity, IActivityPlace } from '../../../appRedux/activity/api';
import { changeDisplayMap, eventTypesFriendlyNames } from './activity.display-map';
import { getFormatDate, msg } from '../../../utils';

export const formatActivityDate = (activity: IActivity): string => getFormatDate(activity.date);

export const formatActivityMember = (member?: IActivityMember): string => {
  if (!member) {
    return '';
  }
  if (!member.firstname && !member.lastname) {
    return 'USER DELETED';
  }
  return `${member.firstname} ${member.lastname}`;
};

export const formatActivityChange = (activity: IActivity): string => {
  if (!changeDisplayMap.hasOwnProperty(activity.eventType)) {
    return '';
  }
  return changeDisplayMap[activity.eventType](activity);
};

export const formatActivityAction = (activity: IActivity): string => {
  if (activity.eventType === 'CHECKIN_BONUS_REDEEMED' && activity.rewardType === 2) {
    return msg('activity.events.voucherRedeemed', 'Voucher redeemed');
  }
  return eventTypesFriendlyNames().find(eventType => eventType._id === activity.eventType)?.name || '';
};

export const formatActivityPlace = (place: IActivityPlace): string => {
  return place.name;
};

export const getActivityFilters = (filterIds: string[]) =>
  eventTypesFriendlyNames().filter(eventTypeFilter => filterIds.some(filter => filter === eventTypeFilter._id));

export const formatActivityTrigger = (activity: IActivity) => {
  if (activity.eventId && activity.autopilotTitle) {
    return `${msg('activity.promotion', 'Promotion')}: ${activity.eventTitle || activity.autopilotTitle}`;
  } else if (activity.autopilotId && activity.autopilotTitle) {
    return `${msg('activity.simpleAutopilot', 'Automated campaign')}: ${msg(
      `simpleAutopilots.${activity.autopilotTitle}`,
      activity.autopilotTitle
    )}`;
  } else if (activity.tagId && activity.tagName) {
    return `${msg('activity.tag', 'Level action')}: ${activity.tagName}`;
  }
  return null;
};
