import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers, Reducer } from 'redux';

import { ActivityStore } from './activity';
import { AgendaJobsStore } from './agenda-jobs';
import { AnnouncementsStore } from './announcements';
import { AuthStore } from './authentication';
import { AutopilotStore } from './autopilot';
import { AutopilotLogsStore } from './autopilot-logs';
import { CheckinsStore } from './checkins';
import { EventsStore } from './events';
import { FeedbackStore } from './feedback';
import { IndustriesStore } from './industries';
import { MembersStore } from './members';
import { OnboardStore } from './onboard';
import { PackagesStore } from './packages';
import { PartnerEmailsStore } from './partner-emails';
import { PartnersStore } from './partners';
import { PayBatchesStore } from './pay-batches';
import { PaySettlementsStore } from './pay-settlements';
import { PlacesStore } from './places';
import { ReceiptsStore } from './receipts';
import { ReviewsStore } from './reviews';
import { RewardsStore } from './rewards';
import { StatisticsStore } from './statistics';
import { TagsStore } from './tags';
import { TransactionsStore } from './transactions';
import { TranslationsStore } from './translation';
import { TriggersStore } from './triggers';
import { UsersStore } from './users';

export const history = createBrowserHistory();
export const routerMid = routerMiddleware(history);

/*
  Combination of all Reducers
*/
export const rootReducer: Reducer<any> = combineReducers({
  activity: ActivityStore.reducer,
  agendaJobs: AgendaJobsStore.reducer,
  auth: AuthStore.reducer,
  autopilot: AutopilotStore.reducer,
  checkins: CheckinsStore.reducer,
  events: EventsStore.reducer,
  industries: IndustriesStore.reducer,
  members: MembersStore.reducer,
  announcements: AnnouncementsStore.reducer,
  onboard: OnboardStore.reducer,
  packages: PackagesStore.reducer,
  partners: PartnersStore.reducer,
  places: PlacesStore.reducer,
  receipts: ReceiptsStore.reducer,
  reviews: ReviewsStore.reducer,
  rewards: RewardsStore.reducer,
  statistics: StatisticsStore.reducer,
  translations: TranslationsStore.reducer,
  users: UsersStore.reducer,
  router: connectRouter(history),
  autopilotLogs: AutopilotLogsStore.reducer,
  partnerEmails: PartnerEmailsStore.reducer,
  triggers: TriggersStore.reducer,
  transactions: TransactionsStore.reducer,
  paySettlements: PaySettlementsStore.reducer,
  payBatches: PayBatchesStore.reducer,
  feedback: FeedbackStore.reducer,
  tags: TagsStore.reducer
});

export const defaultReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT' || action.type === 'SELECT_PARTNER') {
    state.activity = undefined;
    state.agendaJobs = undefined;
    state.rewards = undefined;
    state.statistics = undefined;
    state.autopilot = undefined;
    state.checkins = undefined;
    state.events = undefined;
    state.industries = undefined;
    state.members = undefined;
    state.packages = undefined;
    state.announcements = undefined;
    state.users = undefined;
    state.autopilotLogs = undefined;
    state.reviews = undefined;
    state.receipts = undefined;
    state.partnerEmails = undefined;
    state.triggers = undefined;
    state.transactions = undefined;
    state.paySettlements = undefined;
    state.payBatches = undefined;
    state.feedback = undefined;
    state.tags = undefined;
  }
  if (action.type === 'LOGOUT') {
    state.auth = undefined;
    state.partners = undefined;
    state.places = undefined;
  }

  return rootReducer(state, action);
};

/*
  Creation of AppStore object that contains all the individual Redux states
*/
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AppStore {
  export type states = {
    activity: ActivityStore.IState;
    agendaJobs: AgendaJobsStore.IState;
    auth: AuthStore.IState;
    autopilot: AutopilotStore.IState;
    checkins: CheckinsStore.IState;
    events: EventsStore.IState;
    industries: IndustriesStore.IState;
    members: MembersStore.IState;
    announcements: AnnouncementsStore.IState;
    onboard: OnboardStore.IState;
    packages: PackagesStore.IState;
    partners: PartnersStore.IState;
    places: PlacesStore.IState;
    reviews: ReviewsStore.IState;
    rewards: RewardsStore.IState;
    statistics: StatisticsStore.IState;
    translations: TranslationsStore.IState;
    users: UsersStore.IState;
    autopilotLogs: AutopilotLogsStore.IState;
    receipts: ReceiptsStore.IState;
    partnerEmails: PartnerEmailsStore.IState;
    triggers: TriggersStore.IState;
    transactions: TransactionsStore.IState;
    paySettlements: PaySettlementsStore.IState;
    payBatches: PayBatchesStore.IState;
    feedback: FeedbackStore.IState;
    tags: TagsStore.IState;
  };
}
