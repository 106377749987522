import moment from 'moment';

import { msg } from '../../../../utils';
import { checkinsTabFixtures } from '../fixtures/PlaceDashboardChartsFixtures';
import { colors } from './colorsForCharts';

export const generateDataForChart = (dataChart: any) => {
  const keys = Object.keys(dataChart);
  const dataSets: any = [];
  for (let i = 0; i < keys.length; i++) {
    dataSets.push({
      label: keys[i],
      backgroundColor: colors[`color${(i + 1) % 26}`],
      borderColor: colors[`color${(i + 1) % 26}`],
      borderWidth: 1,
      hoverBackgroundColor: colors[`color${(i + 1) % 26}Opac04`],
      hoverBorderColor: colors[`color${(i + 1) % 26}`],
      data: dataChart[keys[i]]
    });
  }
  return dataSets;
};

export const generateDataForPieChart = (dataChart: any) => {
  const dataSets: any[] = [];
  const myColors: any[] = [];
  const backgroundColors: any[] = [];
  for (let i = 0; i < dataChart.length; i++) {
    myColors.push(colors[`color${(i + 1) % 26}`]);
    backgroundColors.push(colors[`color${i + (1 % 26)}Opac04`]);
  }
  dataSets.push({
    backgroundColor: myColors,
    borderColor: myColors,
    borderWidth: 1,
    hoverBackgroundColor: backgroundColors,
    hoverBorderColor: myColors,
    data: dataChart
  });
  return dataSets;
};

export const generateColors = (list: any) => {
  let badgeColors: any[] = [];
  for (let i = 0; i < list.length; i++) {
    badgeColors.push(colors[`color${(i + 1) % 26}`]);
  }
  return badgeColors;
};

// Visits
export function createMembersVSCheckinsChartData(list: any, type: string) {
  let checkinsByPeriod: any = [];
  let membersByPeriod: any = [];
  let period: any = [];
  if (list.length) {
    list.forEach((item: any) => {
      if (item && item.checkinsAndMembers) {
        checkinsByPeriod.push(item.checkinsAndMembers.checkinsByPeriod);
        membersByPeriod.push(item.checkinsAndMembers.membersByPeriod);
      } else {
        checkinsByPeriod.push(0);
        membersByPeriod.push(0);
      }
      if (type === 'DAY') {
        period.push(moment(item.startDate).format('DD.MM.YYYY'));
      } else {
        period.push(`${moment(item.startDate).format('DD.MM.YYYY')} - ${moment(item.endDate).format('DD.MM.YYYY')}`);
      }
    });
  }

  const dataChart2 = {
    labels: period,
    datasets: [
      {
        label: msg('dashboardHeader.checkins', 'Total Check-ins'),
        backgroundColor: colors.color6Opac02,
        borderColor: colors.color6,
        borderWidth: 1,
        hoverBackgroundColor: colors.color6Opac04,
        hoverBorderColor: colors.color6,
        data: checkinsByPeriod
      },
      {
        label: msg('dashboardHeader.members', 'Total Members'),
        backgroundColor: colors.color1Opac02,
        borderColor: colors.color1,
        borderWidth: 1,
        hoverBackgroundColor: colors.color1Opac04,
        hoverBorderColor: colors.color1,
        data: membersByPeriod
      }
    ]
  };
  return dataChart2;
}

export function createChartMembersAgeData(list: any) {
  const arrayLabels = [
    msg('charts.under25', 'Under 25'),
    '25-34',
    '35-44',
    '45-54',
    '55-64',
    msg('charts.over65', 'Over 65')
  ];
  const usedColors = [colors.color7, colors.color6, colors.color1, colors.color4, colors.color3, colors.color5];
  // let membersUnder25 = list !== null ? list.visitorsByAge.under25 : 0;
  // let membersBetween25And34 = list !== null ? list.visitorsByAge.between25_34 : 0;
  // let membersBetween35And44 = list !== null ? list.visitorsByAge.between35_44 : 0;
  // let membersBetween45And54 = list !== null ? list.visitorsByAge.between45_54 : 0;
  // let membersBetween55And64 = list !== null ? list.visitorsByAge.between55_64 : 0;
  // let membersOver64 = list !== null ? list.visitorsByAge.over64 : 0;
  const dataChart1 = {
    labels: arrayLabels,
    datasets: [
      {
        label: msg('charts.membersByAge', 'Members by age'),
        backgroundColor: usedColors,
        borderColor: usedColors,
        borderWidth: 1,
        hoverBackgroundColor: [
          colors.color7Opac04,
          colors.color6Opac04,
          colors.color1Opac04,
          colors.color4Opac04,
          colors.color3Opac04,
          colors.color5Opac04
        ],
        hoverBorderColor: usedColors,
        data: checkinsTabFixtures.membersByAgeData
      }
    ]
  };
  return dataChart1;
}

export function createChartMembersGender() {
  const dataChart3 = {
    labels: [msg('genders.male', 'Male'), msg('genders.female', 'Female')],
    datasets: [
      {
        backgroundColor: [colors.color4, colors.color7],
        borderColor: [colors.color4, colors.color7],
        borderWidth: 1,
        hoverBackgroundColor: [colors.color4Opac04, colors.color7Opac04],
        hoverBorderColor: [colors.color4, colors.color7],
        data: checkinsTabFixtures.membersByGenderData
      }
    ]
  };
  return dataChart3;
}

export function createChartChannelsDistribution() {
  const dataChart4 = {
    labels: [
      msg('notificationChannels.sms', 'SMS'),
      msg('notificationChannels.email', 'Email'),
      msg('notificationChannels.notification', 'In-app notification')
    ],
    datasets: [
      {
        backgroundColor: [colors.color2, colors.color1, colors.color4],
        borderColor: [colors.color2, colors.color1, colors.color4],
        borderWidth: 1,
        hoverBackgroundColor: [colors.color2Opac04, colors.color1Opac04, colors.color4Opac04],
        hoverBorderColor: [colors.color2, colors.color1, colors.color4],
        data: checkinsTabFixtures.channelsDistributionData
      }
    ]
  };
  return dataChart4;
}

// Events
export function createChartTotalEventsDistribution(list: any) {
  let labels: any = [];
  let opens: any = [];
  if (list.length) {
    list.forEach((item: any) => {
      if (item.eventsPerPeriod.length) {
        item.eventsPerPeriod.forEach((event: any) => {
          labels.push(event.title);
          opens.push(event.stats.opens);
        });
      }
    });
  }

  const dataSets = generateDataForPieChart(opens);

  const dataChart7 = {
    labels: labels,
    datasets: dataSets
  };
  return dataChart7;
}

export function createChartEvents(list: any, type: string) {
  let labels: any[] = [];
  let opens: any[] = [];
  let titles: any[] = [];
  if (list.length) {
    list.forEach((item: any) => {
      let opensSubList: any[] = [];
      let titlesSubList: any[] = [];
      if (item.eventsPerPeriod && item.eventsPerPeriod.length) {
        item.eventsPerPeriod.forEach((event: any) => {
          titlesSubList.push(event.title);
          opensSubList.push(event.stats.opens);
        });
      }
      opens.push(opensSubList);
      titles.push(titlesSubList);
      if (type === 'DAY') {
        labels.push(moment(item.startDate).format('DD.MM.YYYY'));
      } else {
        labels.push(`${moment(item.startDate).format('DD.MM.YYYY')} - ${moment(item.endDate).format('DD.MM.YYYY')}`);
      }
    });
  }

  const dataSets = generateDataForChart(opens);
  const dataChart8 = {
    labels: ['period1', 'period2', 'period3', 'period4', 'period5'],
    datasets: dataSets
  };
  return dataChart8;
}

// Autopilots
export function createChartTotalAutopilotsDistribution(list: any) {
  let labels: any = [];
  let claims: any = [];
  if (list.length) {
    list.forEach((item: any) => {
      if (item.autopilotListPerPeriod.length) {
        item.autopilotListPerPeriod.forEach((event: any) => {
          labels.push(event.title);
          claims.push(event.claims);
        });
      }
    });
  }
  const dataSets = generateDataForPieChart(claims);

  const dataChart9 = {
    labels: labels,
    datasets: dataSets
  };
  return dataChart9;
}

export function createChartAutopilots(list: any, type: string) {
  let labels: any[] = [];
  let claims: any[] = [];
  let period: any[] = [];
  if (list.length) {
    list.forEach((item: any) => {
      if (item.autopilotListPerPeriod && item.autopilotListPerPeriod.length) {
        item.autopilotListPerPeriod.forEach((event: any) => {
          labels.push(event.title);
          claims.push(event.claims);
        });
      }
      if (type === 'DAY') {
        period.push(moment(item.startDate).format('DD.MM.YYYY'));
      } else {
        period.push(`${moment(item.startDate).format('DD.MM.YYYY')} - ${moment(item.endDate).format('DD.MM.YYYY')}`);
      }
    });
  }

  const dataSets = generateDataForChart(claims);

  const dataChart10 = {
    labels: period,
    datasets: dataSets
  };
  return dataChart10;
}

// Rewards
export function createChartTotalRewardsDistribution(list: any) {
  let labels: any = [];
  let distributed: any = [];
  if (list.length) {
    list.forEach((item: any) => {
      if (item.rewards && item.rewards.basicRewards && item.rewards.basicRewards.length) {
        item.rewards.basicRewards.forEach((reward: any) => {
          labels.push(reward.reward);
          distributed.push(reward.count);
        });
      }
    });
  }
  const dataSets = generateDataForPieChart(distributed);

  const dataChart5 = {
    labels: labels,
    datasets: dataSets
  };
  return dataChart5;
}

export function createChartRewards(list: any, type: string) {
  let labels: any[] = [];
  let distributed: any[] = [];
  let period: any[] = [];
  if (list.length) {
    list.forEach((item: any) => {
      if (item.rewards && item.rewards.basicRewards && item.rewards.basicRewards.length) {
        item.rewards.basicRewards.forEach((reward: any) => {
          labels.push(reward.reward);
          distributed.push(reward.count);
        });
      }
      if (type === 'DAY') {
        period.push(moment(item.startDate).format('DD.MM.YYYY'));
      } else {
        period.push(`${moment(item.startDate).format('DD.MM.YYYY')} - ${moment(item.endDate).format('DD.MM.YYYY')}`);
      }
    });
  }

  const dataSets = generateDataForChart(distributed);

  const dataChart6 = {
    labels: period,
    datasets: dataSets
  };
  return dataChart6;
}
