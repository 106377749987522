import React, { ChangeEvent, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';

import { colors } from '../../assets/styles';
import { getDateInterval, msg } from '../../utils';
import { ButtonComponent, TextFieldComponent } from './';
import { DateRangePicker } from './DateRangePicker';

interface IProps {
  config: {
    title: string;
    message?: string;
    successMessage?: string;
    errorMessage?: string;
    buttonLabel?: string;
    buttonIcon?: string;
    filter?: {
      required: boolean;
      message: string;
      options: { label: string; value: string }[];
    };
    input?: {
      required: boolean;
      label: string;
      default?: string;
      isValid?: (input: string) => boolean;
    };
    pending: boolean;
    error: any | null;
    toggleModal: () => any;
    exportInExcel: (startDate: number, endDate: number, params?: { filter?: string; input?: string }) => any;
  };
}

const ExportModalComponent = (props: IProps) => {
  const [exportModal, setExportModal] = useState(getDateInterval(28));

  const [exportClicked, setExportClicked] = useState(false);
  const [dateRangeDefaultValue, setDateRangeDefaultValue] = useState([
    new Date(exportModal.startTime),
    new Date(exportModal.endTime)
  ]);
  const [filter, setFilter] = useState({ label: '', value: '', error: '' });
  const [input, setInput] = useState({ value: props.config.input?.default ?? '', error: '' });

  const getStartEndRange = (start: any, end: any) => {
    const startDate = new Date(start).setHours(0, 0, 0);
    const endDate = new Date(end).setHours(23, 59, 59);
    exportModal.startTime = startDate;
    exportModal.endTime = endDate;
    setExportModal(exportModal);
    setDateRangeDefaultValue([new Date(exportModal.startTime), new Date(exportModal.endTime)]);
    setExportClicked(false);
  };

  const exportInExcel = () => {
    setExportClicked(true);
    if (props.config.filter?.required && !filter?.value) {
      setFilter({
        ...filter,
        error: msg('paymentSettlements.placeRequired', 'You must choose a place for the settlement!')
      });
      return;
    }
    if (props.config.input?.required && !input?.value) {
      setInput({
        ...input,
        error: msg('transactions.emailRequired', 'The email is required!')
      });
      return;
    }
    if (props.config.input?.isValid && !props.config.input?.isValid(input?.value)) {
      setInput({
        ...input,
        error: msg('formValidation.emailFormat', 'Invalid email format!')
      });
      return;
    }
    props.config.exportInExcel(exportModal.startTime, exportModal.endTime, {
      filter: filter?.value,
      input: input?.value
    });
  };

  const handleFilterChange = (newFilter: { label: string; value: string }) => {
    setFilter({ ...newFilter, error: '' });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput({ value: event.target.value, error: '' });
  };

  const colourStyles = {
    control: (styles: any, state: any) => ({
      ...styles,
      boxShadow: 'inset 0 0 0 1px rgb(16 22 26 / 15%)',
      borderRadius: 3,
      border: 'none',
      fontSize: '14px',
      minHeight: 'initial',
      height: 30,
      '&:hover': {
        border: 'none'
      }
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.15)' : state.isSelected ? 'rgba(0, 0, 0, 0.20)' : 'white',
      color: state.isSelected && '#333',
      fontSize: '14px'
    }),
    indicatorSeparator: () => ({ display: 'hidden' }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '100%'
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '100%'
    })
  };

  return (
    <Modal show={true} onHide={props.config.toggleModal} dialogClassName="responsive-modal">
      <Modal.Header>
        <Modal.Title>{props.config.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex' }}>
          <div style={{ fontSize: 16, padding: 5, marginRight: 5 }}>
            {props.config.message || msg('exportExcel.exportDate', 'Choose dates for the report:')}
          </div>
          <DateRangePicker defaultValue={dateRangeDefaultValue} getRangeData={getStartEndRange} />
        </div>
        {props.config.filter && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
            <div style={{ fontSize: 16, padding: 5 }}>{props.config.filter.message}</div>
            <div style={{ minWidth: 175, margin: '2px 0px 4px 15px' }}>
              <Select
                className="basic-multi-select"
                classNamePrefix="select"
                options={props.config.filter.options || []}
                onChange={handleFilterChange}
                value={filter}
                styles={colourStyles}
              />
            </div>
          </div>
        )}
        {props.config.input && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ fontSize: 16, padding: 5, marginRight: 8 }}>{props.config.input.label}</div>
            <TextFieldComponent value={input.value ?? ''} onChange={handleInputChange} />
          </div>
        )}
        <div style={{ margin: 5, height: 25 }}>
          {exportClicked && (filter.error || input.error) ? (
            <span style={{ color: colors.red }}>{filter.error || input.error}</span>
          ) : (
            exportClicked &&
            !props.config.pending &&
            (props.config.error?.code === 'NOT_FOUND' ? (
              <span style={{ color: colors.red }}>
                {msg('exportExcel.noData', 'There is no data for the selected interval')}
              </span>
            ) : props.config.error ? (
              <span style={{ color: colors.red }}>
                {props.config.errorMessage ||
                  msg('exportExcel.exportError', 'Due to an error, the report could not be generated!')}
              </span>
            ) : (
              <span style={{ color: colors.green }}>
                {props.config.successMessage ||
                  msg('exportExcel.exportSuccess', 'The report has been successfully generated!')}
              </span>
            ))
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-danger" onClick={props.config.toggleModal}>
          {msg('general.close', 'Close')}
        </Button>
        <ButtonComponent
          styles={{ marginTop: 0 }}
          label={props.config.buttonLabel || msg('exportExcel.export', 'Export')}
          icon={props.config.buttonIcon || 'file_download'}
          pending={props.config.pending}
          action={exportInExcel}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ExportModalComponent;
