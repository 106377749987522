import { PlaceDTO, IndustryDTO } from './';

export class PartnerDTO {
  _id: string;
  name: string = '';
  shortname: string = '';
  vanityname: string = '';
  description: string = '';
  industry: IndustryDTO = new IndustryDTO();
  places: PlaceDTO[];
  created: string = '';
  status: number;
  phone: string = '';
  updatedAt?: string;
  isActive: boolean;
}

export class PartnerWithPlaceDTO {
  partner: PartnerDTO;
  place: PlaceDTO;
}
