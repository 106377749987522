import '../node_modules/@blueprintjs/core/lib/css/blueprint.css';
import '../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css';
import './assets/css/material-design/bootstrap/css/bootstrap.css';
import './assets/css/material-design/material-dashboard.css';
import '../node_modules/react-toggle/style.css';
import '../node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '../node_modules/react-toastify/dist/ReactToastify.css';
import '../node_modules/react-image-gallery/styles/css/image-gallery.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './assets/css/index.css';
import './assets/css/global.css';

import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import MomentUtils from '@date-io/moment';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import App from './App';
import { history, store } from './appRedux';

const theme = createTheme({
  palette: {
    primary: {
      main: '#64BF88'
    },
    secondary: {
      main: '#FFFFFF'
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
          <Router history={history}>
            <App />
          </Router>
          <ToastContainer />
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root') as HTMLElement
);

// registerServiceWorker();
