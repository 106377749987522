import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Checkbox, Chip, FormControlLabel, Tooltip } from '@material-ui/core';

import { AppStore, TagsStore } from '../../../appRedux';
import {
    IDispatch, PaginationDTO, TagActionDTO, TagDTO, TimeIntervalType
} from '../../../appRedux/types';
import { colors } from '../../../assets/styles';
import { AlertUtil, hexToRgba, msg } from '../../../utils';
import {
    ButtonComponent, CardComponent, ColorPickerComponent, SimpleSelectComponent, TextFieldComponent
} from '../../shared';

interface IProps {
  isSAdmin: boolean;
  tags_list: PaginationDTO<TagDTO>;
  tags_list_pending: boolean;
  tags_list_error: string | null;
  add_tag_pending: boolean;
  add_tag_error: string | null;
  edit_tag_pending: boolean;
  edit_tag_error: string | null;
  delete_tag_pending: boolean;
  delete_tag_error: string | null;
  addTagAction(forSAdmin: boolean, data: TagDTO, placeId?: string): Promise<void>;
  editTagAction(forSAdmin: boolean, tagId: string, data: TagDTO, placeId?: string): Promise<void>;
  deleteTagAction(forSAdmin: boolean, tagId: string, placeId?: string): Promise<void>;
  getTagsAction(forSAdmin: boolean, placeId?: string): Promise<void>;
  clearTagsActions(): IDispatch;
}

const TagsContainer = (props: IProps & RouteComponentProps<{ placeId: string }>) => {
  const [currentTag, setCurrentTag] = useState<TagDTO>(new TagDTO());
  const [error, setError] = useState<{ [key: string]: string }>({});
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [enableAction, setEnableAction] = useState<boolean>(false);

  useEffect(() => {
    props.getTagsAction(props.isSAdmin, props.match.params?.placeId || '');
    return () => {
      props.clearTagsActions();
    };
    // tslint:disable-next-line: align
  }, []);

  useEffect(() => {
    if (currentTag?.name && !props.add_tag_pending && !props.add_tag_error) {
      setCurrentTag(new TagDTO());
      setIsDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.add_tag_pending]);

  useEffect(() => {
    if (currentTag?.name && !props.edit_tag_pending && !props.edit_tag_error) {
      setIsDirty(false);
    }
    // tslint:disable-next-line: align
  }, [props.edit_tag_pending]);

  useEffect(() => {
    if (currentTag?.name && !props.delete_tag_pending && !props.delete_tag_error) {
      setCurrentTag(new TagDTO());
      setIsDirty(false);
    }
    // tslint:disable-next-line: align
  }, [props.delete_tag_pending]);

  const handleChipClick = (tag: TagDTO) => {
    setCurrentTag(tag);
    setEnableAction(!!tag.action?.target?.toString());
    setError({});
  };

  const handleEditTag = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.getAttribute('id') + '';
    if (key.indexOf('.') >= 0) {
      const fields = key.split('.');
      const action = currentTag.action || new TagActionDTO();
      action[fields[1]] = event.target?.value;
      setCurrentTag({ ...currentTag, action });
    } else {
      setCurrentTag({ ...currentTag, [key]: event.target?.value });
    }
    setIsDirty(true);
    setError({ name: '' });
  };

  const handleSaveTagColor = (color: string) => {
    setIsDirty(true);
    setCurrentTag({ ...currentTag, color });
  };

  const handleChangePeriodType = (event: ChangeEvent<HTMLInputElement>) => {
    const action = currentTag.action || new TagActionDTO();
    action.downgradeIntervalType = event.target.value as TimeIntervalType;
    setIsDirty(true);
    setCurrentTag({ ...currentTag, action });
    setError({ downgradeIntervalType: '' });
  };

  const checkIsValid = () => {
    if (!currentTag.name) {
      setError({ name: msg('formValidation.fieldRequired', 'Field required!') });
      return false;
    } else if (currentTag.action?.downgradeInterval && !currentTag.action.downgradeIntervalType) {
      setError({ downgradeIntervalType: msg('tags.downgradeIntervalRequired', 'Interval type is required') });
      return false;
    }
    return true;
  };

  const handleSaveTag = async () => {
    if (!checkIsValid()) {
      return;
    }
    if (currentTag.name && currentTag._id) {
      const hasConfirmation = await AlertUtil.confirm(
        msg('tags.tagEditConfirmationText', 'This action will affect existing users.'),
        msg('general.edit', 'Edit'),
        msg('tags.tagEditConfirmation', 'Are you sure you want to edit this tag?')
      );
      if (hasConfirmation) {
        props.editTagAction(props.isSAdmin, currentTag._id, currentTag, props.match.params?.placeId || '');
      }
    } else if (currentTag.name) {
      props.addTagAction(props.isSAdmin, currentTag, props.match.params?.placeId || '');
    }
  };

  const handleSaveTagByEnter = (event: KeyboardEvent) => {
    let e = event || window.event;
    if (e.key === 'Enter' && currentTag.name) {
      handleSaveTag();
    }
  };

  const handleDelete = async (tagId: string = '') => {
    const hasConfirmation = await AlertUtil.confirm(
      msg('tags.tagDeleteConfirmation', 'You are about to delete a tag!')
    );
    if (hasConfirmation) {
      props.deleteTagAction(props.isSAdmin, tagId, props.match.params?.placeId || '');
    }
  };

  const toggleColorPicker = (show: boolean) => () => {
    setShowColorPicker(show);
  };

  const renderTags = (tags: PaginationDTO<TagDTO>) => {
    if (!tags?.results) {
      return null;
    }
    return tags.results.map((tag: TagDTO) => (
      <div key={tag._id} style={{ padding: 5, zIndex: 2 }}>
        <Chip
          id={tag._id}
          label={tag.name}
          onClick={() => handleChipClick(tag)}
          onDelete={() => handleDelete(tag._id)}
          style={{
            ...styles.chip,
            borderColor: tag.color || colors.gray,
            backgroundColor:
              tag._id === currentTag._id
                ? hexToRgba(tag.color || colors.gray, 0.5)
                : hexToRgba(tag.color || colors.gray, 0.2),
            fontWeight: tag._id === currentTag._id ? 500 : 300
          }}
        />
      </div>
    ));
  };

  return (
    <CardComponent
      title={msg('tags.cardTitle', 'Tags')}
      error={!!props.tags_list_error}
      pending={props.tags_list_pending && !props.tags_list.results}
      headerIcon="local_offer"
      needsTitle={true}
      disableAnimated={true}
    >
      <legend style={{ fontSize: 18, margin: 12 }}>
        {props.isSAdmin ? msg('tags.userTags', 'User Tags') : msg('tags.memberTags', 'Member Tags')}
      </legend>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: 20, marginLeft: 10 }}>
        {renderTags(props.tags_list)}
        <div style={{ padding: 5, zIndex: 2 }}>
          <Chip
            label={
              <i className="material-icons" style={{ color: 'white', padding: '5px 0px' }}>
                add
              </i>
            }
            color="primary"
            onClick={() => {
              setCurrentTag(new TagDTO());
            }}
          />
        </div>
      </div>
      <div style={styles.row}>
        <div className="col-sm-6 col-md-4 col-lg-2" style={{ zIndex: 2 }}>
          <TextFieldComponent
            id="name"
            label={msg('tags.name', 'Tag name')}
            value={currentTag.name}
            onChange={handleEditTag}
            onKeyUp={handleSaveTagByEnter}
            minLength={1}
            formatError={error.name}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-2" style={{ zIndex: 2 }}>
          <TextFieldComponent
            id="priority"
            label={msg('tags.priority', 'Tag priority')}
            value={(currentTag.priority ?? '') + ''}
            onChange={handleEditTag}
            onKeyUp={handleSaveTagByEnter}
            minLength={1}
          />
        </div>
      </div>

      <div className="row" style={{ margin: 0 }}>
        <div className="col-sm-10 col-md-8 col-lg-4" style={{ zIndex: 2 }}>
          <TextFieldComponent
            id="description"
            inType="textarea"
            rows={4}
            label={msg('tags.description', 'Tag description')}
            value={currentTag.description || ''}
            onChange={handleEditTag}
            onKeyUp={handleSaveTagByEnter}
            minLength={1}
          />
        </div>
      </div>

      <div style={styles.row}>
        <div className="col-sm-6 col-md-4 col-lg-2" style={{ zIndex: 2 }}>
          <TextFieldComponent
            id="color"
            label={msg('tags.color', 'Tag color')}
            value={currentTag.color || ''}
            onFocus={toggleColorPicker(true)}
            onChange={handleEditTag}
            onKeyUp={handleSaveTagByEnter}
            minLength={1}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-2">
          {showColorPicker && (
            <ColorPickerComponent
              initialColor={currentTag.color}
              closeColorPicker={toggleColorPicker(false)}
              handleColorChange={handleSaveTagColor}
            />
          )}
        </div>
      </div>

      <div style={{ marginLeft: 12, marginTop: 20 }}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={enableAction}
              onChange={() => setEnableAction(!enableAction)}
              value="showPasswords"
            />
          }
          label={msg('tags.actions', 'Configure tag action')}
        />
      </div>

      {enableAction && (
        <div style={{ marginLeft: 12, marginTop: 10 }}>
          <div style={styles.row}>
            {msg('tags.actionTarget', 'Number of coins needed to upgrade to this tag')}
            <Tooltip title={props.isSAdmin ? msg('tags.userActionInfo', '') : msg('tags.memberActionInfo', '')}>
              <i className="material-icons" style={{ marginLeft: 3, fontSize: 16, color: colors.gray }}>
                info
              </i>
            </Tooltip>
            :
            <div className="col-sm-6 col-md-3 col-lg-1">
              <TextFieldComponent
                id="action.target"
                value={(currentTag.action?.target ?? '') + ''}
                onChange={handleEditTag}
                style={styles.input}
              />
            </div>
          </div>
          <div style={{ ...styles.row, marginTop: 5 }}>
            {msg('tags.actionDowngrade', 'Time before tag expires')}:
            <div className="col-sm-6 col-md-3 col-lg-1">
              <TextFieldComponent
                id="action.downgradeInterval"
                value={(currentTag.action?.downgradeInterval ?? '') + ''}
                onChange={handleEditTag}
                style={styles.input}
              />
            </div>
            <div className="col-sm-2">
              <SimpleSelectComponent
                id="action.downgradeIntervalType"
                name="action.downgradeIntervalType"
                options={[
                  { name: msg('periodTypes.minutes', 'minutes'), _id: TimeIntervalType.MINUTES },
                  { name: msg('periodTypes.hours', 'hours'), _id: TimeIntervalType.HOURS },
                  { name: msg('periodTypes.days', 'days'), _id: TimeIntervalType.DAYS },
                  { name: msg('periodTypes.weeks', 'weeks'), _id: TimeIntervalType.WEEKS },
                  { name: msg('periodTypes.months', 'months'), _id: TimeIntervalType.MONTHS }
                ]}
                value={currentTag.action?.downgradeIntervalType ?? TimeIntervalType.MONTHS}
                onChange={handleChangePeriodType}
                style={styles.input}
                formatError={error.downgradeIntervalType}
              />
            </div>
          </div>
        </div>
      )}

      <div style={{ marginLeft: 10 }}>
        <ButtonComponent
          label={currentTag._id ? msg('general.edit', 'Edit') : msg('general.add', 'Add')}
          action={handleSaveTag}
          styles={{ marginRight: 10, zIndex: 2 }}
          pending={props.add_tag_pending || props.edit_tag_pending}
          disabled={!isDirty}
        />
      </div>
    </CardComponent>
  );
};

function mapStateToProps(state: AppStore.states) {
  return {
    tags_list: state.tags.tags_list,
    tags_list_pending: state.tags.tags_list_pending,
    tags_list_error: state.tags.tags_list_error,
    add_tag_pending: state.tags.add_tag_pending,
    add_tag_error: state.tags.add_tag_error,
    edit_tag_pending: state.tags.edit_tag_pending,
    edit_tag_error: state.tags.edit_tag_error,
    delete_tag_pending: state.tags.delete_tag_pending,
    delete_tag_error: state.tags.delete_tag_error
  };
}

const dispatchToProps = {
  getTagsAction: TagsStore.actions.getTagsAction,
  addTagAction: TagsStore.actions.addTagAction,
  editTagAction: TagsStore.actions.editTagAction,
  deleteTagAction: TagsStore.actions.deleteTagAction,
  clearTagsActions: TagsStore.actions.clearTagsActions
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(TagsContainer));

const styles = {
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  chip: {
    borderWidth: 2,
    borderStyle: 'solid'
  },
  input: {
    marginTop: -5
  }
};
