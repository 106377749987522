import React from 'react';
import { Bounce } from 'react-activity';
import { colors } from '../../assets/styles';

import { msg } from '../../utils';

export const LoadingOverlay = () => {
  const spinnerOverlayStyle = {
    position: 'absolute' as 'absolute',
    top: '40%',
    left: '42%',
    width: 150,
    backgroundColor: 'rgba(85, 85, 85, 0.8)',
    borderRadius: 6,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.44)',
    padding: 20,
    textAlign: 'center' as 'center',
    zIndex: 99
  };
  return (
    <div style={{ ...spinnerOverlayStyle }}>
      <h4 style={{ color: colors.white }}>{msg('general.loading', 'Loading...')}</h4>
      <Bounce color={colors.white} />
    </div>
  );
};
