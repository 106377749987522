import 'sweetalert2/src/sweetalert2.scss';

import Swal from 'sweetalert2';

import { msg } from './';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger'
  },
  buttonsStyling: false
});

const decorateResult =
  ({ confirmValue, cancelValue }: any) =>
  (result: any) => {
    return result.value ? confirmValue : cancelValue;
  };

class AlertsUtil {
  /*
    Class that implements all alert type functions, based on sweetalert library
  */

  /*
    @function confirm => prompts a modal whenever you need confirmation from user (usually used for deleting processes)
      @accepts text : prompt message
      @returns swal : sweetalert object
  */
  async confirm(text: string, confirmText?: string, title?: string) {
    return swalWithBootstrapButtons
      .fire({
        title: title || msg('alertMessages.areYouSure', 'Are you sure?'),
        text: text,
        icon: 'warning',
        confirmButtonText: confirmText ? confirmText : msg('alertMessages.delete', 'Delete'),
        cancelButtonText: msg('alertMessages.goBack', 'Go Back'),
        showCancelButton: true,
        reverseButtons: true,
        padding: '1em 2em'
      })
      .then(result => result.value);
  }

  async confirmWithHtml(title: string, htmlContent: string, confirmText: string) {
    return swalWithBootstrapButtons
      .fire({
        title: title || msg('alertMessages.areYouSure', 'Are you sure?'),
        html: htmlContent,
        icon: 'info',
        confirmButtonText: confirmText,
        cancelButtonText: msg('alertMessages.goBack', 'Go Back'),
        showCancelButton: true,
        reverseButtons: true,
        padding: '1em 2em'
      })
      .then(result => result.value);
  }

  /*
    @function inherit => prompts a modal, asking if you want to inherit default autopilot rules
      @accepts text : prompt message
      @returns swal : sweetalert object
  */
  async inherit(text: string) {
    return swalWithBootstrapButtons
      .fire({
        title: msg('alertMessages.areYouSure', 'Are you sure?'),
        text: text,
        icon: 'warning',
        confirmButtonText: msg('alertMessages.agree', 'I Agree'),
        cancelButtonText: msg('alertMessages.goBack', 'Go Back'),
        showCancelButton: true,
        padding: '1em 2em'
      })
      .then(
        decorateResult({
          confirmValue: true,
          cancelValue: null
        })
      );
  }

  /*
    @function revokeRights => prompts a modal, asking if you want to revoke admin rights for a partner / place admin
      @accepts text : prompt message
      @returns swal : sweetalert object
  */
  async revokeRights(text: string) {
    return swalWithBootstrapButtons
      .fire({
        title: msg('alertMessages.areYouSure', 'Are you sure?'),
        text: text,
        icon: 'warning',
        confirmButtonText: msg('alertMessages.revoke', 'Revoke Rights'),
        cancelButtonText: msg('alertMessages.goBack', 'Go Back'),
        showCancelButton: true,
        padding: '1em 2em'
      })
      .then(
        decorateResult({
          confirmValue: true,
          cancelValue: null
        })
      );
  }

  updateContent = (title: string, icon: 'info' | 'success' | 'error' | 'warning' | 'question' | undefined) => {
    Swal.update({
      title: title,
      icon: icon
    });
  };

  /*
    @function simple => creates a sweetalert notification that closes in 1.5 seconds
      @accepts title : message that will be displayed in the notification
               icon : name of the icon (ex: 'success', 'info', 'error')
      @returns null
  */
  simple(
    title: string,
    icon: 'info' | 'success' | 'error' | 'warning' | 'question' | undefined,
    duration: number = 2000,
    forceFire: boolean = false
  ) {
    if (!Swal.isVisible() || forceFire) {
      Swal.fire({
        title: title,
        icon: icon,
        showConfirmButton: false,
        timer: duration,
        padding: '1em 2em'
      });
    }
  }

  fireOnce(
    title: string,
    icon: 'info' | 'success' | 'error' | 'warning' | 'question' | undefined,
    duration: number = 2000
  ) {
    if (Swal.isVisible()) {
      this.updateContent(title, icon);
    } else {
      this.simple(title, icon, duration);
    }
  }

  async fireWithInput(
    title: string,
    text?: string,
    label?: string,
    inputValidator?: (value: string) => string | null,
    hideInput?: boolean
  ) {
    return swalWithBootstrapButtons
      .fire({
        title,
        text: text,
        input: 'text',
        inputLabel: label,
        inputValidator,
        inputAttributes: hideInput
          ? {
              style: '-webkit-text-security: disc !important;'
            }
          : {},
        showCancelButton: true,
        cancelButtonText: msg('alertMessages.goBack', 'Go Back'),
        reverseButtons: true,
        padding: '1em 2em'
      })
      .then(result => result.value);
  }
}

const alertsUtil = new AlertsUtil();
export default alertsUtil;
