import { TransactionsStore } from '.';
import { PaginationDTO } from '../types';

function transactionsReducer(
  state: TransactionsStore.IState = TransactionsStore.initialState,
  action: IAction<any>
): TransactionsStore.IState {
  switch (action.type) {
    case TransactionsStore.ActionTypes.GET_TRANSACTIONS: {
      return {
        ...state,
        transactions_list_pending: true,
        transactions_list_error: null
      };
    }
    case TransactionsStore.ActionTypes.GET_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        transactions_list_pending: false,
        transactions_list_error: null,
        transactions_list: action.payload
      };
    }
    case TransactionsStore.ActionTypes.GET_TRANSACTIONS_FAILED: {
      return {
        ...state,
        transactions_list_pending: false,
        transactions_list: new PaginationDTO(),
        transactions_list_error: action.payload
      };
    }
    case TransactionsStore.ActionTypes.GET_TRANSACTION: {
      return {
        ...state,
        transaction_pending: true,
        transaction_error: null
      };
    }
    case TransactionsStore.ActionTypes.GET_TRANSACTION_SUCCESS: {
      return {
        ...state,
        transaction_pending: false,
        transaction_error: null,
        transaction: action.payload
      };
    }
    case TransactionsStore.ActionTypes.GET_TRANSACTION_FAILED: {
      return {
        ...state,
        transaction_pending: false,
        transaction_error: action.payload,
        transaction: null
      };
    }
    case TransactionsStore.ActionTypes.ROLLBACK: {
      return {
        ...state,
        rollback_pending: true,
        rollback_error: null
      };
    }
    case TransactionsStore.ActionTypes.ROLLBACK_SUCCESS: {
      return {
        ...state,
        rollback_pending: false,
        rollback_error: null
      };
    }
    case TransactionsStore.ActionTypes.ROLLBACK_FAILED: {
      return {
        ...state,
        rollback_pending: false,
        rollback_error: action.payload
      };
    }

    case TransactionsStore.ActionTypes.GET_TRANSACTIONS_FOR_USER: {
      return {
        ...state,
        transactions_list_for_user_pending: true,
        transactions_list_for_user_error: null
      };
    }
    case TransactionsStore.ActionTypes.GET_TRANSACTIONS_FOR_USER_SUCCESS: {
      return {
        ...state,
        transactions_list_for_user: action.payload,
        transactions_list_for_user_pending: false,
        transactions_list_for_user_error: null
      };
    }
    case TransactionsStore.ActionTypes.GET_TRANSACTIONS_FOR_USER_FAILED: {
      return {
        ...state,
        transactions_list_for_user: new PaginationDTO(),
        transactions_list_for_user_pending: false,
        transactions_list_for_user_error: action.payload
      };
    }
    case TransactionsStore.ActionTypes.EXPORT_TRANSACTIONS: {
      return {
        ...state,
        export_transactions_pending: true,
        export_transactions_error: null
      };
    }
    case TransactionsStore.ActionTypes.EXPORT_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        export_transactions_pending: false,
        export_transactions_error: null
      };
    }
    case TransactionsStore.ActionTypes.EXPORT_TRANSACTIONS_FAILED: {
      return {
        ...state,
        export_transactions_pending: false,
        export_transactions_error: action.payload
      };
    }
    case TransactionsStore.ActionTypes.CLEAR_USER_TRANSACTIONS: {
      return {
        ...state,
        transactions_list_for_user: new PaginationDTO()
      };
    }
    case TransactionsStore.ActionTypes.CLEAR_MODAL_DATA: {
      return {
        ...state,
        transaction: null
      };
    }
    case TransactionsStore.ActionTypes.CLEAR_TRANSACTIONS_ERROR: {
      return {
        ...state,
        transactions_list_error: null,
        transactions_list_for_user_error: null
      };
    }
    default:
      return state;
  }
}

export default transactionsReducer;
