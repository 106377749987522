// CHECK-INS TAB
export const checkinsTabFixtures = {
  totalCheckinsData: [16, 34, 59, 40, 24, 13, 40, 68, 82, 111],
  totalMembersData: [8, 29, 71, 32, 10, 8, 54, 58, 95, 123],
  membersByAgeData: [112, 97, 69, 106, 45, 16],
  membersByGenderData: [197, 167],
  channelsDistributionData: [186, 156, 205]
};
// REWARDS TAB
const sum = (a: any, b: any) => a + b;
const rewardsValues = {
  reward1: [38, 41, 49, 35, 28, 22, 26, 45, 54, 66],
  reward2: [46, 49, 58, 42, 31, 25, 34, 44, 62, 64],
  reward3: [43, 43, 49, 37, 26, 18, 28, 43, 56, 68],
  reward4: [38, 41, 45, 50, 35, 25, 22, 37, 49, 63],
  reward5: [33, 35, 48, 49, 29, 19, 21, 36, 48, 62],
  reward6: [23, 24, 32, 19, 12, 6, 10, 25, 38, 40],
  reward7: [42, 46, 59, 45, 36, 22, 37, 43, 67, 62],
  reward8: [36, 35, 42, 47, 25, 17, 26, 32, 46, 65]
};
export const rewardsTabFixtures = {
  rewardCosts: [10, 10, 20, 30, 25, 45, 35, 50],
  rewardTitles: [
    'Complementary Beer',
    'Complementary Soda',
    'Complementary Appetizer',
    '30% Discount',
    'Complementary Salad',
    'Complementary Steak'
  ],
  rewardsPerMonth: rewardsValues,
  distributedValues: [
    rewardsValues.reward1.reduce(sum, 0),
    rewardsValues.reward2.reduce(sum, 0),
    rewardsValues.reward3.reduce(sum, 0),
    rewardsValues.reward4.reduce(sum, 0),
    rewardsValues.reward5.reduce(sum, 0),
    rewardsValues.reward6.reduce(sum, 0),
    rewardsValues.reward7.reduce(sum, 0),
    rewardsValues.reward8.reduce(sum, 0)
  ]
};

// AUTOPILOT TAB
const autopilotValues = {
  autopilot1: [25, 28, 28, 29, 27, 25, 28, 46, 37, 45],
  autopilot2: [31, 35, 39, 40, 39, 37, 39, 49, 64, 69],
  autopilot3: [23, 27, 30, 33, 33, 31, 35, 48, 63, 68],
  autopilot4: [28, 34, 38, 39, 37, 35, 36, 50, 65, 70],
  autopilot5: [24, 29, 22, 31, 28, 26, 29, 46, 52, 61],
  autopilot6: [22, 25, 29, 31, 31, 29, 30, 16, 39, 48],
  autopilot7: [25, 27, 22, 36, 35, 28, 35, 12, 35, 46],
  autopilot8: [27, 22, 24, 35, 36, 27, 33, 16, 33, 46]
};
export const autopilotTabFixtures = {
  claims: [
    autopilotValues.autopilot1.reduce(sum, 0),
    autopilotValues.autopilot2.reduce(sum, 0),
    autopilotValues.autopilot3.reduce(sum, 0),
    autopilotValues.autopilot4.reduce(sum, 0),
    autopilotValues.autopilot5.reduce(sum, 0),
    autopilotValues.autopilot6.reduce(sum, 0),
    autopilotValues.autopilot7.reduce(sum, 0),
    autopilotValues.autopilot8.reduce(sum, 0)
  ],
  redeems: [
    autopilotValues.autopilot1.reduce(sum, 0) - 32,
    autopilotValues.autopilot2.reduce(sum, 0) - 15,
    autopilotValues.autopilot3.reduce(sum, 0) - 24,
    autopilotValues.autopilot4.reduce(sum, 0) - 21,
    autopilotValues.autopilot5.reduce(sum, 0) - 29,
    autopilotValues.autopilot6.reduce(sum, 0) - 30,
    autopilotValues.autopilot7.reduce(sum, 0) - 30,
    autopilotValues.autopilot8.reduce(sum, 0) - 30
  ],
  triggered: [
    autopilotValues.autopilot1.reduce(sum, 0) + 32,
    autopilotValues.autopilot2.reduce(sum, 0) + 15,
    autopilotValues.autopilot3.reduce(sum, 0) + 24,
    autopilotValues.autopilot4.reduce(sum, 0) + 21,
    autopilotValues.autopilot5.reduce(sum, 0) + 29,
    autopilotValues.autopilot6.reduce(sum, 0) + 30,
    autopilotValues.autopilot7.reduce(sum, 0) + 30,
    autopilotValues.autopilot8.reduce(sum, 0) + 30
  ],
  autopilotTitle: [
    'Coins Reached',
    'Check-ins Reached',
    'Less than 5 check-ins',
    'Halloween Special',
    'One Complementary Drink every 5 check-ins',
    'Extra 10 coins for every 5 check-ins'
  ],
  autopilotsPerMonth: autopilotValues
};

// EVENTS TAB
const eventValues = {
  event1: [32, 44, 62, 47, 30, 25, 41, 65, 81, 84],
  event2: [30, 38, 60, 49, 37, 34, 39, 63, 79, 80],
  event3: [29, 37, 59, 47, 35, 30, 38, 62, 78, 78],
  event4: [12, 14, 19, 23, 26, 29, 41, 51, 75, 72],
  event5: [9, 12, 17, 24, 24, 31, 46, 46, 52, 54],
  event6: [11, 13, 18, 23, 25, 29, 38, 42, 47, 52],
  event7: [13, 15, 18, 23, 25, 27, 33, 42, 47, 52],
  event8: [14, 13, 51, 23, 32, 29, 13, 45, 44, 52]
};
export const eventsTabFixtures = {
  sent: [
    eventValues.event1.reduce(sum, 0) + 99,
    eventValues.event2.reduce(sum, 0) + 121,
    eventValues.event3.reduce(sum, 0) + 94,
    eventValues.event4.reduce(sum, 0) + 135,
    eventValues.event5.reduce(sum, 0) + 109,
    eventValues.event6.reduce(sum, 0) + 80,
    eventValues.event7.reduce(sum, 0) + 80,
    eventValues.event8.reduce(sum, 0) + 80
  ],
  opened: [
    eventValues.event1.reduce(sum, 0),
    eventValues.event2.reduce(sum, 0),
    eventValues.event3.reduce(sum, 0),
    eventValues.event4.reduce(sum, 0),
    eventValues.event5.reduce(sum, 0),
    eventValues.event6.reduce(sum, 0),
    eventValues.event7.reduce(sum, 0),
    eventValues.event8.reduce(sum, 0)
  ],
  eventNames: [
    'Happy Hour Tuesday!',
    'Pasta Friday',
    'Rock 80-90s party',
    'Halloween Costume Party',
    'New Years Eve 2019 Dinner',
    'Christmas Party'
  ],
  eventsPerMonth: eventValues,
  emails: [356, 49, 241, 56, 192, 98, 91, 219],
  sms: [112, 55, 169, 101, 46, 112, 151, 89],
  notifs: [73, 405, 83, 205, 77, 88, 91, 104]
};

// COLORS
export const colors = {
  color1: 'rgba(99, 191, 136,1)',
  color1Opac02: 'rgba(99, 191, 136,0.2)',
  color1Opac04: 'rgba(99, 191, 136,0.4)',
  color2: 'rgba(0, 89, 34,1)',
  color2Opac02: 'rgba(0, 89, 34,0.2)',
  color2Opac04: 'rgba(0, 89, 34,0.4)',
  color3: 'rgba(74, 192, 170,1)',
  color3Opac02: 'rgba(74, 192, 170,0.2)',
  color3Opac04: 'rgba(74, 192, 170,0.4)',
  color4: 'rgba(0, 129, 164,1)',
  color4Opac02: 'rgba(0, 129, 164,0.2)',
  color4Opac04: 'rgba(0, 129, 164,0.4)',
  color5: 'rgba(78, 255, 252,1)',
  color5Opac02: 'rgba(78, 255, 252,0.2)',
  color5Opac04: 'rgba(78, 255, 252,0.4)',
  color6: 'rgba(3, 124, 3,1)',
  color6Opac02: 'rgba(3, 124, 3,0.2)',
  color6Opac04: 'rgba(3, 124, 3,0.4)',
  color7: 'rgba(28, 193, 228,1)',
  color7Opac02: 'rgba(28, 193, 228,0.2)',
  color7Opac04: 'rgba(28, 193, 228,0.4)',
  color8: 'rgba(244, 200, 66, 1)',
  color8Opac02: 'rgba(244, 200, 66, 0.2)',
  color8Opac04: 'rgba(244, 200, 66, 0.4)',
  color9: 'rgba(208, 133, 221, 1)',
  color9Opac02: 'rgba(208, 133, 221, 0.2)',
  color9Opac04: 'rgba(208, 133, 221, 0.4)',
  color10: 'rgba(192, 193, 214, 1)',
  color10Opac02: 'rgba(192, 193, 214, 0.2)',
  color10Opac04: 'rgba(192, 193, 214, 0.4)'
};
