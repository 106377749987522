/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react';
import { Bounce } from 'react-activity';
import { Button, Modal } from 'react-bootstrap';
import Toggle from 'react-toggle';

import Tooltip from '@material-ui/core/Tooltip';

import { PaginationDTO, PlaceDTO, ReceiptDTO, ReceiptOcrDTO, ReceiptStatus } from '../../../../appRedux/types';
import { colors } from '../../../../assets/styles';
import { AlertUtil, deserializeQueryString, getFormatDate, msg, msgHTML } from '../../../../utils';
import {
  AdvancedListComponent,
  ButtonComponent,
  CardComponent,
  ExportModalComponent,
  TextFieldComponent
} from '../../../shared';

interface IProps {
  match?: any;
  config: {
    place: PlaceDTO | null;
    receipt: ReceiptDTO | null;
    receipt_list: PaginationDTO<ReceiptDTO>;
    receipt_list_pending: boolean;
    receipt_list_error: string | null;
    delete_succcess: boolean;
    place_save_pending: boolean;
    place_save_error: string | null;
    export_receipts_pending: boolean;
    export_receipts_error: string | null;
    getReceiptListAction(
      isSAdmin: boolean,
      placeId: string,
      limit?: Number,
      skip?: Number,
      sort?: string,
      criteria?: { [key: string]: any }
    ): any;
    editReceiptAction(isSAdmin: boolean, placeId: string, receiptId: string, receipt: any, showAlert?: boolean): any;
    deleteReceiptAction(isSAdmin: boolean, placeId: string, receiptId: string, showAlert?: boolean): any;
    changeReceiptsAllowed(placeId: string, allowReceipts: boolean): any;
    editPlaceAction(settings: any): any;
    exportReceiptsInExcel(placeId: string, startDate: number, endDate: number): any;
    clearPlaceReceiptsError(): any;
  };
  forSAdmin: boolean;
}

export const ReceiptListComponent = (props: IProps) => {
  const [receipts, setReceipts] = useState<PaginationDTO<ReceiptDTO>>(new PaginationDTO());
  const [errors, setErrors] = useState<any>({});
  const [allowReceipts, setAllowReceipts] = useState<boolean>(false);
  const [convertAmountRule, setConvertAmountRule] = useState<number>(10);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [modalIsDirty, setModalIsDirty] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const placeId = props.match?.params?.placeId || '';
  const { getReceiptListAction, clearPlaceReceiptsError } = props.config;

  useEffect(() => {
    return clearPlaceReceiptsError();
    // tslint:disable-next-line: align
  }, [props.match?.params?.placeId]);

  useEffect(() => {
    if (props.config.place) {
      const placeAllowReceipts = props.config.place.allowReceipts;
      const placeConvertAmountRule = props.config.place.settings?.convertAmountRule;
      setAllowReceipts(placeAllowReceipts);
      setConvertAmountRule(placeConvertAmountRule);
    }
    // tslint:disable-next-line: align
  }, [props.config.place]);

  useEffect(() => {
    if (props.config.receipt_list) {
      setReceipts(parseReceipts(props.config.receipt_list));
    }
    // tslint:disable-next-line: align
  }, [props.config.receipt_list]);

  useEffect(() => {
    const receipt = props.config.receipt;
    if (receipt) {
      const receiptResults = receipts?.results;
      const index = receiptResults?.findIndex((item: ReceiptDTO) => item._id === receipt?._id);
      if (index > -1) {
        receiptResults[index].amount = receipt.amount;
        receiptResults[index].status = receipt.status;
        receiptResults[index].statusLabel = ReceiptStatus[receipt.status];
        receiptResults[index].badge = getBadgeStatus(ReceiptStatus[receipt.status]);
        receiptResults[index].isValid = true;
        setReceipts({ ...receipts, results: receiptResults });
      }
    }
    // tslint:disable-next-line: align
  }, [props.config.receipt]);

  useEffect(() => {
    if (props.config.delete_succcess) {
      const { limit, skip } = props.config.receipt_list;
      const queryCriteria = deserializeQueryString(window.location.search);
      const newCriteria = {
        filters: { status: queryCriteria.status },
        search: queryCriteria.search
          ? { 'user.firstname': queryCriteria.search, 'user.lastname': queryCriteria.search }
          : {}
      };
      getReceiptListAction(props.forSAdmin, placeId, limit, skip, queryCriteria.sort || 'created,-1', newCriteria);
    }
    // tslint:disable-next-line: align
  }, [props.config.delete_succcess]);

  useEffect(() => {
    if (!props.forSAdmin && showSettingsModal && !props.config.place_save_pending && !props.config.place_save_error) {
      toggleSettingsModal();
    }
    // tslint:disable-next-line: align
  }, [props.config.place_save_pending]);

  const getReceipts = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    return props.config.getReceiptListAction(props.forSAdmin, placeId, limit, skip, sort, criteria);
  };

  const getBadgeStatus = (status: string) => {
    return status === 'PENDING' ? colors.orange : status === 'APPROVED' ? colors.green : colors.red;
  };

  const renderPlaceholder = (extraData: string) => () => {
    const data = extraData.split(/\n/);
    return data.map((item, index) => <div key={index}>{item}</div>);
  };

  const parseReceipts = (list: PaginationDTO<ReceiptDTO>): any => {
    const results: any[] = [];
    if (list.results) {
      list.results.forEach(item => {
        results.push({
          ...item,
          created: getFormatDate(item.created),
          user: item.user ? item.user.lastname + ' ' + item.user.firstname : 'N/A',
          statusLabel: ReceiptStatus[item.status],
          isDirty: false,
          isValid: true,
          badge: getBadgeStatus(ReceiptStatus[item.status]),
          receiptData: {
            type: 'custom',
            render: () => {
              if (!item.ocrData && !item.userData) {
                return <div>N/A</div>;
              }
              return (
                <>
                  {item.ocrData && renderOcrData(item.ocrData, msg('receipts.ocr', 'OCR'))}
                  {item.userData && renderOcrData(item.userData, msg('receipts.user', 'User'))}
                </>
              );
            }
          }
        });
        if (item.extraData) {
          results[results.length - 1].renderPlaceholder = renderPlaceholder(item.extraData);
        }
      });
    }
    return { ...list, results };
  };

  const renderOcrData = (data: ReceiptOcrDTO, type: string) => {
    return (
      <div style={{ marginBottom: 8 }}>
        <div style={{ fontWeight: 'bold' }}>{type}:</div>
        {Object.keys(data).map((key, index) => (
          <div key={index}>
            {key}: {data[key]}
          </div>
        ))}
      </div>
    );
  };

  const checkValidAmount = (receipt: ReceiptDTO) => {
    return !isNaN(receipt.amount) && receipt.amount > 0;
  };

  const handleApproveReceipt = (event: any) => {
    const receiptId = event.currentTarget.getAttribute('id');
    const receiptIndex = receipts?.results.findIndex((item: ReceiptDTO) => item._id === receiptId);
    if (receiptIndex > -1) {
      const receipt = receipts?.results[receiptIndex];
      receipt.isValid = checkValidAmount(receipt);
      if (receipt.isValid) {
        const editedReceipt = { amount: receipt.amount, status: ReceiptStatus.APPROVED };
        props.config.editReceiptAction(props.forSAdmin, placeId, receiptId, editedReceipt, true);
      } else {
        const receiptResults = receipts?.results;
        receiptResults[receiptIndex] = receipt;
        setReceipts({ ...receipts, results: receiptResults });
      }
    }
  };

  const handleMarkFraud = async (event: any) => {
    const receiptId = event.currentTarget.getAttribute('id');
    const receipt = receipts?.results.find((item: ReceiptDTO) => item._id === receiptId);
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.receiptFraudConfirmation', 'You are about to mark a receipt as fraud!'),
      msg('alertMessages.continue', 'Continue')
    );
    if (hasConfirmation) {
      const editedReceipt = { amount: receipt?.amount, status: ReceiptStatus.FRAUD };
      props.config.editReceiptAction(props.forSAdmin, placeId, receiptId, editedReceipt, true);
    }
  };

  const handleDelete = async (event: any) => {
    const receiptId = event.currentTarget.getAttribute('id');
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.receiptDeleteConfirmation', 'You are about to delete a receipt!')
    );
    if (hasConfirmation) {
      props.config.deleteReceiptAction(props.forSAdmin, placeId, receiptId, true);
    }
  };

  const toggleExportModal = () => {
    const show = showExportModal;
    setShowExportModal(!show);
  };

  const exportInExcel = (startDate: number, endDate: number) => {
    props.config.exportReceiptsInExcel(placeId, startDate, endDate);
  };

  const getButtons = () => {
    if (props.forSAdmin) {
      return;
    }
    let buttons = [
      {
        label: msg('receipts.receiptsSettings', 'Receipt settings'),
        icon: 'settings',
        onClick: toggleSettingsModal
      }
    ];
    if (props.config.place?.featureFlags.exportInExcel) {
      buttons.push({
        label: msg('exportExcel.exportExcel', 'Export Excel'),
        icon: 'file_download',
        onClick: toggleExportModal
      });
    }
    return buttons;
  };

  const getActions = () => {
    return [
      {
        label: msg('receipts.approve', 'Approve'),
        btn: 'btn-success',
        icon: 'change_history',
        isShown: (item: ReceiptDTO) => item.status === ReceiptStatus.PENDING,
        onClick: handleApproveReceipt
      },
      {
        label: msg('receipts.markFraud', 'Mark as fraud'),
        btn: 'btn-warning',
        icon: 'warning',
        isShown: (item: ReceiptDTO) => item.status === ReceiptStatus.PENDING,
        onClick: handleMarkFraud
      },
      {
        label: msg('general.delete', 'Delete'),
        btn: 'btn-danger',
        icon: 'delete',
        isShown: (item: ReceiptDTO) => item.status === ReceiptStatus.PENDING,
        onClick: handleDelete
      }
    ];
  };

  const getFields = () => {
    const fields: any = {
      created: msg('receipts.data', 'Date'),
      user: msg('receipts.user', 'User'),
      receiptData: msg('receipts.receiptData', 'Receipt Data')
    };
    if (props.forSAdmin) {
      fields.placeName = msg('receipts.place', 'Place');
    }
    fields.statusLabel = msg('receipts.status', 'Status');
    return fields;
  };

  const getSearch = () => {
    const search: any = ['user.lastname', 'user.firstname'];
    if (props.forSAdmin) {
      search.push('placeName');
    }
    return search;
  };

  const handleChangeAmount = (itemIndex: number, value: number) => {
    const receiptResults = receipts.results.map((receipt: ReceiptDTO, index: number) => {
      if (index === itemIndex) {
        receipt.amount = value;
        receipt.isDirty = true;
      }
      return receipt;
    });
    setReceipts({ ...receipts, results: receiptResults });
  };

  const handleSaveAmount = (itemIndex: string) => {
    const receipt = { ...receipts.results[itemIndex] };
    receipt.isValid = checkValidAmount(receipt);
    // if (receipt.isValid && receipt.isDirty) {
    //   const editedReceipt = { amount: receipt.amount, status: receipt.status };
    //   props.config.editReceiptAction(props.forSAdmin, placeId, receipt._id, editedReceipt);
    // } else
    if (receipt.isDirty) {
      const receiptResults = receipts?.results;
      receiptResults[itemIndex] = receipt;
      setReceipts({ ...receipts, results: receiptResults });
    }
  };

  const checkIfReadOnly = (item: ReceiptDTO) => {
    return item.status !== ReceiptStatus.PENDING;
  };

  const handleToggleChange = (event: any) => {
    const value = !!event.target.checked;
    setAllowReceipts(value);
    setModalIsDirty(true);
  };

  const toggleSettingsModal = () => {
    const show = showSettingsModal;
    setShowSettingsModal(!show);
  };

  const changeConvertRule = (event: any) => {
    const value = event.target.value;
    if (value < 0 || isNaN(value)) {
      setErrors({ convertAmountRule: msg('formValidation.onlyPositiveNumbers', 'Use only positive numbers!') });
    } else {
      setErrors({ convertAmountRule: null });
    }
    setConvertAmountRule(value);
    setModalIsDirty(true);
  };

  const saveReceiptSettings = () => {
    if (!errors.convertAmountRule) {
      const dataToSend = {
        _id: placeId,
        allowReceipts: allowReceipts,
        settings: {
          ...props.config.place?.settings,
          convertAmountRule: parseInt(convertAmountRule + '', 10)
        }
      };
      props.config.editPlaceAction(dataToSend);
      setModalIsDirty(false);
    }
  };

  const renderReceiptSettingsModal = () => {
    const enableToggle = parseInt(convertAmountRule + '', 10) >= 0;
    return (
      <Modal show={true} onHide={toggleSettingsModal} dialogClassName="responsive-modal">
        <Modal.Header>
          <Modal.Title>{msg('receipts.receiptsSettings', 'Receipt settings')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', marginLeft: 10, alignItems: 'center', fontSize: 16 }}>
            <div style={{ marginRight: 5 }}>
              {msgHTML('placeSettings.conversionRule', 'Amount to coins convert ratio:')}
            </div>
            <div className="col-sm-2">
              <TextFieldComponent
                id="convertAmountRule"
                inType="text"
                value={convertAmountRule.toString() || ''}
                onChange={changeConvertRule}
                minLength={1}
                formatError={errors.convertAmountRule}
              />
            </div>
            %.{' '}
          </div>
          <div style={{ marginLeft: 10, marginTop: 10, fontSize: 16 }}>
            {convertAmountRule > 0
              ? msgHTML(
                  'placeSettings.conversionExample',
                  `For spendings of 100 RON, users will receive ${convertAmountRule} coins`,
                  {
                    amount: convertAmountRule + ''
                  }
                )
              : msg('placeSettings.conversionExample2', 'Users will not receive any coins.')}
          </div>
          <div style={{ display: 'flex', marginLeft: 10, marginTop: 10, fontSize: 16 }}>
            <div style={{ marginRight: 5 }}>{msg('receipts.enableReceipts', 'Enable receipts')}</div>
            <Tooltip
              title={!enableToggle ? msg('receipts.convertRequired', 'You must configure a conversion rule first') : ''}
            >
              <div>
                <Toggle
                  onChange={handleToggleChange}
                  checked={allowReceipts && enableToggle}
                  disabled={!enableToggle}
                />
              </div>
            </Tooltip>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={toggleSettingsModal}>
            {msg('general.close', 'Close')}
          </Button>
          <ButtonComponent
            styles={{ marginTop: 0 }}
            label={msg('general.save', 'Save')}
            icon="save"
            action={saveReceiptSettings}
            disabled={!modalIsDirty}
          />
        </Modal.Footer>
      </Modal>
    );
  };

  const exportModalConfig = {
    title: msg('exportExcel.exportExcelReceipts', 'Export place receipts'),
    pending: props.config.export_receipts_pending,
    error: props.config.export_receipts_error,
    toggleModal: toggleExportModal,
    exportInExcel: exportInExcel
  };

  return (
    ((props.config.receipt_list || !props.config.receipt_list_pending) && (
      <CardComponent
        title={msg('cardTitle.receipts', 'Receipts')}
        error={!!props.config.receipt_list_error}
        headerIcon="receipt"
        needsTitle={true}
        buttons={getButtons()}
      >
        {showSettingsModal && !props.forSAdmin && renderReceiptSettingsModal()}
        {props.config.place?.featureFlags.exportInExcel && showExportModal && (
          <ExportModalComponent config={exportModalConfig} />
        )}
        <AdvancedListComponent
          list={receipts}
          fields={getFields()}
          sort={{
            fields: ['created', 'user', 'placeName'],
            default: 'created,-1'
          }}
          pending={props.config.receipt_list_pending}
          error={props.config.receipt_list_error}
          search={getSearch()}
          limit={10}
          get={getReceipts}
          actions={getActions()}
          filters={[
            {
              field: 'status',
              value: [
                {
                  _id: '',
                  name: msg('receipts.all', 'All receipts')
                },
                {
                  _id: '0',
                  name: msg('receipts.pending', 'Pending')
                },
                {
                  _id: '1',
                  name: msg('receipts.approved', 'Approved')
                },
                {
                  _id: '2',
                  name: msg('receipts.fraud', 'Fraud')
                }
              ],
              default: '0'
            }
          ]}
          images={[
            {
              name: msg('receipts.receiptPhoto', 'Receipt'),
              field: 'media'
            }
          ]}
          input={[
            {
              name: msg('receipts.amount', 'Amount'),
              field: 'amount',
              label: msg('receipts.amountRon', 'RON'),
              validate: true,
              errorText: msg('receipts.incorrectAmount', 'Incorrect amount'),
              readOnly: checkIfReadOnly,
              onChange: handleChangeAmount,
              onBlur: handleSaveAmount
            }
          ]}
        />
      </CardComponent>
    )) || (
      <div className="w-screen h-screen flex items-center justify-center">
        <Bounce color={colors.lightGreen} />
      </div>
    )
  );
};
