import classnames from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { colors } from '../../../../../assets/styles';
import { msg } from '../../../../../utils';
import { authRoutes } from '../../../../layouts';
import styles from '../styles/Auth.module.css';

interface IState {
  showCard: boolean;
  headerUp: boolean;
  visible: boolean;
}

interface IProps {}

export default class SentActivationEmailComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showCard: false,
      headerUp: false,
      visible: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showCard: true });
      // tslint:disable-next-line:align
    }, 800);
    setTimeout(() => {
      this.setState({ visible: true });
      // tslint:disable-next-line:align
    }, 1000);
  }

  render() {
    let cardClasses = classnames(`card card-login ${styles.card}`, { 'card-hidden': !this.state.showCard });
    let headerClasses = classnames(`card-header text-center ${styles.header}`, {
      [styles.up]: this.state.headerUp
    });

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form>
              <div className={cardClasses}>
                <div className={headerClasses} data-background-color="green">
                  <h4 className="card-title">{msg('accountActivationScreen.emailSent', 'Activation email sent')}</h4>
                </div>
                <div className={`card-content ${styles.content}`}>
                  <div className="form-group" style={{ marginLeft: 20, marginTop: -5, textAlign: 'center' }}>
                    {msg(
                      'accountActivationScreen.sentEmailMessage',
                      // tslint:disable-next-line:max-line-length
                      `An activation email has been sent to the address! Follow the given instruction to activate your account and start using the ${process.env.REACT_APP_NAME} app.`,
                      { name: process.env.REACT_APP_NAME || 'ReGage' }
                    )}
                  </div>
                  <div
                    className="form-group"
                    style={{ marginLeft: 20, marginTop: -5, textAlign: 'center', marginBottom: -10 }}
                  >
                    {msg('general.thankYou', 'Thank you!')}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3" style={{ marginTop: -15 }}>
            <div style={{ visibility: this.state.visible ? 'visible' : 'hidden' }}>
              <div className="col-sm-4" style={{ textAlign: 'center', padding: 0 }}>
                <div className="card-actions">
                  <NavLink to="/auth" exact={true}>
                    <p style={{ color: colors.white, fontSize: 16 }}>{msg('login.title', 'Log in')}</p>
                  </NavLink>
                </div>
              </div>
              <div className="col-sm-4" />
              <div className="col-sm-4" style={{ textAlign: 'center', padding: 0, paddingRight: 13 }}>
                <div className="card-actions">
                  <NavLink to={authRoutes.FORGOT_PASSWORD} exact={true}>
                    <p style={{ color: colors.white, fontSize: 16 }}>
                      {msg('passwords.forgotPassword', 'Forgot password')}
                    </p>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
