import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import { AppStore, PackagesStore } from '../../../appRedux';
import { IPackage, PaginationDTO } from '../../../appRedux/types';
import { colors } from '../../../assets/styles';
import { AlertUtil, msg } from '../../../utils';
import { CardComponent, LoadingOverlay } from '../../shared';

interface IPackageEntryProps {
  packageEntry: IPackage;
  onEdit: (packageId: string) => void;
  onDelete: (packageId: string) => void;
}
const PackageEntry = (props: IPackageEntryProps) => {
  const { packageEntry, onEdit, onDelete } = props;
  const backgroundColor = packageEntry.comingSoon ? colors.green : colors.red;
  return (
    <tr key={packageEntry._id}>
      <td>{packageEntry.name}</td>
      <td>
        {packageEntry.moments.day} / {msg('intervalTypes.day', 'Day')}
        <br />
        {packageEntry.moments.week} / {msg('intervalTypes.week', 'Week')}
        <br />
        {packageEntry.moments.month} / {msg('intervalTypes.month', 'Month')}
      </td>
      <td>{packageEntry.priority}</td>
      <td>
        <div className="badge" style={{ backgroundColor }}>
          {packageEntry.comingSoon ? 'YES' : 'NO'}
        </div>
      </td>
      <td className="td-actions text-left">
        <button
          key={'edit-action'}
          type="button"
          data-rel="tooltip"
          className={`btn btn-success`}
          title={msg('general.edit', 'Edit')}
          onClick={() => onEdit(packageEntry._id)}
        >
          <i className="material-icons">edit</i>
        </button>
        <button
          key={'delete-action'}
          type="button"
          data-rel="tooltip"
          className={`btn btn-danger`}
          title={msg('general.delete', 'Delete')}
          onClick={() => onDelete(packageEntry._id)}
        >
          <i className="material-icons">delete</i>
        </button>
      </td>
    </tr>
  );
};

interface IPackagesListProps {
  packages: IPackage[] | undefined;

  onDelete: (packageId: string) => void;
}
const PackagesList = (props: IPackagesListProps) => {
  const history = useHistory();
  const { packages, onDelete } = props;

  if (!packages) {
    return null;
  }

  const onEdit = (packageId: string) => {
    history.push(`/admin/packages/edit/${packageId}`);
  };

  return (
    <table className="table table-hover">
      <thead className="text-primary">
        <tr>
          <th>{msg('package.name', 'Package name')}</th>
          <th>{msg('package.maxMoments', 'Maximum moments')}</th>
          <th>{msg('package.priority', 'Priority')}</th>
          <th>{msg('package.comingSoon', 'Coming soon')}</th>
          <th>{msg('general.actions', 'Actions')}</th>
        </tr>
      </thead>
      <tbody>
        {packages.map(p => (
          <PackageEntry key={p._id} packageEntry={p} onEdit={onEdit} onDelete={onDelete} />
        ))}
      </tbody>
    </table>
  );
};

interface IListPackagesComponentStateProps {
  packagesList: PaginationDTO<IPackage>;
  pending: boolean;
}
interface IListPackagesComponentActionsProps {
  getPackagesListAction: (options?: any) => void;
  removePackageAction: (packageId: string) => void;
}
type IListPackagesComponentProps = IListPackagesComponentStateProps & IListPackagesComponentActionsProps;

const ListPackagesComponent = (props: IListPackagesComponentProps) => {
  const history = useHistory();
  const { packagesList, pending } = props;
  const { getPackagesListAction, removePackageAction } = props;

  useEffect(() => {
    getPackagesListAction();
    // tslint:disable-next-line:align
  }, []);

  const onCreateClick = () => {
    history.push(`/admin/packages/create`);
  };
  const onDeleteClick = async (packageId: string) => {
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.packageDeleteConfirmation', 'You are about to delete a package!')
    );

    if (hasConfirmation) {
      removePackageAction(packageId);
    }
  };

  return (
    <CardComponent title={msg('sidebar.packages', 'Packages')} headerIcon="card_giftcard" needsTitle={true}>
      {pending && <LoadingOverlay />}
      <PackagesList packages={packagesList.results} onDelete={onDeleteClick} />
      <button className="btn btn-success" style={{ padding: '8px' }} onClick={onCreateClick}>
        <i className="material-icons" style={{ marginRight: '4px' }}>
          add_circle
        </i>
        {msg('cardTitle.createPackage', 'Create package')}
      </button>
    </CardComponent>
  );
};

const mapStateToProps = (state: AppStore.states): IListPackagesComponentStateProps => ({
  packagesList: state.packages.packages_list,
  pending: state.packages.packages_list_pending
});
const dispatchToProps: IListPackagesComponentActionsProps = {
  getPackagesListAction: PackagesStore.actions.getPackagesListAction,
  removePackageAction: PackagesStore.actions.removePackageAction
};

export const ListPackagesContainer = withRouter(connect(mapStateToProps, dispatchToProps)(ListPackagesComponent));
