import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { AppStore, IndustriesStore, reduxContainer } from '../../../appRedux';
import { IndustryEditComponent, IndustriesListComponent } from './components';
import { adminRoutes } from '../../layouts';

interface IProps extends IndustriesStore.IState {}
interface IState {}

class IndustriesContainer extends React.Component<IProps & IndustriesStore.IActions, IState> {
  render() {
    const industryEditProps = {
      pending: this.props.industry_save_pending,
      error: this.props.industry_save_error,
      loaded: this.props.industry,
      load_pending: this.props.industry_pending,
      load_error: this.props.industry_error,
      add: this.props.addIndustryAction,
      get: this.props.getIndustryAction,
      edit: this.props.editIndustryAction
    };

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Switch>
              <Route exact={true} path={adminRoutes.INDUSTRIES.path} render={() => <IndustriesListComponent />} />
              <Route
                path={adminRoutes.INDUSTRIES.subroutes.ADD.path}
                render={({ match }) => (
                  <IndustryEditComponent match={match} editMode={false} config={industryEditProps} />
                )}
              />
              <Route
                path={adminRoutes.INDUSTRIES.subroutes.EDIT.path}
                render={({ match }) => (
                  <IndustryEditComponent match={match} editMode={true} config={industryEditProps} />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    industry: state.industries.industry,
    industry_pending: state.industries.industry_pending,
    industry_error: state.industries.industry_error,
    industry_save_pending: state.industries.industry_save_pending,
    industry_save_error: state.industries.industry_save_error
  };
}

const dispatchToProps = {
  getIndustryAction: IndustriesStore.actions.getIndustryAction,
  addIndustryAction: IndustriesStore.actions.addIndustryAction,
  editIndustryAction: IndustriesStore.actions.editIndustryAction
};

export default reduxContainer(IndustriesContainer, mapStateToProps, dispatchToProps);
