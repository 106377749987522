import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  AppStore,
  PlacesStore,
  PartnersStore,
  reduxContainer,
  PackagesStore,
  OnboardStore,
  UsersStore
} from '../../../appRedux';
import { PlacesEditComponent } from './components';
import { IPackage, PlaceDTO, UserDTO } from '../../../appRedux/types';
import { partnerRoutes, adminRoutes } from '../../layouts';

interface IProps extends PlacesStore.IState, PartnersStore.IState, OnboardStore.IState {
  availablePackages: IPackage[];
  place_admins_list: Array<UserDTO>;
  fetchAvailablePackages: () => any;
  changePlacePackage: (place: PlaceDTO) => any;
  getAdminsForPlaceAction: (placeId: string, includePartner?: boolean) => any;
}
interface IState {}

class PlacesContainer extends React.Component<
  IProps & PlacesStore.IActions & PartnersStore.IActions & OnboardStore.IActions & IState
> {
  render() {
    const placesEditProps = {
      pending: this.props.place_save_pending,
      place_save_error: this.props.place_save_error,
      loaded: this.props.place,
      load_pending: this.props.place_pending,
      load_error: this.props.place_error,
      place_admins_list: this.props.place_admins_list,
      add: this.props.addPlaceAction,
      get: this.props.getPlaceAction,
      edit: this.props.editPlaceAction,
      editForPartner: this.props.editPlaceForPartnerAction,
      deleteMedia: this.props.deletePlaceMedia,
      getPartner: this.props.getPartnerAction,
      loadedPartner: this.props.partner,
      availablePackages: this.props.availablePackages,
      fetchAvailablePackages: this.props.fetchAvailablePackages,
      changePlacePackage: this.props.changePlacePackage,
      alternative_address_flag: this.props.alternative_address_flag,
      getFlagsForOnboarding: this.props.getFlagsForOnboarding,
      resetPinCode: this.props.resetPinCode,
      getAdminsForPlaceAction: this.props.getAdminsForPlaceAction,
      redirectToPlaceAdminsAction: this.props.redirectToPlaceAdminsAction
    };
    const partnerId = this.props.selected_partner;
    return (
      <Switch>
        <Route
          path={adminRoutes.PLACES.subroutes.ADD.path}
          render={({ match }) => (
            <PlacesEditComponent
              match={match}
              editMode={false}
              config={placesEditProps}
              partnerId={partnerId}
              forSAdmin={true}
            />
          )}
        />
        <Route
          path={adminRoutes.PLACES.subroutes.EDIT.path}
          render={({ match }) => (
            <PlacesEditComponent
              match={match}
              editMode={true}
              config={placesEditProps}
              partnerId={partnerId}
              forSAdmin={true}
            />
          )}
        />
        <Route
          path={partnerRoutes.PLACE.default}
          render={({ match }) => (
            <PlacesEditComponent
              match={match}
              editMode={true}
              config={placesEditProps}
              partnerId={partnerId}
              forSAdmin={false}
            />
          )}
        />
        <Redirect from="*" to={`/partner/places/edit/${this.props.selected_place}`} />
      </Switch>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    place: state.places.place,
    place_pending: state.places.place_pending,
    place_error: state.places.place_error,
    place_save_pending: state.places.place_save_pending,
    place_save_error: state.places.place_save_error,
    place_admins_list: state.users.place_admins_list,
    partner: state.partners.partner,
    selected_partner: state.partners.selected_partner,
    selected_place: state.places.selected_place,
    availablePackages: state.packages?.packages_list.results || [],
    alternative_address_flag: state.onboard.alternative_address_flag
  };
}

const dispatchToProps = {
  getPlaceAction: PlacesStore.actions.getPlaceAction,
  addPlaceAction: PlacesStore.actions.addPlaceAction,
  editPlaceAction: PlacesStore.actions.editPlaceAction,
  editPlaceForPartnerAction: PlacesStore.actions.editPlaceForPartnerAction,
  resetPinCode: PlacesStore.actions.resetPinCode,
  deletePlaceMedia: PlacesStore.actions.deletePlaceMedia,
  getPartnerAction: PartnersStore.actions.getPartnerAction,
  fetchAvailablePackages: PackagesStore.actions.getPackagesListAction,
  changePlacePackage: PlacesStore.actions.editPlaceAction,
  getFlagsForOnboarding: OnboardStore.actions.getFlagsForOnboarding,
  getAdminsForPlaceAction: UsersStore.actions.getAdminsForPlaceAction,
  redirectToPlaceAdminsAction: PlacesStore.actions.redirectToPlaceAdminsAction
};

export default reduxContainer(PlacesContainer, mapStateToProps, dispatchToProps);
