import 'moment/locale/ro';

import moment from 'moment';
import { Dispatch } from 'redux';

import { loadLocales } from '../../utils';
import { TranslationsStore } from './';

/*
  ITranslationsActions interface definition, which contains every redux action asociated with Translations State.
*/
export interface ITranslationsActions {
  changeLanguageAction(option: string): any;
  init(): any;
}

/*
  class TranslationsActions that implements redux actions defined in ITranslationsActions interface
*/
class TranslationsActions implements ITranslationsActions {
  /*
    @function changeLanguageAction => Redux action that changes the language of the app (saved only in redux)
      @accepts option : string representing the selected language to overwrite old language
      @returns Promise
  */
  changeLanguageAction(option: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: TranslationsStore.ActionTypes.CHANGE_LANGUAGE,
        payload: option
      });
      dispatch(TranslationsStore.actions.init());
    };
  }

  /*
    @function init => Redux action that initialises the translation API with necessary locale and initial language
      @returns Promise
  */
  init() {
    return (dispatch: Dispatch<any>, getStore: any) => {
      dispatch({
        type: TranslationsStore.ActionTypes.INIT
      });
      const appState = getStore();
      const language = appState.translations.initial_language;
      moment.locale('en', {
        week: {
          dow: 1
        }
      });
      loadLocales(language)
        .then((data: any) => {
          dispatch({
            type: TranslationsStore.ActionTypes.INIT_SUCCESS
          });
        })
        .catch((error: any) => {
          dispatch({
            type: TranslationsStore.ActionTypes.INIT_FAILED
          });
        });
    };
  }
}

const translationsActions = new TranslationsActions();
export default translationsActions;
