import moment from 'moment';
import * as React from 'react';

import {
    Popover, PopoverInteractionKind, PopoverPosition, PopperBoundary, PopperModifiers
} from '@blueprintjs/core';

import { msg } from '../../utils';
import { RangePickerComponent } from './RangePickerComponent';

interface IState {
  boundary?: PopperBoundary;
  canEscapeKeyClose?: boolean;
  exampleIndex?: number;
  hasBackdrop?: boolean;
  inheritDarkTheme?: boolean;
  interactionKind?: PopoverInteractionKind;
  isOpen?: boolean;
  minimal?: boolean;
  modifiers?: PopperModifiers;
  position?: PopoverPosition;
  usePortal?: boolean;
  dateRange: any;
}

interface IProps {
  style?: any;
  customInputClass?: string;
  defaultValue: any;
  getRangeData: (startDate: any, endDate: any) => any;
}

export class DateRangePicker extends React.PureComponent<IProps, IState> {
  public state: IState = {
    boundary: 'viewport',
    canEscapeKeyClose: true,
    hasBackdrop: false,
    inheritDarkTheme: true,
    interactionKind: PopoverInteractionKind.CLICK,
    isOpen: false,
    minimal: false,
    modifiers: {
      arrow: { enabled: true },
      flip: { enabled: true },
      keepTogether: { enabled: true },
      preventOverflow: { enabled: true }
    },
    position: 'bottom',
    usePortal: true,
    dateRange: []
  };

  public getDateRange = (startDate: any, endDate: any) => {
    const start = moment(startDate).format('l');
    const end = moment(endDate).format('l');

    if (startDate && endDate) {
      this.setState({ dateRange: [start, end] });
      this.props.getRangeData(start, end);
      this.setState({ isOpen: false });
    }
  };

  handleInteraction(nextOpenState: boolean) {
    this.setState({ isOpen: nextOpenState });
  }

  public render() {
    const { ...popoverProps } = this.state;
    let value = '';
    if (this.state.dateRange[0] && this.state.dateRange[1]) {
      value = `${this.state.dateRange[0]} - ${this.state.dateRange[1]}`;
    } else if (this.props.defaultValue && this.props.defaultValue[0] && this.props.defaultValue[1]) {
      value = `${moment(this.props.defaultValue[0]).format('l')} - ${moment(this.props.defaultValue[1]).format('l')}`;
    }
    return (
      <div className="docs-popover-example-scroll" style={this.props.style}>
        <Popover
          portalClassName="dateRangePicker"
          {...popoverProps}
          enforceFocus={false}
          isOpen={this.state.isOpen}
          onInteraction={state => this.handleInteraction(state)}
        >
          <div className="bp4-input-group .modifier">
            <span className="bp4-icon bp4-icon-calendar" />
            <input
              className={`bp4-input ${this.props.customInputClass || ''}`}
              type="text"
              placeholder={msg('general.selectDate', 'Select date')}
              dir="auto"
              style={{ cursor: 'pointer' }}
              value={value}
              readOnly={true}
            />
          </div>
          {this.getContents()}
        </Popover>
      </div>
    );
  }

  private getContents = () => (
    <div key="text">
      <RangePickerComponent defaultValue={this.props.defaultValue} getRangeData={this.getDateRange} />
    </div>
  );
}
