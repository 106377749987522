import eventsActions, { IEventsActions } from './actions';
import eventsReducer from './reducer';
import { EventDTO, PaginationDTO, RaffleIntervalDTO, SettlementDTO } from '../types';

/*
  EventsStore definition as a namespace that contains actions, reducers, actionTypes and events state
*/
export namespace EventsStore {
  export type IState = {
    events_list: PaginationDTO<any>;
    events_list_pending: boolean;
    events_list_error: string | null;
    event: EventDTO | null;
    event_pending: boolean;
    event_error: string | null;
    event_save_pending: boolean;
    event_save_error: string | null;
    event_deleted: boolean;
    event_delete_pending: boolean;
    event_delete_error: string | null;
    event_change_status_pending: boolean;
    event_change_status_error: boolean;
    export_events_pending: boolean;
    export_events_error: any;
    campaign_members: PaginationDTO<any>;
    campaign_members_pending: boolean;
    campaign_members_error: string | null;
    partner_settlements: PaginationDTO<SettlementDTO>;
    partner_settlements_pending: boolean;
    partner_settlements_error: string | null;
    settlement: SettlementDTO | null;
    edit_settlement_pending: boolean;
    edit_settlement_error: string | null;
    send_settlement_email_pending: boolean;
    send_settlement_email_error: string | null;
    approve_settlement_email_pending: boolean;
    approve_settlement_email_error: string | null;
    send_all_emails_pending: boolean;
    send_all_emails_error: string | null;
    download_settlements_pending: boolean;
    download_settlements_error: string | null;
    raffle_preview: RaffleIntervalDTO[] | null;
    raffle_preview_pending: boolean;
    raffle_preview_error: string | null;
    update_raffle_preview_pending: boolean;
    update_raffle_preview_error: string | null;
  };

  export const initialState: IState = {
    events_list: new PaginationDTO(),
    events_list_pending: false,
    events_list_error: null,
    event: null,
    event_pending: false,
    event_error: null,
    event_save_pending: false,
    event_save_error: null,
    event_change_status_pending: false,
    event_change_status_error: false,
    event_deleted: false,
    event_delete_pending: false,
    event_delete_error: null,
    export_events_pending: false,
    export_events_error: null,
    campaign_members: new PaginationDTO(),
    campaign_members_pending: false,
    campaign_members_error: null,
    partner_settlements: new PaginationDTO(),
    partner_settlements_pending: false,
    partner_settlements_error: null,
    settlement: null,
    edit_settlement_pending: false,
    edit_settlement_error: null,
    send_settlement_email_pending: false,
    send_settlement_email_error: null,
    approve_settlement_email_pending: false,
    approve_settlement_email_error: null,
    send_all_emails_pending: false,
    send_all_emails_error: null,
    download_settlements_pending: false,
    download_settlements_error: null,
    raffle_preview: null,
    raffle_preview_pending: false,
    raffle_preview_error: null,
    update_raffle_preview_pending: false,
    update_raffle_preview_error: null
  };

  export enum ActionTypes {
    GET_EVENTS = 'GET_EVENTS',
    GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS',
    GET_EVENTS_FAILED = 'GET_EVENTS_FAILED',
    GET_EVENT = 'GET_EVENT',
    GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS',
    GET_EVENT_FAILED = 'GET_EVENT_FAILED',
    SAVE_EVENT = 'SAVE_EVENT',
    SAVE_EVENT_SUCCESS = 'SAVE_EVENT_SUCCESS',
    SAVE_EVENT_FAILED = 'SAVE_EVENT_FAILED',
    DELETE_EVENT = 'DELETE_EVENT',
    DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED',
    CHANGE_EVENT_STATUS = 'CHANGE_EVENT_STATUS',
    CHANGE_EVENT_STATUS_SUCCESS = 'CHANGE_EVENT_STATUS_SUCCESS',
    CHANGE_EVENT_STATUS_FAILED = 'CHANGE_EVENT_STATUS_FAILED',
    CLEAR_EVENTS_ERROR = 'CLEAR_EVENTS_ERROR',
    EXPORT_EVENTS = 'EXPORT_MEMBERS',
    EXPORT_EVENTS_SUCCESS = 'EXPORT_MEMBERS_SUCCESS',
    EXPORT_EVENTS_FAILED = 'EXPORT_MEMBERS_FAILED',
    GET_CAMPAIGN_MEMBERS = 'GET_CAMPAIGN_MEMBERS',
    GET_CAMPAIGN_MEMBERS_SUCCESS = 'GET_CAMPAIGN_MEMBERS_SUCCESS',
    GET_CAMPAIGN_MEMBERS_FAILED = 'GET_CAMPAIGN_MEMBERS_FAILED',
    PARTNER_SETTLEMENTS = 'PARTNER_SETTLEMENTS',
    PARTNER_SETTLEMENTS_SUCCESS = 'PARTNER_SETTLEMENTS_SUCCESS',
    PARTNER_SETTLEMENTS_FAILED = 'PARTNER_SETTLEMENTS_FAILED',
    EDIT_SETTLEMENT = 'EDIT_SETTLEMENT',
    EDIT_SETTLEMENT_SUCCESS = 'EDIT_SETTLEMENT_SUCCESS',
    EDIT_SETTLEMENT_FAILED = 'EDIT_SETTLEMENT_FAILED',
    SEND_SETTLEMENT_EMAIL = 'SEND_SETTLEMENT_EMAIL',
    SEND_SETTLEMENT_EMAIL_SUCCESS = 'SEND_SETTLEMENT_EMAIL_SUCCESS',
    SEND_SETTLEMENT_EMAIL_FAILED = 'SEND_SETTLEMENT_EMAIL_FAILED',
    APPROVE_SETTLEMENT_EMAIL = 'APPROVE_SETTLEMENT_EMAIL',
    APPROVE_SETTLEMENT_EMAIL_SUCCESS = 'APPROVE_SETTLEMENT_EMAIL_SUCCESS',
    APPROVE_SETTLEMENT_EMAIL_FAILED = 'APPROVE_SETTLEMENT_EMAIL_FAILED',
    SEND_ALL_EMAILS = 'SEND_ALL_EMAILS',
    SEND_ALL_EMAILS_SUCCESS = 'SEND_ALL_EMAILS_SUCCESS',
    SEND_ALL_EMAILS_FAILED = 'SEND_ALL_EMAILS_FAILED',
    DOWNLOAD_SETTLEMENTS_PDF = 'DOWNLOAD_SETTLEMENTS_PDF',
    DOWNLOAD_SETTLEMENTS_PDF_SUCCESS = 'DOWNLOAD_SETTLEMENTS_PDF_SUCCESS',
    DOWNLOAD_SETTLEMENTS_PDF_FAILED = 'DOWNLOAD_SETTLEMENTS_PDF_FAILED',
    GET_RAFFLE_PREVIEW = 'GET_RAFFLE_PREVIEW',
    GET_RAFFLE_PREVIEW_SUCCESS = 'GET_RAFFLE_PREVIEW_SUCCESS',
    GET_RAFFLE_PREVIEW_FAILED = 'GET_RAFFLE_PREVIEW_FAILED',
    UPDATE_RAFFLE_INTERVALS = 'UPDATE_RAFFLE_INTERVALS',
    UPDATE_RAFFLE_INTERVALS_SUCCESS = 'UPDATE_RAFFLE_INTERVALS_SUCCESS',
    UPDATE_RAFFLE_INTERVALS_FAILED = 'UPDATE_RAFFLE_INTERVALS_FAILED',
    CLEAR_EVENT_DATA = 'CLEAR_EVENT_DATA',
    CLEAR_MODAL_DATA = 'CLEAR_MODAL_DATA',
    CLEAR_RAFFLE_DATA = 'CLEAR_RAFFLE_DATA'
  }

  export const actions = eventsActions;
  export const reducer = eventsReducer;
  export interface IActions extends IEventsActions {}
}

export * from './actions';
