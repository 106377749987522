import React from 'react';
import Geosuggest, { Suggest } from 'react-geosuggest';
import FormHelperText from '@material-ui/core/FormHelperText';

import { msg } from '../../../utils';

interface IProps {
  setValues: (lat: any, lng: any, additionalData?: any) => any;
  error: any;
  value: string;
}

export default class GeoSuggestInput extends React.Component<IProps, {}> {
  onSuggestSelect = (place: Suggest) => {
    if (place) {
      const {
        location: { lat, lng }
      } = place;
      const { setValues } = this.props;
      setValues(lat, lng, place);
    }
  };

  render() {
    return (
      <div>
        <Geosuggest
          placeholder={msg('place.address', 'Address *')}
          initialValue={this.props.value}
          onSuggestSelect={this.onSuggestSelect}
          location={new google.maps.LatLng(53.558572, 9.9278215)}
          radius={20}
        />
        {this.props.error && (
          <FormHelperText id="inputText" error={true}>
            {this.props.error}
          </FormHelperText>
        )}
      </div>
    );
  }
}
