import * as React from 'react';
import { msg, Validator, IValidator } from '../../../../utils';
import { TextFieldComponent, CardComponent } from '../../../shared';
import { Step3SettingsDTO, Step3FlagsDTO, RewardDTO } from '../../../../appRedux/types';
import { FormControlLabel, Radio, RadioGroup, Tooltip } from '@material-ui/core';
import { RewardsListComponent } from '../../rewards/components';

interface IState {}

interface IProps {
  forwardRef: any;
  settings: {
    placeSettings: Step3SettingsDTO;
    placeFeatureFlags: Step3FlagsDTO;
    placeRewards: Array<RewardDTO>;
  };
  extraId: string;
  handleChange: (event: any, key?: string) => any;
}

class SettingsComponent extends React.Component<IProps & IValidator, IState> {
  handleRadioChange = (event: any) => {
    const key = event.target.name;
    const value = event.target.value;
    let { placeFeatureFlags, placeSettings, placeRewards } = this.props.settings;
    placeFeatureFlags[key] = value === 'true';
    if (value === 'false') {
      switch (key) {
        case 'becomeMember':
          // tslint:disable-next-line: max-line-length
          placeFeatureFlags.checkin = placeFeatureFlags.useCoins = placeFeatureFlags.sendReceipts = placeFeatureFlags.simpleAutopilots = false;
          placeFeatureFlags.moments = undefined;
          // tslint:disable-next-line: max-line-length
          placeSettings.memberRegistration = placeSettings.pointsForCheckin = placeSettings.convertAmountRule = undefined;
          placeRewards = [];
          break;
        case 'checkin':
          placeFeatureFlags.sendReceipts = placeFeatureFlags.useCoins = placeFeatureFlags.simpleAutopilots = false;
          placeFeatureFlags.moments = undefined;
          placeSettings.pointsForCheckin = placeSettings.convertAmountRule = undefined;
          placeRewards = [];
          break;
        case 'sendReceipts':
          placeFeatureFlags.moments = undefined;
          placeSettings.convertAmountRule = undefined;
          break;
        case 'moments':
          placeFeatureFlags.news = false;
          break;
        default:
          break;
      }
    } else if (value === 'true') {
      switch (key) {
        case 'becomeMember':
          placeFeatureFlags.checkin = placeFeatureFlags.sendReceipts = placeFeatureFlags.moments = undefined;
          // tslint:disable-next-line: max-line-length
          placeSettings.memberRegistration = placeSettings.pointsForCheckin = placeSettings.convertAmountRule = undefined;
          break;
        case 'checkin':
          placeFeatureFlags.sendReceipts = placeFeatureFlags.moments = undefined;
          placeFeatureFlags.simpleAutopilots = true;
          placeSettings.pointsForCheckin = placeSettings.convertAmountRule = undefined;
          break;
        case 'sendReceipts':
          placeFeatureFlags.moments = undefined;
          placeSettings.convertAmountRule = undefined;
          break;
        case 'moments':
          placeFeatureFlags.news = true;
          break;
        default:
          break;
      }
    }
    this.props.handleChange({ placeFeatureFlags, placeSettings, placeRewards });
  };

  handleChange = (event: any) => {
    const key = event.target.getAttribute('id');
    const value = event.target.value;
    const placeSettings = this.props.settings.placeSettings;
    if (this.props.validator) {
      const { isDirty } = this.props.validator!;
      if (isDirty) {
        isDirty(key);
      }
    }
    placeSettings[key] = value;
    this.props.handleChange(placeSettings, 'placeSettings');
  };

  handleRewardChange = (value: any) => {
    const placeRewards = value;
    this.props.handleChange(placeRewards, 'placeRewards');
  };

  render() {
    const { errors } = this.props.validator!;
    const { placeSettings, placeFeatureFlags, placeRewards } = this.props.settings;
    const showMomentsFlag =
      placeFeatureFlags.becomeMember === false ||
      (placeFeatureFlags.becomeMember &&
        (placeFeatureFlags.checkin === false ||
          placeFeatureFlags.sendReceipts === false ||
          (placeFeatureFlags.sendReceipts && placeSettings.convertAmountRule !== undefined)));
    return (
      <CardComponent
        title={msg('cardTitle.programSettings', 'Program Settings')}
        pending={false}
        error={false}
        headerIcon="settings"
        needsTitle={true}
      >
        <div style={styles.row}>
          <div className="col-sm-6">
            {msg('onboard.membershipFeature', 'Do you want to activate the membership feature?')}
          </div>
          <div className="col-sm-2" style={{ marginLeft: 5 }}>
            <RadioGroup
              name="becomeMember"
              onChange={this.handleRadioChange}
              value={placeFeatureFlags.becomeMember ?? ''}
              row={true}
              style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label={msg('general.yes', 'Yes')}
                style={{ width: 'auto', color: 'black' }}
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label={msg('general.no', 'No')}
                style={{ width: 'auto', color: 'black' }}
              />
            </RadioGroup>
          </div>
        </div>
        {placeFeatureFlags.becomeMember && (
          <div>
            <hr style={styles.line} />
            <div style={styles.row}>
              <div className="col-sm-6">
                {msg(
                  'onboard.memberRegistration',
                  'How many coins do you want a client to receive when they become member?'
                )}
              </div>
              <div className="col-sm-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextFieldComponent
                    id="memberRegistration"
                    value={placeSettings.memberRegistration !== undefined ? placeSettings.memberRegistration! + '' : ''}
                    onChange={this.handleChange}
                    inType="text"
                    minLength={1}
                    maxLength={4}
                    required={false}
                    style={styles.input}
                    inputStyle={{ paddingLeft: 5 }}
                    formatError={errors.memberRegistration}
                    ref="memberRegistration"
                    validator={[
                      {
                        type: 'isInt',
                        msg: msg('formValidation.onlyPositiveNumbers', 'Use only positive numbers!'),
                        params: { min: 0 }
                      },
                      {
                        type: 'isLength',
                        msg: msg('formValidation.fieldRequired', 'Field required!'),
                        params: { min: 1 }
                      }
                    ]}
                  />
                  <span style={{ marginLeft: 5 }}>{msg('general.coins', 'coins')}</span>
                </div>
              </div>
            </div>
            {placeSettings.memberRegistration !== undefined && (
              <>
                <hr style={styles.line} />
                <div style={styles.row}>
                  <div className="col-sm-6">
                    {msg('onboard.checkinFeature', 'Do you want to activate the check-in feature?')}
                  </div>
                  <div className="col-sm-2" style={{ marginLeft: 5 }}>
                    <RadioGroup
                      name="checkin"
                      onChange={this.handleRadioChange}
                      value={placeFeatureFlags.checkin ?? ''}
                      row={true}
                      style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        label={msg('general.yes', 'Yes')}
                        style={{ width: 'auto', color: 'black' }}
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        label={msg('general.no', 'No')}
                        style={{ width: 'auto', color: 'black' }}
                      />
                    </RadioGroup>
                  </div>
                </div>
              </>
            )}
            {placeFeatureFlags.checkin && (
              <>
                <hr style={styles.line} />
                <div style={styles.row}>
                  <div className="col-sm-6">
                    {msg(
                      'onboard.pointsPerCheckin',
                      'How many coins do you want a client to receive for each visit to your place?'
                    )}
                  </div>
                  <div className="col-sm-2">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <TextFieldComponent
                        id="pointsForCheckin"
                        value={placeSettings.pointsForCheckin !== undefined ? placeSettings.pointsForCheckin + '' : ''}
                        onChange={this.handleChange}
                        inType="text"
                        minLength={1}
                        maxLength={4}
                        required={false}
                        style={styles.input}
                        inputStyle={{ paddingLeft: 5 }}
                        formatError={errors.pointsForCheckin}
                        ref="pointsForCheckin"
                        validator={[
                          {
                            type: 'isInt',
                            msg: msg('formValidation.onlyPositiveNumbers', 'Use only positive numbers!'),
                            params: { min: 0 }
                          },
                          {
                            type: 'isLength',
                            msg: msg('formValidation.fieldRequired', 'Field required!'),
                            params: { min: 1 }
                          }
                        ]}
                      />
                      <span style={{ marginLeft: 5 }}>{msg('general.coins', 'coins')}</span>
                    </div>
                  </div>
                </div>
                {placeSettings.pointsForCheckin !== undefined && (
                  <>
                    <hr style={{ marginTop: 10, marginBottom: 15 }} />
                    <div style={styles.row}>
                      <div className="col-sm-6">
                        {msg('onboard.rewards', 'What rewards do you want to give in exchange for coins?')}
                      </div>
                    </div>
                    <div style={styles.row}>
                      <RewardsListComponent
                        forSAdmin={false}
                        forPlace={false}
                        output={true}
                        config={{ rewards_list: placeRewards }}
                        handleChange={this.handleRewardChange}
                        mediaURL={`onboarding/media/${this.props.extraId}`}
                        ref={this.props.forwardRef}
                        showLabelInfo={true}
                        cardTitle={false}
                        cardStyle={{ boxShadow: 'none', margin: 0 }}
                      />
                    </div>
                  </>
                )}
                {placeRewards.length ? (
                  <>
                    <hr style={styles.line} />
                    <div style={styles.row}>
                      <div className="col-sm-6">
                        {msg('onboard.scanFeature', 'Do you want to activate the receipt scanning feature?')}
                      </div>
                      <div className="col-sm-2" style={{ marginLeft: 5 }}>
                        <RadioGroup
                          name="sendReceipts"
                          onChange={this.handleRadioChange}
                          value={placeFeatureFlags.sendReceipts ?? ''}
                          row={true}
                          style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio color="primary" />}
                            label={msg('general.yes', 'Yes')}
                            style={{ width: 'auto', color: 'black' }}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={msg('general.no', 'No')}
                            style={{ width: 'auto', color: 'black' }}
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </>
                ) : null}
                {placeFeatureFlags.sendReceipts && (
                  <>
                    <hr style={styles.line} />
                    <div style={styles.row}>
                      <div className="col-sm-6">
                        {msg('onboard.convertAmount', 'Conversion rule')}
                        <Tooltip
                          title={msg(
                            'onboard.convertAmountTip',
                            'The percentage of the receipt amount users will receive as coins'
                          )}
                        >
                          <i className="material-icons" style={{ marginLeft: 8, fontSize: 18 }}>
                            info
                          </i>
                        </Tooltip>
                      </div>
                      <div className="col-sm-2">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <TextFieldComponent
                            id="convertAmountRule"
                            value={
                              placeSettings.convertAmountRule !== undefined ? placeSettings.convertAmountRule + '' : ''
                            }
                            onChange={this.handleChange}
                            inType="text"
                            minLength={1}
                            required={false}
                            style={styles.input}
                            inputStyle={{ paddingLeft: 5 }}
                            formatError={errors.convertAmountRule}
                            ref="convertAmountRule"
                            validator={[
                              {
                                type: 'isInt',
                                msg: msg('formValidation.onlyPositiveNumbers', 'Use only positive numbers!'),
                                params: { min: 1 }
                              },
                              {
                                type: 'isLength',
                                msg: msg('formValidation.fieldRequired', 'Field required!'),
                                params: { min: 1 }
                              }
                            ]}
                          />
                          <span style={{ marginLeft: 5 }}>%</span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
        {showMomentsFlag && (
          <>
            <hr style={styles.line} />
            <div style={styles.row}>
              <div className="col-sm-6">
                {msg('onboard.momentsFeature', 'Do you want to activate events and announcements?')}
              </div>
              <div className="col-sm-2" style={{ marginLeft: 5 }}>
                <RadioGroup
                  name="moments"
                  onChange={this.handleRadioChange}
                  value={placeFeatureFlags.moments ?? ''}
                  row={true}
                  style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio color="primary" />}
                    label={msg('general.yes', 'Yes')}
                    style={{ width: 'auto', color: 'black' }}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio color="primary" />}
                    label={msg('general.no', 'No')}
                    style={{ width: 'auto', color: 'black' }}
                  />
                </RadioGroup>
              </div>
            </div>
          </>
        )}
      </CardComponent>
    );
  }
}

export default Validator(SettingsComponent);

const styles = {
  row: {
    display: 'flex',
    marginLeft: 10,
    alignItems: 'center',
    fontSize: 16
  },
  input: {
    width: 50,
    marginLeft: 8,
    marginRight: 8
  },
  line: {
    marginTop: 10,
    marginBottom: 10
  }
};
