// tslint:disable:no-console
const options: Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

class Logger {
  msg(message: string, reqType: string) {
    const date = new Date().toLocaleDateString('en-US', options);
    console.log('\x1b[33m%s\x1b[0m', `[Logger] [${date}] [${reqType}] ${message}`);
  }

  err(message: string, reqType: string) {
    const date = new Date().toLocaleDateString('en-US', options);
    console.log('\x1b[31m%s\x1b[0m', `[Logger] [${date}] [${reqType}] Error: ${message}`);
  }

  server(message: string) {
    console.log('\x1b[34m%s\x1b[0m', '[server] ' + message);
  }
}

const logger = new Logger();
export default logger;
