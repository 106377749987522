export const colors = {
  color1: 'rgba(99, 191, 136,1)',
  color1Opac02: 'rgba(99, 191, 136,0.2)',
  color1Opac04: 'rgba(99, 191, 136,0.4)',
  color2: 'rgba(0, 89, 34,1)',
  color2Opac02: 'rgba(0, 89, 34,0.2)',
  color2Opac04: 'rgba(0, 89, 34,0.4)',
  color3: 'rgba(74, 192, 170,1)',
  color3Opac02: 'rgba(74, 192, 170,0.2)',
  color3Opac04: 'rgba(74, 192, 170,0.4)',
  color4: 'rgba(0, 129, 164,1)',
  color4Opac02: 'rgba(0, 129, 164,0.2)',
  color4Opac04: 'rgba(0, 129, 164,0.4)',
  color5: 'rgba(78, 255, 252,1)',
  color5Opac02: 'rgba(78, 255, 252,0.2)',
  color5Opac04: 'rgba(78, 255, 252,0.4)',
  color6: 'rgba(3, 124, 3,1)',
  color6Opac02: 'rgba(3, 124, 3,0.2)',
  color6Opac04: 'rgba(3, 124, 3,0.4)',
  color7: 'rgba(28, 193, 228,1)',
  color7Opac02: 'rgba(28, 193, 228,0.2)',
  color7Opac04: 'rgba(28, 193, 228,0.4)',
  color8: 'rgba(244, 200, 66, 1)',
  color8Opac02: 'rgba(244, 200, 66, 0.2)',
  color8Opac04: 'rgba(244, 200, 66, 0.4)',
  color9: 'rgba(208, 133, 221, 1)',
  color9Opac02: 'rgba(208, 133, 221, 0.2)',
  color9Opac04: 'rgba(208, 133, 221, 0.4)',
  color10: 'rgba(192, 193, 214, 1)',
  color10Opac02: 'rgba(192, 193, 214, 0.2)',
  color10Opac04: 'rgba(192, 193, 214, 0.4)',
  color11: 'rgba(142, 191, 99, 1)',
  color11Opac02: 'rgba(142, 191, 99, 0.2)',
  color11Opac04: 'rgba(142, 191, 99, 0.4)',
  color12: 'rgba(15, 41, 26, 1)',
  color12Opac02: 'rgba(15, 41, 26, 0.2)',
  color12Opac04: 'rgba(15, 41, 26, 0.4)',
  color13: 'rgba(30, 21, 138, 1)',
  color13Opac02: 'rgba(30, 21, 138, 0.2)',
  color13Opac04: 'rgba(30, 21, 138, 0.4)',
  color14: 'rgba(93, 21, 138, 1)',
  color14Opac02: 'rgba(93, 21, 138, 0.2)',
  color14Opac04: 'rgba(93, 21, 138, 0.4)',
  color15: 'rgba(138, 21, 136, 1)',
  color15Opac02: 'rgba(138, 21, 136, 0.2)',
  color15Opac04: 'rgba(138, 21, 136, 0.4)',
  color16: 'rgba(138, 21, 54, 1)',
  color16Opac02: 'rgba(138, 21, 54, 0.2)',
  color16Opac04: 'rgba(138, 21, 54, 0.4)',
  color17: 'rgba(21, 134, 138, 1)',
  color17Opac02: 'rgba(21, 134, 138, 0.2)',
  color17Opac04: 'rgba(21, 134, 138, 0.4)',
  color18: 'rgba(138, 116, 21, 1)',
  color18Opac02: 'rgba(138, 116, 21, 0.2)',
  color18Opac04: 'rgba(138, 116, 21, 0.4)',
  color19: 'rgba(204, 30, 30, 1)',
  color19Opac02: 'rgba(204, 30, 30, 0.2)',
  color19Opac04: 'rgba(204, 30, 30, 0.4)',
  color20: 'rgba(120, 115, 115, 1)',
  color20Opac02: 'rgba(120, 115, 115, 0.2)',
  color20Opac04: 'rgba(120, 115, 115, 0.4)',
  color21: 'rgba(229, 131, 52, 1)',
  color21Opac02: 'rgba(229, 131, 52, 0.2)',
  color21Opac04: 'rgba(229, 131, 52, 0.4)',
  color22: 'rgba(229, 208, 52, 1)',
  color22Opac02: 'rgba(229, 208, 52, 0.2)',
  color22Opac04: 'rrgba(229, 208, 52, 0.4)',
  color23: 'rgba(137, 229, 52, 1)',
  color23Opac02: 'rgba(137, 229, 52, 0.2)',
  color23Opac04: 'rgba(137, 229, 52, 0.4)',
  color24: 'rgba(229, 52, 181, 1)',
  color24pac02: 'rgba(229, 52, 181, 0.2)',
  color24Opac04: 'rgba(229, 52, 181, 0.4)',
  color25: 'rgba(70, 98, 236, 1)',
  color25pac02: 'rgba(70, 98, 236, 02)',
  color25Opac04: 'rgba(70, 98, 236, 0.4)',
};
