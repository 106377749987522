import classnames from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import InputAdornment from '@material-ui/core/InputAdornment';
import Email from '@material-ui/icons/Email';

import { colors } from '../../../../../assets/styles';
import { IValidator, msg, Validator } from '../../../../../utils';
import { ButtonComponent, TextFieldComponent } from '../../../../shared';
import styles from '../styles/Auth.module.css';

interface IState {
  showCard: boolean;
  headerUp: boolean;
  visible: boolean;
  email: string;
}

interface IProps {
  forgotPasswordAction: any;
  state: {
    error: any;
    pending: any;
  };
}

class ForgotPasswordComponent extends React.Component<IProps & IValidator, IState> {
  // emailInput: HTMLInputElement;

  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      showCard: false,
      headerUp: false,
      visible: false,
      email: ''
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showCard: true });
      // tslint:disable-next-line:align
    }, 800);
    setTimeout(() => {
      this.setState({ visible: true });
      // tslint:disable-next-line:align
    }, 1000);
  }

  componentDidUpdate(prevProps: IProps) {
    if (!prevProps.state.error && this.props.state.error) {
      this.setState({ headerUp: true });
    }
    if (!prevProps.state.pending && this.props.state.pending) {
      this.setState({ headerUp: false });
    }
  }

  sendRecoverEmailHandler = () => {
    const { isValid } = this.props.validator!;
    if (isValid && isValid()) {
      this.props.forgotPasswordAction(this.state.email);
    }
  };

  triggerSendByEnterPress = (event: any) => {
    let e = event || window.event;
    if (e.keyCode === 13) {
      this.sendRecoverEmailHandler();
    }
  };

  handleChange = (event: any) => {
    const fieldValue = event.target.value;
    const { isDirty } = this.props.validator!;
    if (isDirty) {
      isDirty(fieldValue);
    }

    this.setState({ email: fieldValue });
  };

  render() {
    let cardClasses = classnames(`card card-login ${styles.card}`, { 'card-hidden': !this.state.showCard });
    let headerClasses = classnames(`card-header text-center ${styles.header}`, {
      [styles.up]: this.state.headerUp
    });
    const { errors } = this.props.validator!;

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form>
              <div className={cardClasses}>
                <div className={headerClasses} data-background-color="green">
                  <h4 className="card-title">{msg('passwords.resetPassword', 'Reset password')}</h4>
                </div>
                <div className={`card-content ${styles.content}`}>
                  <div className="card-actions" style={{ top: -29, color: colors.red }}>
                    {msg('formValidation.inexistentEmail', 'The provided email does not exist!')}
                  </div>
                  <div className="form-group" style={{ marginLeft: 20, marginTop: -5 }}>
                    {msg('resetPasswordScreen.text1', 'Type in the email address for your account identification.')}
                  </div>
                  <div className="form-group" style={{ marginLeft: 20, marginTop: -5 }}>
                    {msg(
                      'resetPasswordScreen.text2',
                      // tslint:disable-next-line:max-line-length quotemark
                      "After pressing the button below, you'll shortly receive an email with a link to reset your password."
                    )}
                  </div>
                  <div className="form-group" style={{ marginLeft: 20, marginTop: -5 }}>
                    <TextFieldComponent
                      id="email"
                      inType="email"
                      onKeyUp={(event: any) => this.triggerSendByEnterPress(event)}
                      onChange={this.handleChange}
                      value={this.state.email}
                      formatError={errors.email}
                      ref="email"
                      validator={[{ type: 'isEmail', msg: msg('formValidation.emailFormat', 'Invalid email format!') }]}
                      startAdornment={
                        <InputAdornment position="start">
                          <Email />
                        </InputAdornment>
                      }
                    />
                  </div>
                </div>
                <div className="footer text-center">
                  <ButtonComponent
                    pending={this.props.state.pending}
                    action={this.sendRecoverEmailHandler}
                    label={msg('resetPasswordScreen.button', 'Send email')}
                    icon="email"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3" style={{ marginTop: -15 }}>
            <div style={{ visibility: this.state.visible ? 'visible' : 'hidden' }}>
              <div className="col-sm-4" />
              <div className="col-sm-4" style={{ textAlign: 'center', padding: 0 }}>
                <div className="card-actions">
                  <NavLink to="/auth" exact={true}>
                    <p style={{ color: colors.white, fontSize: 16 }}> {msg('login.title', 'Log in')}</p>
                  </NavLink>
                </div>
              </div>
              <div className="col-sm-4" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Validator(ForgotPasswordComponent);
