import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ReviewFormComponent, ReviewsListComponent, ReviewConfigsListComponent } from './components';
import { reduxContainer, AppStore, ReviewsStore, PlacesStore } from '../../../appRedux';
import { partnerRoutes } from '../../layouts';

interface IProps extends ReviewsStore.IState, PlacesStore.IState {}
interface IState {}

class ReviewsContainer extends React.Component<IProps & ReviewsStore.IActions, IState> {
  render() {
    const reviewListProps = {
      review: this.props.review,
      review_pending: this.props.review_pending,
      reviews: this.props.reviews_list,
      reviews_error: this.props.reviews_list_error,
      reviews_pending: this.props.reviews_list_pending,
      getReviews: this.props.getReviewsListAction,
      getReview: this.props.getReviewAction,
      clearReviewModalData: this.props.clearReviewModalData,
      clearPlaceReviewsError: this.props.clearPlaceReviewsError
    };

    const reviewConfigListProps = {
      place: this.props.place,
      review_config: this.props.review_config,
      review_configs: this.props.review_configs_list,
      review_configs_error: this.props.review_configs_list_error,
      review_configs_pending: this.props.review_configs_list_pending,
      export_review_config_pending: this.props.export_review_config_pending,
      export_review_config_error: this.props.export_review_config_error,
      getReviewConfigsListAction: this.props.getReviewConfigsListAction,
      editConfigStatusAction: this.props.editConfigStatusAction,
      redirectToReviewConfig: this.props.redirectToReviewConfig,
      redirectToReviews: this.props.redirectToReviews,
      changeReviewStatus: this.props.changeReviewStatus,
      clearPlaceReviewsError: this.props.clearPlaceReviewsError,
      deleteReviewConfigAction: this.props.deleteReviewConfigAction,
      exportReviewsInExcel: this.props.exportReviewsInExcel
    };

    const reviewFormProps = {
      place: this.props.place,
      config: this.props.review_config,
      config_pending: this.props.review_config_pending,
      saveReviewConfigAction: this.props.saveReviewConfigAction,
      editReviewConfigAction: this.props.editReviewConfigAction,
      getReviewConfigAction: this.props.getReviewConfigAction
    };

    let enableRoute = true;
    if (this.props.place && !this.props.place_pending && !this.props.place?.featureFlags?.reviews) {
      enableRoute = false;
    }

    return (
      <Switch>
        {enableRoute && (
          <Route
            exact={true}
            path={partnerRoutes.REVIEWS.subroutes.EDIT.path}
            render={({ match }) => <ReviewFormComponent match={match} {...reviewFormProps} />}
          />
        )}
        {enableRoute && (
          <Route
            exact={true}
            path={partnerRoutes.REVIEWS.subroutes.ADD.path}
            render={({ match }) => <ReviewFormComponent match={match} {...reviewFormProps} />}
          />
        )}
        {enableRoute && (
          <Route
            exact={true}
            path={partnerRoutes.REVIEWS.subroutes.ANSWERS.path}
            render={({ match }) => <ReviewsListComponent match={match} config={reviewListProps} />}
          />
        )}
        {enableRoute && (
          <Route
            exact={true}
            path={partnerRoutes.REVIEWS.default}
            render={({ match }) => <ReviewConfigsListComponent match={match} config={reviewConfigListProps} />}
          />
        )}
        <Redirect from="*" to={`/partner/dashboard/${this.props.selected_place}`} />
      </Switch>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    review: state.reviews.review,
    review_pending: state.reviews.review_pending,
    reviews_list: state.reviews.reviews_list,
    reviews_list_pending: state.reviews.reviews_list_pending,
    reviews_list_error: state.reviews.reviews_list_error,
    review_configs_list: state.reviews.review_configs_list,
    review_configs_list_pending: state.reviews.review_configs_list_pending,
    review_configs_list_error: state.reviews.review_configs_list_error,
    export_review_config_pending: state.reviews.export_review_config_pending,
    export_review_config_error: state.reviews.export_review_config_error,
    review_config: state.reviews.review_config,
    review_config_pending: state.reviews.review_config_pending,
    allow_reviews: state.reviews.allow_reviews,
    allow_reviews_pending: state.reviews.allow_reviews_pending,
    place: state.places.place,
    place_pending: state.places.place_pending,
    selected_place: state.places.selected_place
  };
}

const dispatchToProps = {
  getReviewAction: ReviewsStore.actions.getReviewAction,
  getReviewsListAction: ReviewsStore.actions.getReviewsListAction,
  getReviewConfigsListAction: ReviewsStore.actions.getReviewConfigsListAction,
  getReviewConfigAction: ReviewsStore.actions.getReviewConfigAction,
  saveReviewConfigAction: ReviewsStore.actions.saveReviewConfigAction,
  editReviewConfigAction: ReviewsStore.actions.editReviewConfigAction,
  editConfigStatusAction: ReviewsStore.actions.editConfigStatusAction,
  deleteReviewConfigAction: ReviewsStore.actions.deleteReviewConfigAction,
  exportReviewsInExcel: ReviewsStore.actions.exportReviewsInExcel,
  redirectToReviewConfig: ReviewsStore.actions.redirectToReviewConfig,
  redirectToReviews: ReviewsStore.actions.redirectToReviews,
  clearReviewModalData: ReviewsStore.actions.clearReviewModalData,
  changeReviewStatus: ReviewsStore.actions.changeReviewStatus,
  clearPlaceReviewsError: ReviewsStore.actions.clearPlaceReviewsError
};

export default reduxContainer(ReviewsContainer, mapStateToProps, dispatchToProps);
