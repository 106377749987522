import React, { useState } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface IProps {
  label: any;
  maxToShow?: number;
  disabled?: boolean;
  onChange: (event: any) => void;
  options: any[];
  id: string;
  value?: any[];
  formatError?: string;
  selectProps?: any;
  isCreatable?: boolean;
  style?: object;
  selectStyle?: object;
}

const ValueContainer = ({ children, getValue, ...props }: any) => {
  const length = getValue().length;
  const displayChips = children?.length === 2 && React.Children.toArray(children[0]).slice(0, props.maxToShow);
  const shouldBadgeShow = length > props.maxToShow;
  const displayLength = length - props.maxToShow;

  return (
    <components.ValueContainer {...props}>
      {props.showAll ? (
        <>
          {children}
          {displayLength > 0 && (
            <div className="selectShowMore" onClick={props.showHandler}>
              show less
            </div>
          )}
        </>
      ) : (
        <>
          {displayChips}
          {children?.length === 2 && children[1]}
          <div className="selectShowMore" onClick={props.showHandler}>
            {shouldBadgeShow && `+ ${displayLength} item${length !== 1 ? 's' : ''} selected`}
          </div>
        </>
      )}
    </components.ValueContainer>
  );
};

export const MultipleSelectComponent = (props: IProps) => {
  const SelectComponent = props.isCreatable ? CreatableSelect : Select;
  const [showAll, setShowAll] = useState(false);

  const showHandler = () => {
    setShowAll(!showAll);
  };

  const colourStyles = {
    control: (styles: any, state: any) => ({
      ...styles,
      border: 'none',
      boxShadow: state.isFocused ? '0px 2px 0px #4caf50' : '0px 1px 0px rgba(0, 0, 0, 0.54)',
      borderRadius: 0,
      ':hover': {
        boxShadow: state.menuIsOpen || state.isFocused ? '0px 2px 0px #4caf50' : '0px 2px 0px #3C4858'
      }
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.1)' : 'white'
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 2
    }),
    ...props.selectStyle
  };

  return (
    <div style={{ marginTop: 10, ...props.style }}>
      <SelectComponent
        className="basic-multi-select"
        classNamePrefix="select"
        isMulti={true}
        name={props.label}
        options={props.options}
        onChange={props.onChange}
        value={props.value}
        styles={colourStyles}
        isDisabled={props.disabled}
        components={
          props.maxToShow
            ? {
                ValueContainer: (inputProps: any) => (
                  <ValueContainer
                    {...inputProps}
                    maxToShow={props.maxToShow}
                    showAll={showAll}
                    showHandler={showHandler}
                  />
                )
              }
            : null
        }
        {...props.selectProps}
      />
    </div>
  );
};
