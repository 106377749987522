import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { TransactionsStore } from '.';
import { Server, composedCriteriaBuilder, AlertUtil, msg, logger, queryStringToCriteria } from '../../utils';
import { PaginationDTO, TransactionDTO } from '../types';

export interface ITransactionsActions {
  getTransactionsAction(
    isSAdmin: boolean,
    placeId?: string,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: { [key: string]: string | object }
  ): any;
  getTransactionAction(isSAdmin: boolean, transactionId: string, placeId?: string): any;
  rollbackTransactionAction(isSAdmin: boolean, transactionId: string, placeId?: string, pinCode?: string): any;
  getTransactionsForUser(
    userId: string,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: { [key: string]: string | object }
  ): any;
  sendTransactionsReport(placeId: string, startDate: number, endDate: number, email: string): any;
  clearUserTransactions(): any;
  clearTransactionModalData(): any;
  clearTransactionsError(): any;
}

class TransactionsActions implements ITransactionsActions {
  getTransactionsAction(
    isSAdmin: boolean,
    placeId?: string,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: TransactionsStore.ActionTypes.GET_TRANSACTIONS });
      try {
        if (!limit) {
          limit = 30;
        }
        let url;
        if (isSAdmin) {
          url = `admin/transactions?limit=${limit}`;
        } else {
          url = `places/${placeId}/transactions?limit=${limit}`;
        }
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg(`Get transactions action, route:/places/:placeId/transactions`, 'GET');
        const response = (await Server.get(url)) as AxiosResponse;
        dispatch({
          type: TransactionsStore.ActionTypes.GET_TRANSACTIONS_SUCCESS,
          payload: response.data as PaginationDTO<TransactionDTO>
        });
      } catch (error) {
        logger.err('Get transactions action, route:/places/:placeId/transactions', 'GET');
        AlertUtil.simple(
          msg(
            'reduxMessages.transactions.getTransactionsError',
            'Due to an error, the transactions list could not be loaded!'
          ),
          'error'
        );
        dispatch({
          type: TransactionsStore.ActionTypes.GET_TRANSACTIONS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  getTransactionAction(isSAdmin: boolean, transactionId: string, placeId?: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: TransactionsStore.ActionTypes.GET_TRANSACTION });
      try {
        let url;
        if (isSAdmin) {
          url = `admin/transactions/${transactionId}`;
        } else {
          url = `places/${placeId}/transactions/${transactionId}`;
        }
        logger.msg(`Get transaction action, route:/places/:placeId/transactions/transactionId`, 'GET');
        const response = (await Server.get(url)) as AxiosResponse;
        dispatch({
          type: TransactionsStore.ActionTypes.GET_TRANSACTION_SUCCESS,
          payload: response.data as TransactionDTO
        });
      } catch (error) {
        logger.err('Get transaction action, route:/places/:placeId/transactions/transactionId', 'GET');
        AlertUtil.simple(
          msg(
            'reduxMessages.transactions.getTransactionError',
            'Due to an error, the transaction could not be loaded!'
          ),
          'error'
        );
        dispatch({
          type: TransactionsStore.ActionTypes.GET_TRANSACTION_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  rollbackTransactionAction(isSAdmin: boolean, transactionId: string, placeId?: string, pinCode?: string) {
    return async (dispatch: Dispatch<any>, getState: () => any) => {
      logger.msg('Rollback transaction action, route:/places/placeId/pin-code/check?pinCode=pin', 'GET');
      dispatch({
        type: TransactionsStore.ActionTypes.ROLLBACK
      });
      AlertUtil.simple(
        msg('reduxMessages.transactions.rollback', 'The transaction is being rolled back, please wait...'),
        'info',
        3000
      );
      let url;
      if (isSAdmin) {
        url = `admin/transactions/${transactionId}/rollback?placeId=${placeId}`;
      } else {
        url = `places/${placeId}/transactions/${transactionId}/rollback?pinCode=${pinCode}`;
      }
      await Server.get(url)
        .then((response: AxiosResponse) => {
          if (response.data.success) {
            dispatch({
              type: TransactionsStore.ActionTypes.ROLLBACK_SUCCESS,
              payload: response.data
            });
            AlertUtil.fireOnce(
              msg('reduxMessages.transactions.rollbackSuccess', 'The transaction was successfully rolled back!'),
              'success'
            );
            dispatch(TransactionsStore.actions.getTransactionAction(isSAdmin, transactionId, placeId));
            const { limit, page_number } = getState().transactions.transactions_list;
            const newCriteria = queryStringToCriteria(window.location.search);
            dispatch(
              TransactionsStore.actions.getTransactionsAction(
                isSAdmin,
                placeId,
                limit * page_number,
                0,
                newCriteria.sort,
                {
                  filters: newCriteria.filters,
                  search: newCriteria.search
                }
              )
            );
          } else {
            AlertUtil.fireOnce(
              msg('reduxMessages.transactions.resetPinWrongCode', 'The place pin code is not correct!'),
              'error'
            );
          }
        })
        .catch(error => {
          logger.err('Rollback transaction action, route:/places/placeId/pin-code/check?pinCode=pin', 'GET');
          if (error.response?.data?.ERROR?.code === 'NOT_ALLOWED') {
            AlertUtil.fireOnce(
              msg(
                'reduxMessages.transactions.rollbackNotAllowedError',
                'This payment has already been settled and cannot be rolled back!'
              ),
              'error'
            );
          } else {
            AlertUtil.fireOnce(
              msg(
                'reduxMessages.transactions.rollbackError',
                'Due to an error, the transaction could not be rolled back!'
              ),
              'error'
            );
          }
          dispatch({
            type: TransactionsStore.ActionTypes.ROLLBACK_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getTransactionsForUser(
    userId: string,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: { [key: string]: string | object }
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: TransactionsStore.ActionTypes.GET_TRANSACTIONS_FOR_USER });
      try {
        if (!limit) {
          limit = 30;
        }
        let url = `admin/user/${userId}/transactions?limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg(`Get transactions for user action, route:/admin/user/:id/transactions`, 'GET');
        const response = (await Server.get(url)) as AxiosResponse;
        dispatch({
          type: TransactionsStore.ActionTypes.GET_TRANSACTIONS_FOR_USER_SUCCESS,
          payload: response.data as PaginationDTO<TransactionDTO>
        });
      } catch (error) {
        logger.err('Get transactions for user action, route:/admin/user/:id/transactions', 'GET');
        AlertUtil.simple(
          msg(
            'reduxMessages.transactions.getTransactionsError',
            'Due to an error, the transactions list could not be loaded!'
          ),
          'error'
        );
        dispatch({
          type: TransactionsStore.ActionTypes.GET_TRANSACTIONS_FOR_USER_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  sendTransactionsReport(placeId: string, startDate: number, endDate: number, email: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: TransactionsStore.ActionTypes.EXPORT_TRANSACTIONS
      });
      let url = `places/${placeId}/report-transactions?startDate=${startDate}&endDate=${endDate}&email=${email}`;
      logger.msg('Send transactions report action, route:/places/placeId/report-transations', 'GET');
      Server.get(url)
        .then(() => {
          dispatch({
            type: TransactionsStore.ActionTypes.EXPORT_TRANSACTIONS_SUCCESS
          });
        })
        .catch(error => {
          logger.err('Send transactions report action, route:/places/placeId/report-receipts', 'GET');
          dispatch({
            type: TransactionsStore.ActionTypes.EXPORT_TRANSACTIONS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  clearUserTransactions() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: TransactionsStore.ActionTypes.CLEAR_USER_TRANSACTIONS });
    };
  }

  clearTransactionModalData() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: TransactionsStore.ActionTypes.CLEAR_MODAL_DATA });
    };
  }

  clearTransactionsError() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: TransactionsStore.ActionTypes.CLEAR_TRANSACTIONS_ERROR });
    };
  }
}

const transactionsActions = new TransactionsActions();
export default transactionsActions;
