import React from 'react';
import * as val from 'validator';
import moment from 'moment';
export interface IValidator {
  validator?: {
    errors: { [type: string]: string };
    isValid: () => boolean;
    isDirty: (type: string) => void;
  };
}

const validator = function<P extends object>(Component: React.ComponentType<P>) {
  return class Validator extends React.Component<P, { errors: any }> {
    references: React.RefObject<any>;

    customValidations = {
      geolocationValidation: (params: any) => {
        return params[0] !== 0 && params[1] !== 0;
      },
      checkIfSelected: (params?: any) => {
        return params || params === 0 ? true : false;
      },
      checkIfAlpha: (params: any) => {
        return val.isAlpha(params.replace(/\s/g, ''));
      },
      customDatePicker: (params: any) => {
        return moment(params.startDate).valueOf() < moment(params.endDate).valueOf();
      }
    };

    constructor(props: any) {
      super(props);
      this.references = React.createRef();
      this.state = {
        errors: {}
      };
    }

    getValidator(type: string, value: any, params?: any) {
      if (value === undefined || value === null) {
        value = '';
      } else if (typeof value !== 'string') {
        value = value + '';
      }
      if (typeof val[type] === 'function') {
        return params ? val[type](value, params) : val[type](value);
      }
      if (typeof this.customValidations[type] === 'function') {
        return this.customValidations[type](params);
      }
    }

    isDirty = (type: string) => {
      const { errors } = this.state;
      if (errors[type]) {
        delete errors[type];
        this.setState({ errors });
      }
    };

    autopilotValidation = (data: any) => {
      const numericTriggers = [0, 2, 3, 4, 5, 6];
      const actionSelectorTriggers = [0, 2];
      let err = {};
      let valid = true;
      for (let trigger of data.list) {
        if (!this.customValidations.checkIfSelected(trigger.type)) {
          valid = false;
          err[trigger._id] = 'Invalid input';
        }
        if (numericTriggers.indexOf(trigger.type) !== -1) {
          if (!this.getValidator('isNumeric', trigger.value)) {
            valid = false;
            err[trigger.type] = 'Invalid input';
          }
        }
        if (trigger.type === 0) {
          let items = ['condition', 'period'];
          for (let key of items) {
            if (!this.customValidations.checkIfSelected(trigger[key])) {
              valid = false;
              err[key] = 'Invalid input';
            }
          }
        }
      }
      for (let action of data.actions) {
        if (typeof action.type !== 'string') {
          action.type = action.type + '';
        }
        if (!this.customValidations.checkIfSelected(action.type)) {
          err[action._id] = 'Invalid input';
          valid = false;
        }
        if (
          actionSelectorTriggers.indexOf(action.type) !== -1 &&
          !this.customValidations.checkIfSelected(action.reward)
        ) {
          err[`reward_${action._id}`] = 'Invalid input';
        }

        if (action.reward === 'custom' && action.customReward === '') {
          err[`reward_${action._id}`] = 'Invalid input';
        }
        if (action.type === '1') {
          if (!this.getValidator('isNumeric', action.value)) {
            err[`reward_${action._id}`] = 'Invalid input';
            valid = false;
          }
        }
      }

      this.setState({ errors: err });
      return valid;
    };

    isValid = () => {
      const refs = this.references && this.references.current && this.references.current.refs;
      if (!refs) {
        return true;
      }
      let valid = true;
      const err = {};

      for (let key in refs) {
        const { props } = refs[key];
        const { value } = props;
        const validators = props.validator;
        if (props.validatorIgnore) {
          continue;
        }
        if (key === 'bait') {
          let data = {
            actions: props.actions,
            list: props.list
          };
          valid = this.autopilotValidation(data);
        }

        if (validators) {
          for (let e of validators) {
            const { type, msg, params } = e;
            if (!this.getValidator(type, value, params)) {
              err[key] = msg;
              valid = false;
            }
          }
        }
      }

      this.setState({ errors: err });

      return valid;
    };

    render() {
      const props = Object.assign({}, this.props, {
        ref: this.references,
        validator: {
          errors: this.state.errors,
          isValid: this.isValid,
          isDirty: this.isDirty
        }
      });

      return <Component {...(props as P)} />;
    }
  };
};

export default validator;
