import { AutopilotStore } from './';

/*
  autopilotReducer = reducer for all actionTypes for Autopilot
*/
function autopilotReducer(
  state: AutopilotStore.IState = AutopilotStore.initialState,
  action: IAction<any>
): AutopilotStore.IState {
  switch (action.type) {
    case AutopilotStore.ActionTypes.GET_AUTOPILOTS: {
      return {
        ...state,
        autopilot_list_pending: true,
        autopilot_list_error: null
      };
    }
    case AutopilotStore.ActionTypes.GET_AUTOPILOTS_SUCCESS: {
      return {
        ...state,
        autopilot_list_pending: false,
        autopilot_list_error: null,
        autopilot_list: action.payload
      };
    }
    case AutopilotStore.ActionTypes.GET_AUTOPILOTS_FAILED: {
      return {
        ...state,
        autopilot_list_pending: false,
        autopilot_list: [],
        autopilot_list_error: action.payload
      };
    }
    case AutopilotStore.ActionTypes.GET_AUTOPILOT: {
      return {
        ...state,
        autopilot_pending: true,
        autopilot_error: null
      };
    }
    case AutopilotStore.ActionTypes.GET_AUTOPILOT_SUCCESS: {
      return {
        ...state,
        autopilot_pending: false,
        autopilot_error: null,
        autopilot: action.payload
      };
    }
    case AutopilotStore.ActionTypes.GET_AUTOPILOT_FAILED: {
      return {
        ...state,
        autopilot_pending: false,
        autopilot: null,
        autopilot_error: action.payload
      };
    }
    case AutopilotStore.ActionTypes.SAVE_AUTOPILOT: {
      return {
        ...state,
        autopilot_save_pending: true,
        autopilot_save_error: null,
        autopilot_saved: null
      };
    }
    case AutopilotStore.ActionTypes.SAVE_AUTOPILOT_SUCCESS: {
      return {
        ...state,
        autopilot_save_pending: false,
        autopilot_save_error: null,
        autopilot_saved: action.payload
      };
    }
    case AutopilotStore.ActionTypes.SAVE_AUTOPILOT_FAILED: {
      return {
        ...state,
        autopilot_save_pending: false,
        autopilot_save_error: action.payload,
        autopilot_saved: null
      };
    }
    case AutopilotStore.ActionTypes.SAVE_ALL_SIMPLE_AUTOPILOTS: {
      return {
        ...state,
        all_simple_autopilots_save_success: false,
        all_simple_autopilots_save_pending: true,
        all_simple_autopilots_save_error: null
      };
    }
    case AutopilotStore.ActionTypes.SAVE_ALL_SIMPLE_AUTOPILOTS_SUCCESS: {
      return {
        ...state,
        all_simple_autopilots_save_success: true,
        all_simple_autopilots_save_pending: false,
        all_simple_autopilots_save_error: null
      };
    }
    case AutopilotStore.ActionTypes.SAVE_ALL_SIMPLE_AUTOPILOTS_FAILED: {
      return {
        ...state,
        all_simple_autopilots_save_pending: false,
        all_simple_autopilots_save_error: action.payload
      };
    }
    case AutopilotStore.ActionTypes.DELETE_AUTOPILOT: {
      return {
        ...state,
        autopilot_delete_pending: true,
        autopilot_delete_error: null,
        autopilot_deleted: false
      };
    }
    case AutopilotStore.ActionTypes.DELETE_AUTOPILOT_SUCCESS: {
      return {
        ...state,
        autopilot_delete_pending: false,
        autopilot_delete_error: null,
        autopilot_deleted: action.payload
      };
    }
    case AutopilotStore.ActionTypes.DELETE_AUTOPILOT_FAILED: {
      return {
        ...state,
        autopilot_delete_pending: false,
        autopilot_delete_error: action.payload,
        autopilot_deleted: false
      };
    }
    case AutopilotStore.ActionTypes.AUTOPILOT_VERIFICATION_FAILED: {
      return {
        ...state,
        autopilot_verification_error: action.payload
      };
    }
    default:
      return state;
  }
}

export default autopilotReducer;
