import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { TagsContainer } from '.';
import { partnerRoutes, adminRoutes } from '../../layouts';

const TagsRouter = () => {
  return (
    <Switch>
      <Route exact={true} path={adminRoutes.TAGS.path} render={() => <TagsContainer isSAdmin={true} />} />
      <Route exact={true} path={partnerRoutes.TAGS.default} render={() => <TagsContainer isSAdmin={false} />} />
    </Switch>
  );
};

export default TagsRouter;
