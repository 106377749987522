export class SelectedPartnerDTO {
  name: string;
  _id: string;
  places: SelectedPlaceDTO[];
}

export class SelectedPlaceDTO {
  name: string;
  _id: string;
  partnerId?: string;
}
