import usersActions, { IUsersActions } from './actions';
import usersReducer from './reducer';
import { UserDTO, PaginationDTO, ProfileDTO } from '../types';

/*
  UsersStore definition as a namespace that contains actions, reducers, actionTypes and users state
*/
export namespace UsersStore {
  export type IState = {
    users_list: PaginationDTO<UserDTO>;
    users_list_pending: boolean;
    users_list_error: string | null;
    user_b: UserDTO | null;
    user_pending: boolean;
    user_error: string | null;
    user_saved: UserDTO | null;
    user_save_pending: boolean;
    user_save_error: string | null;
    user_delete_pending: boolean;
    user_delete_error: string | null;
    user_change_password: null | UserDTO;
    user_change_password_pending: boolean;
    user_change_password_error: string | null;
    admins_list: Array<UserDTO>;
    admins_list_pending: boolean;
    admins_list_error: string | null;
    admin_saved: UserDTO | null;
    admin_save_pending: boolean;
    admin_save_error: string | null;
    admin_removed: boolean;
    admin_removed_pending: boolean;
    admin_removed_error: string | null;
    place_admins_list: Array<UserDTO>;
    place_admins_list_pending: boolean;
    place_admins_list_error: string | null;
    place_admin_removed: boolean;
    place_admin_removed_pending: boolean;
    place_admin_removed_error: string | null;
    profile_list: PaginationDTO<ProfileDTO>;
    profile_list_pending: boolean;
    profile_list_error: string | null;
    profile_save_pending: boolean;
    profile_save_error: string | null;
    profile_delete_pending: boolean;
    profile_delete_error: string | null;
    user_favorite_moments: Array<any>;
    user_favorite_moments_pending: boolean;
    user_favorite_moments_error: string | null;
  };

  export const initialState: IState = {
    users_list: new PaginationDTO(),
    users_list_pending: false,
    users_list_error: null,
    user_b: null,
    user_pending: false,
    user_error: null,
    user_saved: null,
    user_save_pending: false,
    user_save_error: null,
    user_delete_pending: false,
    user_delete_error: null,
    user_change_password: null,
    user_change_password_pending: false,
    user_change_password_error: null,
    admins_list: [],
    admins_list_pending: false,
    admins_list_error: null,
    admin_saved: null,
    admin_save_pending: false,
    admin_save_error: null,
    admin_removed: false,
    admin_removed_pending: false,
    admin_removed_error: null,
    place_admins_list: [],
    place_admins_list_pending: false,
    place_admins_list_error: null,
    place_admin_removed: false,
    place_admin_removed_pending: false,
    place_admin_removed_error: null,
    profile_list: new PaginationDTO(),
    profile_list_pending: false,
    profile_list_error: null,
    profile_save_pending: false,
    profile_save_error: null,
    profile_delete_pending: false,
    profile_delete_error: null,
    user_favorite_moments: [],
    user_favorite_moments_pending: false,
    user_favorite_moments_error: null
  };

  export enum ActionTypes {
    GET_USERS = 'GET_USERS',
    GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
    GET_USERS_FAILED = 'GET_USERS_FAILED',
    GET_USER = 'GET_USER',
    GET_USER_SUCCESS = 'GET_USER_SUCCESS',
    GET_USER_FAILED = 'GET_USER_FAILED',
    SAVE_USER = 'SAVE_USER',
    SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS',
    SAVE_USER_FAILED = 'SAVE_USER_FAILED',
    DELETE_USER = 'DELETE_USER',
    DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILED = 'DELETE_USER_FAILED',
    CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD',
    CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS',
    CHANGE_USER_PASSWORD_FAILED = 'CHANGE_USER_PASSWORD_FAILED',
    GET_ADMINS = 'GET_ADMINS',
    GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS',
    GET_ADMINS_FAILED = 'GET_ADMINS_FAILED',
    SAVE_ADMIN = 'SAVE_ADMIN',
    SAVE_ADMIN_SUCCESS = 'SAVE_ADMIN_SUCCESS',
    SAVE_ADMIN_FAILED = 'SAVE_ADMIN_FAILED',
    REMOVE_ADMIN = 'REMOVE_ADMIN',
    REMOVE_ADMIN_SUCCESS = 'REMOVE_ADMIN_SUCCESS',
    REMOVE_ADMIN_FAILED = 'REMOVE_ADMIN_FAILED',
    GET_PLACE_ADMINS = 'GET_PLACE_ADMINS',
    GET_PLACE_ADMINS_SUCCESS = 'GET_PLACE_ADMINS_SUCCESS',
    GET_PLACE_ADMINS_FAILED = 'GET_PLACE_ADMINS_FAILED',
    REMOVE_PLACE_ADMIN = 'REMOVE_PLACE_ADMIN',
    REMOVE_PLACE_ADMIN_SUCCESS = 'REMOVE_PLACE_ADMIN_SUCCESS',
    REMOVE_PLACE_ADMIN_FAILED = 'REMOVE_PLACE_ADMIN_FAILED',
    GET_PROFILES = 'GET_PROFILES',
    GET_PROFILES_SUCCESS = 'GET_PROFILES_SUCCESS',
    GET_PROFILES_FAILED = 'GET_PROFILES_FAILED',
    SAVE_PROFILE = 'SAVE_PROFILE',
    SAVE_PROFILE_SUCCESS = 'SAVE_PROFILE_SUCCESS',
    SAVE_PROFILE_FAILED = 'SAVE_PROFILE_FAILED',
    DELETE_PROFILE = 'DELETE_PROFILE',
    DELETE_PROFILE_SUCCESS = 'DELETE_PROFILE_SUCCESS',
    DELETE_PROFILE_FAILED = 'DELETE_PROFILE_FAILED',
    GET_USER_FAVORITE_MOMENTS = 'GET_USER_FAVORITE_MOMENTS',
    GET_USER_FAVORITE_MOMENTS_SUCCESS = 'GET_USER_FAVORITE_MOMENTS_SUCCESS',
    GET_USER_FAVORITE_MOMENTS_FAILED = 'GET_USER_FAVORITE_MOMENTS_FAILED'
  }

  export const actions = usersActions;
  export const reducer = usersReducer;
  export interface IActions extends IUsersActions {}
}

export * from './actions';
