import classnames from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import UAParser from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';
import runtimeEnv from '@mars/heroku-js-runtime-env';

import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Email from '@material-ui/icons/Email';
import Lock from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { colors } from '../../../../../assets/styles';
import { AuthUtil, ERROR_CODES, msg } from '../../../../../utils';
import { authRoutes } from '../../../../layouts';
import { ButtonComponent } from '../../../../shared/index';
import styles from '../styles/Auth.module.css';

const env = runtimeEnv();

interface IState {
  showCard: boolean;
  visible: boolean;
  showPassword: boolean;
  errMessage: string;
}

interface IProps {
  loginAction: any;
  state: {
    error: any;
    pending: any;
  };
}

const herokuReleaseVersion = process.env.HEROKU_RELEASE_VERSION
  ? Number(process.env.HEROKU_RELEASE_VERSION.slice(1))
  : 0;

export default class LoginPanelComponent extends React.Component<IProps, IState> {
  emailInput: HTMLInputElement;
  passwordInput: HTMLInputElement;

  constructor(props: IProps) {
    super(props);
    this.state = {
      showCard: false,
      visible: false,
      showPassword: false,
      errMessage: ''
    };
  }

  componentDidMount() {
    this.setState({ showCard: true, visible: true });
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.state.pending && !this.props.state.pending) {
      let message = '';
      if (this.props.state.error) {
        message = msg(
          this.props.state.error.code === ERROR_CODES.NOT_ACTIVATED ? 'login.activationError' : 'login.errorMessage',
          'Wrong email or password!'
        );
      } else if (AuthUtil.isMobileUser()) {
        message = msg('login.noPermission', 'You do not have permission to login');
      }
      this.setState({
        errMessage: message
      });
    }
  }

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  loginHandler = () => {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem('deviceId', deviceId);
    }
    const uaParser = new UAParser(navigator.userAgent);
    const deviceResult = uaParser.getResult();
    const deviceInfo = {
      identifier: deviceId,
      appServer: env.REACT_APP_API_URL || '',
      name: deviceResult.browser.name + ' ' + deviceResult.browser.version,
      systemName: deviceResult.os.name || '',
      systemVersion: deviceResult.os.version || '',
      deviceModel: deviceResult.device.vendor
        ? deviceResult.device.vendor + ' ' + deviceResult.device.model
        : 'Desktop',
      appVersion: process.env.REACT_APP_VERSION || '',
      buildNumber: herokuReleaseVersion + 1,
      codePushVersion: null
    };
    this.props.loginAction(this.emailInput.value, this.passwordInput.value, deviceInfo);
  };

  triggerLoginByEnterPress = (event: any) => {
    let e = event || window.event;
    if (e.keyCode === 13) {
      this.loginHandler();
    }
  };

  render() {
    let cardClasses = classnames(`card card-login ${styles.card}`, { 'card-hidden': !this.state.showCard });
    const { pending } = this.props.state;
    let headerClasses = classnames(`card-header text-center ${styles.header}`, {
      [styles.up]: !!this.state.errMessage && !pending
    });

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form>
              <div className={cardClasses}>
                <div className={headerClasses} data-background-color="green">
                  <h4 className="card-title">{msg('login.title', 'Authentication')}</h4>
                </div>
                <div className={`card-content ${styles.content}`}>
                  <div className="card-actions" style={{ top: -29, color: colors.red }}>
                    {this.state.errMessage}
                  </div>
                  <div className="form-group" style={{ marginLeft: 20, marginTop: -5 }}>
                    <FormControl fullWidth={true} aria-describedby="inputText">
                      <InputLabel htmlFor="emailInput">{msg('accountInfo.email', 'Email address')}</InputLabel>
                      <Input
                        id="emailInput"
                        type="email"
                        onKeyUp={(event: any) => this.triggerLoginByEnterPress(event)}
                        inputRef={(r: any) => (this.emailInput = r as HTMLInputElement)}
                        startAdornment={
                          <InputAdornment position="start">
                            <Email />
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="form-group" style={{ marginLeft: 20, marginTop: -3 }}>
                    <FormControl fullWidth={true} aria-describedby="inputText">
                      <InputLabel htmlFor="passwordInput">{msg('accountInfo.password', 'Password')}</InputLabel>
                      <Input
                        id="passwordInput"
                        type={this.state.showPassword ? 'text' : 'password'}
                        onKeyUp={(event: any) => this.triggerLoginByEnterPress(event)}
                        inputRef={(r: any) => (this.passwordInput = r as HTMLInputElement)}
                        startAdornment={
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                            >
                              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="footer text-center">
                  <ButtonComponent
                    pending={this.props.state.pending}
                    action={this.loginHandler}
                    label={msg('login.button', 'Log in')}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3" style={{ marginTop: -15 }}>
            <div style={{ visibility: this.state.visible ? 'visible' : 'hidden' }}>
              <div className="col-sm-4" />
              <div className="col-sm-4" style={{ textAlign: 'center', padding: 0 }}>
                <div className="card-actions">
                  <NavLink to={authRoutes.FORGOT_PASSWORD} exact={true}>
                    <p style={{ color: colors.white, fontSize: 16 }}>
                      {msg('passwords.forgotPassword', 'Forgot password')}
                    </p>
                  </NavLink>
                </div>
              </div>
              <div className="col-sm-4" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
