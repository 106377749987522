import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { PaySettlementsStore } from '.';
import { Server, composedCriteriaBuilder, AlertUtil, msg, logger } from '../../utils';
import { PaginationDTO, PaySettlementDTO } from '../types';

export interface IPaySettlementsActions {
  getPaySettlementsAction(
    payBatchId: string,
    showWithoutAmount: boolean,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: { [key: string]: string | object }
  ): any;
  downloadPaySettlementAction(payBatchId: string, paySettlementId: string, placeName: string, created: string): any;
  downloadPaySettlementReportAction(payBatchId: string, paySettlementId: string, placeName: string): any;
  clearPaySettlementsError(): any;
}

class PaySettlementsActions implements IPaySettlementsActions {
  getPaySettlementsAction(
    payBatchId: string,
    showWithoutAmount: boolean,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PaySettlementsStore.ActionTypes.GET_PAY_SETTLEMENTS });
      try {
        if (!limit) {
          limit = 30;
        }
        let url = `admin/pay-batches/${payBatchId}/pay-settlements?showWithoutAmount=${showWithoutAmount}&limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get pay-settlements action, route:/admin/settlements', 'GET');
        const response = (await Server.get(url)) as AxiosResponse;
        dispatch({
          type: PaySettlementsStore.ActionTypes.GET_PAY_SETTLEMENTS_SUCCESS,
          payload: response.data as PaginationDTO<PaySettlementDTO>
        });
      } catch (error) {
        logger.err('Get pay-settlements action, route:/admin/settlements', 'GET');
        AlertUtil.simple(
          msg(
            'reduxMessages.paySettlements.getPaySettlementsError',
            'Due to an error, the settlements list could not be loaded!'
          ),
          'error'
        );
        dispatch({
          type: PaySettlementsStore.ActionTypes.GET_PAY_SETTLEMENTS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  downloadPaySettlementAction = (payBatchId: string, paySettlementId: string, placeName: string, created: string) => {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PaySettlementsStore.ActionTypes.DOWNLOAD_PAY_SETTLEMENT });
      logger.msg(
        'Download pay-settlements action, route:/admin/pay-batches/payBatchId/pay-settlements/download-excel',
        'GET'
      );
      const fileName = `${msg('paymentSettlements.settlementExcelTitle', `Settlement ${placeName} ${created}`, {
        placeName,
        created
      })}.xlsx`;
      Server.downloadReports(
        `/admin/pay-batches/${payBatchId}/pay-settlements/${paySettlementId}/download-excel`,
        fileName
      )
        .then(() => {
          dispatch({
            type: PaySettlementsStore.ActionTypes.DOWNLOAD_PAY_SETTLEMENT_SUCCESS
          });
        })
        .catch(error => {
          logger.err(
            'Download pay-settlements action, route:/admin/pay-batches/payBatchId/pay-settlements/download-excel',
            'GET'
          );
          AlertUtil.simple(
            msg(
              'reduxMessages.paySettlements.downloadPaySettlementError',
              'Due to an error, the settlement could not be downloaded!'
            ),
            'error'
          );
          dispatch({
            type: PaySettlementsStore.ActionTypes.DOWNLOAD_PAY_SETTLEMENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  };

  downloadPaySettlementReportAction = (payBatchId: string, paySettlementId: string, placeName: string) => {
    return async (dispatch: Dispatch<any>) => {
      logger.msg(
        'Download pay batch report action, route:/admin/pay-batches/payBatchId/pay-settlements/download-report',
        'GET'
      );
      AlertUtil.simple(
        msg('reduxMessages.paySettlements.downloadPaySettlementReport', 'The report is being generated...'),
        'info'
      );
      dispatch({ type: PaySettlementsStore.ActionTypes.DOWNLOAD_PAY_SETTLEMENT });
      const fileName = `Raport decontare ${placeName}.pdf`;
      Server.downloadReports(
        `/admin/pay-batches/${payBatchId}/pay-settlements/${paySettlementId}/download-report`,
        fileName
      )
        .then(() => {
          dispatch({
            type: PaySettlementsStore.ActionTypes.DOWNLOAD_PAY_SETTLEMENT_SUCCESS
          });
        })
        .catch(error => {
          logger.err(
            'Download pay batch report action,route:/admin/pay-batches/payBatchId/pay-settlements/download-report',
            'GET'
          );
          AlertUtil.simple(
            msg(
              'reduxMessages.paySettlements.downloadPaySettlementReportError',
              'Due to an error, the pay batch report could not be downloaded!'
            ),
            'error'
          );
          dispatch({
            type: PaySettlementsStore.ActionTypes.DOWNLOAD_PAY_SETTLEMENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  };

  clearPaySettlementsError() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: PaySettlementsStore.ActionTypes.CLEAR_PAY_SETTLEMENTS_ERROR });
    };
  }
}

const paySettlementsActions = new PaySettlementsActions();
export default paySettlementsActions;
