import { ReceiptsStore } from '.';
import { PaginationDTO } from '../types';

function receiptsReducer(
  state: ReceiptsStore.IState = ReceiptsStore.initialState,
  action: IAction<any>
): ReceiptsStore.IState {
  switch (action.type) {
    case ReceiptsStore.ActionTypes.GET_RECEIPT: {
      return {
        ...state,
        receipt_pending: true,
        receipt_error: null
      };
    }
    case ReceiptsStore.ActionTypes.GET_RECEIPT_SUCCESS: {
      return {
        ...state,
        receipt_pending: false,
        receipt_error: null,
        receipt: action.payload
      };
    }
    case ReceiptsStore.ActionTypes.GET_RECEIPT_FAILED: {
      return {
        ...state,
        receipt_pending: false,
        receipt_error: action.payload,
        receipt: null
      };
    }
    case ReceiptsStore.ActionTypes.GET_RECEIPT_LIST: {
      return {
        ...state,
        receipt_list_pending: true,
        receipt_list_error: null
      };
    }
    case ReceiptsStore.ActionTypes.GET_RECEIPT_LIST_SUCCESS: {
      return {
        ...state,
        receipt_list_pending: false,
        receipt_list_error: null,
        receipt_list: action.payload
      };
    }
    case ReceiptsStore.ActionTypes.GET_RECEIPT_LIST_FAILED: {
      return {
        ...state,
        receipt_list_pending: false,
        receipt_list_error: action.payload,
        receipt_list: new PaginationDTO()
      };
    }
    case ReceiptsStore.ActionTypes.EDIT_RECEIPT: {
      return {
        ...state,
        edit_receipt_pending: true,
        edit_receipt_error: null,
        receipt: null
      };
    }
    case ReceiptsStore.ActionTypes.EDIT_RECEIPT_SUCCESS: {
      return {
        ...state,
        edit_receipt_pending: false,
        edit_receipt_error: null,
        receipt: action.payload
      };
    }
    case ReceiptsStore.ActionTypes.EDIT_RECEIPT_FAILED: {
      return {
        ...state,
        edit_receipt_pending: false,
        edit_receipt_error: action.payload
      };
    }
    case ReceiptsStore.ActionTypes.EDIT_RECEIPT_FAILED_STATUS_CHANGED: {
      return {
        ...state,
        edit_receipt_pending: false,
        edit_receipt_error: action.payload.parsedError,
        receipt: action.payload.receipt
      };
    }
    case ReceiptsStore.ActionTypes.DELETE_RECEIPT: {
      return {
        ...state,
        delete_receipt_pending: true,
        delete_receipt_error: null,
        delete_succcess: false
      };
    }
    case ReceiptsStore.ActionTypes.DELETE_RECEIPT_SUCCESS: {
      return {
        ...state,
        delete_receipt_pending: false,
        delete_receipt_error: null,
        delete_succcess: true
      };
    }
    case ReceiptsStore.ActionTypes.DELETE_RECEIPT_FAILED: {
      return {
        ...state,
        delete_receipt_pending: false,
        delete_receipt_error: action.payload
      };
    }
    case ReceiptsStore.ActionTypes.CLEAR_RECEIPTS_ERROR: {
      return {
        ...state,
        receipt_list_error: null
      };
    }
    case ReceiptsStore.ActionTypes.ALLOW_RECEIPTS: {
      return {
        ...state,
        allow_receipts: null,
        allow_receipts_pending: true,
        allow_receipts_error: null
      };
    }
    case ReceiptsStore.ActionTypes.ALLOW_RECEIPTS_SUCCESS: {
      return {
        ...state,
        allow_receipts: action.payload,
        allow_receipts_pending: false,
        allow_receipts_error: null
      };
    }
    case ReceiptsStore.ActionTypes.ALLOW_RECEIPTS_FAILED: {
      return {
        ...state,
        allow_receipts: null,
        allow_receipts_pending: false,
        allow_receipts_error: null
      };
    }
    case ReceiptsStore.ActionTypes.EXPORT_RECEIPTS: {
      return {
        ...state,
        export_receipts_pending: true,
        export_receipts_error: null
      };
    }
    case ReceiptsStore.ActionTypes.EXPORT_RECEIPTS_SUCCESS: {
      return {
        ...state,
        export_receipts_pending: false,
        export_receipts_error: null
      };
    }
    case ReceiptsStore.ActionTypes.EXPORT_RECEIPTS_FAILED: {
      return {
        ...state,
        export_receipts_pending: false,
        export_receipts_error: action.payload
      };
    }
    default:
      return state;
  }
}

export default receiptsReducer;
