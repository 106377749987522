import { PaySettlementsStore } from '.';
import { PaginationDTO } from '../types';

function paySettlementsReducer(
  state: PaySettlementsStore.IState = PaySettlementsStore.initialState,
  action: IAction<any>
): PaySettlementsStore.IState {
  switch (action.type) {
    case PaySettlementsStore.ActionTypes.GET_PAY_SETTLEMENTS: {
      return {
        ...state,
        pay_settlements_list_pending: true,
        pay_settlements_list_error: null
      };
    }
    case PaySettlementsStore.ActionTypes.GET_PAY_SETTLEMENTS_SUCCESS: {
      return {
        ...state,
        pay_settlements_list: action.payload,
        pay_settlements_list_pending: false,
        pay_settlements_list_error: null
      };
    }
    case PaySettlementsStore.ActionTypes.GET_PAY_SETTLEMENTS_FAILED: {
      return {
        ...state,
        pay_settlements_list: new PaginationDTO(),
        pay_settlements_list_pending: false,
        pay_settlements_list_error: action.payload
      };
    }
    case PaySettlementsStore.ActionTypes.DOWNLOAD_PAY_SETTLEMENT: {
      return {
        ...state,
        download_pay_settlement_pending: true,
        download_pay_settlement_error: null
      };
    }
    case PaySettlementsStore.ActionTypes.DOWNLOAD_PAY_SETTLEMENT_SUCCESS: {
      return {
        ...state,
        download_pay_settlement_pending: false,
        download_pay_settlement_error: null
      };
    }
    case PaySettlementsStore.ActionTypes.DOWNLOAD_PAY_SETTLEMENT_FAILED: {
      return {
        ...state,
        download_pay_settlement_pending: false,
        download_pay_settlement_error: action.payload
      };
    }
    case PaySettlementsStore.ActionTypes.CLEAR_PAY_SETTLEMENTS_ERROR: {
      return {
        ...state,
        pay_settlements_list_error: null
      };
    }
    default:
      return state;
  }
}

export default paySettlementsReducer;
