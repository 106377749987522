import { EventsStore } from './';
import { EventDTO, PaginationDTO } from '../types';

/*
  eventsReducer = reducer for all actionTypes for Events
*/
function eventsReducer(state: EventsStore.IState = EventsStore.initialState, action: IAction<any>): EventsStore.IState {
  switch (action.type) {
    case EventsStore.ActionTypes.GET_EVENTS: {
      return {
        ...state,
        events_list_pending: true,
        events_list_error: null
      };
    }
    case EventsStore.ActionTypes.GET_EVENTS_SUCCESS: {
      return {
        ...state,
        events_list_pending: false,
        events_list_error: null,
        events_list: {
          ...action.payload,
          results: action.payload.results?.map((event: EventDTO) => new EventDTO(event))
        }
      };
    }
    case EventsStore.ActionTypes.GET_EVENTS_FAILED: {
      return {
        ...state,
        events_list_pending: false,
        events_list: new PaginationDTO(),
        events_list_error: action.payload
      };
    }
    case EventsStore.ActionTypes.GET_EVENT: {
      return {
        ...state,
        event_pending: true,
        event_error: null
      };
    }
    case EventsStore.ActionTypes.GET_EVENT_SUCCESS: {
      return {
        ...state,
        event_pending: false,
        event_error: null,
        event: action.payload
      };
    }
    case EventsStore.ActionTypes.GET_EVENT_FAILED: {
      return {
        ...state,
        event_pending: false,
        event: null,
        event_error: action.payload
      };
    }
    case EventsStore.ActionTypes.SAVE_EVENT: {
      return {
        ...state,
        event_save_pending: true,
        event_save_error: null
      };
    }
    case EventsStore.ActionTypes.SAVE_EVENT_SUCCESS: {
      return {
        ...state,
        event_save_pending: false,
        event_save_error: null,
        event: action.payload
      };
    }
    case EventsStore.ActionTypes.SAVE_EVENT_FAILED: {
      return {
        ...state,
        event_save_pending: false,
        event_save_error: action.payload
      };
    }
    case EventsStore.ActionTypes.CHANGE_EVENT_STATUS: {
      return {
        ...state,
        event_change_status_pending: true,
        event_change_status_error: false
      };
    }
    case EventsStore.ActionTypes.CHANGE_EVENT_STATUS_SUCCESS: {
      const index = state.events_list.results
        .map(x => {
          return x._id;
        })
        .indexOf(action.payload._id);
      let new_list: PaginationDTO<any> = state.events_list;
      if (index >= 0) {
        new_list.results[index] = new EventDTO(action.payload);
      } else {
        new_list.results.push(new EventDTO(action.payload));
      }
      return {
        ...state,
        event_change_status_pending: false,
        event_change_status_error: false,
        events_list: { ...new_list }
      };
    }
    case EventsStore.ActionTypes.CHANGE_EVENT_STATUS_FAILED: {
      return {
        ...state,
        event_change_status_pending: false,
        event_change_status_error: action.payload
      };
    }
    case EventsStore.ActionTypes.DELETE_EVENT: {
      return {
        ...state,
        event_delete_pending: true,
        event_delete_error: null,
        event_deleted: false
      };
    }
    case EventsStore.ActionTypes.DELETE_EVENT_SUCCESS: {
      return {
        ...state,
        event_delete_pending: false,
        event_delete_error: null,
        event_deleted: true
      };
    }
    case EventsStore.ActionTypes.DELETE_EVENT_FAILED: {
      return {
        ...state,
        event_delete_pending: false,
        event_delete_error: action.payload,
        event_deleted: false
      };
    }
    case EventsStore.ActionTypes.CLEAR_EVENTS_ERROR: {
      return {
        ...state,
        events_list_error: null
      };
    }
    case EventsStore.ActionTypes.EXPORT_EVENTS: {
      return {
        ...state,
        export_events_pending: true,
        export_events_error: null
      };
    }
    case EventsStore.ActionTypes.EXPORT_EVENTS_SUCCESS: {
      return {
        ...state,
        export_events_pending: false,
        export_events_error: null
      };
    }
    case EventsStore.ActionTypes.EXPORT_EVENTS_FAILED: {
      return {
        ...state,
        export_events_pending: false,
        export_events_error: action.payload
      };
    }
    case EventsStore.ActionTypes.GET_CAMPAIGN_MEMBERS: {
      return {
        ...state,
        campaign_members_pending: true,
        campaign_members_error: null
      };
    }
    case EventsStore.ActionTypes.GET_CAMPAIGN_MEMBERS_SUCCESS: {
      return {
        ...state,
        campaign_members_pending: false,
        campaign_members_error: null,
        campaign_members: action.payload
      };
    }
    case EventsStore.ActionTypes.GET_CAMPAIGN_MEMBERS_FAILED: {
      return {
        ...state,
        campaign_members_pending: false,
        campaign_members_error: action.payload
      };
    }
    case EventsStore.ActionTypes.PARTNER_SETTLEMENTS: {
      return {
        ...state,
        partner_settlements_pending: true,
        partner_settlements_error: null
      };
    }
    case EventsStore.ActionTypes.PARTNER_SETTLEMENTS_SUCCESS: {
      return {
        ...state,
        partner_settlements_pending: false,
        partner_settlements_error: null,
        partner_settlements: action.payload
      };
    }
    case EventsStore.ActionTypes.PARTNER_SETTLEMENTS_FAILED: {
      return {
        ...state,
        partner_settlements_pending: false,
        partner_settlements_error: action.payload
      };
    }
    case EventsStore.ActionTypes.EDIT_SETTLEMENT: {
      return {
        ...state,
        edit_settlement_pending: true,
        edit_settlement_error: null
      };
    }
    case EventsStore.ActionTypes.EDIT_SETTLEMENT_SUCCESS: {
      return {
        ...state,
        edit_settlement_pending: false,
        edit_settlement_error: null,
        settlement: action.payload
      };
    }
    case EventsStore.ActionTypes.EDIT_SETTLEMENT_FAILED: {
      return {
        ...state,
        edit_settlement_pending: false,
        edit_settlement_error: action.payload
      };
    }
    case EventsStore.ActionTypes.SEND_SETTLEMENT_EMAIL: {
      return {
        ...state,
        send_settlement_email_pending: true,
        send_settlement_email_error: null
      };
    }
    case EventsStore.ActionTypes.SEND_SETTLEMENT_EMAIL_SUCCESS: {
      return {
        ...state,
        send_settlement_email_pending: false,
        send_settlement_email_error: null,
        settlement: action.payload
      };
    }
    case EventsStore.ActionTypes.SEND_SETTLEMENT_EMAIL_FAILED: {
      return {
        ...state,
        send_settlement_email_pending: false,
        send_settlement_email_error: action.payload
      };
    }
    case EventsStore.ActionTypes.SEND_ALL_EMAILS: {
      return {
        ...state,
        send_all_emails_pending: true,
        send_all_emails_error: null
      };
    }
    case EventsStore.ActionTypes.SEND_ALL_EMAILS_SUCCESS: {
      return {
        ...state,
        send_all_emails_pending: false,
        send_all_emails_error: null
      };
    }
    case EventsStore.ActionTypes.SEND_ALL_EMAILS_FAILED: {
      return {
        ...state,
        send_all_emails_pending: false,
        send_all_emails_error: action.payload
      };
    }
    case EventsStore.ActionTypes.APPROVE_SETTLEMENT_EMAIL: {
      return {
        ...state,
        approve_settlement_email_pending: true,
        approve_settlement_email_error: null
      };
    }
    case EventsStore.ActionTypes.APPROVE_SETTLEMENT_EMAIL_SUCCESS: {
      return {
        ...state,
        approve_settlement_email_pending: false,
        approve_settlement_email_error: null,
        settlement: action.payload
      };
    }
    case EventsStore.ActionTypes.APPROVE_SETTLEMENT_EMAIL_FAILED: {
      return {
        ...state,
        approve_settlement_email_pending: false,
        approve_settlement_email_error: action.payload
      };
    }
    case EventsStore.ActionTypes.DOWNLOAD_SETTLEMENTS_PDF: {
      return {
        ...state,
        download_settlements_pending: true,
        download_settlements_error: null
      };
    }
    case EventsStore.ActionTypes.DOWNLOAD_SETTLEMENTS_PDF_SUCCESS: {
      return {
        ...state,
        download_settlements_pending: false,
        download_settlements_error: null
      };
    }
    case EventsStore.ActionTypes.DOWNLOAD_SETTLEMENTS_PDF_FAILED: {
      return {
        ...state,
        download_settlements_pending: false,
        download_settlements_error: action.payload
      };
    }
    case EventsStore.ActionTypes.GET_RAFFLE_PREVIEW: {
      return {
        ...state,
        raffle_preview_pending: true,
        raffle_preview_error: null
      };
    }
    case EventsStore.ActionTypes.GET_RAFFLE_PREVIEW_SUCCESS: {
      return {
        ...state,
        raffle_preview_pending: false,
        raffle_preview_error: null,
        raffle_preview: action.payload
      };
    }
    case EventsStore.ActionTypes.GET_RAFFLE_PREVIEW_FAILED: {
      return {
        ...state,
        raffle_preview_pending: false,
        raffle_preview_error: action.payload
      };
    }
    case EventsStore.ActionTypes.UPDATE_RAFFLE_INTERVALS: {
      return {
        ...state,
        update_raffle_preview_pending: true,
        update_raffle_preview_error: null
      };
    }
    case EventsStore.ActionTypes.UPDATE_RAFFLE_INTERVALS_SUCCESS: {
      return {
        ...state,
        update_raffle_preview_pending: false,
        update_raffle_preview_error: null
      };
    }
    case EventsStore.ActionTypes.UPDATE_RAFFLE_INTERVALS_FAILED: {
      return {
        ...state,
        update_raffle_preview_pending: false,
        update_raffle_preview_error: action.payload
      };
    }
    case EventsStore.ActionTypes.CLEAR_EVENT_DATA: {
      return {
        ...state,
        event: null
      };
    }
    case EventsStore.ActionTypes.CLEAR_MODAL_DATA: {
      return {
        ...state,
        campaign_members: new PaginationDTO(),
        partner_settlements: new PaginationDTO()
      };
    }
    case EventsStore.ActionTypes.CLEAR_RAFFLE_DATA: {
      return {
        ...state,
        raffle_preview: null
      };
    }
    default:
      return state;
  }
}

export default eventsReducer;
