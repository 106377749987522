import classnames from 'classnames';
import * as React from 'react';

import { msg } from '../../../../../utils';
import { ButtonComponent } from '../../../../shared';
import styles from '../styles/Auth.module.css';

interface IState {
  showCard: boolean;
  headerUp: boolean;
  visible: boolean;
  beforeCall: boolean;
}

interface IProps {
  match: any;
  config: {
    pending: boolean;
    error: any;
    action: any;
  };
}

export default class UnsubscribeComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showCard: true,
      headerUp: false,
      visible: true,
      beforeCall: true
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.config.pending && !this.props.config.pending) {
      this.setState({ beforeCall: false });
    }
  }

  buttonHandler = () => {
    if (this.props.match && this.props.match.params && this.props.match.params.userToken) {
      const { userToken, placeToken } = this.props.match.params;
      this.props.config.action(userToken, placeToken);
    }
  };

  render() {
    let cardClasses = classnames(`card card-login ${styles.card}`, { 'card-hidden': !this.state.showCard });
    const { error } = this.props.config;
    let headerClasses = classnames(`card-header text-center ${styles.header}`, {
      [styles.up]: this.state.headerUp
    });

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form>
              {this.state.beforeCall ? (
                <div className={cardClasses}>
                  <div className={headerClasses} data-background-color="green">
                    <h4 className="card-title">{msg('unsubscribeUserScreen.title', 'Unsubscribe confirmation')}</h4>
                  </div>
                  <div className={`card-content ${styles.content}`}>
                    <div className="form-group" style={{ marginLeft: 20, marginTop: -5, textAlign: 'center' }}>
                      {msg(
                        'unsubscribeUserScreen.beforeMessage',
                        'Click on Unsubscribe button to stop receiving all email notifications from ReGage application.'
                      )}
                    </div>
                    <div className="footer text-center">
                      <ButtonComponent
                        action={this.buttonHandler}
                        label={msg('unsubscribeUserScreen.button', 'Unsubscribe')}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className={cardClasses}>
                  <div className={headerClasses} data-background-color="green">
                    <h4 className="card-title">
                      {error
                        ? msg('unsubscribeUserScreen.titleError', 'Error occurred')
                        : msg('unsubscribeUserScreen.titleSuccess', 'Unsubscribe success')}
                    </h4>
                  </div>
                  <div className={`card-content ${styles.content}`}>
                    <div className="form-group" style={{ marginLeft: 20, marginTop: -5, textAlign: 'center' }}>
                      {error
                        ? msg(
                            'unsubscribeUserScreen.unsubscribeError',
                            `An unexpected error occured. Please try again later.`
                          )
                        : msg(
                            'unsubscribeUserScreen.unsubscribeSuccess',
                            `You have been successfully unsubscribed from emails.`
                          )}
                      {}
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}
