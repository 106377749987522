import {
    AUTOPILOT_LOGS_DATA_FILTERED_STARTED, AUTOPILOT_LOGS_DATA_FILTERED_DONE, AUTOPILOT_LOGS_DATA_FILTERED_ERROR,
    TAutopilotLogsActions, IAutopilotLogsDataFilteredDone, IAutopilotLogsDataFilteredError
} from './actions';
import { ToAutopilotLogsListItem } from './mapper';
import { IAutopilotLogsState } from './reducer.definitions';

const initialState: IAutopilotLogsState = {
    list: {
        items: [],
        loading: false,
        error: ''
    },
    members: {},
    autopilots: {},
    places: {},
    pagination: {
        skip: 0,
        limit: 0,
        page: 0,
        total: 0
    }
};

export const AutopilotLogsReducer = (state = initialState, action: TAutopilotLogsActions): IAutopilotLogsState => {
    switch (action.type) {
        case AUTOPILOT_LOGS_DATA_FILTERED_STARTED:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                    error: ''
                }
            };
        case AUTOPILOT_LOGS_DATA_FILTERED_DONE:
            const data = (action as IAutopilotLogsDataFilteredDone).payload;
            return {
                list: {
                    items: data.results.map(ToAutopilotLogsListItem),
                    loading: false,
                    error: ''
                },
                members: data.members,
                autopilots: data.autopilots,
                places: data.places,
                pagination: {
                    limit: data.limit,
                    page: data.page_number,
                    skip: data.skip,
                    total: data.total_record_count
                }
            };
        case AUTOPILOT_LOGS_DATA_FILTERED_ERROR:
            const error = (action as IAutopilotLogsDataFilteredError).payload;
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error
                }
            };
        default:
            return state;
    }
};
