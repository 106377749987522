import placesActions, { IPlacesActions } from './actions';
import placesReducer from './reducer';
import { PlaceDTO, PaginationDTO, SelectValueDTO } from '../types';

/*
  PlacesStore definition as a namespace that contains actions, reducers, actionTypes and places state
*/
export namespace PlacesStore {
  function getInitialSelectedPlaceState() {
    const placeLocalStorage = localStorage.getItem('selected_place');
    if (placeLocalStorage === null || placeLocalStorage === undefined) {
      return '';
    } else {
      return JSON.parse(placeLocalStorage);
    }
  }

  export const selectedPlaceInitialState = getInitialSelectedPlaceState();

  export function setLocalStorage(state: any | null) {
    if (state !== null) {
      localStorage.removeItem('selected_place');
      localStorage.setItem('selected_place', JSON.stringify(state));
    } else {
      localStorage.removeItem('selected_place');
    }
  }

  export type IState = {
    places_list: PaginationDTO<PlaceDTO>;
    places_list_pending: boolean;
    places_list_error: string | null;
    names_of_places_list: SelectValueDTO[];
    names_of_places_list_pending: boolean;
    names_of_places_list_error: string | null;
    partner_places_list: PaginationDTO<PlaceDTO>;
    partner_places_list_pending: boolean;
    partner_places_list_error: string | null;
    place: PlaceDTO | null;
    place_pending: boolean;
    place_error: string | null;
    place_saved: PlaceDTO | null;
    place_save_pending: boolean;
    place_save_error: string | null;
    place_deleted: boolean;
    place_delete_pending: boolean;
    place_delete_error: string | null;
    selected_place: string | null;
    inherit_autopilots: any;
    inherit_autopilots_pending: boolean;
    inherit_autopilots_error: string | null;
    inherit_rewards: any;
    inherit_rewards_pending: boolean;
    inherit_rewards_error: string | null;
    place_delete_media_pending: boolean;
    place_delete_media_error: string | null;
    place_change_status: any;
    place_change_status_pending: boolean;
    place_change_status_error: string | null;
    unavailableMomentsDaysPending: boolean;
    unavailableMomentsDays: string[];
    add_place_admin_by_id_error: string | null;
    reset_pin_code_pending: boolean;
    reset_pin_code_error: string | null;
  };

  export const initialState: IState = {
    places_list: new PaginationDTO(),
    places_list_pending: false,
    places_list_error: null,
    names_of_places_list: [],
    names_of_places_list_pending: false,
    names_of_places_list_error: null,
    partner_places_list: new PaginationDTO(),
    partner_places_list_pending: false,
    partner_places_list_error: null,
    place: null,
    place_pending: false,
    place_error: null,
    place_saved: null,
    place_save_pending: false,
    place_save_error: null,
    place_deleted: false,
    place_delete_pending: false,
    place_delete_error: null,
    selected_place: selectedPlaceInitialState,
    inherit_autopilots: null,
    inherit_autopilots_pending: false,
    inherit_autopilots_error: null,
    inherit_rewards: null,
    inherit_rewards_pending: false,
    inherit_rewards_error: null,
    place_delete_media_pending: false,
    place_delete_media_error: null,
    place_change_status: null,
    place_change_status_pending: false,
    place_change_status_error: null,
    unavailableMomentsDaysPending: false,
    unavailableMomentsDays: [],
    add_place_admin_by_id_error: null,
    reset_pin_code_pending: false,
    reset_pin_code_error: null
  };

  export enum ActionTypes {
    GET_PLACES = 'GET_PLACES',
    GET_PLACES_SUCCESS = 'GET_PLACES_SUCCESS',
    GET_PLACES_FAILED = 'GET_PLACES_FAILED',
    GET_NAMES_LIST_OF_PLACES = 'GET_NAMES_LIST_OF_PLACES',
    GET_NAMES_LIST_OF_PLACES_SUCCESS = 'GET_NAMES_LIST_OF_PLACES_SUCCESS',
    GET_NAMES_LIST_OF_PLACES_FAILED = 'GET_NAMES_LIST_OF_PLACES_FAILED',
    GET_PARTNER_PLACES = 'GET_PARTNER_PLACES',
    GET_PARTNER_PLACES_SUCCESS = 'GET_PARTNER_PLACES_SUCCESS',
    GET_PARTNER_PLACES_FAILED = 'GET_PARTNER_PLACES_FAILED',
    GET_PLACE = 'GET_PLACE',
    GET_PLACE_SUCCESS = 'GET_PLACE_SUCCESS',
    GET_PLACE_FAILED = 'GET_PLACE_FAILED',
    SAVE_PLACE = 'SAVE_PLACE',
    SAVE_PLACE_SUCCESS = 'SAVE_PLACE_SUCCESS',
    SAVE_PLACE_FAILED = 'SAVE_PLACE_FAILED',
    DELETE_PLACE = 'DELETE_PLACE',
    DELETE_PLACE_SUCCESS = 'DELETE_PLACE_SUCCESS',
    DELETE_PLACE_FAILED = 'DELETE_PLACE_FAILED',
    SELECT_PLACE = 'SELECT_PLACE',
    INHERIT_AUTOPILOTS = 'INHERIT_AUTOPILOTS',
    INHERIT_AUTOPILOTS_SUCCESS = 'INHERIT_AUTOPILOTS_SUCCESS',
    INHERIT_AUTOPILOTS_FAILED = 'INHERIT_AUTOPILOTS_FAILED',
    INHERIT_REWARDS = 'INHERIT_REWARDS',
    INHERIT_REWARDS_SUCCESS = 'INHERIT_REWARDS_SUCCESS',
    INHERIT_REWARDS_FAILED = 'INHERIT_REWARDS_FAILED',
    DELETE_PLACE_MEDIA = 'DELETE_PLACE_MEDIA',
    DELETE_PLACE_MEDIA_SUCCESS = 'DELETE_PLACE_MEDIA_SUCCESS',
    DELETE_PLACE_MEDIA_FAILED = 'DELETE_PLACE_MEDIA_FAILED',
    CHANGE_PLACE_STATUS = 'CHANGE_PLACE_STATUS',
    CHANGE_PLACE_STATUS_SUCCESS = 'CHANGE_PLACE_STATUS_SUCCESS',
    CHANGE_PLACE_STATUS_FAILED = 'CHANGE_PLACE_STATUS_FAILED',
    GET_UNAVAILABLE_MOMENTS_DAYS = 'GET_UNAVAILABLE_MOMENTS_DAYS',
    GET_UNAVAILABLE_MOMENTS_DAYS_SUCCESS = 'GET_UNAVAILABLE_MOMENTS_DAYS_SUCCESS',
    GET_UNAVAILABLE_MOMENTS_DAYS_FAILED = 'GET_UNAVAILABLE_MOMENTS_DAYS_FAILED',
    ADD_PLACE_ADMIN_BY_EMAIL = 'ADD_PLACE_ADMIN_BY_EMAIL',
    ADD_PLACE_ADMIN_BY_EMAIL_SUCCESS = 'ADD_PLACE_ADMIN_BY_EMAIL_SUCCESS',
    ADD_PLACE_ADMIN_BY_EMAIL_FAILED = 'ADD_PLACE_ADMIN_BY_EMAIL_FAILED',
    SET_PLACE = 'SET_PLACE',
    RESET_PIN_CODE = 'RESET_PIN_CODE',
    RESET_PIN_CODE_SUCCESS = 'RESET_PIN_CODE_SUCCESS',
    RESET_PIN_CODE_FAILED = 'RESET_PIN_CODE_FAILED'
  }

  export const actions = placesActions;
  export const reducer = placesReducer;
  export interface IActions extends IPlacesActions {}
}

export * from './actions';
