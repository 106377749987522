import * as React from 'react';
import { Bounce } from 'react-activity';

import { Hidden } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { colors } from '../../../../assets/styles';
import { msg } from '../../../../utils';
import { DateRangePicker } from '../../../shared/DateRangePicker';
import DashboardHeader from './DashboardHeader';
import { AutopilotsTab, EventsTab, RewardsTab, TransactionsTab, VisitsTab } from './dashboardTabs';

interface IProps {
  config: {
    place: any;
    place_statistics: any;
    agg_statistics: any;
    agg_statistics_pending: any;
    agg_statistics_error: any;
    getStatisticsForOnePlaceAction: (placeId: string) => any;
    getPlaceAction: (placeId: string) => any;
    getAllAggStatistics: (placeId: string, starDate: number, endDate: number, type: string) => any;
  };
  match?: any;
  partnerId: string;
}
interface IState {
  tabValue: string | boolean;
  startDate: any;
  endDate: any;
  type: string;
}

const stackedBarOptions = {
  responsive: true,
  scales: {
    x: { stacked: true },
    y: { stacked: true }
  },
  plugins: { legend: { display: false } }
};

const pieChartOptions = {
  tooltips: {
    callbacks: {
      label: (tooltipItem: any, data: any) => {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
        var total = meta.total;
        var currentValue = dataset.data[tooltipItem.index];
        var percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
        return currentValue + ' (' + percentage + '%)';
      },
      title: (tooltipItem: any, data: any) => {
        return data.labels[tooltipItem[0].index];
      }
    }
  },
  plugins: { legend: { display: false } }
};

const styles = {
  color: colors.white,
  background: 'linear-gradient(60deg, #66bb6a, #43a047)',
  boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4)',
  marginTop: -10,
  zIndex: 100
};

let currentDate = new Date();
currentDate.setHours(0, 0, 0, 0);

export default class PlaceDashboardComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tabValue: false,
      startDate: +currentDate - 24 * 60 * 60 * 1000 * 7 * 4, // ultimele 4 saptamani
      endDate: +currentDate,
      type: 'DAY'
    };
  }

  componentDidMount() {
    if (this.props.match?.params.placeId) {
      this.props.config.getPlaceAction(this.props.match.params.placeId);
      this.props.config.getStatisticsForOnePlaceAction(this.props.match.params.placeId);
      this.props.config.getAllAggStatistics(
        this.props.match.params.placeId,
        this.state.startDate,
        this.state.endDate,
        this.state.type
      );
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.match.params.placeId !== prevProps.match.params.placeId) {
      this.props.config.getAllAggStatistics(
        this.props.match.params.placeId,
        this.state.startDate,
        this.state.endDate,
        this.state.type
      );
      this.setState({ tabValue: false });
    }
  }

  handleTabChange = (event: any, tabValue: string) => {
    this.setState({ tabValue });
  };

  getStartEndRange = (start: any, end: any) => {
    const startDate = new Date(start).getTime();
    const endDate = new Date(end).getTime();
    this.setState({
      startDate,
      endDate
    });
    this.props.config.getAllAggStatistics(this.props.match.params.placeId, startDate, endDate, this.state.type);
  };

  onChangeType = (event: any) => {
    const type = event.target.value;
    this.setState({ type });
    this.props.config.getAllAggStatistics(
      this.props.match.params.placeId,
      this.state.startDate,
      this.state.endDate,
      type
    );
  };

  // used for scrollable tabs
  a11yProps = (index: string) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`
    };
  };

  render() {
    const dateRangeDefaultValue = [new Date(this.state.startDate), new Date(this.state.endDate)];
    const localPlace = this.props.config.place || {};
    const placeStatistics = this.props.config.place_statistics || {};
    const dataForHeader = {
      name: localPlace.name || '',
      forPartner: false,
      created: localPlace.created || '',
      shortname: localPlace.vanityname || '',
      placeCity: localPlace.city || '',
      placeIndustry: localPlace.industry ? localPlace.industry.name : '',
      statistics: {
        members: placeStatistics.newMembers || 0,
        checkins: placeStatistics.checkins || 0,
        events: placeStatistics.events?.length || 0
      },
      webOnlyStatistics: {
        rewards: placeStatistics.rewards?.length || 0,
        autopilots: placeStatistics.autopilots?.length || 0,
        paymentsNo: placeStatistics.paymentsNo || 0
      },
      logo: localPlace.currentLogoPhoto || {},
      cover: localPlace.currentCoverPhoto || {},
      featureFlags: localPlace.featureFlags
    };
    const tabValue = this.state.tabValue
      ? this.state.tabValue
      : localPlace.featureFlags?.checkin
      ? 'visits'
      : localPlace.featureFlags?.moments
      ? 'events'
      : localPlace.featureFlags?.simpleAutopilots
      ? 'autopilots'
      : false;
    return (
      ((localPlace || !this.props.config.agg_statistics_pending) && (
        <div>
          {localPlace && <DashboardHeader config={dataForHeader} forSAdmin={false} />}
          <AppBar color="primary" position="static" style={styles}>
            <Tabs
              value={tabValue}
              indicatorColor="secondary"
              onChange={this.handleTabChange}
              style={styles}
              scrollButtons="auto"
              variant="scrollable"
              aria-label="scrollable auto tabs example"
            >
              {localPlace.featureFlags?.checkin && (
                <Tab value="visits" label={msg('sidebar.checkins', 'Visits')} {...this.a11yProps('visits')} />
              )}
              {localPlace.featureFlags?.transactions && (
                <Tab
                  value="transactions"
                  label={msg('sidebar.payments', 'Payments')}
                  {...this.a11yProps('transactions')}
                />
              )}
              {localPlace.featureFlags?.moments && (
                <Tab
                  value="events"
                  label={msg('sidebar.promotionalMaterials', 'Promotional Materials')}
                  {...this.a11yProps('events')}
                />
              )}
              {localPlace.featureFlags?.simpleAutopilots && (
                <Tab
                  value="autopilot"
                  label={msg('dashboardHeader.autopilots', 'Autopilot')}
                  {...this.a11yProps('autopilot')}
                />
              )}
              {localPlace.featureFlags?.checkin && localPlace.featureFlags?.useCoins && (
                <Tab value="rewards" label={msg('sidebar.rewards', 'Rewards')} {...this.a11yProps('rewards')} />
              )}
            </Tabs>
          </AppBar>
          {(localPlace.featureFlags?.becomeMember ||
            localPlace.featureFlags?.moments ||
            localPlace.featureFlags?.simpleAutopilots) && (
            <Hidden smDown={true}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 30
                }}
              >
                <FormControl style={{ minWidth: 250, marginRight: 50 }}>
                  <Select
                    className="bp4-input"
                    value={this.state.type}
                    onChange={this.onChangeType}
                    name="type"
                    displayEmpty={true}
                  >
                    <MenuItem value="" disabled={true}>
                      {msg('charts.selectPeriod', 'Select type of period')}
                    </MenuItem>
                    <MenuItem value={'DAY'}>{msg('intervalTypes.day', 'Day')}</MenuItem>
                    <MenuItem value={'WEEK'}>{msg('intervalTypes.week', 'Week')}</MenuItem>
                    <MenuItem value={'MONTH'}>{msg('intervalTypes.month', 'Month')}</MenuItem>
                  </Select>
                </FormControl>
                <DateRangePicker defaultValue={dateRangeDefaultValue} getRangeData={this.getStartEndRange} />
              </div>
            </Hidden>
          )}
          {tabValue === 'visits' && (
            <VisitsTab
              agg_statistics_error={this.props.config.agg_statistics_error}
              agg_statistics_pending={this.props.config.agg_statistics_pending}
              agg_statistics={this.props.config.agg_statistics}
              type={this.state.type}
            />
          )}
          {tabValue === 'events' && (
            <EventsTab
              placeId={this.props.match.params.placeId}
              agg_statistics_error={this.props.config.agg_statistics_error}
              agg_statistics_pending={this.props.config.agg_statistics_pending}
              agg_statistics={this.props.config.agg_statistics}
              type={this.state.type}
              pieChartOptions={pieChartOptions}
              stackedBarOptions={stackedBarOptions}
            />
          )}
          {tabValue === 'autopilot' && (
            <AutopilotsTab
              placeId={this.props.match.params.placeId}
              partnerId={this.props.partnerId}
              agg_statistics_error={this.props.config.agg_statistics_error}
              agg_statistics_pending={this.props.config.agg_statistics_pending}
              agg_statistics={this.props.config.agg_statistics}
              type={this.state.type}
              pieChartOptions={pieChartOptions}
              stackedBarOptions={stackedBarOptions}
            />
          )}
          {tabValue === 'rewards' && (
            <RewardsTab
              placeId={this.props.match.params.placeId}
              partnerId={this.props.partnerId}
              agg_statistics_error={this.props.config.agg_statistics_error}
              agg_statistics_pending={this.props.config.agg_statistics_pending}
              agg_statistics={this.props.config.agg_statistics}
              type={this.state.type}
              pieChartOptions={pieChartOptions}
              stackedBarOptions={stackedBarOptions}
            />
          )}
          {tabValue === 'transactions' && (
            <TransactionsTab
              agg_statistics_error={this.props.config.agg_statistics_error}
              agg_statistics_pending={this.props.config.agg_statistics_pending}
              agg_statistics={this.props.config.agg_statistics}
              type={this.state.type}
            />
          )}
        </div>
      )) || (
        <div className="w-screen h-screen flex items-center justify-center">
          <Bounce color={colors.lightGreen} />
        </div>
      )
    );
  }
}
