import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { PayBatchesStore } from '.';
import { Server, composedCriteriaBuilder, AlertUtil, msg, logger, queryStringToCriteria } from '../../utils';
import { PaginationDTO, PayBatchAttachment, PayBatchDTO, PlaceDTO } from '../types';
import { AppStore } from '..';
import { adminRoutes } from '../../modules/layouts/routes';

export interface IPayBatchesActions {
  getPayBatchesAction(limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string | object }): any;
  createPayBatchAction(placeIds: string[]): any;
  redirectToPlaceSettlements(payBatchId: string): any;
  downloadPayBatchAction(payBatchId: string, created: string): any;
  markPayBatchSentAction(payBatchId: string): any;
  addPayBatchConfirmationAction(payBatchId: string, attachment: PayBatchAttachment): any;
  downloadPayBatchConfirmationAction(payBatchId: string, uniqueNo: string): any;
  sendConfirmationEmailAction(payBatchId: string): any;
  deletePayBatchAction(payBatchId: string): any;
  getAvailablePlacesListAction(): any;
  clearPayBatchesError(): any;
}

class PayBatchesActions implements IPayBatchesActions {
  getPayBatchesAction(limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PayBatchesStore.ActionTypes.GET_PAY_BATCHES });
      try {
        if (!limit) {
          limit = 30;
        }
        let url = `admin/pay-batches?limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get pay batches action, route:/admin/pay-batches', 'GET');
        const response = (await Server.get(url)) as AxiosResponse;
        dispatch({
          type: PayBatchesStore.ActionTypes.GET_PAY_BATCHES_SUCCESS,
          payload: response.data as PaginationDTO<PayBatchDTO>
        });
      } catch (error) {
        logger.err('Get pay batches action, route:/admin/pay-batches', 'GET');
        AlertUtil.simple(
          msg(
            'reduxMessages.paySettlements.getPayBatchesError',
            'Due to an error, the pay batches list could not be loaded!'
          ),
          'error'
        );
        dispatch({
          type: PayBatchesStore.ActionTypes.GET_PAY_BATCHES_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  createPayBatchAction(placeIds: string[]) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PayBatchesStore.ActionTypes.CREATE_PAY_BATCH });
      logger.msg('Create pay batch action, route:/admin/pay-batches', 'POST');
      Server.post('admin/pay-batches', { placeIds })
        .then(() => {
          dispatch({
            type: PayBatchesStore.ActionTypes.CREATE_PAY_BATCH_SUCCESS
          });
          dispatch(PayBatchesStore.actions.getPayBatchesAction(30, 0, 'created,-1'));
        })
        .catch(error => {
          logger.err('Create pay batch action, route:/admin/pay-batches', 'GET');
          dispatch({
            type: PayBatchesStore.ActionTypes.CREATE_PAY_BATCH_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  redirectToPlaceSettlements(payBatchId: string) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(adminRoutes.BATCHES.subroutes.SETTLEMENTS.path, { payBatchId });
      dispatch(push(path));
    };
  }

  downloadPayBatchAction = (payBatchId: string, created: string) => {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PayBatchesStore.ActionTypes.DOWNLOAD_PAY_BATCH });
      logger.msg('Download pay batch action, route:/admin/pay-batches/batchId/download', 'GET');
      const fileName = `${msg('paymentSettlements.batchExcelTitle', `Settlement ${created}`, {
        created
      })}.csv`;
      Server.downloadReports(`/admin/pay-batches/${payBatchId}/download`, fileName)
        .then(() => {
          dispatch({
            type: PayBatchesStore.ActionTypes.DOWNLOAD_PAY_BATCH_SUCCESS
          });
        })
        .catch(error => {
          logger.err('Download pay batch action, route:/admin/pay-batches/batchId/download', 'GET');
          AlertUtil.simple(
            msg(
              'reduxMessages.paySettlements.downloadPayBatchError',
              'Due to an error, the pay batch could not be downloaded!'
            ),
            'error'
          );
          dispatch({
            type: PayBatchesStore.ActionTypes.DOWNLOAD_PAY_BATCH_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  };

  markPayBatchSentAction(payBatchId: string) {
    return async (dispatch: Dispatch<any>, getState: () => AppStore.states) => {
      dispatch({ type: PayBatchesStore.ActionTypes.MARK_PAY_BATCH_SENT });
      logger.msg('Mark pay batch as sent action, route:/admin/pay-batches/batchId/mark', 'GET');
      Server.get(`admin/pay-batches/${payBatchId}/mark`)
        .then(() => {
          dispatch({
            type: PayBatchesStore.ActionTypes.MARK_PAY_BATCH_SENT_SUCCESS
          });
          AlertUtil.simple(
            msg(
              'reduxMessages.paySettlements.markPayBatchSentSuccess',
              'The pay batch was successfully marked as sent!'
            ),
            'success',
            2000,
            true
          );
          const state = getState();
          const { limit, page_number } = state?.payBatches.pay_batches_list;
          const newCriteria = queryStringToCriteria(window.location.search);
          dispatch(
            PayBatchesStore.actions.getPayBatchesAction(limit * page_number, 0, newCriteria.sort, {
              filters: newCriteria.filters,
              search: ''
            })
          );
        })
        .catch(error => {
          logger.err('Mark pay batch as sent action, route:/admin/pay-batches/batchId/mark', 'GET');
          AlertUtil.simple(
            msg(
              'reduxMessages.paySettlements.markPayBatchSentError',
              'Due to an error, the pay batch could not be marked as sent!'
            ),
            'error',
            2000,
            true
          );
          dispatch({
            type: PayBatchesStore.ActionTypes.MARK_PAY_BATCH_SENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  addPayBatchConfirmationAction(payBatchId: string, attachment: PayBatchAttachment) {
    return async (dispatch: Dispatch<any>, getState: () => AppStore.states) => {
      dispatch({ type: PayBatchesStore.ActionTypes.ADD_PAY_BATCH_CONFIRMATION });
      logger.msg('Add pay batch confirmation action, route:/admin/pay-batches/batchId/mark', 'GET');
      Server.put(`admin/pay-batches/${payBatchId}/confirm`, { attachment })
        .then(() => {
          dispatch({
            type: PayBatchesStore.ActionTypes.ADD_PAY_BATCH_CONFIRMATION_SUCCESS
          });
          AlertUtil.simple(
            msg(
              'reduxMessages.paySettlements.addPayBatchConfirmationSuccess',
              'The pay batch confirmation was successfully uploaded!'
            ),
            'success',
            2000,
            true
          );
          const state = getState();
          const { limit, page_number } = state?.payBatches.pay_batches_list;
          const newCriteria = queryStringToCriteria(window.location.search);
          dispatch(
            PayBatchesStore.actions.getPayBatchesAction(limit * page_number, 0, newCriteria.sort, {
              filters: newCriteria.filters,
              search: ''
            })
          );
        })
        .catch(error => {
          logger.err('Add pay batch confirmation action, route:/admin/pay-batches/batchId/mark', 'GET');
          AlertUtil.simple(
            msg(
              'reduxMessages.paySettlements.addPayBatchConfirmationError',
              'Due to an error, the pay batch confirmation could not be uploaded!'
            ),
            'error',
            2000,
            true
          );
          dispatch({
            type: PayBatchesStore.ActionTypes.ADD_PAY_BATCH_CONFIRMATION_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  downloadPayBatchConfirmationAction = (payBatchId: string, originalName: string) => {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PayBatchesStore.ActionTypes.DOWNLOAD_PAY_BATCH });
      logger.msg('Download pay batch confirmation action, route:/admin/pay-batches/batchId/download', 'GET');
      const fileName = originalName;
      Server.downloadReports(`/admin/pay-batches/${payBatchId}/download-confirmation`, fileName)
        .then(() => {
          dispatch({
            type: PayBatchesStore.ActionTypes.DOWNLOAD_PAY_BATCH_SUCCESS
          });
        })
        .catch(error => {
          logger.err('Download pay batch confirmation action, route:/admin/pay-batches/batchId/download', 'GET');
          AlertUtil.simple(
            msg(
              'reduxMessages.paySettlements.downloadPayBatchConfirmationError',
              'Due to an error, the pay batch confirmation could not be downloaded!'
            ),
            'error'
          );
          dispatch({
            type: PayBatchesStore.ActionTypes.DOWNLOAD_PAY_BATCH_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  };

  sendConfirmationEmailAction(payBatchId: string) {
    return async (dispatch: Dispatch<any>, getState: () => AppStore.states) => {
      logger.msg('Send confirmation email action, route:/admin/pay-batches/batchId/send-email', 'GET');
      AlertUtil.simple(
        msg(
          'reduxMessages.paySettlements.sendConfirmationEmail',
          'The confirmation emails are being sent. This may take a while, please come back in a few minutes to check the status.'
        ),
        'info',
        3000,
        true
      );
      dispatch({ type: PayBatchesStore.ActionTypes.SEND_CONFIRMATION_EMAIL });
      Server.get(`admin/pay-batches/${payBatchId}/send-report`)
        .then(() => {
          dispatch({
            type: PayBatchesStore.ActionTypes.SEND_CONFIRMATION_EMAIL_SUCCESS
          });
        })
        .catch(error => {
          logger.err('Send confirmation email action, route:/admin/pay-batches/batchId/send-email', 'GET');
          dispatch({
            type: PayBatchesStore.ActionTypes.SEND_CONFIRMATION_EMAIL_FAILED,
            payload: Server.errorParse(error)
          });
        });
      setTimeout(() => {
        const state = getState();
        const { limit, page_number } = state?.payBatches.pay_batches_list;
        const newCriteria = queryStringToCriteria(window.location.search);
        dispatch(
          PayBatchesStore.actions.getPayBatchesAction(limit * page_number, 0, newCriteria.sort, {
            filters: newCriteria.filters,
            search: ''
          })
        );
        // tslint:disable-next-line: align
      }, 1000);
    };
  }

  deletePayBatchAction(payBatchId: string) {
    return (dispatch: Dispatch<any>, getState: () => AppStore.states) => {
      logger.msg('Delete pay batch action, route:/admin/pay-batches/payBatchId', 'DELETE');
      AlertUtil.simple(msg('reduxMessages.paySettlements.deletePayBatch', 'The pay batch is being deleted...'), 'info');
      dispatch({
        type: PayBatchesStore.ActionTypes.DELETE_PAY_BATCH
      });
      Server.delete(`admin/pay-batches/${payBatchId}`)
        .then(() => {
          dispatch({
            type: PayBatchesStore.ActionTypes.DELETE_PAY_BATCH_SUCCESS
          });
          AlertUtil.updateContent(
            msg('reduxMessages.paySettlements.deletePayBatchSuccess', 'The pay batch has been successfully deleted!'),
            'success'
          );
          const state = getState();
          const { limit, page_number } = state?.payBatches.pay_batches_list;
          const newCriteria = queryStringToCriteria(window.location.search);
          dispatch(PayBatchesStore.actions.getPayBatchesAction(limit * page_number, 0, newCriteria.sort));
        })
        .catch(error => {
          logger.err('Delete place action, route:/admin/pay-batches/payBatch', 'DELETE');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.paySettlements.deletePayBatchError',
              'Due to an error, the pay batch could not be deleted!'
            ),
            'error'
          );
          dispatch({
            type: PayBatchesStore.ActionTypes.DELETE_PAY_BATCH_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getAvailablePlacesListAction() {
    return async (dispatch: Dispatch<any>) => {
      logger.msg('Get available places for pay batches list action, route:/admin/pay-batches/available-places', 'GET');
      dispatch({ type: PayBatchesStore.ActionTypes.GET_AVAILABLE_PLACES });
      try {
        const response: any = await Server.get('admin/pay-batches/available-places');
        dispatch({
          type: PayBatchesStore.ActionTypes.GET_AVAILABLE_PLACES_SUCCESS,
          payload: response.data as Array<PlaceDTO>
        });
      } catch (error) {
        logger.err(
          'Get available places for pay batches list action, route:/admin/pay-batches/available-places',
          'GET'
        );
        dispatch({
          type: PayBatchesStore.ActionTypes.GET_AVAILABLE_PLACES_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  clearPayBatchesError() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: PayBatchesStore.ActionTypes.CLEAR_PAY_BATCHES_ERROR });
    };
  }
}

const payBatchesActions = new PayBatchesActions();
export default payBatchesActions;
