import * as React from 'react';
import { AlertUtil, msg, getFormatDate } from '../../../../utils';
import { CardComponent, ButtonComponent, ExportModalComponent, InfiniteScrollListComponent } from '../../../shared';
import {
  PaginationDTO,
  EventDTO,
  EventType,
  PlaceDTO,
  RewardType,
  SettlementDTO,
  RaffleIntervalDTO,
  ParticipantsType
} from '../../../../appRedux/types';
import { Hidden, withWidth } from '@material-ui/core';
import { SettlementsModal, RewardStatisticsComponent, RaffleModal } from '.';

interface IProps {
  config: {
    get: (
      placeId: string,
      showExpired: boolean,
      limit?: Number,
      skip?: Number,
      sort?: string,
      criteria?: { [key: string]: any }
    ) => {};
    list: PaginationDTO<any>;
    pending: boolean;
    events_list_expired: boolean;
    save_pending: boolean;
    error: string | null;
    event_change_status_pending: boolean;
    event_delete_pending: boolean;
    delete: (eventId: string, partnerId: string, placeId: string) => any;
    cloneEventAction: (eventId: string, placeId: string) => any;
    redirectEdit: (eventId: string, placeId: string) => any;
    redirectAdd: (placeId: string) => any;
    editEventStatusAction: (placeId: string, eventId: string, active: any) => any;
    clearPlaceEventsError: () => any;
    place: PlaceDTO | null;
    export_events_pending: boolean;
    export_events_error: string | null;
    exportEventsInExcel: (placeId: string, startDate: number, endDate: number) => any;
    campaign_members: PaginationDTO<any>;
    campaign_members_pending: boolean;
    campaign_members_error: string | null;
    getMemberStatsForCampaign: (
      placeId: string,
      eventId: string,
      limit?: Number,
      skip?: Number,
      sort?: string,
      criteria?: { [key: string]: string }
    ) => any;
    partner_settlements: PaginationDTO<any>;
    partner_settlements_pending: boolean;
    partner_settlements_error: string | null;
    download_settlements_pending: boolean;
    settlement: SettlementDTO | null;
    getPartnerSettlements: (
      partnerId: string,
      eventId: string,
      limit?: number,
      skip?: number,
      sort?: string,
      criteria?: any
    ) => any;
    sendSettlementEmail: (partnerId: string, settlementId: string) => any;
    sendAllSettlementEmails: (partnerId: string, eventId: string) => any;
    approveSettlementEmail: (partnerId: string, settlementId: string) => any;
    downloadSettlementsPdf: (partnerId: string, eventId: string, settlementId?: string, placeId?: string) => any;
    raffle_preview: RaffleIntervalDTO[] | null;
    raffle_preview_pending: boolean;
    update_raffle_preview_pending: boolean;
    update_raffle_preview_error: string | null;
    getRaffleIntervals: (placeId: string, eventId: string) => any;
    editRaffleIntervals: (placeId: string, eventId: string, intervals: RaffleIntervalDTO[], editDate: number) => any;
    setEventsListExpiredAction: (expired: boolean) => any;
    clearRaffleIntervals: () => any;
    clearEventStatsModalData: () => any;
  };
  match?: any;
  partnerId: string;
  width: string;
}
interface IState {
  event: EventDTO | null;
  showExportModal: boolean;
  showStatisticsModal: boolean;
  showSettlementsModal: boolean;
  showRaffleModal: boolean;
}

class PlaceEventsListComponent extends React.Component<IProps, IState> {
  listRef: any;
  constructor(props: IProps) {
    super(props);
    this.state = {
      event: null,
      showExportModal: false,
      showStatisticsModal: false,
      showSettlementsModal: false,
      showRaffleModal: false
    };
    this.listRef = React.createRef();
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.config.update_raffle_preview_pending &&
      !this.props.config.update_raffle_preview_pending &&
      !this.props.config.update_raffle_preview_error
    ) {
      this.toggleRaffleModal();
    }
  }

  componentWillUnmount() {
    this.props.config.clearPlaceEventsError();
  }

  getEvents = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    if (this.props.match?.params.placeId) {
      this.props.config.get(
        this.props.match.params.placeId,
        this.props.config.events_list_expired,
        limit,
        skip,
        sort,
        criteria
      );
    }
  };

  isData() {
    return this.props.config.list && this.props.config.list.results && this.props.config.list.results.length;
  }

  parseEvents(list: PaginationDTO<any>): any {
    if (!this.isData()) {
      return [];
    }
    return {
      ...list,
      results: list.results.map((item: any) => {
        const stats = item.stats.delivered ? Math.round((item.stats.opens / item.stats.delivered) * 100) : 0;
        return {
          title: item.title,
          type: item.visibleType,
          typeLabel:
            item.visibleType === EventType.EVENT
              ? msg('eventTypes.event', 'Event')
              : item.visibleType === EventType.NEWS
              ? msg('eventTypes.news', 'News')
              : msg('eventTypes.promotion', 'Promotion'),
          _id: item._id,
          initialStartDate: item.startDate,
          initialEndDate: item.endDate,
          startDate: getFormatDate(item.startDate, 'hour'),
          endDate: getFormatDate(item.endDate, 'hour'),
          delivered: item.stats && item.stats.delivered,
          opened: `${item.stats && item.stats.opens} (${stats} %)`,
          triggered: item.type === EventType.EVENT ? 'N/A' : item.autopilotStats?.triggered,
          used: item.type === EventType.EVENT ? 'N/A' : item.autopilotStats?.used,
          isActive: item.isActive,
          rewards: item.rewards,
          places: item.places,
          participantsType: item.participantsType,
          raffle: item.raffle
        };
      })
    };
  }

  addAction = () => {
    if (this.props.match?.params.placeId) {
      this.props.config.redirectAdd(this.props.match.params.placeId);
    }
  };

  editAction = (event: any) => {
    if (this.props.match?.params.placeId) {
      this.props.config.redirectEdit(event.currentTarget.getAttribute('id'), this.props.match.params.placeId);
    }
  };

  cloneAction = (event: any) => {
    const eventId = event.currentTarget.getAttribute('id');
    this.props.config.cloneEventAction(eventId, this.props.match.params.placeId);
    this.props.config.redirectAdd(this.props.match.params.placeId);
  };

  deleteAction = async (event: any) => {
    const id = event.currentTarget.getAttribute('id');
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.eventDeleteConfirmation', 'You are about to delete a promotional material!')
    );
    if (hasConfirmation && this.props.match?.params.placeId) {
      this.props.config.delete(id, this.props.partnerId, this.props.match.params.placeId);
    }
  };

  viewStatisticsAction = (event: any) => {
    this.setState({ event });
    this.toggleStatisticsModal();
  };

  viewSettlementsAction = (event: any) => {
    this.setState({ event });
    this.toggleSettlementsModal();
  };

  viewRaffleAction = (event: any) => {
    this.setState({ event }, this.toggleRaffleModal);
  };

  getActions = () => {
    if (this.props.width === 'xs') {
      return [];
    }
    return [
      {
        label: msg('general.edit', 'Edit'),
        btn: 'btn-success',
        icon: 'edit',
        onClick: this.editAction
      },
      {
        label: msg('event.configureRaffle', 'Configure Raffle'),
        btn: 'btn-light',
        icon: 'settings',
        returnFields: [''],
        isShown: (item: EventDTO) => item.participantsType === ParticipantsType.RANDOM,
        onClick: this.viewRaffleAction
      },
      {
        label: msg('event.rewardStatistics', 'Reward statistics'),
        btn: 'btn-info',
        icon: 'info',
        returnFields: [''],
        isShown: (item: EventDTO) => item.type === EventType.PROMOTION,
        onClick: this.viewStatisticsAction
      },
      {
        label: msg('event.voucherSettlement', 'Voucher settlements'),
        btn: 'btn-primary',
        icon: 'assessment',
        returnFields: [''],
        isShown: (item: EventDTO) =>
          !!item.places?.length &&
          item.type === EventType.PROMOTION &&
          item.rewards.findIndex(reward => reward.type === RewardType.VOUCHER) > -1,
        onClick: this.viewSettlementsAction
      },
      {
        label: msg('event.cloneEvent', 'Clone Event'),
        btn: 'btn-secondary',
        icon: 'file_copy',
        onClick: this.cloneAction
      },
      {
        label: msg('general.delete', 'Delete'),
        btn: 'btn-danger',
        icon: 'delete',
        ref: 'deleteEvent',
        onClick: this.deleteAction
      }
    ];
  };

  getFilters = () => {
    let filters = [
      {
        field: 'isActive',
        value: [
          {
            _id: '',
            name: msg('eventTypes.allPromotionalMaterials', 'All promotional materials')
          },
          {
            _id: 'true',
            name: msg('eventTypes.active', 'Active')
          },
          {
            _id: 'false',
            name: msg('eventTypes.inactive', 'Inactive')
          }
        ],
        default: 'true'
      }
    ];
    if (this.props.config.place?.featureFlags?.checkin) {
      filters.push({
        field: 'type',
        value: [
          {
            _id: '',
            name: msg('eventTypes.allPromotionalTypes', 'All types')
          },
          {
            _id: '0',
            name: msg('eventTypes.events', 'Events')
          },
          {
            _id: '1',
            name: msg('eventTypes.promotions', 'Promotions')
          }
        ],
        default: ''
      });
      if (this.props.config.place?.featureFlags?.news) {
        filters[1].value.push({
          _id: '3',
          name: msg('eventTypes.newsFilter', 'News')
        });
      }
    }
    return filters;
  };

  handleChangeStatus = (itemId: string) => (event: any) => {
    if (this.props.match.params && this.props.config.editEventStatusAction) {
      this.props.config.editEventStatusAction(this.props.match.params.placeId, itemId, {
        _id: itemId,
        isActive: event.target.checked
      });
    }
  };

  toggleExportModal = () => {
    const show = this.state.showExportModal;
    this.setState({ showExportModal: !show });
  };

  exportInExcel = (startDate: number, endDate: number) => {
    const placeId = this.props.match?.params.placeId;
    this.props.config.exportEventsInExcel(placeId, startDate, endDate);
  };

  toggleStatisticsModal = () => {
    const show = this.state.showStatisticsModal;
    this.setState({ showStatisticsModal: !show });
    if (this.state.showStatisticsModal) {
      this.props.config.clearEventStatsModalData();
    }
  };

  toggleSettlementsModal = () => {
    const show = this.state.showSettlementsModal;
    this.setState({ showSettlementsModal: !show });
    if (this.state.showSettlementsModal) {
      this.props.config.clearEventStatsModalData();
    }
  };

  toggleRaffleModal = () => {
    const show = this.state.showRaffleModal;
    this.setState({ showRaffleModal: !show });
    if (!show) {
      this.props.config.getRaffleIntervals(this.props.config.place?._id || '', this.state.event?._id || '');
    } else {
      this.props.config.clearRaffleIntervals();
    }
  };

  handleRaffleSave = (preview: RaffleIntervalDTO[], modalDate: number) => {
    let editedPreview: RaffleIntervalDTO[] = [];
    preview?.forEach((interval: RaffleIntervalDTO, index: number) => {
      if (this.props.config.raffle_preview && interval.prizes !== this.props.config.raffle_preview[index].prizes) {
        editedPreview.push(interval);
      }
    });
    if (editedPreview.length) {
      this.props.config.editRaffleIntervals(
        this.props.config.place?._id || '',
        this.state.event?._id || '',
        editedPreview,
        modalDate
      );
    } else {
      this.toggleRaffleModal();
    }
  };

  handleExpiredCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.config.setEventsListExpiredAction(!!event.target.checked);
    this.listRef?.setSearchByValue();
  };

  render() {
    const propsConfig = this.props.config;
    const list = this.parseEvents(this.props.config.list);
    const exportModalConfig = {
      title: msg('exportExcel.exportExcelEvents', 'Export place promotional materials'),
      pending: this.props.config.export_events_pending,
      error: this.props.config.export_events_error,
      toggleModal: this.toggleExportModal,
      exportInExcel: this.exportInExcel
    };
    return (
      <CardComponent
        title={msg('cardTitle.promotionalMaterials', 'Promotional Materials')}
        error={!!this.props.config.error}
        headerIcon="event"
        needsTitle={true}
        buttons={
          this.props.config.place?.featureFlags.exportInExcel
            ? [
                {
                  label: msg('exportExcel.exportExcel', 'Export Excel'),
                  icon: 'file_download',
                  onClick: this.toggleExportModal
                }
              ]
            : undefined
        }
        checkboxes={[
          {
            label: msg('event.listExpired', 'Show expired events'),
            checked: this.props.config.events_list_expired,
            onChange: this.handleExpiredCheckbox
          }
        ]}
      >
        {this.state.showExportModal && <ExportModalComponent config={exportModalConfig} />}
        {this.state.showStatisticsModal && (
          <RewardStatisticsComponent
            members={propsConfig.campaign_members}
            members_pending={propsConfig.campaign_members_pending}
            members_error={propsConfig.campaign_members_error}
            placeId={this.props.match?.params?.placeId}
            event={this.state.event}
            getMembers={propsConfig.getMemberStatsForCampaign}
            toggleModal={this.toggleStatisticsModal}
          />
        )}
        {this.state.showRaffleModal && (
          <RaffleModal
            placeId={this.props.match?.params?.placeId}
            eventId={this.state.event?._id || ''}
            raffle_preview={propsConfig.raffle_preview}
            raffle_preview_pending={propsConfig.raffle_preview_pending}
            update_raffle_preview_pending={propsConfig.update_raffle_preview_pending}
            totalPrizes={this.state.event?.raffle?.totalPrizes}
            editMode={true}
            getRaffleIntervals={propsConfig.getRaffleIntervals}
            toggleModal={this.toggleRaffleModal}
            handleSave={this.handleRaffleSave}
          />
        )}
        {this.state.showSettlementsModal && (
          <SettlementsModal
            settlement={propsConfig.settlement}
            settlements={propsConfig.partner_settlements}
            settlements_pending={propsConfig.partner_settlements_pending}
            settlements_error={propsConfig.partner_settlements_error}
            download_settlements_pending={propsConfig.download_settlements_pending}
            partnerId={this.props.partnerId}
            event={this.state.event}
            getSettlements={propsConfig.getPartnerSettlements}
            sendSettlementEmail={propsConfig.sendSettlementEmail}
            approveSettlementEmail={propsConfig.approveSettlementEmail}
            sendAllSettlementEmails={propsConfig.sendAllSettlementEmails}
            downloadSettlementsPdf={propsConfig.downloadSettlementsPdf}
            toggleModal={this.toggleSettlementsModal}
          />
        )}
        <InfiniteScrollListComponent
          wrappedComponentRef={(ref: any) => (this.listRef = ref)}
          fields={{
            typeLabel: msg('event.type', 'Type'),
            title: msg('event.title', 'Title'),
            startDate: msg('event.startDate', 'Start date'),
            endDate: msg('event.endDate', 'End date'),
            delivered: msg('event.delivered', 'Sent'),
            opened: msg('event.opened', 'Opened'),
            triggered: msg('event.triggered', 'Triggered'),
            used: msg('event.used', 'Used')
          }}
          actions={this.getActions()}
          list={list}
          pending={propsConfig.pending || propsConfig.event_delete_pending || propsConfig.event_change_status_pending}
          error={propsConfig.error}
          get={this.getEvents}
          sort={{
            fields: ['startDate'],
            default: 'created,-1'
          }}
          search={['title']}
          filters={this.getFilters()}
          toggles={[
            {
              name: msg('place.isActive', 'Active'),
              onChange: this.handleChangeStatus,
              field: 'isActive',
              disabled: !propsConfig.place?.isActive,
              tooltip: !propsConfig.place?.isActive ? msg('event.inactivePlace', 'Your place is inactive!') : ''
            }
          ]}
          hasCardButton={true}
        />
        <Hidden smDown={true}>
          <div className="row">
            <div className="col-md-3" />
            <div className="col-md-6" style={{ textAlign: 'center' }}>
              <div className="form-group form-button">
                <ButtonComponent
                  label={msg('cardTitle.addEvent', 'Add promotional material')}
                  icon="event"
                  action={this.addAction}
                  id={this.props.partnerId}
                />
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        </Hidden>
      </CardComponent>
    );
  }
}
export default withWidth()(PlaceEventsListComponent);
