import * as React from 'react';

import { CardComponent, AdvancedListComponent } from '../../../shared';
import { UserFormComponent } from './forms';
import { UserDTO, PaginationDTO, EventDTO, PlaceDTO, UserRegisterType, TagDTO } from '../../../../appRedux/types';
import { msg, getFormatDate } from '../../../../utils';
import { UserActivityContainer } from '../../activity/UserActivity';
import { TransactionsListContainer } from '../../transactions';
import imagePlaceholder from '../../../../assets/images/galleryPlaceholder.jpg';

interface IProps {
  config: {
    pending: boolean;
    error: string | null;
    loaded: UserDTO | null;
    load_pending: boolean;
    load_error: string | null;
    loadedPlace?: PlaceDTO | null;
    add: (user: UserDTO, id: string, forSA?: boolean) => any;
    get: (userId: string) => any;
    edit: (user: UserDTO) => any;
    changePasswordAsAdmin: (userId: string, newPassword: string) => any;
    getMembershipsForUser?: (
      userId: string,
      limit?: Number,
      skip?: Number,
      sort?: string,
      criteria?: { [key: string]: string }
    ) => any;
    memberships_for_user_list?: PaginationDTO<any>;
    memberships_for_user_list_pending?: boolean;
    memberships_for_user_list_error?: string | null;
    changeMemberStatus?: (forSAdmin: boolean, placeId: string, memberId: string, status: number) => any;
    favouriteMoments?: PaginationDTO<EventDTO>;
    favouriteMomentsPending?: boolean;
    favouriteMomentsError?: string | null;
    fetchFavouriteMoments?: (memberId: string, options?: any) => any;
    tags_list?: PaginationDTO<TagDTO>;
    getTagsAction?: (forSAdmin: boolean) => any;
  };
  match?: any;
  editMode: boolean;
  forSA?: boolean;
  forPartner?: boolean;
}
interface IState {}

export default class UsersEditComponent extends React.Component<IProps, IState> {
  componentDidMount() {
    if (this.props.editMode && this.props.match?.params.userId) {
      this.props.config.get(this.props.match.params.userId);
      if (this.props.config.getTagsAction) {
        this.props.config.getTagsAction(!!this.props.forSA);
      }
    }
  }

  getMemberships = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    if (this.props.config.getMembershipsForUser) {
      this.props.config.getMembershipsForUser(this.props.match.params.userId, limit, skip, sort, criteria);
    }
  };

  getFavouriteMoments = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    if (this.props.config.fetchFavouriteMoments) {
      const userId = this.props.match.params.userId;
      const options = { limit, skip, sort, criteria };
      this.props.config.fetchFavouriteMoments(userId, options);
    }
  };

  saveUserHandler = (user: UserDTO) => {
    const { config } = this.props;
    if (this.props.editMode) {
      config.edit(user);
    } else if (!this.props.editMode && this.props.forPartner && this.props.match?.params?.partnerId) {
      config.add(user, this.props.match?.params?.partnerId);
    } else if (!this.props.editMode && !this.props.forPartner && this.props.match?.params?.placeId) {
      config.add(user, this.props.match?.params?.placeId, this.props.forSA);
    }
  };

  userChangeHandler = (user: UserDTO) => {
    this.setState({ user });
  };

  renderUserForm = () => {
    const propsConfig = this.props.config;
    const userData = this.props.editMode ? propsConfig.loaded : new UserDTO();
    return (
      <CardComponent
        title={this.props.editMode ? msg('cardTitle.editUser', 'Edit User') : msg('cardTitle.addUser', 'Add User')}
        pending={propsConfig.load_pending}
        error={!!propsConfig.load_error}
        headerIcon={this.props.editMode ? 'edit' : 'person_add'}
        needsTitle={true}
      >
        <UserFormComponent
          user={userData}
          save={this.saveUserHandler}
          pending={propsConfig.pending}
          output={false}
          onChange={this.userChangeHandler}
          error={propsConfig.error}
          changePasswordAsAdmin={propsConfig.changePasswordAsAdmin}
          editMode={this.props.editMode}
          tags_list={propsConfig.tags_list}
        />
      </CardComponent>
    );
  };

  renderUserInfo = () => {
    const propsConfig = this.props.config;
    const userData = propsConfig.loaded || new UserDTO();
    return (
      <CardComponent
        title={msg('cardTitle.userInfo', 'User Details')}
        pending={propsConfig.load_pending}
        error={!!propsConfig.load_error}
        headerIcon="manage_accounts"
        needsTitle={true}
      >
        <div className="row" style={{ marginBottom: 10 }}>
          <div className="col-sm-1" />
          <div
            className="col-sm-5"
            style={{
              paddingLeft: 35,
              display: 'flex',
              height: 150,
              alignItems: 'center'
            }}
          >
            <img
              src={userData.media?.length ? userData.media[0].url : imagePlaceholder}
              alt="placeholder"
              style={{
                height: '100%',
                width: 'auto'
              }}
            />
          </div>
          <div className="col-sm-5">
            {['birthday', 'created', 'updatedAt'].map(item => (
              <h4 key={item}>
                <strong>{msg(`userInfo.${item}`, item)}</strong>:{' '}
                {userData[item] ? getFormatDate(userData[item], 'date') : 'N/A'}
              </h4>
            ))}
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-5" style={{ paddingLeft: 35 }}>
            <h4>
              <strong>{msg('userInfo.registerType', 'Register Type')}: </strong>{' '}
              {UserRegisterType[userData.registerType || 0]}
            </h4>
            {['phoneStatus', 'emailStatus', 'referralCode', 'referralsNo'].map(item => (
              <h4 key={item}>
                <strong>{msg(`userInfo.${item}`, item)}</strong>: {userData[item] || 'N/A'}
              </h4>
            ))}
            {userData.referrerCodeReceived && (
              <h4>
                <strong>{msg('userInfo.referrerCodeReceived', 'Referrer Code Received')}</strong>:{' '}
                {userData.referrerCodeReceived}
              </h4>
            )}
            <h4>
              <strong>{msg('userInfo.language', 'Language')}</strong>: {userData.settings?.language || 'N/A'}
            </h4>
            {userData.malvenskyHash && userData.malvenskySalt && (
              <h4>
                <strong>{msg('userInfo.malvensky', 'Malvensky')}</strong>: {msg('general.yes', 'Yes')}
              </h4>
            )}
          </div>
          <div className="col-sm-5">
            <h4>
              <strong>{msg('userInfo.deviceInfo', 'Device Info:')}</strong>
              <div style={{ marginLeft: 20 }}>
                {userData.deviceInfo &&
                  Object.keys(userData.deviceInfo).map((key: string, index: number) => {
                    return (
                      <div key={index}>
                        {msg(`userInfo.${key}`, key)}: {(userData.deviceInfo && userData.deviceInfo[key]) || 'N/A'}
                      </div>
                    );
                  })}
              </div>
            </h4>
            <h4>
              <strong>{msg('userInfo.permissions', 'Permissions')}</strong>
              <div style={{ marginLeft: 20 }}>
                {userData.settings?.permissions &&
                  Object.keys(userData.settings?.permissions).map((key: string, index: number) => (
                    <div key={index}>
                      {msg(`userInfo.${key}`, key)}:{' '}
                      {userData.settings?.permissions && userData.settings?.permissions[key].status}
                    </div>
                  ))}
              </div>
            </h4>
            <div className="col-sm-1" />
          </div>
        </div>
      </CardComponent>
    );
  };

  renderMembershipsForUser = () => {
    const propsConfig = this.props.config;
    // I loathe that I have to do this hack in order to extract both memberId and placeId
    const memberships = {
      ...propsConfig.memberships_for_user_list,
      results:
        propsConfig.memberships_for_user_list?.results?.map(membership => ({
          ...membership,
          created: getFormatDate(membership.created),
          id: membership._id,
          _id: `${membership._id},${membership.placeId}`
        })) || []
    };
    return (
      <CardComponent
        title={msg('cardTitle.memberships', 'Memberships')}
        pending={propsConfig.load_pending}
        error={!!propsConfig.load_error}
        headerIcon={'group'}
        needsTitle={true}
      >
        <AdvancedListComponent
          fields={{
            placeName: msg('membership.placeName', 'Place Name'),
            points: msg('membership.points', 'Points'),
            checkinsCount: msg('membership.checkins', 'checkins'),
            created: msg('membership.memberSince', 'Member since')
          }}
          list={memberships}
          actions={[]}
          select={[
            {
              name: msg('membership.status', 'Status'),
              onChange: (ids: string) => (event: any) => {
                const [memberId, placeId] = ids.split(',');
                const status = event.target.value;
                if (this.props.config.changeMemberStatus) {
                  this.props.config.changeMemberStatus(true, placeId, memberId, status);
                }
              },
              field: 'status',
              options: [
                { _id: 0, name: msg('memberTypes.inactive', 'Inactive') },
                { _id: 1, name: msg('memberTypes.new', 'New') },
                { _id: 2, name: msg('memberTypes.regular', 'Regular') },
                { _id: 3, name: msg('memberTypes.vip', 'VIP') },
                { _id: 4, name: msg('memberTypes.flagged', 'Flagged') }
              ]
            }
          ]}
          pending={!!propsConfig.memberships_for_user_list_pending}
          error={propsConfig.memberships_for_user_list_error || null}
          disableQueryParams={true}
          limit={10}
          search={['placeName']}
          get={this.getMemberships}
          sort={{
            fields: ['placeName', 'points', 'checkinsCount'],
            default: 'placeName,1'
          }}
        />
      </CardComponent>
    );
  };

  renderUserFavoriteMoments = () => {
    const propsConfig = this.props.config;
    const favouriteMoments = {
      ...propsConfig.favouriteMoments,
      results: (propsConfig.favouriteMoments?.results || []).map(moment => ({
        placeName: moment.placeNames[0],
        title: moment.title
      }))
    };
    return (
      <CardComponent
        title={msg('cardTitle.favouriteMoments', 'Favourite moments')}
        pending={propsConfig.load_pending}
        error={!!propsConfig.load_error}
        headerIcon={'event'}
        needsTitle={true}
      >
        <AdvancedListComponent
          fields={{
            placeName: msg('favouriteMoments.placeName', 'Place Name'),
            title: msg('favouriteMoments.momentName', 'Moment Name')
          }}
          list={favouriteMoments}
          pending={this.props.config.favouriteMomentsPending}
          error={this.props.config.favouriteMomentsError}
          disableQueryParams={true}
          limit={10}
          search={['placeNames']}
          get={this.getFavouriteMoments}
        />
      </CardComponent>
    );
  };

  renderUserActivity() {
    return <UserActivityContainer />;
  }

  renderUserTransactions = () => {
    return <TransactionsListContainer isSAdmin={true} forUser={true} userId={this.props.match.params.userId} />;
  };

  render() {
    return (
      <div>
        {this.renderUserForm()}
        {this.props.editMode && this.renderUserInfo()}
        {this.props.editMode && this.renderMembershipsForUser()}
        {this.props.editMode && this.renderUserFavoriteMoments()}
        {this.props.editMode && this.renderUserTransactions()}
        {this.props.editMode && this.renderUserActivity()}
      </div>
    );
  }
}
