import { Dispatch } from 'redux';

import { AgendaJobsStore } from '.';
import { Server, composedCriteriaBuilder, AlertUtil, msg, logger } from '../../utils';
import { AgendaJobDTO, PaginationDTO } from '../types';

export interface IAgendaJobsActions {
  getAgendaJobsAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }): any;
  clearAgendaJobsError(): any;
}

class AgendaJobsActions implements IAgendaJobsActions {
  getAgendaJobsAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: AgendaJobsStore.ActionTypes.GET_AGENDA_JOBS });
      try {
        if (!limit) {
          limit = 30;
        }
        let url = `admin/agenda-jobs?pagination=true&limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg(`Get agenda jobs action, route:/admin/agenda-jobs`, 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: AgendaJobsStore.ActionTypes.GET_AGENDA_JOBS_SUCCESS,
          payload: response.data as PaginationDTO<AgendaJobDTO>
        });
      } catch (error) {
        logger.err('Get agenda jobs action, route:/admin/agenda-jobs', 'GET');
        AlertUtil.updateContent(
          msg(
            'reduxMessages.agendaJobs.getAgendaJobsError',
            'Due to an error, the agenda jobs list could not be loaded!'
          ),
          'error'
        );
        dispatch({
          type: AgendaJobsStore.ActionTypes.GET_AGENDA_JOBS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  clearAgendaJobsError() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: AgendaJobsStore.ActionTypes.CLEAR_AGENDA_JOBS_ERROR });
    };
  }
}

const agendaJobsActions = new AgendaJobsActions();
export default agendaJobsActions;
