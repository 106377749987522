import {
    ArcElement, BarElement, CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale,
    LineElement, PointElement, Title, Tooltip
} from 'chart.js';
import * as React from 'react';
import { Bar, Line, Pie, Radar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface IProps {
  data: any;
  chartType: string;
  chartWidth?: number;
  chartHeight?: number;
  options?: any;
  startDate?: any;
  endDate?: any;
}
interface IState {
  chartData: any;
}

export default class ChartComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      chartData: this.props.data || ''
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        chartData: this.props.data
      });
    }
  }

  getChartType = (type: string) => {
    let chartType = null;
    switch (type) {
      case 'BAR':
        chartType = (
          <div className="chart">
            <Bar
              data={this.state.chartData}
              width={this.props.chartWidth}
              height={this.props.chartHeight}
              options={this.props.options}
            />
          </div>
        );
        break;
      case 'LINE':
        chartType = (
          <div className="chart">
            <Line
              data={this.state.chartData}
              width={this.props.chartWidth}
              height={this.props.chartHeight}
              options={this.props.options}
            />
          </div>
        );
        break;
      case 'PIE':
        chartType = (
          <div className="chart">
            <Pie
              data={this.state.chartData}
              width={this.props.chartWidth}
              height={this.props.chartHeight}
              options={this.props.options}
            />
          </div>
        );
        break;
      case 'RADAR':
        chartType = (
          <div className="chart">
            <Radar
              data={this.state.chartData}
              width={this.props.chartWidth}
              height={this.props.chartHeight}
              options={this.props.options}
            />
          </div>
        );
        break;
      case 'HORIZONTALBAR':
        chartType = (
          <div className="chart">
            <Bar
              data={this.state.chartData}
              width={this.props.chartWidth}
              height={this.props.chartHeight}
              options={this.props.options}
            />
          </div>
        );
        break;
      default:
        break;
    }
    return chartType;
  };

  render() {
    return <div>{this.getChartType(this.props.chartType)}</div>;
  }
}
