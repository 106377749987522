import * as React from 'react';
import { AppStore, reduxContainer, AuthStore, TranslationsStore, PartnersStore, PlacesStore } from '../../../appRedux';
import { NavAuthComponent, FooterAuthComponent, ChoosePartnerComponent } from './components';
import backgroundImage from '../../../assets/images/background.jpg';

interface IProps extends AuthStore.IState, TranslationsStore.IState, PartnersStore.IState {
  auth: any;
}

interface IState {}

class ChoosePartnerContainer extends React.Component<
  IProps & AuthStore.IActions & TranslationsStore.IActions & PartnersStore.IActions & PlacesStore.IActions,
  IState
> {
  render() {
    const navAuthConfig = {
      title: 'ReGage Partner',
      initial_language: this.props.initial_language,
      changeLanguage: this.props.changeLanguageAction
    };
    const choosePartnerConfig = {
      partners: this.props.auth.user?.access?.partners,
      get_access_pending: this.props.get_access_pending,
      selectPartnerLogin: this.props.selectPartnerLogin,
      redirectAction: this.props.redirectAction,
      selectPlace: this.props.selectPlaceTopbar
    };
    return (
      <div className="partner-auth off-canvas-sidebar">
        <NavAuthComponent config={navAuthConfig} />
        <div className="wrapper wrapper-full-page">
          <div className="full-page login-page" filter-color="black">
            <div className="content" style={{ paddingTop: '28vh' }}>
              {!this.props.get_access_pending && !this.props.get_access_error && (
                <ChoosePartnerComponent config={choosePartnerConfig} />
              )}
            </div>
            <FooterAuthComponent />
            <div className="full-page-background" style={{ backgroundImage: `url(${backgroundImage})` }} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    auth: state.auth,
    get_access_pending: state.auth.get_access_pending,
    get_access_error: state.auth.get_access_error,
    partners_list_for_user: state.partners.partners_list_for_user,
    initial_language: state.translations.initial_language
  };
}

const dispatchToProps = {
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction,
  selectPartnerLogin: PartnersStore.actions.selectPartnerLogin,
  redirectAction: AuthStore.actions.redirectAction,
  getUserAccess: AuthStore.actions.getUserAccess,
  selectPlaceTopbar: PlacesStore.actions.selectPlaceTopbar
};

export default reduxContainer(ChoosePartnerContainer, mapStateToProps, dispatchToProps);
