import React, { useState, useEffect } from 'react';
import { AlertUtil, msg } from '../../../../utils';
import { Modal, Button } from 'react-bootstrap';
import { AdvancedListComponent, ButtonComponent } from '../../../shared';
import { PaginationDTO, EventDTO, SettlementDTO, SettlementStatus } from '../../../../appRedux/types';
import moment from 'moment';
import { colors } from '../../../../assets/styles';

interface IProps {
  settlement: SettlementDTO | null;
  settlements: PaginationDTO<SettlementDTO>;
  settlements_pending: boolean;
  settlements_error: string | null;
  download_settlements_pending: boolean;
  partnerId: string;
  event: EventDTO | null;
  getSettlements: (
    partnerId: string,
    eventId: string,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: any
  ) => any;
  sendSettlementEmail: (partnerId: string, settlementId: string) => any;
  sendAllSettlementEmails: (partnerId: string, eventId: string) => any;
  approveSettlementEmail: (partnerId: string, settlementId: string) => any;
  downloadSettlementsPdf: (partnerId: string, eventId: string, settlementId?: string, placeId?: string) => any;
  toggleModal: () => any;
}

const SettlementsModal = (props: IProps) => {
  const [settlementsList, setSettlementsList] = useState<PaginationDTO<SettlementDTO>>(new PaginationDTO());
  const [clickedRow, setClickedRow] = useState<string>('');

  useEffect(() => {
    if (props.settlements) {
      setSettlementsList(parseSettlements(props.settlements));
    }
    // tslint:disable-next-line: align
  }, [props.settlements]);

  useEffect(() => {
    const settlement = props.settlement;
    if (settlement) {
      const settlementResults = settlementsList?.results;
      const index = settlementResults?.findIndex((item: SettlementDTO) => item._id === settlement?._id);
      if (index > -1) {
        settlementResults[index].status = settlement.status;
        settlementResults[index].statusLabel = getSettlementStatus(settlement.status);
        settlementResults[index].badge = getStatusBadge(settlement.status);
        settlementResults[index].isValid = true;
        setSettlementsList({ ...settlementsList, results: settlementResults });
      }
    }
    // tslint:disable-next-line: align
  }, [props.settlement]);

  const getSettlements = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    if (props.event) {
      props.getSettlements(props.partnerId, props.event._id, limit, skip, sort, criteria);
    }
  };

  const parseSettlements = (list: PaginationDTO<SettlementDTO>): any => {
    if (!props.settlements?.results) {
      return props.settlements;
    }
    const results: any[] = [];
    if (list.results) {
      list.results.forEach(item => {
        let label;
        if (item.month) {
          const month = moment(item.month, 'MM-YYYY').format('MMMM').toLowerCase();
          const year = moment(item.month, 'MM-YYYY').format('YYYY');
          label = msg(`months.${month}`, month) + ' ' + year;
        } else if (item.months) {
          const startMonth = moment(props.event?.initialStartDate).format('MMMM').toLowerCase();
          const startYear = moment(props.event?.initialStartDate).format('YYYY');
          const endMonth = moment(props.event?.initialEndDate).format('MMMM').toLowerCase();
          const endYear = moment(props.event?.initialEndDate).format('YYYY');
          label = msg(`months.${startMonth}`, startMonth) + ' ' + startYear;
          if (startMonth !== endMonth || startYear !== endYear) {
            label += ' - ' + msg(`months.${endMonth}`, endMonth) + ' ' + endYear;
          }
        }
        results.push({
          _id: item._id || item.placeId,
          placeName: item.placeName,
          totalAmount: item.totalAmount + msg('settlements.ronAmount', ' RON'),
          usedVouchers: item.usedVouchers,
          status: item.status,
          statusLabel: getSettlementStatus(item.status),
          badge: getStatusBadge(item.status),
          month: label,
          isDirty: false,
          isValid: true
        });
      });
    }
    return { ...list, results };
  };

  const getSettlementStatus = (status: number) => {
    switch (status) {
      case SettlementStatus.UNSENT:
        return msg('settlements.unsent', 'Not sent');
      case SettlementStatus.SENT:
        return msg('settlements.sent', 'Sent');
      case SettlementStatus.READ:
        return msg('settlements.read', 'Read');
      case SettlementStatus.APPROVED:
        return msg('settlements.approved', 'Settled');
      default:
        return '';
    }
  };

  const getStatusBadge = (status: number) => {
    switch (status) {
      case SettlementStatus.APPROVED:
        return colors.darkGreen;
      case SettlementStatus.READ:
        return colors.green;
      case SettlementStatus.SENT:
        return colors.blue;
      default:
        return colors.gray;
    }
  };

  const getListFilters = () => {
    let startMonth = moment(props.event?.initialStartDate);
    const endMonth = moment(props.event?.initialEndDate);
    let months = [
      {
        _id: '',
        name: msg('settlements.allMonths', 'All months')
      }
    ];
    while (startMonth < endMonth || startMonth.format('M') === endMonth.format('M')) {
      months.push({
        _id: startMonth.format('MM-YYYY'),
        name: msg(`months.${startMonth.format('MMMM').toLowerCase()}`, startMonth.format('MMMM'))
      });
      startMonth.add(1, 'month');
    }
    let filters = [
      {
        field: 'month',
        value: months,
        default: months[months.length - 1]._id
      }
    ];
    if (settlementsList.perMonth) {
      filters.push({
        field: 'status',
        value: [
          {
            _id: '',
            name: msg('settlements.allSettlements', 'All settlements')
          },
          {
            _id: '0',
            name: msg('settlements.unsentSettlements', 'Not sent')
          },
          {
            _id: '1',
            name: msg('settlements.sentSettlements', 'Sent')
          },
          {
            _id: '2',
            name: msg('settlements.readSettlements', 'Read')
          },
          {
            _id: '3',
            name: msg('settlements.approvedSettlements', 'Approved')
          }
        ],
        default: ''
      });
    }
    return filters;
  };

  const getFields = () => {
    const fields: any = {
      placeName: msg('settlements.place', 'Place'),
      month: msg('settlements.month', 'Month'),
      usedVouchers: msg('settlements.voucherNo', 'Number of vouchers'),
      totalAmount: msg('settlements.totalAmount', 'Total amount')
    };
    if (settlementsList.perMonth) {
      fields.statusLabel = msg('rewardStatus.status', 'Status');
    }
    return fields;
  };

  const sendEmailAction = async (event: any) => {
    const settlementId = event.currentTarget.getAttribute('id');
    const settlement = settlementsList.results.find(item => item._id === settlementId);
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.sendEmailConfirm', 'You are about to send a settlement email!'),
      msg('settlements.sendEmail', 'Send Email')
    );
    if (hasConfirmation && settlement) {
      props.sendSettlementEmail(props.partnerId, settlementId);
    }
  };

  const approveSettlementAction = async (event: any) => {
    const settlementId = event.currentTarget.getAttribute('id');
    const settlement = settlementsList.results.find(item => item._id === settlementId);
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.approveSettlementConfirm', 'You are about to approve a settlement!'),
      msg('settlements.approve', 'Approve')
    );
    if (hasConfirmation && settlement) {
      props.approveSettlementEmail(props.partnerId, settlementId);
    }
  };

  const downloadPdfAction = (event: any) => {
    const settlementId = event.currentTarget.getAttribute('id');
    setClickedRow(settlementId);
    if (settlementsList.perMonth && props.event) {
      props.downloadSettlementsPdf(props.partnerId, props.event._id, settlementId);
    } else if (props.event) {
      props.downloadSettlementsPdf(props.partnerId, props.event._id, undefined, settlementId);
    }
  };

  const checkIfAllEmailsSent = () => {
    return !settlementsList.results?.some(item => item.status === SettlementStatus.UNSENT);
  };

  const sendToAllAction = () => {
    if (props.event) {
      props.sendAllSettlementEmails(props.partnerId, props.event?._id);
    }
  };

  return (
    <Modal show={true} onHide={props.toggleModal} dialogClassName="customEventModal">
      <Modal.Header style={{ paddingTop: 0 }}>
        <Modal.Title>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: 60 }}>
            <div>
              {msg('settlements.settlement', 'Settlements')} - {props.event?.title || ''}
            </div>
            {settlementsList.perMonth && (
              <ButtonComponent
                label={msg('settlements.sendToAll', 'Send to all')}
                icon="email"
                action={sendToAllAction}
                disabled={checkIfAllEmailsSent()}
              />
            )}
          </div>
          <div>
            {msg('settlements.vouchers', 'Vouchers')}:{' '}
            {props.event?.rewards
              .filter(item => item.amount)
              .map(item => item.title)
              .join(', ') || ''}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
          <AdvancedListComponent
            fields={getFields()}
            list={settlementsList}
            pending={props.settlements_pending}
            error={props.settlements_error}
            get={getSettlements}
            disableQueryParams={true}
            limit={10}
            sort={{
              fields: ['placeName', 'usedVouchers', 'totalAmount'],
              default: 'usedVouchers,-1'
            }}
            search={['placeName']}
            filters={getListFilters()}
            actions={[
              {
                label: msg('settlements.sendEmail', 'Send email'),
                btn: 'btn-info',
                icon: 'mail',
                onClick: sendEmailAction,
                isShown: () => settlementsList.perMonth
              },
              {
                label: msg('settlements.approve', 'Approve'),
                btn: 'btn-success',
                icon: 'done',
                onClick: approveSettlementAction,
                isShown: () => settlementsList.perMonth
              },
              {
                label: msg('settlements.downloadPdf', 'Download file'),
                btn: 'btn-primary',
                icon: 'download',
                isPending: (itemId: string) => clickedRow === itemId && props.download_settlements_pending,
                onClick: downloadPdfAction
              }
            ]}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-danger" onClick={props.toggleModal}>
          {msg('general.close', 'Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SettlementsModal;
