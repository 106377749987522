import partnersActions, { IPartnersActions } from './actions';
import partnersReducer from './reducer';
import { PartnerDTO, PaginationDTO, SelectValueDTO } from '../types';

/*
  PartnersStore definition as a namespace that contains actions, reducers, actionTypes and partners state
*/
export namespace PartnersStore {
  function getInitialSelectedPartnerState() {
    const partnerLocalStorage = localStorage.getItem('selected_partner');
    if (partnerLocalStorage === null || partnerLocalStorage === undefined) {
      return {
        selected_partner: '',
        hasPartnerData: false
      };
    } else {
      return { ...JSON.parse(partnerLocalStorage), hasPartnerData: false };
    }
  }

  export const selectedPartnerInitialState = getInitialSelectedPartnerState();

  export function setLocalStorage(state: any | null) {
    if (state !== null) {
      localStorage.removeItem('selected_partner');
      localStorage.setItem('selected_partner', JSON.stringify(state));
    } else {
      localStorage.removeItem('selected_partner');
    }
  }
  export type IState = {
    partners_list: PaginationDTO<PartnerDTO>;
    partners_list_pending: boolean;
    partners_list_error: string | null;
    names_of_partners_list: SelectValueDTO[];
    names_of_partners_list_pending: boolean;
    names_of_partners_list_error: string | null;
    partners_list_for_user: PaginationDTO<PartnerDTO>;
    partners_list_for_user_pending: boolean;
    partners_list_for_user_error: string | null;
    partner: PartnerDTO | null;
    partner_pending: boolean;
    partner_error: string | null;
    partner_saved: PartnerDTO | null;
    partner_save_pending: boolean;
    partner_save_error: string | null;
    partner_deleted: boolean;
    partner_delete_pending: boolean;
    partner_delete_error: string | null;
    selected_partner: string;
    hasPartnerData: boolean;
    export_partners_pending: boolean;
    export_partners_error: any | null;
  };

  export const initialState: IState = {
    partners_list: new PaginationDTO(),
    partners_list_pending: false,
    partners_list_error: null,
    names_of_partners_list: [],
    names_of_partners_list_pending: false,
    names_of_partners_list_error: null,
    partners_list_for_user: new PaginationDTO(),
    partners_list_for_user_pending: false,
    partners_list_for_user_error: null,
    partner: null,
    partner_pending: false,
    partner_error: null,
    partner_saved: null,
    partner_save_pending: false,
    partner_save_error: null,
    partner_deleted: false,
    partner_delete_pending: false,
    partner_delete_error: null,
    selected_partner: selectedPartnerInitialState.selected_partner,
    hasPartnerData: selectedPartnerInitialState.hasPartnerData,
    export_partners_pending: false,
    export_partners_error: null
  };

  export enum ActionTypes {
    GET_PARTNERS = 'GET_PARTNERS',
    GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS',
    GET_PARTNERS_FAILED = 'GET_PARTNERS_FAILED',
    GET_NAMES_LIST_OF_PARTNERS = 'GET_NAMES_LIST_OF_PARTNERS',
    GET_NAMES_LIST_OF_PARTNERS_SUCCESS = 'GET_NAMES_LIST_OF_PARTNERS_SUCCESS',
    GET_NAMES_LIST_OF_PARTNERS_FAILED = 'GET_NAMES_LIST_OF_PARTNERS_FAILED',
    GET_PARTNERS_FOR_USER = 'GET_PARTNERS_FOR_USER',
    GET_PARTNERS_FOR_USER_SUCCESS = 'GET_PARTNERS_FOR_USER_SUCCESS',
    GET_PARTNERS_FOR_USER_FAILED = 'GET_PARTNERS_FOR_USER_FAILED',
    GET_PARTNER = 'GET_PARTNER',
    GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS',
    GET_PARTNER_FAILED = 'GET_PARTNER_FAILED',
    SAVE_PARTNER = 'SAVE_PARTNER',
    SAVE_PARTNER_SUCCESS = 'SAVE_PARTNER_SUCCESS',
    SAVE_PARTNER_FAILED = 'SAVE_PARTNER_FAILED',
    UPDATE_PARTNER_STATUS = 'UPDATE_PARTNER_STATUS',
    DELETE_PARTNER = 'DELETE_PARTNER',
    DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS',
    DELETE_PARTNER_FAILED = 'DELETE_PARTNER_FAILED',
    SELECT_PARTNER = 'SELECT_PARTNER',
    EXPORT_PARTNERS = 'EXPORT_PARTNERS',
    EXPORT_PARTNERS_SUCCESS = 'EXPORT_PARTNERS_SUCCESS',
    EXPORT_PARTNERS_FAILED = 'EXPORT_PARTNERS_FAILED'
  }

  export const actions = partnersActions;
  export const reducer = partnersReducer;
  export interface IActions extends IPartnersActions {}
}

export * from './actions';
