/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ReactJson from 'react-json-view';
import { CardComponent, ButtonComponent } from '../../../shared';
import { msg } from '../../../../utils';

interface IProps {
  match: any;
  config: {
    onboarding: any;
    get_onboarding_pending: boolean;
    get_onboarding_error: string | null;
    getOnboardingBySAdminAction: (onboardingId: string) => any;
    createPartnerFromOnboarding: (onboardingId: string) => any;
    create_onboarding_partner_pending: boolean;
    createPlaceFromOnboarding: (onboardingId: string) => any;
    create_onboarding_place_pending: boolean;
    createUserFromOnboarding: (onboardingId: string) => any;
    create_onboarding_user_pending: boolean;
    activatePlaceFromOnboarding: (onboardingId: string) => any;
    activate_onboarding_place_pending: boolean;
    sendEmailToPartner: (onboardingId: string) => any;
    send_email_to_partner_pending: boolean;
  };
}

export const OnboardingViewComponent = (props: IProps) => {
  const onboardingId = props.match?.params.onboardingId;
  const onboardingPending = props.config.get_onboarding_pending;
  useEffect(() => {
    props.config.getOnboardingBySAdminAction(onboardingId);
    // tslint:disable-next-line: align
  }, []);

  const onboardingAction = (type: string) => () => {
    const {
      createPartnerFromOnboarding,
      createPlaceFromOnboarding,
      createUserFromOnboarding,
      activatePlaceFromOnboarding,
      sendEmailToPartner
    } = props.config;
    switch (type) {
      case 'partner': {
        createPartnerFromOnboarding(onboardingId);
        break;
      }
      case 'place': {
        createPlaceFromOnboarding(onboardingId);
        break;
      }
      case 'user': {
        createUserFromOnboarding(onboardingId);
        break;
      }
      case 'activate': {
        activatePlaceFromOnboarding(onboardingId);
        break;
      }
      case 'save': {
        sendEmailToPartner(onboardingId);
        break;
      }
      default:
        break;
    }
  };

  const getStatus = (type: string) => {
    const onboarding = props.config.onboarding;
    if (!onboarding || !onboarding.config || onboardingPending) {
      return null;
    }
    let checked = false;
    switch (type) {
      case 'place':
        checked = onboarding.config.autopilotsCreated && onboarding.config.rewardsCreated && onboarding.config.placeId;
        break;
      case 'partner':
        checked = !!onboarding.config.partnerId;
        break;
      case 'user':
        checked = !!onboarding.config.userId;
        break;
      case 'activate':
        checked = !!onboarding.config.placeActivated;
        break;
      case 'email':
        checked = onboarding.config.emailSent;
        break;
      default:
        checked = false;
    }
    return checked ? (
      <i className="material-icons" style={{ fontSize: 24 }}>
        done
      </i>
    ) : null;
  };

  return (
    <>
      <CardComponent
        title={msg('cardTitle.approveOnboarding', 'Approve onboarding')}
        headerIcon="assignment"
        pending={false}
        error={false}
        needsTitle={true}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <div style={styles.buttonContainer}>
            <ButtonComponent
              label={msg('onboard.createPartner', 'Create partner')}
              icon="create"
              action={onboardingAction('partner')}
              pending={props.config.create_onboarding_partner_pending}
              disabled={props.config.onboarding?.config?.partnerId && !onboardingPending}
              styles={{ width: 200, padding: 12 }}
            />
            <div> {getStatus('partner')}</div>
          </div>
          <div style={styles.buttonContainer}>
            <ButtonComponent
              label={msg('onboard.createPlace', 'Create place')}
              icon="place"
              action={onboardingAction('place')}
              pending={props.config.create_onboarding_place_pending}
              disabled={
                props.config.onboarding?.config?.placeId &&
                props.config.onboarding?.config?.autopilotsCreated &&
                props.config.onboarding?.config?.rewardsCreated &&
                !onboardingPending
              }
              styles={{ width: 200, padding: 12 }}
            />
            <div> {getStatus('place')}</div>
          </div>
          <div style={styles.buttonContainer}>
            <ButtonComponent
              label={msg('onboard.createUser', 'Create user')}
              icon="person"
              action={onboardingAction('user')}
              pending={props.config.create_onboarding_user_pending}
              disabled={props.config.onboarding?.config?.userId && !onboardingPending}
              styles={{ width: 200, padding: 12 }}
            />
            <div> {getStatus('user')}</div>
          </div>
          <div style={styles.buttonContainer}>
            <ButtonComponent
              label={msg('onboard.activateComingSoon', 'Activate with coming soon')}
              icon="card_giftcard"
              action={onboardingAction('activate')}
              pending={props.config.activate_onboarding_place_pending}
              disabled={
                (!props.config.onboarding?.config?.partnerId ||
                  !props.config.onboarding?.config?.placeId ||
                  props.config.onboarding?.config?.placeActivated) &&
                !onboardingPending
              }
              styles={{ width: 220, padding: 12 }}
            />
            <div> {getStatus('activate')}</div>
          </div>
          <div style={styles.buttonContainer}>
            <ButtonComponent
              label={msg('onboard.sendEmail', 'Send email')}
              icon="email"
              action={onboardingAction('save')}
              pending={props.config.send_email_to_partner_pending}
              disabled={props.config.onboarding?.config?.emailSent && !onboardingPending}
              styles={{ width: 200, padding: 12 }}
            />
            <div>{getStatus('email')}</div>
          </div>
        </div>
      </CardComponent>
      <CardComponent
        title={msg('cardTitle.viewOnboarding', 'View onboarding')}
        headerIcon="list"
        pending={props.config.get_onboarding_pending}
        error={!!props.config.get_onboarding_error}
        needsTitle={true}
      >
        <ReactJson
          src={props.config.onboarding || {}}
          displayDataTypes={false}
          displayObjectSize={false}
          name={false}
        />
      </CardComponent>
    </>
  );
};

const styles: any = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
};
