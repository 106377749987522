import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { PayBatchAttachment } from '../../../../appRedux/types';
import { msg } from '../../../../utils';
import { ButtonComponent, UploadComponent } from '../../../shared';

interface IProps {
  batchId: string;
  pending: boolean;
  toggleModal: () => void;
  addPayBatchConfirmationAction: (payBatchId: string, attachment: PayBatchAttachment) => any;
}

const UploadConfirmationModal = (props: IProps) => {
  const [attachment, setAttachment] = useState<PayBatchAttachment | null>(null);

  const uploadConfirmation = (response: any) => {
    if (response.data) {
      setAttachment(response.data);
    }
  };

  const clearAttachment = () => {
    setAttachment(null);
  };

  const confirmUpload = () => {
    if (attachment) {
      props.addPayBatchConfirmationAction(props.batchId, attachment);
    }
  };

  return (
    <Modal show={true} onHide={props.toggleModal} dialogClassName="responsive-modal">
      <Modal.Header>
        <Modal.Title>{msg('paymentSettlements.uploadTitle', 'Upload file')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: 20 }}>
          {attachment ? (
            <div
              style={{
                border: '1px dashed #8b8d8e',
                borderRadius: 3,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div style={{ padding: 14, fontSize: 16 }}>{attachment.originalName}</div>
              <div className="selectShowMore" style={{ marginRight: 15 }} onClick={clearAttachment}>
                X
              </div>
            </div>
          ) : (
            <UploadComponent
              url={`admin/pay-batches/${props.batchId}/attachment`}
              accept={{ 'application/pdf': ['.pdf', '.docx', '.doc'] }}
              extraData={{
                batchId: props.batchId
              }}
              messages={{
                default: msg(
                  'paymentSettlements.uploadAttachment',
                  'Upload the bank confirmation by drag-and-drop or click!'
                )
              }}
              onSuccess={uploadConfirmation}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-danger" onClick={props.toggleModal}>
          {msg('general.close', 'Close')}
        </Button>
        <ButtonComponent
          styles={{ marginTop: 0 }}
          label={msg('paymentSettlements.confirm', 'Done')}
          icon="done"
          action={confirmUpload}
          pending={props.pending}
          disabled={!attachment}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default UploadConfirmationModal;
