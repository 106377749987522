import * as React from 'react';

export const FooterComponent = () => (
  <footer className="footer">
    <div className="container-fluid">
      <p className="copyright pull-right" style={{ marginRight: 60 }}>
        &copy; &nbsp;
        {process.env.REACT_APP_FOOTER ? (
          process.env.REACT_APP_FOOTER
        ) : (
          <>
            <a href="http://regage.ro">ReGage</a>, Engagement Revolution!
          </>
        )}
      </p>
    </div>
  </footer>
);
