import classnames from 'classnames';
import * as React from 'react';

import { msg } from '../../../../../utils';
import styles from '../styles/Auth.module.css';

interface IState {
  showCard: boolean;
  headerUp: boolean;
  visible: boolean;
}

interface IProps {
  match: any;
  validate: {
    pending: boolean;
    error: any;
    action: any;
  };
}

export default class ValidateEmailComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showCard: true,
      headerUp: false,
      visible: true
    };
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.token) {
      this.props.validate.action(this.props.match.params.token);
    }
  }

  render() {
    let cardClasses = classnames(`card card-login ${styles.card}`, { 'card-hidden': !this.state.showCard });
    const { error } = this.props.validate;
    let headerClasses = classnames(`card-header text-center ${styles.header}`, {
      [styles.up]: this.state.headerUp
    });

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form>
              <div className={cardClasses}>
                <div className={headerClasses} data-background-color="green">
                  <h4 className="card-title">
                    {error
                      ? msg('emailValidationScreen.validationAlreadyDone', 'Already validated')
                      : msg('emailValidationScreen.title', 'Validation success')}
                  </h4>
                </div>
                <div className={`card-content ${styles.content}`}>
                  <div className="form-group" style={{ marginLeft: 20, marginTop: -5, textAlign: 'center' }}>
                    {error
                      ? msg('emailValidationScreen.validationError', 'Your email has already been validated.')
                      : msg('emailValidationScreen.message', 'Congratulations! Your email was successfully validated.')}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
