import { Dispatch } from 'redux';
import { ReviewsStore } from '.';
import { Server, AlertUtil, msg, logger, composedCriteriaBuilder, queryStringToCriteria } from '../../utils';
import { ReviewConfigDTO, ReviewDTO, PaginationDTO } from '../types';
import { push } from 'connected-react-router';
import { PlacesStore } from '..';
import moment from 'moment';
import { generatePath } from 'react-router-dom';
import { partnerRoutes } from '../../modules/layouts/routes';

export interface IReviewsActions {
  saveReviewConfigAction(placeId: string, reviewConfig: ReviewConfigDTO): any;
  editReviewConfigAction(placeId: string, configId: string, reviewConfig: ReviewConfigDTO): any;
  editConfigStatusAction(placeId: string, configId: string, reviewConfig: any): any;
  getReviewConfigAction(placeId: string, configId: string): any;
  deleteReviewConfigAction(placeId: string, configId: string): any;
  getReviewConfigsListAction(
    placeId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  getReviewsListAction(
    placeId: string,
    reviewConfigId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  getReviewAction(placeId: string, reviewId: string): any;
  exportReviewsInExcel(placeId: string, configId: string, startDate: number, endDate: number): any;
  redirectToReviews(placeId: string, configId: string): any;
  redirectToReviewConfig(placeId: string, configId?: string): any;
  clearReviewModalData(): any;
  changeReviewStatus(placeId: string, allowReviews: boolean): any;
  clearPlaceReviewsError(): any;
}

class ReviewsActions implements IReviewsActions {
  saveReviewConfigAction(placeId: string, reviewConfig: ReviewConfigDTO) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: ReviewsStore.ActionTypes.SAVE_REVIEW_CONFIG
      });
      AlertUtil.simple(
        msg('reduxMessages.reviews.editReviewPending', 'The review is being saved, please wait...'),
        'info'
      );
      logger.msg('Save review-config action, route:/places/placeId/create-review-config', 'POST');
      Server.post(`places/${placeId}/create-review-config`, reviewConfig)
        .then((response: any) => {
          dispatch({
            type: ReviewsStore.ActionTypes.SAVE_REVIEW_CONFIG_SUCCESS,
            payload: response.data as ReviewConfigDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.reviews.editReviewSuccess', 'The review was successfully updated!'),
            'success'
          );
          const path = generatePath(partnerRoutes.REVIEWS.default, { placeId });
          dispatch(push(path));
        })
        .catch(error => {
          logger.err('Save review-config action, route:/places/placeId/create-review-config', 'POST');
          AlertUtil.updateContent(
            msg('reduxMessages.reviews.editReviewError', 'Due to an error, the review could not be updated!'),
            'error'
          );
          dispatch({
            type: ReviewsStore.ActionTypes.SAVE_REVIEW_CONFIG_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  editConfigStatusAction(placeId: string, configId: string, reviewConfig: ReviewConfigDTO) {
    return (dispatch: Dispatch<any>, getState: any) => {
      dispatch({
        type: ReviewsStore.ActionTypes.EDIT_CONFIG_STATUS
      });
      AlertUtil.simple(
        msg('reduxMessages.reviews.editReviewPending', 'The configuration is being saved, please wait...'),
        'info'
      );
      logger.msg('Update review-config action, route:/places/placeId/review-config/configId', 'PUT');
      Server.put(`places/${placeId}/review-config/${configId}`, reviewConfig)
        .then((response: any) => {
          dispatch({
            type: ReviewsStore.ActionTypes.EDIT_CONFIG_STATUS_SUCCESS,
            payload: response.data as ReviewConfigDTO
          });
          const state = getState();
          if (!reviewConfig.isActive && state?.places?.place?.allowReviews) {
            dispatch(ReviewsStore.actions.changeReviewStatus(placeId, false));
          } else {
            AlertUtil.updateContent(
              msg('reduxMessages.reviews.editReviewSuccess', 'The configuration was successfully updated!'),
              'success'
            );
          }
          const { limit, page_number } = state?.events.events_list;
          const newCriteria = queryStringToCriteria(window.location.search, ['name']);
          dispatch(
            ReviewsStore.actions.getReviewConfigsListAction(placeId, limit * page_number, 0, newCriteria.sort, {
              filters: newCriteria.filters,
              search: newCriteria.search
            })
          );
        })
        .catch(error => {
          logger.err('Update review-config action, route:/places/placeId/review-config/configId', 'PUT');
          let message = msg(
            'reduxMessages.reviews.editReviewError',
            'Due to an error, the configuration could not be updated!'
          );
          const parsedError = Server.errorParse(error);
          if (parsedError?.code === 'ACTIVATION_ERROR') {
            message = parsedError.message;
          }
          AlertUtil.updateContent(message, 'error');
          dispatch({
            type: ReviewsStore.ActionTypes.EDIT_CONFIG_STATUS_FAILED,
            payload: parsedError
          });
        });
    };
  }

  editReviewConfigAction(placeId: string, configId: string, reviewConfig: ReviewConfigDTO) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: ReviewsStore.ActionTypes.SAVE_REVIEW_CONFIG
      });
      AlertUtil.simple(
        msg('reduxMessages.reviews.editReviewPending', 'The configuration is being saved, please wait...'),
        'info'
      );
      logger.msg('Update review-config action, route:/places/placeId/review-config/configId', 'PUT');
      Server.put(`places/${placeId}/review-config/${configId}`, reviewConfig)
        .then((response: any) => {
          dispatch({
            type: ReviewsStore.ActionTypes.SAVE_REVIEW_CONFIG_SUCCESS,
            payload: response.data as ReviewConfigDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.reviews.editReviewSuccess', 'The configuration was successfully updated!'),
            'success'
          );
          const path = generatePath(partnerRoutes.REVIEWS.default, { placeId });
          dispatch(push(path));
        })
        .catch(error => {
          logger.err('Update review-config action, route:/places/placeId/review-config/configId', 'PUT');
          AlertUtil.updateContent(
            msg('reduxMessages.reviews.editReviewError', 'Due to an error, the configuration could not be updated!'),
            'error'
          );
          dispatch({
            type: ReviewsStore.ActionTypes.SAVE_REVIEW_CONFIG_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getReviewConfigAction(placeId: string, configId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: ReviewsStore.ActionTypes.GET_REVIEW_CONFIG
      });
      logger.msg('Get review-config action, route:/places/placeId/review-config/configId', 'GET');
      Server.get(`places/${placeId}/review-config/${configId}`)
        .then((response: any) => {
          dispatch({
            type: ReviewsStore.ActionTypes.GET_REVIEW_CONFIG_SUCCESS,
            payload: response.data as ReviewConfigDTO
          });
        })
        .catch(error => {
          logger.err('Get review-config action, route:/places/placeId/review-config/configId', 'GET');
          dispatch({
            type: ReviewsStore.ActionTypes.GET_REVIEW_CONFIG_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  deleteReviewConfigAction(placeId: string, configId: string) {
    return (dispatch: Dispatch<any>, getState: any) => {
      dispatch({
        type: ReviewsStore.ActionTypes.DELETE_REVIEW_CONFIG
      });
      logger.msg('Delete review-config action, route:/places/placeId/review-config/configId', 'DELETE');
      AlertUtil.simple(
        msg('reduxMessages.reviews.deleteReviewPending', 'The configuration is being deleted, please wait...'),
        'info'
      );
      Server.delete(`places/${placeId}/review-config/${configId}`)
        .then((response: any) => {
          AlertUtil.updateContent(
            msg('reduxMessages.reviews.deleteReviewSuccess', 'The configuration was successfully deleted!'),
            'success'
          );
          dispatch({
            type: ReviewsStore.ActionTypes.DELETE_REVIEW_CONFIG_SUCCESS,
            payload: response.data as ReviewConfigDTO
          });
          dispatch(ReviewsStore.actions.getReviewConfigsListAction(placeId));
          const state = getState();
          if (response.data?.isActive && state?.places?.place?.allowReviews) {
            const place = state?.places?.place;
            dispatch(PlacesStore.actions.setPlaceAction({ ...place, allowReviews: false }));
          }
        })
        .catch(error => {
          logger.err('Delete review-config action, route:/places/placeId/review-config/configId', 'DELETE');
          AlertUtil.updateContent(
            msg('reduxMessages.reviews.editReviewError', 'Due to an error, the configuration could not be deleted!'),
            'error'
          );
          dispatch({
            type: ReviewsStore.ActionTypes.DELETE_REVIEW_CONFIG_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  exportReviewsInExcel(placeId: string, configId: string, startDate: number, endDate: number) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: ReviewsStore.ActionTypes.EXPORT_REVIEW_CONFIG
      });
      const startDateFormat = moment(startDate).format('DD-MM-YYYY');
      const endDateFormat = moment(endDate).format('DD-MM-YYYY');
      const name = `Report Reviews ${startDateFormat} - ${endDateFormat}.xlsx`;
      logger.msg('Export review-config action, route:/places/placeId/report-reviews/configId', 'GET');
      Server.downloadReports(
        `/places/${placeId}/report-reviews/${configId}?startDate=${startDate}&endDate=${endDate}`,
        name
      )
        .then((response: any) => {
          dispatch({
            type: ReviewsStore.ActionTypes.EXPORT_REVIEW_CONFIG_SUCCESS,
            payload: response.data as ReviewConfigDTO
          });
        })
        .catch(error => {
          logger.err('Export review-config action, route:/places/placeId/report-reviews/configId', 'GET');
          dispatch({
            type: ReviewsStore.ActionTypes.EXPORT_REVIEW_CONFIG_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getReviewConfigsListAction(
    placeId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: ReviewsStore.ActionTypes.GET_REVIEW_CONFIGS_LIST
      });
      if (!limit) {
        limit = 30;
      }
      let url = `places/${placeId}/review-configs?limit=${limit}`;
      if (skip) {
        url += `&skip=${skip}`;
      }
      if (sort) {
        url += `&sort=${sort}`;
      }
      if (criteria) {
        url += composedCriteriaBuilder(criteria);
      }
      logger.msg('Get review-config action, route:/places/placeId/review-configs', 'GET');
      Server.get(url)
        .then((response: any) => {
          dispatch({
            type: ReviewsStore.ActionTypes.GET_REVIEW_CONFIGS_LIST_SUCCESS,
            payload: response.data as PaginationDTO<ReviewConfigDTO>
          });
        })
        .catch(error => {
          logger.err('Get review-config action, route:/places/placeId/review-configs', 'GET');
          dispatch({
            type: ReviewsStore.ActionTypes.GET_REVIEW_CONFIGS_LIST_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getReviewsListAction(
    placeId: string,
    reviewConfigId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: ReviewsStore.ActionTypes.GET_REVIEWS_LIST
      });
      if (!limit) {
        limit = 30;
      }
      let url = `places/${placeId}/reviews/${reviewConfigId}?limit=${limit}`;
      if (skip) {
        url += `&skip=${skip}`;
      }
      if (sort) {
        url += `&sort=${sort}`;
      }
      if (criteria) {
        url += composedCriteriaBuilder(criteria);
      }
      logger.msg('Get reviews action, route:/places/placeId/reviews', 'GET');
      Server.get(url)
        .then((response: any) => {
          dispatch({
            type: ReviewsStore.ActionTypes.GET_REVIEWS_LIST_SUCCESS,
            payload: response.data as PaginationDTO<ReviewDTO>
          });
        })
        .catch(error => {
          logger.err('Get reviews action, route:/places/placeId/reviews', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.reviews.getReviewsError', 'Due to an error, the reviews list could not be loaded!'),
            'error'
          );
          dispatch({
            type: ReviewsStore.ActionTypes.GET_REVIEWS_LIST_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getReviewAction(placeId: string, reviewId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: ReviewsStore.ActionTypes.GET_REVIEW
      });
      logger.msg('Get review action, route:/places/placeId/review/reviewId', 'GET');
      Server.get(`places/${placeId}/review/${reviewId}`)
        .then((response: any) => {
          dispatch({
            type: ReviewsStore.ActionTypes.GET_REVIEW_SUCCESS,
            payload: response.data as ReviewDTO
          });
        })
        .catch(error => {
          logger.err('Get review action, route:/places/placeId/review/reviewId', 'GET');
          dispatch({
            type: ReviewsStore.ActionTypes.GET_REVIEW_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  redirectToReviews(placeId: string, configId: string) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(partnerRoutes.REVIEWS.subroutes.ANSWERS.path, { placeId, configId });
      dispatch(push(path));
    };
  }

  redirectToReviewConfig(placeId: string, configId?: string) {
    return (dispatch: Dispatch<any>) => {
      if (configId) {
        const path = generatePath(partnerRoutes.REVIEWS.subroutes.EDIT.path, { placeId, configId });
        dispatch(push(path));
      } else {
        const path = generatePath(partnerRoutes.REVIEWS.subroutes.ADD.path, { placeId });
        dispatch(push(path));
      }
    };
  }

  clearReviewModalData() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: ReviewsStore.ActionTypes.CLEAR_MODAL_DATA });
    };
  }

  changeReviewStatus(placeId: string, allowReviews: boolean) {
    return (dispatch: Dispatch<any>) => {
      logger.msg('Update reviews allowed action, route:/places/placeId/allow-reviews', 'PUT');
      AlertUtil.fireOnce(
        msg('reduxMessages.reviews.changeStatusPending', 'The reviews status is being updated, please wait...'),
        'info'
      );
      Server.put(`places/${placeId}/allow-reviews`, { allowReviews })
        .then((response: any) => {
          dispatch({
            type: ReviewsStore.ActionTypes.ALLOW_REVIEWS_SUCCESS,
            payload: response.data as boolean
          });
          dispatch(PlacesStore.actions.setPlaceAction(response.data));
          AlertUtil.updateContent(
            msg('reduxMessages.reviews.changeStatusSuccess', 'The reviews status was successfully updated!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Update reviews allowed action, route:/places/placeId/allow-reviews', 'PUT');
          if (error.response?.data?.ERROR?.code === 'ACTIVATION_ERROR') {
            AlertUtil.updateContent(msg('reduxMessages.reviews.noConfig', 'You must have one active form!'), 'error');
          } else {
            AlertUtil.updateContent(
              msg(
                'reduxMessages.reviews.changeStatusError',
                'Due to an error, the reviews status could not be updated!'
              ),
              'error'
            );
          }

          dispatch({
            type: ReviewsStore.ActionTypes.ALLOW_REVIEWS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  clearPlaceReviewsError() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: ReviewsStore.ActionTypes.CLEAR_REVIEWS_ERROR });
    };
  }
}

const reviewsActions = new ReviewsActions();
export default reviewsActions;
