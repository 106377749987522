import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AppStore, PlacesStore, UsersStore, reduxContainer } from '../../../appRedux';
import { UsersEditComponent } from '../users/components';
import PlaceUsersComponent from './components/PlaceUsersComponent';
import { partnerRoutes, adminRoutes } from '../../layouts';

interface IProps extends PlacesStore.IState, UsersStore.IState {}
interface IState {}

class PlaceAdminsContainer extends React.Component<IProps & PlacesStore.IActions & UsersStore.IActions & IState> {
  render() {
    const placeAdmins = {
      place_admins_list: this.props.place_admins_list,
      place_admins_list_error: this.props.place_admins_list_error,
      place_admins_list_pending: this.props.place_admins_list_pending,
      getAdminsForPlaceAction: this.props.getAdminsForPlaceAction,
      redirectToAddPlaceAdminAction: this.props.redirectToAddPlaceAdminAction,
      removePlaceAdminAction: this.props.removePlaceAdminAction,
      addPlaceAdminByEmail: this.props.addPlaceAdminByEmail
    };
    const userPlaceEditProps = {
      pending: this.props.user_save_pending,
      error: this.props.user_save_error,
      loaded: this.props.user_b,
      load_pending: this.props.user_pending,
      load_error: this.props.user_error,
      loadedPlace: this.props.place,
      add: this.props.addPlaceAdminAction,
      get: this.props.getUserAction,
      edit: this.props.editUserAction,
      changePasswordAsAdmin: this.props.changePasswordAsAdmin
    };
    const profiles = {
      profile_list: this.props.profile_list,
      profile_list_pending: this.props.profile_list_pending,
      profile_list_error: this.props.profile_list_error,
      getTabletProfilesAction: this.props.getTabletProfilesAction,
      addTabletProfileAction: this.props.addTabletProfileAction,
      editTabletProfileAction: this.props.editTabletProfileAction,
      deleteTabletProfileAction: this.props.deleteTabletProfileAction
    };

    return (
      <Switch>
        <Route
          path={partnerRoutes.PLACE_ADMINS.subroutes.ADD.path}
          render={({ match }) => (
            <UsersEditComponent
              match={match}
              editMode={false}
              config={userPlaceEditProps}
              forPartner={false}
              forSA={false}
            />
          )}
        />
        <Route
          path={adminRoutes.PLACES.subroutes.EDIT.subroutes.ADMINS.subroutes.ADD.path}
          render={({ match }) => (
            <UsersEditComponent
              match={match}
              editMode={false}
              config={userPlaceEditProps}
              forPartner={false}
              forSA={true}
            />
          )}
        />
        <Route
          path={partnerRoutes.PLACE_ADMINS.default}
          render={({ match }) => (
            <PlaceUsersComponent match={match} admins={placeAdmins} profiles={profiles} forSAdmin={false} />
          )}
        />
        <Route
          path={adminRoutes.PLACES.subroutes.EDIT.subroutes.ADMINS.path}
          render={({ match }) => (
            <PlaceUsersComponent match={match} admins={placeAdmins} profiles={profiles} forSAdmin={true} />
          )}
        />
        <Redirect from="*" to={`/partner/places/admins/${this.props.selected_place}`} />
      </Switch>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    place: state.places.place,
    selected_place: state.places.selected_place,
    place_admins_list: state.users.place_admins_list,
    place_admins_list_pending: state.users.place_admins_list_pending,
    place_admins_list_error: state.users.place_admins_list_error,
    user_b: state.users.user_b,
    user_pending: state.users.user_pending,
    user_error: state.users.user_error,
    user_save_pending: state.users.user_save_pending,
    user_save_error: state.users.user_save_error,
    profile_list: state.users.profile_list,
    profile_list_pending: state.users.profile_list_pending,
    profile_list_error: state.users.profile_list_error
  };
}

const dispatchToProps = {
  getAdminsForPlaceAction: UsersStore.actions.getAdminsForPlaceAction,
  redirectToAddPlaceAdminAction: UsersStore.actions.redirectToAddPlaceAdminAction,
  removePlaceAdminAction: UsersStore.actions.removePlaceAdminAction,
  addPlaceAdminByEmail: PlacesStore.actions.addPlaceAdminByEmail,
  getUserAction: UsersStore.actions.getUserAction,
  addPlaceAdminAction: UsersStore.actions.addPlaceAdminAction,
  editUserAction: UsersStore.actions.editUserAction,
  changePasswordAsAdmin: UsersStore.actions.changePasswordAsAdmin,
  getTabletProfilesAction: UsersStore.actions.getTabletProfilesAction,
  addTabletProfileAction: UsersStore.actions.addTabletProfileAction,
  editTabletProfileAction: UsersStore.actions.editTabletProfileAction,
  deleteTabletProfileAction: UsersStore.actions.deleteTabletProfileAction
};

export default reduxContainer(PlaceAdminsContainer, mapStateToProps, dispatchToProps);
