export class ReceiptDTO {
  _id: string;
  amount: number;
  media: string;
  status: number;
  user: {
    lastname: string;
    firstname: string;
    phone: string;
    email: string;
  };
  placeId: string;
  placeName: string;
  checkinId: string;
  deleted: boolean;
  created: number;
  isDirty?: boolean;
  isValid?: boolean;
  statusLabel?: string;
  badge?: string;
  extraData?: string;
  ocrData?: ReceiptOcrDTO;
  userData?: ReceiptOcrDTO;
}

export class ReceiptOcrDTO {
  issue_date?: string;
  receipt_number?: string;
  supplier_vat_number?: string;
  total?: number;
  total_vat?: number;
  message?: string;
}

export enum ReceiptStatus {
  PENDING,
  APPROVED,
  FRAUD
}

export enum ReceiptType {
  MOBILE,
  TABLET,
  RESERVATION,
  ONLINE_PURCHASE,
  EVENT,
  TRANSACTION
}
