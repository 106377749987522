import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import { CardComponent, ButtonComponent, TextFieldComponent } from '../../../shared';
import { ReviewQuestionComponent } from './';
import { msg, Validator, IValidator, AlertUtil } from '../../../../utils';
import { ReviewConfigDTO, ReviewQuestionDTO, PlaceDTO } from '../../../../appRedux/types';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

interface IProps {
  match: any;
  place: PlaceDTO | null;
  config: ReviewConfigDTO | null;
  config_pending: boolean;
  getReviewConfigAction: (placeId: string, configId: string) => any;
  saveReviewConfigAction: (placeId: string, reviewConfig: ReviewConfigDTO) => any;
  editReviewConfigAction: (placeId: string, configId: string, reviewConfig: ReviewConfigDTO) => any;
}

interface IState {
  config: any;
  editMode: boolean;
  dirty: boolean;
  giveReward: boolean | string;
}

class ReviewFormComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      config: new ReviewConfigDTO(),
      editMode: false,
      dirty: false,
      giveReward: ''
    };
  }

  componentDidMount() {
    const placeId = this.props.match?.params?.placeId;
    const configId = this.props.match?.params?.configId;
    if (placeId && configId) {
      this.props.getReviewConfigAction(placeId, configId);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.config_pending && !this.props.config_pending && this.props.config) {
      this.setState({ config: this.props.config, editMode: true, giveReward: !!this.props.config.action?.value });
    }
  }

  questionInputHandler = (event: any, index: number) => {
    const config = this.state.config;
    config.questions[index][event.name] = event.value;
    if (event.name === 'type' && event.value === 0 && config.questions[index].options) {
      delete config.questions[index].options;
    }
    this.setState({ config, dirty: true });
  };

  onQuestionDelete = (index: number) => {
    const config = this.state.config;
    config.questions.splice(index, 1);
    this.setState({ config, dirty: true });
  };

  onAddQuestion = () => {
    const config = this.state.config;
    if (!config.questions) {
      config.questions = [];
    }
    config.questions.push(new ReviewQuestionDTO());
    this.setState({ config, dirty: true });
  };

  onAddQuestionOption = (index: number) => {
    const config = this.state.config;
    if (!config.questions[index].options) {
      config.questions[index].options = [];
    }
    config.questions[index].options.push('');
    this.setState({ config, dirty: true });
  };

  onDeleteQuestionOption = (questionIndex: number, optionIndex: number) => {
    const config = this.state.config;
    config.questions[questionIndex].options.splice(optionIndex, 1);
    this.setState({ config, dirty: true });
  };

  questionOptionHandler = (event: any, index: number) => {
    const config = this.state.config;
    config.questions[event.target].options[index] = event.value;
    this.setState({ config, dirty: true });
  };

  inputHandler = (event: any) => {
    const fieldKey = event.target.getAttribute('id').split('.');
    const fieldValue = event.target.value;
    const config = { ...this.state.config };
    if (fieldKey.length > 1) {
      const { isDirty } = this.props.validator!;
      if (isDirty) {
        isDirty(event.target.getAttribute('id'));
      }
      config[fieldKey[0]] = { ...config[fieldKey[0]], [fieldKey[1]]: fieldValue };
    } else {
      config[fieldKey[0]] = fieldValue;
    }
    this.setState({ config, dirty: true });
  };

  rewardRadioHandler = (event: any) => {
    const value = event.target.value === 'true';
    const config = { ...this.state.config };
    if (!value && config.action) {
      delete config.action;
    }
    this.setState({ config, giveReward: value });
  };

  saveHandler = (isActive?: boolean) => () => {
    const config = { ...this.state.config, isActive };
    const { isValid } = this.props.validator!;
    if (
      !config.questions ||
      config.questions.some((question: ReviewQuestionDTO) => typeof question.type !== 'number' || !question.label)
    ) {
      AlertUtil.simple(
        msg('reviews.completeQuestionsWarning', 'Each question must have a type and a label!'),
        'warning'
      );
    } else if (
      config.questions.some(
        (question: ReviewQuestionDTO) =>
          (question.type === 1 || question.type === 2) &&
          (!question.options || question.options?.length < 2 || question.options.some(option => !option))
      )
    ) {
      AlertUtil.simple(
        msg('reviews.completeOptionsWarning', 'You must add at least two valid answer options!'),
        'warning'
      );
    } else if (isValid()) {
      const editMode = this.state.editMode;
      const placeId = this.props.match.params.placeId;
      editMode
        ? this.props.editReviewConfigAction(placeId, config._id, config)
        : this.props.saveReviewConfigAction(placeId, config);
    }
  };

  render() {
    const { errors } = this.props.validator!;
    const { config, editMode } = this.state;
    return (
      <CardComponent
        title={msg('cardTitle.configureReview', 'Configure config')}
        headerIcon="assignment"
        needsTitle={true}
        pending={this.props.config_pending}
      >
        {editMode && (
          <div style={{ fontSize: 14, marginBottom: 25 }}>
            {msg(
              'reviews.editHelp',
              '*You can edit only texts. For a different structure, please create a new configuration.'
            )}
          </div>
        )}
        <div className="row">
          <div className="col-sm-3">
            <TextFieldComponent
              id="name"
              inType="text"
              label={msg('reviews.configName', 'Name (it will not appear on mobile)')}
              value={config.name || ''}
              onChange={this.inputHandler}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <FormLabel style={{ marginTop: 15, marginBottom: 10 }}>{msg('reviews.questions', 'Questions')}</FormLabel>
          </div>
        </div>
        {config.questions &&
          config.questions.map((question: ReviewQuestionDTO, index: number) => {
            return (
              <ReviewQuestionComponent
                listItem={question}
                key={index}
                index={index}
                editMode={editMode}
                onChange={this.questionInputHandler}
                onDelete={this.onQuestionDelete}
                onAddOption={this.onAddQuestionOption}
                onOptionInput={this.questionOptionHandler}
                onOptionDelete={this.onDeleteQuestionOption}
              />
            );
          })}
        {!editMode && (
          <div className="row">
            <div className="col-md-6">
              <div className="form-group form-button">
                <ButtonComponent
                  label={msg('reviews.addQuestion', 'Add question')}
                  icon="quiz"
                  action={this.onAddQuestion}
                  pending={false}
                />
              </div>
            </div>
          </div>
        )}
        {this.props.place?.featureFlags?.useCoins && (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
              <FormLabel style={{ marginTop: 15, marginBottom: 10 }}>
                {msg('reviews.addReward', 'Do you want to give coins for completing the poll?')}
              </FormLabel>
              <RadioGroup
                name="giveReward"
                onChange={this.rewardRadioHandler}
                value={this.state.giveReward}
                row={true}
                style={{ display: 'flex', justifyContent: 'space-evenly', marginLeft: 25, paddingTop: 5 }}
              >
                <FormControlLabel
                  disabled={editMode}
                  value={false}
                  control={<Radio color="primary" />}
                  label={msg('general.no', 'No')}
                />
                <FormControlLabel
                  disabled={editMode}
                  value={true}
                  control={<Radio color="primary" />}
                  label={msg('general.yes', 'Yes')}
                />
              </RadioGroup>
            </div>
            {this.state.giveReward && (
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
                <FormLabel style={{ marginTop: 15, marginBottom: 10 }}>
                  {msg('reviews.coinsReward', 'Amount of coins:')}
                </FormLabel>
                <TextFieldComponent
                  id="action.value"
                  value={config.action?.value !== undefined ? config.action?.value + '' : ''}
                  onChange={this.inputHandler}
                  inType="text"
                  minLength={1}
                  required={false}
                  style={{ width: 50, marginLeft: 8, marginRight: 8 }}
                  inputStyle={{ paddingLeft: 5 }}
                  formatError={errors['action.value']}
                  ref="action.value"
                  validator={[
                    {
                      type: 'isInt',
                      msg: msg('formValidation.onlyPositiveNumbers', 'Use only positive numbers!'),
                      params: { min: 0 }
                    },
                    {
                      type: 'isLength',
                      msg: msg('formValidation.fieldRequired', 'Field required!'),
                      params: { min: 1 }
                    }
                  ]}
                />
              </div>
            )}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            padding: '0px 50px 0px 50px'
          }}
        >
          <ButtonComponent
            label={msg('general.save', 'Save')}
            icon="save"
            action={this.saveHandler()}
            pending={false}
            disabled={!this.state.dirty}
          />
          <ButtonComponent
            label={msg('reviews.saveAndActivate', 'Save and Activate')}
            icon="save"
            action={this.saveHandler(true)}
            pending={false}
            disabled={!this.state.dirty}
          />
        </div>
      </CardComponent>
    );
  }
}

export default Validator(ReviewFormComponent);
