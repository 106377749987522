import * as React from 'react';

import { AppStore, reduxContainer, AuthStore, TranslationsStore } from '../../../appRedux';
import { AuthUtil } from '../../../utils';
import { NavAuthComponent, FooterAuthComponent, LoginPanelComponent } from './components';

import backgroundImage from '../../../assets/images/background.jpg';
import { UserDeviceDTO } from '../../../appRedux/types';

interface IProps extends AuthStore.IState, TranslationsStore.IState {
  auth?: any;
}

interface IState {}

class AuthContainer extends React.Component<IProps & AuthStore.IActions & TranslationsStore.IActions, IState> {
  constructor(props: IProps & AuthStore.IActions & TranslationsStore.IActions) {
    super(props);
    if (AuthUtil.isPartnerAdmin() || AuthUtil.isPlaceAdmin()) {
      this.props.redirectAction('/partner');
    }
  }

  loginHandler = (email: string, password: string, deviceInfo: UserDeviceDTO) => {
    this.props.loginAction(email, password, deviceInfo);
  };

  render() {
    const navAuthConfig = {
      title: 'ReGage Partner',
      initial_language: this.props.initial_language,
      changeLanguage: this.props.changeLanguageAction
    };

    const loginState = {
      error: this.props.auth.login_error,
      pending: this.props.auth.login_pending
    };

    return (
      <div className="partner-auth off-canvas-sidebar">
        <NavAuthComponent config={navAuthConfig} />
        <div className="wrapper wrapper-full-page">
          <div className="full-page login-page" filter-color="black">
            <div className="content" style={{ paddingTop: '28vh' }}>
              <LoginPanelComponent loginAction={this.loginHandler} state={loginState} />
            </div>
            <FooterAuthComponent />
            <div className="full-page-background" style={{ backgroundImage: `url(${backgroundImage})` }} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    auth: state.auth,
    initial_language: state.translations.initial_language
  };
}

const dispatchToProps = {
  loginAction: AuthStore.actions.loginAction,
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction
};

export default reduxContainer(AuthContainer, mapStateToProps, dispatchToProps);
