export class SettlementDTO {
  _id: string = '';
  eventId: string;
  placeId: string;
  placeName: string;
  month?: string;
  months?: string[];
  usedVouchers: number;
  totalAmount: number;
  status: SettlementStatus;
  statusLabel: string;
  badge: string;
  created: number;
  deleted: boolean;
  changes: SettlementChangesDTO;
  isDirty?: boolean;
  isValid?: boolean;
  statusText?: string;
}

export class SettlementChangesDTO {
  created: number;
  userId: string;
  amount: number;
}

export const enum SettlementStatus {
  UNSENT,
  SENT,
  READ,
  APPROVED
}
