import { PartnersStore } from './';
import { PaginationDTO } from '../types';

/*
  partnersReducer = reducer for all actionTypes for Partners
*/
function partnersReducer(
  state: PartnersStore.IState = PartnersStore.initialState,
  action: IAction<any>
): PartnersStore.IState {
  switch (action.type) {
    case PartnersStore.ActionTypes.GET_PARTNERS: {
      return {
        ...state,
        partners_list_pending: true,
        partners_list_error: null
      };
    }
    case PartnersStore.ActionTypes.GET_PARTNERS_SUCCESS: {
      return {
        ...state,
        partners_list_pending: false,
        partners_list_error: null,
        partners_list: action.payload
      };
    }
    case PartnersStore.ActionTypes.GET_PARTNERS_FAILED: {
      return {
        ...state,
        partners_list_pending: false,
        partners_list: new PaginationDTO(),
        partners_list_error: action.payload
      };
    }
    case PartnersStore.ActionTypes.GET_NAMES_LIST_OF_PARTNERS: {
      return {
        ...state,
        names_of_partners_list_pending: true,
        names_of_partners_list_error: null
      };
    }
    case PartnersStore.ActionTypes.GET_NAMES_LIST_OF_PARTNERS_SUCCESS: {
      return {
        ...state,
        names_of_partners_list_pending: false,
        names_of_partners_list_error: null,
        names_of_partners_list: action.payload
      };
    }
    case PartnersStore.ActionTypes.GET_NAMES_LIST_OF_PARTNERS_FAILED: {
      return {
        ...state,
        names_of_partners_list_pending: false,
        names_of_partners_list: [],
        names_of_partners_list_error: action.payload
      };
    }
    case PartnersStore.ActionTypes.GET_PARTNERS_FOR_USER: {
      return {
        ...state,
        partners_list_for_user_pending: true,
        partners_list_for_user_error: null
      };
    }
    case PartnersStore.ActionTypes.GET_PARTNERS_FOR_USER_SUCCESS: {
      return {
        ...state,
        partners_list_for_user_pending: false,
        partners_list_for_user_error: null,
        partners_list_for_user: action.payload
      };
    }
    case PartnersStore.ActionTypes.GET_PARTNERS_FOR_USER_FAILED: {
      return {
        ...state,
        partners_list_for_user_pending: false,
        partners_list_for_user: new PaginationDTO(),
        partners_list_for_user_error: action.payload
      };
    }
    case PartnersStore.ActionTypes.GET_PARTNER: {
      return {
        ...state,
        partner_pending: true,
        partner_error: null
      };
    }
    case PartnersStore.ActionTypes.GET_PARTNER_SUCCESS: {
      const nextState = {
        ...state,
        partner_pending: false,
        partner_error: null,
        partner: action.payload,
        hasPartnerData: true
      };
      PartnersStore.setLocalStorage({ selected_partner: state.selected_partner, hasPartnerData: true });
      return nextState;
    }
    case PartnersStore.ActionTypes.GET_PARTNER_FAILED: {
      return {
        ...state,
        partner_pending: false,
        partner: null,
        partner_error: action.payload,
        hasPartnerData: false
      };
    }
    case PartnersStore.ActionTypes.SAVE_PARTNER: {
      return {
        ...state,
        partner_save_pending: true,
        partner_save_error: null,
        partner_saved: null
      };
    }
    case PartnersStore.ActionTypes.SAVE_PARTNER_SUCCESS: {
      return {
        ...state,
        partner_save_pending: false,
        partner_save_error: null,
        partner_saved: action.payload
      };
    }
    case PartnersStore.ActionTypes.SAVE_PARTNER_FAILED: {
      return {
        ...state,
        partner_save_pending: false,
        partner_save_error: action.payload,
        partner_saved: null
      };
    }
    case PartnersStore.ActionTypes.UPDATE_PARTNER_STATUS: {
      const partners_list = state.partners_list;
      const partnersArray = partners_list.results;
      const newPartnersList = partnersArray.map((partner: any) => {
        if (partner._id === action.payload._id) {
          partner = {
            ...action.payload,
            industry: partner.industry
          };
          return partner;
        } else {
          return partner;
        }
      });
      partners_list.results = newPartnersList;
      return {
        ...state,
        partner_save_pending: false,
        partner_save_error: null,
        partner_saved: action.payload,
        partners_list
      };
    }
    case PartnersStore.ActionTypes.DELETE_PARTNER: {
      return {
        ...state,
        partner_delete_pending: true,
        partner_delete_error: null,
        partner_deleted: false
      };
    }
    case PartnersStore.ActionTypes.DELETE_PARTNER_SUCCESS: {
      return {
        ...state,
        partner_delete_pending: false,
        partner_delete_error: null,
        partner_deleted: true,
        partners_list: action.payload
      };
    }
    case PartnersStore.ActionTypes.DELETE_PARTNER_FAILED: {
      return {
        ...state,
        partner_delete_pending: false,
        partner_delete_error: action.payload,
        partner_deleted: false
      };
    }
    case PartnersStore.ActionTypes.SELECT_PARTNER: {
      const nextState = {
        ...state,
        selected_partner: action.payload
      };
      const selPartner = action.payload;
      PartnersStore.setLocalStorage({ selected_partner: selPartner, hasPartnerData: state.hasPartnerData });
      return nextState;
    }
    case PartnersStore.ActionTypes.EXPORT_PARTNERS: {
      return {
        ...state,
        export_partners_pending: true,
        export_partners_error: null
      };
    }
    case PartnersStore.ActionTypes.EXPORT_PARTNERS_SUCCESS: {
      return {
        ...state,
        export_partners_pending: false,
        export_partners_error: null
      };
    }
    case PartnersStore.ActionTypes.EXPORT_PARTNERS_FAILED: {
      return {
        ...state,
        export_partners_pending: false,
        export_partners_error: action.payload
      };
    }
    default:
      return state;
  }
}

export default partnersReducer;
