import { Server } from '../../utils';
import { AxiosResponse } from 'axios';
import { PaginationDTO } from '../types';
import { serializeQueryString } from '../../utils/serializers';
import { IPackage } from '../types';

const BASE_URL = 'packages';

type IFetchPackagesServerResponse = PaginationDTO<IPackage>;
export const fetchPackagesList = async (options?: any) => {
  const optionsString = options
    ? `?${serializeQueryString(options)}`
    : '';
  const url = `${BASE_URL}${optionsString}`;
  const response = await Server.get(url) as AxiosResponse<IFetchPackagesServerResponse>;
  return response.data;
};

type IFetchPackageServerResponse = IPackage;
export const fetchPackage = async (packageId: string) => {
  const url = `${BASE_URL}/${packageId}`;
  const response = await Server.get(url) as AxiosResponse<IFetchPackageServerResponse>;
  return response.data;
};

type ICreatePackageServerResponse = IPackage;
export const createPackage = async (newPackage: Omit<IPackage, '_id'>) => {
  const url = `${BASE_URL}`;
  const response = await Server.post(url, { package: newPackage }) as AxiosResponse<ICreatePackageServerResponse>;
  return response.data;
};

interface IEditPackageServerResponse { }
export const editPackage = async (packageId: string, newPackage: IPackage) => {
  const url = `${BASE_URL}/${packageId}`;
  const response = await Server.put(url, { package: newPackage }) as AxiosResponse<IEditPackageServerResponse>;
  return response.data;
};

interface IRemovePackageServerResponse { }
export const removePackage = async (packageId: string) => {
  const url = `${BASE_URL}/${packageId}`;
  const response = await Server.delete(url) as AxiosResponse<IRemovePackageServerResponse>;
  return response.data;
};
