import checkinsActions, { ICheckinsActions } from './actions';
import checkinsReducer from './reducer';
import { PaginationDTO } from '../types';

/*
  CheckinsStore definition as a namespace that contains actions, reducers, actionTypes and checkins state
*/
export namespace CheckinsStore {
  export type IState = {
    checkins_list: PaginationDTO<any>;
    checkins_list_pending: boolean;
    checkins_list_error: string | null;
    checkin: any | null;
    checkin_pending: boolean;
    checkin_error: string | null;
    checkin_deleted: boolean;
    checkin_delete_pending: boolean;
    checkin_delete_error: string | null;
    changes_list: PaginationDTO<any>;
    changes_list_pending: boolean;
    changes_list_error: string | null;
  };

  export const initialState: IState = {
    checkins_list: new PaginationDTO(),
    checkins_list_pending: false,
    checkins_list_error: null,
    checkin: null,
    checkin_pending: false,
    checkin_error: null,
    checkin_deleted: false,
    checkin_delete_pending: false,
    checkin_delete_error: null,
    changes_list: new PaginationDTO(),
    changes_list_pending: false,
    changes_list_error: null
  };

  export enum ActionTypes {
    GET_CHECKINS = 'GET_CHECKINS',
    GET_CHECKINS_SUCCESS = 'GET_CHECKINS_SUCCESS',
    GET_CHECKINS_FAILED = 'GET_CHECKINS_FAILED',
    GET_CHECKIN = 'GET_CHECKIN',
    GET_CHECKIN_SUCCESS = 'GET_CHECKIN_SUCCESS',
    GET_CHECKIN_FAILED = 'GET_CHECKIN_FAILED',
    DELETE_CHECKIN = 'DELETE_CHECKIN',
    DELETE_CHECKIN_SUCCESS = 'DELETE_CHECKIN_SUCCESS',
    DELETE_CHECKIN_FAILED = 'DELETE_CHECKIN_FAILED',
    GET_CHANGES = 'GET_CHANGES',
    GET_CHANGES_SUCCESS = 'GET_CHANGES_SUCCESS',
    GET_CHANGES_FAILED = 'GET_CHANGES_FAILED'
  }

  export const actions = checkinsActions;
  export const reducer = checkinsReducer;
  export interface IActions extends ICheckinsActions {}
}

export * from './actions';
