import { Dispatch } from 'redux';
import { push } from 'connected-react-router';

import { PartnersStore } from './';
import { PlacesStore } from '../';
import { Server, composedCriteriaBuilder, AlertUtil, msg, logger } from '../../utils';
import { PartnerDTO, PartnerWithPlaceDTO, PaginationDTO, SelectValueDTO } from '../types';
import moment from 'moment';
import { generatePath } from 'react-router-dom';
import { adminRoutes } from '../../modules/layouts/routes';

/*
  IPartnersActions interface definition, which contains every redux action asociated with Partners State.
*/
export interface IPartnersActions {
  addPartnerAction(partnerWithPlace: PartnerWithPlaceDTO): any;
  deletePartnerAction(partnerId: string): any;
  editPartnerAction(partner: PartnerDTO | { _id: string; status: number }): any;
  editPartnerStatusAction(partner: any): any;
  editPlaceStatusAction(partnerId: string, place: any): any;
  getPartnerAction(partnerId: string, needsSelectPlace?: boolean): any;
  getNamesListForPartnersAction(): any;
  getPartnersListAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }): any;
  redirectToEditAction(partnerId: string): any;
  selectPartnerLogin(partnerId: string, needsSelectPlace?: boolean): any;
  exportPartnersInExcel(partnerId: string, startDate: number, endDate: number): any;
}

/*
  class PartnersActions that implements redux actions defined in IPartnersActions interface
*/
class PartnersActions implements IPartnersActions {
  /*
    @function addPartnerAction => Redux action that adds a new partner on both redux and server
      @accepts partnerWithPlace : PartnerWithPlaceDTO object that contains information completed on add partner form
      @returns Promise
  */
  addPartnerAction(partnerWithPlace: PartnerWithPlaceDTO) {
    return async (dispatch: Dispatch<any>, getState: () => any) => {
      dispatch({
        type: PartnersStore.ActionTypes.SAVE_PARTNER
      });
      AlertUtil.simple(
        msg('reduxMessages.partners.addPartnerPending', 'The partner is being added, please wait...'),
        'info'
      );
      logger.msg('Add partner action, route:/partners', 'POST');
      await Server.post('partners', partnerWithPlace)
        .then((response: any) => {
          dispatch({
            type: PartnersStore.ActionTypes.SAVE_PARTNER_SUCCESS,
            payload: response.data as PartnerDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.partners.addPartnerSuccess', 'The partner was successfully added!'),
            'success'
          );
          const { limit, skip } = getState().partners.partners_list;
          dispatch(PartnersStore.actions.getPartnersListAction(limit, skip, 'updatedAt,-1'));
          dispatch(push(adminRoutes.PARTNERS.path));
        })
        .catch(error => {
          logger.err('Add partner action, route:/partners', 'POST');
          AlertUtil.updateContent(
            msg('reduxMessages.partners.addPartnerError', 'Due to an error, the partner could not be added!'),
            'error'
          );
          dispatch({
            type: PartnersStore.ActionTypes.SAVE_PARTNER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function deletePartnerAction => Redux action that deletes an existing partner on both redux and server
      @accepts partnerId : string that represents the id of the partner to be deleted
      @returns Promise
  */
  deletePartnerAction(partnerId: string) {
    return (dispatch: Dispatch<any>) => {
      AlertUtil.simple(msg('alertMessages.partnerBeingDeleted', 'The partner is being deleted...'), 'info');
      dispatch({
        type: PartnersStore.ActionTypes.DELETE_PARTNER
      });
      logger.msg('Delete partner action, route:/partners/partnerId', 'DELETE');
      Server.delete(`partners/${partnerId}`)
        .then(async (response: any) => {
          const getPartnersResponse: any = await Server.get('partners');
          dispatch({
            type: PartnersStore.ActionTypes.DELETE_PARTNER_SUCCESS,
            payload: getPartnersResponse.data as PartnerDTO
          });
          AlertUtil.updateContent(
            msg('alertMessages.partnerDeleted', 'The partner has been successfully deleted!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Delete partner action, route:/partners/partnerId', 'DELETE');
          AlertUtil.updateContent(
            msg('reduxMessages.partners.deletePartnerError', 'Due to an error, the partner could not be deleted!'),
            'error'
          );
          dispatch({
            type: PartnersStore.ActionTypes.DELETE_PARTNER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function editPartnerAction => Redux action for editing an existing partner from redux and server
      @accepts partner : PartnerDTO representing the object with information completed in edit partner form
      @returns Promise
  */
  editPartnerAction(partner: PartnerDTO) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: PartnersStore.ActionTypes.SAVE_PARTNER
      });
      AlertUtil.simple(
        msg('reduxMessages.partners.editPartnerPending', 'The partner is being updated, please wait...'),
        'info'
      );
      delete partner.updatedAt;
      logger.msg('Edit partner action, route:/partners/partnerId', 'PUT');
      await Server.put(`partners/${partner._id}`, partner)
        .then((response: any) => {
          dispatch({
            type: PartnersStore.ActionTypes.SAVE_PARTNER_SUCCESS,
            payload: response.data as PartnerDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.partners.editPartnerSuccess', 'The partner was successfully updated!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Edit partner action, route:/partners/partnerId', 'PUT');
          AlertUtil.updateContent(
            msg('reduxMessages.partners.editPartnerError', 'Due to an error, the partner could not be updated!'),
            'error'
          );
          dispatch({
            type: PartnersStore.ActionTypes.SAVE_PARTNER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function editPlaceStatusAction => Redux action for editing the status of an existing place
      @accepts partner : object that contains the new status and partner id to be updated
      @returns Promise
  */
  editPlaceStatusAction(partnerId: string, place: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: PlacesStore.ActionTypes.CHANGE_PLACE_STATUS
      });
      AlertUtil.simple(
        msg('reduxMessages.partners.editPartnerPending', 'The partner is being updated, please wait...'),
        'info'
      );
      logger.msg('Edit place status action, route:/partners/partnerId/active-place/placeId', 'PUT');
      await Server.put(`partners/${partnerId}/active-place/${place._id}`, place)
        .then((response: any) => {
          dispatch({
            type: PlacesStore.ActionTypes.CHANGE_PLACE_STATUS_SUCCESS,
            payload: response.data as PartnerDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.partners.editPartnerSuccess', 'The partner was successfully updated!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Edit place status action, route:/partners/partnerId/active-partner', 'PUT');
          AlertUtil.updateContent(
            msg('reduxMessages.partners.editPartnerError', 'Due to an error, the partner could not be updated!'),
            'error'
          );
          dispatch({
            type: PlacesStore.ActionTypes.CHANGE_PLACE_STATUS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function editPartnerStatusAction => Redux action for editing the status of an existing partner
      @accepts partner : object that contains the new status and partner id to be updated
      @returns Promise
  */
  editPartnerStatusAction(partner: any) {
    return async (dispatch: Dispatch<any>, getState: () => any) => {
      dispatch({
        type: PartnersStore.ActionTypes.SAVE_PARTNER
      });
      AlertUtil.simple(
        msg('reduxMessages.partners.editPartnerPending', 'The partner is being updated, please wait...'),
        'info'
      );
      logger.msg('Edit partner status action, route:/partners/partnerId/active-partner', 'PUT');
      await Server.put(`partners/${partner._id}/active-partner`, partner)
        .then((response: any) => {
          dispatch({
            type: PartnersStore.ActionTypes.UPDATE_PARTNER_STATUS,
            payload: response.data as PartnerDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.partners.editPartnerSuccess', 'The partner was successfully updated!'),
            'success'
          );
          const { limit, skip } = getState().partners.partners_list;
          dispatch(PartnersStore.actions.getPartnersListAction(limit, skip, 'updatedAt,-1'));
        })
        .catch(error => {
          logger.err('Edit partner status action, route:/partners/partnerId/active-partner', 'PUT');
          AlertUtil.updateContent(
            msg('reduxMessages.partners.editPartnerError', 'Due to an error, the partner could not be updated!'),
            'error'
          );
          dispatch({
            type: PartnersStore.ActionTypes.SAVE_PARTNER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getPartnerAction => Redux action for getting the information of an existing partner
      @accepts partnerId : string representing the id of the partner you want to get information about
      @returns Promise
  */
  getPartnerAction(partnerId: string, needsSelectPlace?: boolean) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: PartnersStore.ActionTypes.GET_PARTNER
      });
      logger.msg('Get partner action, route:/partners/partnerId', 'GET');
      Server.get(`partners/${partnerId}`)
        .then((response: any) => {
          dispatch({
            type: PartnersStore.ActionTypes.GET_PARTNER_SUCCESS,
            payload: response.data as PartnerDTO
          });
          if (needsSelectPlace) {
            dispatch(PlacesStore.actions.selectPlaceTopbar(response.data.places[0]));
          }
        })
        .catch(error => {
          logger.err('Get partner action, route:/partners/partnerId', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.partners.getPartnerError', 'Due to an error, the partner data could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: PartnersStore.ActionTypes.GET_PARTNER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getNamesListForPartnersAction() {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PartnersStore.ActionTypes.GET_NAMES_LIST_OF_PARTNERS });
      try {
        logger.msg('Get partners list action, route:/partners', 'GET');
        const response: any = await Server.get('partners/names');
        dispatch({
          type: PartnersStore.ActionTypes.GET_NAMES_LIST_OF_PARTNERS_SUCCESS,
          payload: response.data as PaginationDTO<SelectValueDTO>
        });
      } catch (error) {
        logger.err('Get partners list action, route:/partners', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.partners.getPartnersError', 'Due to an error, the partners list could not be loaded!'),
          'error',
          2000
        );
        dispatch({
          type: PartnersStore.ActionTypes.GET_NAMES_LIST_OF_PARTNERS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  /*
    @function getPartnersListAction => Redux action for getting the list of partners
      (optional) limit, skip, sort, criteria : params used for pagination
      @returns Promise
  */
  getPartnersListAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PartnersStore.ActionTypes.GET_PARTNERS });
      try {
        if (!limit) {
          limit = 30;
        }
        let url = `partners?limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get partners list action, route:/partners', 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: PartnersStore.ActionTypes.GET_PARTNERS_SUCCESS,
          payload: response.data as PaginationDTO<PartnerDTO>
        });
      } catch (error) {
        logger.err('Get partners list action, route:/partners', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.partners.getPartnersError', 'Due to an error, the partners list could not be loaded!'),
          'error',
          2000
        );
        dispatch({
          type: PartnersStore.ActionTypes.GET_PARTNERS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  /*
    @function redirectToEditAction => Redux action that redirects user to edit partner form page
      @accepts partnerId : string that represents the id of the partner to be edited
      @returns Promise
  */
  redirectToEditAction(partnerId: string) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(adminRoutes.PARTNERS.subroutes.EDIT.path, { partnerId });
      dispatch(push(path));
    };
  }

  /*
    @function selectPartnerLogin => Redux action for selecting which partner to login to
      @accepts partnerId : string representing the id of the selected partner
      @returns Promise
  */
  selectPartnerLogin(partnerId: string, needsSelectPlace?: boolean) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: PartnersStore.ActionTypes.SELECT_PARTNER,
        payload: partnerId
      });
      dispatch(PartnersStore.actions.getPartnerAction(partnerId, needsSelectPlace));
    };
  }

  exportPartnersInExcel(partnerId: string, startDate: number, endDate: number) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: PartnersStore.ActionTypes.EXPORT_PARTNERS
      });
      const startDateFormat = moment(startDate).format('DD-MM-YYYY');
      const endDateFormat = moment(endDate).format('DD-MM-YYYY');
      const name = `Report Partners ${startDateFormat} - ${endDateFormat}.xlsx`;
      let url = `/partners/${partnerId}/report-partners?startDate=${startDate}&endDate=${endDate}`;
      logger.msg('Export partners in excel action, route:/partners/partnerId/report-partners', 'GET');
      Server.downloadReports(url, name)
        .then((response: any) => {
          dispatch({
            type: PartnersStore.ActionTypes.EXPORT_PARTNERS_SUCCESS,
            payload: response
          });
        })
        .catch(error => {
          logger.err('Export partners in excel action, route:/partners/partnerId/report-partners', 'GET');
          dispatch({
            type: PartnersStore.ActionTypes.EXPORT_PARTNERS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }
}

const partnersActions = new PartnersActions();
export default partnersActions;
