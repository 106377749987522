import * as React from 'react';

import { Hidden } from '@material-ui/core';

import { getFormatDate, msg } from '../../../../../utils';
import { CardComponent, ChartComponent, StatisticsListComponent } from '../../../../shared';
import {
    generateColors, generateDataForChart, generateDataForPieChart
} from '../ChartFunctionsForPlaceDashboard';

interface IProps {
  placeId: string;
  partnerId: string;
  agg_statistics_error: any;
  agg_statistics_pending: any;
  agg_statistics: any;
  type: string;
  pieChartOptions: any;
  stackedBarOptions: any;
}

const AutopilotsTab = (props: IProps) => {
  const parseDistributionData = () => {
    let data: any = {};
    props.agg_statistics?.forEach((item: any) => {
      item.autopilots?.forEach((autopilot: any) => {
        if (!data[autopilot.title]) {
          data[autopilot.title] = 0;
        }
        data[autopilot.title] += autopilot.used;
      });
    });
    return {
      labels: Object.keys(data).map(key => msg(`simpleAutopilots.${key}`, key)),
      datasets: generateDataForPieChart(Object.values(data))
    };
  };

  const createChartAutopilots = () => {
    const titles = {};
    props.agg_statistics?.forEach((item: any) => {
      item.autopilots?.forEach((autopilot: any) => {
        titles[autopilot.title] = [];
      });
    });
    let labels: any[] = [];
    let data: any = {};
    props.agg_statistics?.forEach((item: any) => {
      Object.keys(titles)?.forEach((title: any) => {
        const autopilotFound = item.autopilots?.find((statAutopilot: any) => statAutopilot.title === title);
        if (!data[title]) {
          data[title] = [];
        }
        data[title].push(autopilotFound?.used || 0);
      });
      let date = getFormatDate(item.startDate, 'date');
      if (props.type !== 'DAY') {
        date += ` - ${getFormatDate(item.endDate, 'date')}`;
      }
      labels.push(date);
    });
    return {
      labels,
      datasets: generateDataForChart(data)
    };
  };

  const parseAutopilots = (list: any) => {
    let data = {};
    list?.forEach((item: any) => {
      item.autopilots?.forEach((autopilot: any) => {
        if (data[autopilot.title]) {
          data[autopilot.title].triggered += autopilot.triggered;
          data[autopilot.title].claims += autopilot.claims;
          data[autopilot.title].redeems += autopilot.used;
        } else {
          data[autopilot.title] = {
            name: msg(`simpleAutopilots.${autopilot.title}`, autopilot.title),
            triggered: autopilot.triggered,
            claims: autopilot.claims,
            redeems: autopilot.used
          };
        }
      });
    });
    return Object.values(data);
  };

  const getAutopilotListData = () => {
    const list = parseAutopilots(props.agg_statistics);
    const listOfColors: any[] = generateColors(list);
    return {
      fields: {
        name: msg('autopilot.title', 'Rule title'),
        triggered: msg('autopilot.triggeredTimes', 'Triggered times'),
        claims: msg('general.claims', 'Claims'),
        redeems: msg('general.redeems', 'Redeems')
      },
      list: list,
      badgeColors: listOfColors,
      pending: props.agg_statistics_pending,
      error: props.agg_statistics_error
    };
  };

  return (
    <div style={{ marginTop: 5 }}>
      <CardComponent
        title={msg('charts.autopilotStatistics', 'Autopilot statistics')}
        error={!!props.agg_statistics_error}
        pending={props.agg_statistics_pending}
        headerIcon="assignment"
      >
        <Hidden smDown={true}>
          <div className="row">
            <div className="col-lg-3">
              <h4 style={{ textAlign: 'center' }}>
                {msg('charts.totalAutopilotDistribution', 'Total reward redeems from autopilots')}
              </h4>
              <div style={{ marginTop: 20 }}>
                <ChartComponent
                  data={parseDistributionData()}
                  chartType="PIE"
                  chartHeight={280}
                  options={props.pieChartOptions}
                />
              </div>
            </div>
            <div className="col-lg-9">
              <h4 style={{ textAlign: 'center' }}>{msg('charts.autopilotPer', 'Autopilot rules')}</h4>
              <ChartComponent
                chartHeight={100}
                data={createChartAutopilots()}
                chartType="BAR"
                options={props.stackedBarOptions}
              />
            </div>
          </div>
        </Hidden>
        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-lg-12">
            {!props.agg_statistics_pending && <StatisticsListComponent data={getAutopilotListData()} />}
          </div>
        </div>
      </CardComponent>
    </div>
  );
};

export default AutopilotsTab;
