import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { AgendaJobsContainer } from './';
import { adminRoutes } from '../../layouts';

const AgendaRouter = () => {
  return (
    <Switch>
      <Route exact={true} path={adminRoutes.AGENDA.path} render={() => <AgendaJobsContainer />} />
    </Switch>
  );
};

export default AgendaRouter;
