import { Dispatch } from 'redux';
import { push } from 'connected-react-router';

import { PartnerEmailsStore } from '.';
import { Server, composedCriteriaBuilder, AlertUtil, msg, logger } from '../../utils';
import { PaginationDTO, PartnerEmailDTO } from '../types';
import { generatePath } from 'react-router-dom';
import { adminRoutes, partnerRoutes } from '../../modules/layouts/routes';

/*
  IPartnerEmailsActions interface definition, which contains every redux action asociated with PartnerEmails State.
*/
export interface IPartnerEmailsActions {
  getPartnerEmailsAction(
    isSAdmin: boolean,
    partnerId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  addPartnerEmailAction(isSAdmin: boolean, partnerId: string, partnerEmail: PartnerEmailDTO): any;
  getPartnerEmailAction(isSAdmin: boolean, partnerId: string, partnerEmailId: string): any;
  readEmailAction(emailId: string, userId: string): any;
  deleteAttachmentAction(isSAdmin: boolean, partnerId: string, attachment: any): any;
  redirectToPartnerEmail(isSAdmin: boolean, partnerEmailId?: string): any;
  clearPartnerEmailsError(): any;
  clearPartnerEmailAction(): any;
}

/*
  class PartnerEmailsActions that implements redux actions defined in IPartnerEmailsActions interface
*/
class PartnerEmailsActions implements IPartnerEmailsActions {
  getPartnerEmailsAction(
    isSAdmin: boolean,
    partnerId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PartnerEmailsStore.ActionTypes.GET_PARTNER_EMAILS });
      try {
        if (!limit) {
          limit = 30;
        }
        let url;
        if (isSAdmin) {
          url = `admin/partner-emails?limit=${limit}`;
        } else {
          url = `partners/${partnerId}/emails?limit=${limit}`;
        }
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg(`Get partnerEmails action, route:/partnerEmails`, 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: PartnerEmailsStore.ActionTypes.GET_PARTNER_EMAILS_SUCCESS,
          payload: response.data as PaginationDTO<PartnerEmailDTO>
        });
      } catch (error) {
        logger.err('Get partnerEmails action, route:/partnerEmails', 'GET');
        AlertUtil.updateContent(
          msg(
            'reduxMessages.partnerEmails.getPartnerEmailsError',
            'Due to an error, the partnerEmails list could not be loaded!'
          ),
          'error'
        );
        dispatch({
          type: PartnerEmailsStore.ActionTypes.GET_PARTNER_EMAILS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  addPartnerEmailAction(isSAdmin: boolean, partnerId: string, partnerEmail: PartnerEmailDTO) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: PartnerEmailsStore.ActionTypes.SAVE_PARTNER_EMAIL
      });
      AlertUtil.simple('The email is being created, please wait!', 'info');
      logger.msg('Add email action, route:/partners/:id/email', 'POST');
      let url;
      if (isSAdmin) {
        url = 'admin/partner-emails';
      } else {
        url = `partners/${partnerId}/emails`;
      }
      await Server.post(url, partnerEmail)
        .then((response: any) => {
          dispatch({
            type: PartnerEmailsStore.ActionTypes.SAVE_PARTNER_EMAIL_SUCCESS,
            payload: response.data as PartnerEmailDTO
          });
          AlertUtil.updateContent('The email was successfully created!', 'success');
          if (isSAdmin) {
            dispatch(push(adminRoutes.EMAILS.path));
          } else {
            dispatch(push(partnerRoutes.EMAILS.path));
          }
        })
        .catch(error => {
          logger.err('Add email action, route:/partners/:id/email', 'POST');
          AlertUtil.updateContent('Due to an error, the partnerEmail could not be created!', 'error');
          dispatch({
            type: PartnerEmailsStore.ActionTypes.SAVE_PARTNER_EMAIL_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getPartnerEmailAction(isSAdmin: boolean, partnerId: string, partnerEmailId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: PartnerEmailsStore.ActionTypes.GET_PARTNER_EMAIL
      });
      logger.msg('Get email action, route:/partnerEmails/partnerEmailId', 'GET');
      let url;
      if (isSAdmin) {
        url = `admin/partner-emails/${partnerEmailId}`;
      } else {
        url = `partners/${partnerId}/emails/${partnerEmailId}`;
      }
      await Server.get(url)
        .then((response: any) => {
          dispatch({
            type: PartnerEmailsStore.ActionTypes.GET_PARTNER_EMAIL_SUCCESS,
            payload: response.data as PartnerEmailDTO
          });
        })
        .catch(error => {
          logger.err('GET email action error, route:/partnerEmails/partnerEmailId', 'GET');
          dispatch({
            type: PartnerEmailsStore.ActionTypes.GET_PARTNER_EMAIL_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  readEmailAction(emailId: string, userId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: PartnerEmailsStore.ActionTypes.READ_PARTNER_EMAIL
      });
      logger.msg('Read email action, route:/partners/:emailId', 'GET');
      await Server.get(`partners/emails/${emailId}/read/${userId}`, true)
        .then((response: any) => {
          dispatch({
            type: PartnerEmailsStore.ActionTypes.READ_PARTNER_EMAIL_SUCCESS
          });
        })
        .catch(error => {
          logger.err('Read email action, route:/partners/:emailId', 'GET');
          dispatch({
            type: PartnerEmailsStore.ActionTypes.READ_PARTNER_EMAIL_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  deleteAttachmentAction(isSAdmin: boolean, partnerId: string, attachment: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: PartnerEmailsStore.ActionTypes.DELETE_ATTACHMENT
      });
      logger.msg('Delete attachment action, route:/partners/:id/delete-attachment', 'PUT');
      let url;
      if (isSAdmin) {
        url = `admin/partner-emails/delete-attachment`;
      } else {
        url = `partners/${partnerId}/emails/delete-attachment`;
      }
      await Server.put(url, attachment)
        .then((response: any) => {
          dispatch({
            type: PartnerEmailsStore.ActionTypes.DELETE_ATTACHMENT_SUCCESS
          });
        })
        .catch(error => {
          logger.err('Delete attachment action, route:/partners/:id/delete-attachment', 'PUT');
          dispatch({
            type: PartnerEmailsStore.ActionTypes.DELETE_ATTACHMENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  redirectToPartnerEmail(isSAdmin: boolean, emailId?: string) {
    return (dispatch: Dispatch<any>) => {
      if (isSAdmin) {
        if (emailId) {
          const path = generatePath(adminRoutes.EMAILS.subroutes.EDIT.path, { emailId });
          dispatch(push(path));
        } else {
          dispatch(push(adminRoutes.EMAILS.subroutes.ADD.path));
        }
      } else {
        if (emailId) {
          const path = generatePath(partnerRoutes.EMAILS.subroutes.EDIT.path, { emailId });
          dispatch(push(path));
        } else {
          dispatch(push(partnerRoutes.EMAILS.subroutes.ADD.path));
        }
      }
    };
  }

  clearPartnerEmailAction() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: PartnerEmailsStore.ActionTypes.CLEAR_PARTNER_EMAIL });
    };
  }

  clearPartnerEmailsError() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: PartnerEmailsStore.ActionTypes.CLEAR_EMAILS_ERROR });
    };
  }
}

const partnerEmailsActions = new PartnerEmailsActions();
export default partnerEmailsActions;
