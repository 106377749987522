import membersActions, { IMembersActions } from './actions';
import membersReducer from './reducer';
import { PaginationDTO, EventDTO } from '../types';

/*
  MembersStore definition as a namespace that contains actions, reducers, actionTypes and members state
*/
export namespace MembersStore {
  export type IState = {
    members_list: PaginationDTO<any>;
    members_list_pending: boolean;
    members_list_error: string | null;
    member: any | null;
    member_pending: boolean;
    member_error: string | null;
    member_change_status: any | null;
    member_change_status_pending: boolean;
    member_change_status_error: any | null;
    memberships_for_user_list: PaginationDTO<any>;
    memberships_for_user_list_pending: boolean;
    memberships_for_user_list_error: string | null;
    favouriteMoments: PaginationDTO<EventDTO>;
    favouriteMomentsPending: boolean;
    favouriteMomentsError: string | null;
    export_members_pending: boolean;
    export_members_error: any | null;
  };

  export const initialState: IState = {
    members_list: new PaginationDTO(),
    members_list_pending: false,
    members_list_error: null,
    member: null,
    member_pending: false,
    member_error: null,
    member_change_status: null,
    member_change_status_pending: false,
    member_change_status_error: false,
    memberships_for_user_list: new PaginationDTO(),
    memberships_for_user_list_pending: false,
    memberships_for_user_list_error: null,
    favouriteMoments: new PaginationDTO(),
    favouriteMomentsPending: false,
    favouriteMomentsError: null,
    export_members_pending: false,
    export_members_error: null
  };

  export enum ActionTypes {
    GET_MEMBERS = 'GET_MEMBERS',
    GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS',
    GET_MEMBERS_FAILED = 'GET_MEMBERS_FAILED',
    GET_MEMBER = 'GET_MEMBER',
    GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS',
    GET_MEMBER_FAILED = 'GET_MEMBER_FAILED',
    CHANGE_MEMBER_STATUS = 'CHANGE_MEMBER_STATUS',
    CHANGE_MEMBER_STATUS_SUCCESS = 'CHANGE_MEMBER_STATUS_SUCCESS',
    CHANGE_MEMBER_STATUS_FAILED = 'CHANGE_MEMBER_STATUS_FAILED',
    GET_MEMBERSHIPS_FOR_USER = 'GET_MEMBERSHIPS_FOR_USER',
    GET_MEMBERSHIPS_FOR_USER_SUCCESS = 'GET_MEMBERSHIPS_FOR_USER_SUCCESS',
    GET_MEMBERSHIPS_FOR_USER_FAILED = 'GET_MEMBERSHIPS_FOR_USER_FAILED',
    GET_FAVOURITE_MOMENTS = 'GET_FAVOURITE_MOMENTS',
    GET_FAVOURITE_MOMENTS_SUCCESS = 'GET_FAVOURITE_MOMENTS_SUCCESS',
    GET_FAVOURITE_MOMENTS_FAILED = 'GET_FAVOURITE_MOMENTS_FAILED',
    CLEAR_MEMBERS_ERROR = 'CLEAR_MEMBERS_ERROR',
    EXPORT_MEMBERS = 'EXPORT_MEMBERS',
    EXPORT_MEMBERS_SUCCESS = 'EXPORT_MEMBERS_SUCCESS',
    EXPORT_MEMBERS_FAILED = 'EXPORT_MEMBERS_FAILED'
  }

  export const actions = membersActions;
  export const reducer = membersReducer;
  export interface IActions extends IMembersActions {}
}

export * from './actions';
