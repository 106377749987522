import { IDictionary } from './helpers';

const serializeQueryStringValue = (value: any): string =>
  typeof value === 'object' ? JSON.stringify(value) : `${value}`;

export const serializeQueryString = (options: any): string => {
  if (!options) {
    return '';
  }

  return Object.entries(options)
    .filter(([_, value]) => !!value)
    .map(([name, value]) => [name, serializeQueryStringValue(value)])
    .map(([name, value]) => `${name}=${value}`)
    .join('&');
};

export const deserializeQueryString = (queryString: string): IDictionary<string> => {
  if (!queryString) {
    return {};
  }

  const qs = queryString[0] === '?' ? queryString.slice(1) : queryString;

  return qs
    .split('&')
    .map(param => param.split('='))
    .reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {});
};

export const queryStringToCriteria = (queryString: string, searchFields?: string[]) => {
  const queryCriteria = deserializeQueryString(queryString);
  delete queryCriteria.limit;
  delete queryCriteria.skip;
  let criteria: { [key: string]: any } = {};
  if (queryCriteria.sort) {
    criteria.sort = queryCriteria.sort;
    delete queryCriteria.sort;
  }
  criteria.search = {};
  if (queryCriteria.search) {
    if (searchFields?.length) {
      searchFields.forEach(field => {
        criteria.search[field] = decodeURIComponent(queryCriteria.search);
      });
    } else {
      criteria.search = decodeURIComponent(queryCriteria.search);
    }
    delete queryCriteria.search;
  }
  criteria.filters = {};
  Object.keys(queryCriteria).forEach(key => {
    const decoded = decodeURIComponent(queryCriteria[key]);
    try {
      const parsed = JSON.parse(decoded);
      criteria.filters[key] = parsed;
    } catch (err) {
      criteria.filters[key] = decoded;
    }
  });
  return criteria;
};
