import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { Dispatch } from 'redux';

import { adminRoutes, partnerRoutes } from '../../modules/layouts/routes';
import {
    AlertUtil, composedCriteriaBuilder, getDateInterval, logger, msg, queryStringToCriteria, Server
} from '../../utils';
import { AppStore } from '../reducers';
import { PaginationDTO, ProfileDTO, UserDTO } from '../types';
import { UsersStore } from './';

/*
  IUsersActions interface definition, which contains every redux action asociated with Users State.
*/
export interface IUsersActions {
  /*
    General actions and for super admin
  */
  changePasswordAction(email: string, oldPassword: string, newPassword: string): any;
  changePasswordAsAdmin(userId: string, newPassword: string): any;
  deleteActionForUser(userId: string): any;
  editUserAction(user: UserDTO): any;
  getUserAction(userId: string): any;
  getUsersListAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }): any;
  redirectToEditUserAction(userId: string): any;

  /*
    Actions specific for partner
  */
  addPartnerAdminAction(user: UserDTO, partnerId: string): any;
  addPartnerAdminByIdAction(userId: string, partnerId: string): any;
  getAdminsForPartnerAction(partnerId: string): any;
  redirectToAddPartnerAdminAction(partnerId: string): any;
  removePartnerAdminAction(partnerId: string, userId: string): any;

  /*
    Actions specific for place
  */
  addPlaceAdminAction(user: UserDTO, placeId: string, forSA: boolean): any;
  getAdminsForPlaceAction(placeId: string, includePartner?: boolean): any;
  removePlaceAdminAction(placeId: string, userId: string): any;
  redirectToAddPlaceAdminAction(placeId: string, forSAdmin: boolean): any;

  getTabletProfilesAction(
    placeId: string,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  addTabletProfileAction(name: string, placeId: string): any;
  editTabletProfileAction(placeId: string, profileId: string, name: string): any;
  deleteTabletProfileAction(placeId: string, profileId: string): any;
}

/*
  class TranslationsActions that implements redux actions defined in ITranslationsActions interface
*/
class UsersActions implements IUsersActions {
  /*
    @function changePasswordAction => Redux action that initiates procedure to change password of an user
      @accepts email : string representing the email of the user that wants to change password
               oldPassword : string representing the old password
               newPassword : string representing the new password
      @returns Promise
  */
  changePasswordAction(email: string, oldPassword: string, newPassword: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: UsersStore.ActionTypes.CHANGE_USER_PASSWORD
      });
      AlertUtil.simple(
        msg('reduxMessages.users.changePassPending', 'The password has been successfully changed!'),
        'info'
      );
      logger.msg('Change password action, route:/auth/change-pass', 'POST');
      Server.post(`auth/change-pass`, {
        username: email,
        password: oldPassword,
        newPassword: newPassword
      })
        .then(async (response: any) => {
          dispatch({
            type: UsersStore.ActionTypes.CHANGE_USER_PASSWORD_SUCCESS,
            payload: response.data as UserDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.users.changePassSuccess', 'The password has been successfully changed!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Change password action, route:/auth/change-pass', 'POST');
          AlertUtil.updateContent(
            msg('reduxMessages.users.changePassError', 'Due to an error, the password could not be changed!'),
            'error'
          );
          dispatch({
            type: UsersStore.ActionTypes.CHANGE_USER_PASSWORD_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
  @function changePasswordAsAdmin => Redux action that initiates procedure to change password of an user
    @accepts email : string representing the email of the user that wants to change password
             newPassword : string representing the new password
    @returns Promise
*/
  changePasswordAsAdmin(userId: string, newPassword: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: UsersStore.ActionTypes.CHANGE_USER_PASSWORD
      });
      AlertUtil.simple(
        msg('reduxMessages.users.changePassPending', 'The password has been successfully changed!'),
        'info'
      );
      logger.msg('Change password action, route:/admin/reset-pass-by-admin', 'POST');
      Server.post(`admin/reset-pass-by-admin`, {
        userId,
        password: newPassword
      })
        .then(async (response: any) => {
          dispatch({
            type: UsersStore.ActionTypes.CHANGE_USER_PASSWORD_SUCCESS,
            payload: response.data as UserDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.users.changePassSuccess', 'The password has been successfully changed!'),
            'success'
          );
          dispatch(push(`/admin/users`));
        })
        .catch(error => {
          logger.err('Change password action, route:/admin/reset-pass-by-admin', 'POST');
          AlertUtil.updateContent(
            msg('reduxMessages.users.changePassError', 'Due to an error, the password could not be changed!'),
            'error'
          );
          dispatch({
            type: UsersStore.ActionTypes.CHANGE_USER_PASSWORD_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function deleteActionForUser => Redux action that deletes an existing user
      @accepts userId : string representing the id of the user to be deleted
      @returns Promise
  */
  deleteActionForUser(userId: string) {
    return (dispatch: Dispatch<any>, getState: () => any) => {
      AlertUtil.simple(msg('alertMessages.userBeingDeleted', 'The user is being deleted...'), 'info');
      dispatch({
        type: UsersStore.ActionTypes.DELETE_USER
      });
      logger.msg('Delete user action, route:/admin/delete-user/userId', 'DELETE');
      Server.delete(`admin/delete-user/${userId}`)
        .then(() => {
          dispatch({
            type: UsersStore.ActionTypes.DELETE_USER_SUCCESS
          });
          AlertUtil.updateContent(
            msg('alertMessages.userDeleted', 'The user has been successfully deleted!'),
            'success'
          );
          const state = getState();
          const { limit, skip } = state?.users.users_list;
          const newCriteria = queryStringToCriteria(window.location.search, [
            'email',
            'lastname',
            'firstname',
            'phone'
          ]);
          dispatch(
            UsersStore.actions.getUsersListAction(limit, skip, newCriteria.sort || 'created,-1', {
              filters: newCriteria.filters,
              search: newCriteria.search
            })
          );
        })
        .catch(error => {
          logger.err('Delete user action, route:/admin/delete-user/userId', 'DELETE');
          AlertUtil.updateContent(
            msg('reduxMessages.users.deleteUserError', 'Due to an error, the user could not be deleted!'),
            'error'
          );
          dispatch({
            type: UsersStore.ActionTypes.DELETE_USER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function editUserAction => Redux action for editing an user's information
      @accepts user : UserDTO object that contains the new information that will overwrite the existing one
      @returns Promise
  */
  editUserAction(user: UserDTO) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: UsersStore.ActionTypes.SAVE_USER
      });
      AlertUtil.simple(msg('reduxMessages.users.editUserPending', 'The user is being updated, please wait...'), 'info');
      logger.msg('Edit user action, route:/admin/update-user/userId', 'PUT');
      await Server.put(`admin/update-user/${user._id}`, user)
        .then((response: any) => {
          dispatch({
            type: UsersStore.ActionTypes.SAVE_USER_SUCCESS,
            payload: response.data as UserDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.users.editUserSuccess', 'The user was successfully updated!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Edit user action, route:/admin/update-user/userId', 'PUT');
          AlertUtil.updateContent(
            msg('reduxMessages.users.editUserError', 'Due to an error, the user could not be updated!'),
            'error'
          );
          dispatch({
            type: UsersStore.ActionTypes.SAVE_USER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getUserAction => Redux action for getting information about an user
      @accepts userId : string that represents the id of the user you want to get information about
      @returns Promise
  */
  getUserAction(userId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: UsersStore.ActionTypes.GET_USER
      });
      logger.msg('Get user action, route:/admin/user/userId', 'GET');
      Server.get(`admin/user/${userId}?type=DASHBOARD`)
        .then((response: any) => {
          dispatch({
            type: UsersStore.ActionTypes.GET_USER_SUCCESS,
            payload: response.data as UserDTO
          });
        })
        .catch(error => {
          logger.err('Get user action, route:/admin/user/userId', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.users.getUserError', 'Due to an error, the user data could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: UsersStore.ActionTypes.GET_USER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getUsersListAction => Redux action for getting all existing users
      (optional) limit, skip, sort, criteria : params used for pagination
      @returns Promise
  */
  getUsersListAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: UsersStore.ActionTypes.GET_USERS });
      try {
        if (!limit) {
          limit = 30;
        }
        let url = `admin/users?limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get users list action, route:/admin/users', 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: UsersStore.ActionTypes.GET_USERS_SUCCESS,
          payload: response.data as PaginationDTO<UserDTO>
        });
      } catch (error) {
        logger.err('Get users list action, route:/admin/users', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.users.getUsersError', 'Due to an error, the users list could not be loaded!'),
          'error',
          2000
        );
        dispatch({
          type: UsersStore.ActionTypes.GET_USERS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  /*
    @function redirectToEditUserAction => Redux action for redirecting super admin to edit user form
      @accepts userId : string that represents the id of the user to be edited
      @returns Promise
  */
  redirectToEditUserAction(userId: string) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(adminRoutes.USERS.subroutes.EDIT.path, { userId });
      dispatch(push(path));
    };
  }

  /*
    @function addPartnerAdminAction => Redux action for creating a new partner admin account
      @accepts user : UserDTO object that contains all information needed to create a partner admin
               partnerId : string that represents the id of the partner to whom you add a new partner admin
      @returns Promise
  */
  addPartnerAdminAction(user: UserDTO, partnerId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: UsersStore.ActionTypes.SAVE_USER
      });
      AlertUtil.simple(
        msg('reduxMessages.users.addPartnerAdminPending', 'The partner admin is being created, please wait...'),
        'info'
      );
      logger.msg('Add partner admin action, route:/partners/partnerId/admin', 'POST');
      await Server.post(`partners/${partnerId}/admin`, user)
        .then((response: any) => {
          dispatch({
            type: UsersStore.ActionTypes.SAVE_USER_SUCCESS,
            payload: response.data as UserDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.users.addPartnerAdminSuccess', 'The partner admin was successfully created!'),
            'success'
          );
          const path = generatePath(adminRoutes.PARTNERS.subroutes.EDIT.path, { partnerId });
          dispatch(push(path));
        })
        .catch(error => {
          logger.err('Add partner admin action, route:/partners/partnerId/admin', 'POST');
          AlertUtil.updateContent(
            msg('reduxMessages.users.addPartnerAdminError', 'Due to an error, the partner admin could not be created!'),
            'error'
          );
          dispatch({
            type: UsersStore.ActionTypes.SAVE_USER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function addPartnerAdminByIdAction => Redux action for making an existing user a partner admin
      @accepts userId : string that represents the id of the user to be made partner admin
               partnerId : string that represents the id of the partner to whom you add a new partner admin
      @returns Promise
  */
  addPartnerAdminByIdAction(userId: string, partnerId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: UsersStore.ActionTypes.SAVE_ADMIN
      });
      AlertUtil.simple(
        msg(
          'reduxMessages.users.givePartnerAdminPending',
          'Giving partner administrator rights to user, please wait...'
        ),
        'info'
      );
      logger.msg('Add partner admin by Id action, route:/partners/partnerId/adminById', 'POST');
      await Server.post(`partners/${partnerId}/adminById`, { userId })
        .then(async (response: any) => {
          const getAdminsResponse: any = await Server.get(`partners/${partnerId}/admins`);
          dispatch({
            type: UsersStore.ActionTypes.SAVE_ADMIN_SUCCESS,
            payload: getAdminsResponse.data as UserDTO
          });
          AlertUtil.updateContent(
            msg(
              'reduxMessages.users.givePartnerAdminSuccess',
              'The user was successfully given partner administrator rights!'
            ),
            'success'
          );
          const path = generatePath(adminRoutes.PARTNERS.subroutes.EDIT.path, { partnerId });
          dispatch(push(path));
        })
        .catch(error => {
          logger.err('Add partner admin by Id action, route:/partners/partnerId/adminById', 'POST');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.users.givePartnerAdminError',
              'Due to an error, the user was not given partner administrator rights!'
            ),
            'error'
          );
          dispatch({
            type: UsersStore.ActionTypes.SAVE_ADMIN_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getAdminsForPartnerAction => Redux action for getting all partner admins for a given partner
      @accepts partnerId : string that represents the id of the partner for whom to take all admins
      @returns Promise
  */
  getAdminsForPartnerAction(partnerId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: UsersStore.ActionTypes.GET_ADMINS
      });
      logger.msg('Get partner admins action, route:/partners/partnerId/admins', 'GET');
      Server.get(`partners/${partnerId}/admins`)
        .then((response: any) => {
          dispatch({
            type: UsersStore.ActionTypes.GET_ADMINS_SUCCESS,
            payload: response.data as Array<UserDTO>
          });
        })
        .catch(error => {
          logger.err('Get partner admins action, route:/partners/partnerId/admins', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.users.getAdminsError', 'Due to an error, the admins list could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: UsersStore.ActionTypes.GET_ADMINS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function redirectToAddPartnerAdminAction => Redux action for redirecting super admin to add new partner admin
      @accepts partnerId : string that represents the id of the partner for whom to add new partner admin
      @returns Promise
  */
  redirectToAddPartnerAdminAction(partnerId: string) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(adminRoutes.USERS.subroutes.ADD.path, { partnerId });
      dispatch(push(path));
    };
  }

  /*
    @function removePartnerAdminAction => Redux action for revoking admin rights for a partner admin
      @accepts partnerId : string that represents the id of the partner for whom the user is partner admin
               userId : string that represents the id of the partner admin whose rights will be removed
      @returns Promise
  */
  removePartnerAdminAction(partnerId: string, userId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: UsersStore.ActionTypes.REMOVE_ADMIN
      });
      AlertUtil.simple(
        // tslint:disable-next-line:quotemark
        msg('reduxMessages.users.revokeRightsPending', "The administrator's rights are being revoked, please wait..."),
        'info'
      );
      logger.msg('Remove partner admin action, route:/partners/partnerId/remove-admin?userId', 'GET');
      Server.get(`partners/${partnerId}/remove-admin?userId=${userId}`)
        .then(async (response: any) => {
          const getAdminsResponse: any = await Server.get(`partners/${partnerId}/admins`);
          dispatch({
            type: UsersStore.ActionTypes.REMOVE_ADMIN_SUCCESS,
            payload: getAdminsResponse.data
          });
          AlertUtil.updateContent(
            // tslint:disable-next-line:quotemark
            msg('reduxMessages.users.revokeRightsSuccess', "The administrator's rights were successfully revoked!"),
            'success'
          );
          const path = generatePath(adminRoutes.PARTNERS.subroutes.EDIT.path, { partnerId });
          dispatch(push(path));
        })
        .catch(error => {
          logger.err('Remove partner admin action, route:/partners/partnerId/remove-admin?userId', 'GET');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.users.revokeRightsError',
              // tslint:disable-next-line:quotemark
              "Due to an error, the administrator's rights could not be revoked!"
            ),
            'error'
          );
          dispatch({
            type: UsersStore.ActionTypes.REMOVE_ADMIN_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function addPlaceAdminAction => Redux action for adding a new place admin for a partner
      @accepts user : UserDTO object that contains all user information necessary for creating new place admin
               placeId : string that represents the id of the place to whom you add a new place admin
               forSA : boolean that decides where to redirect after creating new place admin
               (true -> super admin, false -> partner admin)
      @returns Promise
  */
  addPlaceAdminAction(user: UserDTO, placeId: string, forSA: boolean) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: UsersStore.ActionTypes.SAVE_USER
      });
      AlertUtil.simple(
        msg('reduxMessages.users.addPlaceAdminPending', 'The place admin is being created, please wait...'),
        'info'
      );
      logger.msg('Add place admin action, route:/places/placeId/admin', 'POST');
      await Server.post(`places/${placeId}/admin`, user)
        .then((response: any) => {
          dispatch({
            type: UsersStore.ActionTypes.SAVE_USER_SUCCESS,
            payload: response.data as UserDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.users.addPlaceAdminSuccess', 'The place admin was successfully created!'),
            'success'
          );
          if (forSA) {
            const path = generatePath(adminRoutes.PLACES.subroutes.EDIT.subroutes.ADMINS.path, { placeId });
            dispatch(push(path));
          } else {
            const path = generatePath(partnerRoutes.PLACE_ADMINS.default, { placeId });
            dispatch(push(path));
          }
        })
        .catch(error => {
          logger.err('Add place admin action, route:/places/placeId/admin', 'POST');
          AlertUtil.updateContent(
            msg('reduxMessages.users.addPlaceAdminError', 'Due to an error, the place admin could not be created!'),
            'error'
          );
          dispatch({
            type: UsersStore.ActionTypes.SAVE_USER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getAdminsForPlaceAction => Redux action for getting the list of all place admins for a given place
      @accepts placeId : string that represents the id of the place to get all place admins
      @returns Promise
  */
  getAdminsForPlaceAction(placeId: string, includePartner: boolean = false) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: UsersStore.ActionTypes.GET_PLACE_ADMINS
      });
      let url = `places/${placeId}/admins`;
      if (includePartner) {
        url += `?includePartner=true`;
      }
      logger.msg('Get place admins action, route:/places/placeId/admins', 'GET');
      Server.get(url)
        .then((response: any) => {
          dispatch({
            type: UsersStore.ActionTypes.GET_PLACE_ADMINS_SUCCESS,
            payload: response.data as Array<UserDTO>
          });
        })
        .catch(error => {
          logger.err('Get place admins action, route:/places/placeId/admins', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.users.getAdminsError', 'Due to an error, the admins list could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: UsersStore.ActionTypes.GET_PLACE_ADMINS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function removePlaceAdminAction => Redux action for revoking place admin rights of a place admin
      @accepts placeId : string that represents the id of the place that has the place admin
               userId : string that represents the place admin that will have his rights revoked
               forWhom : boolean that decides where to redirect after revoking rights was successful
      @returns Promise
  */
  removePlaceAdminAction(placeId: string, userId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: UsersStore.ActionTypes.REMOVE_PLACE_ADMIN
      });
      AlertUtil.simple(
        // tslint:disable-next-line:quotemark
        msg('reduxMessages.users.revokeRightsPending', "The administrator's rights are being revoked, please wait..."),
        'info'
      );
      logger.msg('Remove place admin action, route:/places/placeId/remove-admin?userId', 'GET');
      Server.get(`places/${placeId}/remove-admin?userId=${userId}`)
        .then(async (response: any) => {
          const getAdminsResponse: any = await Server.get(`places/${placeId}/admins`);
          dispatch({
            type: UsersStore.ActionTypes.REMOVE_PLACE_ADMIN_SUCCESS,
            payload: getAdminsResponse.data
          });
          AlertUtil.fireOnce(
            // tslint:disable-next-line:quotemark
            msg('reduxMessages.users.revokeRightsSuccess', "The administrator's rights were successfully revoked!"),
            'success'
          );
        })
        .catch(error => {
          logger.err('Remove place admin action, route:/places/placeId/remove-admin?userId', 'GET');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.users.revokeRightsError',
              // tslint:disable-next-line:quotemark
              "Due to an error, the administrator's rights could not be revoked!"
            ),
            'error'
          );
          dispatch({
            type: UsersStore.ActionTypes.REMOVE_PLACE_ADMIN_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function redirectToAddPlaceAdminAction => Redux action for redirecting to add place admin form
      @accepts placeId : string that represents the id of the place for whom to add new place admin
               forSAdmin : boolean that decides if it's a super admin that needs redirecting or not
               (if false, it means that the logged user is a partner/place admin and will be redirected accordingly)
      @returns null
  */
  redirectToAddPlaceAdminAction(placeId: string, forSAdmin: boolean) {
    if (forSAdmin) {
      return (dispatch: Dispatch<any>) => {
        const path = generatePath(adminRoutes.PLACES.subroutes.EDIT.subroutes.ADMINS.subroutes.ADD.path, { placeId });
        dispatch(push(path));
      };
    } else {
      return (dispatch: Dispatch<any>) => {
        const path = generatePath(partnerRoutes.PLACE_ADMINS.subroutes.ADD.path, { placeId });
        dispatch(push(path));
      };
    }
  }

  getTabletProfilesAction(
    placeId: string,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: UsersStore.ActionTypes.GET_PROFILES
      });
      try {
        if (!limit) {
          limit = 30;
        }
        let url = `places/${placeId}/profiles?limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        } else {
          url += `&sort=name,1`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get tablet profiles action, route:/places/placeId/profiles', 'GET');
        const response = (await Server.get(url)) as AxiosResponse;
        dispatch({
          type: UsersStore.ActionTypes.GET_PROFILES_SUCCESS,
          payload: response.data as PaginationDTO<ProfileDTO>
        });
      } catch (error) {
        logger.err('Get tablet profiles action, route:/places/placeId/profiles', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.users.getProfilesError', 'Due to an error, the profiles list could not be loaded!'),
          'error',
          2000
        );
        dispatch({
          type: UsersStore.ActionTypes.GET_PROFILES_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  addTabletProfileAction(name: string, placeId: string) {
    return (dispatch: Dispatch<any>, getState: () => AppStore.states) => {
      dispatch({
        type: UsersStore.ActionTypes.SAVE_PROFILE
      });
      AlertUtil.simple(
        msg('reduxMessages.users.addTabletProfilePending', 'The tablet profile is being created, please wait...'),
        'info',
        3000,
        true
      );
      logger.msg('Add tablet profile action, route:/places/placeId/profiles', 'POST');
      Server.post(`places/${placeId}/profiles`, { name })
        .then((response: AxiosResponse) => {
          dispatch({
            type: UsersStore.ActionTypes.SAVE_PROFILE_SUCCESS,
            payload: response.data as ProfileDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.users.addTabletProfileSuccess', 'The tablet profile was successfully created!'),
            'success'
          );
          const state = getState();
          const { limit, page_number } = state?.users.profile_list;
          const newCriteria = queryStringToCriteria(window.location.search, ['name']);
          if (!newCriteria.filters.interval) {
            newCriteria.filters = {
              ...newCriteria.filters,
              interval: getDateInterval(6)
            };
          }
          dispatch(
            UsersStore.actions.getTabletProfilesAction(placeId, limit * page_number, 0, newCriteria.sort, {
              filters: newCriteria.filters,
              search: newCriteria.search
            })
          );
        })
        .catch(error => {
          logger.err('Add tablet profile action, route:/partners/partnerId/admin', 'POST');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.users.addTabletProfileError',
              'Due to an error, the tablet profile could not be created!'
            ),
            'error'
          );
          dispatch({
            type: UsersStore.ActionTypes.SAVE_PROFILE_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  editTabletProfileAction(placeId: string, profileId: string, name: string) {
    return (dispatch: Dispatch<any>, getState: () => AppStore.states) => {
      dispatch({
        type: UsersStore.ActionTypes.SAVE_PROFILE
      });
      AlertUtil.simple(
        msg('reduxMessages.users.editTabletProfilePending', 'The tablet profile is being edited, please wait...'),
        'info',
        3000,
        true
      );
      logger.msg('Edit tablet profile action, route:/places/placeId/profiles/profileId', 'PUT');
      Server.put(`places/${placeId}/profiles/${profileId}`, { name })
        .then(() => {
          dispatch({
            type: UsersStore.ActionTypes.SAVE_PROFILE_SUCCESS
          });
          AlertUtil.fireOnce(
            msg('reduxMessages.users.editTabletProfileSuccess', 'The tablet profile was successfully edited!'),
            'success'
          );
          const state = getState();
          const { limit, page_number } = state?.users.profile_list;
          const newCriteria = queryStringToCriteria(window.location.search, ['name']);
          if (!newCriteria.filters.interval) {
            newCriteria.filters = {
              ...newCriteria.filters,
              interval: getDateInterval(6)
            };
          }
          dispatch(
            UsersStore.actions.getTabletProfilesAction(placeId, limit * page_number, 0, newCriteria.sort, {
              filters: newCriteria.filters,
              search: newCriteria.search
            })
          );
        })
        .catch(error => {
          logger.err('Edit tablet profile action, route:/places/placeId/profiles/profileId', 'PUT');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.users.editTabletProfileError',
              'Due to an error, the tablet profile could not be edited!'
            ),
            'error'
          );
          dispatch({
            type: UsersStore.ActionTypes.SAVE_PROFILE_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  deleteTabletProfileAction(placeId: string, profileId: string) {
    return (dispatch: Dispatch<any>, getState: () => AppStore.states) => {
      dispatch({
        type: UsersStore.ActionTypes.DELETE_PROFILE
      });
      AlertUtil.simple(
        msg('reduxMessages.users.deleteTabletProfilePending', 'The tablet profile is being deleted, please wait...'),
        'info',
        3000,
        true
      );
      logger.msg('Delete tablet profile action, route:/places/placeId/profiles/profileId', 'DELETE');
      Server.delete(`places/${placeId}/profiles/${profileId}`)
        .then(() => {
          dispatch({
            type: UsersStore.ActionTypes.DELETE_PROFILE_SUCCESS
          });
          AlertUtil.fireOnce(
            msg('reduxMessages.users.deleteTabletProfileSuccess', 'The tablet profile was successfully deleted!'),
            'success'
          );
          const state = getState();
          const { limit, page_number } = state?.users.profile_list;
          const newCriteria = queryStringToCriteria(window.location.search, ['name']);
          if (!newCriteria.filters.interval) {
            newCriteria.filters = {
              ...newCriteria.filters,
              interval: getDateInterval(6)
            };
          }
          dispatch(
            UsersStore.actions.getTabletProfilesAction(placeId, limit * page_number, 0, newCriteria.sort, {
              filters: newCriteria.filters,
              search: newCriteria.search
            })
          );
        })
        .catch(error => {
          logger.err('Delete tablet profile action, route:/places/placeId/profiles/profileId', 'DELETE');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.users.deleteTabletProfileError',
              'Due to an error, the atablet profile could not be deleted!'
            ),
            'error'
          );
          dispatch({
            type: UsersStore.ActionTypes.DELETE_PROFILE_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }
}

const usersActions = new UsersActions();
export default usersActions;
