import { FeedbackStore } from '.';
import { PaginationDTO } from '../types';

function feedbackReducer(
  state: FeedbackStore.IState = FeedbackStore.initialState,
  action: IAction<any>
): FeedbackStore.IState {
  switch (action.type) {
    case FeedbackStore.ActionTypes.GET_FEEDBACK_LIST: {
      return {
        ...state,
        feedback_list_pending: true,
        feedback_list_error: null
      };
    }
    case FeedbackStore.ActionTypes.GET_FEEDBACK_LIST_SUCCESS: {
      return {
        ...state,
        feedback_list_pending: false,
        feedback_list_error: null,
        feedback_list: action.payload
      };
    }
    case FeedbackStore.ActionTypes.GET_FEEDBACK_LIST_FAILED: {
      return {
        ...state,
        feedback_list_pending: false,
        feedback_list_error: action.payload,
        feedback_list: new PaginationDTO()
      };
    }
    case FeedbackStore.ActionTypes.ALLOW_FEEDBACK: {
      return {
        ...state,
        allow_feedback: null,
        allow_feedback_pending: true,
        allow_feedback_error: null
      };
    }
    case FeedbackStore.ActionTypes.ALLOW_FEEDBACK_SUCCESS: {
      return {
        ...state,
        allow_feedback: action.payload,
        allow_feedback_pending: false,
        allow_feedback_error: null
      };
    }
    case FeedbackStore.ActionTypes.ALLOW_FEEDBACK_FAILED: {
      return {
        ...state,
        allow_feedback: null,
        allow_feedback_pending: false,
        allow_feedback_error: null
      };
    }
    case FeedbackStore.ActionTypes.CLEAR_FEEDBACK_ERROR: {
      return {
        ...state,
        feedback_list_error: null
      };
    }
    default:
      return state;
  }
}

export default feedbackReducer;
