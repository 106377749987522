import React from 'react';

import { msg } from '../../../../../../../utils/index';
import { TextFieldComponent } from '../../../../../../shared/index';

export function GivePointsAction({ listItem, onValueChange, index, errors }: any) {
  return (
    <div>
      <div className="col-sm-4" style={{ marginRight: 10 }}>
        <TextFieldComponent
          label={msg('autopilot.actionValue', 'Value')}
          id="actionValue"
          value={listItem.value}
          formatError={errors[listItem.value]}
          onChange={(e: any) => onValueChange(e.target.value, index, 'value')}
          inType="text"
          minLength={1}
          maxLength={3}
          required={true}
        />
      </div>
    </div>
  );
}
