import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppStore } from '../../../appRedux';
import { CardComponent, ButtonComponent, InfiniteScrollListComponent } from '../../shared';
import { msg, getFormatDate } from '../../../utils';
import { PaginationDTO, PartnerEmailDTO } from '../../../appRedux/types';
import { PartnerEmailsStore } from '../../../appRedux/partner-emails';
import SentToModalComponent from './components/SentToModalComponent';

interface IProps {
  selected_partner: string;
  partner_emails_list: PaginationDTO<PartnerEmailDTO>;
  partner_emails_list_pending: boolean;
  partner_emails_list_error: string | null;
  getPartnerEmailsAction(
    isSAdmin: boolean,
    partnerId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  redirectToPartnerEmail(isSAdmin: boolean, partnerEmailId?: string): any;
  clearPartnerEmailsError(): any;
}

const PartnerEmailsListContainer = (props: IProps & RouteComponentProps) => {
  const [isSAdmin, setIsSAdmin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentPartnerEmail, setPartnerEmail] = useState(new PartnerEmailDTO());
  const { clearPartnerEmailsError } = props;

  useEffect(() => {
    if (props.match?.path === '/admin/partner-emails') {
      setIsSAdmin(true);
    }
    return () => {
      clearPartnerEmailsError();
    };
    // tslint:disable-next-line: align
  }, []);

  const parseList = (list: PaginationDTO<PartnerEmailDTO>) => {
    return {
      ...list,
      results: list?.results
        ? list.results.map((item: any, index: any) => {
            return {
              ...item,
              sent: item.sentTo?.length,
              created: getFormatDate(item.created, 'hour')
            };
          })
        : []
    };
  };

  const viewAction = (partnerEmail: any) => {
    const emaildId = partnerEmail.currentTarget.getAttribute('id');
    props.redirectToPartnerEmail(isSAdmin, emaildId);
  };

  const viewUsersListAction = (partnerEmail: any) => {
    setPartnerEmail(partnerEmail);
    toggleModal();
  };

  const getPartnerEmails = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    const SAdmin = props.match?.path === '/admin/partner-emails';
    props.getPartnerEmailsAction(SAdmin, props.selected_partner, limit, skip, sort, criteria);
  };

  const createHandler = () => {
    props.redirectToPartnerEmail(isSAdmin);
  };

  const getActions = () => {
    return [
      {
        label: msg('general.read', 'Read'),
        btn: 'btn-info',
        icon: 'info',
        onClick: viewAction
      },
      {
        label: msg('partnerEmail.sentToList', 'Users list'),
        btn: 'btn-success',
        icon: 'group',
        returnFields: [''],
        onClick: viewUsersListAction
      }
    ];
  };

  const getFields = () => {
    const fields: any = {
      subject: msg('partnerEmail.subject', 'Subject'),
      created: msg('partnerEmail.created', 'Created'),
      sent: msg('partnerEmail.sent', 'Sent'),
      readBy: msg('partnerEmail.readBy', 'Read')
    };
    if (isSAdmin) {
      fields.partnerName = msg('partnerEmail.partnerName', 'Partner');
    }
    return fields;
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <CardComponent
      title={msg('partnerEmail.cardTitle', 'Partner Emails')}
      error={!!props.partner_emails_list_error}
      headerIcon="email"
      needsTitle={true}
      buttons={[
        {
          label: msg('partnerEmail.createEmail', 'Send a new email'),
          icon: 'email',
          onClick: createHandler
        }
      ]}
    >
      {showModal && (
        <SentToModalComponent toggleModal={toggleModal} sentTo={currentPartnerEmail?.sentTo || []} pending={false} />
      )}
      <InfiniteScrollListComponent
        fields={getFields()}
        actions={getActions()}
        list={parseList(props.partner_emails_list)}
        pending={props.partner_emails_list_pending}
        error={props.partner_emails_list_error}
        get={getPartnerEmails}
        sort={{
          fields: ['created', 'subject'],
          default: 'created,-1'
        }}
        search={['subject']}
        hasCardButton={true}
      />
      <div className="row" style={{ margin: 10 }}>
        <div className="col-md-3" />
        <div className="col-md-6" style={{ textAlign: 'center' }}>
          <div className="form-group form-button">
            <ButtonComponent
              label={msg('partnerEmail.createEmail', 'Send a new email')}
              icon="email"
              action={createHandler}
            />
          </div>
        </div>
        <div className="col-md-3" />
      </div>
    </CardComponent>
  );
};

function mapStateToProps(state: AppStore.states) {
  return {
    selected_partner: state.partners.selected_partner,
    partner_emails_list: state.partnerEmails.partner_emails_list,
    partner_emails_list_pending: state.partnerEmails.partner_emails_list_pending,
    partner_emails_list_error: state.partnerEmails.partner_emails_list_error
  };
}

const dispatchToProps = {
  getPartnerEmailsAction: PartnerEmailsStore.actions.getPartnerEmailsAction,
  redirectToPartnerEmail: PartnerEmailsStore.actions.redirectToPartnerEmail,
  clearPartnerEmailsError: PartnerEmailsStore.actions.clearPartnerEmailsError
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(PartnerEmailsListContainer));
