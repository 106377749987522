import { StatisticsStore } from './';
import { PaginationDTO, StatisticsSuperAdminDTO } from '../types';

/*
  statisticsReducer = reducer for all actionTypes for Statistics
*/
function statisticsReducer(
  state: StatisticsStore.IState = StatisticsStore.initialState,
  action: IAction<any>
): StatisticsStore.IState {
  switch (action.type) {
    case StatisticsStore.ActionTypes.GET_STATISTICS: {
      return {
        ...state,
        statistics_list_pending: true,
        statistics_list_error: null
      };
    }
    case StatisticsStore.ActionTypes.GET_STATISTICS_SUCCESS: {
      return {
        ...state,
        statistics_list_pending: false,
        statistics_list_error: null,
        statistics_list: action.payload
      };
    }
    case StatisticsStore.ActionTypes.GET_STATISTICS_FAILED: {
      return {
        ...state,
        statistics_list_pending: false,
        statistics_list: [],
        statistics_list_error: action.payload
      };
    }
    case StatisticsStore.ActionTypes.GET_PLACE_STATISTICS: {
      return {
        ...state,
        place_statistics_pending: true,
        place_statistics_error: null
      };
    }
    case StatisticsStore.ActionTypes.GET_PLACE_STATISTICS_SUCCESS: {
      return {
        ...state,
        place_statistics_pending: false,
        place_statistics_error: null,
        place_statistics: action.payload
      };
    }
    case StatisticsStore.ActionTypes.GET_PLACE_STATISTICS_FAILED: {
      return {
        ...state,
        place_statistics_pending: false,
        place_statistics: null,
        place_statistics_error: action.payload
      };
    }
    case StatisticsStore.ActionTypes.GET_PERSONALIZED_STATISTICS: {
      return {
        ...state,
        personalized_statistics_list_pending: true,
        personalized_statistics_list_error: null
      };
    }
    case StatisticsStore.ActionTypes.GET_PERSONALIZED_STATISTICS_SUCCESS: {
      return {
        ...state,
        personalized_statistics_list_pending: false,
        personalized_statistics_list_error: null,
        personalized_statistics_list: action.payload
      };
    }
    case StatisticsStore.ActionTypes.GET_PERSONALIZED_STATISTICS_FAILED: {
      return {
        ...state,
        personalized_statistics_list_pending: false,
        personalized_statistics_list: [],
        personalized_statistics_list_error: action.payload
      };
    }
    case StatisticsStore.ActionTypes.GET_ALL_AGG_STATISTICS: {
      return {
        ...state,
        agg_statistics_pending: true,
        agg_statistics_error: null
      };
    }
    case StatisticsStore.ActionTypes.GET_ALL_AGG_STATISTICS_SUCCESS: {
      return {
        ...state,
        agg_statistics_pending: false,
        agg_statistics_error: null,
        agg_statistics: action.payload
      };
    }
    case StatisticsStore.ActionTypes.GET_ALL_AGG_STATISTICS_FAILED: {
      return {
        ...state,
        agg_statistics_pending: false,
        agg_statistics: [],
        agg_statistics_error: action.payload
      };
    }
    case StatisticsStore.ActionTypes.GET_STATISTICS_PER_PLACE_SUPERADMIN: {
      return {
        ...state,
        statistics_super_admin_pending: true,
        statistics_super_admin_error: null
      };
    }
    case StatisticsStore.ActionTypes.GET_STATISTICS_PER_PLACE_SUPERADMIN_SUCCESS: {
      return {
        ...state,
        statistics_super_admin: action.payload,
        statistics_super_admin_pending: false
      };
    }
    case StatisticsStore.ActionTypes.GET_STATISTICS_PER_PLACE_SUPERADMIN_FAILED: {
      return {
        ...state,
        statistics_super_admin: new PaginationDTO(),
        statistics_super_admin_pending: true,
        statistics_super_admin_error: action.payload
      };
    }
    case StatisticsStore.ActionTypes.GET_ALL_STATISTICS_SUPERADMIN: {
      return {
        ...state,
        statistics_all_super_admin_pending: true,
        statistics_super_admin_error: null
      };
    }
    case StatisticsStore.ActionTypes.GET_ALL_STATISTICS_SUPERADMIN_SUCCESS: {
      return {
        ...state,
        statistics_all_super_admin: action.payload,
        statistics_all_super_admin_pending: false
      };
    }
    case StatisticsStore.ActionTypes.GET_ALL_STATISTICS_SUPERADMIN_FAILED: {
      return {
        ...state,
        statistics_all_super_admin: new StatisticsSuperAdminDTO(),
        statistics_all_super_admin_pending: false,
        statistics_super_admin_error: action.payload
      };
    }
    default:
      return state;
  }
}

export default statisticsReducer;
