import * as React from 'react';
import { colors } from '../../../../assets/styles';
import { msg } from '../../../../utils';

interface IState {
  width: number;
}
interface IProps {
  stepNo: number;
  actualStep: number;
  changeStepAction: (stepNo: number) => any;
}

export default class StepComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { width: 0 };
    window.addEventListener('resize', this.update);
  }

  componentDidMount() {
    this.update();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update);
  }

  update = () => {
    this.setState({ width: window.innerWidth });
  };

  changeStepAction = (stepNo: number) => () => {
    this.props.changeStepAction(stepNo);
  };

  renderMargin = () => {
    if (this.state.width < 768) {
      return;
    }
    return (
      <div style={styles.marginContainer}>
        <div style={styles.stepText} />
        <div style={styles.stepLineMargin} />
        <div style={styles.stepText} />
      </div>
    );
  };

  renderStep = (step: string, no: number, length: number) => {
    const verifyStep = this.props.stepNo ? this.props.stepNo : 1;
    const selectedStep = verifyStep === no;
    let stepText: any = styles.stepText;
    let stepLine: any = styles.stepLine;
    let stepContainer: any = styles.stepContainer;
    if (no <= this.props.actualStep) {
      stepText = { ...stepText, ...styles.progressStepText };
      stepLine = { ...stepLine, ...styles.progressStepLine };
    }
    if (selectedStep) {
      stepText = { ...stepText, ...styles.selectedText };
      stepLine = { ...stepLine, ...styles.selectedLine };
    }
    if (no < length) {
      stepContainer = { ...stepContainer, marginRight: 10 };
    }
    return (
      <div key={no} style={stepContainer} onClick={this.changeStepAction(no)}>
        <div style={stepText}>
          {msg('onboard.step', 'STEP')}
          {no}
        </div>
        <div style={stepLine} />
        <div style={stepText}>{step}</div>
      </div>
    );
  };

  render() {
    const steps = [
      msg('onboard.step1', 'User'),
      msg('onboard.step2', 'Place'),
      msg('onboard.step3', 'Program Settings')
    ];
    return (
      <div style={styles.container}>
        {this.renderMargin()}
        {steps.map((step: string, index: number) => this.renderStep(step, index + 1, steps.length))}
        {this.renderMargin()}
      </div>
    );
  }
}

const styles: any = {
  container: { display: 'flex', flex: 1, height: 150 },
  stepContainer: { display: 'flex', flexDirection: 'column', flex: 1, cursor: 'pointer' },
  marginContainer: { display: 'flex', flexDirection: 'column', flex: 1 },
  stepText: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white,
    fontSize: 17,
    textAlign: 'center'
  },
  stepLine: { display: 'flex', flex: 0.1, backgroundColor: colors.white },
  stepLineMargin: { display: 'flex', flex: 0.1, backgroundColor: 'transparent' },
  progressStepText: { color: colors.lightGreen },
  progressStepLine: { backgroundColor: colors.lightGreen },
  selectedText: {
    color: colors.onboardingBlue
  },
  selectedLine: {
    backgroundColor: colors.onboardingBlue
  }
};
