import React from 'react';

import { msg, tlist } from '../../../../../../../utils';
import { SimpleSelectComponent, TextFieldComponent } from '../../../../../../shared';
import { conditionsForTypeZero } from '../../../../../../../appRedux/autopilot/AutopilotSelections';

export function TimeElapsedCondition({ listItem, onValueChange, index, onClick, errors }: any) {
  return (
    <div>
      <div className="col-sm-4">
        <SimpleSelectComponent
          label={msg('autopilot.triggerCondition', 'Condition')}
          name="triggerCondition"
          options={tlist(conditionsForTypeZero)}
          id="triggerCondition"
          value={listItem.condition}
          onChange={(e: any) => onValueChange(e.target.value, index, 'condition')}
          required={true}
          needsAllLabel={false}
          arrayOptions={false}
          formatError={errors.condition}
        />
      </div>
      <div className="col-sm-2">
        <TextFieldComponent
          label={msg('autopilot.triggerValue', 'Value')}
          id="triggerValue"
          value={listItem.value}
          onChange={(e: any) => onValueChange(e.target.value, index, 'value')}
          inType="text"
          minLength={1}
          maxLength={3}
          required={true}
          formatError={errors[listItem.type]}
        />
      </div>
      <div className="col-sm-2" style={{ marginRight: 10 }}>
        <SimpleSelectComponent
          label={msg('autopilot.triggerPeriod', 'Period type')}
          name="triggerPeriod"
          options={[
            msg('periodTypes.minutes', 'minutes'),
            msg('periodTypes.hours', 'hours'),
            msg('periodTypes.days', 'days'),
            msg('periodTypes.weeks', 'weeks'),
            msg('periodTypes.months', 'months')
          ]}
          id="triggerPeriod"
          value={listItem.period}
          onChange={(e: any) => onValueChange(e.target.value, index, 'period')}
          required={true}
          needsAllLabel={false}
          arrayOptions={true}
          formatError={errors.period}
        />
      </div>
    </div>
  );
}
