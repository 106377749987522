import * as React from 'react';
import { Bounce } from 'react-activity';
import { CardComponent, ExportModalComponent, InfiniteScrollListComponent } from '../../../shared';
import { msg, getFormatDate, AlertUtil } from '../../../../utils';
import { PaginationDTO, ReviewConfigDTO, PlaceDTO } from '../../../../appRedux/types';
import { colors } from '../../../../assets/styles';

interface IProps {
  match: any;
  config: {
    place: PlaceDTO | null;
    review_config: ReviewConfigDTO | null;
    review_configs: PaginationDTO<any>;
    review_configs_error: string | null;
    review_configs_pending: boolean;
    export_review_config_pending: boolean;
    export_review_config_error: string | null;
    getReviewConfigsListAction: (
      placeId: string,
      limit?: Number,
      skip?: Number,
      sort?: string,
      criteria?: { [key: string]: string }
    ) => any;
    redirectToReviewConfig(placeId: string, configId?: string): any;
    redirectToReviews(placeId: string, configId: string): any;
    changeReviewStatus(placeId: string, allowReviews: boolean): any;
    deleteReviewConfigAction(placeId: string, configId: string): any;
    exportReviewsInExcel(placeId: string, configId: string, startDate: number, endDate: number): any;
    editConfigStatusAction(placeId: string, configId: string, reviewConfig: any): any;
    clearPlaceReviewsError(): any;
  };
}
interface IState {
  showExportModal: boolean;
  exportedConfigId: string | null;
}

export default class ReviewConfigsListComponent extends React.Component<IProps, IState> {
  state = {
    showExportModal: false,
    exportedConfigId: null
  };

  componentWillUnmount() {
    this.props.config.clearPlaceReviewsError();
  }

  createReviewConfigAction = () => {
    const placeId = this.props.match.params.placeId;
    this.props.config.redirectToReviewConfig(placeId);
  };

  handleToggleChange = (event: any) => {
    const placeId = this.props.match.params.placeId;
    const allowReviews = !!event.target.checked;
    this.props.config.changeReviewStatus(placeId, allowReviews);
  };

  parseReviews = (list: PaginationDTO<ReviewConfigDTO>): any => {
    const results: any[] = [];
    if (list.results) {
      list.results.forEach(item => {
        results.push({
          ...item,
          created: getFormatDate(item.created),
          questions: item.questions?.length || 0,
          answers: item.answers || 0
        });
      });
    }
    return { ...list, results };
  };

  getReviewCofings = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    const placeId = this.props.match?.params?.placeId;
    return this.props.config.getReviewConfigsListAction(placeId, limit, skip, sort, criteria);
  };

  handleChangeStatus = (itemId: any) => (event: any) => {
    const placeId = this.props.match?.params.placeId;
    this.props.config.editConfigStatusAction(placeId, itemId, {
      _id: itemId,
      isActive: event.target.checked
    });
  };

  deleteAction = async (event: any) => {
    const configId = event.currentTarget.getAttribute('id');
    const placeId = this.props.match?.params?.placeId;
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.reviewConfigDeleteConfirmation', 'You are about to delete a configuration!')
    );
    if (hasConfirmation) {
      this.props.config.deleteReviewConfigAction(placeId, configId);
    }
  };

  toggleExportModal = () => {
    const show = this.state.showExportModal;
    this.setState({ showExportModal: !show });
  };

  exportInExcel = (startDate: number, endDate: number) => {
    const placeId = this.props.match?.params?.placeId;
    const configId = this.state.exportedConfigId || '';
    this.props.config.exportReviewsInExcel(placeId, configId, startDate, endDate);
  };

  getActions = () => {
    return [
      {
        label: msg('reviews.seeAdditionalInfo', 'See additional information'),
        btn: 'btn-info',
        icon: 'info',
        onClick: (event: any) => {
          const configId = event.currentTarget.getAttribute('id');
          const placeId = this.props.match?.params?.placeId;
          this.props.config.redirectToReviews(placeId, configId);
        }
      },
      {
        label: msg('exportExcel.export', 'Export informations'),
        btn: 'btn-primary',
        icon: 'get_app',
        isShown: (item: ReviewConfigDTO) => !!this.props.config.place?.featureFlags.exportInExcel && item.answers > 0,
        onClick: (event: any) => {
          const configId = event.currentTarget.getAttribute('id');
          this.setState({ exportedConfigId: configId });
          this.toggleExportModal();
        }
      },
      {
        label: msg('general.edit', 'Edit'),
        btn: 'btn-success',
        icon: 'edit',
        onClick: (event: any) => {
          const configId = event.currentTarget.getAttribute('id');
          const placeId = this.props.match?.params?.placeId;
          this.props.config.redirectToReviewConfig(placeId, configId);
        }
      },
      {
        label: msg('general.delete', 'Delete'),
        btn: 'btn-danger',
        icon: 'delete',
        onClick: this.deleteAction
      }
    ];
  };

  render() {
    const reviews = this.parseReviews(this.props.config.review_configs);
    const allowReviews = !!this.props.config.place?.allowReviews;
    const exportInExcel = !!this.props.config.place?.featureFlags?.exportInExcel;
    const exportModalConfig = {
      title: msg('exportExcel.exportExcelReviews', 'Export review informations'),
      pending: this.props.config.export_review_config_pending,
      error: this.props.config.export_review_config_error,
      toggleModal: this.toggleExportModal,
      exportInExcel: this.exportInExcel
    };
    return (
      ((this.props.config.review_configs || !this.props.config.review_configs_pending) && (
        <CardComponent
          title={msg('reviews.reviewConfigs', 'Review Configurations')}
          error={!!this.props.config.review_configs_error}
          headerIcon="poll"
          needsTitle={true}
          toggle={{
            label: msg('reviews.enableReviews', 'Enable reviews'),
            onChange: this.handleToggleChange,
            checked: allowReviews
          }}
          buttons={[
            {
              label: msg('reviews.createNewConfiguration', 'Create new configuration'),
              icon: 'description',
              onClick: this.createReviewConfigAction
            }
          ]}
        >
          {exportInExcel && this.state.showExportModal && <ExportModalComponent config={exportModalConfig} />}
          <div>
            <InfiniteScrollListComponent
              list={reviews}
              fields={{
                created: msg('reviews.created', 'Created'),
                name: msg('reviews.name', 'Name'),
                questions: msg('reviews.questions', 'Questions'),
                answers: msg('reviews.answers', 'Answers')
              }}
              sort={{
                fields: ['created', 'name', 'answers'],
                default: 'created,-1'
              }}
              pending={this.props.config.review_configs_pending}
              error={this.props.config.review_configs_error}
              search={['name']}
              get={this.getReviewCofings}
              actions={this.getActions()}
              toggles={[
                { name: msg('place.isActive', 'Active'), onChange: this.handleChangeStatus, field: 'isActive' }
              ]}
            />
          </div>
        </CardComponent>
      )) || (
        <div className="w-screen h-screen flex items-center justify-center">
          <Bounce color={colors.lightGreen} />
        </div>
      )
    );
  }
}
