import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { AppStore, PartnersStore, MembersStore, reduxContainer, PlacesStore, TagsStore } from '../../../appRedux';
import { MembersForAPlaceComponent } from './components';
import { partnerRoutes } from '../../layouts';
import { PaginationDTO, TagDTO } from '../../../appRedux/types';

interface IProps extends PartnersStore.IState, MembersStore.IState, PlacesStore.IState {
  tags_list: PaginationDTO<TagDTO>;
  getTagsAction(forSAdmin: boolean, placeId?: string): any;
}
interface IState {}

class PartnerMembersContainer extends React.Component<IProps & MembersStore.IActions, IState> {
  render() {
    const membersPlaceListProps = {
      getMembersForPlaceAction: this.props.getMembersOnePlaceListAction,
      members_list: this.props.members_list,
      members_list_pending: this.props.members_list_pending,
      members_list_error: this.props.members_list_error,
      getMemberAction: this.props.getMemberFromPlaceAction,
      member: this.props.member,
      member_pending: this.props.member_pending,
      redirectToSeeActivity: this.props.redirectToSeeActivityOnPlaceAction,
      changeMemberStatus: this.props.changeMemberStatus,
      clearPlaceMembersError: this.props.clearPlaceMembersError,
      exportInExcel: !!this.props.place?.featureFlags?.exportInExcel,
      export_members_pending: this.props.export_members_pending,
      export_members_error: this.props.export_members_error,
      exportMembersInExcel: this.props.exportMembersInExcel,
      tags_list: this.props.tags_list,
      getTagsAction: this.props.getTagsAction
    };

    const partnerId = this.props.selected_partner;
    let enableRoute = true;
    if (this.props.place && !this.props.place_pending && !this.props.place?.featureFlags?.becomeMember) {
      enableRoute = false;
    }

    return (
      <Switch>
        {enableRoute && (
          <Route
            exact={true}
            path={partnerRoutes.MEMBERS.default}
            render={({ match }) => (
              <MembersForAPlaceComponent match={match} config={membersPlaceListProps} partnerId={partnerId} />
            )}
          />
        )}
        <Redirect from="*" to={`/partner/dashboard/${this.props.selected_place}`} />
      </Switch>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    members_list: state.members.members_list,
    members_list_pending: state.members.members_list_pending,
    members_list_error: state.members.members_list_error,
    member: state.members.member,
    member_pending: state.members.member_pending,
    place: state.places.place,
    place_pending: state.places.place_pending,
    selected_partner: state.partners.selected_partner,
    selected_place: state.places.selected_place,
    export_members_pending: state.members.export_members_pending,
    export_members_error: state.members.export_members_error,
    tags_list: state.tags.tags_list
  };
}

const dispatchToProps = {
  getMembersOnePlaceListAction: MembersStore.actions.getMembersOnePlaceListAction,
  getMemberFromPlaceAction: MembersStore.actions.getMemberFromPlaceAction,
  redirectToSeeActivityOnPlaceAction: MembersStore.actions.redirectToSeeActivityOnPlaceAction,
  changeMemberStatus: MembersStore.actions.changeMemberStatus,
  exportMembersInExcel: MembersStore.actions.exportMembersInExcel,
  getTagsAction: TagsStore.actions.getTagsAction,
  clearPlaceMembersError: MembersStore.actions.clearPlaceMembersError
};

export default reduxContainer(PartnerMembersContainer, mapStateToProps, dispatchToProps);
