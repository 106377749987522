import { Dispatch } from 'redux';

import { PackagesStore } from './';
import { Server, AlertUtil, msg, logger } from '../../utils';
import { fetchPackagesList, fetchPackage, editPackage, createPackage, removePackage } from './api';
import { IPackage } from '../types';

export interface IPackagesActions {
  getPackagesListAction(options?: any): any;
  getPackageAction(packageId: string): any;
  createPackageAction(newPackage: IPackage): any;
  editPackageAction(packageId: string, newPackage: IPackage): any;
  removePackageAction(packageId: string): any;
}

class PackagesActions implements IPackagesActions {
  constructor() {
    this.getPackagesListAction = this.getPackagesListAction.bind(this);
    this.getPackageAction = this.getPackageAction.bind(this);
    this.createPackageAction = this.createPackageAction.bind(this);
    this.editPackageAction = this.editPackageAction.bind(this);
    this.removePackageAction = this.removePackageAction.bind(this);
  }

  getPackagesListAction(options?: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PackagesStore.ActionTypes.GET_PACKAGES });
      fetchPackagesList(options)
        .then((response) => {
          logger.msg('Get packages list action, route:/packages', 'GET');
          dispatch({
            type: PackagesStore.ActionTypes.GET_PACKAGES_SUCCESS,
            payload: response
          });
        })
        .catch((error) => {
          logger.err('Get packages list action, route:/packages', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.packages.getPackagesError', 'Due to an error, the packages list could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: PackagesStore.ActionTypes.GET_PACKAGES_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getPackageAction(packageId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PackagesStore.ActionTypes.GET_PACKAGE });
      fetchPackage(packageId)
        .then(response => {
          dispatch({
            type: PackagesStore.ActionTypes.GET_PACKAGE_SUCCESS,
            payload: response
          });
        })
        .catch((error) => {
          AlertUtil.simple(
            msg('reduxMessages.packages.getPackageError', 'Due to an error, the package could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: PackagesStore.ActionTypes.GET_PACKAGE_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  createPackageAction(newPackage: Omit<IPackage, '_id'>) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PackagesStore.ActionTypes.CREATE_PACKAGE });
      AlertUtil.simple(
        msg('reduxMessages.packages.createPackagePending', 'The package is being created, please wait...'),
        'info'
      );
      createPackage(newPackage)
        .then(response => {
          dispatch({
            type: PackagesStore.ActionTypes.CREATE_PACKAGE_SUCCESS,
            payload: response
          });
          AlertUtil.simple(
            msg('reduxMessages.packages.createPackageSuccess', 'The package was successfully created!'),
            'success'
          );
        })
        .catch((error) => {
          dispatch({
            type: PackagesStore.ActionTypes.CREATE_PACKAGE_FAILED,
            payload: Server.errorParse(error)
          });
          AlertUtil.simple(
            msg('reduxMessages.packages.createPackageError', 'Due to an error, the package could not be created!'),
            'error',
            2000
          );
        });
    };
  }

  editPackageAction(packageId: string, newPackage: IPackage) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PackagesStore.ActionTypes.EDIT_PACKAGE });
      AlertUtil.simple(
        msg('reduxMessages.packages.editPackagePending', 'The package is being edited, please wait...'),
        'info'
      );
      editPackage(packageId, newPackage)
        .then(response => {
          dispatch({
            type: PackagesStore.ActionTypes.EDIT_PACKAGE_SUCCESS,
            payload: response
          });
          AlertUtil.simple(
            msg('reduxMessages.packages.editPackageSuccess', 'The package was successfully edited!'),
            'success'
          );
        })
        .catch((error) => {
          dispatch({
            type: PackagesStore.ActionTypes.EDIT_PACKAGE_FAILED,
            payload: Server.errorParse(error)
          });
          AlertUtil.simple(
            msg('reduxMessages.packages.editPackageError', 'Due to an error, the package could not be edited!'),
            'error',
            2000
          );
        });
    };
  }

  removePackageAction(packageId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PackagesStore.ActionTypes.REMOVE_PACKAGE });
      AlertUtil.simple(
        msg('reduxMessages.packages.removePackagePending', 'The package is being removed, please wait...'),
        'info'
      );
      removePackage(packageId)
        .then(async response => {
          await this.getPackagesListAction()(dispatch);
          dispatch({
            type: PackagesStore.ActionTypes.REMOVE_PACKAGE_SUCCESS,
            payload: response
          });
          AlertUtil.simple(
            msg('reduxMessages.packages.removePackageSuccess', 'The package was successfully removed!'),
            'success'
          );
        })
        .catch((error) => {
          dispatch({
            type: PackagesStore.ActionTypes.REMOVE_PACKAGE_FAILED,
            payload: Server.errorParse(error)
          });
          AlertUtil.simple(
            msg('reduxMessages.packages.removePackageError', 'Due to an error, the package could not be removed!'),
            'error',
            2000
          );
        });
    };
  }
}

const packagesActions = new PackagesActions();
export default packagesActions;
