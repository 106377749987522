import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AppStore, TransactionsStore } from '../../../appRedux';
import {
    PaginationDTO, PaymentExternalStatuses, PaymentInternalStatuses, PlaceDTO, TransactionDTO,
    TransactionStatusTypes, UserDTO
} from '../../../appRedux/types';
import { colors } from '../../../assets/styles';
import { EMAIL_VALIDATION_REGEX, getFormatDate, msg, parseAmount } from '../../../utils';
import { CardComponent, ExportModalComponent, InfiniteScrollListComponent } from '../../shared';
import TransactionDetailsModal from './components/TransactionDetailsModal';

interface IProps {
  isSAdmin: boolean;
  forUser?: boolean;
  userId?: string;
  user: UserDTO | null;
  selected_place: string | null;
  place?: PlaceDTO | null;
  transaction: TransactionDTO | null;
  transaction_pending: boolean;
  transactions_list: PaginationDTO<TransactionDTO>;
  transactions_list_pending: boolean;
  transactions_list_error: string | null;
  transactions_list_for_user: PaginationDTO<TransactionDTO>;
  transactions_list_for_user_pending: boolean;
  transactions_list_for_user_error: string | null;
  export_transactions_pending: boolean;
  export_transactions_error: string | null;
  getTransactionsAction(
    isSAdmin: boolean,
    placeId?: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  getTransactionAction(isSAdmin: boolean, transactionId: string, placeId?: string): any;
  rollbackTransactionAction(isSAdmin: boolean, transactionId: string, placeId?: string, pinCode?: string): any;
  getTransactionsForUser(
    userId: string,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: { [key: string]: string | object }
  ): any;
  sendTransactionsReport(placeId: string, startDate: number, endDate: number, email: string): any;
  clearUserTransactions(): any;
  clearTransactionModalData(): any;
  clearTransactionsError(): any;
}

interface IListFields {
  created: string;
  uniqueNo: string;
  user: string;
  requestedAmount: string;
  amount: string;
  tipsAmount?: string;
  hasReceipt: string;
  displayedStatus: string;
}

const TransactionsListContainer = (props: IProps & RouteComponentProps) => {
  const [showModal, setShowModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const listRef = useRef<any>();

  useEffect(() => {
    return () => {
      props.clearTransactionsError();
      if (props.forUser) {
        props.clearUserTransactions();
      }
    };
    // tslint:disable-next-line: align
  }, []);

  const getData = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    if (props.forUser) {
      props.getTransactionsForUser(props.userId || '', limit, skip, sort, criteria);
    } else {
      const placeId = props.selected_place || '';
      props.getTransactionsAction(props.isSAdmin, placeId, limit, skip, sort, criteria);
    }
  };

  const getStatusBadge = (status: string) => {
    switch (status) {
      case TransactionStatusTypes.SIGNED:
      case PaymentInternalStatuses.WAITING_FOR_USER:
      case PaymentExternalStatuses.CREATED:
      case TransactionStatusTypes.PENDING:
      case PaymentInternalStatuses.PENDING:
        return colors.blue;
      case TransactionStatusTypes.APPROVED:
      case PaymentInternalStatuses.DONE_BY_USER:
      case PaymentExternalStatuses.DEPOSITED:
        return colors.green;
      case TransactionStatusTypes.FAILED:
      case TransactionStatusTypes.TIMEOUT:
      case PaymentInternalStatuses.FAILED:
      case PaymentInternalStatuses.CLOSED_BY_USER:
      case PaymentInternalStatuses.FAILED_BY_USER:
      case PaymentExternalStatuses.DECLINED:
        return colors.red;
      case TransactionStatusTypes.AUTO_TIMEOUT:
        return colors.darkRed;
      default:
        return colors.gray;
    }
  };

  const getTransactionStatus = (status: string) => {
    switch (status) {
      case TransactionStatusTypes.APPROVED:
        return msg('transactions.approved', 'Approved');
      case TransactionStatusTypes.FAILED:
        return msg('transactions.failed', 'Failed');
      case TransactionStatusTypes.SIGNED:
        return msg('transactions.signed', 'Waiting');
      case TransactionStatusTypes.PENDING:
        return msg('transactions.pending', 'Pending');
      case TransactionStatusTypes.TIMEOUT:
        return msg('transactions.timeout', 'Timeout');
      case TransactionStatusTypes.AUTO_TIMEOUT:
        return msg('transactions.autoTimeout', 'Auto timeout');
      case TransactionStatusTypes.ROLLED_BACK:
        return msg('transactions.rolledBack', 'Rolled back');
      default:
        return status;
    }
  };

  const parseList = (list: PaginationDTO<TransactionDTO>) => {
    return {
      ...list,
      results: list?.results?.length
        ? list.results.map((item: TransactionDTO) => {
            return {
              ...item,
              created: getFormatDate(item.created),
              requestedAmount: {
                type: 'custom',
                render: () =>
                  item.requestedAmount ? (
                    <div style={{ fontWeight: 'bold' }}>
                      {(item.requestedAmount / 100).toFixed(2) + ' ' + msg('placeSettings.currency', 'RON')}
                    </div>
                  ) : (
                    <div>N/A</div>
                  )
              },
              amount: {
                type: 'custom',
                render: () => {
                  if (item.status === TransactionStatusTypes.FAILED) {
                    return <div style={{ fontWeight: 'bold' }}>N/A</div>;
                  }
                  return (
                    <div style={{ fontWeight: 'bold' }}>
                      {item.amount
                        ? (item.amount / 100).toFixed(2) + ' ' + msg('placeSettings.currency', 'RON')
                        : 'N/A'}
                      {item.usedCoins ? ' + ' + item.usedCoins + ' ' + msg('general.coins', 'coins') : ''}
                      {props.isSAdmin && item.tipsAmount ? (
                        <div> + {(item.tipsAmount / 100).toFixed(2)} lei tips</div>
                      ) : (
                        ''
                      )}
                    </div>
                  );
                }
              },
              tipsAmount: ((item.tipsAmount || 0) / 100).toFixed(2) + ' ' + msg('placeSettings.currency', 'RON'),
              hasReceipt: {
                type: 'custom',
                render: () => (
                  <div className="badge" style={{ backgroundColor: item.receiptId ? colors.green : colors.red }}>
                    {item.receiptId ? msg('general.yes', 'Yes') : msg('general.no', 'No')}
                  </div>
                )
              },
              user: {
                type: 'custom',
                render: () => {
                  if (item.mobileUser) {
                    return (
                      <div
                        className="linkedData"
                        onClick={() =>
                          props.isSAdmin
                            ? setSearchHandler(item.mobileUser?.email)
                            : setSearchHandler(item.mobileUser?.firstname + ' ' + item.mobileUser?.lastname)
                        }
                      >
                        {item.mobileUser.firstname + ' ' + item.mobileUser.lastname}
                      </div>
                    );
                  }
                  return <div className="customData">N/A</div>;
                }
              },
              tabletUser: item.tabletUser ? item.tabletUser.firstname + ' ' + item.tabletUser.lastname : 'N/A',
              placeName: {
                type: 'custom',
                render: () => {
                  const value = item.place?.name;
                  if (value) {
                    return (
                      <div className="linkedData" onClick={() => setSearchHandler(value)}>
                        {value}
                      </div>
                    );
                  }
                  return <div className="customData">N/A</div>;
                }
              },
              displayedStatus: {
                type: 'custom',
                render: () => (
                  <div
                    className="badge"
                    style={{ backgroundColor: getStatusBadge(item.status), cursor: 'pointer' }}
                    onClick={() => setFilterHandler(item.status, 0)}
                  >
                    {props.isSAdmin ? item.status : getTransactionStatus(item.status)}
                  </div>
                )
              },
              displayedInternalStatus: {
                type: 'custom',
                render: () => {
                  const status = item.payment?.internalStatus;
                  if (!status) {
                    return <div>N/A</div>;
                  }
                  return (
                    <div
                      className="badge"
                      style={{ backgroundColor: getStatusBadge(status), cursor: 'pointer' }}
                      onClick={() => setFilterHandler(status, 1)}
                    >
                      {status}
                    </div>
                  );
                }
              },
              displayedExternalStatus: {
                type: 'custom',
                render: () => {
                  const status = item.payment?.externalStatus;
                  if (!status) {
                    return <div>N/A</div>;
                  }
                  return (
                    <div
                      className="badge"
                      style={{ backgroundColor: getStatusBadge(status), cursor: 'pointer' }}
                      onClick={() => setFilterHandler(status, 2)}
                    >
                      {status}
                    </div>
                  );
                }
              },
              isSettled: {
                type: 'custom',
                render: () => (
                  <div
                    className="badge"
                    style={{ backgroundColor: item.payment?.isSettled ? colors.darkGreen : colors.gray }}
                  >
                    {item.payment?.isSettled ? msg('general.yes', 'Yes') : msg('general.no', 'No')}
                  </div>
                )
              }
            };
          })
        : []
    };
  };

  const setSearchHandler = (value?: string) => {
    listRef.current?.setSearchByValue(value);
  };

  const setFilterHandler = (value: string, index: number) => {
    listRef.current?.setFilterByValue(value, index, true);
  };

  const getFilterFromEnum = (data: any): { _id: any; name: any }[] => {
    return Object.values(data).map(value => ({ _id: value, name: value }));
  };

  const getListFilters = () => {
    if (props.isSAdmin) {
      return [
        {
          field: 'status',
          value: [
            {
              _id: '',
              name: msg('transactions.allTransactions', 'All transactions (transaction status)')
            },
            ...getFilterFromEnum(TransactionStatusTypes)
          ],
          default: ''
        },
        {
          field: 'payment.internalStatus',
          value: [
            {
              _id: '',
              name: msg('transactions.allPaymentsInternal', 'All transactions (internal status)')
            },
            ...getFilterFromEnum(PaymentInternalStatuses)
          ],
          default: ''
        },
        {
          field: 'payment.externalStatus',
          value: [
            {
              _id: '',
              name: msg('transactions.allPaymentsExternal', 'All transactions (external status)')
            },
            ...getFilterFromEnum(PaymentExternalStatuses)
          ],
          default: ''
        }
      ];
    }
    return [
      {
        field: 'status',
        value: [
          {
            _id: '',
            name: msg('transactions.allTransactions', 'All transactions (transaction status)')
          },
          {
            _id: TransactionStatusTypes.PENDING,
            name: msg('transactions.pending', 'Waiting for client')
          },
          {
            _id: TransactionStatusTypes.SIGNED,
            name: msg('transactions.signed', 'Waiting for bank')
          },
          {
            _id: TransactionStatusTypes.APPROVED,
            name: msg('transactions.approvedTransactions', 'Approved')
          },
          {
            _id: TransactionStatusTypes.TIMEOUT,
            name: msg('transactions.timeoutTransactions', 'Timeout')
          },
          {
            _id: TransactionStatusTypes.AUTO_TIMEOUT,
            name: msg('transactions.autoTimeoutTransactions', 'Auto timeout')
          },
          {
            _id: TransactionStatusTypes.FAILED,
            name: msg('transactions.failedTransactions', 'Failed')
          },
          {
            _id: TransactionStatusTypes.ROLLED_BACK,
            name: msg('transactions.rolledBackTransactions', 'Rolled back')
          }
        ],
        default: TransactionStatusTypes.APPROVED
      }
    ];
  };

  const getFields = () => {
    if (props.forUser) {
      return {
        created: msg('transactions.created', 'Date'),
        uniqueNo: msg('transactions.uniqueNo', 'Unique number'),
        user: msg('transactions.user', 'User'),
        tabletUser: msg('transactions.tabletUser', 'Tablet user'),
        placeName: msg('transactions.place', 'Place'),
        requestedAmount: msg('transactions.requestedAmount', 'Requested amount'),
        amount: msg('transactions.amount', 'Amount'),
        hasReceipt: msg('transactions.receiptImage', 'Receipt image'),
        displayedStatus: msg('transactions.transactionStatus', 'Transaction status')
      };
    }
    if (props.isSAdmin) {
      return {
        created: msg('transactions.created', 'Date'),
        uniqueNo: msg('transactions.uniqueNo', 'Unique number'),
        user: msg('transactions.user', 'User'),
        placeName: msg('transactions.place', 'Place'),
        requestedAmount: msg('transactions.requestedAmount', 'Requested amount'),
        amount: msg('transactions.amount', 'Amount'),
        hasReceipt: msg('transactions.receiptImage', 'Receipt image'),
        displayedStatus: msg('transactions.transactionStatus', 'Transaction status'),
        displayedInternalStatus: msg('transactions.internalStatus', 'Transaction internal status'),
        displayedExternalStatus: msg('transactions.externalStatus', 'Transaction external status'),
        isSettled: msg('paymentSettlements.settled', 'Settled')
      };
    }
    const placeFields: IListFields = {
      created: msg('transactions.created', 'Date'),
      uniqueNo: msg('transactions.uniqueNo', 'Unique number'),
      user: msg('transactions.user', 'User'),
      requestedAmount: msg('transactions.requestedAmount', 'Requested amount'),
      amount: msg('transactions.amount', 'Amount'),
      tipsAmount: msg('transactions.tipsAmount', 'Tips'),
      hasReceipt: msg('transactions.receiptImage', 'Receipt image'),
      displayedStatus: msg('transactions.status', 'Status')
    };
    if (props.selected_place && !props.place?.featureFlags?.tips) {
      delete placeFields.tipsAmount;
    }
    return placeFields;
  };

  const viewTransactionDetails = (transaction: { _id: string }) => {
    const placeId = props.selected_place || '';
    props.getTransactionAction(props.isSAdmin, transaction._id, placeId);
    toggleModal();
  };

  const toggleModal = () => {
    setShowModal(!showModal);
    if (showModal) {
      props.clearTransactionModalData();
    }
  };

  const toggleExportModal = () => {
    const show = showExportModal;
    setShowExportModal(!show);
  };

  const sendReport = (startDate: number, endDate: number, params: { input: string }) => {
    props.sendTransactionsReport(props.selected_place || '', startDate, endDate, params.input);
  };

  const isEmailValid = (email: string) => {
    return !!email.match(EMAIL_VALIDATION_REGEX);
  };

  const renderExportModal = () => {
    const config = {
      title: msg('transactions.generateReport', 'Generate report'),
      message: msg('transactions.chooseTimeInterval', 'Choose time interval for the report'),
      buttonLabel: msg('transactions.sendAction', 'Send'),
      buttonIcon: 'mail',
      pending: props.export_transactions_pending,
      error: props.export_transactions_error,
      input: {
        required: true,
        label: msg('transactions.email', 'Email to send the report to:'),
        default: props.user?.email,
        isValid: isEmailValid
      },
      toggleModal: toggleExportModal,
      exportInExcel: sendReport
    };
    return <ExportModalComponent config={config} />;
  };

  return (
    <CardComponent
      title={msg('transactions.cardTitle', 'Transactions')}
      error={!!props.transactions_list_error}
      headerIcon="credit_card"
      needsTitle={true}
      buttons={
        props.isSAdmin
          ? []
          : [
              {
                label: msg('transactions.report', 'Report'),
                icon: 'assignment',
                onClick: toggleExportModal
              }
            ]
      }
    >
      {showExportModal && renderExportModal()}
      {showModal && (
        <TransactionDetailsModal
          isSAdmin={props.isSAdmin}
          forUser={props.forUser}
          selected_place={props.selected_place}
          transaction={props.transaction}
          transaction_pending={props.transaction_pending}
          toggleModal={toggleModal}
          getStatusBadge={getStatusBadge}
          getTransactionStatus={getTransactionStatus}
          rollbackTransactionAction={props.rollbackTransactionAction}
        />
      )}
      <InfiniteScrollListComponent
        wrappedComponentRef={(ref: any) => (listRef.current = ref)}
        fields={getFields()}
        actions={[
          {
            label: msg('transactions.detailsModal', 'Transaction details'),
            btn: 'btn-info',
            icon: 'info',
            returnFields: ['_id'],
            onClick: viewTransactionDetails
          }
        ]}
        list={props.forUser ? parseList(props.transactions_list_for_user) : parseList(props.transactions_list)}
        pending={props.forUser ? props.transactions_list_for_user_pending : props.transactions_list_pending}
        error={props.forUser ? props.transactions_list_for_user_error : props.transactions_list_error}
        get={getData}
        limit={30}
        disableQueryParams={props.forUser}
        search={['search']}
        filters={getListFilters()}
        sort={{
          fields: ['created', 'requestedAmount'],
          default: 'created,-1'
        }}
        dateInterval={{ field: 'created', default: 28 }}
        statsFields={{
          requestedAmount: { label: msg('transactions.requestedAmount', 'Requested amount'), parser: parseAmount },
          amount: { label: msg('transactions.amount', 'Amount'), parser: parseAmount },
          usedCoins: { label: msg('transactions.usedCoins', 'Used coins') },
          tipsAmount: { label: msg('transactions.tipsAmount', 'Tips amount'), parser: parseAmount }
        }}
      />
    </CardComponent>
  );
};

function mapStateToProps(state: AppStore.states) {
  return {
    user: state.auth.user,
    selected_place: state.places.selected_place,
    place: state.places.place,
    transactions_list: state.transactions.transactions_list,
    transactions_list_pending: state.transactions.transactions_list_pending,
    transactions_list_error: state.transactions.transactions_list_error,
    transaction: state.transactions.transaction,
    transaction_pending: state.transactions.transaction_pending,
    transaction_error: state.transactions.transaction_error,
    transactions_list_for_user: state.transactions.transactions_list_for_user,
    transactions_list_for_user_pending: state.transactions.transactions_list_for_user_pending,
    transactions_list_for_user_error: state.transactions.transactions_list_for_user_error,
    export_transactions_pending: state.transactions.export_transactions_pending,
    export_transactions_error: state.transactions.export_transactions_error
  };
}

const dispatchToProps = {
  getTransactionsAction: TransactionsStore.actions.getTransactionsAction,
  getTransactionAction: TransactionsStore.actions.getTransactionAction,
  rollbackTransactionAction: TransactionsStore.actions.rollbackTransactionAction,
  getTransactionsForUser: TransactionsStore.actions.getTransactionsForUser,
  sendTransactionsReport: TransactionsStore.actions.sendTransactionsReport,
  clearUserTransactions: TransactionsStore.actions.clearUserTransactions,
  clearTransactionModalData: TransactionsStore.actions.clearTransactionModalData,
  clearTransactionsError: TransactionsStore.actions.clearTransactionsError
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(TransactionsListContainer));
