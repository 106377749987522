/*
  @function composedCriteriaBuilder => builds a composed url criteria from key-value pairs with AND and OR operators
    @accepts objectOfCriterias : object that contains the key-value pairs (of each criteria) to be transformed
             needsOptions : boolean that checks if the criteria needs $options
    @returns a string of the composed url criteria
*/
export function composedCriteriaBuilder(objectOfCriterias: any) {
  let searchCriteria,
    filterCriteria = {};
  if (Object.keys(objectOfCriterias.search).length) {
    const searchKeys = Object.keys(objectOfCriterias.search);
    if (searchKeys.length === 1) {
      const key = searchKeys[0];
      searchCriteria = {
        [key]: {
          $regex: encodeURIComponent(objectOfCriterias.search[key]?.toString().trim()),
          $options: 'i'
        }
      };
    } else {
      const searchValues = objectOfCriterias.search[searchKeys[0]]?.toString().split(' ');
      let searchCriteriaContent = [];
      for (const key of searchKeys) {
        for (const value of searchValues) {
          const content = {
            [key]: {
              $regex: encodeURIComponent(value?.toString().trim()),
              $options: 'i'
            }
          };
          searchCriteriaContent.push(content);
        }
      }
      searchCriteria = {
        $or: searchCriteriaContent
      };
    }
  }
  if (Object.keys(objectOfCriterias.filters).length) {
    const filterKeys = Object.keys(objectOfCriterias.filters).filter(
      key => !!objectOfCriterias.filters[key]?.toString()
    );
    filterKeys.forEach(key => (filterCriteria[key] = objectOfCriterias.filters[key]));
  }

  const criteriaObject = { ...searchCriteria, ...filterCriteria };
  if (!Object.keys(criteriaObject).length) {
    return '';
  }
  return `&criteria=${JSON.stringify(criteriaObject)}`;
}
