import React from 'react';

import { msg } from '../../../../../../../utils';
import { SimpleSelectComponent, TextFieldComponent } from '../../../../../../shared';

export function GiveRewardAction({ listItem, onValueChange, index, onClick, rewards, errors }: any) {
  return (
    <div>
      <div className="col-sm-4" style={listItem.reward !== 'custom' ? { marginRight: 10 } : {}}>
        <SimpleSelectComponent
          label={msg('autopilot.actionReward', 'Reward')}
          name="actionReward"
          options={rewards}
          id="actionReward"
          value={listItem.reward}
          onChange={(e: any) => onValueChange(e.target.value, index, 'reward')}
          required={true}
          needsAllLabel={false}
          arrayOptions={false}
          formatError={errors[`reward_${listItem._id}`]}
        />
      </div>
      {listItem.reward === 'custom' && (
        <div className="col-sm-4" style={{ marginRight: 10 }}>
          <TextFieldComponent
            label={msg('autopilot.rewardName', 'Reward name')}
            id="rewardName"
            value={listItem.customReward}
            onChange={(e: any) => onValueChange(e.target.value, index, 'customReward')}
            inType="text"
            minLength={1}
            maxLength={60}
            required={true}
            formatError={errors[`reward_${listItem._id}`]}
          />
        </div>
      )}
    </div>
  );
}
