/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AppStore, AuthStore, PlacesStore, reduxContainer } from '../../../appRedux';
import { ActivityStore } from '../../../appRedux/activity';
import { IFetchPlaceActivityResponse } from '../../../appRedux/activity/api';
import { PlaceDTO } from '../../../appRedux/types';
import { deserializeQueryString, msg } from '../../../utils';
import { CardComponent, InfiniteScrollListComponent } from '../../shared';
import { ModalCheckinActivityContainer } from './CheckinActivity';
import {
    formatActivityAction, formatActivityChange, formatActivityDate, formatActivityMember,
    formatActivityTrigger, getActivityFilters
} from './common';

interface IActivityViewModel {
  memberFullname: string;
  action: string;
  change: string;
  date: string;
  isCheckin: boolean;
  _id: string | undefined;
}
export interface IPlaceActivityProps {
  location: any;
  placeId: string;
  activity: IFetchPlaceActivityResponse;
  isLoading: boolean;
  error: any;
  place: PlaceDTO | null;
  place_pending: boolean;
  selectedCheckinId: string | null;
  groupByCheckin: boolean;
  getPlaceActivityList: (
    isSAdmin: boolean,
    placeId: string,
    groupByCheckin: boolean,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: any
  ) => any;
  openCheckinActivityList: (checkinId: string) => any;
  closeCheckinActivityList: () => any;
  clearPlaceActivityError: () => any;
  redirectToPlaceDashboardAction: (placeId: string) => any;
  setGroupByCheckinAction: (groupByCheckin: boolean) => any;
}

export interface IState {
  isSAdmin: boolean;
}

const allFilters = [
  '',
  'AUTOPILOT_ACTION_REWARD_GIVEN',
  'AUTOPILOT_ACTION_POINTS_GIVEN',
  'AUTOPILOT_ACTION_MEMBER_STATUS_CHANGED',
  'AUTOPILOT_ACTION_MESSAGE_SENT',
  'MEMBERSHIP_CREATED',
  'PAYMENT_COINS_ALLOCATED',
  'PAYMENT_COMPLETED',
  'PAYMENT_REFUNDED',
  'PAYMENT_COINS_REFUNDED',
  'MEMBERSHIP_CANCELED',
  'CHECKIN_CREATED',
  'CHECKIN_REWARD_BOUGHT',
  'CHECKIN_BONUS_REDEEMED',
  'CHECKIN_COINS_ALLOCATED',
  'RECEIPT_APPROVED',
  'RECEIPT_COINS_ALLOCATED',
  'REVIEW_COINS_ALLOCATED',
  'CHECKIN_PARTIAL_REDEEM',
  'CHECKOUT',
  'MEMBER_TAG_ADDED',
  'MEMBER_TAG_REMOVED'
];

const SAdminFilters = ['USER_TAG_ADDED', 'USER_TAG_REMOVED'];

const groupFilters = [
  '',
  'AUTOPILOT_ACTION_REWARD_GIVEN',
  'AUTOPILOT_ACTION_POINTS_GIVEN',
  'AUTOPILOT_ACTION_MEMBER_STATUS_CHANGED',
  'AUTOPILOT_ACTION_MESSAGE_SENT',
  'MEMBERSHIP_CREATED',
  'MEMBERSHIP_CANCELED',
  'REVIEW_COINS_ALLOCATED',
  'CHECKIN_CREATED',
  'CHECKOUT',
  'MEMBER_TAG_ADDED',
  'MEMBER_TAG_REMOVED'
];

class PlaceActivity extends React.Component<IPlaceActivityProps & RouteComponentProps, IState> {
  listRef: any;

  constructor(props: IPlaceActivityProps & RouteComponentProps) {
    super(props);
    this.state = {
      isSAdmin: props.match?.path === '/admin/activity'
    };
    this.listRef = React.createRef();
  }

  fetchPlaceActivity = async (limit: number, skip: number, sort: string, criteria: any) => {
    this.props.getPlaceActivityList(
      this.state.isSAdmin,
      this.props.placeId,
      this.props.groupByCheckin,
      limit,
      skip,
      sort,
      criteria
    );
  };

  componentDidMount() {
    if (
      !this.state.isSAdmin &&
      this.props.place &&
      !this.props.place_pending &&
      !this.props.place?.featureFlags?.becomeMember
    ) {
      this.props.redirectToPlaceDashboardAction(this.props.placeId);
    }
  }

  componentWillUnmount() {
    this.props.clearPlaceActivityError();
  }

  toggleHandler = (event: any) => {
    this.setFilterHandler('', 0);
    this.props.setGroupByCheckinAction(!!event.target.checked);
  };

  setFilterHandler = (value: string, index: number) => {
    this.listRef?.setFilterByValue(value, index);
  };

  setSearchHandler = (value?: string) => {
    this.listRef?.setSearchByValue(value);
  };

  mapResponseToView = () => {
    const { activity } = this.props;
    if (!activity.results) {
      return activity;
    }
    return {
      ...activity,
      results: activity.results.map(entry => ({
        memberFullname: {
          type: 'custom',
          render: () => {
            const value = this.state.isSAdmin ? formatActivityMember(entry.userId) : formatActivityMember(entry.member);
            return (
              <div
                className="linkedData"
                onClick={() =>
                  this.setSearchHandler(
                    this.state.isSAdmin ? entry.userId.email : entry.member?.firstname + ' ' + entry.member?.lastname
                  )
                }
              >
                {value}
              </div>
            );
          }
        },
        action: {
          type: 'custom',
          render: () => {
            const value = formatActivityAction(entry);
            return (
              <div className="linkedData" onClick={() => this.setFilterHandler(entry.eventType, 0)}>
                {value}
                {entry.eventType === 'CHECKIN_CREATED'
                  ? ` (${entry.type + (entry.confirmationNo ? ' - ' + entry.confirmationNo : '')})`
                  : ''}
              </div>
            );
          }
        },
        change: {
          type: 'custom',
          render: () => {
            const value = formatActivityChange(entry);
            let searchField: any = null;
            if (entry.rewardTitle) {
              searchField = entry.rewardTitle;
            } else if (entry.bonus) {
              searchField = entry.bonus;
            }
            if (searchField) {
              return (
                <div className="linkedData" onClick={() => this.setSearchHandler(searchField)}>
                  {value}
                </div>
              );
            } else if (value) {
              return <div className="customData">{value}</div>;
            }
            return <div className="customData">N/A</div>;
          }
        },
        date: formatActivityDate(entry),
        trigger: {
          type: 'custom',
          render: () => {
            const value = formatActivityTrigger(entry);
            if (value) {
              return (
                <div
                  className="linkedData"
                  onClick={() => this.setSearchHandler(entry.eventTitle || entry.autopilotTitle || entry.tagName || '')}
                >
                  {value}
                </div>
              );
            }
            return <div className="customData">N/A</div>;
          }
        },
        placeName: {
          type: 'custom',
          render: () => {
            const value = entry.placeName;
            if (value) {
              return (
                <div className="linkedData" onClick={() => this.setSearchHandler(entry.placeName)}>
                  {value}
                </div>
              );
            }
            return <div className="customData">N/A</div>;
          }
        },
        isCheckin: entry.eventType === 'CHECKIN_CREATED',
        _id: entry.checkinId
      }))
    };
  };

  defaultSearchValue = () => deserializeQueryString(this.props.location.search).search || '';
  getActions = () => {
    return [
      {
        label: msg('activityChanges.viewTransactionHistory', 'View transaction history'),
        btn: 'btn-info',
        icon: 'change_history',
        isShown: (entity: IActivityViewModel) => !!entity.isCheckin,
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          const checkinId = event.currentTarget.getAttribute('id');
          if (!!checkinId) {
            this.props.openCheckinActivityList(checkinId);
          }
        }
      }
    ];
  };

  getFields = () => {
    const fields: any = {
      date: msg('activity.dateTime', 'Date and Time'),
      action: msg('activity.action', 'Action'),
      change: msg('activity.change', 'Change'),
      trigger: msg('activity.trigger', 'Trigger'),
      memberFullname: msg('activity.member', 'Member')
    };
    if (this.state.isSAdmin) {
      fields.placeName = msg('activity.place', 'Place');
    }
    return fields;
  };

  render() {
    const { selectedCheckinId, isLoading, error, groupByCheckin } = this.props;
    return (
      <CardComponent
        title={msg('sidebar.activity', 'Activity')}
        error={!!error}
        headerIcon="group"
        needsTitle={true}
        toggle={{
          label: msg('activity.groupByToggle', 'Group events by check-in'),
          tooltip: '',
          checked: groupByCheckin,
          onChange: this.toggleHandler
        }}
      >
        {selectedCheckinId ? <ModalCheckinActivityContainer onClose={this.props.closeCheckinActivityList} /> : null}
        <InfiniteScrollListComponent
          wrappedComponentRef={(ref: any) => (this.listRef = ref)}
          fields={this.getFields()}
          actions={groupByCheckin ? this.getActions() : undefined}
          list={this.mapResponseToView()}
          pending={isLoading}
          error={error}
          get={this.fetchPlaceActivity}
          limit={30}
          search={['search']}
          defaultSearchValue={this.defaultSearchValue()}
          sort={{
            fields: ['date'],
            default: 'date,-1'
          }}
          filters={[
            {
              field: 'eventType',
              value: groupByCheckin
                ? getActivityFilters(groupFilters)
                : getActivityFilters(this.state.isSAdmin ? [...allFilters, ...SAdminFilters] : allFilters)
            }
          ]}
        />
      </CardComponent>
    );
  }
}

const mapStateToProps = (state: AppStore.states) => ({
  placeId: state.places.selected_place,
  groupByCheckin: state.auth.activity_group_by_checkin,
  activity: state.activity.place_activity_list,
  isLoading: state.activity.place_activity_list_pending,
  error: state.activity.place_activity_list_error,
  selectedCheckinId: state.activity.selected_checkin,
  place: state.places.place,
  place_pending: state.places.place_pending
});

const dispatchToProps = {
  getPlaceActivityList: ActivityStore.actions.getPlaceActivityList,
  openCheckinActivityList: ActivityStore.actions.openCheckinActivityList,
  closeCheckinActivityList: ActivityStore.actions.closeCheckinActivityList,
  clearPlaceActivityError: ActivityStore.actions.clearPlaceActivityError,
  redirectToPlaceDashboardAction: PlacesStore.actions.redirectToPlaceDashboardAction,
  setGroupByCheckinAction: AuthStore.actions.setGroupByCheckinAction
};

const PlaceActivityContainer = reduxContainer(PlaceActivity, mapStateToProps, dispatchToProps);

export default PlaceActivityContainer;
