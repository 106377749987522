import React from 'react';

import { SimpleSelectComponent } from '../../../../../../shared/index';
import { memberStatus } from '../../../../../../../appRedux/autopilot/AutopilotSelections';
import { msg, tlist } from '../../../../../../../utils/index';

export function ChangeMemberStatusAction({ listItem, onValueChange, index, onClick, errors }: any) {
  return (
    <div>
      <div className="col-sm-4" style={{ marginRight: 10 }}>
        <SimpleSelectComponent
          label={msg('autopilot.memberStatus', 'New Status')}
          name="memberStatus"
          options={tlist(memberStatus)}
          id="memberStatus"
          value={listItem.value}
          onChange={(e: any) => onValueChange(e.target.value, index, 'value')}
          required={true}
          needsAllLabel={false}
          arrayOptions={false}
          formatError={errors[`reward_${listItem._id}`]}
        />
      </div>
    </div>
  );
}
