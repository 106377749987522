import { cloneDeep } from 'lodash';
import moment from 'moment';
import React from 'react';
import { renderToString } from 'react-dom/server';

import { Checkbox, Tooltip } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { RaffleModal } from '../';
import {
    EventDTO, EventForType, EventType, PaginationDTO, ParticipantsType, PlaceDTO, RaffleDTO,
    RaffleIntervalDTO, RewardType, ScheduleDTO, SelectValueDTO
} from '../../../../../appRedux/types';
import galleryPlaceholder from '../../../../../assets/images/galleryPlaceholder.jpg';
import voucherIcon from '../../../../../assets/images/voucher-icon.png';
import { colors } from '../../../../../assets/styles';
import { AlertUtil, IValidator, msg, Validator } from '../../../../../utils';
import {
    ButtonComponent, DateTimePickerComponent, MultipleSelectComponent, RewardPictureInput,
    SimpleSelectComponent, TextFieldComponent, UploadComponent
} from '../../../../shared';
import JoditComponent from '../../../../shared/JoditComponent';
import { PlaceScheduleComponent } from '../../../places/components/forms';
import { EventPreview, SelectMembersModal } from './';
import styles from './EventForm.module.css';

interface IProps {
  event: EventDTO;
  save?: (event: any) => void;
  pending: boolean;
  editable?: boolean;
  partnerId: string;
  places_list?: SelectValueDTO[];
  allowMultiplePlaces: boolean;
  place: PlaceDTO | null;
  unavailableMomentsDays: string[];
  members_list: PaginationDTO<any>;
  members_list_pending: boolean;
  members_list_error: string | null;
  getMembers: (
    placeId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string },
    places?: string[]
  ) => any;
  raffle_preview: RaffleIntervalDTO[] | null;
  raffle_preview_pending: boolean;
  update_raffle_preview_pending: boolean;
  update_raffle_preview_error: string | null;
  getRafflePreview: (
    placeId: string,
    config: { raffleStart: string; raffleEnd: string; totalPrizes: number; schedule: ScheduleDTO[] }
  ) => any;
  getRaffleIntervals: (placeId: string, eventId: string) => any;
  editRaffleIntervals: (placeId: string, eventId: string, intervals: RaffleIntervalDTO[], editDate: number) => any;
  clearRaffleIntervals: () => any;
}
interface IState {
  event: EventDTO;
  raffleIntervals: RaffleIntervalDTO[] | null;
  isDirty: boolean;
  raffleChanged: boolean;
  selectAllPlaces: boolean;
  showMemberModal: boolean;
  showRaffleModal: boolean;
  currentCoverPhoto: {
    createdAt: string;
    id: string;
    type: string;
    url: string;
  };
  totalMembers: number;
  voucherConfig: {
    minimumAmount?: number;
    partialRedeem?: boolean;
  };
}

class EventFormComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    const voucherReward = props.event?.rewards?.find(item => item.type === RewardType.VOUCHER);
    this.state = {
      event: props.event || new EventDTO(),
      raffleIntervals: props.raffle_preview || [],
      isDirty: !props.editable,
      raffleChanged: false,
      selectAllPlaces: props.allowMultiplePlaces,
      showMemberModal: false,
      showRaffleModal: false,
      currentCoverPhoto: props.event?.currentCoverPhoto || {},
      totalMembers: props.members_list?.total_record_count,
      voucherConfig: voucherReward
        ? { partialRedeem: voucherReward.partialRedeem, minimumAmount: voucherReward.minimumAmount }
        : {}
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.update_raffle_preview_pending &&
      !this.props.update_raffle_preview_pending &&
      !this.props.update_raffle_preview_error
    ) {
      this.toggleRaffleModal();
    }
    if (
      !this.state.totalMembers &&
      prevProps.members_list_pending &&
      !this.props.members_list_pending &&
      !this.props.members_list_error
    ) {
      this.setState({ totalMembers: this.props.members_list?.total_record_count });
    }
  }

  componentWillUnmount() {
    this.props.clearRaffleIntervals();
  }

  parsePlacesForEvent = (list: { label: string; value: string }[]) => {
    let placesList = list?.map(place => {
      return { _id: place.value, name: place.label };
    });
    return placesList;
  };

  parsePlacesForSelect = (list: SelectValueDTO[]) => {
    return list?.map(place => {
      return { label: place.name, value: place._id };
    });
  };

  checkValidMembers = () => {
    const event = this.state.event;
    return !(event.participantsType === ParticipantsType.SELECTED && !event.memberIds?.length);
  };

  saveHandler = async () => {
    const event = this.state.event;
    const channels = ['1'];
    const targets = ['1', '2', '3'];
    const stylePattern = new RegExp('style=""', 'g');
    const linkPattern = new RegExp('href="www.', 'g');
    const rewards = event.rewards.map(reward => {
      if (reward.type === RewardType.VOUCHER) {
        reward = { ...reward, ...this.state.voucherConfig };
      }
      if (event.participantsType !== ParticipantsType.RANDOM || event.rewards.length < 2) {
        delete reward.total;
      }
      return reward;
    });
    // creating the final object to send
    let dataToSend = {
      ...event,
      rewards,
      content: event.content.replace(stylePattern, 'style="width: 100%"').replace(linkPattern, 'href="https://www.'),
      currentCoverPhoto: this.state.currentCoverPhoto,
      channels: channels,
      target: targets,
      raffleIntervals: !this.state.raffleChanged && !this.props.editable ? this.state.raffleIntervals : null,
      startDate: event.hideDates ? event.dispatch : event.startDate
    };
    const validMembers = this.checkValidMembers();
    const { isValid } = this.props.validator!;
    if (this.props.save && isValid() && validMembers) {
      const hasConfirmation = await this.fireConfirmationAlert();
      if (hasConfirmation) {
        this.props.save(dataToSend);
      }
    } else if (!validMembers) {
      AlertUtil.simple(msg('event.invalidMembers', 'You must select at least one member for the event!'), 'warning');
    }
  };

  // universal handling change function for event
  handleChange = (event: any) => {
    const fieldKey = event.target.getAttribute('id');
    const fieldValue = event.target.value;
    const eveniment = this.state.event;
    const { isDirty } = this.props.validator!;
    let { raffleChanged } = this.state;
    if (fieldKey.indexOf('.') >= 0) {
      const fields = fieldKey.split('.');
      eveniment[fields[0]][fields[1]] = Number(fieldValue);
      raffleChanged = true;
    } else {
      eveniment[fieldKey] = fieldValue;
    }
    isDirty(fieldKey);
    this.setState({ event: eveniment, isDirty: true, raffleChanged });
  };

  // event description / content handling function
  handleChangeDescription = (value: string) => {
    this.setState({ event: { ...this.state.event, content: value }, isDirty: true });
  };

  // event type change function
  handleRadioChange = (event: any) => {
    const eveniment = this.state.event;
    let raffleIntervals = this.state.raffleIntervals;
    const oldtype = typeof eveniment[event.target.name];
    let value = event.target.value;
    if (oldtype === 'number') {
      value = parseInt(value, 10);
    }
    eveniment.visibleType = eveniment.type = value;
    if (value === EventType.PROMOTION) {
      eveniment.rewards = [{ type: RewardType.VOUCHER, title: 'Voucher' }];
      delete eveniment.hideDates;
    } else {
      raffleIntervals = null;
      eveniment.rewards = [];
      eveniment.places = [];
      eveniment.memberIds = [];
      eveniment.participantsType = ParticipantsType.ALL;
      delete eveniment.raffle;
      delete eveniment.hideDates;
      if (value === EventType.NEWS) {
        eveniment.type = EventType.EVENT;
        eveniment.hideDates = true;
      }
    }
    this.setState({ event: eveniment, raffleIntervals });
  };

  handlePlaces = (data: any) => {
    const event = this.state.event;
    const oldtype = typeof event[data.target.name];
    let value = data.target.value;
    if (oldtype === 'number') {
      value = parseInt(value, 10);
    }
    event[data.target.name] = value;
    if (value === 0) {
      event.places = [];
    } else if (this.props.places_list?.length) {
      event.places = this.props.places_list;
    }
    this.setState({ event });
  };

  // get startDate, endDate & dispatch functions to be sent as props to datePickerComponent
  getEventDate = (type: string) => (date: any) => {
    const { event } = this.state;
    let raffleChanged = this.state.raffleChanged;
    if (type.indexOf('.') >= 0) {
      const fields = type.split('.');
      if (fields[1] === 'raffleEnd' && event.raffle) {
        event.raffle.raffleEnd = date.endOf('day').format();
      } else {
        event[fields[0]][fields[1]] = date.format();
      }
      raffleChanged = true;
    } else {
      event[type] = date.format();
      if (type === 'startDate' && !this.props.editable) {
        const minDate = moment(event.startDate).subtract(7, 'd');
        event.dispatch = minDate.valueOf() < moment().valueOf() ? moment().format() : minDate.format();
        if (event.raffle) {
          event.raffle.raffleStart = date.startOf('day').format();
          raffleChanged = true;
        }
      } else if (type === 'endDate' && !this.props.editable && event.raffle) {
        event.raffle.raffleEnd = date.endOf('day').format();
        raffleChanged = true;
      }
    }
    const { isDirty } = this.props.validator!;
    isDirty(type);
    this.setState({ event, raffleChanged, isDirty: true });
  };

  sendToAllHandler = (event: any) => {
    const newEvent = this.state.event;
    if (newEvent.places?.length === this.props.places_list?.length) {
      newEvent.places = [];
    } else if (this.props.places_list) {
      newEvent.places = this.props.places_list;
    }
    this.setState({ event: newEvent, selectAllPlaces: event.target.checked });
  };

  handlePlaceMultipleSelectChange = (values: { label: string; value: string }[]) => {
    const { event, selectAllPlaces } = this.state;
    event.places = this.parsePlacesForEvent(values);
    if (values && !selectAllPlaces && values.length === this.props.places_list?.length) {
      this.setState({ selectAllPlaces: true });
    } else if (selectAllPlaces) {
      this.setState({ selectAllPlaces: false });
    }
    delete event.memberIds;
    delete event.raffle;
    event.participantsType = ParticipantsType.ALL;
    event.rewards.splice(1, event.rewards.length - 1);
    this.setState({ event });
  };

  onScheduleChange = (dayIndex: number, intervalIndex: number, period?: string, type?: string) => (event: any) => {
    const raffle = this.state.event.raffle || new RaffleDTO();
    if (type === 'start' || type === 'end') {
      raffle.schedule[dayIndex].intervals[intervalIndex][`${type}${period}`] = event.target.value;
    } else {
      raffle.schedule[dayIndex].opened = event.target.checked;
    }
    this.setState({ ...event, raffle, raffleChanged: true });
  };

  checkValidSchedule = () => {
    const schedule = this.state.event.raffle?.schedule;
    return schedule?.some(day => day.opened);
  };

  handleRewardChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.id || event.target.name;
    const value: any = event.target.value;
    const { isDirty } = this.props.validator!;
    const newEvent = this.state.event;
    let raffleChanged = this.state.raffleChanged;
    if (newEvent.rewards.length) {
      newEvent.rewards.forEach((reward, rewardIndex) => {
        if (index === rewardIndex) {
          switch (key) {
            case 'amount':
              const intValue = parseInt(value, 10);
              reward.title = `Voucher ${isNaN(intValue) ? '' : intValue} lei`;
              reward.amount = intValue;
              break;
            case 'rewardTitle':
              reward.title = value;
              break;
            case 'type':
              if (value === RewardType.VOUCHER) {
                reward.title = 'Voucher';
                delete reward.media;
              } else {
                reward.title = '';
                delete reward.amount;
              }
              reward.type = value;
              break;
            case 'total': {
              reward.total = parseInt(value, 10);
              if (newEvent.raffle) {
                raffleChanged = true;
                newEvent.raffle.totalPrizes = newEvent.rewards.reduce(
                  (prev, current) => prev + (current.total || 0),
                  0
                );
              }
              break;
            }
            default:
              reward[key] = value;
              break;
          }
        }
      });
    }
    isDirty(`${key}${index}`);
    this.setState({ event: newEvent, isDirty: true, raffleChanged });
  };

  handleAddReward = () => {
    const event = this.state.event;
    event.rewards.push({ type: RewardType.VOUCHER, title: 'Voucher' });
    this.setState({ event, isDirty: true });
  };

  handleDeleteReward = (index: number) => () => {
    let { event } = this.state;
    if (event.rewards.length) {
      event.rewards.splice(index, 1);
      this.setState({ event, isDirty: true });
    }
  };

  checkValidRewards = () => {
    const allRewardsCompleted = this.state.event.rewards.every(
      item =>
        RewardType[item.type + ''] &&
        item.title &&
        (item.type !== RewardType.VOUCHER || item.amount) &&
        (this.state.event.participantsType !== ParticipantsType.RANDOM || item.total)
    );
    return this.props.place?.featureFlags.integration || allRewardsCompleted;
  };

  handleVoucherConfigChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.id || event.target.name;
    let value: any = event.target.value;
    const { voucherConfig } = this.state;
    if (key === 'partialRedeem') {
      value = value === 'true';
      if (!value) {
        delete voucherConfig.minimumAmount;
      }
    } else {
      const { isDirty } = this.props.validator!;
      value = parseInt(value, 10);
      isDirty(key);
    }
    voucherConfig[key] = value;
    this.setState({ voucherConfig });
  };

  checkValidVoucher = () => {
    const hasVoucher = this.state.event.rewards.findIndex(item => item.type === RewardType.VOUCHER) > -1;
    const { voucherConfig } = this.state;
    return (
      !hasVoucher ||
      voucherConfig.partialRedeem === false ||
      (voucherConfig.partialRedeem && !!voucherConfig.minimumAmount)
    );
  };

  uploadPicture = (index: number) => (res: any) => {
    const rewards = this.state.event.rewards;
    if (res?.data?.[0]) {
      rewards[index].media = res.data[0];
      this.setState({ event: { ...this.state.event, rewards }, isDirty: true });
    }
  };

  selectAllMembers = (event: any) => {
    const newEvent = this.state.event;
    const eventParticipantsType = Number(event.target.value);
    if (eventParticipantsType !== ParticipantsType.SELECTED) {
      delete newEvent.memberIds;
    }
    if (eventParticipantsType !== ParticipantsType.RANDOM) {
      delete newEvent.raffle;
      if (newEvent.rewards.length) {
        const remainingReward = newEvent.rewards[0];
        delete remainingReward.total;
        newEvent.rewards = [remainingReward];
      }
    } else {
      newEvent.raffle = new RaffleDTO();
      newEvent.raffle.raffleStart = moment(newEvent.startDate).startOf('day').format();
      newEvent.raffle.raffleEnd = moment(newEvent.endDate).endOf('day').format();
      const placeSchedule = this.props.place?.schedule?.map(day => {
        const intervals = [cloneDeep(day.intervals[0])];
        intervals[0].endMinutes = intervals[0].startMinutes = 0;
        return { ...day, intervals };
      });
      if (placeSchedule) {
        newEvent.raffle.schedule = placeSchedule;
      }
    }
    newEvent.participantsType = eventParticipantsType;
    this.setState({ event: newEvent });
  };

  setCustomMembers = (memberIds: string[]) => {
    const event = this.state.event;
    event.memberIds = memberIds;
    this.setState({ event });
  };

  toggleMembersModal = () => {
    const show = this.state.showMemberModal;
    this.setState({
      showMemberModal: !show
    });
  };

  toggleRaffleModal = () => {
    const show = this.state.showRaffleModal;
    if (!show) {
      const { event, raffleChanged } = this.state;
      const config = event.raffle
        ? {
            raffleStart: event.raffle?.raffleStart,
            raffleEnd: event.raffle?.raffleEnd,
            totalPrizes: event.raffle?.totalPrizes,
            schedule: event.raffle?.schedule
          }
        : new RaffleDTO();
      if (!this.props.editable && (raffleChanged || !this.props.raffle_preview)) {
        this.props.getRafflePreview(this.props.place?._id || '', config);
      } else if (this.props.editable) {
        this.props.getRaffleIntervals(this.props.place?._id || '', this.props.event._id);
      }
    }
    this.setState({
      showRaffleModal: !show
    });
  };

  saveRafflePreview = (preview: RaffleIntervalDTO[], modalDate: number) => {
    if (this.props.editable) {
      let editedPreview: any = [];
      preview?.forEach((interval: RaffleIntervalDTO, index: number) => {
        if (this.props.raffle_preview && interval.prizes !== this.props.raffle_preview[index].prizes) {
          editedPreview.push(interval);
        }
      });
      if (editedPreview.length) {
        this.props.editRaffleIntervals(this.props.place?._id || '', this.state.event._id, editedPreview, modalDate);
      } else {
        this.toggleRaffleModal();
      }
    }
    this.setState({
      raffleIntervals: preview,
      raffleChanged: false,
      isDirty: true
    });
  };

  handleSendNotifications = (event: any) => {
    const value = event.target.value === 'true';
    const newEvent = this.state.event;
    newEvent.nativeNotifications = value;
    this.setState({ event: newEvent });
  };

  fireConfirmationAlert = () => {
    if (this.props.editable) {
      return true;
    }
    const content = this.getAlertHTMLContent();
    const stringContent = renderToString(content);
    const title = `<div style="line-height: initial">${msg(
      'event.confirmSendEvent',
      'Are you sure you want to send the following event?'
    )}</div>`;
    return AlertUtil.confirmWithHtml(title, stringContent, msg('alertMessages.continue', 'Continue'));
  };

  getAlertHTMLContent = () => {
    const event = this.state.event;
    const eventType =
      event.visibleType === EventType.EVENT
        ? msg('eventTypes.event', 'Event')
        : event.visibleType === EventType.NEWS
        ? msg('eventTypes.news', 'News')
        : msg('eventTypes.promotion', 'Promotion');
    const participants =
      event.participantsType === ParticipantsType.SELECTED ? event.memberIds?.length || 0 : this.state.totalMembers;
    const rewards = this.state.event.rewards.map(item => item.title).join(', ');
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 20 }}>
        <div>
          {msg('event.title', 'Title')}: {event.title}
        </div>
        <div>
          {msg('event.type', 'Type')}: {eventType}
        </div>
        <div style={{ textAlign: 'left' }}>
          {msg('event.rewards', 'Reward')}: {rewards || msg('general.no', 'No')}
        </div>
        <div>
          {msg('event.sendNotifications', 'Send notifications')}:{' '}
          {event.nativeNotifications ? msg('general.yes', 'Yes') : msg('general.no', 'No')}
        </div>
        <div>
          {msg('event.sendTo', 'Send to')}: {participants} {msg('event.members', 'members')}
        </div>
      </div>
    );
  };

  renderType = () => (
    <>
      <div className="row">
        <legend style={{ marginTop: 10, marginBottom: 15, marginLeft: 15 }}>
          {msg('event.eventType', 'Choose the type of promotional material:')}
        </legend>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <RadioGroup
            name="visibleType"
            onChange={this.handleRadioChange}
            value={this.state.event.visibleType.toString()}
            row={true}
            style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
          >
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={msg('eventTypes.event', 'Event')}
              style={{ width: 'auto', color: 'black' }}
              disabled={this.props.editable}
            />
            {this.props.place?.featureFlags.checkin && (
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label={msg('eventTypes.promotion', 'Promotion')}
                style={{ width: 'auto', color: 'black' }}
                disabled={this.props.editable}
              />
            )}
            {this.props.place?.featureFlags.news && (
              <FormControlLabel
                value="3"
                control={<Radio color="primary" />}
                label={msg('eventTypes.news', 'News')}
                style={{ width: 'auto', color: 'black' }}
                disabled={this.props.editable}
              />
            )}
          </RadioGroup>
        </div>
      </div>
      <hr style={customStyles.separator} />
    </>
  );

  renderContent = () => (
    <>
      <div className="row">
        <legend style={customStyles.legend}>{msg('event.basicInfo', 'Basic information:')}</legend>
        <div className="col-sm-5">
          <TextFieldComponent
            label={
              this.state.event.visibleType === EventType.EVENT
                ? msg('eventTypes.eventTitle', 'Event title')
                : this.state.event.visibleType === EventType.NEWS
                ? msg('eventTypes.newsTitle', 'News title')
                : msg('eventTypes.promotionTitle', 'Promotion title')
            }
            id="title"
            value={this.state.event.title}
            onChange={this.handleChange}
            inType="text"
            minLength={1}
            maxLength={70}
            formatError={this.props.validator!.errors.title}
            required={true}
            ref="title"
            validator={[
              {
                type: 'isLength',
                msg: msg('formValidation.inputLength', 'Input too short!'),
                params: { min: 2 }
              }
            ]}
          />
        </div>
        <div className="col-sm-4" />
      </div>
      <div className="row" style={{ marginBottom: 10 }}>
        <div className="col-sm-6" style={{ marginTop: 10 }}>
          <FormLabel required={true}>{msg('event.description', 'Description')}</FormLabel>
        </div>
      </div>
      <div className="event-description">
        <div className="rich-text-editor">
          <JoditComponent
            content={this.state.event.content}
            onChange={this.handleChangeDescription}
            config={{
              mediaUrl: `media/place/${this.props.place?._id}?type=QUILL_EVENT`,
              style: { fontSize: 28, lineHeight: 36, height: 700 }
            }}
          />
        </div>
        <div className="event-preview">
          <EventPreview {...{ styles, event: this.state.event }} />
        </div>
      </div>
    </>
  );

  renderCover = () => (
    <>
      <hr style={customStyles.separator} />
      <div className="row" style={this.state.event.type === EventType.PROMOTION ? { marginBottom: 10 } : {}}>
        <legend style={customStyles.legend}>
          {msg('event.chooseMedia', 'Choose a picture for the promotional material:')}
        </legend>
        <div className={styles.images + ' flex items-stretch'}>
          <div className="col-sm-5">
            <div className={styles.cover} style={{ margin: 'auto', maxWidth: 475 }}>
              {this.state.currentCoverPhoto?.url ? (
                <div
                  className="cover-image image-ratio-cover"
                  style={{
                    background: `url(${encodeURI(this.state.currentCoverPhoto.url)})`
                  }}
                />
              ) : (
                <img className={styles.img} src={galleryPlaceholder} alt="" />
              )}
            </div>
            <div style={{ margin: 'auto', maxWidth: 400 }}>
              <div>
                <UploadComponent
                  url={`media/place/${this.props.place?._id}?type=EVENT`}
                  accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
                  extraData={{
                    partnerId: this.props.partnerId
                  }}
                  messages={{
                    default: msg('general.uploadImage', 'Upload images by drag-and-drop or click!')
                  }}
                  onSuccess={(res: any) => this.setState({ currentCoverPhoto: res.data[0], isDirty: true })}
                  crop={true}
                  cropAspect={2 / 1}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  renderDates = () => (
    <>
      <hr style={customStyles.separator} />
      <div className="row">
        <legend style={customStyles.legend}>
          {this.state.event.hideDates
            ? msg('eventTypes.expirationDate', 'Choose expiration date:')
            : this.state.event.type === EventType.EVENT
            ? msg('eventTypes.eventSchedule', 'Choose event schedule:')
            : msg('eventTypes.promotionSchedule', 'Choose promotion schedule:')}
        </legend>
      </div>
      <div className="row" style={this.state.event.hideDates ? { marginBottom: 10 } : {}}>
        {!this.state.event.hideDates && (
          <div className="col-sm-3">
            <DateTimePickerComponent
              date={this.state.event.startDate}
              label={msg('event.startDate', 'Start date')}
              getSelectedDate={this.getEventDate('startDate')}
              disablePast={true}
            />
          </div>
        )}
        <div className="col-sm-3">
          <DateTimePickerComponent
            date={this.state.event.endDate}
            minDate={this.state.event.startDate}
            label={!this.state.event.hideDates ? msg('event.endDate', 'End date') : ''}
            getSelectedDate={this.getEventDate('endDate')}
            formatError={this.props.validator!.errors.endDate}
            ref="endDate"
            validator={[
              {
                type: 'customDatePicker',
                msg: msg('formValidation.invalidDate', 'Invalid date!'),
                params: { startDate: this.state.event.startDate, endDate: this.state.event.endDate }
              }
            ]}
          />
        </div>
      </div>
    </>
  );

  renderPlaces = () => (
    <>
      <hr style={customStyles.separator} />
      <div className="row">
        <legend style={customStyles.legend}>{msg('event.eventFor', 'Choose the goal for the promotion:')}</legend>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <RadioGroup
            name="for"
            onChange={this.handlePlaces}
            value={this.state.event.for?.toString()}
            row={true}
            style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
          >
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={msg('event.forCurrentPlace', 'For current place')}
              disabled={this.props.editable}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={msg('event.forPartners', 'For partners')}
              disabled={this.props.editable}
            />
          </RadioGroup>
        </div>
      </div>
      {this.state.event.for === EventForType.PARTNERS && (
        <>
          <hr style={customStyles.separator} />
          <div className="row">
            <legend style={customStyles.legend}>
              {msg('event.eventPlaces', 'Choose the places for the promotion:')}
            </legend>
          </div>
          <div className="row">
            <div className="col-sm-9">
              <MultipleSelectComponent
                label={msg('event.places', 'Places *')}
                options={this.parsePlacesForSelect(this.props.places_list || [])}
                id="placesList"
                value={this.parsePlacesForSelect(this.state.event.places)}
                onChange={this.handlePlaceMultipleSelectChange}
                maxToShow={this.props.editable ? this.props.places_list?.length : 10}
                disabled={this.props.editable}
                style={{ marginTop: 0 }}
              />
              {!this.props.editable && (
                <div>
                  <Checkbox
                    checked={this.state.selectAllPlaces}
                    onChange={this.sendToAllHandler}
                    color="primary"
                    disabled={this.props.editable}
                  />
                  <FormLabel style={{ marginTop: 25 }}>
                    {msg('partnerEmail.sendToAll', 'Send to all partners')}
                  </FormLabel>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );

  renderParticipantsType = () => (
    <>
      <hr style={customStyles.separator} />
      <div>
        <div className="row">
          <legend style={customStyles.legend}>
            {msg('event.promotionMembers', 'Choose the members for the promotion:')}
          </legend>
        </div>
        <div className="row">
          <div className="col-md-8 col-lg-6">
            <RadioGroup
              name="selectMembers"
              onChange={this.selectAllMembers}
              value={this.state.event.participantsType}
              row={true}
              style={{ display: 'flex', justifyContent: 'space-between', minHeight: '60px' }}
            >
              <FormControlLabel
                disabled={this.props.editable}
                value={ParticipantsType.ALL}
                control={<Radio color="primary" />}
                label={msg('event.selectAll', 'Send to all members')}
              />
              <FormControlLabel
                disabled={this.props.editable}
                value={ParticipantsType.SELECTED}
                control={<Radio color="primary" />}
                label={msg('event.selectMembers', 'Select members')}
              />
              <FormControlLabel
                disabled={this.props.editable}
                value={ParticipantsType.RANDOM}
                control={<Radio color="primary" />}
                label={msg('event.randomMembers', 'Raffle')}
              />
            </RadioGroup>
          </div>
          {this.state.event.participantsType === ParticipantsType.SELECTED && !this.props.editable && (
            <div className="col-sm-3">
              <ButtonComponent
                label={msg('event.selectMembers', 'Select members')}
                icon="people"
                action={this.toggleMembersModal}
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-sm-3">
            {this.state.event.participantsType === ParticipantsType.SELECTED
              ? this.state.event.memberIds?.length || 0
              : this.state.totalMembers}{' '}
            {msg('event.membersSelected', 'members selected')}
          </div>
        </div>
      </div>
    </>
  );

  renderRaffleSchedule = () => (
    <>
      <hr style={customStyles.separator} />
      <div className="row">
        <legend style={customStyles.legend}>{msg('event.raffleDuration', 'Select the raffle schedule:')}</legend>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <DateTimePickerComponent
            date={this.state.event.raffle?.raffleStart}
            minDate={this.state.event.startDate}
            maxDate={this.state.event.endDate}
            label={msg('event.raffleStartDate', 'Raffle start date')}
            getSelectedDate={this.getEventDate('raffle.raffleStart')}
            disablePast={true}
            disabled={this.props.editable}
            formatError={this.props.validator!.errors.raffleStart}
            ref="raffleEnd"
            validator={[
              {
                type: 'customDatePicker',
                msg: msg('formValidation.invalidDate', 'Invalid date!'),
                params: { startDate: this.state.event.startDate, endDate: this.state.event.endDate }
              }
            ]}
            dateOnly={true}
          />
        </div>
        <div className="col-sm-3">
          <DateTimePickerComponent
            date={this.state.event.raffle?.raffleEnd}
            minDate={this.state.event.raffle?.raffleStart}
            maxDate={this.state.event.endDate}
            label={msg('event.raffleEndDate', 'Raffle end date')}
            getSelectedDate={this.getEventDate('raffle.raffleEnd')}
            disabled={this.props.editable}
            formatError={this.props.validator!.errors.raffleEnd}
            ref="raffleEnd"
            validator={[
              {
                type: 'customDatePicker',
                msg: msg('formValidation.invalidDate', 'Invalid date!'),
                params: { startDate: this.state.event.startDate, endDate: this.state.event.endDate }
              }
            ]}
            dateOnly={true}
          />
        </div>
      </div>

      <hr style={customStyles.separator} />
      <div className="row">
        <legend style={customStyles.legend}>{msg('event.raffleSchedule', 'Raffle schedule:')}</legend>
      </div>
      <PlaceScheduleComponent
        onChange={this.onScheduleChange}
        schedule={this.state.event.raffle?.schedule}
        forEvent={true}
        disabled={this.props.editable}
      />
    </>
  );

  renderRaffleConfig = () => (
    <>
      <hr style={customStyles.separator} />
      <div className="row">
        <legend style={{ marginBottom: 5, marginLeft: 15 }}>
          {msg('event.raffleDistribution', 'Rewards distribution:')}
        </legend>
      </div>
      <div className="row">
        <div className="col-md-10 col-lg-8">
          <ButtonComponent
            label={msg('event.configureRaffle', 'Configure raffle')}
            icon="settings"
            action={this.toggleRaffleModal}
          />
          {this.state.raffleIntervals?.length && this.state.raffleChanged ? (
            <span style={{ margin: 10, color: colors.red }}>
              {msg('event.configurationReset', 'Your configuration has been reset!')}
            </span>
          ) : null}
        </div>
      </div>
    </>
  );

  renderRewards = () => {
    const { event, voucherConfig } = this.state;
    const showVoucherConfig =
      this.checkValidRewards() && event.rewards.findIndex(item => item.type === RewardType.VOUCHER) > -1;
    const minimumAmount = Math.min(...event.rewards.map(item => item.amount || Number.MAX_VALUE));
    return (
      <>
        <hr style={customStyles.separator} />
        <div className="row">
          <legend style={customStyles.legend}>
            {msg('event.defineReward', 'Define the reward for the promotional material:')}
          </legend>
        </div>
        {event.rewards.map((reward, index) => (
          <div
            className="row"
            style={{ marginBottom: 10, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
            key={index}
          >
            {event.participantsType === ParticipantsType.RANDOM && (
              <>
                <div className="col-sm-2 col-lg-1" style={{ minWidth: 150 }}>
                  <TextFieldComponent
                    id="total"
                    value={reward?.total ? reward.total + '' : ''}
                    onChange={this.handleRewardChange(index)}
                    inType="text"
                    required={false}
                    placeholder={msg('event.rewardTotal', 'Number of rewards')}
                    readOnly={this.props.editable}
                    formatError={this.props.validator!.errors[`total${index}`]}
                    ref={`total${index}`}
                    validator={[
                      {
                        type: 'isNumeric',
                        msg: msg('formValidation.onlyNumbers', 'Use only numbers!')
                      },
                      {
                        type: 'isInt',
                        msg: msg('formValidation.invalidAmount', 'Invalid amount!'),
                        params: { min: 1 }
                      }
                    ]}
                  />
                </div>
                X
              </>
            )}
            <div className="col-sm-6 col-md-3" style={{ minWidth: 250 }}>
              <RewardPictureInput
                media={reward.type === RewardType.VOUCHER ? voucherIcon : reward?.media?.url || null}
                placeId={this.props.place?._id}
                id="rewardTitle"
                value={reward?.title}
                onChange={this.handleRewardChange(index)}
                uploadPicture={this.uploadPicture(index)}
                marginPicture={8}
                formatError={this.props.validator!.errors[`rewardTitle${index}`]}
                ref={`rewardTitle${index}`}
                validator={[
                  {
                    type: 'isLength',
                    params: { min: 2 },
                    msg: msg('formValidation.inputLength', 'Input too short!')
                  },
                  {
                    type: 'isLength',
                    msg: msg('formValidation.fieldRequired', 'Field required!'),
                    params: { min: 1 }
                  }
                ]}
                readOnly={this.props.editable || reward?.type === RewardType.VOUCHER}
              />
            </div>
            <div className="col-sm-6 col-md-2">
              <SimpleSelectComponent
                name="type"
                options={[
                  { _id: 0, name: msg('rewardTypes.discount', 'Discount') },
                  { _id: 1, name: msg('rewardTypes.product', 'Product') },
                  { _id: 2, name: msg('rewardTypes.voucher', 'Voucher') }
                ]}
                id="type"
                value={reward?.type !== undefined ? reward?.type + '' : ''}
                onChange={this.handleRewardChange(index)}
                required={false}
                needsAllLabel={false}
                placeholder={msg('reward.typePlaceholder', 'Select type')}
                arrayOptions={false}
                readOnly={this.props.editable}
                formatError={this.props.validator!.errors[`type${index}`]}
                ref={`type${index}`}
                validator={[
                  {
                    type: 'checkIfSelected',
                    params: reward?.type,
                    msg: msg('formValidation.fieldRequired', 'Field required!')
                  }
                ]}
              />
            </div>
            {reward?.type === RewardType.VOUCHER && (
              <div className="col-sm-6 col-md-2 col-lg-1" style={{ minWidth: 150 }}>
                <TextFieldComponent
                  id="amount"
                  value={reward?.amount ? reward.amount + '' : ''}
                  onChange={this.handleRewardChange(index)}
                  inType="text"
                  required={false}
                  placeholder={msg('reward.valuePlaceholder', 'RON Amount')}
                  readOnly={this.props.editable}
                  formatError={this.props.validator!.errors[`amount${index}`]}
                  ref={`amount${index}`}
                  validator={[
                    {
                      type: 'isNumeric',
                      msg: msg('formValidation.onlyNumbers', 'Use only numbers!')
                    },
                    {
                      type: 'isInt',
                      msg: msg('formValidation.invalidAmount', 'Invalid amount!'),
                      params: { min: 10 }
                    }
                  ]}
                />
              </div>
            )}
            {!this.props.editable && event.participantsType === ParticipantsType.RANDOM && (
              <div style={{ marginTop: 8 }}>
                {index > 0 ? (
                  <div onClick={this.handleDeleteReward(index)} style={{ ...customStyles.icon, color: colors.red }}>
                    <i className="material-icons">delete</i>
                  </div>
                ) : (
                  <div onClick={this.handleAddReward} style={{ ...customStyles.icon, color: colors.green }}>
                    <i className="material-icons">add</i>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
        {event.participantsType === ParticipantsType.RANDOM && !!event.raffle?.totalPrizes && (
          <div className="row">
            <div className="col-sm-3">
              {msg('event.totalPrizes', 'Total prizes')} {event.raffle.totalPrizes}
            </div>
          </div>
        )}
        {showVoucherConfig && !this.props.place?.featureFlags.integration && (
          <>
            <hr style={customStyles.separator} />
            <div className="row">
              <legend style={customStyles.legend}>
                {msg('reward.partialRedeem', 'Can the voucher be patially redeemed?')}
              </legend>
            </div>
            <div className="row">
              <div className="col-sm-2">
                <RadioGroup
                  name="partialRedeem"
                  onChange={this.handleVoucherConfigChange}
                  value={voucherConfig.partialRedeem ?? ''}
                  row={true}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <FormControlLabel
                    disabled={this.props.editable}
                    value={false}
                    control={<Radio color="primary" />}
                    label={msg('general.no', 'No')}
                  />
                  <FormControlLabel
                    disabled={this.props.editable}
                    value={true}
                    control={<Radio color="primary" />}
                    label={msg('general.yes', 'Yes')}
                  />
                </RadioGroup>
              </div>
            </div>
            {voucherConfig.partialRedeem && (
              <>
                <hr style={customStyles.separator} />
                <div className="row">
                  <legend style={customStyles.legend}>
                    {msg('reward.minimumRedeem', 'Minimum amount to be redeemed')}
                  </legend>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <TextFieldComponent
                      id="minimumAmount"
                      value={voucherConfig.minimumAmount ? voucherConfig.minimumAmount + '' : ''}
                      onChange={this.handleVoucherConfigChange}
                      inType="text"
                      required={false}
                      readOnly={this.props.editable}
                      placeholder={msg('reward.valuePlaceholder', 'RON Value')}
                      formatError={this.props.validator!.errors.minimumAmount}
                      ref="minimumAmount"
                      validator={[
                        {
                          type: 'isNumeric',
                          msg: msg('formValidation.onlyNumbers', 'Use only numbers!')
                        },
                        {
                          type: 'isInt',
                          msg: msg('formValidation.invalidAmount', 'Invalid amount!'),
                          params: { min: 10, max: minimumAmount }
                        }
                      ]}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  };

  renderNotifications = () => (
    <>
      <hr style={customStyles.separator} />
      <div className="row">
        <legend style={customStyles.legend}>
          {msg('event.nativeNotifications', 'Do you want to send mobile notifications?')}
        </legend>
      </div>
      <div className="row">
        <div className="col-sm-3 col-md-2">
          <RadioGroup
            name="notifications"
            onChange={this.handleSendNotifications}
            value={this.state.event.nativeNotifications + ''}
            row={true}
            style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
          >
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label={msg('general.no', 'No')}
              disabled={this.props.editable}
            />
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label={msg('general.yes', 'Yes')}
              disabled={this.props.editable}
            />
          </RadioGroup>
        </div>
      </div>
    </>
  );

  renderDispatch = () => (
    <>
      <hr style={customStyles.separator} />
      <div className="row">
        <legend style={customStyles.legend}>{msg('event.dispatch', 'Choose the dispatch date:')}</legend>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <DateTimePickerComponent
            date={this.state.event.dispatch}
            label=""
            getSelectedDate={this.getEventDate('dispatch')}
            minDate={moment(this.state.event.startDate).subtract(7, 'd')}
            maxDate={this.state.event.endDate}
            disablePast={true}
            disabled={this.state.event.sent}
            formatError={this.props.validator!.errors.dispatch}
            ref="dispatch"
            validator={[
              {
                type: 'customDatePicker',
                msg: msg('formValidation.invalidDate', 'Invalid date!'),
                params: { startDate: this.state.event.dispatch, endDate: this.state.event.endDate }
              }
            ]}
          />
        </div>
      </div>
    </>
  );

  render() {
    const event = this.state.event;
    const place = this.props.place;
    const contentSet = !!(event.title && event.content.replace('<p>', '').replace('</p>', '').replace('<br>', ''));
    const placeSet =
      event.for === EventForType.CURRENT || (event.for === EventForType.PARTNERS && !!event.places?.length);
    const raffleScheduleSet = this.checkValidSchedule();
    const rewardsSet = this.checkValidRewards() && this.checkValidVoucher();
    return (
      <div>
        {this.state.showMemberModal && !this.props.editable && (
          <SelectMembersModal
            members={this.props.members_list}
            eventMembers={event.memberIds || []}
            pending={this.props.members_list_pending}
            error={this.props.members_list_error}
            placeId={place?._id || ''}
            getMembers={this.props.getMembers}
            toggleModal={this.toggleMembersModal}
            setCustomMembers={this.setCustomMembers}
          />
        )}
        {this.state.showRaffleModal && (
          <RaffleModal
            placeId={place?._id || ''}
            eventId={event._id || ''}
            raffle_preview={
              this.state.raffleIntervals?.length && !this.state.raffleChanged
                ? this.state.raffleIntervals
                : this.props.raffle_preview
            }
            raffle_preview_pending={this.props.raffle_preview_pending}
            update_raffle_preview_pending={this.props.update_raffle_preview_pending}
            totalPrizes={event.raffle?.totalPrizes}
            editMode={!!this.props.editable}
            getRaffleIntervals={this.props.getRaffleIntervals}
            toggleModal={this.toggleRaffleModal}
            handleSave={this.saveRafflePreview}
          />
        )}

        {this.renderType()}
        {this.renderContent()}
        {contentSet && (
          <>
            {this.renderCover()}
            {this.renderDates()}
            {event.type !== EventType.PROMOTION ? (
              <>
                {this.props.place?.featureFlags.nativeNotifications && this.renderNotifications()}
                {this.renderDispatch()}
                {this.renderSaveButton()}
              </>
            ) : (
              <>
                {this.props.allowMultiplePlaces && this.renderPlaces()}
                {placeSet && (
                  <>
                    {this.renderParticipantsType()}
                    {event.participantsType !== ParticipantsType.RANDOM ? (
                      <>
                        {this.renderRewards()}
                        {rewardsSet && (
                          <>
                            {place?.featureFlags.nativeNotifications && this.renderNotifications()}
                            {this.renderDispatch()}
                            {this.renderSaveButton()}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {this.renderRaffleSchedule()}
                        {raffleScheduleSet && (
                          <>
                            {this.renderRewards()}
                            {rewardsSet && (
                              <>
                                {this.renderRaffleConfig()}
                                {place?.featureFlags.nativeNotifications && this.renderNotifications()}
                                {this.renderDispatch()}
                                {this.renderSaveButton()}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        <div className="row">
          <div className="col-md-6 category form-category">
            <small>*</small>
            {msg('errors.fieldsRequired', 'Required fields')}
          </div>
        </div>
      </div>
    );
  }

  private isDispatchDayValid() {
    const dispatchDay = moment(this.state.event.dispatch).startOf('day').toISOString();

    return this.props.unavailableMomentsDays.find(day => day === dispatchDay) === undefined;
  }

  private renderSaveButton() {
    const canSave = this.isDispatchDayValid() || this.props.editable;
    return (
      <div className="text-center mt-8">
        <Tooltip
          open={!canSave}
          title={
            !canSave &&
            msg('reduxMessages.events.packageLimitReached', 'The package limit for this day has been reached')
          }
        >
          <span>
            <ButtonComponent
              label={this.props.editable ? msg('general.save', 'Save') : msg('general.create', 'Create')}
              icon="event_available"
              action={this.saveHandler}
              pending={this.props.pending}
              disabled={!this.state.isDirty || !canSave}
            />
          </span>
        </Tooltip>
      </div>
    );
  }
}

export default Validator(EventFormComponent);

const customStyles = {
  separator: { marginTop: 10 },
  legend: { marginBottom: 15, marginLeft: 15 },
  icon: { padding: 6, margin: 0, cursor: 'pointer' }
};
