import { clone, isEqual } from 'lodash';
import React from 'react';

import { IntervalDTO, IPackage, PlaceDTO } from '../../../../../appRedux/types';
import galleryPlaceholder from '../../../../../assets/images/galleryPlaceholder.jpg';
import {
    AlertUtil, getAddressFromGeosuggest, getFormattedAddress, IValidator, msg, Validator
} from '../../../../../utils';
import {
    ButtonComponent, GeoSuggest, GoogleMapsComponent, SimpleSelectComponent, TextFieldComponent,
    UploadComponent
} from '../../../../shared';
import PlaceScheduleComponent from './PlaceScheduleComponent';

interface IProps {
  place?: PlaceDTO | null;
  save?: (place: any) => void;
  pending?: boolean;
  output?: boolean;
  onChange?: (place: any) => void;
  forSAdmin: boolean;
  onboard?: boolean;
  availablePackages?: IPackage[];
  fetchAvailablePackages?: () => any;
  changePlacePackage?: (place: PlaceDTO) => any;
  showIndustry?: boolean;
  industries?: any;
  extraId?: string;
  photos?: { url: string; handleUpload: any };
}
interface IState {
  place: PlaceDTO;
  isDirty: boolean;
  valid: boolean;
  additionalData: any;
}

class PlaceFormComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      place: props.place || new PlaceDTO(),
      isDirty: false,
      valid: true,
      additionalData: ''
    };
  }

  componentDidMount() {
    if (this.props.forSAdmin && this.props.fetchAvailablePackages) {
      this.props.fetchAvailablePackages();
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.place && !isEqual(prevProps.place, this.props.place)) {
      this.setState({ place: this.props.place });
    }
  }

  saveHandler = () => {
    const { place } = this.state;
    const { save, validator } = this.props;

    if (validator) {
      const { isValid } = validator!;
      if (isValid && isValid() && save && this.checkValidSchedule()) {
        save(place);
        this.setState({ isDirty: false });
      } else if (!this.checkValidSchedule()) {
        AlertUtil.simple(msg('place.scheduleWarning', 'The schedule has invalid intervals!'), 'warning');
      }
    }
  };

  handleChange = (event: any) => {
    const fieldKey = event.target.getAttribute('id');
    const fieldValue = event.target.value;

    const place = this.state.place;
    if (this.props.validator) {
      const { isDirty } = this.props.validator!;
      if (isDirty) {
        isDirty(fieldKey);
      }
    }

    if (fieldKey.indexOf('.') >= 0) {
      const fields = fieldKey.split('.');
      if (fields[0] === 'links') {
        place.links[fields[1]] = fieldValue;
      }
    } else {
      place[fieldKey] = fieldValue;
    }

    if (fieldKey === 'address' && place.geolocation) {
      place.geolocation = null;
    }

    this.setState({ place, isDirty: true });
    if (this.props.output && this.props.onChange) {
      this.props.onChange(place);
    }
  };

  handleSchedule = (dayIndex: number, intervalIndex?: number, period?: string, type?: string) => (event: any) => {
    let { place } = this.state;
    for (let key in place.schedule) {
      if (Number(key) === dayIndex) {
        if (type === 'start' || type === 'end') {
          place.schedule[key].intervals[intervalIndex][`${type}${period}`] = event.target.value;
        } else if (event.target.checked || !event.target.checked) {
          place.schedule[key].opened = event.target.checked;
        }
      }
    }
    this.setState({ place, isDirty: true });
  };

  addScheduleInterval = (dayIndex: number) => () => {
    const place = this.state.place;
    if (place.schedule) {
      const intervals = place.schedule[dayIndex].intervals;
      const previousInterval = intervals[intervals.length - 1];
      const newInterval = {
        startHour: previousInterval.endHour,
        startMinutes: 0,
        endHour: (previousInterval.endHour + 1) % 24,
        endMinutes: 0
      };
      place.schedule[dayIndex].intervals.push(new IntervalDTO(newInterval));
      this.setState({ place, isDirty: true });
    }
  };

  deleteScheduleInterval = (dayIndex: number, intervalIndex: number) => () => {
    const place = this.state.place;
    if (place.schedule) {
      place.schedule[dayIndex].intervals.splice(intervalIndex, 1);
      this.setState({ place, isDirty: true });
    }
  };

  checkValidSchedule = () => {
    const schedule = this.state.place.schedule;
    const invalidIntervals = schedule?.some(day => {
      return day.intervals.some((interval, index) => {
        const previousInterval = day.intervals[index - 1];
        return (
          previousInterval &&
          (previousInterval.endHour > interval.startHour ||
            (previousInterval.endHour === interval.startHour && previousInterval.endMinutes >= interval.startMinutes))
        );
      });
    });
    return !invalidIntervals;
  };

  setPlace = (lngCoords: any, latCoords: any, address: string, city: string) => {
    const place = clone(this.state.place);
    place.geolocation = {
      type: 'Point',
      coordinates: [lngCoords, latCoords]
    };
    if (this.props.validator) {
      const { isDirty } = this.props.validator!;
      if (isDirty) {
        isDirty('address');
        isDirty('city');
      }
    }
    place.address = address;
    place.city = city;
    return place;
  };

  suggestHandler = async (event?: any, additionalData?: any, locationInfo?: any) => {
    const { lat, lng } = locationInfo.location;
    const response = getAddressFromGeosuggest(locationInfo.gmaps.address_components);
    let address = '';
    if (response.route) {
      address = response.route;
      if (response.street_number) {
        address = `${response.route} ${response.street_number}`;
      }
    }
    const place = this.setPlace(lng, lat, address, response.locality);
    this.setState({ place, additionalData, isDirty: true });
    if (this.props.output && this.props.onChange) {
      this.props.onChange(place);
    }
  };

  suggestBlur = () => {
    const place = this.state.place;
    if (!place.geolocation || !place.geolocation.coordinates || place.geolocation.coordinates.length === 0) {
      place.address = '';
      this.setState({ place });
    }
  };

  addressHandler = (address: any) => {
    const place = this.state.place;
    place.address = address;
    this.setState({ place, isDirty: true });
  };

  coordsHandler = async (event: any) => {
    const latCoords = event.latLng.lat();
    const lngCoords = event.latLng.lng();
    const response = await getFormattedAddress(latCoords, lngCoords);
    let address = '';

    if (response.route) {
      address = response.route;
      if (response.street_number) {
        address = `${response.route} ${response.street_number}`;
      }
    }
    const place = this.setPlace(lngCoords, latCoords, address, response.locality);
    this.setState({ place, isDirty: true });
    if (this.props.output && this.props.onChange) {
      this.props.onChange(place);
    }
  };

  handleMapUpload = (res: any) => {
    const { place } = this.state;
    if (this.props.photos) {
      this.props.photos.handleUpload(res.data, 'MAP');
    }
    place.currentMapPhoto = res.data[0].url;
    this.setState({ place });
  };

  render() {
    const { place } = this.state;
    const extraId = this.props.extraId ? this.props.extraId : place._id;
    const url = this.props.photos?.url || (place?._id ? `media/place/${place._id}` : 'admin/media');
    const schedule = place.schedule;
    const { errors } = this.props.validator!;
    return (
      <div>
        <div className="col-sm-12">
          <legend style={{ marginTop: 15, marginBottom: 8 }}>{msg('place.placeInfo', 'Place Information')}</legend>
          <div className="row">
            <div className="col-sm-1" />
            <div className="col-sm-5">
              <TextFieldComponent
                label={msg('place.name', 'Name')}
                id="name"
                value={place.name}
                onChange={this.handleChange}
                inType="text"
                minLength={1}
                maxLength={50}
                required={true}
                formatError={errors.name}
                ref="name"
                validator={[
                  { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } },
                  {
                    type: 'isLength',
                    msg: msg('formValidation.fieldRequired', 'Field required!'),
                    params: { min: 1 }
                  }
                ]}
              />
            </div>
            <div className="col-sm-5">
              <TextFieldComponent
                label={msg('place.phone', 'Phone')}
                id="phone"
                value={place.phone}
                onChange={this.handleChange}
                inType="text"
                // required={true}
                // formatError={errors.phone}
                ref="phone"
                // validator={[
                //   {
                //     type: 'isNumeric',
                //     msg: msg('formValidation.onlyNumbersPhone', 'A phone must contain only numbers!')
                //   }
                //   {
                //     type: 'isLength',
                //     msg: msg('formValidation.fieldRequired', 'Field required!'),
                //     params: { min: 1 }
                //   }
                // ]}
              />
            </div>
            <div className="col-sm-1" />
          </div>
          <div className="row">
            <div className="col-sm-1" />
            <div className="col-sm-10">
              <TextFieldComponent
                label={msg('place.description', 'Description')}
                id="description"
                rows={6}
                value={place.description}
                onChange={this.handleChange}
                inType="textarea"
                required={false}
                formatError=""
              />
            </div>
            <div className="col-sm-1" />
          </div>
          <div className="row">
            <div className="col-sm-1" />
            {!place?.featureFlags?.alternativeAddress ? (
              <div className="col-sm-5">
                <GeoSuggest
                  label={msg('place.address', 'Address')}
                  setValues={this.suggestHandler}
                  onChange={this.addressHandler}
                  onBlur={this.suggestBlur}
                  initialValue={place.address}
                  value={place.address}
                  id="address"
                  formatError={errors.address}
                  ref="address"
                  validator={[
                    {
                      type: 'isLength',
                      msg: msg('formValidation.fieldRequired', 'Field required!'),
                      params: { min: 1 }
                    }
                  ]}
                />
              </div>
            ) : (
              <div className="col-sm-5">
                <TextFieldComponent
                  label={msg('place.address', 'Address')}
                  id="address"
                  value={place.address ? place.address : ''}
                  onChange={this.handleChange}
                  inType="text"
                  minLength={1}
                  maxLength={30}
                  formatError={errors.address}
                  ref="address"
                  validator={[
                    {
                      type: 'isLength',
                      msg: msg('formValidation.fieldRequired', 'Field required!'),
                      params: { min: 1 }
                    }
                  ]}
                />
              </div>
            )}
            <div className="col-sm-5">
              <TextFieldComponent
                label={msg('place.city', 'City')}
                id="city"
                value={place.city ? place.city : ''}
                onChange={this.handleChange}
                inType="text"
                minLength={1}
                maxLength={30}
                required={true}
                formatError={errors.city}
                ref="city"
                validator={[
                  {
                    type: 'isLength',
                    msg: msg('formValidation.fieldRequired', 'Field required!'),
                    params: { min: 1 }
                  }
                ]}
              />
            </div>
            <div className="col-sm-1" />
          </div>
          {!place?.featureFlags?.alternativeAddress ? (
            place.geolocation &&
            place.geolocation.coordinates.length &&
            place.geolocation.coordinates[1] !== 0 &&
            place.geolocation.coordinates[0] !== 0 ? (
              <div className="row">
                <div className="col-sm-1" />
                <div className="col-sm-10">
                  <GoogleMapsComponent
                    containerStyle={{ height: 400 }}
                    defaultCenter={{
                      lat: place.geolocation.coordinates[1],
                      lng: place.geolocation.coordinates[0]
                    }}
                    lat={place.geolocation.coordinates[1]}
                    lng={place.geolocation.coordinates[0]}
                    onClick={this.coordsHandler}
                    onDragEnd={this.coordsHandler}
                  />
                </div>
                <div className="col-sm-1" />
              </div>
            ) : null
          ) : (
            <div>
              <div className="row">
                <div className="row" style={{ textAlign: 'center', marginTop: 10 }}>
                  <legend>{msg('place.mapPhoto', 'Map Photo')}</legend>
                </div>
                <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', maxWidth: 575 }}>
                  {place.currentMapPhoto ? (
                    <div
                      className="map-image image-ratio-cover"
                      style={{
                        background: `url(${encodeURI(place.currentMapPhoto)})`
                      }}
                    />
                  ) : (
                    <div className="map-image image-ratio-map" style={{ background: `url(${galleryPlaceholder})` }} />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="flex justify-center">
                  <UploadComponent
                    url={`${url}?type=MAP`}
                    accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
                    extraData={{ extraId }}
                    messages={{
                      default: msg('general.uploadImage', 'Upload images by drag-and-drop or click!')
                    }}
                    onSuccess={res => {
                      this.handleMapUpload(res);
                    }}
                    crop={true}
                    cropAspect={9 / 5}
                  />
                </div>
              </div>
            </div>
          )}
          {!place.featureFlags?.manualCheckout && schedule && (
            <>
              <legend style={{ marginTop: 15, marginBottom: 8 }}>{msg('place.placeSchedule', 'Schedule')}</legend>
              <PlaceScheduleComponent
                onChange={this.handleSchedule}
                addInterval={this.addScheduleInterval}
                deleteInterval={this.deleteScheduleInterval}
                schedule={schedule}
              />
            </>
          )}
          {place.links && (
            <>
              <legend style={{ margin: '15px 0' }}>{msg('socialMedia.legend', 'Interact with place')}</legend>
              <div>
                {Object.keys(place.links)
                  .reduce((groupedLinks: any, value, index, array) => {
                    if (!(index % 2)) {
                      groupedLinks.push(array.slice(index, index + 2));
                    }
                    return groupedLinks;
                    // tslint:disable-next-line: align
                  }, [])
                  .map((linkPair: any, index: number) => {
                    return (
                      <div key={index} className="row">
                        <div className="col-sm-1" />
                        {linkPair.map((link: string, linkIndex: number) => (
                          <div key={linkIndex} className="col-sm-5">
                            <TextFieldComponent
                              label={msg(`socialMedia.${link}`, link)}
                              id={`links.${link}`}
                              inType="text"
                              value={place.links[link] || ''}
                              onChange={this.handleChange}
                              required={false}
                              formatError={errors[`links.${link}`]}
                              ref={`links.${link}`}
                              validatorIgnore={!place.links[link]}
                              validator={
                                link !== 'phoneNumber' && link !== 'whatsapp'
                                  ? [
                                      {
                                        type: 'isURL',
                                        msg: msg(
                                          'formValidation.urlProtocolRequired',
                                          'URL must begin with "http://"!'
                                        ),
                                        params: { protocols: ['http', 'https'], require_protocol: true }
                                      }
                                    ]
                                  : null
                              }
                            />
                          </div>
                        ))}
                        <div className="col-sm-1" />
                      </div>
                    );
                  })}
              </div>
            </>
          )}
          {this.props.showIndustry && (
            <div style={{ marginBottom: 15 }}>
              <legend className="mt-4 mb-2">{msg('industry.selectIndustry', 'Select industry')}</legend>
              <div className="row">
                <div className="col-sm-3 col-sm-offset-1">
                  <SimpleSelectComponent
                    label={msg('industry.name', 'Industry name')}
                    name="industry"
                    options={Array.isArray(this.props.industries) ? this.props.industries : []}
                    id="industry"
                    value={place.industry || ''}
                    onChange={this.handleChangeSelect}
                    required={true}
                    needsAllLabel={false}
                    placeholder={msg('place.typePlaceholder', 'Select industry')}
                    arrayOptions={false}
                    shrinkLabel={true}
                    formatError={errors.industry}
                    ref="industry"
                    validator={[
                      {
                        type: 'isLength',
                        msg: msg('formValidation.fieldRequired', 'Field required!'),
                        params: { min: 1 }
                      }
                    ]}
                  />
                </div>
              </div>
            </div>
          )}
          {this.props.forSAdmin && (
            <>
              <legend className="mt-4 mb-2">{msg('placeSettings.selectPackage', 'Package')}</legend>
              <div className="row">
                <div className="col-sm-3 col-sm-offset-1">
                  <SimpleSelectComponent
                    label={msg('placeSettings.selectPackage', 'Package')}
                    name="packageId"
                    options={this.props.availablePackages || []}
                    id="packageId"
                    value={place.packageId || ''}
                    onChange={this.handleChangeSelect}
                    required={true}
                    placeholder={msg('place.packagePlaceholder', 'Select package')}
                    needsAllLabel={false}
                    arrayOptions={false}
                    shrinkLabel={true}
                  />
                </div>
              </div>
            </>
          )}
          {this.props.forSAdmin && (
            <>
              <legend className="mt-4 mb-2">{msg('placeSettings.geoBoost', 'Geoboost')}</legend>
              <div className="row">
                <div className="col-sm-6 col-lg-3 col-sm-offset-1">
                  <TextFieldComponent
                    label={msg('placeSettings.boost', 'Boost')}
                    id="geoBoost"
                    value={(place.geoBoost ?? '') + ''}
                    onChange={this.handleChange}
                    required={false}
                    formatError={errors.geoBoost}
                    ref="geoBoost"
                    validatorIgnore={!place.geoBoost}
                    validator={[{ type: 'isNumeric', msg: msg('formValidation.onlyNumbers', 'Use only numbers!') }]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-3 col-sm-offset-1">
                  <TextFieldComponent
                    label={msg('placeSettings.geoMaxDistance', 'Maximum distance boost')}
                    id="geoMaxDistance"
                    value={(place.geoMaxDistance ?? '') + ''}
                    onChange={this.handleChange}
                    required={false}
                    formatError={errors.geoMaxDistance}
                    ref="geoMaxDistance"
                    validatorIgnore={!place.geoMaxDistance}
                    validator={[{ type: 'isNumeric', msg: msg('formValidation.onlyNumbers', 'Use only numbers!') }]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-3 col-sm-offset-1">
                  <TextFieldComponent
                    label={msg('placeSettings.geoClosedDistance', 'Closed distance increase')}
                    id="geoClosedDistance"
                    value={(place.geoClosedDistance ?? '') + ''}
                    onChange={this.handleChange}
                    required={false}
                    formatError={errors.geoClosedDistance}
                    ref="geoClosedDistance"
                    validatorIgnore={!place.geoClosedDistance}
                    validator={[{ type: 'isNumeric', msg: msg('formValidation.onlyNumbers', 'Use only numbers!') }]}
                  />
                </div>
              </div>
            </>
          )}
          {!this.props.output && (
            <div>
              {!this.props.onboard && (
                <div className="row" style={{ margin: 10 }}>
                  <div className="col-md-3" />
                  <div className="col-md-6" style={{ textAlign: 'center' }}>
                    <div className="form-group form-button">
                      <ButtonComponent
                        label={msg('general.save', 'Save')}
                        action={this.saveHandler}
                        pending={this.props.pending}
                        disabled={!this.state.isDirty}
                        icon="save"
                      />
                    </div>
                  </div>
                  <div className="col-md-3" />
                </div>
              )}
              <div className="row">
                <div className="col-md-6 category form-category">
                  <small>*</small>
                  {msg('errors.fieldsRequired', 'Required fields')}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  private handleChangeSelect = (event: any) => {
    this.handleChange({
      target: {
        getAttribute: () => event.target.name,
        value: event.target.value
      }
    });
  };
}

export default Validator(PlaceFormComponent);
