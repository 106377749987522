import { colors } from '../../../assets/styles';

export const styles: { [name: string]: React.CSSProperties } = {
  iconContainer: {
    height: 50,
    width: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconDone: {
    fontSize: 50,
    color: colors.green
  },
  iconError: {
    fontSize: 50,
    color: colors.red
  },
  iconOff: {
    fontSize: 50,
    color: colors.orange
  },
  iconUpload: {
    fontSize: 50
  },
  picture: {
    height: 50,
    width: 50,
    borderRadius: 10,
    objectFit: 'cover'
  }
};
