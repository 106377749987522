import React, { ChangeEvent, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Checkbox } from '@material-ui/core';

import { RewardDTO, RewardStocksDTO, TagDTO } from '../../../../appRedux/types';
import { getTagStylesForSelect, msg } from '../../../../utils';
import { ButtonComponent, MultipleSelectComponent, TextFieldComponent } from '../../../shared';

interface IProps {
  index: number;
  reward: RewardDTO;
  member_tags: TagDTO[];
  user_tags: TagDTO[];
  saveReward: (index: number, reward: RewardDTO) => void;
  toggleModal: () => void;
}

const RewardSettingsComponent = (props: IProps) => {
  const [currentReward, setCurrentReward] = useState({
    ...props.reward,
    stocks: new RewardStocksDTO(props.reward.stocks)
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^\d+$/;
    if (!value || regex.test(value)) {
      const stocks = {
        ...currentReward.stocks,
        [event.target.getAttribute('id') + '']: value ? Number(value) : ''
      };
      setCurrentReward({ ...currentReward, stocks });
    }
  };

  const handleChangeCheckbox = (field: string) => () => {
    const stocks = {
      ...currentReward.stocks,
      [field]: !currentReward.stocks[field]
    };
    setCurrentReward({ ...currentReward, stocks });
  };

  const parseTagsForSelect = (tags: TagDTO[]) => {
    return tags?.map(tag => ({ label: tag.name, value: tag._id, color: tag.color }));
  };

  const changeRewardTags = (type: string) => (values: { label: string; value: string; color: string }[]) => {
    let targetTags: TagDTO[] = [];
    if (values) {
      values.forEach((item: { label: string; value: string; color: string }) => {
        const propsTags = type === 'userTags' ? props.user_tags : props.member_tags;
        const tag = propsTags.find(fullTag => fullTag._id === item.value);
        if (tag) {
          targetTags.push({ name: tag.name, _id: tag._id, color: tag.color, priority: tag.priority });
        }
      });
    }
    setCurrentReward({ ...currentReward, [type]: targetTags });
  };

  const saveReward = () => {
    props.saveReward(props.index, currentReward);
  };

  return (
    <Modal show={true} onHide={props.toggleModal} dialogClassName="memberDetailsModal">
      <Modal.Header>
        <Modal.Title>
          {msg('reward.advancedSettings', 'Advanced settings')} - <strong>{currentReward.title}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={styles.row}>
          <div className="col-sm-4 col-lg-2" style={{ padding: 0 }}>
            {msg('reward.dayStock', 'Stock per day')}:
          </div>
          <div className="col-sm-6 col-lg-3" style={{ padding: 0 }}>
            <Checkbox
              checked={!!currentReward.stocks.dayUnlimited}
              onChange={handleChangeCheckbox('dayUnlimited')}
              color="primary"
            />
            {msg('reward.unlimited', 'unlimited')}
          </div>
          {!currentReward.stocks.dayUnlimited ? (
            <div className="col-sm-2 col-md-3">
              <TextFieldComponent
                id="dayStock"
                value={(currentReward.stocks?.dayStock ?? '') + ''}
                onChange={handleChange}
                minLength={1}
                style={styles.input}
              />
            </div>
          ) : (
            <div className="col-sm-2 col-md-3" style={{ height: 58 }} />
          )}
          <div className="col-sm-4">
            {msg('reward.bought', 'Bought')}: {currentReward.stocks?.dayCount || 0}
          </div>
        </div>
        <div style={styles.row}>
          <div className="col-sm-4 col-lg-2" style={{ padding: 0 }}>
            {msg('reward.weekStock', 'Stock per week')}:
          </div>
          <div className="col-sm-6 col-lg-3" style={{ padding: 0 }}>
            <Checkbox
              checked={!!currentReward.stocks.weekUnlimited}
              onChange={handleChangeCheckbox('weekUnlimited')}
              color="primary"
            />
            {msg('reward.unlimited', 'unlimited')}
          </div>
          {!currentReward.stocks.weekUnlimited ? (
            <div className="col-sm-2 col-md-3">
              <TextFieldComponent
                id="weekStock"
                value={(currentReward.stocks?.weekStock ?? '') + ''}
                onChange={handleChange}
                minLength={1}
                style={styles.input}
              />
            </div>
          ) : (
            <div className="col-sm-2 col-md-3" style={{ height: 58 }} />
          )}
          <div className="col-sm-4">
            {msg('reward.bought', 'Bought')}: {currentReward.stocks?.weekCount || 0}
          </div>
        </div>
        <div style={styles.row}>
          <div className="col-sm-4 col-lg-2" style={{ padding: 0 }}>
            {msg('reward.monthStock', 'Stock per month')}:
          </div>
          <div className="col-sm-6 col-lg-3" style={{ padding: 0 }}>
            <Checkbox
              checked={!!currentReward.stocks.monthUnlimited}
              onChange={handleChangeCheckbox('monthUnlimited')}
              color="primary"
            />
            {msg('reward.unlimited', 'unlimited')}
          </div>
          {!currentReward.stocks.monthUnlimited ? (
            <div className="col-sm-2 col-md-3">
              <TextFieldComponent
                id="monthStock"
                value={(currentReward.stocks?.monthStock ?? '') + ''}
                onChange={handleChange}
                minLength={1}
                style={styles.input}
              />
            </div>
          ) : (
            <div className="col-sm-2 col-md-3" style={{ height: 58 }} />
          )}
          <div className="col-sm-4">
            {msg('reward.bought', 'Bought')}: {currentReward.stocks?.monthCount || 0}
          </div>
        </div>
        {!!props.member_tags?.length && (
          <div style={{ ...styles.row, marginBottom: 30, marginTop: 10 }}>
            <div>{msg('tags.memberTags', 'Member Tags')}:</div>
            <div className="col-md-9">
              <MultipleSelectComponent
                label={msg('tags.memberTags', 'Member Tags')}
                options={parseTagsForSelect(props.member_tags)}
                id="memberTags"
                value={parseTagsForSelect(currentReward.memberTags || [])}
                onChange={changeRewardTags('memberTags')}
                style={{ marginTop: 0, minWidth: 100 }}
                selectStyle={getTagStylesForSelect()}
              />
            </div>
          </div>
        )}
        {!!props.user_tags?.length && (
          <div style={{ ...styles.row, marginBottom: 30, marginTop: 10 }}>
            <div>{msg('tags.userTags', 'User Tags')}:</div>
            <div className="col-md-9">
              <MultipleSelectComponent
                label={msg('tags.userTags', 'User Tags')}
                options={parseTagsForSelect(props.user_tags)}
                id="userTags"
                value={parseTagsForSelect(currentReward.userTags || [])}
                onChange={changeRewardTags('userTags')}
                style={{ marginTop: 0, minWidth: 100 }}
                selectStyle={getTagStylesForSelect()}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonComponent
          styles={{ marginTop: 0 }}
          label={msg('reward.confirm', 'OK')}
          icon="done"
          action={saveReward}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default RewardSettingsComponent;

const styles = {
  row: {
    display: 'flex',
    marginLeft: 10,
    alignItems: 'center',
    fontSize: 14
  },
  inputRow: {
    display: 'flex',
    padding: 0,
    alignItems: 'center'
  },
  input: {
    width: 80,
    position: 'relative',
    top: -2
  }
};
