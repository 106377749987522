import * as React from 'react';
import {
  TextFieldComponent,
  SimpleSelectComponent,
  ButtonComponent,
  CardComponent,
  RewardPictureInput
} from '../../shared';
import { msg, Validator, IValidator } from '../../../utils';
import Toggle from 'react-toggle';
import simpleAutopilotsUtils from './simpleAutopilotsUtils';
import { AutopilotDTO, PlaceDTO } from '../../../appRedux/types';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { AutopilotTriggerType, AutopilotActionType } from './simpleAutopilotsSchema';

interface IProps {
  config: {
    place: PlaceDTO | null;
    autopilot_list: any[];
    autopilot_list_pending: boolean;
    autopilot_list_error: string | null;
    selected_place: any;
    selected_partner: any;
    all_simple_autopilots_save_success: boolean;
    all_simple_autopilots_save_pending: boolean;
    all_simple_autopilots_save_error: string | null;
    getAutopilotListForPlaceAction?: (placeId: string, partnerId: string) => any;
    updateAllSimpleAutopilotsRuleForPlaceAction?: (autopilotRules: AutopilotDTO[], placeId: string) => any;
  };
  showStatistics: boolean;
  showCheck: boolean;
}

interface IState {
  autopilots: any[];
  saveButton: boolean;
  showStats: boolean;
  showMessages: boolean;
  showTips: boolean;
}

class SimpleAutopilotsComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      autopilots: [],
      saveButton: true,
      showStats: false,
      showMessages: false,
      showTips: false
    };
  }

  componentDidMount() {
    const { getAutopilotListForPlaceAction, selected_place, selected_partner } = this.props.config;
    if (getAutopilotListForPlaceAction) {
      getAutopilotListForPlaceAction(selected_place, selected_partner);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.config.autopilot_list_pending &&
      !this.props.config.autopilot_list_pending &&
      this.props.config.autopilot_list
    ) {
      const disableCoins = !this.props.config.place?.featureFlags?.useCoins;
      this.setState({ autopilots: simpleAutopilotsUtils.getState(this.props.config.autopilot_list, disableCoins) });
    }

    if (
      prevProps.config.all_simple_autopilots_save_pending &&
      !this.props.config.all_simple_autopilots_save_pending &&
      this.props.config.all_simple_autopilots_save_success
    ) {
      const { autopilots } = this.state;
      autopilots.forEach((pilot: any, index: number) => {
        if (pilot && pilot.dirty) {
          autopilots[index].dirty = false;
        }
        if (pilot && pilot.updateAllRewards) {
          autopilots[index].updateAllRewards = false;
        }
      });
      this.setState({ autopilots, saveButton: true });
    }
  }

  handleChange = (type: string, index: number) => (event: any) => {
    const { autopilots } = this.state;
    let { value, checked } = event.target;
    switch (type) {
      case 'trigger&value':
        if (!isNaN(Number(value))) {
          autopilots[index].for.value = Number(value);
        } else {
          autopilots[index].for.value = value;
        }
        break;
      case 'trigger&type':
        autopilots[index].for.triggerType = value;
        break;
      case 'reward&value':
        autopilots[index].reward.value = value;
        break;
      case 'reward&message':
        if (!autopilots[index].reward.value) {
          autopilots[index].reward.value = {
            channels: [1]
          };
        }
        autopilots[index].reward.value.message = value;
        break;
      case 'reward&type':
        autopilots[index].reward.type = value;
        autopilots[index].reward.value = '';
        autopilots[index].reward.media = undefined;
        autopilots[index].updateAllRewards = false;
        if (autopilots[index].reward.type === AutopilotActionType.REWARD && !autopilots[index].expireSelected) {
          autopilots[index].expireSelected = 1;
        } else if (autopilots[index].reward.type !== AutopilotActionType.REWARD) {
          autopilots[index].expireSelected = 0;
        }
        break;
      case 'isActive':
        autopilots[index].isActive = checked;
        break;
      case 'reward&expires':
        autopilots[index].expireSelected = parseInt(value, 10);
        break;
      case 'updateAllRewards':
        autopilots[index].updateAllRewards = checked;
        break;
      case 'message':
        autopilots[index].reward.message = value;
        break;
      default:
        break;
    }
    const dirtyList = ['trigger&value', 'reward&value'];
    if (this.props.validator && dirtyList.indexOf(type) !== -1) {
      const fieldKey = event?.target?.id && event.target.getAttribute('id');
      const { isDirty } = this.props.validator!;
      if (isDirty) {
        isDirty(fieldKey);
      }
    }
    autopilots[index].dirty = true;
    this.setState({ autopilots, saveButton: false });
  };

  handleCheckbox = (type: string) => (event: any) => {
    switch (type) {
      case 'statistics':
        this.setState({ showStats: !!event.target.checked });
        break;
      case 'messages':
        this.setState({ showMessages: !!event.target.checked });
        break;
      case 'tips':
        this.setState({ showTips: !!event.target.checked });
        break;
      default:
        break;
    }
  };

  uploadPicture = (index: number) => (res: any) => {
    const { autopilots } = this.state;
    autopilots[index].dirty = true;
    if (res && res.data && res.data[0] && res.data[0].url) {
      autopilots[index].reward.media = res.data[0].url;
      this.setState({ autopilots, saveButton: false });
    }
  };

  saveHandler = () => {
    const { autopilots } = this.state;
    const { autopilot_list, selected_place } = this.props.config;
    const { isValid } = this.props.validator!;
    if (isValid()) {
      const server = simpleAutopilotsUtils.getServer(autopilots, autopilot_list);
      if (server.length) {
        const { updateAllSimpleAutopilotsRuleForPlaceAction } = this.props && this.props.config;
        if (updateAllSimpleAutopilotsRuleForPlaceAction) {
          updateAllSimpleAutopilotsRuleForPlaceAction(server, selected_place);
        }
      }
    } else {
      this.setState({ saveButton: true });
    }
  };

  getActionFieldAndValue = (autopilot: any, index: any) => {
    const { errors } = this.props.validator!;
    switch (autopilot.reward.type) {
      case AutopilotActionType.REWARD:
        return (
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
            <div style={{ width: '30%', paddingRight: '10px' }}>{this.getActionSelect(autopilot, index)}</div>
            <div style={{ width: '70%', paddingRight: '10px' }}>
              <RewardPictureInput
                media={autopilot.reward && autopilot.reward.media}
                placeId={autopilot.placeId}
                id={`action_reward_${index}`}
                value={autopilot.reward.value}
                onChange={this.handleChange('reward&value', index)}
                uploadPicture={this.uploadPicture(index)}
                marginPicture={7}
                formatError={errors[`action_reward_${index}`]}
                ref={`action_reward_${index}`}
                validatorIgnore={!autopilot.dirty}
                validator={[
                  {
                    type: 'isLength',
                    msg: msg('formValidation.inputLength', 'Input too short!'),
                    params: { min: 2 }
                  },
                  {
                    type: 'isLength',
                    msg: msg('formValidation.fieldRequired', 'Field required!'),
                    params: { min: 1 }
                  }
                ]}
              />
            </div>
          </div>
        );
      case AutopilotActionType.POINTS:
        return (
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row' }}>
            <div style={{ width: '30%', paddingRight: '10px' }}>{this.getActionSelect(autopilot, index)}</div>
            <div style={{ width: '30%', paddingRight: '10px' }}>
              <TextFieldComponent
                id={`action_number_${index}`}
                value={autopilot.reward.value}
                onChange={this.handleChange('reward&value', index)}
                inType="text"
                maxLength={40}
                placeholder={msg('simpleAutopilots.placeholderCoins', 'Number of coins')}
                readOnly={false}
                ref={`action_number_${index}`}
                formatError={errors[`action_number_${index}`]}
                validatorIgnore={!autopilot.dirty}
                validator={[
                  {
                    type: 'isInt',
                    msg: msg('formValidation.onlyPositiveNumbers', 'Use only positive numbers!'),
                    params: { min: 1, allow_leading_zeroes: false }
                  },
                  {
                    type: 'isLength',
                    msg: msg('formValidation.fieldRequired', 'Field required!'),
                    params: { min: 1 }
                  }
                ]}
              />
            </div>
          </div>
        );
      case AutopilotActionType.MESSAGE:
        return (
          <div style={{ width: '100%', paddingRight: '10px' }}>
            <TextFieldComponent
              id={`action_message_${index}`}
              value={autopilot.reward.value.message}
              onChange={this.handleChange('reward&message', index)}
              inType="text"
              readOnly={false}
              ref={`action_message_${index}`}
              placeholder={msg('simpleAutopilots.message-placeholder', 'Please write a message!')}
              formatError={errors[`action_message_${index}`]}
              validatorIgnore={!autopilot.dirty}
              validator={[
                { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } }
              ]}
            />
          </div>
        );
      default:
        return <div />;
    }
  };

  getActionSelect = (autopilot: any, index: number) => {
    const { errors } = this.props.validator!;
    const useCoins = this.props.config.place?.featureFlags?.useCoins;
    return (
      <SimpleSelectComponent
        name="reward_type"
        id={`action_type_${index}`}
        options={[
          { name: msg('simpleAutopilots.rewardTypeReward', 'Reward'), value: 0 },
          { name: msg('simpleAutopilots.rewardTypeCoins', 'Coins'), value: 1 }
        ]}
        value={autopilot.reward.type}
        onChange={this.handleChange('reward&type', index)}
        required={false}
        needsAllLabel={false}
        arrayOptions={true}
        hasNameValue={true}
        disabled={autopilot.type === 'birthday' || !useCoins}
        ref={`action_type_${index}`}
        formatError={errors[`action_type_${index}`]}
        validatorIgnore={!autopilot.dirty}
        validator={[
          {
            type: 'isInt',
            msg: msg('formValidation.fieldRequired', 'Field required!'),
            params: { min: 0, max: 1 }
          }
        ]}
      />
    );
  };

  getForFieldValue = (autopilot: any, index: number) => {
    const { errors } = this.props.validator!;
    switch (autopilot.for.type) {
      case AutopilotTriggerType.TEXT:
        return (
          <div>
            {autopilot.for.text}
            {autopilot.for.hasTooltip && (
              <Tooltip title={autopilot.for.tooltipMessage}>
                <i className="material-icons" style={{ marginLeft: 8, fontSize: 20 }}>
                  info
                </i>
              </Tooltip>
            )}
          </div>
        );
      case AutopilotTriggerType.DROPDOWN:
        return (
          <SimpleSelectComponent
            name="for"
            id={`trigger_dd_${index}`}
            options={autopilot.for.dd}
            value={autopilot.for.value}
            onChange={this.handleChange('trigger&value', index)}
            required={false}
            needsAllLabel={false}
            arrayOptions={true}
            hasNameValue={true}
            readOnly={false}
            validatorIgnore={!autopilot.dirty}
            formatError={errors[`trigger_dd_${index}`]}
          />
        );
      case AutopilotTriggerType.DROPDOWNTYPE:
        return (
          <SimpleSelectComponent
            name="for"
            id={`trigger_dd_${index}`}
            options={autopilot.for.dd}
            value={autopilot.for.triggerType}
            onChange={this.handleChange('trigger&type', index)}
            required={false}
            needsAllLabel={false}
            arrayOptions={true}
            hasNameValue={true}
            readOnly={false}
            validatorIgnore={!autopilot.dirty}
            formatError={errors[`trigger_dd_${index}`]}
          />
        );
      case AutopilotTriggerType.VALUE:
        return (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ marginRight: 7 }}>
              {autopilot.for && autopilot.for.textValue && autopilot.for.textValue.split('$c')[0]}
            </div>
            <div style={{ width: 25 }}>
              <TextFieldComponent
                id={`trigger_coins_${index}`}
                value={autopilot.for.value}
                onChange={this.handleChange('trigger&value', index)}
                inType="text"
                maxLength={3}
                readOnly={false}
                required={false}
                ref={`trigger_coins_${index}`}
                formatError={errors[`trigger_coins_${index}`]}
                validator={[
                  {
                    type: 'isInt',
                    msg: msg('formValidation.onlyPositiveNumbers', 'Use only positive numbers!'),
                    params: { min: 1 }
                  },
                  {
                    type: 'isLength',
                    msg: msg('formValidation.fieldRequired', 'Field required!'),
                    params: { min: 1 }
                  }
                ]}
                validatorIgnore={!autopilot.dirty}
              />
            </div>
            <div style={{ marginLeft: 7 }}>
              {autopilot.for && autopilot.for.textValue && autopilot.for.textValue.split('$c')[1]}
            </div>
          </div>
        );
      default:
        return <div />;
    }
  };

  getInfoForOnboardAutopilot = (autopilot: any) => {
    return msg(`onboard.tips.${autopilot.type}`, autopilot.type);
  };

  getHeader() {
    const fieldsList: string[] = [
      msg('simpleAutopilots.type', 'Type'),
      msg('simpleAutopilots.for', 'For *'),
      msg('simpleAutopilots.reward', 'Reward *'),
      msg('simpleAutopilots.expiresIn', 'Reward expires in*'),
      msg('simpleAutopilots.active', 'Active')
    ];
    return (
      <tr>
        {fieldsList.map(item => {
          if (item === msg('simpleAutopilots.reward', 'Reward *')) {
            return (
              <th style={{ width: '38%' }} key={item} colSpan={2}>
                {item}
              </th>
            );
          } else if (item === msg('simpleAutopilots.for', 'For *')) {
            return (
              <th style={{ width: '27%' }} key={item}>
                {item}
              </th>
            );
          } else if (item === msg('simpleAutopilots.active', 'Active')) {
            return (
              <th style={{ width: '11%', paddingLeft: 14 }} key={item}>
                {item}
              </th>
            );
          } else if (item === msg('simpleAutopilots.expiresIn', 'Expires in')) {
            return (
              <th style={{ width: '11%' }} key={item}>
                {item}
              </th>
            );
          }
          return (
            <th style={{ width: '13%' }} key={item}>
              {item}
            </th>
          );
        })}
      </tr>
    );
  }

  getBody() {
    const { autopilots } = this.state;
    const { errors } = this.props.validator!;
    const useCoins = this.props.config.place?.featureFlags?.useCoins;
    if (autopilots.length === 0) {
      return null;
    }
    return autopilots.map((pilot: any, i: number) => {
      if (!useCoins && (pilot.type === 'retention' || pilot.type === 'retention-message')) {
        return;
      }
      return (
        <tr className="table-color-grid" key={i} style={{ width: '100%', height: '100%' }}>
          <td colSpan={6} style={{ width: '100%', height: '100%' }}>
            <table style={{ width: '100%', height: '100%' }}>
              <tbody>
                {this.state.showTips && (
                  <tr style={{ width: '100%', height: '100%' }}>
                    <td colSpan={5} className="simple-autopilot-stats" style={{ fontSize: 14 }}>
                      {this.getInfoForOnboardAutopilot(pilot)}
                    </td>
                  </tr>
                )}
                <tr style={{ width: '100%', height: '100%' }}>
                  <td rowSpan={3} style={{ width: '13%', paddingLeft: 8, whiteSpace: 'normal' }}>
                    {pilot.name}
                  </td>
                  <td style={{ width: '27%', paddingLeft: 8, whiteSpace: 'normal' }}>
                    {this.getForFieldValue(pilot, i)}
                  </td>
                  <td style={{ width: '38%', paddingLeft: 8 }} colSpan={2}>
                    {this.getActionFieldAndValue(pilot, i)}
                  </td>
                  <td style={{ width: '10%', paddingLeft: 8 }}>
                    {Number(pilot.reward.type) === 0 && (
                      <SimpleSelectComponent
                        name="expiration"
                        id={`expiration_${i}`}
                        options={pilot.expires}
                        value={pilot.expireSelected}
                        onChange={this.handleChange('reward&expires', i)}
                        required={false}
                        needsAllLabel={false}
                        arrayOptions={true}
                        hasNameValue={true}
                        ref={`expiration_${i}`}
                        formatError={errors[`expiration_${i}`]}
                        validatorIgnore={!pilot.dirty}
                        validator={[
                          {
                            type: 'isInt',
                            msg: msg('formValidation.fieldRequired', 'Field required!'),
                            params: { min: 1, max: 14 }
                          }
                        ]}
                      />
                    )}
                  </td>
                  <td rowSpan={3} style={{ width: '8%', textAlign: 'center', padding: '0px 8px' }}>
                    <Tooltip
                      disableHoverListener={true}
                      title={
                        !pilot.isActive && pilot.reward.length < 1
                          ? msg(
                              'simpleAutopilots.infoStatus',
                              'To activate the rule, the reward field must be completed!'
                            )
                          : ''
                      }
                    >
                      <Toggle
                        onChange={this.handleChange('isActive', i)}
                        checked={pilot.isActive}
                        disabled={
                          !pilot.reward.value ||
                          pilot.reward.value === '0' ||
                          pilot.reward.value?.message === '' ||
                          pilot.for.value === 0
                        }
                      />
                    </Tooltip>
                  </td>
                  <td rowSpan={3} style={{ width: '4%' }}>
                    {pilot.reward.type === AutopilotActionType.REWARD && this.props.showCheck ? (
                      <Tooltip
                        title={msg(
                          'simpleAutopilots.updateAllRewards',
                          'Check this and all rewards will be changed, even those already sent'
                        )}
                      >
                        <Checkbox
                          checked={pilot.updateAllRewards}
                          onChange={this.handleChange('updateAllRewards', i)}
                          color="primary"
                        />
                      </Tooltip>
                    ) : (
                      <div style={{ width: 42 }} />
                    )}
                  </td>
                </tr>
                {this.state.showMessages && pilot.allowCustomMessage && (
                  <tr style={{ width: '100%', height: '100%' }}>
                    <td style={{ paddingLeft: 8 }}>
                      <div>{msg('simpleAutopilots.customMessage', 'Custom message')}</div>
                    </td>
                    <td colSpan={2} style={{ paddingLeft: 8 }}>
                      <div style={{ paddingRight: '10px' }}>
                        <TextFieldComponent
                          id={`message_${i}`}
                          value={pilot.reward?.message || ''}
                          onChange={this.handleChange('message', i)}
                          inType="text"
                          readOnly={false}
                          required={false}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                {this.state.showStats && (
                  <tr style={{ width: '100%', height: '100%' }}>
                    <td className="simple-autopilot-stats">
                      {msg('autopilot.lastTriggered', 'Last triggered on')}:{' '}
                      {pilot.stats.date !== 'Invalid date' ? pilot.stats.date : 'N/A'}
                    </td>
                    <td colSpan={2} className="simple-autopilot-stats">
                      <div style={{ width: '30%', float: 'left' }}>
                        {msg('autopilot.triggeredTimes', 'Triggered Times')}: {pilot.stats.triggered}
                      </div>
                      <div style={{ float: 'left', marginLeft: 5 }}>
                        {msg('autopilot.used', 'Member redeems')}: {pilot.stats.used}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { showStats, showMessages, showTips } = this.state;
    const { showStatistics } = this.props;
    return (
      <CardComponent
        title={msg('simpleAutopilots.simpleAutopilots', 'Set autopilots')}
        headerIcon="assignment"
        needsTitle={true}
        pending={this.props.config.autopilot_list_pending}
        error={!!this.props.config.autopilot_list_error}
        checkboxes={
          showStatistics
            ? [
                {
                  label: msg('simpleAutopilots.showStatistics', 'Statistics'),
                  checked: showStats,
                  onChange: this.handleCheckbox('statistics')
                },
                {
                  label: msg('simpleAutopilots.showMessages', 'Messages'),
                  checked: showMessages,
                  onChange: this.handleCheckbox('messages')
                },
                {
                  label: msg('simpleAutopilots.showTips', 'Help'),
                  checked: showTips,
                  onChange: this.handleCheckbox('tips')
                }
              ]
            : undefined
        }
      >
        <div className="table-responsive">
          <table className="table table-hover" style={{ minWidth: 650 }}>
            <thead className="text-primary">{this.getHeader()}</thead>
            <tbody>{this.getBody()}</tbody>
          </table>
          <div className="col-md-12" style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="form-group form-button">
              <ButtonComponent
                label={msg('general.save', 'Save')}
                icon="save"
                action={this.saveHandler}
                disabled={this.state.saveButton}
                pending={this.props.config.all_simple_autopilots_save_pending}
              />
            </div>
          </div>
        </div>
      </CardComponent>
    );
  }
}

export default Validator(SimpleAutopilotsComponent);
