import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  SidebarComponent,
  TopbarComponent,
  FooterComponent,
  AdminDashboardContainer,
  PartnersContainer,
  RewardsContainer,
  PlacesContainer,
  PlaceAdminsContainer,
  UsersContainer,
  IndustriesContainer,
  ProfileContainer,
  PackagesContainer,
  AnnouncementContainer,
  OnboardContainer,
  PartnerEmailRouter,
  ReceiptsContainer,
  TriggersRouter,
  ActivityRouter,
  AgendaRouter,
  TransactionsRouter,
  PayBatchesRouter,
  TagsRouter
} from '../components';
import { AppStore, AuthStore, TranslationsStore, reduxContainer } from '../../appRedux';
import { UserDTO, PlaceDTO } from '../../appRedux/types';

import { AutopilotLogsContainer } from '../components/autopilot-logs/autopilot-logs.component';
import { ScrollToTopComponent } from '../shared';
import { adminRoutes } from '.';

interface IProps {
  user: UserDTO;
  place: PlaceDTO;
  initial_language: string;
  origin: string;
  logoutAction: () => any;
  changeLanguageAction: (option: string) => any;
}
interface IState {}

class AdminLayoutContainer extends React.Component<IProps, IState> {
  toggleSideBar = () => {
    let html = document.getElementsByTagName('html');
    let openSideBar = document.getElementById('openSideBar');
    if (!html[0].className.includes('nav-open')) {
      openSideBar?.classList.add('toggled');
      html[0].classList.add('nav-open');
    } else {
      openSideBar?.classList.remove('toggled');
      html[0].classList.remove('nav-open');
    }
  };
  render() {
    const sidebarConfig = {
      title: 'Admin',
      user: this.props.user,
      place: this.props.place,
      origin: this.props.origin
    };
    const sidebarActions = {
      logOut: this.props.logoutAction,
      forSAdmin: true,
      toggleSideBar: this.toggleSideBar
    };
    const topbarActions = {
      initial_language: this.props.initial_language,
      changeLanguage: this.props.changeLanguageAction,
      toggleSideBar: this.toggleSideBar
    };

    return (
      <div className="App">
        <div className="wrapper">
          <SidebarComponent config={sidebarConfig} actions={sidebarActions}/>
          <div className="main-panel">
            <TopbarComponent actions={topbarActions} />
            <ScrollToTopComponent />
            <div className="content" style={{ paddingTop: 3 }}>
              <div className="container-fluid">
                <Switch>
                  <Redirect exact={true} from="/admin" to={adminRoutes.DASHBOARD.path} />
                  <Route path={adminRoutes.DASHBOARD.path} component={AdminDashboardContainer} />
                  <Route path={adminRoutes.PROFILE.path} component={ProfileContainer} />
                  <Route path={adminRoutes.PARTNERS.path} component={PartnersContainer} />
                  <Route path={adminRoutes.REWARDS.path} component={RewardsContainer} />
                  <Route
                    path={adminRoutes.PLACES.subroutes.EDIT.subroutes.ADMINS.path}
                    component={PlaceAdminsContainer}
                  />
                  <Route path={adminRoutes.PLACES.path} component={PlacesContainer} />
                  <Route path={adminRoutes.USERS.path} component={UsersContainer} />
                  <Route path={adminRoutes.INDUSTRIES.path} component={IndustriesContainer} />
                  <Route path={adminRoutes.PACKAGES.path} component={PackagesContainer} />
                  <Route path={adminRoutes.AUTOPILOT_LOGS.path} component={AutopilotLogsContainer} />
                  <Route path={adminRoutes.ANNOUNCEMENTS.path} component={AnnouncementContainer} />
                  <Route path={adminRoutes.ONBOARDINGS.path} component={OnboardContainer} />
                  <Route path={adminRoutes.EMAILS.path} component={PartnerEmailRouter} />
                  <Route path={adminRoutes.RECEIPTS.path} component={ReceiptsContainer} />
                  <Route path={adminRoutes.TRIGGERS.path} component={TriggersRouter} />
                  <Route path={adminRoutes.ACTIVITY.path} component={ActivityRouter} />
                  <Route path={adminRoutes.TRANSACTIONS.path} component={TransactionsRouter} />
                  <Route path={adminRoutes.BATCHES.path} component={PayBatchesRouter} />
                  <Route path={adminRoutes.AGENDA.path} component={AgendaRouter} />
                  <Route path={adminRoutes.TAGS.path} component={TagsRouter} />
                  <Redirect from="*" to={adminRoutes.DASHBOARD.path} />
                </Switch>
              </div>
            </div>
            <FooterComponent />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    user: state.auth.user,
    initial_language: state.translations.initial_language,
    place: state.places.place,
    origin: state.auth.origin
  };
}

const dispatchToProps = {
  logoutAction: AuthStore.actions.logoutAction,
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction
};

export default reduxContainer(AdminLayoutContainer, mapStateToProps, dispatchToProps);
