import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';

import { AuthStore } from './';
import { Server, msg, AlertUtil, logger } from '../../utils';
import { PlacesStore } from '../';
import { UserDeviceDTO } from '../types';
import { authRoutes } from '../../modules/layouts/routes';

/*
  IAuthActions interface definition, which contains every redux action asociated with Auth State.
*/
export interface IAuthActions {
  activationAction(token: string): any;
  validateEmailAction(token: string): any;
  editProfileAction(user: any): any;
  forgotPasswordAction(email: string): any;
  loginAction(email: string, password: string, deviceInfo: UserDeviceDTO): any;
  logoutAction(): any;
  redirectAction(route: string): any;
  resetPasswordAction(token: string, password: string): any;
  unsubcribeUserAction(userToken: string, placeToken?: string): any;
  getUserAccess(): any;
  setEventsListExpiredAction(expired: boolean): any;
  setShowSettlementsWithoutAmountAction(showWithoutAmount: boolean): (dispatch: Dispatch) => void;
  setOriginURLAction(origin: string): (dispatch: Dispatch) => void;
}

/*
  class AuthActions that implements redux actions defined in IAuthActions interface
*/
class AuthActions implements IAuthActions {
  /*
    @function activationAction => Redux action that activates account after registration
      @accepts token : string that contains the activation token
      @returns Promise
  */
  activationAction(token: string) {
    return (dispatch: Dispatch<any>) => {
      logger.msg('Activation action, route:/activation/token', 'GET');
      return Server.get(`auth/activation/${token}`, true).catch(error => {
        dispatch({
          type: AuthStore.ActionTypes.ACTIVATION_FAILED,
          payload: Server.errorParse(error)
        });
      });
    };
  }

  /*
    @function validateEmailAction => Redux action that validate a new email for a mobile user
      @accepts token : string that contains the validate token
      @returns Promise
  */
  validateEmailAction(token: string) {
    return (dispatch: Dispatch<any>) => {
      logger.msg('Validate email action, route:/validate-email/token', 'GET');
      dispatch({
        type: AuthStore.ActionTypes.VALIDATE_EMAIL
      });
      return Server.get(`users/validate-email/${token}`, true)
        .then(response => {
          dispatch({
            type: AuthStore.ActionTypes.VALIDATE_EMAIL_SUCCESS,
            payload: response
          });
        })
        .catch(error => {
          dispatch({
            type: AuthStore.ActionTypes.VALIDATE_EMAIL_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function editProfileAction => Redux action for editing the logged user's profile
      @accepts user : object that contains the new information that will overwrite the existing one
      @returns Promise
  */
  editProfileAction(user: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: AuthStore.ActionTypes.EDIT_PROFILE
      });
      AlertUtil.simple(
        msg('reduxMessages.users.editProfilePending', 'The profile is being updated, please wait...'),
        'info'
      );
      logger.msg('Edit logged user profile, route:/users/userId', 'PUT');
      await Server.put(`users/${user._id}`, user)
        .then((response: any) => {
          dispatch({
            type: AuthStore.ActionTypes.EDIT_PROFILE_SUCCESS,
            payload: response.data as any
          });
          AlertUtil.updateContent(
            msg('reduxMessages.users.editProfileSuccess', 'The profile was successfully updated!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Edit logged user profile, route:/users/userId', 'PUT');
          AlertUtil.updateContent(
            msg('reduxMessages.users.editProfileError', 'Due to an error, the profile could not be updated!'),
            'error'
          );
          dispatch({
            type: AuthStore.ActionTypes.EDIT_PROFILE_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function forgotPasswordAction => Redux action that requests the forgot password procedure to the server
      @accepts email : string that contains the email that needs password changing
      @returns Promise
  */
  forgotPasswordAction(email: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: AuthStore.ActionTypes.FORGOT_PASSWORD
      });
      logger.msg('Forgot password action, route:/auth/forgot-pass', 'POST');
      return Server.post('auth/forgot-pass', { email }, true)
        .then(() => {
          dispatch({
            type: AuthStore.ActionTypes.FORGOT_PASSWORD_SUCCESS
          });
          dispatch(push(authRoutes.FORGOT_PASSWORD_EMAIL));
        })
        .catch(error => {
          logger.err('Forgot password action, route:/auth/forgot-pass', 'POST');
          AlertUtil.simple(
            msg('reduxMessages.auth.sendEmailError', 'Due to an error, the email could not be sent!'),
            'error',
            2000
          );
          dispatch({
            type: AuthStore.ActionTypes.FORGOT_PASSWORD_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function loginAction => Redux action that requests the login procedure to the server
      @accepts email : string that contains the email of the authenticating user
              password : string that contains the password of the authenticating user
              player_id : string that contains the player_id of the authenticating user (for OneSignal Notification API)
      @returns Promise
  */
  loginAction(email: string, password: string, deviceInfo: UserDeviceDTO) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: AuthStore.ActionTypes.LOGIN
      });
      logger.msg('Login action, route:/auth/login', 'POST');
      return Server.login(email, password, deviceInfo)
        .then(async user => {
          // console.warn('success', user);
          // const id = '5bc87a6ff495282b80c49b5e';
          // const hasPartner = false;
          dispatch({
            type: AuthStore.ActionTypes.LOGIN_SUCCESS,
            payload: user
          });
          dispatch(AuthStore.actions.getUserAccess());
          // if (!hasPartner) {
          //   const place = await Server.get(`places/${id}`);
          //   console.warn('place', place);
          // }
        })
        .catch(error => {
          logger.err('Login action, route:/auth/login', 'POST');
          dispatch({
            type: AuthStore.ActionTypes.LOGIN_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function logoutAction => Redux action that requests the logout procedure to the server
      @returns Promise
  */
  logoutAction() {
    return (dispatch: Dispatch<any>) => {
      logger.msg('Logout action, route:/auth/logout', 'GET');
      Server.logout()
        // tslint:disable-next-line: no-empty
        .then(() => {})
        .catch(() => {
          logger.err('Logout action, route:/auth/logout', 'GET');
        })
        .finally(() => {
          dispatch({ type: AuthStore.ActionTypes.LOGOUT });
          dispatch(PlacesStore.actions.selectPlaceTopbar(''));
          dispatch(push('/'));
        });
    };
  }

  /*
    @function redirectAction => Redux action that redirects user to specified route
      @returns Promise
  */
  redirectAction(route: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch(push(route));
    };
  }

  /*
    @function resetPasswordAction => Redux action that requests the reset password procedure to the server
      @accepts token : string that contains the provided token for reset password procedure from received email
                password : string that contains the new password, set by the user in the reset password form
      @returns Promise
  */

  resetPasswordAction(token: string, password: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: AuthStore.ActionTypes.RESET_PASSWORD
      });
      AlertUtil.simple(
        msg('reduxMessages.auth.resetPasswordPending', 'The password is being updated, please wait!'),
        'info',
        2000
      );
      logger.msg('Reset password action, route:/auth/reset-pass/token', 'POST');
      return Server.post(`auth/reset-pass/${token}`, { password }, true)
        .then(() => {
          dispatch({
            type: AuthStore.ActionTypes.RESET_PASSWORD_SUCCESS
          });
          const path = generatePath(authRoutes.PASSWORD_CHANGED, { type: 'success' });
          dispatch(push(path));
        })
        .catch(error => {
          logger.err('Reset password action, route:/auth/reset-pass/token', 'POST');
          if (error.response?.data?.ERROR?.code === 'TOKEN_EXPIRED') {
            const path = generatePath(authRoutes.PASSWORD_CHANGED, { type: 'expired' });
            dispatch(push(path));
          } else {
            AlertUtil.updateContent(
              msg('reduxMessages.auth.resetPasswordError', 'Due to an error, the password could not be changed!'),
              'error'
            );
          }
          dispatch({
            type: AuthStore.ActionTypes.RESET_PASSWORD_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function unsubcribeUserAction => Redux action that unsubscribe a user from place or app news
      @accepts placeToken : string that contains the place token
      @accepts userToken : string that contains the user token
      @returns Promise
  */
  unsubcribeUserAction(userToken: string, placeToken?: string) {
    return (dispatch: Dispatch<any>) => {
      logger.msg('Validate email action, route:/unsubscribe-user/token', 'GET');
      dispatch({
        type: AuthStore.ActionTypes.UNSUBSCRIBE_USER
      });
      let url;
      if (placeToken) {
        url = `users/${userToken}/unsubscribe-place/${placeToken}`;
      } else {
        url = `users/${userToken}/unsubscribe-user`;
      }
      return Server.get(url, true)
        .then(response => {
          dispatch({
            type: AuthStore.ActionTypes.UNSUBSCRIBE_USER_SUCCESS,
            payload: response
          });
        })
        .catch(error => {
          dispatch({
            type: AuthStore.ActionTypes.UNSUBSCRIBE_USER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getUserAccess() {
    return (dispatch: Dispatch<any>) => {
      logger.msg('Get access list for user, route:/auth/user-access', 'GET');
      dispatch({ type: AuthStore.ActionTypes.GET_USER_ACCESS });
      return Server.get(`auth/user-access`)
        .then((response: any) => {
          dispatch({
            type: AuthStore.ActionTypes.GET_USER_ACCESS_SUCCESS,
            payload: response.data
          });
        })
        .catch(error => {
          dispatch({
            type: AuthStore.ActionTypes.GET_USER_ACCESS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  setGroupByCheckinAction(groupByCheckin: boolean) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: AuthStore.ActionTypes.SET_GROUP_BY_CHECKIN,
        payload: groupByCheckin
      });
    };
  }

  setEventsListExpiredAction(expired: boolean) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: AuthStore.ActionTypes.SET_EVENTS_LIST_EXPIRED,
        payload: expired
      });
    };
  }

  setShowSettlementsWithoutAmountAction(showWithoutAmount: boolean) {
    return (dispatch: Dispatch) => {
      dispatch({
        type: AuthStore.ActionTypes.SET_SETTLEMENTS_WITHOUT_AMOUNT,
        payload: showWithoutAmount
      });
    };
  }

  setOriginURLAction = (origin: string) => {
    return (dispatch: Dispatch) => {
      Server.setOrigin(origin);
      dispatch({
        type: AuthStore.ActionTypes.SET_ORIGIN,
        payload: origin
      });
    };
  };
}

const authActions = new AuthActions();
export default authActions;
