import transactionsActions, { ITransactionsActions } from './actions';
import transactionsReducer from './reducer';
import { PaginationDTO, TransactionDTO } from '../types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TransactionsStore {
  export type IState = {
    transaction: TransactionDTO | null;
    transaction_pending: boolean;
    transaction_error: string | null;
    transactions_list: PaginationDTO<TransactionDTO>;
    transactions_list_pending: boolean;
    transactions_list_error: string | null;
    rollback_pending: boolean;
    rollback_error: string | null;
    transactions_list_for_user: PaginationDTO<TransactionDTO>;
    transactions_list_for_user_pending: boolean;
    transactions_list_for_user_error: string | null;
    export_transactions_pending: boolean;
    export_transactions_error: string | null;
  };

  export const initialState: IState = {
    transactions_list: new PaginationDTO(),
    transactions_list_pending: false,
    transactions_list_error: null,
    transaction: null,
    transaction_pending: false,
    transaction_error: null,
    rollback_pending: false,
    rollback_error: null,
    transactions_list_for_user: new PaginationDTO(),
    transactions_list_for_user_pending: false,
    transactions_list_for_user_error: null,
    export_transactions_pending: false,
    export_transactions_error: null
  };

  export enum ActionTypes {
    GET_TRANSACTIONS = 'GET_TRANSACTIONS',
    GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS',
    GET_TRANSACTIONS_FAILED = 'GET_TRANSACTIONS_FAILED',
    GET_TRANSACTION = 'GET_TRANSACTION',
    GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS',
    GET_TRANSACTION_FAILED = 'GET_TRANSACTION_FAILED',
    ROLLBACK = 'ROLLBACK',
    ROLLBACK_SUCCESS = 'ROLLBACK_SUCCESS',
    ROLLBACK_FAILED = 'ROLLBACK_FAILED',
    GET_TRANSACTIONS_FOR_USER = 'GET_TRANSACTIONS_FOR_USER',
    GET_TRANSACTIONS_FOR_USER_SUCCESS = 'GET_TRANSACTIONS_FOR_USER_SUCCESS',
    GET_TRANSACTIONS_FOR_USER_FAILED = 'GET_TRANSACTIONS_FOR_USER_FAILED',
    CLEAR_USER_TRANSACTIONS = 'CLEAR_USER_TRANSACTIONS',
    CLEAR_MODAL_DATA = 'CLEAR_MODAL_DATA',
    CLEAR_TRANSACTIONS_ERROR = 'CLEAR_TRANSACTIONS_ERROR',
    EXPORT_TRANSACTIONS = 'EXPORT_TRANSACTIONS',
    EXPORT_TRANSACTIONS_SUCCESS = 'EXPORT_TRANSACTIONS_SUCCESS',
    EXPORT_TRANSACTIONS_FAILED = 'EXPORT_TRANSACTIONS_FAILED'
  }

  export const actions = transactionsActions;
  export const reducer = transactionsReducer;
  export interface IActions extends ITransactionsActions {}
}

export * from './actions';
