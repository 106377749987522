import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import {
    EventDTO, IAutopilotAction, PaginationDTO, RewardType, TriggerStatus
} from '../../../../appRedux/types';
import { colors } from '../../../../assets/styles';
import { getFormatDate, msg } from '../../../../utils';
import { InfiniteScrollListComponent } from '../../../shared';

interface IProps {
  members: PaginationDTO<any>;
  members_pending: boolean;
  members_error: string | null;
  placeId: string;
  event: EventDTO | null;
  getMembers: (placeId: string, eventId: string, limit?: number, skip?: number, sort?: string, criteria?: any) => any;
  toggleModal: () => any;
}

const RewardStatisticsComponent = (props: IProps) => {
  const getMembers = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    if (props.event) {
      props.getMembers(props.placeId, props.event?._id, limit, skip, sort, criteria);
    }
  };

  const getTriggerStatus = (status: number) => {
    switch (status) {
      case TriggerStatus.UNTRIGGERED:
        return msg('rewardStatus.untriggered', 'Not sent');
      case TriggerStatus.UNCLAIMED:
      case TriggerStatus.CLAIMED:
      case TriggerStatus.SPECIAL:
      case TriggerStatus.HIDDEN:
        return msg('rewardStatus.unused', 'Unused');
      case TriggerStatus.USED:
        return msg('rewardStatus.used', 'Used');
      case TriggerStatus.PARTIAL:
        return msg('rewardStatus.partial', 'Partial');
      default:
        return '';
    }
  };

  const getTriggerBadge = (status: number) => {
    switch (status) {
      case TriggerStatus.USED:
        return colors.green;
      case TriggerStatus.PARTIAL:
        return colors.blue;
      default:
        return colors.gray;
    }
  };

  const getListFilters = () => [
    {
      field: 'status',
      value: [
        {
          _id: '',
          name: msg('rewardStatus.allRewards', 'All rewards')
        },
        {
          _id: 'UNTRIGGERED',
          name: msg('rewardStatus.untriggeredRewards', 'Not sent')
        },
        {
          _id: 'UNUSED',
          name: msg('rewardStatus.unusedRewards', 'Unused')
        },
        {
          _id: 'PARTIAL',
          name: msg('rewardStatus.partialRewards', 'Partially used')
        },
        {
          _id: 'USED',
          name: msg('rewardStatus.usedRewards', 'Used')
        }
      ],
      default: ''
    }
  ];

  const parseMembers = (list: PaginationDTO<any>): any => {
    if (!list?.results?.length) {
      return [];
    }
    return {
      ...list,
      results: list.results.map(item => ({
        _id: item._id,
        lastModified: item.lastModified ? getFormatDate(item.lastModified, 'hour') : 'N/A',
        status: item.status,
        statusLabel: getTriggerStatus(item.status),
        badge: getTriggerBadge(item.status),
        user: {
          lastname: item.user?.lastname + ' ' + item.user?.firstname,
          phone: item && item.user?.phone ? item.user?.phone : 'N/A',
          email: item && item.user?.email
        },
        reward: item.autopilotSnapshot.actions
          .map((action: IAutopilotAction) => action.value.title || action.value)
          .join(', '),
        changes: item.changes?.map((change: any) => {
          return {
            date: getFormatDate(change.created, 'hour'),
            place: change.placeName,
            amount: change.usedAmount
          };
        })
      }))
    };
  };

  const renderCustomColumn = (fieldItem: any) => {
    return fieldItem.changes.length
      ? fieldItem.changes.map((change: any, index: number) => (
          <div key={`custom${index}`}>
            {change.date} - {props.event?.places?.length ? `${change.place} - ` : ''}
            {change.amount} lei
          </div>
        ))
      : 'N/A';
  };

  return (
    <Modal show={true} onHide={props.toggleModal} dialogClassName="customEventModal">
      <Modal.Header>
        <Modal.Title>
          {msg('rewardStatus.usageStatistics', 'Statistics:')} {props.event?.title || ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InfiniteScrollListComponent
          fields={{
            lastModified: msg('rewardStatus.lastModified', 'Last modified'),
            'user.lastname': msg('rewardStatus.member', 'Member'),
            'user.phone': msg('members.phone', 'Phone'),
            'user.email': msg('members.email', 'Email'),
            statusLabel: msg('rewardStatus.status', 'Status'),
            reward: msg('event.rewards', 'Reward')
          }}
          customColumn={
            props.event?.rewards?.some(item => item.type === RewardType.VOUCHER)
              ? {
                  name: msg('rewardStatus.changes', 'Changes'),
                  renderColumn: renderCustomColumn
                }
              : undefined
          }
          list={parseMembers(props.members)}
          pending={props.members_pending}
          error={props.members_error}
          get={getMembers}
          disableQueryParams={true}
          sort={{
            fields: ['lastModified', 'user.lastname', 'user.email'],
            default: 'lastModified,-1'
          }}
          filters={getListFilters()}
          search={['user.lastname', 'user.firstname', 'user.phone', 'user.email']}
          hasCardButton={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-danger" onClick={props.toggleModal}>
          {msg('general.close', 'Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RewardStatisticsComponent;
