import { AnnouncementsStore } from '.';
import { PaginationDTO } from '../types';

/*
  announcementsReducer = reducer for all actionTypes for Announcements
*/
function announcementsReducer(
  state: AnnouncementsStore.IState = AnnouncementsStore.initialState,
  action: IAction<any>
): AnnouncementsStore.IState {
  switch (action.type) {
    case AnnouncementsStore.ActionTypes.GET_ANNOUNCEMENTS: {
      return {
        ...state,
        announcements_list_pending: true,
        announcements_list_error: null
      };
    }
    case AnnouncementsStore.ActionTypes.GET_ANNOUNCEMENTS_SUCCESS: {
      return {
        ...state,
        announcements_list_pending: false,
        announcements_list_error: null,
        announcements_list: action.payload
      };
    }
    case AnnouncementsStore.ActionTypes.GET_ANNOUNCEMENTS_FAILED: {
      return {
        ...state,
        announcements_list_pending: false,
        announcements_list: new PaginationDTO(),
        announcements_list_error: action.payload
      };
    }
    case AnnouncementsStore.ActionTypes.SAVE_ANNOUNCEMENT: {
      return {
        ...state,
        save_announcement_pending: true,
        save_announcement_error: null
      };
    }
    case AnnouncementsStore.ActionTypes.SAVE_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        save_announcement_pending: false,
        save_announcement_error: null,
        announcement: action.payload
      };
    }
    case AnnouncementsStore.ActionTypes.SAVE_ANNOUNCEMENT_FAILED: {
      return {
        ...state,
        save_announcement_pending: false,
        announcement: null,
        save_announcement_error: action.payload
      };
    }
    case AnnouncementsStore.ActionTypes.GET_ANNOUNCEMENT: {
      return {
        ...state,
        announcement_pending: true,
        announcement_error: null
      };
    }
    case AnnouncementsStore.ActionTypes.GET_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        announcement_pending: false,
        announcement_error: null,
        announcement: action.payload
      };
    }
    case AnnouncementsStore.ActionTypes.GET_ANNOUNCEMENT_FAILED: {
      return {
        ...state,
        announcement_pending: false,
        announcement: null,
        announcement_error: action.payload
      };
    }
    case AnnouncementsStore.ActionTypes.EDIT_ANNOUNCEMENT: {
      return {
        ...state,
        edit_announcement_pending: true,
        edit_announcement_error: null
      };
    }
    case AnnouncementsStore.ActionTypes.EDIT_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        edit_announcement_pending: false,
        edit_announcement_error: null,
        announcement: action.payload
      };
    }
    case AnnouncementsStore.ActionTypes.EDIT_ANNOUNCEMENT_FAILED: {
      return {
        ...state,
        edit_announcement_pending: false,
        edit_announcement_error: action.payload
      };
    }
    case AnnouncementsStore.ActionTypes.DELETE_ANNOUNCEMENT: {
      return {
        ...state,
        delete_announcement_pending: true,
        delete_announcement_error: null
      };
    }
    case AnnouncementsStore.ActionTypes.DELETE_ANNOUNCEMENT_SUCCESS: {
      return {
        ...state,
        delete_announcement_pending: false,
        delete_announcement_error: null
      };
    }
    case AnnouncementsStore.ActionTypes.DELETE_ANNOUNCEMENT_FAILED: {
      return {
        ...state,
        delete_announcement_pending: false,
        delete_announcement_error: action.payload
      };
    }
    default:
      return state;
  }
}

export default announcementsReducer;
