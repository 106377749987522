import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Toggle from 'react-toggle';
import { isEqual } from 'lodash';
import { msg, msgHTML, Validator, IValidator, AlertUtil } from '../../../../../utils';
import { ButtonComponent, TextFieldComponent, CardComponent } from '../../../../shared';
import { PlaceSettingsDTO, FeatureFlagsDTO, PlaceIntegrationDTO } from '../../../../../appRedux/types';
interface IProps {
  placeId?: string | null;
  partnerId?: string | null;
  settings?: PlaceSettingsDTO | null;
  pending?: boolean;
  forSAdmin: boolean;
  handleChange?: any;
  allowReceipts?: boolean;
  featureFlags?: FeatureFlagsDTO;
  qrHash: string;
  integration?: PlaceIntegrationDTO;
  externalApp?: string;
  save?: (settings: any) => void;
  resetPinCode: (isSAdmin: boolean, placeId: string, newPinCode: string, oldPinCode?: string) => any;
}
interface IState {
  integration: PlaceIntegrationDTO | null;
  qrHash: string;
  externalApp: string;
  settings: PlaceSettingsDTO;
  isDirty: boolean;
  allowReceipts: boolean;
}

class PlaceSettingsComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      integration: props.featureFlags?.integration ? props.integration || new PlaceIntegrationDTO() : null,
      qrHash: props.qrHash || '',
      externalApp: props.externalApp || '',
      settings: props.settings || new PlaceSettingsDTO(),
      allowReceipts: !!this.props.allowReceipts,
      isDirty: false
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.settings && !isEqual(this.props.settings, prevProps.settings)) {
      this.setState({ settings: this.props.settings });
    }
  }

  saveHandler = () => {
    const { isValid } = this.props.validator!;
    if (isValid()) {
      const { settings } = this.state;
      const sendData = {
        _id: this.props.placeId || '',
        partnerId: this.props.partnerId || '',
        allowReceipts: settings.convertAmountRule >= 0 ? this.state.allowReceipts : false,
        qrHash: this.state.qrHash,
        integration: this.state.integration,
        externalApp: this.state.externalApp,
        settings: {
          pointsForCheckin: settings.pointsForCheckin,
          memberRegistration: settings.memberRegistration,
          convertAmountRule: Number(settings.convertAmountRule)
        }
      };
      if (this.props.save) {
        this.props.save(sendData);
        this.setState({ isDirty: false });
      }
    }
  };

  handleChange = (event: any) => {
    const fieldKey = event.target.getAttribute('id');
    const fieldValue = event.target.value;
    let { settings, qrHash, integration, externalApp } = this.state;
    if (this.props.validator) {
      const { isDirty } = this.props.validator!;
      if (isDirty) {
        isDirty(fieldKey);
      }
    }
    if (fieldKey.indexOf('.') >= 0) {
      const fields = fieldKey.split('.');
      if (integration) {
        integration[fields[1]] = fieldValue;
      } else if (this.props.featureFlags?.integration) {
        integration = new PlaceIntegrationDTO();
        integration[fields[1]] = fieldValue;
      }
    } else {
      switch (fieldKey) {
        case 'qrHash':
          qrHash = fieldValue;
          break;
        case 'externalApp':
          externalApp = fieldValue;
          break;
        default:
          settings[fieldKey] = fieldValue;
          break;
      }
    }
    this.setState({ settings, qrHash, integration, externalApp, isDirty: true });
    if (this.props.handleChange) {
      this.props.handleChange(settings);
    }
  };

  handleToggleReceiptsChange = (event: any) => {
    const allowReceipts = !!event.target.checked;
    this.setState({ allowReceipts, isDirty: true });
  };

  checkTabletPinCode = (input: string) => {
    const reg = /^\d+$/;
    const isInt = reg.test(input);
    if (!isInt) {
      return msg('formValidation.onlyNumbers', 'Use only numbers!');
    }
    if (input.length !== 4) {
      return msg('formValidation.invalidCode', 'The code must have 4 digits!');
    }
    return null;
  };

  resetTabletPinCode = async () => {
    if (this.props.forSAdmin) {
      const newPin = await AlertUtil.fireWithInput(
        msg('placeSettings.resetCode', 'Reset tablet pin code'),
        '',
        msg('placeSettings.newCode', 'New tablet pin code'),
        this.checkTabletPinCode,
        true
      );
      if (newPin) {
        this.props.resetPinCode(this.props.forSAdmin, this.props.placeId || '', newPin);
      }
    } else {
      const oldPin = await AlertUtil.fireWithInput(
        msg('placeSettings.changeCode', 'Change tablet pin code'),
        '',
        msg('placeSettings.oldCode', 'Old pin code'),
        this.checkTabletPinCode,
        true
      );
      if (oldPin) {
        const newPin = await AlertUtil.fireWithInput(
          msg('placeSettings.changeCode', 'Change tablet pin code'),
          '',
          msg('placeSettings.newCode', 'New pin code'),
          this.checkTabletPinCode,
          true
        );
        if (newPin) {
          this.props.resetPinCode(this.props.forSAdmin, this.props.placeId || '', newPin, oldPin);
        }
      }
    }
  };

  render() {
    const { settings, qrHash, integration, externalApp } = this.state;
    const { errors } = this.props.validator!;
    const allowReceipts = this.props.featureFlags?.sendReceipts && this.state.allowReceipts;
    const enableReceiptsToggle = !!(
      this.props.featureFlags?.sendReceipts && parseInt(this.state.settings.convertAmountRule + '', 10) >= 0
    );
    return (
      <CardComponent title={msg('cardTitle.placeSettings', 'Place Settings')} headerIcon="settings" needsTitle={true}>
        <div style={styles.row}>
          <div>{msg('placeSettings.memberRegistration', 'Each user will receive ')}</div>
          <TextFieldComponent
            id="memberRegistration"
            value={settings?.memberRegistration?.toString() || ''}
            onChange={this.handleChange}
            inType="text"
            minLength={1}
            maxLength={4}
            style={styles.input}
            disabled={!this.props.featureFlags?.useCoins}
            formatError={errors.memberRegistration}
            ref="memberRegistration"
            validator={[
              { type: 'isInt', msg: msg('formValidation.isInt', 'Use only numbers!') },
              {
                type: 'isLength',
                msg: msg('formValidation.fieldRequired', 'Field required!'),
                params: { min: 1 }
              }
            ]}
          />
          <div>{msg('general.coinsUponRegistration', 'Coins after becoming a member to a settings')}.</div>
        </div>
        {(this.props.forSAdmin || this.props.featureFlags?.checkin) && (
          <div style={styles.row}>
            <div>{msg('placeSettings.pointsPerCheckin', 'For each check-in, a member will receive ')}</div>
            <TextFieldComponent
              id="pointsForCheckin"
              value={settings?.pointsForCheckin?.toString() || ''}
              onChange={this.handleChange}
              inType="text"
              minLength={1}
              maxLength={4}
              style={styles.input}
              disabled={!this.props.featureFlags?.useCoins}
              formatError={errors.pointsForCheckin}
              ref="pointsForCheckin"
              validator={[
                { type: 'isInt', msg: msg('formValidation.isInt', 'Use only numbers!') },
                {
                  type: 'isLength',
                  msg: msg('formValidation.fieldRequired', 'Field required!'),
                  params: { min: 1 }
                }
              ]}
            />
            <div>{msg('general.coins', 'Coins')}.</div>
          </div>
        )}
        {this.props.forSAdmin && this.props.featureFlags?.staticQR && (
          <div style={styles.row}>
            <div>{msg('placeSettings.staticQR', 'QR Link')}</div>
            <div className="col-sm-10">
              <TextFieldComponent
                id="qrHash"
                inType="text"
                value={qrHash}
                onChange={this.handleChange}
                style={styles.staticQR}
                formatError={errors.qrHash}
                ref="qrHash"
                validator={[
                  {
                    type: 'isLength',
                    msg: msg('formValidation.fieldRequired', 'Field required!'),
                    params: { min: 1 }
                  }
                ]}
              />
            </div>
          </div>
        )}
        {this.props.forSAdmin && this.props.featureFlags?.integration && (
          <>
            <div style={styles.row}>
              <div>{msg('placeSettings.integrationLink', 'Integration link:')}</div>
              <div className="col-sm-6">
                <TextFieldComponent
                  id="integration.api"
                  inType="text"
                  value={integration?.api || ''}
                  onChange={this.handleChange}
                  formatError={errors['integration.api']}
                  ref="integration.api"
                  validator={[
                    {
                      type: 'isLength',
                      msg: msg('formValidation.fieldRequired', 'Field required!'),
                      params: { min: 1 }
                    }
                  ]}
                />
              </div>
            </div>
            <div style={styles.row}>
              <div>{msg('placeSettings.integrationUsername', 'Integration username:')}</div>
              <div className="col-sm-2">
                <TextFieldComponent
                  id="integration.username"
                  inType="text"
                  value={integration?.username || ''}
                  onChange={this.handleChange}
                  formatError={errors['integration.username']}
                  ref="integration.username"
                  validator={[
                    {
                      type: 'isLength',
                      msg: msg('formValidation.fieldRequired', 'Field required!'),
                      params: { min: 1 }
                    }
                  ]}
                />
              </div>
              <div>{msg('placeSettings.integrationPassword', 'Integration password:')}</div>
              <div className="col-sm-2">
                <TextFieldComponent
                  id="integration.password"
                  inType="text"
                  value={integration?.password || ''}
                  onChange={this.handleChange}
                  formatError={errors['integration.password']}
                  ref="integration.password"
                  validator={[
                    {
                      type: 'isLength',
                      msg: msg('formValidation.fieldRequired', 'Field required!'),
                      params: { min: 1 }
                    }
                  ]}
                />
              </div>
            </div>
          </>
        )}
        {(this.props.forSAdmin || this.props.featureFlags?.sendReceipts) && (
          <div>
            <div style={styles.row}>
              <div>{msgHTML('placeSettings.conversionRule', 'Amount to coins convert ratio:')}</div>
              <TextFieldComponent
                id="convertAmountRule"
                inType="text"
                value={settings?.convertAmountRule.toString() || ''}
                onChange={this.handleChange}
                minLength={1}
                style={styles.input}
                disabled={!this.props.featureFlags?.useCoins}
                formatError={errors.convertAmountRule}
                ref="convertAmountRule"
                validator={[
                  {
                    type: 'isInt',
                    msg: msg('formValidation.onlyPositiveNumbers', 'Use only positive numbers!'),
                    params: { min: 0 }
                  }
                ]}
              />
              <div>
                %.{' '}
                {settings.convertAmountRule > 0
                  ? msgHTML(
                      'placeSettings.conversionExample',
                      `For spendings of 100 RON, users will receive ${settings?.convertAmountRule} coins`,
                      { amount: settings.convertAmountRule > 0 ? settings.convertAmountRule + '' : '0' }
                    )
                  : msg('placeSettings.conversionExample2', 'Users will not receive any coins.')}
              </div>
            </div>
            <div style={styles.row}>
              <div>{msg('receipts.enableReceipts', 'Enable receipts')}:</div>
              <div style={{ marginLeft: 10 }}>
                <Tooltip
                  title={
                    enableReceiptsToggle
                      ? ''
                      : this.props.featureFlags?.sendReceipts
                      ? msg('receipts.convertRequired', 'You must configure a conversion rule first')
                      : msg('receipts.convertRequiredFlag', 'You must first enable send receipts feature flag')
                  }
                >
                  <div>
                    <Toggle
                      onChange={this.handleToggleReceiptsChange}
                      checked={allowReceipts && settings.convertAmountRule >= 0}
                      disabled={!enableReceiptsToggle}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
        {this.props.forSAdmin && this.props.featureFlags?.externalApp && (
          <div style={styles.row}>
            <div>{msg('placeSettings.externalApp', 'External App:')}</div>
            <div className="col-sm-5 col-lg-3">
              <TextFieldComponent id="externalApp" inType="text" value={externalApp} onChange={this.handleChange} />
            </div>
          </div>
        )}
        <ButtonComponent
          label={
            this.props.forSAdmin
              ? msg('placeSettings.resetCode', 'Reset tablet code')
              : msg('placeSettings.changeCode', 'Change tablet code')
          }
          action={this.resetTabletPinCode}
          icon="lock_reset"
          styles={{ margin: 10 }}
        />
        {!this.props.handleChange && (
          <div style={{ margin: 10 }}>
            <div className="col-md-3" />
            <div className="col-md-6" style={{ textAlign: 'center' }}>
              <div className="form-group form-button">
                <ButtonComponent
                  label={msg('general.save', 'Save')}
                  action={this.saveHandler}
                  pending={this.props.pending}
                  disabled={!this.state.isDirty}
                  icon="save"
                />
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        )}
      </CardComponent>
    );
  }
}

export default Validator(PlaceSettingsComponent);

const styles = {
  row: {
    display: 'flex',
    marginLeft: 10,
    alignItems: 'center',
    fontSize: 14
  },
  input: {
    width: 50,
    marginLeft: 8,
    marginRight: 8
  },
  tabletCode: {
    width: 70,
    marginLeft: 8,
    marginRight: 8
  },
  staticQR: {
    width: '100%'
  }
};
