export class ReviewConfigDTO {
  placeId: string;
  name: string;
  questions: ReviewQuestionDTO[];
  answers: number;
  isActive: boolean;
  created: number;
  deleted: boolean;
  action?: ReviewConfigAction;
}

export class ReviewQuestionDTO {
  label: string;
  type: any;
  options?: string[];
}

export class ReviewConfigAction {
  value: number;
  message?: string;
}

export enum ReviewQuestionType {
  TEXT,
  CHECKBOX,
  RADIO,
  TEXTAREA
}
