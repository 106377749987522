import runtimeEnv from '@mars/heroku-js-runtime-env';

/* eslint-disable @typescript-eslint/no-namespace */
import authActions, { IAuthActions } from './actions';
import authReducer from './reducer';

const env = runtimeEnv();

/*
  AuthStore definition as a namespace that contains actions, reducers, actionTypes and auth state
*/
export namespace AuthStore {
  function getInitialState() {
    const authLocalStorage = localStorage.getItem('auth');
    const storageOrigin = localStorage.getItem('origin');
    if (authLocalStorage === null || authLocalStorage === undefined) {
      return {
        login_pending: false,
        login_error: null,
        user: null,
        get_access_pending: false,
        get_access_error: null,
        edit_user_pending: false,
        edit_user_error: null,
        token: null,
        refresh_token: null,
        forgot_password_pending: false,
        forgot_password_error: null,
        reset_password_info: null,
        reset_password_pending: false,
        reset_password_error: null,
        activation_error: null,
        activity_group_by_checkin: false,
        events_list_expired: false,
        show_settlements_without_amount: false,
        get_access_token_pending: false,
        get_access_token_error: null,
        origin: storageOrigin || env.REACT_APP_API_URL || ''
      };
    } else {
      return JSON.parse(authLocalStorage);
    }
  }

  export const initialState: IState = getInitialState();

  export function setLocalStorage(state: IState | null) {
    if (state !== null) {
      localStorage.removeItem('auth');
      localStorage.setItem('auth', JSON.stringify(state));
    } else {
      localStorage.removeItem('auth');
    }
  }

  export type IState = {
    login_pending: boolean;
    login_error: string | null;
    user: any;
    get_access_pending: boolean;
    get_access_error: string | null;
    edit_user_pending: boolean;
    edit_user_error: string | null;
    token: string | null;
    refresh_token: string | null;
    forgot_password_pending: boolean;
    forgot_password_error: string | null;
    reset_password_info: any;
    reset_password_pending: boolean;
    reset_password_error: string | null;
    activation_error: string | null;
    validate_email_pending: boolean;
    validate_email_error: string | null;
    unsubscribe_user_pending: boolean;
    unsubscribe_user_error: string | null;
    activity_group_by_checkin: boolean;
    events_list_expired: boolean;
    show_settlements_without_amount: boolean;
    get_access_token_pending: boolean;
    get_access_token_error: string | null;
    origin: string;
  };

  export enum ActionTypes {
    LOGIN = 'LOGIN',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILED = 'LOGIN_FAILED',
    LOGOUT = 'LOGOUT',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED',
    RESET_PASSWORD = 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED',
    ACTIVATION_FAILED = 'ACTIVATION_FAILED',
    EDIT_PROFILE = 'EDIT_PROFILE',
    EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS',
    EDIT_PROFILE_FAILED = 'EDIT_PROFILE_FAILED',
    VALIDATE_EMAIL = 'VALIDATE_EMAIL',
    VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS',
    VALIDATE_EMAIL_FAILED = 'VALIDATE_EMAIL_FAILED',
    UNSUBSCRIBE_USER = 'UNSUBSCRIBE_USER',
    UNSUBSCRIBE_USER_SUCCESS = 'UNSUBSCRIBE_USER_SUCCESS',
    UNSUBSCRIBE_USER_FAILED = 'UNSUBSCRIBE_USER_FAILED',
    GET_USER_ACCESS = 'GET_USER_ACCESS',
    GET_USER_ACCESS_SUCCESS = 'GET_USER_ACCESS_SUCCESS',
    GET_USER_ACCESS_FAILED = 'GET_USER_ACCESS_FAILED',
    SET_GROUP_BY_CHECKIN = 'SET_GROUP_BY_CHECKIN',
    SET_EVENTS_LIST_EXPIRED = 'SET_EVENTS_LIST_EXPIRED',
    SET_SETTLEMENTS_WITHOUT_AMOUNT = 'SET_SETTLEMENTS_WITHOUT_AMOUNT',
    GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN',
    GET_ACCESS_TOKEN_SUCCESS = 'GET_ACCESS_TOKEN_SUCCESS',
    GET_ACCESS_TOKEN_FAILED = 'GET_ACCESS_TOKEN_FAILED',
    SET_ORIGIN = 'SET_ORIGIN'
  }

  export const actions = authActions;
  export const reducer = authReducer;
  export interface IActions extends IAuthActions {}
}
