import React from 'react';
import { msg } from '../../../../utils';
import { SimpleSelectComponent, TextFieldComponent, ButtonComponent } from '../../../shared';
import { ReviewQuestionDTO, ReviewQuestionType } from '../../../../appRedux/types';
import { Hidden } from '@material-ui/core';

interface IProps {
  index: number;
  listItem: ReviewQuestionDTO;
  editMode: boolean;
  onChange: (value: { name: string; value: string }, index: number) => any;
  onDelete: (index: number) => any;
  onAddOption: (index: number) => any;
  onOptionInput: (event: any, index: number) => any;
  onOptionDelete: (questionIndex: number, optionIndex: number) => any;
}
interface IState {}

export class ReviewQuestionComponent extends React.Component<IProps, IState> {
  renderQuestionAndValue = () => {
    const { listItem, index, editMode } = this.props;
    switch (listItem.type) {
      case ReviewQuestionType.CHECKBOX:
      case ReviewQuestionType.RADIO:
        return (
          <div>
            {listItem.options &&
              listItem.options.map((option: string, index_option: number) => {
                return (
                  <div key={index_option} className="row" style={{ marginBottom: 15 }}>
                    <div className="col-sm-1" />
                    <Hidden smDown={true}>
                      <div style={{ display: 'inline-flex', float: 'left', height: 58, alignItems: 'center' }}>
                        <i className="material-icons">{listItem.type === 1 ? 'check_box' : 'radio_button_checked'}</i>
                      </div>
                    </Hidden>
                    <div className="col-sm-3">
                      <TextFieldComponent
                        key={index_option}
                        id={`value_${this.props.index}_${index_option}`}
                        inType="text"
                        label={msg('reviews.option', 'Option')}
                        value={option || ''}
                        onChange={(e: any) =>
                          this.props.onOptionInput({ target: this.props.index, value: e.target.value }, index_option)
                        }
                        required={true}
                      />
                    </div>
                    {!editMode && (
                      <button
                        key={'buton' + index_option}
                        type="button"
                        data-rel="tooltip"
                        className="btn btn-danger"
                        onClick={() => this.props.onOptionDelete(this.props.index, index_option)}
                        style={{ width: 27, padding: 5, height: 27, marginTop: 18 }}
                        title={msg('general.delete', 'Delete')}
                        id={index_option + ''}
                      >
                        <i className="material-icons">delete</i>
                      </button>
                    )}
                  </div>
                );
              })}
            {!editMode && (
              <>
                <div className="col-sm-1" />
                <ButtonComponent
                  label={msg('reviews.addOption', 'Add option')}
                  icon="add_circle"
                  action={() => this.props.onAddOption(index)}
                  pending={false}
                />
              </>
            )}
          </div>
        );
      case ReviewQuestionType.TEXT:
        return <div />;
      default:
        return <div />;
    }
  };

  render() {
    const { listItem, index, editMode } = this.props;
    return (
      <div style={{ marginBottom: 10, paddingLeft: 15 }}>
        <div className="row">
          <Hidden smDown={true}>
            <div style={{ display: 'inline-flex', float: 'left', height: 58, alignItems: 'center' }}>
              <i className="material-icons">create</i>
            </div>
          </Hidden>
          <div className="col-sm-5">
            <TextFieldComponent
              id={`value_${index}`}
              inType="text"
              label={msg('reviews.question', 'Question')}
              value={listItem.label || ''}
              onChange={(e: any) => this.props.onChange({ name: 'label', value: e.target.value }, index)}
              required={true}
            />
          </div>
          <div className="col-sm-2">
            <SimpleSelectComponent
              label={msg('reviews.answerType', 'Answer type')}
              options={[
                {
                  name: 'Text',
                  _id: ReviewQuestionType.TEXT
                },
                {
                  name: 'Checkbox',
                  _id: ReviewQuestionType.CHECKBOX
                },
                { name: 'Radio', _id: ReviewQuestionType.RADIO }
              ]}
              name="type"
              id={`type_${index}`}
              value={listItem.type !== undefined ? listItem.type : ''}
              onChange={(e: any) => this.props.onChange(e.target, index)}
              required={true}
              needsAllLabel={false}
              arrayOptions={false}
              readOnly={editMode}
            />
          </div>
          {!editMode && (
            <button
              type="button"
              data-rel="tooltip"
              className="btn btn-danger"
              onClick={() => this.props.onDelete(index)}
              style={{ width: 27, padding: 5, height: 27, marginTop: 18 }}
              title={msg('general.delete', 'Delete')}
              id={index + ''}
            >
              <i className="material-icons">delete</i>
            </button>
          )}
        </div>
        <div>{this.renderQuestionAndValue()}</div>
      </div>
    );
  }
}
