/* eslint-disable jsx-a11y/anchor-is-valid */
import { debounce } from 'lodash';
import * as React from 'react';
import { Bounce } from 'react-activity';
import ReactImageZoom from 'react-image-zoom';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Toggle from 'react-toggle';

import { Popover } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import { SimpleSelectComponent, TextFieldComponent } from '../';
import { PaginationDTO } from '../../../appRedux/types';
import { colors } from '../../../assets/styles';
import { deserializeQueryString, getDateInterval, msg } from '../../../utils';
import { DateRangePicker } from '../DateRangePicker';
import { sortIconStyle, spinnerOverlayStyle, upIconStyle } from './AdvancedListStyles';
import { IProps, IState } from './AdvancedListTypes';

const defaultLimit = 15;

class InfiniteScrollListComponent extends React.Component<IProps & RouteComponentProps, IState> {
  actions: IProps['actions'];
  limits: string[] = [];
  filterValues: IProps['filters'];
  scrollDivRef: any;
  changedElement: any;
  displayedDateRange: Date[] | [null, null];
  getDataDebounced = debounce(this.getData, 300);

  constructor(props: IProps & RouteComponentProps) {
    super(props);
    let fields: Array<{ key: string; value: string }> = [];
    const dates = getDateInterval(this.props.dateInterval?.default);
    Object.keys(this.props.fields).forEach(key => {
      fields.push({
        key: key,
        value: this.props.fields[key]
      });
    });
    if (this.props.filters) {
      this.filterValues = this.props.filters || [];
    }
    for (let i = 10; i <= 50; i += 10) {
      this.limits.push(i.toString());
    }
    this.actions = this.props.actions || [];
    this.scrollDivRef = React.createRef();
    this.changedElement = null;
    this.displayedDateRange = [new Date(dates.startTime), new Date(dates.endTime)];
    this.state = {
      fields: fields,
      limit: this.props.limit || defaultLimit,
      sort: this.props.sort?.default || undefined,
      skip: 0,
      search: this.props.defaultSearchValue ? this.props.defaultSearchValue : '',
      filters: this.props.filters?.map(filter => filter.default || '') || undefined,
      dateInterval: dates,
      popoverAnchor: null,
      popoverId: '',
      data: this.props.list || new PaginationDTO()
    };
  }

  componentDidMount() {
    const parsedSearch = deserializeQueryString(this.props.location.search);
    let newState = { ...this.state };
    if (!this.props.disableQueryParams) {
      const fields = ['search', 'skip', 'limit'];
      fields.forEach(field => {
        if (parsedSearch[field]) {
          const reg = /^\d+$/;
          newState[field] = reg.test(parsedSearch[field])
            ? parseInt(parsedSearch[field], 10)
            : decodeURIComponent(parsedSearch[field]);
          delete parsedSearch[field];
        }
      });
      if (!parsedSearch.sort && this.props.sort?.default) {
        this.setQueryParams(this.props.sort?.default, 'sort');
      } else if (parsedSearch.sort) {
        newState.sort = parsedSearch.sort;
        delete parsedSearch.sort;
      }
      newState.filters = this.props.filters?.map((filter, index) => {
        if (!parsedSearch[filter.field] && filter.default) {
          this.setQueryParams(filter.default, 'filters', index);
        }
        return parsedSearch[filter.field] ? parsedSearch[filter.field] : filter.default || '';
      });
      const dateField = this.props.dateInterval?.field;
      if (dateField && parsedSearch[dateField]) {
        const decoded = decodeURIComponent(parsedSearch[dateField]);
        const newDateInterval = JSON.parse(decoded);
        newState.dateInterval = newDateInterval;
        this.displayedDateRange = [new Date(newDateInterval.startTime), new Date(newDateInterval.endTime)];
      }
    }
    this.setState(newState, () => this.getData(newState.skip || 0));
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props && this.props !== prevProps) {
      let fields: Array<{ key: string; value: string }> = [];
      this.actions = [];
      this.filterValues = [];
      Object.keys(this.props.fields).forEach(key => {
        fields.push({
          key: key,
          value: this.props.fields[key]
        });
      });
      this.actions = this.props.actions || [];
      if (this.props.filters) {
        this.filterValues = this.props.filters || [];
      }
      let data = this.state.data;
      if (prevProps.pending && !this.props.pending) {
        const currentResults = this.state.data.results || [];
        const newResults = this.props.list.results || [];
        if (this.props.list.skip) {
          if (this.changedElement) {
            const indexOfElement = data.results.findIndex(item => item._id === this.changedElement);
            const newElement = this.props.list.results.find(item => item._id === this.changedElement);
            if (newElement && indexOfElement > -1) {
              data.results[indexOfElement] = newElement;
            }
            this.changedElement = null;
          } else {
            data = { ...this.props.list, results: [...currentResults, ...newResults] };
          }
        } else {
          data = this.props.list;
          this.changedElement = null;
          if (data.limit === defaultLimit || data.limit === this.props.limit) {
            this.resetScroll();
          }
        }
      }
      this.setState({ fields, data });
    }
  }

  scrollHandler = (event: any) => {
    if (this.props.pending) {
      return;
    }
    const element = event.target;
    if (element.scrollHeight - (element.scrollTop + element.offsetHeight) <= 1) {
      const resultsLength = this.state.data.results?.length;
      if (resultsLength < this.state.data.total_record_count) {
        this.getData(resultsLength);
      }
    }
  };

  resetScroll = () => {
    if (this.scrollDivRef.current) {
      this.scrollDivRef.current.scrollTop = 0;
    }
  };

  isData() {
    return !!(this.state.fields?.length && this.props.list?.results?.length);
  }

  getData(skip: number) {
    const { limit, sort, search, filters, dateInterval } = this.state;
    if (limit > 0 && skip >= 0 && this.props.get) {
      let criteria: any = { search: {}, filters: {} };
      if (search && this.props.search.length > 0) {
        this.props.search.forEach((searchItem: string) => {
          criteria.search[searchItem] = search;
        });
      }
      if (filters?.length && this.props.filters?.length) {
        this.props.filters.forEach((item, index) => {
          criteria.filters[item.field] = filters[index] || undefined;
        });
      }
      if (dateInterval && this.props.dateInterval) {
        criteria.filters[this.props.dateInterval.field] = dateInterval;
      }
      this.setState({ skip });
      this.props.get(limit, skip, sort, criteria);
    }
  }

  setQueryParams = (value: string, type: string, index?: number) => {
    let field = '';
    if (type === 'filters' && this.props.filters && typeof index === 'number') {
      field = this.props.filters[index]?.field;
    } else {
      field = type;
    }
    const parsedSearch = deserializeQueryString(this.props.location.search);
    if (value) {
      parsedSearch[field] = value;
    } else {
      delete parsedSearch[field];
    }
    let querySearch = '';
    if (this.props.filters) {
      querySearch = this.props.filters
        .map(filter => {
          const filterValue = parsedSearch[filter.field];
          delete parsedSearch[filter.field];
          return filterValue ? `${filter.field}=${filterValue}` : undefined;
        })
        .filter(item => !!item)
        .join('&');
    }
    if (this.props.search?.length && parsedSearch.search) {
      if (querySearch.length) {
        querySearch += '&';
      }
      querySearch += `search=${parsedSearch.search}`;
      delete parsedSearch.search;
    }
    const filteredParsedSearch = Object.keys(parsedSearch).filter(key => !!key);
    if (filteredParsedSearch.length) {
      if (querySearch.length) {
        querySearch += '&';
      }
      querySearch += filteredParsedSearch.map(key => `${key}=${parsedSearch[key]}`).join('&');
    }
    this.props.history.replace({ search: `?${querySearch}` });
  };

  setFilter = (event: any, index: number) => {
    if (event && event.target) {
      const filters = this.state.filters;
      if (filters) {
        filters[index] = event.target.value;
        if (!this.props.disableQueryParams) {
          this.setQueryParams(event.target.value, 'filters', index);
        }
      }
      this.setState({ filters }, () => {
        this.getData(0);
      });
    }
  };

  setFilterByValue = (value: any, index: number, clearFilters?: boolean) => {
    let filters = this.state.filters;
    if (clearFilters) {
      const parsedSearch = deserializeQueryString(this.props.location.search);
      filters = filters?.map((item, idx) => {
        item = idx === index ? value : '';
        if (!this.props.disableQueryParams) {
          const field = (this.props.filters && this.props.filters[idx]?.field) || '';
          if (item) {
            parsedSearch[field] = item;
          } else if (parsedSearch[field]) {
            delete parsedSearch[field];
          }
        }
        return item;
      });
      const querySearch = Object.keys(parsedSearch)
        .map(key => `${key}=${parsedSearch[key]}`)
        .join('&');
      this.props.history.replace({ search: `?${querySearch}` });
    } else if (filters) {
      filters[index] = value;
      if (!this.props.disableQueryParams) {
        this.setQueryParams(value, 'filters', index);
      }
    }
    this.setState({ filters }, () => {
      this.getData(0);
    });
  };

  setSearch = (event: any) => {
    if (event && event.target) {
      this.setState({ search: event.target.value }, () => {
        this.getDataDebounced(0);
      });
      if (!this.props.disableQueryParams) {
        this.setQueryParams(encodeURIComponent(event.target.value), 'search');
      }
    }
  };

  setSearchByValue = (value: string = this.state.search || '') => {
    this.setState({ search: value }, () => {
      this.getDataDebounced(0);
    });
    if (!this.props.disableQueryParams) {
      this.setQueryParams(encodeURIComponent(value), 'search');
    }
  };

  clearSearch = () => {
    if (!this.props.disableQueryParams) {
      this.setQueryParams('', 'search');
    }
    this.setState({ search: '' }, () => {
      this.getDataDebounced(0);
    });
  };

  setSort = (field: string) => () => {
    let newSort = field;
    if (this.state.sort && this.props.sort && this.props.sort.fields.indexOf(field) >= 0) {
      let sort = this.state.sort.split(',');
      if (sort[0] === field && sort[1] === '1') {
        newSort += ',-1';
      } else {
        newSort += ',1';
      }
      if (!this.props.disableQueryParams) {
        this.setQueryParams(newSort, 'sort');
      }
      this.setState({ sort: newSort }, () => {
        this.getData(0);
      });
    }
  };

  setDateStartEndRange = (start: any, end: any) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    this.displayedDateRange = [startDate, endDate];
    const dateInterval = {
      startTime: startDate.setHours(0, 0, 0),
      endTime: endDate.setHours(23, 59, 59)
    };
    if (!this.props.disableQueryParams) {
      this.setQueryParams(JSON.stringify(dateInterval), this.props.dateInterval?.field || '');
    }
    this.setState({ dateInterval }, () => {
      this.getData(0);
    });
  };

  handlePopoverClick = (event: any, itemId: string) => {
    this.setState({ popoverAnchor: event.currentTarget, popoverId: itemId });
  };

  handlePopoverClose = () => {
    this.setState({ popoverAnchor: null, popoverId: '' });
  };

  getOptions() {
    return (
      <div className="row">
        {this.props.search.length > 0 && (
          <div className="col-sm-4" style={{ margin: 0, padding: 0, paddingLeft: 15 }}>
            <TextFieldComponent
              label={msg('general.search', 'Search')}
              id="search"
              value={this.state.search ? this.state.search : ''}
              onChange={this.setSearch}
              inType={this.state.search ? 'clearable' : 'text'}
              handleClear={this.clearSearch}
              maxLength={50}
              required={false}
            />
          </div>
        )}
        {this.props.filters &&
          this.filterValues?.map((filter, index) => (
            <div key={index} className="col-sm-3">
              <SimpleSelectComponent
                label={msg('general.filterBy', 'Filter by')}
                name={`noOfFilterOptions${index}`}
                options={filter.value || []}
                id={`noOfFilterOptions${index}`}
                value={this.state.filters ? this.state.filters[index] : ''}
                onChange={(event: any) => this.setFilter(event, index)}
                required={false}
                needsAllLabel={false}
                arrayOptions={false}
                shrinkLabel={true}
              />
            </div>
          ))}
        {this.props.dateInterval && (
          <div className="col-sm-3" style={{ height: 48, display: 'flex', alignItems: 'flex-end' }}>
            <label className="MuiFormLabel-root MuiInputLabel-formControl MuiInputLabel-shrink">
              {msg('general.selectDate', 'Select date')}
            </label>
            <DateRangePicker
              defaultValue={this.displayedDateRange}
              getRangeData={this.setDateStartEndRange}
              customInputClass="customDatePickerInput"
            />
          </div>
        )}
      </div>
    );
  }

  getHeader() {
    return (
      <tr className="sticky-header sticky-border-bottom">
        {this.props.checkbox && (
          <th>
            <Checkbox
              checked={this.props.checkbox.selectedAll}
              indeterminate={this.props.checkbox.intermediate}
              onChange={this.props.checkbox.onSelectAll}
              color="primary"
            />
          </th>
        )}
        {this.props.images &&
          this.props.images.map((item: any, i: number) => {
            return <th key={i}>{item.name}</th>;
          })}
        {this.state.fields.map(item => {
          let sortIcon = null;
          if (this.props.sort && this.props.sort.fields.indexOf(item.key) >= 0) {
            if (this.state.sort && this.state.sort.indexOf(item.key) >= 0) {
              const sortDirection = this.state.sort.split(',')[1];
              if (sortDirection === '1') {
                sortIcon = (
                  <i className="material-icons" style={upIconStyle}>
                    arrow_drop_up
                  </i>
                );
              } else {
                sortIcon = (
                  <i className="material-icons" style={upIconStyle}>
                    arrow_drop_down
                  </i>
                );
              }
            } else {
              sortIcon = (
                <i className="material-icons" style={sortIconStyle}>
                  sort
                </i>
              );
            }
          }
          return (
            <th key={item.key} onClick={this.setSort(item.key)} style={{ cursor: sortIcon ? 'pointer' : 'default' }}>
              {item.value}
              {sortIcon}
            </th>
          );
        })}
        {this.props.customColumn && <th key="custom">{this.props.customColumn.name}</th>}
        {this.props.input?.map((item: any, i: number) => {
          return <th key={i}>{item.name}</th>;
        })}
        {this.props.select?.map((item: any, i: number) => {
          return <th key={i}>{item.name}</th>;
        })}
        {this.props.toggles?.map((item: any, i: number) => {
          return (
            <th key={i} style={{ width: 120 }}>
              {item.name}
            </th>
          );
        })}
        {this.actions?.length ? <th style={{ width: 140 }}>{msg('general.actions', 'Actions')}</th> : null}
      </tr>
    );
  }

  getActions(listItem: any, isPopover?: boolean) {
    return this.actions?.map((action, aKey) => {
      if (action.isShown && !action.isShown(listItem)) {
        return null;
      }
      return (
        <div
          key={aKey}
          className={isPopover ? 'advanced-list-actions' : 'advanced-list-button'}
          id={listItem._id}
          onClick={event => {
            if (isPopover) {
              this.handlePopoverClose();
              document.body.style.removeProperty('overflow');
              document.body.style.removeProperty('padding-right');
            }
            if (action.returnFields?.length) {
              let returnedFields = {};
              if (action.returnFields[0] === '') {
                returnedFields = listItem;
              } else {
                action.returnFields.forEach(field => {
                  returnedFields[field] = listItem[field];
                });
              }
              action.onClick(returnedFields);
            } else {
              action.onClick(event);
            }
          }}
        >
          <button
            key={aKey}
            type="button"
            className={`btn ${action.btn} popover-btn`}
            title={!isPopover ? action.label : ''}
          >
            <i className="material-icons">{action.icon}</i>
          </button>
          {isPopover && <div style={{ paddingLeft: 3 }}>{action.label}</div>}
        </div>
      );
    });
  }

  getBody() {
    return this.state.data.results?.map((listItem, key) => {
      return (
        <tr key={key}>
          {this.props.checkbox && (
            <td>
              <Checkbox
                checked={listItem[this.props.checkbox.field]}
                onChange={() => {
                  this.props.checkbox?.onChange(listItem._id);
                }}
                color="primary"
              />
            </td>
          )}
          {this.props.images?.map((item: any, i: number) => {
            return (
              <td key={i}>
                {listItem[item.field] ? (
                  <div className="zoom-image">
                    <div className="zoom-overlay" />
                    <ReactImageZoom
                      height={300}
                      img={listItem[item.field]}
                      scale={1}
                      zoomLensStyle="cursor:crosshair; border:1px solid white; z-index:2; background-color:rgba(255,255,255,0.15)"
                    />
                  </div>
                ) : listItem.renderPlaceholder ? (
                  listItem.renderPlaceholder()
                ) : (
                  <div />
                )}
              </td>
            );
          })}
          {this.state.fields.map((fieldItem, i) => {
            if (fieldItem?.key?.indexOf('.') > 0) {
              const f = fieldItem.key.split('.');
              let value = listItem[f[0]][f[1]]?.toString() || 'N/A';
              return (
                <td key={i} style={{ fontWeight: listItem.read === false ? 'bold' : 'normal' }}>
                  {value}
                </td>
              );
            } else if (
              (fieldItem?.key && listItem[fieldItem.key]) ||
              (fieldItem?.key && listItem[fieldItem.key] === 0)
            ) {
              let value = listItem[fieldItem.key];
              if (typeof value === 'object' && value.type === 'custom') {
                return <td key={i}>{value.render()}</td>;
              } else {
                value = value.toString();
              }
              return (
                <td key={i} style={{ fontWeight: listItem.read === false ? 'bold' : 'normal' }}>
                  {listItem.badge && fieldItem.key === 'statusLabel' ? (
                    <div className="badge" style={{ backgroundColor: listItem.badge }}>
                      {value}
                    </div>
                  ) : (
                    value
                  )}
                </td>
              );
            } else {
              return <td key={i}>N/A</td>;
            }
          })}
          {this.props.customColumn && <td key="custom">{this.props.customColumn.renderColumn(listItem)}</td>}
          {this.props.input?.map((item: any, i: number) => {
            return (
              <td key={i}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextFieldComponent
                    id={item._id}
                    value={listItem[item.field] || ''}
                    onChange={(event: any) => item.onChange(key, event.target.value)}
                    onBlur={() => item.onBlur(key)}
                    onKeyUp={(event: any) => {
                      if (event.keyCode === 13) {
                        item.onBlur(key);
                      }
                    }}
                    readOnly={item.readOnly ? item.readOnly(listItem) : false}
                    required={false}
                    style={{ padding: 0, width: 75 }}
                    formatError={!listItem.isValid && item.errorText}
                    validatorIgnore={!item.validate || !listItem.isDirty}
                  />
                  <div style={{ marginLeft: 5 }}>{item.label}</div>
                </div>
              </td>
            );
          })}
          {this.props.select?.map((item: any, i: number) => {
            return (
              <td key={i}>
                <SimpleSelectComponent
                  name={item.name}
                  options={item.options}
                  id={item._id}
                  value={listItem[item.field] || ''}
                  onChange={item.onChange(listItem._id)}
                  required={false}
                  needsAllLabel={false}
                  arrayOptions={false}
                  style={{ padding: 0, paddingRight: 15 }}
                  needsFullLength={true}
                />
              </td>
            );
          })}
          {this.props.toggles?.map((item: any, i: number) => {
            return (
              <td key={i}>
                <Tooltip title={item.tooltip || ''}>
                  <div>
                    <Toggle
                      onChange={(event: any) => {
                        this.changedElement = listItem._id;
                        item.onChange(listItem._id)(event);
                      }}
                      checked={listItem[item.field]}
                      disabled={item.disabled}
                    />
                  </div>
                </Tooltip>
              </td>
            );
          })}
          {this.actions?.length ? (
            this.actions.length < 4 ? (
              <td className="td-actions">{this.getActions(listItem)}</td>
            ) : (
              <td className="td-actions">
                <i
                  className="material-icons"
                  style={{ color: colors.green, cursor: 'pointer', fontSize: 28 }}
                  id={listItem._id}
                  onClick={e => this.handlePopoverClick(e, listItem._id)}
                >
                  more_vert
                </i>
                <Popover
                  open={!!this.state.popoverAnchor && this.state.popoverId === listItem._id}
                  anchorEl={this.state.popoverAnchor}
                  onClose={this.handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', padding: 5 }}>
                    {this.getActions(listItem, true)}
                  </div>
                </Popover>
              </td>
            )
          ) : null}
        </tr>
      );
    });
  }

  render() {
    const isData: Boolean = this.isData();
    const pending: Boolean = this.props.pending;
    const statsFields = this.props.statsFields;
    const { data } = this.state;
    return (
      <div className="table-responsive" style={{ minHeight: '20vh' }}>
        {this.getOptions()}
        {isData && (
          <>
            <div
              ref={this.scrollDivRef}
              style={{
                height: this.props.fixedHeight || (this.props.hasCardButton ? '52vh' : '58vh'),
                overflowY: 'auto'
              }}
              onScroll={this.scrollHandler}
            >
              <table className={`table table-hover ${this.props.tableClassName || ''}`}>
                <thead className="text-primary">{this.getHeader()}</thead>
                <tbody>{this.getBody()}</tbody>
              </table>
              {data.results?.length === data.total_record_count && data.total_record_count > 10 ? (
                <div style={{ textAlign: 'center', padding: 10, fontSize: 16 }}>
                  {msg('general.endOfResultList', 'No more results to show')}
                </div>
              ) : null}
            </div>
            <div style={{ margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                {statsFields &&
                  data.stats &&
                  Object.keys(statsFields).map(key => {
                    const parser = statsFields?.[key]?.parser;
                    return (
                      <div key={key} className="badge" style={{ backgroundColor: colors.statsLightGreen, margin: 5 }}>
                        {statsFields?.[key]?.label}:{' '}
                        {parser ? parser(data.stats?.[key] || 0) : data.stats?.[key] || 'N/A'}
                      </div>
                    );
                  })}
              </div>
              <div className="badge" style={{ backgroundColor: colors.gray, margin: 5 }}>
                {msg(
                  'pagination.showFromToTotalInfinite',
                  `Showing ${data.results?.length} from ${data.total_record_count} entries`,
                  {
                    current: `${data.results?.length}`,
                    total: `${data.total_record_count}`
                  }
                )}
              </div>
            </div>
          </>
        )}
        {pending && (
          <div style={{ ...spinnerOverlayStyle }}>
            <h4 style={{ color: colors.white }}>{msg('general.loading', 'Loading...')}</h4>
            <Bounce color={colors.white} />
          </div>
        )}
        {!isData && !pending && (
          <h3 style={{ marginTop: 60 }}>{msg('general.noAvailableData', 'There is no available data.')}</h3>
        )}
      </div>
    );
  }
}

export default withRouter(InfiniteScrollListComponent);
