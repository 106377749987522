import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { msg } from '../../../../utils';
import { SimpleSelectComponent, ListComponent } from '../../../shared';
import { Bounce } from 'react-activity';
import { colors } from '../../../../assets/styles';

interface IProps {
  sentTo: any;
  pending: boolean;
  toggleModal: () => any;
}

const filterOptions = [
  { value: 'ALL', name: msg('partnerEmail.all', 'All') },
  { value: 'READ', name: msg('partnerEmail.read', 'Read') },
  { value: 'UNREAD', name: msg('partnerEmail.unread', 'Unread') }
];

const SentToModalComponent = (props: IProps) => {
  const [filter, setFilter] = useState(filterOptions[0].value);

  const getData = () => {
    let list = [];
    switch (filter) {
      case filterOptions[0].value:
        list = props.sentTo;
        break;
      case filterOptions[1].value:
        list = props.sentTo.filter((user: any) => user.read);
        break;
      case filterOptions[2].value:
        list = props.sentTo.filter((user: any) => !user.read);
        break;
      default:
        list = props.sentTo;
    }
    return list.map((user: any) => {
      return {
        ...user,
        partners: user.partners?.length ? user.partners.map((partner: any) => partner.partnerName).join(', ') : '-',
        places: user.places?.length ? user.places.map((place: any) => place.placeName).join(', ') : '-'
      };
    });
  };

  const filterHandler = (event: any) => {
    setFilter(event.target.value);
  };

  return (
    <Modal show={true} onHide={props.toggleModal} dialogClassName="sentToModal">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center'
        }}
      >
        <div className="col-sm-3" style={{ fontSize: 18, fontWeight: 300, marginLeft: 5 }}>
          {msg('partnerEmail.sentToList', 'Users List')}
        </div>
        <div className="col-sm-2" style={{ marginRight: 10 }}>
          <SimpleSelectComponent
            id="id"
            name="name"
            options={filterOptions}
            value={filter}
            onChange={filterHandler}
            needsAllLabel={false}
            hasNameValue={true}
            arrayOptions={true}
            required={false}
          />
        </div>
      </div>
      <Modal.Body style={{ maxHeight: 700, overflowX: 'auto' }}>
        {props.pending ? (
          <div style={{ textAlign: 'center', padding: 20, marginTop: 25 }}>
            <Bounce color={colors.lightGreen} />
          </div>
        ) : (
          <ListComponent
            data={{
              fields: {
                lastname: msg('partnerEmail.lastname', 'Lastname'),
                firstname: msg('partnerEmail.firstname', 'Firstname'),
                email: msg('partnerEmail.email', 'Email'),
                partners: msg('partnerEmail.partnerAdmins', 'Partner Admins'),
                places: msg('partnerEmail.placeAdmins', 'Place Admins')
              },
              list: getData(),
              badges: {
                read: msg('partnerEmail.readBy', 'Read')
              },
              pending: false,
              error: null
            }}
          />
        )}
      </Modal.Body>
      <Modal.Footer style={{ marginTop: 25 }}>
        <Button className="btn btn-success" onClick={props.toggleModal}>
          {msg('general.close', 'Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SentToModalComponent;
