export enum TimeIntervalType {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months'
}

export class TagDTO {
  _id?: string;
  name: string = '';
  description?: string;
  color?: string = '';
  priority?: number;
  placeId?: string;
  created?: string;
  action?: TagActionDTO;
}

export class TagActionDTO {
  downgradeInterval?: number;
  downgradeIntervalType: TimeIntervalType = TimeIntervalType.MONTHS;
  target?: number;
}
