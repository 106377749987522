import { Dispatch } from 'redux';

import { AlertUtil, composedCriteriaBuilder, logger, msg, Server } from '../../utils';
import { PaginationDTO } from '../types';
import { CheckinsStore } from './';

/*
  ICheckinsActions interface definition, which contains every redux action asociated with Checkins State.
*/
export interface ICheckinsActions {
  /*
    General actions and super admin actions
  */
  getChangesOfCheckinAction(
    checkinId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;

  /*
    Actions specific for a place
  */
  getCheckinsForOnePlaceAction(
    placeId: string,
    partnerId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
}

/*
  class CheckinsActions that implements redux actions defined in ICheckinsActions interface
*/
class CheckinsActions implements ICheckinsActions {
  /*
    @function getChangesOfCheckinAction => Redux action that gets all changes that happened during an active check-in
      @accepts checkinId : string representing the Id of the checkin to be brought its changes
      (optional) limit, skip, sort, criteria : params used for pagination
      @returns Promise
  */
  getChangesOfCheckinAction(
    checkinId: string,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: CheckinsStore.ActionTypes.GET_CHANGES });
      try {
        if (!limit) {
          limit = 10;
        }
        let url = `changes?checkinId=${checkinId}&limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get changes for checkin action, route:/changes?checkinId', 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: CheckinsStore.ActionTypes.GET_CHANGES_SUCCESS,
          payload: response.data as PaginationDTO<any>
        });
      } catch (error) {
        logger.err('Get changes for checkin action, route:/changes?checkinId', 'GET');
        AlertUtil.simple(
          msg(
            'reduxMessages.checkins.getChangesError',
            'Due to an error, the transaction history could not be loaded!'
          ),
          'error',
          2000
        );
        dispatch({
          type: CheckinsStore.ActionTypes.GET_CHANGES_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  /*
    @function getCheckinsForOnePlaceAction => Redux action that gets all checkins from a single place
      @accepts placeId : string representing the id of the place from whom we get all checkins
               partnerId : string representing the id of the partner of which the place belongs to
      (optional) limit, skip, sort, criteria : params used for pagination
      @returns Promise
  */
  getCheckinsForOnePlaceAction(
    placeId: string,
    partnerId: string,
    limit?: number,
    skip?: number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: CheckinsStore.ActionTypes.GET_CHECKINS });
      try {
        if (!limit) {
          limit = 10;
        }
        let url = `places/${placeId}/checkins?partnerId=${partnerId}&limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get checkins list for place action, route:/places/placeId/checkins?partnerId', 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: CheckinsStore.ActionTypes.GET_CHECKINS_SUCCESS,
          payload: response.data as PaginationDTO<any>
        });
      } catch (error) {
        logger.err('Get checkins list for place action, route:/places/placeId/checkins?partnerId', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.checkins.getCheckinsError', 'Due to an error, the check-ins list could not be loaded!'),
          'error',
          2000
        );
        dispatch({
          type: CheckinsStore.ActionTypes.GET_CHECKINS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }
}

const checkinsActions = new CheckinsActions();
export default checkinsActions;
