import * as React from 'react';
import { CardComponent, AdvancedListComponent } from '../../../shared';
import { AlertUtil, msg, getFormatDate } from '../../../../utils';
import { PaginationDTO } from '../../../../appRedux/types';
interface IProps {
  config: {
    onboardings: PaginationDTO<any>;
    get_all_onboardings_pending: boolean;
    get_all_onboardings_error: string | null;
    getAllOnboardingsAction: (
      limit?: Number,
      skip?: Number,
      sort?: string,
      criteria?: { [key: string]: string }
    ) => any;
    deleteOnboardingAction: (onboardingId: string) => any;
    redirectToOnboardingView: (onboardingId: string) => any;
    getFlagsForOnboarding: () => any;
  };
}
interface IState {}

export default class OnboardingsListComponent extends React.Component<IProps, IState> {
  componentDidMount() {
    this.props.config.getFlagsForOnboarding();
  }

  editAction = (event: any) => {
    const onboardingId = event.currentTarget.getAttribute('id');
    if (onboardingId) {
      window.open(`/onboard/${onboardingId}`);
    }
  };

  deleteAction = async (event: any) => {
    const onboardingId = event.currentTarget.getAttribute('id');
    const hasConfirmation = await AlertUtil.confirm('You are about to delete this onboarding!');
    if (hasConfirmation) {
      this.props.config.deleteOnboardingAction(onboardingId);
    }
  };

  viewAction = (event: any) => {
    const onboardingId = event.currentTarget.getAttribute('id');
    this.props.config.redirectToOnboardingView(onboardingId);
  };

  getActions = () => {
    return [
      {
        label: msg('onboard.viewOnboarding', 'View onboarding'),
        btn: 'btn-info',
        icon: 'info',
        onClick: this.viewAction
      },
      {
        label: msg('general.edit', 'Edit'),
        btn: 'btn-success',
        icon: 'edit',
        onClick: this.editAction
      },
      {
        label: msg('general.delete', 'Delete'),
        btn: 'btn-danger',
        icon: 'delete',
        ref: 'deleteEvent',
        onClick: this.deleteAction
      }
    ];
  };

  getOnboardingStatus = (onboarding: any) => {
    const config = onboarding.config;
    return config?.emailSent
      ? msg('onboard.emailSent', 'Email Sent')
      : config?.placeActivated
      ? msg('onboard.placeActivated', 'Place activated')
      : config?.userId
      ? msg('onboard.userCreated', 'User Created')
      : config?.placeId
      ? msg('onboard.placeCreated', 'Place Created')
      : config?.partnerId
      ? msg('onboard.partnerCreated', 'Partner Created')
      : msg('onboard.pending', 'Pending');
  };

  parserOnboardings = (onboardings: PaginationDTO<any>): any => {
    let results;
    if (onboardings.results) {
      results = onboardings.results.map((onboarding: any) => {
        return {
          ...onboarding,
          firstname: onboarding?.step1?.firstname,
          lastname: onboarding?.step1?.lastname,
          placeName: onboarding?.step2?.name,
          actualStep: onboarding.actualStep === 4 ? msg('onboard.finished', 'Finished') : onboarding.actualStep,
          status: this.getOnboardingStatus(onboarding),
          created: getFormatDate(onboarding?.created, 'hour')
        };
      });
    }
    return { ...onboardings, results };
  };

  getOnboardings = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    return this.props.config.getAllOnboardingsAction(limit, skip, sort, criteria);
  };

  render() {
    const onboardings = this.parserOnboardings(this.props.config.onboardings);
    return (
      <CardComponent title={msg('sidebar.onboardings', 'Onboardings')} headerIcon="list" needsTitle={true}>
        <AdvancedListComponent
          list={onboardings}
          fields={{
            firstname: msg('onboard.firstname', 'Firstname'),
            lastname: msg('onboard.lastname', 'Lastname'),
            placeName: msg('onboard.placeName', 'Place Name'),
            actualStep: msg('onboard.actualStep', 'Pasul actual'),
            status: msg('onboard.status', 'Status'),
            created: msg('onboard.created', 'Created')
          }}
          sort={{
            fields: ['created', 'actualStep'],
            default: 'created,-1'
          }}
          pending={this.props.config.get_all_onboardings_pending}
          error={this.props.config.get_all_onboardings_error}
          search={['step2.name']}
          limit={30}
          get={this.getOnboardings}
          actions={this.getActions()}
        />
      </CardComponent>
    );
  }
}
