import React from 'react';

import { AdvancedListComponent, CardComponent, ButtonComponent } from '../../../shared';
import { PlaceDTO, PaginationDTO, IPackage } from '../../../../appRedux/types';
import { AlertUtil, msg } from '../../../../utils';

interface IProps {
  partnerId: string;
  forSAdmin: boolean;
  config: {
    get: (partnerId: string, limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }) => any;
    list: PaginationDTO<PlaceDTO>;
    pending: boolean;
    error: string | null;
    redirectToEditSAdmin: (placeId: string) => any;
    deleteAsSAdmin: (placeId: string, partnerId: string) => any;
    redirectToAddSAdmin: (partnerId: string) => any;
    redirectToPlaceAdminsAction: (placeId: string, forSAdmin: boolean) => any;
    availablePackages: IPackage[];
    fetchAvailablePackages: () => any;
    changePlacePackage: (place: any) => any;
  };
  handleChangeStatus?: any;
  match?: any;
}
interface IState {}

export default class PlacesListComponent extends React.Component<IProps, IState> {
  componentDidMount() {
    this.props.config.fetchAvailablePackages();
  }

  getPlaces = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    this.props.config.get(this.props.partnerId, limit, skip, sort, criteria);
  };

  isData() {
    return this.props.config.list && this.props.config.list.results && this.props.config.list.results.length;
  }

  addAction = (event: any) => {
    this.props.config.redirectToAddSAdmin(event.currentTarget.getAttribute('id'));
  };

  editAction = (event: any) => {
    this.props.config.redirectToEditSAdmin(event.currentTarget.getAttribute('id'));
  };

  redirectToAdminsAction = (event: any) => {
    this.props.config.redirectToPlaceAdminsAction(event.currentTarget.getAttribute('id'), true);
  };

  deleteAction = async (event: any) => {
    const id = event.currentTarget.getAttribute('id');
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.placeDeleteConfirmation', 'You are about to delete a place!')
    );
    if (hasConfirmation) {
      if (this.props.forSAdmin) {
        this.props.config.deleteAsSAdmin(id, this.props.partnerId);
      }
    }
  };

  getActions = () => [
    {
      label: msg('cardTitle.placeAdmins', 'Place admins'),
      btn: 'btn-info',
      icon: 'manage_accounts',
      onClick: this.redirectToAdminsAction
    },
    {
      label: msg('general.edit', 'Edit'),
      btn: 'btn-success',
      icon: 'edit',
      onClick: this.editAction
    },
    {
      label: msg('general.delete', 'Delete'),
      btn: 'btn-danger',
      icon: 'delete',
      onClick: this.deleteAction
    }
  ];

  handleChangeStatus = (itemId: any) => (event: any) => {
    if (this.props.match.params.partnerId && this.props.handleChangeStatus) {
      this.props.handleChangeStatus(this.props.match.params.partnerId, itemId, {
        _id: itemId,
        isActive: event.target.checked
      });
    }
  };

  handlePackageChange = (placeId: string) => (event: any) => {
    const currentPlace = this.props.config.list.results.find(place => place._id === placeId) || ({} as PlaceDTO);
    const packageId = event.target.value;
    this.props.config.changePlacePackage({
      _id: currentPlace._id,
      packageId
    });
  };

  render() {
    return (
      <CardComponent
        title={msg('sidebar.places', 'Places')}
        error={!!this.props.config.error}
        headerIcon="location_city"
        needsTitle={true}
      >
        <AdvancedListComponent
          fields={{
            name: msg('place.name', 'Name'),
            phone: msg('place.phone', 'Phone'),
            city: msg('place.city', 'City')
          }}
          actions={this.getActions()}
          list={this.props.config.list}
          pending={this.props.config.pending}
          error={this.props.config.error}
          get={this.getPlaces}
          limit={10}
          sort={{
            fields: ['name', 'city'],
            default: 'name,1'
          }}
          search={['name', 'phone', 'city']}
          disabled={'1'}
          toggles={[{ name: msg('place.isActive', 'Active'), onChange: this.handleChangeStatus, field: 'isActive' }]}
          select={[
            {
              name: msg('placeSettings.selectPackage', 'Package'),
              options: this.props.config.availablePackages || [],
              field: 'packageId',
              onChange: this.handlePackageChange
            }
          ]}
        />
        <div className="row" style={{ margin: 10 }}>
          <div className="col-md-3" />
          <div className="col-md-6" style={{ textAlign: 'center' }}>
            <div className="form-group form-button">
              <ButtonComponent
                label={msg('cardTitle.addNewPlace', 'Add new place')}
                icon="add_location"
                action={this.addAction}
                id={this.props.partnerId}
              />
            </div>
          </div>
          <div className="col-md-3" />
        </div>
      </CardComponent>
    );
  }
}
