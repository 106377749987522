import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PlaceActivity from './PlaceActivity';
import { partnerRoutes, adminRoutes } from '../../layouts';

export const ActivityRouter = () => {
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <Switch>
            {/* <Route
              exact={true}
              path="/partner/checkins"
              render={() => <CheckinsListComponent config={checkinsListProps} partnerId={partnerId} />}
            /> */}
            <Route exact={true} path={partnerRoutes.ACTIVITY.default} render={() => <PlaceActivity />} />
            <Route exact={true} path={adminRoutes.ACTIVITY.path} render={() => <PlaceActivity />} />
          </Switch>
        </div>
      </div>
    </div>
  );
};
