import * as React from 'react';
import { matchPath, NavLink } from 'react-router-dom';
import Select, { components } from 'react-select';

import { Breadcrumbs, Hidden } from '@material-ui/core';

import { LANGUAGES } from '../../../appRedux/translation/types';
import { SelectedPartnerDTO, SelectedPlaceDTO } from '../../../appRedux/types';
import { msg } from '../../../utils';
import { adminRoutes, partnerRoutes } from '../../layouts';
import { SimpleSelectComponent } from '../../shared';

const { ValueContainer, Placeholder } = components;

interface IProps {
  actions: {
    partners?: SelectedPartnerDTO[];
    places?: SelectedPlaceDTO[];
    selected_place?: string | null;
    selected_partner?: string | null;
    initial_language: string;
    changeLanguage: (option: string) => any;
    selectPlace?: (placeId: string) => any;
    selectPartner?: (partnerId: string) => any;
    toggleSideBar: () => void;
  };
}

interface IState {
  anchorEl: any;
  currentPartner: SelectedPartnerDTO;
  currentPlaces: SelectedPlaceDTO[];
}

export class TopbarComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { places, partners, selected_partner } = this.props.actions;
    const currentPartner =
      partners?.find((item: SelectedPartnerDTO) => item._id === selected_partner) || new SelectedPartnerDTO();
    const currentPlaces = currentPartner?.places || places || [];
    this.state = {
      anchorEl: null,
      currentPartner,
      currentPlaces
    };
  }

  componentDidMount() {
    const match: any = matchPath(window.location.pathname, {
      path: ['/partner/([a-z]+)/:id/*', '/partner/*/:id']
    });
    const pathId = match?.params.id;
    if (pathId && pathId !== this.props.actions.selected_place) {
      const partners = this.props.actions.partners || [];
      const partner = partners.find(item => item.places.find(place => place._id === pathId));
      if (partner) {
        this.handlePartnerChange({ label: partner.name, value: partner._id });
      }
    }
  }

  handlePlaceChange = (place: { label: string; value: string }) => {
    const value = place.value;
    const placeList = this.state.currentPlaces;
    const foundPlace = placeList?.find((item: SelectedPlaceDTO) => item._id === value);
    if (foundPlace && this.props.actions.selectPlace) {
      this.props.actions.selectPlace(foundPlace._id);
      if (
        foundPlace.partnerId &&
        foundPlace.partnerId !== this.props.actions.selected_partner &&
        this.props.actions.selectPartner
      ) {
        this.props.actions.selectPartner(foundPlace.partnerId);
      }
    }
  };

  handlePartnerChange = (partner: { label: string; value: string }) => {
    const value = partner.value;
    const foundPartner = this.props.actions.partners?.find((item: SelectedPartnerDTO) => item._id === value);
    if (foundPartner && this.props.actions.selectPartner && this.props.actions.selectPlace) {
      this.props.actions.selectPartner(foundPartner._id);
      this.props.actions.selectPlace(foundPartner.places[0]._id);
      this.setState({ currentPartner: foundPartner, currentPlaces: foundPartner.places });
    }
  };

  parseOptionsForSelect = (options: any) => {
    const results: { label: string; value: string }[] = [];
    if (options && options.length) {
      options.forEach((item: any) => {
        results.push({ label: item.name, value: item._id });
      });
    }
    return results;
  };

  parseCurrentForSelect = (id: any, options: any) => {
    const item = options?.find((current: any) => current._id === id);
    return { label: item.name, value: item._id };
  };

  handleLanguageChange = (event: any) => {
    const fieldValue = event.target.value;
    this.props.actions.changeLanguage(fieldValue);
  };

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  colourStyles = () => ({
    control: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: '#eee',
      border: 'none',
      boxShadow: state.isFocused ? '0px 2px 0px #4caf50' : '0px 1px 0px rgba(0, 0, 0, 0.42)',
      borderRadius: 0,
      ':hover': {
        boxShadow: state.menuIsOpen ? '0px 2px 0px #4caf50' : '0px 2px 0px #3C4858'
      },
      fontSize: '16px'
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? 'rgba(0, 0, 0, 0.14)' : 'white',
      color: state.isSelected && '#333',
      fontSize: '16px'
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      position: 'absolute',
      top: '-8px',
      fontSize: '12px',
      transform: 'translateY(-50%)',
      color: state.selectProps.menuIsOpen ? '#4caf50' : 'rgba(0, 0, 0, 0.54)'
    })
  });

  selectPlaceholderFix = (props: any) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(props.children, child => (child && child.type !== Placeholder ? child : null))}
      </ValueContainer>
    );
  };

  getRoutes = (routes: any) => {
    const matches: any = [];
    const pathname = window.location.pathname;
    pathname.split('/').reduce((previous: any, current: any) => {
      const pathSection = `${previous}/${current}`;
      const allRoutes = matches.length
        ? Object.values(matches[matches.length - 1].subroutes || {})
        : Object.values(routes);
      allRoutes.find((route: any) => {
        const match = matchPath(pathSection, { exact: true, path: route.default || route.path });
        if (match) {
          matches.push({ ...route, path: match.url });
          return true;
        }
        return false;
      });
      return pathSection;
    });
    return matches;
  };

  render() {
    const { actions } = this.props;
    const places = this.state.currentPlaces;
    const breadcrumbs = actions.selected_partner ? this.getRoutes(partnerRoutes) : this.getRoutes(adminRoutes);
    return (
      <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute" id="scrollToElement">
        <div className="container-fluid">
          <div className="navbar-header" style={{ width: '100%' }}>
            <div
              className="navbar-wrapper"
              style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingRight: 15 }}
            >
              <Hidden smDown={!(actions.partners || actions.places)}>
                <div
                  className="navbar-mobile"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap'
                  }}
                >
                  {actions.partners && actions.partners.length > 1 && (
                    <div style={{ minWidth: 175, margin: '2px 0px 4px 15px' }}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={this.parseOptionsForSelect(actions.partners) || []}
                        components={{
                          ValueContainer: this.selectPlaceholderFix
                        }}
                        placeholder={msg('topbar.selectPartner', 'Select a partner:')}
                        onChange={(partner: { label: string; value: string }) => this.handlePartnerChange(partner)}
                        value={this.parseCurrentForSelect(actions.selected_partner, actions.partners) || ''}
                        styles={this.colourStyles()}
                      />
                    </div>
                  )}
                  {places.length > 1 ? (
                    <div style={{ minWidth: 175, margin: '2px 0px 4px 15px' }}>
                      <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={this.parseOptionsForSelect(places) || []}
                        components={{
                          ValueContainer: this.selectPlaceholderFix
                        }}
                        placeholder={msg('topbar.selectPlace', 'Select a place:')}
                        onChange={(place: { label: string; value: string }) => this.handlePlaceChange(place)}
                        value={this.parseCurrentForSelect(actions.selected_place, places) || ''}
                        styles={this.colourStyles()}
                      />
                    </div>
                  ) : (
                    actions.partners?.length === 1 && (
                      <div className="navbar-brand" style={{ top: 2 }}>
                        {places[0].name}
                      </div>
                    )
                  )}
                  <div style={{ margin: '10px 45px', fontSize: 16 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                      {breadcrumbs?.length > 1 &&
                        breadcrumbs.map((breadcrumb: any) => (
                          <span key={breadcrumb.path}>
                            <NavLink to={breadcrumb.path}>
                              <span className="breadcrumbItem">
                                {msg(`sidebar.${breadcrumb.name}`, breadcrumb.name)}
                              </span>
                            </NavLink>
                          </span>
                        ))}
                    </Breadcrumbs>
                  </div>
                </div>
              </Hidden>
              <div style={{ alignSelf: 'flex-start', paddingLeft: 8 }}>
                <SimpleSelectComponent
                  label={msg('topbar.selectLanguage', 'Language:')}
                  name="language"
                  options={[
                    { _id: LANGUAGES.RO, name: msg('nationalities.romanian', 'Romanian') },
                    { _id: LANGUAGES.EN, name: msg('nationalities.english', 'English') }
                  ]}
                  id="language"
                  value={this.props.actions.initial_language}
                  onChange={this.handleLanguageChange}
                  required={false}
                  needsAllLabel={false}
                  arrayOptions={false}
                />
              </div>
              <button
                id="openSideBar"
                type="button"
                onClick={this.props.actions.toggleSideBar}
                className="navbar-toggle"
                data-toggle="collapse"
                area-controls="navigation-index"
                style={{ marginRight: -26, paddingRight: 0, alignSelf: 'flex-start' }}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="navbar-toggler-icon icon-bar" />
                <span className="navbar-toggler-icon icon-bar" />
                <span className="navbar-toggler-icon icon-bar" />
              </button>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
