import { AutopilotEventType } from './api.definitions';
import { FilterAutopilotLogsData } from './actions';
import { AutopilotLogsReducer } from './reducer';
import { IAutopilotLogsState } from './reducer.definitions';

export namespace AutopilotLogsStore {
    export const actions = {
        FilterAutopilotLogsData
    };

    export const reducer = AutopilotLogsReducer;

    export type IState = IAutopilotLogsState;

    export const autopilotEvents = AutopilotEventType;
}