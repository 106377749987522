import packagesActions, { IPackagesActions } from './actions';
import packagesReducer from './reducer';
import { PaginationDTO, IPackage } from '../types';

export namespace PackagesStore {
  export type IState = {
    packages_list: PaginationDTO<IPackage>;
    packages_list_pending: boolean;
    packages_list_error: string | null;
    package_details: IPackage | null;
    package_details_pending: boolean;
    package_details_error: string | null;
  };

  export const initialState: IState = {
    packages_list: new PaginationDTO(),
    packages_list_pending: false,
    packages_list_error: null,
    package_details: null,
    package_details_pending: false,
    package_details_error: null,
  };

  export enum ActionTypes {
    GET_PACKAGES = 'GET_PACKAGES',
    GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS',
    GET_PACKAGES_FAILED = 'GET_PACKAGES_FAILED',
    GET_PACKAGE = 'GET_PACKAGE',
    GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS',
    GET_PACKAGE_FAILED = 'GET_PACKAGE_FAILED',
    CREATE_PACKAGE = 'CREATE_PACKAGE',
    CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS',
    CREATE_PACKAGE_FAILED = 'CREATE_PACKAGE_FAILED',
    EDIT_PACKAGE = 'EDIT_PACKAGE',
    EDIT_PACKAGE_SUCCESS = 'EDIT_PACKAGE_SUCCESS',
    EDIT_PACKAGE_FAILED = 'EDIT_PACKAGE_FAILED',
    REMOVE_PACKAGE = 'REMOVE_PACKAGE',
    REMOVE_PACKAGE_SUCCESS = 'REMOVE_PACKAGE_SUCCESS',
    REMOVE_PACKAGE_FAILED = 'REMOVE_PACKAGE_FAILED',
  }

  export const actions = packagesActions;
  export const reducer = packagesReducer;
  export interface IActions extends IPackagesActions { }
}

export * from './actions';
