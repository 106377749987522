import { Dispatch } from 'redux';
import { OnboardStore } from '.';
import { replace, push } from 'connected-react-router';
import { Server, AlertUtil, msg, logger, composedCriteriaBuilder } from '../../utils';
import { PaginationDTO } from '../types';
import { adminRoutes } from '../../modules/layouts/routes';
import { generatePath } from 'react-router-dom';

export interface IOnboardActions {
  sendOnboardingAction(form: any): any;
  getAllOnboardingsAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }): any;
  getOnboardingAction(onboardingId: string): any;
  getOnboardingBySAdminAction(onboardingId: string): any;
  updateOnboardingAction(onboardingId: string, form: any): any;
  deleteOnboardingAction(onboardingId: string): any;
  createPartnerFromOnboarding(onboardingId: string): any;
  createPlaceFromOnboarding(onboardingId: string): any;
  activatePlaceFromOnboarding(onboardingId: string): any;
  createUserFromOnboarding(onboardingId: string): any;
  sendEmailToPartner(onboardingId: string): any;
  getFlagsForOnboarding(): any;
}

class OnboardActions implements IOnboardActions {
  sendOnboardingAction(form: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: OnboardStore.ActionTypes.SEND_ONBOARDING
      });
      AlertUtil.simple(
        msg('reduxMessages.onboarding.sendOnboarding', 'The onboarding is being created, please wait...'),
        'info'
      );
      logger.msg('Add onboarding action, route:/onboarding', 'POST');
      await Server.post('onboarding', form, true)
        .then((response: any) => {
          dispatch({
            type: OnboardStore.ActionTypes.SEND_ONBOARDING_SUCCESS,
            payload: response.data
          });
          dispatch(replace(`/onboard/${response?.data?._id}`));
          AlertUtil.updateContent(msg('reduxMessages.onboarding.sendSuccess', 'Onboarding has been saved'), 'success');
        })
        .catch(error => {
          logger.err('Add onboarding action, route:/onboarding', 'POST');
          AlertUtil.updateContent(
            msg('reduxMessages.onboarding.sendOnboardingError', 'Due to an error, the onboarding could not be sent!'),
            'error'
          );
          dispatch({
            type: OnboardStore.ActionTypes.SEND_ONBOARDING_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getAllOnboardingsAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: OnboardStore.ActionTypes.GET_ALL_ONBOARDINGS
      });
      try {
        if (!limit) {
          limit = 30;
        }
        let url = `admin/onboard/get-all?limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get all onboardings action, route:admin/onboard/get-all', 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: OnboardStore.ActionTypes.GET_ALL_ONBOARDINGS_SUCCESS,
          payload: response.data as PaginationDTO<any>
        });
      } catch (error) {
        logger.err('Get all onboardings action, route:admin/onboard/get-all', 'GET');
        AlertUtil.updateContent(
          // tslint:disable-next-line: max-line-length
          msg('reduxMessages.onboarding.getAllOnboardingsError', 'Due to an error, the onboardings could not be get!'),
          'error'
        );
        dispatch({
          type: OnboardStore.ActionTypes.GET_ALL_ONBOARDINGS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  getOnboardingAction(onboardingId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: OnboardStore.ActionTypes.GET_ONBOARDING
      });
      logger.msg('Get onboarding action, route:/onboarding/id', 'GET');
      await Server.get(`onboarding/${onboardingId}`, true)
        .then((response: any) => {
          dispatch({
            type: OnboardStore.ActionTypes.GET_ONBOARDING_SUCCESS,
            payload: response.data
          });
        })
        .catch((error: any) => {
          logger.err('Get onboarding action, route:/onboarding/id', 'GET');
          AlertUtil.updateContent(
            msg('reduxMessages.onboarding.getOnboardingError', 'Due to an error, the onboarding could not be get!'),
            'error'
          );
          dispatch({
            type: OnboardStore.ActionTypes.GET_ONBOARDING_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getOnboardingBySAdminAction(onboardingId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: OnboardStore.ActionTypes.GET_ONBOARDING
      });
      logger.msg('Get onboarding action, route:/admin/onboard/id', 'GET');
      await Server.get(`admin/onboard/${onboardingId}`)
        .then((response: any) => {
          dispatch({
            type: OnboardStore.ActionTypes.GET_ONBOARDING_SUCCESS,
            payload: response.data
          });
        })
        .catch((error: any) => {
          logger.err('Get onboarding action, route:/admin/onboard/id', 'GET');
          AlertUtil.updateContent(
            msg('reduxMessages.onboarding.getOnboardingError', 'Due to an error, the onboarding could not be get!'),
            'error'
          );
          dispatch({
            type: OnboardStore.ActionTypes.GET_ONBOARDING_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  updateOnboardingAction(onboardingId: string, form: any) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: OnboardStore.ActionTypes.UPDATE_ONBOARDING
      });
      AlertUtil.simple(
        msg('reduxMessages.onboarding.updateOnboarding', 'The onboarding is being updated, please wait...'),
        'info'
      );
      logger.msg('Update onboarding action, route:/onbording/id', 'PUT');
      await Server.put(`onboarding/${onboardingId}`, form, true)
        .then((response: any) => {
          dispatch({
            type: OnboardStore.ActionTypes.UPDATE_ONBOARDING_SUCCESS,
            payload: response.data
          });
          AlertUtil.updateContent(
            msg('reduxMessages.onboarding.updateSuccess', 'Onboarding has been updated!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Update onboarding action, route:/onbording/id', 'PUT');
          if (error.response?.data?.ERROR?.code === 'INVALID') {
            AlertUtil.updateContent(
              msg('reduxMessages.onboarding.onboardingVersionError', 'This onboarding version is no longer supported!'),
              'error'
            );
          } else {
            AlertUtil.updateContent(
              msg(
                'reduxMessages.onboarding.updateOnboardingError',
                'Due to an error, the onboarding could not be updated!'
              ),
              'error'
            );
          }
          dispatch({
            type: OnboardStore.ActionTypes.UPDATE_ONBOARDING_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  deleteOnboardingAction(onboardingId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: OnboardStore.ActionTypes.DELETE_ONBOARDING
      });
      AlertUtil.simple(
        msg('reduxMessages.onboarding.deleteOnboarding', 'The onboarding is being deleted, please wait...'),
        'info'
      );
      logger.msg('Deleting onboarding action, admin/onboard/:id', 'DELETE');
      await Server.delete(`admin/onboard/${onboardingId}`)
        .then((response: any) => {
          dispatch({
            type: OnboardStore.ActionTypes.DELETE_ONBOARDING_SUCCESS
          });
          this.getAllOnboardingsAction();
        })
        .catch((error: any) => {
          logger.err('Deleting onboarding action, admin/onboard/:id', 'DELETE');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.onboarding.deleteOnboardingError',
              'Due to an error, the onboarding could not be deleted!'
            ),
            'error'
          );
          dispatch({
            type: OnboardStore.ActionTypes.DELETE_ONBOARDING_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  redirectToOnboardingView(onboardingId: string) {
    return (dispatch: Dispatch<any>) => {
      if (onboardingId) {
        const path = generatePath(adminRoutes.ONBOARDINGS.subroutes.VIEW.path, { onboardingId });
        dispatch(push(path));
      }
    };
  }

  createPartnerFromOnboarding(onboardingId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: OnboardStore.ActionTypes.CREATE_PARTNER_FROM_ONBOARDING
      });
      AlertUtil.simple(
        msg('reduxMessages.onboarding.createPartnerOnboarding', 'Creating partner, please wait...'),
        'info',
        2500
      );
      logger.msg('Create partner from onboarding, route:/admin/onboard/:id/create-partner', 'POST');
      await Server.get(`admin/onboard/${onboardingId}/create-partner`)
        .then((response: any) => {
          dispatch({
            type: OnboardStore.ActionTypes.CREATE_PARTNER_FROM_ONBOARDING_SUCCESS,
            payload: response.data
          });
          AlertUtil.updateContent(
            msg('reduxMessages.onboarding.createPartnerOnboardingSuccess', 'Partner has been created'),
            'success'
          );
        })
        .catch((error: any) => {
          logger.err('Create partner from onboarding, route:/admin/onboard/:id/create-partner', 'POST');
          if (error.response?.data?.ERROR?.code === 'INVALID') {
            AlertUtil.updateContent(
              msg('reduxMessages.onboarding.onboardingVersionError', 'This onboarding version is no longer supported!'),
              'error'
            );
          } else {
            AlertUtil.updateContent(
              msg(
                'reduxMessages.onboarding.createPartnerOnboardingError',
                'Due to an error, the partner could not be created!'
              ),
              'error'
            );
          }
          dispatch({
            type: OnboardStore.ActionTypes.CREATE_PARTNER_FROM_ONBOARDING_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  createPlaceFromOnboarding(onboardingId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: OnboardStore.ActionTypes.CREATE_PLACE_FROM_ONBOARDING
      });
      AlertUtil.simple(msg('reduxMessages.onboarding.createPlaceOnboarding', 'Creating place, please wait...'), 'info');
      logger.msg('Create place from onboarding, route:/admin/onboard/:id/create-place', 'POST');
      await Server.get(`admin/onboard/${onboardingId}/create-place`)
        .then((response: any) => {
          dispatch({
            type: OnboardStore.ActionTypes.CREATE_PLACE_FROM_ONBOARDING_SUCCESS,
            payload: response.data
          });
          AlertUtil.updateContent(
            msg('reduxMessages.onboarding.createPlaceOnboardingSuccess', 'Place has been created'),
            'success'
          );
        })
        .catch((error: any) => {
          logger.err('Create place from onboarding, route:/admin/onboard/:id/create-place', 'POST');
          let displayedError = msg(
            'reduxMessages.onboarding.createPlaceOnboardingError',
            'Due to an error, the place could not be created!'
          );
          if (error.response?.data?.ERROR?.code === 'INVALID') {
            AlertUtil.updateContent(
              msg('reduxMessages.onboarding.onboardingVersionError', 'This onboarding version is no longer supported!'),
              'error'
            );
          } else if (error?.response?.data?.ERROR?.message) {
            displayedError = error?.response?.data?.ERROR?.message;
          }
          AlertUtil.updateContent(displayedError, 'error');
          dispatch({
            type: OnboardStore.ActionTypes.CREATE_PLACE_FROM_ONBOARDING_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  createUserFromOnboarding(onboardingId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: OnboardStore.ActionTypes.CREATE_USER_FROM_ONBOARDING
      });
      AlertUtil.simple(msg('reduxMessages.onboarding.createUserOnboarding', 'Creating user, please wait...'), 'info');
      logger.msg('Create user from onboarding, route:/admin/onboard/:id/create-user', 'POST');
      await Server.get(`admin/onboard/${onboardingId}/create-user`)
        .then((response: any) => {
          dispatch({
            type: OnboardStore.ActionTypes.CREATE_USER_FROM_ONBOARDING_SUCCESS,
            payload: response.data
          });
          AlertUtil.updateContent(
            msg('reduxMessages.onboarding.createUserOnboardingSuccess', 'User has been created'),
            'success'
          );
        })
        .catch((error: any) => {
          logger.err('Create user from onboarding, route:/admin/onboard/:id/create-user', 'POST');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.onboarding.createUserOnboardingError',
              'Due to an error, the user could not be created!'
            ),
            'error'
          );
          dispatch({
            type: OnboardStore.ActionTypes.CREATE_USER_FROM_ONBOARDING_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  activatePlaceFromOnboarding(onboardingId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: OnboardStore.ActionTypes.ACTIVATE_PLACE_FROM_ONBOARDING
      });
      AlertUtil.simple(
        msg(
          'reduxMessages.onboarding.activatePlaceOnboarding',
          'Activating place with coming soon flag, please wait...'
        ),
        'info'
      );
      logger.msg('Activate place from onboarding, route:/admin/onboard/:id/activate-place', 'GET');
      await Server.get(`admin/onboard/${onboardingId}/activate-place`)
        .then((response: any) => {
          dispatch({
            type: OnboardStore.ActionTypes.ACTIVATE_PLACE_FROM_ONBOARDING_SUCCESS,
            payload: response.data
          });
          AlertUtil.updateContent(
            msg('reduxMessages.onboarding.activatePlaceOnboardingSuccess', 'Place has been activated'),
            'success'
          );
        })
        .catch((error: any) => {
          logger.err('Activate place from onboarding, route:/admin/onboard/:id/activate-place', 'GET');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.onboarding.activatePlaceOnboardingError',
              'Due to an error, the place could not be activated!'
            ),
            'error'
          );
          dispatch({
            type: OnboardStore.ActionTypes.ACTIVATE_PLACE_FROM_ONBOARDING_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  sendEmailToPartner(onboardingId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: OnboardStore.ActionTypes.SEND_EMAIL_TO_PARTNER
      });
      AlertUtil.simple(
        msg('reduxMessages.onboarding.sendEmailToPartner', 'Sending email to partner, please wait...'),
        'info'
      );
      logger.msg('Sending email to partner, route:/admin/onboard/:id/send-email', 'POST');
      await Server.get(`admin/onboard/${onboardingId}/send-email`)
        .then((response: any) => {
          dispatch({
            type: OnboardStore.ActionTypes.SEND_EMAIL_TO_PARTNER_SUCCESS,
            payload: response.data
          });
          AlertUtil.updateContent(
            msg('reduxMessages.onboarding.sendEmailToPartnerSuccess', 'Email has been sent'),
            'success'
          );
        })
        .catch((error: any) => {
          logger.err('Sending email to partner, route:/admin/onboard/:id/send-email', 'POST');
          AlertUtil.updateContent(
            msg('reduxMessages.onboarding.sendEmailToPartnerError', 'Due to an error, the email could not be sent!'),
            'error'
          );
          dispatch({
            type: OnboardStore.ActionTypes.SEND_EMAIL_TO_PARTNER_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getFlagsForOnboarding() {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: OnboardStore.ActionTypes.GET_ONBOARDING_FLAGS
      });
      logger.msg('Get flags for onboarding, route:/onboarding/flags', 'GET');
      await Server.get('onboarding/flags', true)
        .then((response: any) => {
          dispatch({
            type: OnboardStore.ActionTypes.GET_ONBOARDING_FLAGS_SUCCESS,
            payload: response.data
          });
        })
        .catch((error: any) => {
          logger.err('Get alternative address flag for onboarding, route:/onboarding/flags', 'GET');
          dispatch({
            type: OnboardStore.ActionTypes.GET_ONBOARDING_FLAGS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }
}

const onboardActions = new OnboardActions();
export default onboardActions;
