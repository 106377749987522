import * as React from 'react';
import { TextFieldComponent, UploadComponentReward } from '../';
import { msg } from '../../../utils';

interface IProps {
  id: string;
  placeId?: string;
  industryId?: string;
  forSAdmin?: boolean;
  value: any;
  media?: any;
  formatError?: any;
  readOnly?: boolean;
  label?: string;
  disabled?: boolean;
  ref?: any;
  onChange: (event: any) => void;
  uploadPicture: (res: any) => void;
  mediaURL?: string;
  validator?: any;
  validatorIgnore?: boolean;
  marginPicture?: number;
}

interface IState {}
export default class RewardPictureInput extends React.Component<IProps, IState> {
  render() {
    let url = '';
    if (this.props.mediaURL) {
      url = this.props.mediaURL;
    } else if (this.props.forSAdmin && this.props.industryId) {
      url = `media/industries/${this.props.industryId}?type=REWARD&width=200&height=200`;
    } else if (!this.props.forSAdmin && this.props.placeId) {
      url = `media/place/${this.props.placeId}?type=REWARD&width=200&height=200`;
    }
    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ marginRight: this.props.marginPicture || 0 }}>
          <UploadComponentReward
            url={url}
            accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
            media={this.props.media}
            extraData={{ rewardId: this.props.id }}
            onSuccess={this.props.uploadPicture}
            crop={true}
            disabled={this.props.disabled || this.props.readOnly}
          />
        </div>
        <div style={{ width: '100%' }}>
          <TextFieldComponent
            id={`${this.props.id}`}
            value={this.props.value || ''}
            onChange={this.props.onChange}
            inType="text"
            minLength={1}
            maxLength={40}
            style={{ padding: 0 }}
            readOnly={this.props.readOnly}
            disabled={this.props.disabled}
            label={this.props.label}
            placeholder={msg('reward.titlePlaceholder', 'Reward name')}
            formatError={this.props.formatError}
            validatorIgnore={this.props.validatorIgnore}
          />
        </div>
      </div>
    );
  }
}
