import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';

import {
    AppStore, IndustriesStore, PartnersStore, PlacesStore, reduxContainer, RewardsStore, TagsStore
} from '../../../appRedux';
import { PaginationDTO, TagDTO } from '../../../appRedux/types';
import { adminRoutes, partnerRoutes } from '../../layouts';
import { RewardsListComponent } from './components';

interface IProps extends PartnersStore.IState, RewardsStore.IState, IndustriesStore.IState, PlacesStore.IState {
  tags_list: PaginationDTO<TagDTO>;
  getTagsAction(forSAdmin: boolean, placeId?: string): any;
  clearTagsActions(): (dispatch: Dispatch) => void;
}
interface IState {}

class RewardsContainer extends React.Component<
  IProps & RewardsStore.IActions & IndustriesStore.IActions & PlacesStore.IActions,
  IState
> {
  rewardsPropsConstructor = (type: string) => {
    switch (type) {
      case 'superadminview':
        return {
          rewards_list: this.props.rewards_list,
          rewards_list_pending: this.props.rewards_list_pending,
          rewards_list_error: this.props.rewards_list_error,
          reward_save_pending: this.props.reward_save_pending,
          reward_delete_pending: this.props.reward_delete_pending,
          industries: this.props.industries_list.results,
          getRewardsListAction: this.props.getRewardsListAsAdminAction,
          updateRewardsAction: this.props.updateRewardsAction,
          deleteRewardAction: this.props.deleteRewardAction,
          getIndustriesListAction: this.props.getIndustriesListAction
        };
      case 'placeview':
        return {
          rewards_list: this.props.rewards_list,
          rewards_list_pending: this.props.rewards_list_pending,
          rewards_list_error: this.props.rewards_list_error,
          reward_save_pending: this.props.reward_save_pending,
          reward_delete_pending: this.props.reward_delete_pending,
          tags_list: this.props.tags_list,
          getRewardsListAction: this.props.getRewardsListForPlaceAction,
          updateRewardsAction: this.props.updateRewardsForPlaceAction,
          deleteRewardAction: this.props.deleteRewardForPlaceAction,
          inheritRewardsAction: this.props.inheritRewardsAction,
          getTagsAction: this.props.getTagsAction,
          clearTagsActions: this.props.clearTagsActions
        };
      default:
        return {
          rewards_list: this.props.rewards_list
        };
    }
  };

  render() {
    const partnerId = this.props.selected_partner;
    let enableRoute = true;
    if (this.props.place && !this.props.place_pending && !this.props.place?.featureFlags?.becomeMember) {
      enableRoute = false;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Switch>
              <Route
                exact={true}
                path={adminRoutes.REWARDS.path}
                render={() => (
                  <RewardsListComponent
                    forSAdmin={true}
                    forPlace={false}
                    config={this.rewardsPropsConstructor('superadminview')}
                  />
                )}
              />
              {enableRoute && (
                <Route
                  exact={true}
                  path={partnerRoutes.REWARDS.default}
                  render={({ match }) => (
                    <RewardsListComponent
                      forSAdmin={false}
                      forPlace={true}
                      config={this.rewardsPropsConstructor('placeview')}
                      match={match}
                      partnerId={partnerId}
                    />
                  )}
                />
              )}
              <Redirect from="*" to={`/partner/dashboard/${this.props.selected_place}`} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    reward_delete_pending: state.rewards.reward_delete_pending,
    reward_save_pending: state.rewards.reward_save_pending,
    industries_list: state.industries.industries_list,
    rewards_list: state.rewards.rewards_list,
    rewards_list_pending: state.rewards.rewards_list_pending,
    selected_partner: state.partners.selected_partner,
    selected_place: state.places.selected_place,
    place: state.places.place,
    place_pending: state.places.place_pending,
    tags_list: state.tags.tags_list
  };
}

const dispatchToProps = {
  getRewardsListAsAdminAction: RewardsStore.actions.getRewardsListAsAdminAction,
  deleteRewardAction: RewardsStore.actions.deleteRewardAction,
  updateRewardsAction: RewardsStore.actions.updateRewardsAction,
  updateRewardsForPlaceAction: RewardsStore.actions.updateRewardsForPlaceAction,
  getRewardsListForPlaceAction: RewardsStore.actions.getRewardsListForPlaceAction,
  deleteRewardForPlaceAction: RewardsStore.actions.deleteRewardForPlaceAction,
  inheritRewardsAction: PlacesStore.actions.inheritRewardsAction,
  getIndustriesListAction: IndustriesStore.actions.getIndustriesListAction,
  getTagsAction: TagsStore.actions.getTagsAction,
  clearTagsActions: TagsStore.actions.clearTagsActions
};

export default reduxContainer(RewardsContainer, mapStateToProps, dispatchToProps);
