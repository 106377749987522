import * as React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Face, Phone, Email, Lock } from '@material-ui/icons';
import { msg, Validator, IValidator } from '../../../../utils';
import { TextFieldComponent, CardComponent } from '../../../shared';
import { Step1DTO } from '../../../../appRedux/types';
import { Tooltip } from '@material-ui/core';

interface IState {
  user: Step1DTO;
}
interface IProps {
  user: Step1DTO;
  changePassword: boolean;
  handleChange: (event: any) => any;
}

class UserFormComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      user: props.user || new Step1DTO()
    };
  }

  handleChange = (event: any) => {
    const fieldKey = event.target.getAttribute('id');
    const fieldValue = event.target.value;
    const user = this.state.user;
    if (this.props.validator) {
      const { isDirty } = this.props.validator!;
      if (isDirty) {
        isDirty(fieldKey);
      }
    }
    user[fieldKey] = fieldValue;
    this.props.handleChange(user);
  };

  render() {
    const { errors } = this.props.validator!;
    const { changePassword } = this.props;
    return (
      <CardComponent
        title={msg('onboard.userData', 'User data')}
        pending={false}
        error={false}
        headerIcon="person_add"
        needsTitle={true}
        style={styles.card}
      >
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('accountInfo.lastname', 'Last name')}
              id="lastname"
              value={this.state.user.lastname}
              onChange={this.handleChange}
              inType="text"
              minLength={1}
              maxLength={30}
              required={true}
              autoComplete="off"
              formatError={errors.lastname}
              ref="lastname"
              validator={[
                { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } },
                {
                  type: 'isLength',
                  msg: msg('formValidation.fieldRequired', 'Field required!'),
                  params: { min: 1 }
                }
              ]}
              startAdornment={
                <InputAdornment position="start">
                  <Face />
                </InputAdornment>
              }
            />
          </div>
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('accountInfo.firstname', 'First name')}
              id="firstname"
              value={this.state.user.firstname}
              onChange={this.handleChange}
              inType="text"
              minLength={1}
              maxLength={30}
              required={true}
              autoComplete="off"
              formatError={errors.firstname}
              ref="firstname"
              validator={[
                { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } },
                {
                  type: 'isLength',
                  msg: msg('formValidation.fieldRequired', 'Field required!'),
                  params: { min: 1 }
                }
              ]}
              startAdornment={
                <InputAdornment position="start">
                  <Face />
                </InputAdornment>
              }
            />
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('accountInfo.phone', 'Phone')}
              id="phone"
              value={this.state.user.phone}
              onChange={this.handleChange}
              inType="text"
              required={true}
              autoComplete="off"
              formatError={errors.phone}
              ref="phone"
              validator={[
                {
                  type: 'isNumeric',
                  msg: msg('formValidation.onlyNumbersPhone', 'A phone must contain only numbers!')
                },
                {
                  type: 'isLength',
                  msg: msg('formValidation.fieldRequired', 'Field required!'),
                  params: { min: 1 }
                }
              ]}
              startAdornment={
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              }
            />
          </div>
          <div className="col-sm-5">
            <Tooltip title={msg('onboard.tabletCodeTip', 'This is required to access your place from the tablet')}>
              <div>
                <TextFieldComponent
                  label={msg('onboard.tabletCode', 'Tablet code access')}
                  placeholder={msg('onboard.tabletCodePlaceholder', '4 numbers')}
                  id="tabletCode"
                  value={this.state.user.tabletCode}
                  onChange={this.handleChange}
                  inType="text"
                  minLength={4}
                  maxLength={4}
                  required={true}
                  formatError={errors.tabletCode}
                  ref="tabletCode"
                  validator={[
                    {
                      type: 'isNumeric',
                      msg: msg('formValidation.onlyNumbers', 'Use only numbers!')
                    },
                    {
                      type: 'isInt',
                      msg: msg('formValidation.isInt', 'Use only numbers!'),
                      params: { min: 0, max: 9999 }
                    },
                    {
                      type: 'isLength',
                      msg: msg('formValidation.4numbersNeeded', '4 numbers needed!'),
                      params: { min: 4, max: 4 }
                    },
                    {
                      type: 'isLength',
                      msg: msg('formValidation.fieldRequired', 'Field required!'),
                      params: { min: 1 }
                    }
                  ]}
                />
              </div>
            </Tooltip>
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('accountInfo.email', 'Email')}
              id="email"
              value={this.state.user.email}
              onChange={this.handleChange}
              inType="text"
              minLength={1}
              required={true}
              autoComplete="off"
              formatError={errors.email}
              ref="email"
              validator={[
                {
                  type: 'isEmail',
                  msg: msg('formValidation.emailFormat', 'Invalid email format!')
                },
                {
                  type: 'isLength',
                  msg: msg('formValidation.fieldRequired', 'Field required!'),
                  params: { min: 1 }
                }
              ]}
              startAdornment={
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              }
            />
          </div>
          <div className="col-sm-5">
            <TextFieldComponent
              label={
                changePassword
                  ? msg('passwords.changePassword', 'Change password')
                  : msg('accountInfo.password', 'Password')
              }
              id="password"
              value={this.state.user.password}
              onChange={this.handleChange}
              inType="password"
              minLength={1}
              maxLength={30}
              required={!changePassword}
              autoComplete="off"
              formatError={errors.password}
              ref="password"
              validator={
                changePassword
                  ? []
                  : [
                      {
                        type: 'isLength',
                        msg: msg('formValidation.inputLength', 'Input too short!'),
                        params: { min: 6 }
                      },
                      {
                        type: 'isLength',
                        msg: msg('formValidation.fieldRequired', 'Field required!'),
                        params: { min: 1 }
                      }
                    ]
              }
              startAdornment={
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              }
            />
          </div>
          <div className="col-sm-1" />
        </div>
      </CardComponent>
    );
  }
}

export default Validator(UserFormComponent);

const styles = {
  card: {
    paddingBottom: 20
  }
};
