import * as React from 'react';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { AlertUtil, getServerName, msg } from '../../../../../utils';
import { AppStore, AuthStore } from '../../../../../appRedux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { OriginModalComponent } from './OriginModalComponent';
const env = runtimeEnv();

const herokuReleaseVersion = process.env.HEROKU_RELEASE_VERSION
  ? Number(process.env.HEROKU_RELEASE_VERSION.slice(1))
  : 0;

interface IProps {
  origin: string;
  setOriginURLAction(origin: string): (dispatch: Dispatch) => void;
}

const FooterAuthComponent = (props: IProps) => {
  const [showOriginModal, setShowOriginModal] = React.useState(false);

  const handleCheckCode = async () => {
    const code = await AlertUtil.fireWithInput(
      msg('footer.checkCode', 'Check code'),
      '',
      '',
      input => (!input.length ? 'Invalid input' : input === env.REACT_APP_API_MODAL_CODE ? null : 'Wrong code'),
      true
    );
    if (code) {
      setShowOriginModal(true);
    }
  };

  const handleChangeOrigin = (newOrigin: string) => {
    if (newOrigin) {
      props.setOriginURLAction(newOrigin);
    }
    setShowOriginModal(false);
  };

  const closeModal = () => {
    setShowOriginModal(false);
  };

  return (
    <footer className="footer">
      <div className="container">
        <nav className="pull-left">
          <ul>
            <li>
              <small>
                <span onClick={handleCheckCode}>
                  {`${env.REACT_APP_VERSION}-v${herokuReleaseVersion + 1}/`}
                  {getServerName(props.origin || env.REACT_APP_API_URL)}
                </span>
              </small>
            </li>
            <li>
              <a href="/">{msg('footer.home', 'Home')}</a>
            </li>
            <li>
              <a href="/onboard">{msg('footer.onboard', 'Onboard')}</a>
            </li>
            <li>
              <a href="https://help.regage.io/#/" target="_blank" rel="noopener noreferrer">
                {msg('footer.help', 'Help')}
              </a>
            </li>
          </ul>
        </nav>
        <p className="copyright pull-right">
          &copy; &nbsp;
          {process.env.REACT_APP_FOOTER ? (
            process.env.REACT_APP_FOOTER
          ) : (
            <>
              <a href="http://regage.ro">ReGage</a>, Engagement Revolution!{' '}
            </>
          )}
        </p>
      </div>
      {showOriginModal && (
        <OriginModalComponent origin={props.origin} closeModal={closeModal} setOriginURLAction={handleChangeOrigin} />
      )}
    </footer>
  );
};

function mapStateToProps(state: AppStore.states) {
  return {
    origin: state.auth.origin
  };
}

const dispatchToProps = {
  setOriginURLAction: AuthStore.actions.setOriginURLAction
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(FooterAuthComponent));
