export enum AutopilotTriggerType {
  TEXT,
  DROPDOWN,
  DROPDOWNTYPE,
  VALUE,
  MESSAGE
}

export enum AutopilotActionType {
  REWARD,
  POINTS,
  MEMBER_STATUS,
  MESSAGE,
  CUSTOM
}

const ExpiresDefaultArray = [
  { name: '1 zi', value: 1 },
  { name: '3 zile', value: 3 },
  { name: '5 zile', value: 5 },
  { name: '7 zile', value: 7 }
];

export const simpleAutopilotsSchema = [
  {
    type: 'first_visit',
    name: 'Prima vizită',
    for: {
      type: AutopilotTriggerType.TEXT,
      text: 'Primul check-in efectuat la locație'
    },
    expires: ExpiresDefaultArray,
    expireSelected: 1,
    allowCustomMessage: true
  },
  {
    type: 'acquisition',
    name: 'Încurajare vizită',
    for: {
      type: AutopilotTriggerType.DROPDOWN,
      dd: [
        { name: 'Fără vizită (check-in) în 7 zile de la devenire membru', value: '7 days' },
        { name: 'Fără vizită (check-in) în 14 zile de la devenire membru', value: '14 days' }
      ],
      value: '7 days'
    },
    expires: ExpiresDefaultArray,
    expireSelected: 1,
    allowCustomMessage: true
  },
  {
    type: 'referral',
    name: 'Recomandare',
    for: {
      type: AutopilotTriggerType.TEXT,
      text: 'Noul client efectuează check-in la locație',
      hasTooltip: true,
      tooltipMessage: 'Recompensele se vor acorda atât clientului care a făcut recomandarea, cât și noului client.'
    },
    expires: ExpiresDefaultArray,
    expireSelected: 1,
    allowCustomMessage: true
  },
  {
    type: 'growth',
    name: 'Client recurent',
    for: {
      type: AutopilotTriggerType.DROPDOWN,
      dd: [
        { name: 'La fiecare 5 vizite', value: 5 },
        { name: 'La fiecare 7 vizite', value: 7 },
        { name: 'La fiecare 10 vizite', value: 10 }
      ],
      value: 10
    },
    expires: ExpiresDefaultArray,
    expireSelected: 1,
    allowCustomMessage: true
  },
  {
    type: 'growth-message',
    name: 'Client recurent - Mesaj',
    for: {
      type: AutopilotTriggerType.VALUE,
      textValue: 'Clientul a ajuns la $c vizite',
      value: 9
    }
  },
  {
    type: 'risk',
    name: 'Client cu risc 1',
    for: {
      type: AutopilotTriggerType.DROPDOWN,
      dd: [
        { name: 'Fără vizită (check-in) în ultimele 15 zile', value: '15 days' },
        { name: 'Fără vizită (check-in) în ultimele 20 zile', value: '20 days' },
        { name: 'Fără vizită (check-in) în ultimele 30 zile', value: '30 days' }
      ],
      value: '15 days'
    },
    expires: ExpiresDefaultArray,
    expireSelected: 1,
    allowCustomMessage: true
  },
  {
    type: 'risk2',
    name: 'Client cu risc 2',
    for: {
      type: AutopilotTriggerType.DROPDOWN,
      dd: [
        { name: 'Fără vizită (check-in) în ultimele 45 zile', value: '45 days' },
        { name: 'Fără vizită (check-in) în ultimele 60 zile', value: '60 days' },
        { name: 'Fără vizită (check-in) în ultimele 75 zile', value: '75 days' }
      ],
      value: '45 days'
    },
    expires: ExpiresDefaultArray,
    expireSelected: 1,
    allowCustomMessage: true
  },
  {
    type: 'risk3',
    name: 'Client cu risc 3',
    for: {
      type: AutopilotTriggerType.DROPDOWN,
      dd: [
        { name: 'Fără vizită (check-in) în ultimele 90 zile', value: '90 days' },
        { name: 'Fără vizită (check-in) în ultimele 120 zile', value: '120 days' },
        { name: 'Fără vizită (check-in) în ultimele 180 zile', value: '180 days' }
      ],
      value: '90 days'
    },
    expires: ExpiresDefaultArray,
    expireSelected: 1,
    allowCustomMessage: true
  },
  {
    type: 'lapsed',
    name: 'Client pierdut',
    for: {
      type: AutopilotTriggerType.TEXT,
      text: 'Fără vizită (check-in) în ultimele 365 de zile'
    },
    expires: ExpiresDefaultArray,
    expireSelected: 1,
    allowCustomMessage: true
  },
  {
    type: 'retention',
    name: 'Client fidel',
    for: {
      type: AutopilotTriggerType.VALUE,
      textValue: 'Clientul a ajuns la $c monede',
      value: 0
    },
    expires: ExpiresDefaultArray,
    expireSelected: 1,
    allowCustomMessage: true
  },
  {
    type: 'retention-message',
    name: 'Client fidel - Mesaj',
    for: {
      type: AutopilotTriggerType.VALUE,
      textValue: 'Clientul a ajuns la $c monede',
      value: 0
    }
  },
  {
    type: 'birthday',
    name: 'Zi de naștere',
    for: {
      type: AutopilotTriggerType.DROPDOWN,
      dd: [
        { name: '1 zi înainte de ziua de naștere', value: '1 day' },
        { name: '3 zile înainte de ziua de naștere', value: '3 days' },
        { name: '5 zile înainte de ziua de naștere', value: '5 days' },
        { name: '7 zile înainte de ziua de naștere', value: '7 days' },
        { name: '14 zile înainte de ziua de naștere', value: '14 days' },
        { name: '30 zile înainte de ziua de naștere', value: '30 days' }
      ],
      value: '7 days'
    },
    expires: [...ExpiresDefaultArray, { name: '14 zile', value: 14 }],
    expireSelected: 1,
    allowCustomMessage: true
  }
];
