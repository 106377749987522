import industriesActions, { IIndustriesActions } from './actions';
import industriesReducer from './reducer';
import { IndustryDTO, SelectValueDTO, PaginationDTO } from '../types';

/*
  IndustriesStore definition as a namespace that contains actions, reducers, actionTypes and industries state
*/
export namespace IndustriesStore {
  export type IState = {
    industries_list: PaginationDTO<IndustryDTO>;
    industries_list_pending: boolean;
    industries_list_error: string | null;
    industries_short_list: Array<SelectValueDTO>;
    industries_short_list_pending: boolean;
    industries_short_list_error: string | null;
    industry: IndustryDTO | null;
    industry_pending: boolean;
    industry_error: string | null;
    industry_saved: IndustryDTO | null;
    industry_save_pending: boolean;
    industry_save_error: string | null;
    industry_change_status: any | null;
    industry_change_status_pending: boolean;
    industry_change_status_error: string | null;
    industry_deleted: boolean;
    industry_delete_pending: boolean;
    industry_delete_error: string | null;
  };

  export const initialState: IState = {
    industries_list: new PaginationDTO(),
    industries_list_pending: false,
    industries_list_error: null,
    industries_short_list: new Array<SelectValueDTO>(),
    industries_short_list_pending: false,
    industries_short_list_error: null,
    industry: null,
    industry_pending: false,
    industry_error: null,
    industry_saved: null,
    industry_save_pending: false,
    industry_save_error: null,
    industry_deleted: false,
    industry_delete_pending: false,
    industry_delete_error: null,
    industry_change_status: null,
    industry_change_status_pending: false,
    industry_change_status_error: null
  };

  export enum ActionTypes {
    GET_INDUSTRIES = 'GET_INDUSTRIES',
    GET_INDUSTRIES_SUCCESS = 'GET_INDUSTRIES_SUCCESS',
    GET_INDUSTRIES_FAILED = 'GET_INDUSTRIES_FAILED',
    GET_INDUSTRIES_SHORT_LIST = 'GET_INDUSTRIES_SHORT_LIST',
    GET_INDUSTRIES_SHORT_LIST_SUCCESS = 'GET_INDUSTRIES_SHORT_LIST_SUCCESS',
    GET_INDUSTRIES_SHORT_LIST_FAILED = 'GET_INDUSTRIES_SHORT_LIST_FAILED',
    GET_INDUSTRY = 'GET_INDUSTRY',
    GET_INDUSTRY_SUCCESS = 'GET_INDUSTRY_SUCCESS',
    GET_INDUSTRY_FAILED = 'GET_INDUSTRY_FAILED',
    SAVE_INDUSTRY = 'SAVE_INDUSTRY',
    SAVE_INDUSTRY_SUCCESS = 'SAVE_INDUSTRY_SUCCESS',
    SAVE_INDUSTRY_FAILED = 'SAVE_INDUSTRY_FAILED',
    DELETE_INDUSTRY = 'DELETE_INDUSTRY',
    DELETE_INDUSTRY_SUCCESS = 'DELETE_INDUSTRY_SUCCESS',
    DELETE_INDUSTRY_FAILED = 'DELETE_INDUSTRY_FAILED',
    CHANGE_INDUSTRY_STATUS = 'CHANGE_INDUSTRY_STATUS',
    CHANGE_INDUSTRY_STATUS_SUCCESS = 'CHANGE_INDUSTRY_STATUS_SUCCESS',
    CHANGE_INDUSTRY_STATUS_FAILED = 'CHANGE_INDUSTRY_STATUS_FAILED'
  }

  export const actions = industriesActions;
  export const reducer = industriesReducer;
  export interface IActions extends IIndustriesActions {}
}

export * from './actions';
