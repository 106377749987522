import * as React from 'react';

import { AppStore, AuthStore, reduxContainer, TranslationsStore } from '../../../appRedux';
import backgroundImage from '../../../assets/images/background.jpg';
import { FooterAuthComponent, ForgotPasswordComponent, NavAuthComponent } from './components';

interface IProps extends AuthStore.IState, TranslationsStore.IState {
  auth?: any;
}

interface IState {}

class ForgotPasswordContainer extends React.Component<
  IProps & AuthStore.IActions & TranslationsStore.IActions,
  IState
> {
  sendEmailHandler = (email: string) => {
    this.props.forgotPasswordAction(email);
  };

  render() {
    const navAuthConfig = {
      title: 'ReGage Partner',
      initial_language: this.props.initial_language,
      changeLanguage: this.props.changeLanguageAction
    };

    const forgotPasswordState = {
      error: this.props.auth.forgot_password_error,
      pending: this.props.auth.forgot_password_pending
    };

    return (
      <div className="partner-auth off-canvas-sidebar">
        <NavAuthComponent config={navAuthConfig} />
        <div className="wrapper wrapper-full-page">
          <div className="full-page login-page" filter-color="black">
            <div className="content" style={{ paddingTop: '24vh' }}>
              <ForgotPasswordComponent forgotPasswordAction={this.sendEmailHandler} state={forgotPasswordState} />
            </div>
            <FooterAuthComponent />
            <div className="full-page-background" style={{ backgroundImage: `url(${backgroundImage})` }} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    auth: state.auth,
    initial_language: state.translations.initial_language
  };
}

const dispatchToProps = {
  forgotPasswordAction: AuthStore.actions.forgotPasswordAction,
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction
};

export default reduxContainer(ForgotPasswordContainer, mapStateToProps, dispatchToProps);
