import announcementsActions, { IAnnouncementsActions } from './actions';
import announcementsReducer from './reducer';
import { PaginationDTO, AnnouncementDTO } from '../types';

/*
  AnnouncementsStore definition as a namespace that contains actions, reducers, actionTypes and announcements state
*/
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AnnouncementsStore {
  export type IState = {
    announcements_list: PaginationDTO<any>;
    announcements_list_pending: boolean;
    announcements_list_error: string | null;
    save_announcement_pending: boolean;
    save_announcement_error: string | null;
    announcement: AnnouncementDTO | null;
    announcement_pending: boolean;
    announcement_error: string | null;
    edit_announcement_pending: boolean;
    edit_announcement_error: string | null;
    delete_announcement_pending: boolean;
    delete_announcement_error: string | null;
  };

  export const initialState: IState = {
    announcements_list: new PaginationDTO(),
    announcements_list_pending: false,
    announcements_list_error: null,
    save_announcement_pending: false,
    save_announcement_error: null,
    announcement: null,
    announcement_pending: false,
    announcement_error: null,
    edit_announcement_pending: false,
    edit_announcement_error: null,
    delete_announcement_pending: false,
    delete_announcement_error: null
  };

  export enum ActionTypes {
    GET_ANNOUNCEMENTS = 'GET_ANNOUNCEMENTS',
    GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS',
    GET_ANNOUNCEMENTS_FAILED = 'GET_ANNOUNCEMENTS_FAILED',
    SAVE_ANNOUNCEMENT = 'SAVE_ANNOUNCEMENT',
    SAVE_ANNOUNCEMENT_SUCCESS = 'SAVE_ANNOUNCEMENT_SUCCESS',
    SAVE_ANNOUNCEMENT_FAILED = 'SAVE_ANNOUNCEMENT_FAILED',
    GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT',
    GET_ANNOUNCEMENT_SUCCESS = 'GET_ANNOUNCEMENT_SUCCESS',
    GET_ANNOUNCEMENT_FAILED = 'GET_ANNOUNCEMENT_FAILED',
    EDIT_ANNOUNCEMENT = 'EDIT_ANNOUNCEMENT',
    EDIT_ANNOUNCEMENT_SUCCESS = 'EDIT_ANNOUNCEMENT_SUCCESS',
    EDIT_ANNOUNCEMENT_FAILED = 'EDIT_ANNOUNCEMENT_FAILED',
    DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT',
    DELETE_ANNOUNCEMENT_SUCCESS = 'DELETE_ANNOUNCEMENT_SUCCESS',
    DELETE_ANNOUNCEMENT_FAILED = 'DELETE_ANNOUNCEMENT_FAILED'
  }

  export const actions = announcementsActions;
  export const reducer = announcementsReducer;
  export interface IActions extends IAnnouncementsActions {}
}

export * from './actions';
