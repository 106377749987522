import { TriggersStore } from '.';
import { PaginationDTO } from '../types';

function triggersReducer(
  state: TriggersStore.IState = TriggersStore.initialState,
  action: IAction<any>
): TriggersStore.IState {
  switch (action.type) {
    case TriggersStore.ActionTypes.GET_TRIGGERS: {
      return {
        ...state,
        triggers_list_pending: true,
        triggers_list_error: null
      };
    }
    case TriggersStore.ActionTypes.GET_TRIGGERS_SUCCESS: {
      return {
        ...state,
        triggers_list_pending: false,
        triggers_list_error: null,
        triggers_list: action.payload
      };
    }
    case TriggersStore.ActionTypes.GET_TRIGGERS_FAILED: {
      return {
        ...state,
        triggers_list_pending: false,
        triggers_list: new PaginationDTO(),
        triggers_list_error: action.payload
      };
    }
    case TriggersStore.ActionTypes.CLEAR_TRIGGERS_ERROR: {
      return {
        ...state,
        triggers_list_error: null
      };
    }
    default:
      return state;
  }
}

export default triggersReducer;
