import React, { useState, useEffect } from 'react';
import Toggle from 'react-toggle';
import { msg } from '../../../../../utils';
import { FeatureFlagsDTO, PlaceDTO, ScheduleDTO, TabletFeatureFlagsDTO } from '../../../../../appRedux/types';
import { ButtonComponent, CardComponent } from '../../../../shared';
import { colors } from '../../../../../assets/styles';

interface IProps {
  place: PlaceDTO | null;
  place_save_pending: boolean;
  place_save_error: string | null;
  save?: (settings: any) => void;
}

const skipFlags = ['transactions', 'transactionsWithCoins', 'tips', 'transactionReports'];

const PlaceFeatureFlagsComponent = (props: IProps) => {
  const [featureFlags, setFeatureFlags] = useState(new FeatureFlagsDTO());
  const [tabletFeatureFlags, setTabletFeatureFlags] = useState(new TabletFeatureFlagsDTO());
  const [allowReceipts, setAllowReceipts] = useState(false);
  const [allowReviews, setAllowReviews] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (props.place?.featureFlags) {
      setFeatureFlags({ ...props.place?.featureFlags });
    }
    if (props.place?.tabletFeatureFlags) {
      setTabletFeatureFlags({ ...props.place?.tabletFeatureFlags });
    }
    setAllowReceipts(!!props.place?.allowReceipts);
    setAllowReviews(!!props.place?.allowReviews);
    // tslint:disable-next-line: align
  }, [props.place?.featureFlags]);

  useEffect(() => {
    if (props.place?.tabletFeatureFlags) {
      setTabletFeatureFlags({ ...props.place?.tabletFeatureFlags });
    }
    // tslint:disable-next-line: align
  }, [props.place?.tabletFeatureFlags?.transactions]);

  useEffect(() => {
    if (!props.place_save_pending && props.place_save_error) {
      setFeatureFlags({ ...props.place?.featureFlags });
    }
    // tslint:disable-next-line: align
  }, [props.place_save_pending]);

  const handleChange = (event: any, type: string) => {
    const fieldKey = event.target.getAttribute('id');
    const fieldValue = event.target.checked;
    if (type === 'place') {
      featureFlags[fieldKey] = fieldValue;
      if (!fieldValue) {
        switch (fieldKey) {
          case 'becomeMember':
          case 'checkin':
            featureFlags.useCoins = false;
            featureFlags.simpleAutopilots = featureFlags.sendReceipts = featureFlags.approveReceipts = false;
            featureFlags.manualCheckout = featureFlags.receiptsAfterCheckout = false;
            if (fieldKey === 'becomeMember') {
              featureFlags.checkin = false;
            }
            break;
          case 'useCoins':
            featureFlags.approveReceipts = featureFlags.sendReceipts = featureFlags.receiptsAfterCheckout = false;
            setAllowReceipts(false);
            break;
          case 'sendReceipts':
            featureFlags.approveReceipts = featureFlags.receiptsAfterCheckout = false;
            setAllowReceipts(false);
            break;
          case 'reviews':
            setAllowReviews(false);
            break;
          case 'manualCheckout':
            featureFlags.integration = false;
            break;
          case 'moments':
            featureFlags.news = false;
            break;
          default:
            break;
        }
      } else {
        if (fieldKey === 'integration') {
          setAllowReceipts(false);
        }
      }
      setFeatureFlags({ ...featureFlags });
      setIsDirty(true);
    } else if (type === 'tablet') {
      tabletFeatureFlags[fieldKey] = fieldValue;
      setTabletFeatureFlags({ ...tabletFeatureFlags });
      setIsDirty(true);
    }
  };

  const handleSave = () => {
    let schedule = props.place?.schedule;
    if (featureFlags.manualCheckout && props.place?.schedule) {
      schedule = null;
    } else if (!featureFlags.manualCheckout && !props.place?.schedule) {
      schedule = [
        new ScheduleDTO(),
        new ScheduleDTO(),
        new ScheduleDTO(),
        new ScheduleDTO(),
        new ScheduleDTO(),
        new ScheduleDTO(),
        new ScheduleDTO()
      ];
    }
    const sendData: { [key: string]: any } = {
      _id: props.place?._id,
      featureFlags,
      tabletFeatureFlags,
      allowReceipts,
      allowReviews,
      schedule
    };
    if (
      !featureFlags.useCoins &&
      (props.place?.settings?.memberRegistration || props.place?.settings.pointsForCheckin)
    ) {
      sendData.settings = {
        ...props.place?.settings,
        memberRegistration: 0,
        pointsForCheckin: 0,
        convertAmountRule: 0
      };
    }
    if (props.save) {
      props.save(sendData);
      setIsDirty(false);
    }
  };

  const disableToggle = (autopilot: string) => {
    switch (autopilot) {
      case 'checkin':
        return !featureFlags.becomeMember;
      case 'useCoins':
      case 'simpleAutopilots':
      case 'manualCheckout':
        return !featureFlags.becomeMember || !featureFlags.checkin;
      case 'sendReceipts':
        return !featureFlags.becomeMember || !featureFlags.checkin || !featureFlags.useCoins;
      case 'approveReceipts':
        return !featureFlags.becomeMember || !featureFlags.checkin || !featureFlags.sendReceipts;
      case 'receiptsAfterCheckout':
        return !featureFlags.sendReceipts;
      case 'integration':
        return !featureFlags.manualCheckout;
      case 'news':
        return !featureFlags.moments;
      case 'externalApp':
        return !!props.place?.externalApp;
      case 'tablet_transactions':
        return !featureFlags.transactions;
      default:
        return false;
    }
  };

  return (
    <CardComponent title="Feature Flags" headerIcon="playlist_add_check" needsTitle={true}>
      <div style={{ padding: 10 }}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            {Object.keys(featureFlags).map((value, index) => {
              if (skipFlags.includes(value)) {
                return null;
              }
              return (
                <div key={index} className="row" style={{ margin: 5 }}>
                  <div className="col-sm-6">{msg(`featureFlags.${value}`, value)}</div>
                  <div className="col-sm-2" style={{ margin: '0px 10px' }}>
                    {featureFlags[value] ? (
                      <div
                        style={{
                          alignSelf: 'center',
                          backgroundColor: 'transparent',
                          color: colors.green,
                          marginRight: 10
                        }}
                        className="label label-success badge-pill pull-left"
                      >
                        {msg('featureFlags.enabled', 'Enabled')}
                      </div>
                    ) : (
                      <div
                        style={{
                          alignSelf: 'center',
                          backgroundColor: 'transparent',
                          color: colors.red,
                          marginRight: 10
                        }}
                        className="label label-danger badge-pill pull-left"
                      >
                        {msg('featureFlags.disabled', 'Disabled')}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-1" style={{ margin: '0px 10px' }}>
                    <Toggle
                      id={value}
                      onChange={(event: any) => handleChange(event, 'place')}
                      checked={featureFlags[value]}
                      disabled={disableToggle(value)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ flex: 1 }}>
            {Object.keys(tabletFeatureFlags).map((value, index) => {
              return (
                <div key={index} className="row" style={{ margin: 5 }}>
                  <div className="col-sm-6">{msg(`featureFlags.${value}`, value)}</div>
                  <div className="col-sm-2" style={{ margin: '0px 10px' }}>
                    {tabletFeatureFlags[value] ? (
                      <div
                        style={{
                          alignSelf: 'center',
                          backgroundColor: 'transparent',
                          color: colors.green,
                          marginRight: 10
                        }}
                        className="label label-success badge-pill pull-left"
                      >
                        {msg('featureFlags.enabled', 'Enabled')}
                      </div>
                    ) : (
                      <div
                        style={{
                          alignSelf: 'center',
                          backgroundColor: 'transparent',
                          color: colors.red,
                          marginRight: 10
                        }}
                        className="label label-danger badge-pill pull-left"
                      >
                        {msg('featureFlags.disabled', 'Disabled')}
                      </div>
                    )}
                  </div>
                  <div className="col-sm-1" style={{ margin: '0px 10px' }}>
                    <Toggle
                      id={value}
                      onChange={(event: any) => handleChange(event, 'tablet')}
                      checked={tabletFeatureFlags[value]}
                      disabled={disableToggle(`tablet_${value}`)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {
          <div style={{ margin: 10 }}>
            <div className="col-md-3" />
            <div className="col-md-6" style={{ textAlign: 'center' }}>
              <div className="form-group form-button">
                <ButtonComponent
                  label={msg('general.save', 'Save')}
                  icon="save"
                  action={handleSave}
                  disabled={!isDirty}
                />
              </div>
            </div>
            <div className="col-md-3" />
          </div>
        }
      </div>
    </CardComponent>
  );
};

export default PlaceFeatureFlagsComponent;
