/* eslint-disable jsx-a11y/anchor-is-valid */
import { debounce } from 'lodash';
import * as React from 'react';
import { Bounce } from 'react-activity';
import ReactImageZoom from 'react-image-zoom';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Toggle from 'react-toggle';

import { Popover } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

import { SimpleSelectComponent, TextFieldComponent } from '../';
import { PaginationDTO } from '../../../appRedux/types';
import { colors } from '../../../assets/styles';
import { deserializeQueryString, msg } from '../../../utils';
import { sortIconStyle, spinnerOverlayStyle, upIconStyle } from './AdvancedListStyles';
import { IProps, IState } from './AdvancedListTypes';

class PaginatedListComponent extends React.Component<IProps & RouteComponentProps, IState> {
  actions: IProps['actions'];
  limits: string[] = [];
  filterValues: IProps['filters'];
  defaultPagination = {
    limit: 30
  };
  getDataDebounced = debounce(this.getData, 300);

  constructor(props: IProps & RouteComponentProps) {
    super(props);
    let fields: Array<{ key: string; value: string }> = [];
    Object.keys(this.props.fields).forEach(key => {
      fields.push({
        key: key,
        value: this.props.fields[key]
      });
    });
    if (this.props.filters) {
      this.filterValues = this.props.filters || [];
    }
    for (let i = 10; i <= 50; i += 10) {
      this.limits.push(i.toString());
    }
    this.actions = this.props.actions || [];
    this.state = {
      fields: fields,
      limit: this.props.limit || 30,
      sort: (this.props.sort && this.props.sort.default) || undefined,
      skip: 0,
      search: this.props.defaultSearchValue ? this.props.defaultSearchValue : '',
      filters: this.props.filters?.map(filter => filter.default || '') || undefined,
      popoverAnchor: null,
      popoverId: '',
      data: this.props.list || new PaginationDTO()
    };
  }

  componentDidMount() {
    const parsedSearch = deserializeQueryString(this.props.location.search);
    let newState = { ...this.state };
    if (!this.props.disableQueryParams) {
      const fields = ['search', 'skip', 'limit'];
      fields.forEach(field => {
        if (parsedSearch[field]) {
          newState[field] = !isNaN(parseInt(parsedSearch[field], 10))
            ? parseInt(parsedSearch[field], 10)
            : decodeURIComponent(parsedSearch[field]);
          delete parsedSearch[field];
        }
      });
      if (!parsedSearch.sort && this.props.sort?.default) {
        this.setQueryParams(this.props.sort?.default, 'sort');
      } else if (parsedSearch.sort) {
        newState.sort = parsedSearch.sort;
        delete parsedSearch.sort;
      }
      newState.filters = this.props.filters?.map((filter, index) => {
        if (!parsedSearch[filter.field] && filter.default) {
          this.setQueryParams(filter.default, 'filters', index);
        }
        return parsedSearch[filter.field] ? parsedSearch[filter.field] : filter.default || '';
      });
    }
    this.setState(newState, () => this.getData(newState.skip || 0));
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props && this.props !== prevProps) {
      let fields: Array<{ key: string; value: string }> = [];
      this.actions = [];
      this.filterValues = [];
      Object.keys(this.props.fields).forEach(key => {
        fields.push({
          key: key,
          value: this.props.fields[key]
        });
      });
      this.actions = this.props.actions || [];
      if (this.props.filters) {
        this.filterValues = this.props.filters || [];
      }
      this.setState({ fields });
    }
  }

  isData() {
    return !!(
      this.state.fields &&
      this.state.fields.length &&
      this.props.list &&
      this.props.list.results &&
      this.props.list.results.length
    );
  }

  getData(skip: number) {
    if (this.props.simple && this.props.get) {
      this.props.get();
      return;
    }
    const { limit, sort, search, filters } = this.state;
    if (limit > 0 && skip >= 0 && this.props.get) {
      let criteria: any = { search: {}, filters: {} };
      if (search && this.props.search.length > 0) {
        this.props.search.forEach((searchItem: string) => {
          criteria.search[searchItem] = search;
        });
      }
      if (filters?.length && this.props.filters?.length) {
        this.props.filters.forEach((item, index) => {
          criteria.filters[item.field] = filters[index] || undefined;
        });
      }
      this.setState({ skip });
      if (!this.props.disableQueryParams) {
        this.setQueryParams(skip ? skip + '' : '', 'skip');
      }
      this.props.get(limit, skip, sort, criteria);
    }
  }

  setQueryParams = (value: string, type: string, index?: number) => {
    let field = '';
    if (type === 'filters' && this.props.filters && typeof index === 'number') {
      field = this.props.filters[index]?.field;
    } else {
      field = type;
    }
    const parsedSearch = deserializeQueryString(this.props.location.search);
    if (value) {
      parsedSearch[field] = value;
    } else {
      delete parsedSearch[field];
    }
    let querySearch = '';
    if (this.props.filters) {
      querySearch = this.props.filters
        .map(filter => {
          const filterValue = parsedSearch[filter.field];
          delete parsedSearch[filter.field];
          return filterValue ? `${filter.field}=${filterValue}` : undefined;
        })
        .filter(item => !!item)
        .join('&');
    }
    if (this.props.search?.length && parsedSearch.search) {
      if (querySearch.length) {
        querySearch += '&';
      }
      querySearch += `search=${parsedSearch.search}`;
      delete parsedSearch.search;
    }
    const filteredParsedSearch = Object.keys(parsedSearch).filter(key => !!key);
    if (filteredParsedSearch.length) {
      if (querySearch.length) {
        querySearch += '&';
      }
      querySearch += filteredParsedSearch.map(key => `${key}=${parsedSearch[key]}`).join('&');
    }
    this.props.history.replace({ search: `?${querySearch}` });
  };

  setFilter = (event: any, index: number) => {
    if (event && event.target) {
      const filters = this.state.filters;
      if (filters) {
        filters[index] = event.target.value;
        if (!this.props.disableQueryParams) {
          this.setQueryParams(event.target.value, 'filters', index);
        }
      }
      this.setState({ filters }, () => {
        this.getData(0);
      });
    }
  };

  setFilterByValue = (value: any, index: number) => {
    const filters = this.state.filters;
    if (filters) {
      filters[index] = value;
      if (!this.props.disableQueryParams) {
        this.setQueryParams(value, 'filters', index);
      }
    }
    this.setState({ filters }, () => {
      this.getData(0);
    });
  };

  setLimit = (event: any) => {
    if (event && event.target) {
      if (!this.props.disableQueryParams) {
        this.setQueryParams(event.target.value, 'limit');
      }
      this.setState({ limit: parseInt(event.target.value, 0) }, () => {
        this.getData(0);
      });
    }
  };

  setSearch = (event: any) => {
    if (event && event.target) {
      this.setState({ search: event.target.value }, () => {
        this.getDataDebounced(0);
      });
      if (!this.props.disableQueryParams) {
        this.setQueryParams(encodeURIComponent(event.target.value), 'search');
      }
    }
  };

  setSearchByValue = (value: string) => {
    this.setState({ search: value }, () => {
      this.getDataDebounced(0);
    });
    if (!this.props.disableQueryParams) {
      this.setQueryParams(encodeURIComponent(value), 'search');
    }
  };

  clearSearch = () => {
    if (!this.props.disableQueryParams) {
      this.setQueryParams('', 'search');
    }
    this.setState({ search: '' }, () => {
      this.getDataDebounced(0);
    });
  };

  setSort = (field: string) => () => {
    let newSort = field;
    if (!this.props.simple && this.state.sort && this.props.sort && this.props.sort.fields.indexOf(field) >= 0) {
      let sort = this.state.sort.split(',');
      if (sort[0] === field && sort[1] === '1') {
        newSort += ',-1';
      } else {
        newSort += ',1';
      }
      if (!this.props.disableQueryParams) {
        this.setQueryParams(newSort, 'sort');
      }
      this.setState({ sort: newSort }, () => {
        this.getData(0);
      });
    }
  };

  setPage = (type: string, page?: number) => () => {
    const { limit } = this.state;
    const total = this.props.list.total_record_count;
    const skip = this.props.list.skip;
    switch (type) {
      case 'NEXT':
        const newSkip = skip + limit;
        if (newSkip < total) {
          this.getData(newSkip);
        }
        break;
      case 'PREVIOUS':
        if (skip - limit >= 0) {
          this.getData(skip - limit);
        }
        break;
      case 'LAST':
        this.getData((Math.ceil(total / limit) - 1) * limit);
        break;
      case 'PAGE':
        if (page) {
          const ns = (page - 1) * limit;
          if (ns <= total && ns >= 0) {
            this.getData(ns);
          }
        }
        break;
      default:
        break;
    }
  };

  handlePopoverClick = (event: any, itemId: string) => {
    this.setState({ popoverAnchor: event.currentTarget, popoverId: itemId });
  };

  handlePopoverClose = () => {
    this.setState({ popoverAnchor: null, popoverId: '' });
  };

  getOptions() {
    return (
      <div className="row">
        {this.props.search.length > 0 && (
          <div className="col-sm-4" style={{ margin: 0, padding: 0, paddingLeft: 15 }}>
            <TextFieldComponent
              label={msg('general.search', 'Search')}
              id="search"
              value={this.state.search ? this.state.search : ''}
              onChange={this.setSearch}
              inType={this.state.search ? 'clearable' : 'text'}
              handleClear={this.clearSearch}
              maxLength={50}
              required={false}
            />
          </div>
        )}
        {this.props.filters &&
          this.filterValues &&
          this.filterValues.map((filter, index) => (
            <div key={index} className="col-sm-3">
              <SimpleSelectComponent
                label={msg('general.filterBy', 'Filter by')}
                name={`noOfFilterOptions${index}`}
                options={filter.value || []}
                id={`noOfFilterOptions${index}`}
                value={this.state.filters ? this.state.filters[index] : ''}
                onChange={(event: any) => this.setFilter(event, index)}
                required={false}
                needsAllLabel={false}
                arrayOptions={false}
                shrinkLabel={true}
              />
            </div>
          ))}
        <div className="col-sm-1">
          <SimpleSelectComponent
            label={msg('general.nrOfElements', 'Items/pg')}
            name="noOfItems"
            options={this.limits}
            id="noOfItems"
            value={this.state.limit.toString()}
            onChange={this.setLimit}
            required={false}
            needsAllLabel={false}
            arrayOptions={true}
            shrinkLabel={true}
          />
        </div>
      </div>
    );
  }

  getHeader() {
    return (
      <tr>
        {this.props.checkbox && (
          <th>
            <Checkbox
              checked={this.props.checkbox.selectedAll}
              indeterminate={this.props.checkbox.intermediate}
              onChange={this.props.checkbox.onSelectAll}
              color="primary"
            />
          </th>
        )}
        {this.props.images &&
          this.props.images.map((item: any, i: number) => {
            return <th key={i}>{item.name}</th>;
          })}
        {this.state.fields.map(item => {
          let sortIcon = null;
          if (!this.props.simple && this.props.sort && this.props.sort.fields.indexOf(item.key) >= 0) {
            if (this.state.sort && this.state.sort.indexOf(item.key) >= 0) {
              const sortDirection = this.state.sort.split(',')[1];
              if (sortDirection === '1') {
                sortIcon = (
                  <i className="material-icons" style={upIconStyle}>
                    arrow_drop_up
                  </i>
                );
              } else {
                sortIcon = (
                  <i className="material-icons" style={upIconStyle}>
                    arrow_drop_down
                  </i>
                );
              }
            } else {
              sortIcon = (
                <i className="material-icons" style={sortIconStyle}>
                  sort
                </i>
              );
            }
          }
          return (
            <th key={item.key} onClick={this.setSort(item.key)} style={{ cursor: sortIcon ? 'pointer' : 'default' }}>
              {item.value}
              {sortIcon}
            </th>
          );
        })}
        {this.props.customColumn && <th key="custom">{this.props.customColumn.name}</th>}
        {this.props.input &&
          this.props.input.map((item: any, i: number) => {
            return <th key={i}>{item.name}</th>;
          })}
        {this.props.select &&
          this.props.select.map((item: any, i: number) => {
            return <th key={i}>{item.name}</th>;
          })}
        {this.props.toggles &&
          this.props.toggles.map((item: any, i: number) => {
            return (
              <th key={i} style={{ width: 120 }}>
                {item.name}
              </th>
            );
          })}
        {this.actions && this.actions.length ? (
          <th style={{ width: 140 }}>{msg('general.actions', 'Actions')}</th>
        ) : null}
      </tr>
    );
  }

  getBody() {
    return this.props.list.results.map((listItem, key) => {
      return (
        <tr key={key}>
          {this.props.checkbox && (
            <td>
              <Checkbox
                checked={listItem[this.props.checkbox.field]}
                onChange={() => {
                  this.props.checkbox?.onChange(listItem._id);
                }}
                color="primary"
              />
            </td>
          )}
          {this.props.images &&
            this.props.images.map((item: any, i: number) => {
              return (
                <td key={i}>
                  {listItem[item.field] ? (
                    <div className="zoom-image">
                      <div className="zoom-overlay" />
                      <ReactImageZoom
                        height={300}
                        img={listItem[item.field]}
                        scale={1}
                        zoomLensStyle="cursor:crosshair; border:1px solid white; z-index:2; background-color:rgba(255,255,255,0.15)"
                      />
                    </div>
                  ) : listItem.renderPlaceholder ? (
                    listItem.renderPlaceholder()
                  ) : (
                    <div />
                  )}
                </td>
              );
            })}
          {this.state.fields.map((fieldItem, i) => {
            if (fieldItem && fieldItem.key && fieldItem.key.indexOf('.') > 0) {
              const f = fieldItem.key.split('.');
              let value = listItem[f[0]][f[1]]?.toString() || '';
              return (
                <td key={i} style={{ fontWeight: listItem.read === false ? 'bold' : 'normal' }}>
                  {value}
                </td>
              );
            } else if (
              (fieldItem && fieldItem.key && listItem[fieldItem.key]) ||
              (fieldItem && fieldItem.key && listItem[fieldItem.key] === 0)
            ) {
              let value = listItem[fieldItem.key];
              if (typeof value === 'object' && value.type === 'custom') {
                return <td key={i}>{value.render()}</td>;
              } else {
                value = value.toString();
              }
              return (
                <td key={i} style={{ fontWeight: listItem.read === false ? 'bold' : 'normal' }}>
                  {listItem.badge && fieldItem.key === 'statusLabel' ? (
                    <div className="badge" style={{ backgroundColor: listItem.badge }}>
                      {value}
                    </div>
                  ) : (
                    value
                  )}
                </td>
              );
            } else {
              return <td key={i}>N/A</td>;
            }
          })}
          {this.props.customColumn && <td key="custom">{this.props.customColumn.renderColumn(listItem)}</td>}
          {this.props.input &&
            this.props.input.map((item: any, i: number) => {
              return (
                <td key={i}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextFieldComponent
                      id={item._id}
                      value={listItem[item.field] || ''}
                      onChange={(event: any) => item.onChange(key, event.target.value)}
                      onBlur={() => {
                        if (item.onBlur) {
                          item.onBlur(key);
                        }
                      }}
                      onKeyUp={(event: any) => {
                        if (event.keyCode === 13) {
                          item.onBlur(key);
                        }
                      }}
                      readOnly={item.readOnly ? item.readOnly(listItem) : false}
                      required={false}
                      style={{ padding: 0, width: 75 }}
                      formatError={!listItem.isValid && item.errorText}
                      validatorIgnore={!item.validate || !listItem.isDirty}
                    />
                    <div style={{ marginLeft: 5 }}>{item.label}</div>
                  </div>
                </td>
              );
            })}
          {this.props.select &&
            this.props.select.map((item: any, i: number) => {
              return (
                <td key={i}>
                  <SimpleSelectComponent
                    name={item.name}
                    options={item.options}
                    id={item._id}
                    value={listItem[item.field]}
                    onChange={item.onChange(listItem._id)}
                    required={false}
                    needsAllLabel={false}
                    arrayOptions={false}
                    style={{ padding: 0, paddingRight: 15 }}
                    needsFullLength={true}
                  />
                </td>
              );
            })}
          {this.props.toggles &&
            this.props.toggles.map((item: any, i: number) => {
              return (
                <td key={i}>
                  <Tooltip title={item.tooltip || ''}>
                    <div>
                      <Toggle
                        onChange={item.onChange(listItem._id)}
                        checked={listItem[item.field]}
                        disabled={item.disabled}
                      />
                    </div>
                  </Tooltip>
                </td>
              );
            })}
          {this.actions && this.actions.length ? (
            this.actions.length < 4 ? (
              <td className="td-actions">
                {this.actions.map((action, aKey) => {
                  const isShown = typeof action.isShown === 'function' ? action.isShown : () => true;
                  if (!isShown(listItem)) {
                    return null;
                  }
                  return (
                    <button
                      key={aKey}
                      type="button"
                      data-rel="tooltip"
                      className={`btn ${action.btn}`}
                      onClick={action.onClick}
                      title={action.label}
                      id={listItem._id}
                    >
                      {action.isPending && action.isPending(listItem._id) ? (
                        <Bounce color={colors.white} size={9} />
                      ) : (
                        <i className="material-icons">{action.icon}</i>
                      )}
                    </button>
                  );
                })}
              </td>
            ) : (
              <td className="td-actions">
                <i
                  className="material-icons"
                  style={{ color: colors.green, cursor: 'pointer', fontSize: 28 }}
                  id={listItem._id}
                  onClick={e => this.handlePopoverClick(e, listItem._id)}
                >
                  more_vert
                </i>
                <Popover
                  open={!!this.state.popoverAnchor && this.state.popoverId === listItem._id}
                  anchorEl={this.state.popoverAnchor}
                  onClose={this.handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', padding: 5 }}>
                    {this.actions.map((action, aKey) => {
                      const isShown = typeof action.isShown === 'function' ? action.isShown : () => true;
                      if (!isShown(listItem)) {
                        return null;
                      }
                      return (
                        <div
                          key={aKey}
                          className="advanced-list-actions"
                          id={listItem._id}
                          onClick={event => {
                            this.handlePopoverClose();
                            document.body.style.removeProperty('overflow');
                            document.body.style.removeProperty('padding-right');
                            action.onClick(event);
                          }}
                        >
                          <button key={aKey} type="button" className={`btn ${action.btn} popover-btn`}>
                            <i className="material-icons">{action.icon}</i>
                          </button>
                          <div style={{ paddingLeft: 3 }}>{action.label}</div>
                        </div>
                      );
                    })}
                  </div>
                </Popover>
              </td>
            )
          ) : null}
        </tr>
      );
    });
  }

  getPagination() {
    const currentPage = Math.ceil(this.props.list.skip / this.state.limit) + 1;
    const total = this.props.list.total_record_count;
    const noOfPages = Math.ceil(total / this.state.limit);
    const from = (currentPage - 1) * this.state.limit + 1;
    const to = currentPage === noOfPages ? total : from + this.state.limit - 1;
    // tslint:disable-next-line:no-empty
    const noHandler = () => {};
    let backClass, firstClass, fwClass, lastClass;
    firstClass = backClass = fwClass = lastClass = 'paginate_button page-item';
    let backClick = this.setPage('PREVIOUS');
    let fwClick = this.setPage('NEXT');
    let lastClick = this.setPage('LAST');
    let firstClick = this.setPage('PAGE', 1);
    if (currentPage === 1 || noOfPages === 1) {
      firstClass += ' disabled';
      backClass += ' disabled';
      firstClick = backClick = noHandler;
    }
    if (currentPage === noOfPages || noOfPages === 1) {
      fwClass += ' disabled';
      lastClass += ' disabled';
      fwClick = lastClick = noHandler;
    }
    let startPages = currentPage - 3;
    let endPages = currentPage + 2;
    if (currentPage < 4) {
      startPages = 0;
      endPages = 5;
    } else if (currentPage > noOfPages - 5) {
      startPages = noOfPages - 5;
      endPages = noOfPages + 1;
    }
    return (
      <div className="row">
        <div className="col-sm-12 col-md-5">
          <div style={{ margin: '20px 10px' }}>
            {msg('pagination.showFromToTotal', `Show ${from} to ${to} from ${total} entries`, {
              from: `${from}`,
              to: `${to}`,
              total: `${total}`
            })}
          </div>
        </div>
        <div className="col-sm-12 col-md-7">
          <div style={{ textAlign: 'right' }}>
            <ul className="pagination pagination-success">
              <li className={firstClass}>
                <a className="page-link" onClick={firstClick}>
                  {msg('pagination.first', 'First')}
                </a>
              </li>
              <li className={backClass}>
                <a className="page-link" onClick={backClick}>
                  {msg('pagination.back', 'Back')}
                </a>
              </li>
              {Array.from(Array(noOfPages), (x, i) => {
                if (i >= startPages && i < endPages) {
                  const pg = ++i;
                  let setClick = this.setPage('PAGE', pg);
                  let cls = 'paginate_button page-item';
                  if (pg === currentPage) {
                    cls += ' active';
                    setClick = noHandler;
                  }
                  return (
                    <li className={cls} key={i}>
                      <a className="page-link" onClick={setClick}>
                        {pg}
                      </a>
                    </li>
                  );
                } else {
                  return null;
                }
              })}
              <li className={fwClass}>
                <a className="page-link" onClick={fwClick}>
                  {msg('pagination.next', 'Next')}
                </a>
              </li>
              <li className={lastClass} onClick={lastClick}>
                <a className="page-link">{msg('pagination.last', 'Last')}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const isData: Boolean = this.isData();
    const pending: Boolean = this.props.pending;
    return (
      <div style={{ minHeight: 200 }}>
        <div className="table-responsive">
          {!this.props.simple && this.getOptions()}
          {isData && (
            <div style={{ overflowY: 'auto' }}>
              <table className={`table table-hover ${this.props.tableClassName || ''}`}>
                <thead className="text-primary">{this.getHeader()}</thead>
                <tbody>{this.getBody()}</tbody>
              </table>
            </div>
          )}
          {pending && (
            <div style={{ ...spinnerOverlayStyle }}>
              <h4 style={{ color: colors.white }}>{msg('general.loading', 'Loading...')}</h4>
              <Bounce color={colors.white} />
            </div>
          )}
          {!isData && !pending && (
            <h3 style={{ marginTop: 60 }}>{msg('general.noAvailableData', 'There is no available data.')}</h3>
          )}
          {isData && !this.props.simple && this.getPagination()}
        </div>
      </div>
    );
  }
}
const AdvancedListComponent: any = withRouter(PaginatedListComponent);

export default AdvancedListComponent;
