import { clone, isEqual } from 'lodash';
import React from 'react';

import { PartnerDTO, PlaceDTO } from '../../../../../appRedux/types';
import galleryPlaceholder from '../../../../../assets/images/galleryPlaceholder.jpg';
import {
    getAddressFromGeosuggest, getFormattedAddress, IValidator, msg, Validator
} from '../../../../../utils';
import {
    ButtonComponent, GeoSuggest, GoogleMapsComponent, SimpleSelectComponent, TextFieldComponent,
    UploadComponent
} from '../../../../shared';

interface IProps {
  partner?: PartnerDTO | null;
  save: (partner: PartnerDTO, place?: PlaceDTO) => void;
  pending?: boolean;
  addPlace?: boolean;
  needsEditIndustry?: boolean | null;
  extraData: {
    industries: any;
  };
  alternative_address_flag?: boolean | null;
}
interface IState {
  partner: PartnerDTO;
  place: PlaceDTO;
  isDirty: boolean;
  placeValidation?: {
    message: any;
    valid: boolean;
  };
  additionalData: any;
}

class PartnerFormComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      partner: props.partner || new PartnerDTO(),
      place: new PlaceDTO(),
      isDirty: false,
      additionalData: ''
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.partner && !isEqual(prevProps.partner, this.props.partner)) {
      this.setState({ partner: this.props.partner });
    }
  }

  saveHandler = () => {
    const { partner, place } = this.state;
    place.featureFlags = {
      alternativeAddress: !!this.props.alternative_address_flag
    };
    const { validator, save } = this.props;
    const { isValid } = validator!;
    if (isValid() && save) {
      save(partner, place);
    }
  };

  handleIndustryChange = (event: any) => {
    const fieldValue = event.target.value;
    const partner = this.state.partner;
    partner.industry._id = fieldValue;
    const { isDirty } = this.props.validator!;
    isDirty('industry');
    this.setState({
      partner: partner
    });
  };

  handleChange = (event: any) => {
    const fieldKey = event.target.getAttribute('id');

    const fieldValue = event.target.value;
    const { partner, place } = this.state;
    const { isDirty } = this.props.validator!;
    let toClear;
    if (fieldKey.indexOf('.') >= 0) {
      const fields = fieldKey.split('.');
      toClear = fields[1];
      if (fields[0] === 'partner') {
        partner[fields[1]] = fieldValue;
      } else if (fields[0] === 'place') {
        place[fields[1]] = fieldValue;
        if (fields[1] === 'phone') {
          toClear = 'placePhone';
        }
        if (fields[1] === 'name') {
          toClear = 'placeName';
        }
      }
    }

    if (fieldKey === 'address' && place.geolocation) {
      place.geolocation = null;
    }

    isDirty(toClear);

    this.setState({ partner, place, isDirty: true });
  };

  setPlace = (lngCoords: any, latCoords: any, address: string, city: string) => {
    const place = clone(this.state.place);
    place.geolocation = {
      type: 'Point',
      coordinates: [lngCoords, latCoords]
    };
    place.address = address;
    place.city = city;
    return place;
  };

  suggestHandler = async (event?: any, additionalData?: any, locationInfo?: any) => {
    const { lat, lng } = locationInfo.location;
    const response = getAddressFromGeosuggest(locationInfo.gmaps.address_components);
    let address = '';
    if (response.route) {
      address = response.route;
      if (response.street_number) {
        address = `${response.route} ${response.street_number}`;
      }
    }
    const place = this.setPlace(lng, lat, address, response.locality);
    this.setState({ place, additionalData });
  };

  coordsHandler = async (event: any) => {
    const latCoords = event.latLng.lat();
    const lngCoords = event.latLng.lng();
    const response = await getFormattedAddress(latCoords, lngCoords);
    let address = '';
    if (response.route) {
      address = response.route;
      if (response.street_number) {
        address = `${response.route} ${response.street_number}`;
      }
    }
    const place = this.setPlace(lngCoords, latCoords, address, response.locality);
    this.setState({ place });
  };

  sortIndustries = (first: any, second: any) => {
    if (first.name < second.name) {
      return -1;
    }
    if (first.name > second.name) {
      return 1;
    }
    return 0;
  };

  handleMapUpload = (res: any) => {
    const { place } = this.state;
    place.currentMapPhoto = res.data[0].url;
    this.setState({ place });
  };

  render() {
    let { partner, place } = this.state;

    const { errors } = this.props.validator!;

    let industries = [];
    if (this.props.extraData.industries) {
      industries = this.props.extraData.industries.sort(this.sortIndustries);
    }
    return (
      <div>
        <div className="col-sm-12">
          <legend style={{ marginTop: 10, marginBottom: 8 }}>{msg('partner.basicInfo', 'Basic Information')}</legend>
          <div className="row">
            <div className="col-sm-1" />
            <div className="col-sm-5">
              <TextFieldComponent
                label={msg('partner.name', 'Partner name')}
                id="partner.name"
                value={partner.name}
                onChange={this.handleChange}
                inType="text"
                minLength={1}
                maxLength={50}
                required={true}
                formatError={errors.name}
                ref="name"
                validator={[
                  { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 1 } }
                ]}
              />
            </div>
            <div className="col-sm-5">
              <TextFieldComponent
                label={msg('partner.shortname', 'Shortname')}
                id="partner.shortname"
                value={partner.shortname}
                onChange={this.handleChange}
                inType="text"
                minLength={1}
                maxLength={20}
                required={true}
                formatError={errors.shortname}
                validator={[
                  { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 1 } }
                ]}
                ref="shortname"
              />
            </div>
            <div className="col-sm-1" />
          </div>
          <div className="row">
            <div className="col-sm-1" />
            <div className="col-sm-5">
              <TextFieldComponent
                label={msg('partner.phone', 'Phone')}
                id="partner.phone"
                value={partner.phone}
                onChange={this.handleChange}
                inType="text"
                required={true}
                formatError={errors.phone}
                ref="phone"
                validator={[
                  {
                    type: 'isNumeric',
                    msg: msg('formValidation.onlyNumbersPhone', 'A phone must contain only numbers!')
                  },
                  {
                    type: 'isLength',
                    msg: msg('formValidation.fieldRequired', 'Field required!'),
                    params: { min: 1 }
                  }
                ]}
              />
            </div>
            <div className="col-sm-5">
              <TextFieldComponent
                label={msg('partner.vanityname', 'Vanity URL')}
                id="partner.vanityname"
                value={partner.vanityname}
                onChange={this.handleChange}
                inType="text"
              />
            </div>
            <div className="col-sm-1" />
          </div>
          <div className="row">
            <div className="col-sm-1" />
            <div className="col-sm-10">
              <TextFieldComponent
                label={msg('partner.description', 'Description')}
                id="partner.description"
                rows={3}
                value={partner.description}
                onChange={this.handleChange}
                inType="textarea"
                required={true}
                formatError={errors.description}
                ref="description"
                validator={[
                  { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 5 } }
                ]}
              />
            </div>
            <div className="col-sm-1" />
          </div>
          {this.props.needsEditIndustry && (
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-5">
                <SimpleSelectComponent
                  label={msg('partner.industry', 'Industry')}
                  name="partner.industry"
                  options={industries || []}
                  id="industry._id"
                  value={industries.length ? partner.industry && partner.industry._id : ''}
                  onChange={this.handleIndustryChange}
                  required={false}
                  needsAllLabel={false}
                  formatError={errors.industry}
                  arrayOptions={false}
                  style={{ padding: 0 }}
                  ref="industry"
                  validator={[
                    {
                      type: 'checkIfSelected',
                      msg: msg('formValidation.fieldRequired', 'Field required!'),
                      params: partner.industry._id
                    }
                  ]}
                />
              </div>
              <div className="col-sm-6" />
            </div>
          )}
          {this.props.addPlace && (
            <div className="row">
              <div className="col-sm-12">
                <legend style={{ marginTop: 15, marginBottom: 8 }}>
                  {msg('place.placeInfo', 'Place Information')}
                </legend>
                <div className="row">
                  <div className="col-sm-1" />
                  <div className="col-sm-5">
                    <TextFieldComponent
                      label={msg('place.name', 'Name')}
                      id="place.name"
                      value={place.name}
                      onChange={this.handleChange}
                      inType="text"
                      minLength={1}
                      maxLength={50}
                      required={true}
                      formatError={errors.placeName}
                      ref="placeName"
                      validator={[
                        {
                          type: 'isLength',
                          msg: msg('formValidation.inputLength', 'Input too short!'),
                          params: { min: 1 }
                        }
                      ]}
                    />
                  </div>
                  <div className="col-sm-5">
                    <TextFieldComponent
                      label={msg('place.phone', 'Phone')}
                      id="place.phone"
                      value={place.phone}
                      onChange={this.handleChange}
                      inType="text"
                      minLength={10}
                      maxLength={10}
                      required={true}
                      formatError={errors.placePhone}
                      ref="placePhone"
                      validator={[
                        {
                          type: 'isNumeric',
                          msg: msg('formValidation.onlyNumbersPhone', 'A phone must contain only numbers!')
                        },
                        {
                          type: 'isLength',
                          msg: msg('formValidation.fieldRequired', 'Field required!'),
                          params: { min: 1 }
                        }
                      ]}
                    />
                  </div>
                  <div className="col-sm-1" />
                </div>
                <div className="row">
                  <div className="col-sm-1" />
                  <div className="col-sm-10">
                    <TextFieldComponent
                      label={msg('place.description', 'Description')}
                      id="place.description"
                      rows={3}
                      value={place.description}
                      onChange={this.handleChange}
                      inType="textarea"
                      required={false}
                      formatError=""
                    />
                  </div>
                  <div className="col-sm-1" />
                </div>
                <div className="row">
                  <div className="col-sm-1" />
                  {this.props.alternative_address_flag ? (
                    <div className="col-sm-5">
                      <TextFieldComponent
                        label={msg('place.address', 'Address')}
                        id="place.address"
                        value={place.address ? place.address : ''}
                        onChange={this.handleChange}
                        inType="text"
                        minLength={1}
                        maxLength={30}
                        formatError={errors.address}
                        ref="address"
                        validator={[
                          {
                            type: 'isLength',
                            msg: msg('formValidation.fieldRequired', 'Field required!'),
                            params: { min: 1 }
                          }
                        ]}
                      />
                    </div>
                  ) : (
                    <div className="col-sm-5">
                      <GeoSuggest
                        label={msg('place.address', 'Address')}
                        setValues={this.suggestHandler}
                        initialValue={place.address}
                      />
                    </div>
                  )}
                  <div className="col-sm-5">
                    <TextFieldComponent
                      label={msg('place.city', 'City')}
                      id="place.city"
                      value={place.city ? place.city : ''}
                      onChange={this.handleChange}
                      inType="text"
                      minLength={1}
                      maxLength={30}
                      required={true}
                      formatError={errors.city}
                      ref="city"
                      validator={[
                        {
                          type: 'isLength',
                          msg: msg('formValidation.fieldRequired', 'Field required!'),
                          params: { min: 1 }
                        }
                      ]}
                    />
                  </div>
                  <div className="col-sm-1" />
                </div>
              </div>
              {!this.props.alternative_address_flag ? (
                place.geolocation?.coordinates[1] &&
                place.geolocation?.coordinates[0] && (
                  <div className="row">
                    <div className="col-sm-1" />
                    <div className="col-sm-10">
                      <GoogleMapsComponent
                        containerStyle={{ height: 400 }}
                        defaultCenter={{
                          lat: place.geolocation?.coordinates[1],
                          lng: place.geolocation?.coordinates[0]
                        }}
                        lat={place.geolocation?.coordinates[1]}
                        lng={place.geolocation?.coordinates[0]}
                        onClick={this.coordsHandler}
                        onDragEnd={this.coordsHandler}
                      />
                    </div>
                    <div className="col-sm-1" />
                  </div>
                )
              ) : (
                <div>
                  <div className="row">
                    <div className="row" style={{ textAlign: 'center', marginTop: 10 }}>
                      <legend>{msg('place.mapPhoto', 'Map Photo')}</legend>
                    </div>
                    <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', maxWidth: 575 }}>
                      {place.currentMapPhoto ? (
                        <div
                          className="map-image image-ratio-cover"
                          style={{
                            background: `url(${encodeURI(place.currentMapPhoto)})`
                          }}
                        />
                      ) : (
                        <div
                          className="map-image image-ratio-map"
                          style={{ background: `url(${galleryPlaceholder})` }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="flex justify-center">
                      <UploadComponent
                        url={'admin/media?type=MAP'}
                        accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
                        messages={{
                          default: msg('general.uploadImage', 'Upload images by drag-and-drop or click!')
                        }}
                        extraData={{}}
                        onSuccess={res => {
                          this.handleMapUpload(res);
                        }}
                        crop={true}
                        cropAspect={9 / 5}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="row" style={{ margin: 10 }}>
            <div className="col-md-3" />
            <div className="col-md-6" style={{ textAlign: 'center' }}>
              <div className="form-group form-button">
                <ButtonComponent
                  label={msg('general.save', 'Save')}
                  icon="save"
                  action={this.saveHandler}
                  pending={this.props.pending}
                />
              </div>
            </div>
            <div className="col-md-3" />
          </div>

          <div className="row">
            <div className="col-md-6 category form-category">
              <small>*</small>
              {msg('errors.fieldsRequired', 'Required fields')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Validator(PartnerFormComponent);
