import React, { useEffect, useRef, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { AppStore, PayBatchesStore } from '../../../appRedux';
import { AlertUtil, getFormatDate, msg, parseAmount } from '../../../utils';
import { CardComponent, InfiniteScrollListComponent } from '../../shared';
import {
  PaginationDTO,
  PayBatchAttachment,
  PayBatchDTO,
  PayBatchStatus,
  EmailStatus,
  PlaceDTO
} from '../../../appRedux/types';
import { colors } from '../../../assets/styles';
import CreateBatchModal from './components/CreateBatchModal';
import UploadConfirmationModal from './components/UploadConfirmationModal';

interface IProps {
  pay_batches_list: PaginationDTO<PayBatchDTO>;
  pay_batches_list_pending: boolean;
  pay_batches_list_error: string | null;
  create_pay_batch_pending: boolean;
  create_pay_batch_error: string | null;
  add_pay_batch_confirmation_pending: boolean;
  add_pay_batch_confirmation_error: string | null;
  available_places_list: Array<PlaceDTO>;
  getPayBatchesAction(limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }): any;
  createPayBatchAction(placeIds: string[]): any;
  redirectToPlaceSettlements(payBatchId: string): any;
  downloadPayBatchAction(payBatchId: string, created: string): any;
  markPayBatchSentAction(payBatchId: string): any;
  addPayBatchConfirmationAction(payBatchId: string, attachment: PayBatchAttachment): any;
  sendConfirmationEmailAction(payBatchId: string): any;
  downloadPayBatchConfirmationAction(payBatchId: string, originalName: string): any;
  deletePayBatchAction(payBatchId: string): any;
  getAvailablePlacesListAction(): any;
  clearPayBatchesError(): any;
}

const PayBatchesListContainer = (props: IProps & RouteComponentProps) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [currentBatch, setCurrentBatch] = useState('');
  const listRef = useRef<any>();

  useEffect(() => {
    props.getAvailablePlacesListAction();
    return () => {
      props.clearPayBatchesError();
    };
    // tslint:disable-next-line: align
  }, []);

  useEffect(() => {
    if (!props.create_pay_batch_pending && !props.create_pay_batch_error && showCreateModal) {
      setShowCreateModal(false);
    }
    // tslint:disable-next-line: align
  }, [props.create_pay_batch_pending]);

  useEffect(() => {
    if (!props.add_pay_batch_confirmation_pending && !props.add_pay_batch_confirmation_error && currentBatch) {
      setCurrentBatch('');
    }
    // tslint:disable-next-line: align
  }, [props.add_pay_batch_confirmation_pending]);

  const getData = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    props.getPayBatchesAction(limit, skip, sort, criteria);
  };

  const getStatusData = (batchStatus: PayBatchStatus) => {
    switch (batchStatus) {
      case PayBatchStatus.SETTLED:
        return { color: colors.green, text: msg('paymentSettlements.settled', 'Settled') };
      case PayBatchStatus.PENDING:
        return { color: colors.orange, text: msg('paymentSettlements.pending', 'Pending') };
      default:
        return { color: colors.red, text: msg('paymentSettlements.unsettled', 'Unsettled') };
    }
  };

  const getEmailStatusData = (emailStatus: EmailStatus) => {
    switch (emailStatus) {
      case EmailStatus.SENT:
        return { color: colors.green, text: msg('general.yes', 'Yes') };
      case EmailStatus.PENDING:
        return { color: colors.orange, text: msg('paymentSettlements.emailBeingSent', 'Sending') };
      case EmailStatus.PARTIAL:
        return { color: colors.red, text: msg('paymentSettlements.emailPartialSent', 'Partial') };
      default:
        return { color: colors.gray, text: msg('general.no', 'No') };
    }
  };

  const parseList = (list: PaginationDTO<PayBatchDTO>) => {
    return {
      ...list,
      results: list?.results?.length
        ? list?.results.map((item: PayBatchDTO) => ({
            ...item,
            created: getFormatDate(item.created),
            updatedAt: getFormatDate(item.updatedAt),
            places: {
              type: 'custom',
              render: () => {
                const placesString = item.places.join(', ');
                return (
                  <Tooltip title={item.places.length >= 3 ? placesString : ''}>
                    <div
                      style={{
                        maxWidth: '20vw',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        fontWeight: 'normal'
                      }}
                    >
                      {placesString || 'N/A'}
                    </div>
                  </Tooltip>
                );
              }
            },
            amount: parseAmount(item.amount),
            totalAmount: parseAmount(item.totalAmount),
            feeAmount: parseAmount(item.feeAmount),
            statusLabel: {
              type: 'custom',
              render: () => {
                const statusData = getStatusData(item.status);
                return (
                  <div
                    className="badge"
                    style={{
                      backgroundColor: statusData.color,
                      cursor: 'pointer'
                    }}
                    onClick={() => listRef.current?.setFilterByValue(item.status + '', 0)}
                  >
                    {statusData.text}
                  </div>
                );
              }
            },
            emailSent: {
              type: 'custom',
              render: () => {
                const statusData = getEmailStatusData(item.emailStatus);
                return (
                  <div
                    className="badge"
                    style={{
                      backgroundColor: statusData.color
                    }}
                  >
                    {statusData.text}
                  </div>
                );
              }
            }
          }))
        : []
    };
  };

  const createBatch = (placeIds: string[]) => {
    props.createPayBatchAction(placeIds);
  };

  const redirectToPlaceSettlements = (batch: { _id: string }) => {
    props.redirectToPlaceSettlements(batch._id);
  };

  const downloadSettlementExcel = (batch: { _id: string; created: string }) => {
    props.downloadPayBatchAction(batch._id, batch.created);
  };

  const markAsSent = async (batch: { _id: string }) => {
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.markSettlementSentConfirmation', 'You are about to mark a settlement as sent to the bank!'),
      msg('alertMessages.continue', 'Continue')
    );
    if (hasConfirmation) {
      props.markPayBatchSentAction(batch._id);
    }
  };

  const uploadConfirmation = (batch: { _id: string }) => {
    setCurrentBatch(batch._id);
  };

  const sendConfirmationEmail = (batch: { _id: string }) => {
    props.sendConfirmationEmailAction(batch._id);
  };

  const downloadConfirmation = (batch: PayBatchDTO) => {
    props.downloadPayBatchConfirmationAction(
      batch._id,
      batch.attachment?.originalName || `Confirmation for batch ${batch.uniqueNo}`
    );
  };

  const deletePayBatch = async (batch: { _id: string }) => {
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.deleteSettlementConfirmation', 'You are about to delete a settlement!')
    );
    if (hasConfirmation) {
      props.deletePayBatchAction(batch._id);
    }
  };

  const getActions = () => [
    {
      label: msg('paymentSettlements.viewPlaceSettlements', 'View settlements per place'),
      btn: 'btn-light',
      icon: 'info',
      returnFields: ['_id'],
      onClick: redirectToPlaceSettlements
    },
    {
      label: msg('paymentSettlements.downloadBatchExcel', 'Download settlement excel'),
      btn: 'btn-primary',
      icon: 'download',
      returnFields: ['_id', 'created'],
      onClick: downloadSettlementExcel
    },
    {
      label: msg('paymentSettlements.markSent', 'Mark settlement as sent'),
      btn: 'btn-success',
      icon: 'done',
      returnFields: ['_id'],
      isShown: (item: PayBatchDTO) => item.status === PayBatchStatus.UNSETTLED,
      onClick: markAsSent
    },
    {
      label: msg('paymentSettlements.uploadConfirmation', 'Upload bank confirmation'),
      btn: 'btn-info',
      icon: 'upload',
      returnFields: ['_id'],
      isShown: (item: PayBatchDTO) => item.status !== PayBatchStatus.SETTLED,
      onClick: uploadConfirmation
    },
    {
      label: msg('paymentSettlements.downloadConfirmation', 'Download bank confirmation'),
      btn: 'btn-info',
      icon: 'download',
      returnFields: [''],
      isShown: (item: PayBatchDTO) => item.status === PayBatchStatus.SETTLED,
      onClick: downloadConfirmation
    },
    {
      label: msg('paymentSettlements.sendConfirmationEmail', 'Send confirmation email'),
      btn: 'btn-success',
      icon: 'email',
      returnFields: ['_id'],
      isShown: (item: PayBatchDTO) => item.status === PayBatchStatus.SETTLED && item.emailStatus !== EmailStatus.SENT,
      onClick: sendConfirmationEmail
    },
    {
      label: msg('paymentSettlements.delete', 'Delete settlement'),
      btn: 'btn-danger',
      icon: 'delete',
      returnFields: ['_id'],
      isShown: (item: PayBatchDTO) => item.status === PayBatchStatus.UNSETTLED,
      onClick: deletePayBatch
    }
  ];

  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  const parsePlacesForSelect = (list: PlaceDTO[]) => {
    return list?.map(place => {
      return { label: place.name, value: place._id };
    });
  };

  const closeUploadModal = () => {
    setCurrentBatch('');
  };

  return (
    <CardComponent
      title={msg('sidebar.paymentSettlements', 'Payment settlements')}
      error={!!props.pay_batches_list_error}
      headerIcon="credit_score"
      needsTitle={true}
      buttons={[
        {
          label: msg('paymentSettlements.generate', 'Generate batch'),
          icon: 'post_add',
          onClick: toggleCreateModal
        }
      ]}
    >
      {showCreateModal && (
        <CreateBatchModal
          places={parsePlacesForSelect(props.available_places_list)}
          pending={props.create_pay_batch_pending}
          error={props.create_pay_batch_error}
          toggleModal={toggleCreateModal}
          createBatch={createBatch}
        />
      )}
      {currentBatch && (
        <UploadConfirmationModal
          batchId={currentBatch}
          pending={props.add_pay_batch_confirmation_pending}
          toggleModal={closeUploadModal}
          addPayBatchConfirmationAction={props.addPayBatchConfirmationAction}
        />
      )}
      <InfiniteScrollListComponent
        wrappedComponentRef={(ref: any) => (listRef.current = ref)}
        fields={{
          uniqueNo: msg('paymentSettlements.uniqueNo', 'Unique number'),
          created: msg('paymentSettlements.created', 'Date'),
          updatedAt: msg('paymentSettlements.updated', 'Updated'),
          places: msg('paymentSettlements.places', 'Places'),
          placesNo: msg('paymentSettlements.paySettlementsNo', 'Number of places included'),
          totalAmount: msg('paymentSettlements.totalAmount', 'Total Amount'),
          feeAmount: msg('paymentSettlements.feeAmount', 'Fee Amount'),
          amount: msg('paymentSettlements.amount', 'Settlement Amount'),
          emailSent: msg('paymentSettlements.emailSent', 'Email Sent'),
          statusLabel: msg('paymentSettlements.status', 'Status')
        }}
        actions={getActions()}
        list={parseList(props.pay_batches_list)}
        pending={props.pay_batches_list_pending}
        error={props.pay_batches_list_error}
        get={getData}
        limit={30}
        filters={[
          {
            field: 'status',
            value: [
              {
                _id: '',
                name: msg('paymentSettlements.allSettlements', 'All settlements')
              },
              {
                _id: PayBatchStatus.SETTLED + '',
                name: msg('paymentSettlements.settledFilter', 'Settled')
              },
              {
                _id: PayBatchStatus.UNSETTLED + '',
                name: msg('paymentSettlements.unsettledFilter', 'Unsettled')
              },
              {
                _id: PayBatchStatus.PENDING + '',
                name: msg('paymentSettlements.pending', 'Pending')
              }
            ],
            default: ''
          }
        ]}
        search={[]}
        sort={{
          fields: ['created', 'updatedAt', 'paySettlementsNo', 'amount'],
          default: 'created,-1'
        }}
      />
    </CardComponent>
  );
};

function mapStateToProps(state: AppStore.states) {
  return {
    pay_batches_list: state.payBatches.pay_batches_list,
    pay_batches_list_pending: state.payBatches.pay_batches_list_pending,
    pay_batches_list_error: state.payBatches.pay_batches_list_error,
    add_pay_batch_confirmation_pending: state.payBatches.add_pay_batch_confirmation_pending,
    add_pay_batch_confirmation_error: state.payBatches.add_pay_batch_confirmation_error,
    create_pay_batch_pending: state.payBatches.create_pay_batch_pending,
    create_pay_batch_error: state.payBatches.create_pay_batch_error,
    available_places_list: state.payBatches.available_places_list
  };
}

const dispatchToProps = {
  getPayBatchesAction: PayBatchesStore.actions.getPayBatchesAction,
  createPayBatchAction: PayBatchesStore.actions.createPayBatchAction,
  redirectToPlaceSettlements: PayBatchesStore.actions.redirectToPlaceSettlements,
  downloadPayBatchAction: PayBatchesStore.actions.downloadPayBatchAction,
  markPayBatchSentAction: PayBatchesStore.actions.markPayBatchSentAction,
  addPayBatchConfirmationAction: PayBatchesStore.actions.addPayBatchConfirmationAction,
  sendConfirmationEmailAction: PayBatchesStore.actions.sendConfirmationEmailAction,
  downloadPayBatchConfirmationAction: PayBatchesStore.actions.downloadPayBatchConfirmationAction,
  deletePayBatchAction: PayBatchesStore.actions.deletePayBatchAction,
  getAvailablePlacesListAction: PayBatchesStore.actions.getAvailablePlacesListAction,
  clearPayBatchesError: PayBatchesStore.actions.clearPayBatchesError
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(PayBatchesListContainer));
