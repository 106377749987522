import { colors } from '../assets/styles';

export const hexToRgba = (hex: string, alpha: number) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r},${g},${b},${alpha})`;
};

export const getTagStylesForSelect = () => {
  return {
    multiValue: (styles: any, { data }: { data: { label: string; value: string; color: string } }) => {
      const color = data.color || colors.gray;
      return {
        ...styles,
        padding: '0 5px',
        backgroundColor: hexToRgba(color, 0.2),
        border: `2px solid ${color}`,
        borderRadius: 16
      };
    },
    multiValueLabel: (styles: any) => ({
      ...styles,
      padding: 1,
      marginTop: 1
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      cursor: 'pointer',
      paddingLeft: 4,
      height: 18,
      width: 18,
      marginTop: 3,
      marginLeft: 5,
      color: 'rgba(255, 255, 255, 0.8)',
      backgroundColor: 'rgba(0, 0, 0, 0.26)',
      borderRadius: 50,
      ':hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.35)'
      }
    })
  };
};
