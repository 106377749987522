import * as React from 'react';

import { AppStore, reduxContainer, TranslationsStore } from '../../../appRedux';
import backgroundImage from '../../../assets/images/background.jpg';
import { FooterAuthComponent, NavAuthComponent, SentEmailComponent } from './components';

interface IProps extends TranslationsStore.IState {}
interface IState {}

class SentRecoveryEmailContainer extends React.Component<IProps & TranslationsStore.IActions, IState> {
  render() {
    const navAuthConfig = {
      title: 'ReGage Partner',
      initial_language: this.props.initial_language,
      changeLanguage: this.props.changeLanguageAction
    };

    return (
      <div className="partner-auth off-canvas-sidebar">
        <NavAuthComponent config={navAuthConfig} />
        <div className="wrapper wrapper-full-page">
          <div className="full-page login-page" filter-color="black">
            <div className="content" style={{ paddingTop: '30vh' }}>
              <SentEmailComponent />
            </div>
            <FooterAuthComponent />
            <div className="full-page-background" style={{ backgroundImage: `url(${backgroundImage})` }} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    initial_language: state.translations.initial_language
  };
}

const dispatchToProps = {
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction
};

export default reduxContainer(SentRecoveryEmailContainer, mapStateToProps, dispatchToProps);
