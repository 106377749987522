import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
    AppStore, MembersStore, PartnersStore, PlacesStore, reduxContainer, TagsStore, UsersStore
} from '../../../appRedux';
import { ActivityStore } from '../../../appRedux/activity';
import { adminRoutes } from '../../layouts';
import { UsersEditComponent, UsersListComponent } from './components';

interface IProps
  extends UsersStore.IState,
    PartnersStore.IState,
    PlacesStore.IState,
    MembersStore.IState,
    ActivityStore.IState,
    TagsStore.IState {}
interface IState {}

type Actions = UsersStore.IActions &
  PartnersStore.IActions &
  MembersStore.IActions &
  ActivityStore.IActions &
  TagsStore.IActions;

// tslint:disable-next-line: max-line-length
class UsersContainer extends React.Component<IProps & Actions, IState> {
  render() {
    const userEditProps = {
      pending: this.props.user_save_pending,
      error: this.props.user_save_error,
      loaded: this.props.user_b,
      load_pending: this.props.user_pending,
      load_error: this.props.user_error,
      get: this.props.getUserAction,
      edit: this.props.editUserAction,
      changePasswordAsAdmin: this.props.changePasswordAsAdmin,
      memberships_for_user_list: this.props.memberships_for_user_list,
      memberships_for_user_list_pending: this.props.memberships_for_user_list_pending,
      memberships_for_user_list_error: this.props.memberships_for_user_list_error,
      getMembershipsForUser: this.props.getMembershipsForUser,
      changeMemberStatus: this.props.changeMemberStatus,
      favouriteMoments: this.props.favouriteMoments,
      favouriteMomentsPending: this.props.favouriteMomentsPending,
      favouriteMomentsError: this.props.favouriteMomentsError,
      fetchFavouriteMoments: this.props.fetchFavouriteMomentsAction,
      fetchUserActivity: this.props.fetchUserActivityAction,
      selectedCheckinId: this.props.selected_checkin,
      openCheckinActivityList: this.props.openCheckinActivityList,
      closeCheckinActivityList: this.props.closeCheckinActivityList,
      tags_list: this.props.tags_list,
      getTagsAction: this.props.getTagsAction
    };

    const userPartnerEditProps = {
      ...userEditProps,
      add: this.props.addPartnerAdminAction
    };

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Switch>
              <Route exact={true} path="/admin/users" render={() => <UsersListComponent />} />
              <Route
                path={adminRoutes.USERS.subroutes.ADD.path}
                render={({ match }) => (
                  <UsersEditComponent match={match} editMode={false} config={userPartnerEditProps} forPartner={true} />
                )}
              />
              <Route
                path={adminRoutes.USERS.subroutes.EDIT.path}
                render={({ match }) => (
                  <UsersEditComponent match={match} editMode={true} config={userPartnerEditProps} forSA={true} />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    user_b: state.users.user_b,
    user_pending: state.users.user_pending,
    user_error: state.users.user_error,
    user_save_pending: state.users.user_save_pending,
    user_save_error: state.users.user_save_error,
    place: state.places.place,
    memberships_for_user_list: state.members.memberships_for_user_list,
    memberships_for_user_list_pending: state.members.memberships_for_user_list_pending,
    memberships_for_user_list_error: state.members.memberships_for_user_list_error,
    favouriteMoments: state.members.favouriteMoments,
    favouriteMomentsPending: state.members.favouriteMomentsPending,
    favouriteMomentsError: state.members.favouriteMomentsError,
    tags_list: state.tags.tags_list
  };
}

const dispatchToProps = {
  getUserAction: UsersStore.actions.getUserAction,
  addPartnerAdminAction: UsersStore.actions.addPartnerAdminAction,
  addPlaceAdminAction: UsersStore.actions.addPlaceAdminAction,
  editUserAction: UsersStore.actions.editUserAction,
  changePasswordAsAdmin: UsersStore.actions.changePasswordAsAdmin,
  getMembershipsForUser: MembersStore.actions.getMembershipsForUser,
  changeMemberStatus: MembersStore.actions.changeMemberStatus,
  fetchFavouriteMomentsAction: MembersStore.actions.fetchFavouriteMomentsAction,
  getTagsAction: TagsStore.actions.getTagsAction
};

export default reduxContainer(UsersContainer, mapStateToProps, dispatchToProps);
