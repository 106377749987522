import * as React from 'react';
import { CardComponent, ButtonComponent, AdvancedListComponent } from '../../../shared';
import { AlertUtil, msg, getFormatDate } from '../../../../utils';
import { PaginationDTO, AnnouncementDTO } from '../../../../appRedux/types';
interface IProps {
  announcements: PaginationDTO<any>;
  announcementsFail: string | null;
  announcementsPending: boolean;
  delete_announcement_pending: boolean;
  delete_announcement_error: string | null;
  getAnnouncements: (limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }) => any;
  redirectToAnnouncementContent: (announcementId?: string) => any;
  deleteAnnouncementAction: (announcementId?: string) => any;
}
interface IState {}

export default class AnnouncementsListComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  parserAnnouncements = (list: PaginationDTO<AnnouncementDTO>): any => {
    const results: any[] = [];
    if (list.results) {
      list.results.forEach(item => {
        results.push({
          ...item,
          dispatch: getFormatDate(item.dispatch, 'hour'),
          delivered: item.stats && item.stats.delivered,
          opened: item.stats && item.stats.opens
        });
      });
    }
    return { ...list, results };
  };

  getAnnouncements = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    return this.props.getAnnouncements(limit, skip, sort, criteria);
  };

  editAction = (event: any) => {
    const announcementId = event.currentTarget.getAttribute('id');
    this.props.redirectToAnnouncementContent(announcementId);
  };

  deleteAction = async (event: any) => {
    const announcementId = event.currentTarget.getAttribute('id');
    const hasConfirmation = await AlertUtil.confirm('You are about to delete a announcement!');
    if (hasConfirmation) {
      this.props.deleteAnnouncementAction(announcementId);
    }
  };

  getActions = () => {
    return [
      {
        label: msg('general.edit', 'Edit'),
        btn: 'btn-success',
        icon: 'edit',
        onClick: this.editAction
      },
      {
        label: msg('general.delete', 'Delete'),
        btn: 'btn-danger',
        icon: 'delete',
        ref: 'deleteEvent',
        onClick: this.deleteAction
      }
    ];
  };

  addButtonHandler = (event: any) => {
    this.props.redirectToAnnouncementContent();
  };

  render() {
    const announcements = this.parserAnnouncements(this.props.announcements);
    return (
      <CardComponent title={msg('sidebar.announcements', 'Announcements')} headerIcon="announcements" needsTitle={true}>
        <AdvancedListComponent
          list={announcements}
          fields={{
            title: msg('announcement.title', 'Title'),
            message: msg('announcement.message', 'Message'),
            dispatch: msg('announcement.dispatch', 'Dispatch date'),
            delivered: msg('announcement.delivered', 'Delivered'),
            opened: msg('announcement.opened', 'Opened')
          }}
          sort={{
            fields: ['title', 'dispatch'],
            default: 'dispatch,-1'
          }}
          pending={this.props.announcementsPending}
          error={this.props.announcementsFail}
          search={['title']}
          limit={30}
          get={this.getAnnouncements}
          actions={this.getActions()}
        />
        <div className="row" style={{ margin: 10 }}>
          <div className="col-md-3" />
          <div className="col-md-6" style={{ textAlign: 'center' }}>
            <div className="form-group form-button">
              <ButtonComponent
                label={msg('announcement.addNew', 'Add new announcemen')}
                action={this.addButtonHandler}
                disabled={false}
              />
            </div>
          </div>
          <div className="col-md-3" />
        </div>
      </CardComponent>
    );
  }
}
