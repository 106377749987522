import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { reduxContainer, AppStore, ReceiptsStore, PlacesStore } from '../../../appRedux';
import { ReceiptListComponent } from './components';
import { partnerRoutes, adminRoutes } from '../../layouts';

interface IProps extends ReceiptsStore.IState, PlacesStore.IState {}
interface IState {}

class ReceiptsContainer extends React.Component<IProps & ReceiptsStore.IActions & PlacesStore.IActions, IState> {
  render() {
    const placeReceiptListProps = {
      place: this.props.place,
      receipt: this.props.receipt,
      receipt_list: this.props.receipt_list,
      receipt_list_pending: this.props.receipt_list_pending,
      receipt_list_error: this.props.receipt_list_error,
      delete_succcess: this.props.delete_succcess,
      place_save_pending: this.props.place_save_pending,
      place_save_error: this.props.place_save_error,
      export_receipts_pending: this.props.export_receipts_pending,
      export_receipts_error: this.props.export_receipts_error,
      getReceiptListAction: this.props.getReceiptListAction,
      editReceiptAction: this.props.editReceiptAction,
      deleteReceiptAction: this.props.deleteReceiptAction,
      changeReceiptsAllowed: this.props.changeReceiptsAllowed,
      editPlaceAction: this.props.editPlaceForPartnerAction,
      exportReceiptsInExcel: this.props.exportReceiptsInExcel,
      clearPlaceReceiptsError: this.props.clearPlaceReceiptsError
    };
    let enableRoute = true;
    if (this.props.place && !this.props.place_pending && !this.props.place?.featureFlags?.approveReceipts) {
      enableRoute = false;
    }
    return (
      <Switch>
        <Route
          exact={true}
          path={adminRoutes.RECEIPTS.path}
          render={() => <ReceiptListComponent config={placeReceiptListProps} forSAdmin={true} />}
        />
        {enableRoute && (
          <Route
            exact={true}
            path={partnerRoutes.RECEIPTS.default}
            render={({ match }) => (
              <ReceiptListComponent match={match} config={placeReceiptListProps} forSAdmin={false} />
            )}
          />
        )}
        <Redirect from="*" to={`/partner/dashboard/${this.props.selected_place}`} />
      </Switch>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    place: state.places.place,
    place_pending: state.places.place_pending,
    selected_place: state.places.selected_place,
    place_save_pending: state.places.place_save_pending,
    place_save_error: state.places.place_save_error,
    receipt: state.receipts.receipt,
    receipt_list: state.receipts.receipt_list,
    receipt_list_pending: state.receipts.receipt_list_pending,
    receipt_list_error: state.receipts.receipt_list_error,
    delete_succcess: state.receipts.delete_succcess,
    export_receipts_pending: state.receipts.export_receipts_pending,
    export_receipts_error: state.receipts.export_receipts_error
  };
}

const dispatchToProps = {
  getReceiptListAction: ReceiptsStore.actions.getReceiptListAction,
  editReceiptAction: ReceiptsStore.actions.editReceiptAction,
  deleteReceiptAction: ReceiptsStore.actions.deleteReceiptAction,
  changeReceiptsAllowed: ReceiptsStore.actions.changeReceiptsAllowed,
  clearPlaceReceiptsError: ReceiptsStore.actions.clearPlaceReceiptsError,
  editPlaceForPartnerAction: PlacesStore.actions.editPlaceForPartnerAction,
  exportReceiptsInExcel: ReceiptsStore.actions.exportReceiptsInExcel
};

export default reduxContainer(ReceiptsContainer, mapStateToProps, dispatchToProps);
