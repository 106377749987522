import React from 'react';
import Async from 'react-select/async';
import { debounce } from 'lodash';
import { AxiosResponse } from 'axios';
import { Server, msg } from '../../utils';
import { ValueDTO } from '../../appRedux/types';

interface IProps {
  searchFor: string;
  onChange: (selected?: ValueDTO | null) => void;
  setClear?: any;
  value?: ValueDTO | null;
}

interface IState {
  selected?: any;
}

export default class AutocompleteSelectComponent extends React.Component<IProps, IState> {
  typeMap = {
    USERS: {
      placeholder: msg('searchOptions.searchForUsers', 'Search user to give administrator rights.'),
      noResultsText: msg('searchOptions.noUserFound', 'No user found.'),
      source: Server.searchForUsers
    },
    INDUSTRIES: {
      placeholder: msg('searchOptions.searchForIndustries', 'Search industry.'),
      noResultsText: msg('searchOptions.noIndustryFound', 'No industry found.'),
      source: Server.searchForIndustries
    }
  };
  getDebounced: any;
  constructor(props: IProps) {
    super(props);
    this.state = {
      selected: this.props.value || null
    };
    this.getDebounced = debounce(this.get.bind(this), 300);
  }

  componentDidMount() {
    if (this.props.setClear) {
      this.props.setClear(this.clear);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        selected: this.props.value
      });
    }
  }

  clear = () => {
    this.setState({ selected: null });
  };

  onChange = (selected: ValueDTO) => {
    if (selected && selected._id) {
      this.props.onChange(selected);
    } else {
      this.clear();
      this.props.onChange(null);
    }
  };

  get(input: string, callback: any) {
    if (!input) {
      callback({ options: [] });
    } else if (input.length >= 2) {
      const { source } = this.typeMap[this.props.searchFor];
      source(input).then((response: AxiosResponse) => {
        if (response?.data?.results) {
          const newList: ValueDTO[] = response.data.results.map((element: any) => {
            return {
              _id: element._id,
              value: `${element.firstname} ${element.lastname} - ${element.email}`,
              label: `${element.firstname} ${element.lastname} - ${element.email}`
            };
          });
          callback(newList);
        } else if (response?.data) {
          const newList = [
            {
              _id: response.data._id,
              value: `${response.data.name}`,
              label: `${response.data.name}`
            }
          ];
          callback(newList);
        }
        callback({ options: [] });
      });
    }
  }

  render() {
    const { placeholder, noResultsText } = this.typeMap[this.props.searchFor];
    return (
      <Async
        value={this.state.selected}
        onChange={this.onChange}
        loadOptions={this.getDebounced}
        valueKey="fullName"
        labelKey="label"
        placeholder={placeholder}
        noResultsText={noResultsText}
        menuContainerStyle={{ zIndex: 999 }}
        cache={false}
      />
    );
  }
}
