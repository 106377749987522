import axios from 'axios';
import intl from 'react-intl-universal';

/*
  @function loadLocales => makes an axios call to GET locale files for each language and initiates intl setup
    @accepts language : string of desired language setup (ex: 'ro-RO', 'en-EN')
    @returns intl setup
*/
export function loadLocales(language: string) {
  return axios.get(`/locales/${language}.json`).then(data => {
    const locales = { [language]: data.data };
    return intl.init({
      commonLocaleDataUrls: {
        ro: '/locales/supportFiles/ro.js',
        en: '/locales/supportFiles/en.js'
      },
      currentLocale: language,
      locales
    });
  });
}

/*
  @function msg => function that translates text ; with the given id, the function searches in the created json file
    after the key with the same id and gets the translated value
    @accepts id : string representing the key element from translation json (ex: "alertMessages.goBack")
             defaultValue : string representing the default value, in case the given id is not found in json file
             params (optional) : list of key-value pairs representing parameters for translated text
    @returns string : the translated text
*/
export function msg(id: string, defaultValue: string, params?: { [key: string]: string }) {
  return intl.get(id, params).d(defaultValue);
}

/*
  @function msg => function that translates text ; with the given id, the function searches in the created json file
    after the key with the same id and gets the translated value
    @accepts id : string representing the key element from translation json (ex: "alertMessages.goBack")
             defaultValue : string representing the default value, in case the given id is not found in json file
             params (optional) : list of key-value pairs representing parameters for translated text
    @returns React.Component : the translated text inside a React Component
*/
export function msgHTML(id: string, defaultValue: string, params?: { [key: string]: string }) {
  return intl.getHTML(id, params).d(defaultValue);
}

/*
  @function tlist => function that translates an array of texts, calling msg for each element
    @accepts items : array of objects containing the _id, name = json key, default = defaultValue of each element
    @returns array of translated texts
*/
export function tlist(items: { _id: string | number; name: string; default: string }[]) {
  return items.map(i => ({ ...i, name: msg(i.name, i.default) }));
}
