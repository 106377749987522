import { IndustriesStore } from './';
import { PaginationDTO, SelectValueDTO } from '../types';

/*
  industriesReducer = reducer for all actionTypes for Industries
*/
function industriesReducer(
  state: IndustriesStore.IState = IndustriesStore.initialState,
  action: IAction<any>
): IndustriesStore.IState {
  switch (action.type) {
    case IndustriesStore.ActionTypes.GET_INDUSTRIES: {
      return {
        ...state,
        industries_list_pending: true,
        industries_list_error: null
      };
    }
    case IndustriesStore.ActionTypes.GET_INDUSTRIES_SUCCESS: {
      return {
        ...state,
        industries_list_pending: false,
        industries_list_error: null,
        industries_list: action.payload
      };
    }
    case IndustriesStore.ActionTypes.GET_INDUSTRIES_FAILED: {
      return {
        ...state,
        industries_list_pending: false,
        industries_list: new PaginationDTO(),
        industries_list_error: action.payload
      };
    }
    case IndustriesStore.ActionTypes.GET_INDUSTRIES_SHORT_LIST: {
      return {
        ...state,
        industries_short_list_pending: true,
        industries_short_list_error: null
      };
    }
    case IndustriesStore.ActionTypes.GET_INDUSTRIES_SHORT_LIST_SUCCESS: {
      return {
        ...state,
        industries_short_list_pending: false,
        industries_short_list_error: null,
        industries_short_list: action.payload
      };
    }
    case IndustriesStore.ActionTypes.GET_INDUSTRIES_SHORT_LIST_FAILED: {
      return {
        ...state,
        industries_short_list_pending: false,
        industries_short_list_error: action.payload,
        industries_short_list: new Array<SelectValueDTO>()
      };
    }
    case IndustriesStore.ActionTypes.GET_INDUSTRY: {
      return {
        ...state,
        industry_pending: true,
        industry_error: null
      };
    }
    case IndustriesStore.ActionTypes.GET_INDUSTRY_SUCCESS: {
      return {
        ...state,
        industry_pending: false,
        industry_error: null,
        industry: action.payload
      };
    }
    case IndustriesStore.ActionTypes.GET_INDUSTRY_FAILED: {
      return {
        ...state,
        industry_pending: false,
        industry: null,
        industry_error: action.payload
      };
    }
    case IndustriesStore.ActionTypes.SAVE_INDUSTRY: {
      return {
        ...state,
        industry_save_pending: true,
        industry_save_error: null,
        industry_saved: null
      };
    }
    case IndustriesStore.ActionTypes.SAVE_INDUSTRY_SUCCESS: {
      return {
        ...state,
        industry_save_pending: false,
        industry_save_error: null,
        industry_saved: action.payload
      };
    }
    case IndustriesStore.ActionTypes.SAVE_INDUSTRY_FAILED: {
      return {
        ...state,
        industry_save_pending: false,
        industry_save_error: action.payload,
        industry_saved: null
      };
    }
    case IndustriesStore.ActionTypes.CHANGE_INDUSTRY_STATUS: {
      return {
        ...state,
        industry_change_status: null,
        industry_change_status_pending: false,
        industry_change_status_error: null
      };
    }
    case IndustriesStore.ActionTypes.CHANGE_INDUSTRY_STATUS_SUCCESS: {
      const index = state.industries_list.results
        .map(x => {
          return x._id;
        })
        .indexOf(action.payload._id);
      let new_list: PaginationDTO<any> = state.industries_list;
      new_list.results[index] = action.payload;
      return {
        ...state,
        industry_change_status: action.payload,
        industry_change_status_pending: false,
        industry_change_status_error: null,
        industries_list: { ...new_list }
      };
    }
    case IndustriesStore.ActionTypes.CHANGE_INDUSTRY_STATUS_FAILED: {
      return {
        ...state,
        industry_change_status: null,
        industry_change_status_pending: false,
        industry_change_status_error: action.payload
      };
    }
    case IndustriesStore.ActionTypes.DELETE_INDUSTRY: {
      return {
        ...state,
        industry_delete_pending: true,
        industry_delete_error: null,
        industry_deleted: false
      };
    }
    case IndustriesStore.ActionTypes.DELETE_INDUSTRY_SUCCESS: {
      return {
        ...state,
        industry_delete_pending: false,
        industry_delete_error: null,
        industry_deleted: true,
        industries_list: action.payload
      };
    }
    case IndustriesStore.ActionTypes.DELETE_INDUSTRY_FAILED: {
      return {
        ...state,
        industry_delete_pending: false,
        industry_delete_error: action.payload,
        industry_deleted: false
      };
    }
    default:
      return state;
  }
}

export default industriesReducer;
