import React from 'react';

import { CardComponent } from '../../../shared';
import { PartnerFormComponent } from './';
import { PlacesListComponent } from '../../places/components';
import { AdminsListComponent } from '../../users/components';
import { PartnerDTO, PlaceDTO, UserDTO, PaginationDTO, IPackage } from '../../../../appRedux/types';
import { msg } from '../../../../utils';

interface IProps {
  config: {
    partner: {
      saved: PartnerDTO | null;
      pending: boolean;
      error: string | null;
      loaded: PartnerDTO | null;
      load_pending: boolean;
      load_error: string | null;
      add: any;
      get: (partnerId: string) => any;
      edit: (partner: PartnerDTO, forSAdmin?: boolean) => any;
      editPlaceStatusAction: (partnerId: string, place: any) => any;
    };
    places: {
      get: (
        partnerId: string,
        limit?: Number,
        skip?: Number,
        sort?: string,
        criteria?: { [key: string]: string }
      ) => any;
      list: PaginationDTO<PlaceDTO>;
      pending: boolean;
      error: string | null;
      redirectToEditSAdmin: (placeId: string) => any;
      deleteAsSAdmin: (placeId: string, partnerId: string) => any;
      redirectToAddSAdmin: (partnerId: string) => any;
      redirectToPlaceAdminsAction: (placeId: string, forSAdmin: boolean) => any;
      edit: (placeId: PlaceDTO) => any;
      place_save_pending: boolean;
      availablePackages: IPackage[];
      fetchAvailablePackages: () => any;
      changePlacePackage: (place: PlaceDTO) => any;
    };
    extras: {
      industries: any;
      alternative_address_flag: boolean | null;
      getFlagsForOnboarding: () => any;
    };
    admins: {
      list: Array<UserDTO>;
      error: string | null;
      list_pending: boolean;
      admin_save_pending: boolean;
      get: (partnerId: string) => any;
      redirectToEdit: (userId: string) => any;
      redirectToAdd: (partnerId: string) => any;
      removeAdmin: (partnerId: string, userId: string) => any;
      add: (email: string, partnerId: string) => any;
    };
  };
  match?: any;
  editMode: boolean;
}
interface IState {}

export default class PartnerEditComponent extends React.Component<IProps, IState> {
  componentDidMount() {
    if (this.props.editMode && this.props.match?.params.partnerId) {
      this.props.config.partner.get(this.props.match.params.partnerId);
      this.props.config.places.get(this.props.match.params.partnerId);
    }
    this.props.config.extras.getFlagsForOnboarding();
  }

  savePartnerHandler = (partner: PartnerDTO, place: PlaceDTO) => {
    const { config } = this.props;
    if (!this.props.editMode) {
      config.partner.add({ partner, place });
    } else {
      let dataToSend = {
        ...partner,
        industryId: partner.industry._id
      };
      delete partner.updatedAt;
      config.partner.edit(dataToSend);
    }
  };

  renderPartnerForm = () => {
    const { partner, extras } = this.props.config;
    const partnerData = this.props.editMode ? partner.loaded : new PartnerDTO();
    return (
      <CardComponent
        title={
          this.props.editMode
            ? msg('cardTitle.editPartner', 'Edit partner')
            : msg('cardTitle.addPartner', 'Add partner')
        }
        pending={partner.load_pending}
        error={!!partner.load_error}
        headerIcon={this.props.editMode ? 'edit' : 'group_add'}
        needsTitle={true}
      >
        <PartnerFormComponent
          partner={partnerData}
          save={this.savePartnerHandler}
          pending={partner.pending}
          addPlace={!this.props.editMode}
          extraData={extras}
          needsEditIndustry={true}
          alternative_address_flag={this.props.config.extras.alternative_address_flag}
        />
      </CardComponent>
    );
  };

  handleChangeStatus = (partnerId: any, placeId: any, status: any) => {
    this.props.config.partner.editPlaceStatusAction(partnerId, {
      _id: placeId,
      isActive: status.isActive
    });
  };

  renderPlacesList = () => {
    const { partner } = this.props.config;
    if (this.props.editMode && partner.loaded && !partner.load_pending && this.props.match) {
      return (
        <PlacesListComponent
          partnerId={partner.loaded._id}
          config={this.props.config.places}
          forSAdmin={true}
          handleChangeStatus={this.handleChangeStatus}
          match={this.props.match}
        />
      );
    }
    return null;
  };

  renderUsersList = () => {
    const { partner } = this.props.config;
    if (this.props.editMode && partner.loaded && !partner.load_pending) {
      return <AdminsListComponent partnerId={partner.loaded._id} config={this.props.config.admins} />;
    }
    return null;
  };
  render() {
    return (
      <div>
        {this.renderPartnerForm()}
        {this.renderPlacesList()}
        {this.renderUsersList()}
      </div>
    );
  }
}
