import runtimeEnv from '@mars/heroku-js-runtime-env';

import { PartnersStore, PlacesStore } from '../';
import { logger } from '../../utils';
import { AuthStore } from './';

const crypto = require('crypto-browserify');
const env = runtimeEnv();

/*
  authReducer = reducer for all actionTypes for Auth
*/
function authReducer(state: AuthStore.IState = AuthStore.initialState, action: IAction<any>): AuthStore.IState {
  switch (action.type) {
    case AuthStore.ActionTypes.LOGIN: {
      return {
        ...state,
        login_pending: true,
        login_error: null
      };
    }
    case AuthStore.ActionTypes.LOGIN_SUCCESS: {
      const nextState = {
        ...state,
        login_pending: false,
        login_error: null,
        user: action.payload.user,
        token: action.payload.token,
        refresh_token: action.payload.refreshToken
      };
      AuthStore.setLocalStorage(nextState);
      if (crypto && Tawk_API) {
        try {
          const hash = crypto
            .createHmac('sha256', process.env.REACT_APP_TAWKTO_API_KEY)
            .update(action.payload.user.email)
            .digest('hex');
          const { firstname, lastname, email } = action.payload.user;
          // tslint:disable-next-line: no-empty
          Tawk_API.setAttributes({ name: firstname + ' ' + lastname, email, hash }, () => {});
        } catch (error) {
          logger.err('TawkTo error', 'POST');
        }
      }
      PlacesStore.setLocalStorage('');
      return nextState;
    }
    case AuthStore.ActionTypes.LOGIN_FAILED: {
      return {
        ...state,
        login_pending: false,
        user: null,
        token: null,
        refresh_token: null,
        login_error: action.payload
      };
    }
    case AuthStore.ActionTypes.LOGOUT: {
      AuthStore.setLocalStorage(null);
      PlacesStore.setLocalStorage(null);
      PartnersStore.setLocalStorage(null);
      const storageOrigin = localStorage.getItem('origin') || env.REACT_APP_API_URL || '';
      return {
        user: null,
        get_access_pending: false,
        get_access_error: null,
        edit_user_pending: false,
        edit_user_error: null,
        token: null,
        refresh_token: null,
        login_error: null,
        login_pending: false,
        forgot_password_pending: false,
        forgot_password_error: null,
        reset_password_info: null,
        reset_password_pending: false,
        reset_password_error: null,
        activation_error: null,
        validate_email_pending: false,
        validate_email_error: null,
        unsubscribe_user_pending: false,
        unsubscribe_user_error: null,
        activity_group_by_checkin: false,
        events_list_expired: false,
        show_settlements_without_amount: false,
        get_access_token_pending: false,
        get_access_token_error: null,
        origin: storageOrigin
      };
    }
    case AuthStore.ActionTypes.FORGOT_PASSWORD: {
      return {
        ...state,
        forgot_password_pending: true,
        forgot_password_error: null
      };
    }
    case AuthStore.ActionTypes.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        forgot_password_pending: false,
        forgot_password_error: null
      };
    }
    case AuthStore.ActionTypes.FORGOT_PASSWORD_FAILED: {
      return {
        ...state,
        forgot_password_pending: false,
        forgot_password_error: action.payload
      };
    }
    case AuthStore.ActionTypes.RESET_PASSWORD: {
      return {
        ...state,
        reset_password_pending: true,
        reset_password_error: null
      };
    }
    case AuthStore.ActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        reset_password_pending: false,
        reset_password_error: null,
        reset_password_info: action.payload
      };
    }
    case AuthStore.ActionTypes.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        reset_password_pending: false,
        reset_password_info: null,
        reset_password_error: action.payload
      };
    }
    case AuthStore.ActionTypes.ACTIVATION_FAILED: {
      return {
        ...state,
        activation_error: action.payload
      };
    }
    case AuthStore.ActionTypes.EDIT_PROFILE: {
      return {
        ...state,
        edit_user_pending: true,
        edit_user_error: null
      };
    }
    case AuthStore.ActionTypes.EDIT_PROFILE_SUCCESS: {
      const currentState = state.user;
      const newUserData = action.payload;
      const editedUser = {
        ...currentState,
        email: newUserData.email,
        firstname: newUserData.firstname,
        lastname: newUserData.lastname,
        phone: newUserData.phone
      };
      const nextState = {
        ...state,
        user: editedUser,
        edit_user_pending: false,
        edit_user_error: null
      };
      AuthStore.setLocalStorage(nextState);
      return nextState;
    }
    case AuthStore.ActionTypes.EDIT_PROFILE_FAILED: {
      return {
        ...state,
        edit_user_pending: false,
        edit_user_error: action.payload
      };
    }
    case AuthStore.ActionTypes.VALIDATE_EMAIL: {
      return {
        ...state,
        validate_email_pending: true,
        validate_email_error: null
      };
    }
    case AuthStore.ActionTypes.VALIDATE_EMAIL_SUCCESS: {
      return {
        ...state,
        validate_email_pending: false,
        validate_email_error: null
      };
    }
    case AuthStore.ActionTypes.VALIDATE_EMAIL_FAILED: {
      return {
        ...state,
        validate_email_pending: false,
        validate_email_error: action.payload
      };
    }
    case AuthStore.ActionTypes.UNSUBSCRIBE_USER: {
      return {
        ...state,
        unsubscribe_user_pending: true,
        unsubscribe_user_error: null
      };
    }
    case AuthStore.ActionTypes.UNSUBSCRIBE_USER_SUCCESS: {
      return {
        ...state,
        unsubscribe_user_pending: false,
        unsubscribe_user_error: null
      };
    }
    case AuthStore.ActionTypes.UNSUBSCRIBE_USER_FAILED: {
      return {
        ...state,
        unsubscribe_user_pending: false,
        unsubscribe_user_error: action.payload
      };
    }
    case AuthStore.ActionTypes.GET_USER_ACCESS: {
      return {
        ...state,
        get_access_pending: true,
        get_access_error: null
      };
    }
    case AuthStore.ActionTypes.GET_USER_ACCESS_SUCCESS: {
      const currentUserState = state.user;
      const editedUser = {
        ...currentUserState,
        access: action.payload
      };
      const nextState = {
        ...state,
        user: editedUser,
        get_access_pending: false,
        get_access_error: null
      };
      AuthStore.setLocalStorage(nextState);
      return nextState;
    }
    case AuthStore.ActionTypes.GET_USER_ACCESS_FAILED: {
      return {
        ...state,
        get_access_pending: false,
        get_access_error: action.payload
      };
    }
    case AuthStore.ActionTypes.SET_GROUP_BY_CHECKIN: {
      const nextState = {
        ...state,
        activity_group_by_checkin: action.payload
      };
      AuthStore.setLocalStorage(nextState);
      return nextState;
    }
    case AuthStore.ActionTypes.SET_EVENTS_LIST_EXPIRED: {
      const nextState = {
        ...state,
        events_list_expired: action.payload
      };
      AuthStore.setLocalStorage(nextState);
      return nextState;
    }
    case AuthStore.ActionTypes.SET_SETTLEMENTS_WITHOUT_AMOUNT: {
      const nextState = {
        ...state,
        show_settlements_without_amount: action.payload
      };
      AuthStore.setLocalStorage(nextState);
      return nextState;
    }
    case AuthStore.ActionTypes.GET_ACCESS_TOKEN: {
      return {
        ...state,
        get_access_token_pending: true,
        get_access_token_error: null
      };
    }
    case AuthStore.ActionTypes.GET_ACCESS_TOKEN_SUCCESS: {
      const nextState = {
        ...state,
        get_access_token_pending: false,
        get_access_token_error: null,
        token: action.payload.token,
        refresh_token: action.payload.refreshToken
      };
      AuthStore.setLocalStorage(nextState);
      return nextState;
    }
    case AuthStore.ActionTypes.GET_ACCESS_TOKEN_FAILED: {
      return {
        ...state,
        get_access_token_pending: false,
        get_access_token_error: action.payload
      };
    }
    case AuthStore.ActionTypes.SET_ORIGIN: {
      const nextState = {
        ...state,
        origin: action.payload
      };
      localStorage.setItem('origin', action.payload);
      return nextState;
    }
    default:
      return state;
  }
}

export default authReducer;
