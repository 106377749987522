/*
  @function getRegularMedia => get photos that have type 'REGULAR' from an entity's media
    @accepts entity : object that contains the media array
    @returns Array of images
*/
export const getRegularMedia = (entity: any) => {
  return entity.media.filter((photo: any) => {
    return photo.type === 'REGULAR';
  });
};

/*
  @function parseImagesForGallery => parse information of images, necessary for gallery component
    @accepts media : array that contains images
    @returns Array of objects, containing necessary information about images
*/
export const parseImagesForGallery = (media: any) => {
  return media.map((img: any, index: number) => {
    return {
      id: img.id,
      original: img.url,
      thumbnail: img.url,
      index
    };
  });
};
