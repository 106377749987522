import paySettlementsActions, { IPaySettlementsActions } from './actions';
import paySettlementsReducer from './reducer';
import { PaginationDTO, PaySettlementDTO } from '../types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PaySettlementsStore {
  export type IState = {
    pay_settlements_list: PaginationDTO<PaySettlementDTO>;
    pay_settlements_list_pending: boolean;
    pay_settlements_list_error: string | null;
    download_pay_settlement_pending: boolean;
    download_pay_settlement_error: string | null;
  };

  export const initialState: IState = {
    pay_settlements_list: new PaginationDTO(),
    pay_settlements_list_pending: false,
    pay_settlements_list_error: null,
    download_pay_settlement_pending: false,
    download_pay_settlement_error: null
  };

  export enum ActionTypes {
    GET_PAY_SETTLEMENTS = 'GET_PAY_SETTLEMENTS',
    GET_PAY_SETTLEMENTS_SUCCESS = 'GET_PAY_SETTLEMENTS_SUCCESS',
    GET_PAY_SETTLEMENTS_FAILED = 'GET_PAY_SETTLEMENTS_FAILED',
    DOWNLOAD_PAY_SETTLEMENT = 'DOWNLOAD_PAY_SETTLEMENT',
    DOWNLOAD_PAY_SETTLEMENT_SUCCESS = 'DOWNLOAD_PAY_SETTLEMENT_SUCCESS',
    DOWNLOAD_PAY_SETTLEMENT_FAILED = 'DOWNLOAD_PAY_SETTLEMENT_FAILED',
    CLEAR_PAY_SETTLEMENTS_ERROR = 'CLEAR_PAY_SETTLEMENTS_ERROR'
  }

  export const actions = paySettlementsActions;
  export const reducer = paySettlementsReducer;
  export interface IActions extends IPaySettlementsActions {}
}

export * from './actions';
