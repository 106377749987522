export class StatisticsSuperAdminDTO {
  partners?: number = 0;
  members?: number = 0;
  checkins?: number = 0;
  rewards?: number = 0;
  events?: number = 0;
  autopilots?: number = 0;
  triggeres?: number = 0;
  industries?: number = 0;
}
