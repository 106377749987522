/* eslint-disable no-useless-computed-key */
import * as React from 'react';
import { Bounce } from 'react-activity';
import { Button, Modal } from 'react-bootstrap';

import { MenuItem, Select } from '@material-ui/core';

import { MemberDTO, PaginationDTO, TagDTO, UserTagDTO } from '../../../../appRedux/types';
import imagePlaceholder from '../../../../assets/images/galleryPlaceholder.jpg';
import { colors } from '../../../../assets/styles';
import { getFormatDate, getTagStylesForSelect, msg } from '../../../../utils';
import {
    ButtonComponent, CardComponent, ExportModalComponent, InfiniteScrollListComponent,
    MultipleSelectComponent
} from '../../../shared';

interface IProps {
  config: {
    getMembersForPlaceAction: (
      placeId: string,
      limit?: Number,
      skip?: Number,
      sort?: string,
      criteria?: { [key: string]: string }
    ) => {};
    members_list: PaginationDTO<any>;
    members_list_pending: boolean;
    members_list_error: string | null;
    getMemberAction: (memberId: string, placeId: string) => any;
    member: MemberDTO;
    member_pending: boolean;
    redirectToSeeActivity: (placeId: string, memberEmail: any) => any;
    changeMemberStatus: (forSAdmin: boolean, placeId: string, memberId: string, status: number, tags?: TagDTO[]) => any;
    clearPlaceMembersError: () => any;
    exportInExcel: boolean;
    export_members_pending: boolean;
    export_members_error: string | null;
    exportMembersInExcel: (placeId: string, startDate: number, endDate: number) => any;
    tags_list: PaginationDTO<TagDTO>;
    getTagsAction(forSAdmin: boolean, placeId?: string): any;
  };
  partnerId: string;
  match?: any;
}
interface IState {
  showMemberModal: boolean;
  showExportModal: boolean;
  member: MemberDTO;
  memberId: string;
}

export default class MembersForAPlaceComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showMemberModal: false,
      showExportModal: false,
      member: props.config.member,
      memberId: ''
    };
  }

  componentDidMount() {
    this.props.config.getTagsAction(false, this.props.match.params?.placeId);
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.config.member_pending && !this.props.config.member_pending && this.props.config.member) {
      this.setState({ member: this.props.config.member });
    }
  }

  componentWillUnmount() {
    this.props.config.clearPlaceMembersError();
  }

  getMembers = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    if (this.props.match) {
      this.props.config.getMembersForPlaceAction(this.props.match.params.placeId, limit, skip, sort, criteria);
    }
  };

  isData() {
    return (
      this.props.config.members_list &&
      this.props.config.members_list.results &&
      this.props.config.members_list.results.length
    );
  }

  parseMembers(list: PaginationDTO<any>): any {
    if (!this.isData()) {
      return [];
    }
    return {
      ...list,
      results: list.results.map(item => {
        let name = item && item.user && item.user.firstname;
        if (item && item.user && item.user.firstname && item.user.lastname) {
          name = item.user.lastname + ' ' + item.user.firstname;
        }
        return {
          _id: item._id,
          user: {
            lastname: name,
            phone: item && item.user.phone ? item.user.phone : 'N/A',
            email: item && item.user.email,
            referralsNo: item && item.user.referralsNo
          },
          created: getFormatDate(item.created, 'hour'),
          status:
            item.status === 0
              ? msg('memberTypes.inactive', 'Inactive')
              : item.status === 1
              ? msg('memberTypes.new', 'New')
              : item.status === 2
              ? msg('memberTypes.regular', 'Regular')
              : item.status === 3
              ? msg('memberTypes.vip', 'VIP')
              : item.status === 4
              ? msg('memberTypes.flagged', 'Flagged')
              : '',
          checkinsCount: item.checkinsCount,
          points: item.points
        };
      })
    };
  }

  changeMemberStatus = (event: any) => {
    const { member } = this.state;
    member.status = event.target.value;
    this.setState({ member });
  };

  changeMemberTags = (values: { label: string; value: string }[]) => {
    let memberTags: UserTagDTO[] = [];
    if (values) {
      values.forEach((item: { label: string; value: string }) => {
        const existingTag = this.props.config.member?.tags?.find(memberTag => memberTag._id === item.value);
        if (existingTag) {
          memberTags.push(existingTag);
        } else {
          const tag = this.props.config.tags_list.results?.find(fullTag => fullTag._id === item.value);
          if (tag) {
            memberTags.push({ name: tag.name, _id: tag._id + '', color: tag.color, priority: tag.priority });
          }
        }
      });
    }
    this.setState(prevState => ({ member: { ...prevState.member, tags: memberTags } }));
  };

  toggleMemberModal = () => {
    const show = this.state.showMemberModal;
    this.setState({
      showMemberModal: !show
    });
  };

  saveHandler = () => {
    const placeId = this.props.match && this.props.match.params.placeId;
    const member = this.state.member;
    this.props.config.changeMemberStatus(false, placeId, member._id, member.status, member.tags);
    this.toggleMemberModal();
  };

  seeActivityRedirect = (memberEmail: string) => {
    if (this.props.match && this.props.match.params.placeId) {
      this.props.config.redirectToSeeActivity(this.props.match.params.placeId, memberEmail);
    }
  };

  getActions() {
    return [
      {
        label: msg('members.seeAdditionalInfo', 'See additional information'),
        btn: 'btn-info',
        icon: 'info',
        onClick: (event: any) => {
          const memberId = event.currentTarget.getAttribute('id');
          const placeId = this.props.match && this.props.match.params.placeId;
          this.props.config.getMemberAction(memberId, placeId);
          this.setState({ memberId: memberId });
          this.toggleMemberModal();
        }
      },
      {
        label: msg('general.seeActivity', 'See Activity'),
        btn: 'btn-success',
        icon: 'assessment',
        returnFields: ['user'],
        onClick: (fields: any) => {
          const memberName = fields.user.lastname;
          this.seeActivityRedirect(memberName || '');
        }
      }
    ];
  }

  parseTagsForSelect = (tags: TagDTO[]) => {
    return tags?.map(tag => ({ label: tag.name, value: tag._id, color: tag.color }));
  };

  renderTags = () => {
    if (!this.props.config.tags_list?.results?.length) return null;
    return (
      <div style={{ display: 'flex', marginBottom: 15, padding: '0 20px' }}>
        <div>
          <h4>
            <strong>{msg('tags.memberTags', 'Member Tags')}: </strong>
          </h4>
        </div>
        <div className="col-sm-6 col-md-9">
          <MultipleSelectComponent
            label={msg('tags.userTags', 'Member Tags')}
            options={this.parseTagsForSelect(this.props.config.tags_list?.results || [])}
            id="tagsList"
            value={this.parseTagsForSelect(this.state.member?.tags || [])}
            onChange={this.changeMemberTags}
            selectStyle={getTagStylesForSelect()}
          />
        </div>
      </div>
    );
  };

  getModalMemberInfo() {
    return (
      <Modal show={true} onHide={this.toggleMemberModal} dialogClassName="memberDetailsModal">
        <Modal.Header>
          <Modal.Title>{msg('members.modalTitle', 'Member information')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.config.member_pending ? (
            <div style={{ textAlign: 'center', padding: 20 }}>
              <Bounce color={colors.lightGreen} />
            </div>
          ) : (
            <div>
              <div className="row">
                <div className="col-sm-3" />
                <div
                  className="col-sm-6"
                  style={{
                    marginBottom: 10,
                    display: 'flex',
                    width: '100%',
                    height: 200,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <img
                    src={
                      this.state.member?.user?.media?.length > 0
                        ? this.state.member?.user?.media[this.state.member?.user?.media?.length - 1]?.url
                        : imagePlaceholder
                    }
                    alt="placeholder"
                    style={{
                      maxHeight: 225,
                      height: '100%'
                    }}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row">
                <div className="col-sm-6" style={{ paddingLeft: 35 }}>
                  <h4>
                    <strong>{msg('members.name', 'Name')}: </strong>{' '}
                    {this.state.member ? this.state.member.user.firstname + ' ' + this.state.member.user.lastname : ''}
                  </h4>
                  <h4>
                    <strong>{msg('members.phone', 'Phone')}: </strong>{' '}
                    {this.state.member ? this.state.member.user.phone : ''}
                  </h4>
                  <h4>
                    <strong>{msg('members.email', 'Email')}: </strong>{' '}
                    {this.state.member ? this.state.member.user.email : ''}
                  </h4>
                  <div className="row">
                    <div className="col-sm-4">
                      <h4>
                        <strong>{msg('members.status', 'Status')}: </strong>{' '}
                      </h4>
                    </div>
                    <div className="col-sm-6">
                      <Select
                        onChange={this.changeMemberStatus}
                        value={!isNaN(this.state.member?.status) ? this.state.member?.status : ''}
                      >
                        <MenuItem value={0}>{msg('memberTypes.inactive', 'Inactive')}</MenuItem>
                        <MenuItem value={1}>{msg('memberTypes.new', 'New')}</MenuItem>
                        <MenuItem value={2}>{msg('memberTypes.regular', 'Regular')}</MenuItem>
                        <MenuItem value={3}>{msg('memberTypes.vip', 'VIP')}</MenuItem>
                        <MenuItem value={4}>{msg('memberTypes.flagged', 'Flagged')}</MenuItem>
                      </Select>
                    </div>
                    <div className="col-sm-2" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <h4>
                    <strong>{msg('members.since', 'Member since: ')}</strong>{' '}
                    {this.state.member ? getFormatDate(this.state.member.created, 'date') : ''}
                  </h4>
                  <h4>
                    <strong>{msg('members.coins', 'Coins')}: </strong>{' '}
                    {this.state.member ? this.state.member.points : ''}
                  </h4>
                  <h4>
                    <strong>{msg('members.checkinsCount', 'Check-ins count: ')}</strong>{' '}
                    {this.state.member ? this.state.member.checkinsCount : ''}
                  </h4>
                </div>
              </div>
              {this.renderTags()}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={this.toggleMemberModal}>
            {msg('general.close', 'Close')}
          </Button>
          <ButtonComponent
            styles={{ marginTop: 0 }}
            label={msg('general.save', 'Save')}
            icon="save"
            action={this.saveHandler}
          />
        </Modal.Footer>
      </Modal>
    );
  }

  toggleExportModal = () => {
    const show = this.state.showExportModal;
    this.setState({ showExportModal: !show });
  };

  exportInExcel = (startDate: number, endDate: number) => {
    const placeId = this.props.match?.params.placeId;
    this.props.config.exportMembersInExcel(placeId, startDate, endDate);
  };

  render() {
    const list = this.parseMembers(this.props.config.members_list);
    const exportModalConfig = {
      title: msg('exportExcel.exportExcelMembers', 'Export place members'),
      pending: this.props.config.export_members_pending,
      error: this.props.config.export_members_error,
      toggleModal: this.toggleExportModal,
      exportInExcel: this.exportInExcel
    };

    return (
      <CardComponent
        title={msg('sidebar.members', 'Members')}
        error={!!this.props.config.members_list_error}
        headerIcon="group"
        needsTitle={true}
        buttons={
          this.props.config.exportInExcel
            ? [
                {
                  label: msg('exportExcel.exportExcel', 'Export Excel'),
                  icon: 'file_download',
                  onClick: this.toggleExportModal
                }
              ]
            : undefined
        }
      >
        {this.state.showMemberModal && this.getModalMemberInfo()}
        {this.props.config.exportInExcel && this.state.showExportModal && (
          <ExportModalComponent config={exportModalConfig} />
        )}
        <InfiniteScrollListComponent
          fields={{
            created: msg('members.created', 'Created'),
            ['user.lastname']: msg('members.name', 'Name'),
            ['user.phone']: msg('members.phone', 'Phone'),
            ['user.email']: msg('members.email', 'Email'),
            status: msg('members.status', 'Status'),
            points: msg('members.coins', 'Coins'),
            checkinsCount: msg('members.checkins', 'Checkins'),
            ['user.referralsNo']: msg('members.referrals', 'Referrals')
          }}
          actions={window.innerWidth > 500 ? this.getActions() : []}
          list={list}
          pending={this.props.config.members_list_pending}
          error={this.props.config.members_list_error}
          get={this.getMembers}
          sort={{
            fields: ['user.lastname', 'user.email', 'points', 'created', 'checkinsCount', 'user.referralsNo'],
            default: 'created,-1'
          }}
          search={['user.lastname', 'user.firstname', 'user.phone', 'user.email']}
          filters={[
            {
              field: 'status',
              value: [
                {
                  _id: '',
                  name: msg('memberTypes.allMembers', 'All members')
                },
                {
                  _id: '0',
                  name: msg('memberTypes.inactiveMembers', 'Inactive members')
                },
                {
                  _id: '1',
                  name: msg('memberTypes.newMembers', 'New members')
                },
                {
                  _id: '2',
                  name: msg('memberTypes.regularMembers', 'Regular members')
                },
                {
                  _id: '3',
                  name: msg('memberTypes.vipMembers', 'VIP members')
                },
                {
                  _id: '4',
                  name: msg('memberTypes.flaggedMembers', 'Flagged members')
                }
              ]
            }
          ]}
        />
      </CardComponent>
    );
  }
}
