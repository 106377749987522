export const partnerRoutes = {
  ACTIVITY: {
    name: 'activity',
    path: '/partner/activity',
    default: '/partner/activity/:placeId'
  },
  AUTOPILOT: {
    name: 'autopilot',
    path: '/partner/autopilot',
    default: '/partner/autopilot/:placeId',
    subroutes: {
      ADD: { name: 'add', path: '/partner/autopilot/:placeId/add' },
      EDIT: { name: 'edit', path: '/partner/autopilot/:placeId/edit/:autopilotId' }
    }
  },
  DASHBOARD: {
    name: 'dashboard',
    path: '/partner/dashboard',
    default: '/partner/dashboard/:placeId'
  },
  EMAILS: {
    name: 'partnerEmails',
    path: '/partner/emails',
    subroutes: {
      ADD: {
        name: 'add',
        path: '/partner/emails/add'
      },
      EDIT: {
        name: 'edit',
        path: '/partner/emails/edit/:emailId'
      }
    }
  },
  EVENTS: {
    name: 'events',
    path: '/partner/events',
    default: '/partner/events/:placeId',
    subroutes: {
      ADD: {
        name: 'add',
        path: '/partner/events/:placeId/add'
      },
      EDIT: {
        name: 'edit',
        path: '/partner/events/:placeId/edit/:eventId'
      }
    }
  },
  FEEDBACK: {
    name: 'feedback',
    path: '/partner/feedback',
    default: '/partner/feedback/:placeId'
  },
  MEMBERS: {
    name: 'members',
    path: '/partner/members',
    default: '/partner/members/:placeId'
  },
  PLACE: {
    name: 'myPlace',
    path: '/partner/places/edit',
    default: '/partner/places/edit/:placeId'
  },
  PLACE_ADMINS: {
    name: 'placeAdmins',
    path: '/partner/places/admins',
    default: '/partner/places/admins/:placeId',
    subroutes: {
      ADD: { name: 'addAdmin', path: '/partner/places/admins/:placeId/add' }
    }
  },
  PROFILE: { name: 'editProfile', path: '/partner/profile/edit' },
  RECEIPTS: {
    name: 'receipts',
    path: '/partner/receipts',
    default: '/partner/receipts/:placeId'
  },
  REVIEWS: {
    name: 'reviews',
    path: '/partner/reviews',
    default: '/partner/reviews/:placeId/configs',
    subroutes: {
      ADD: { name: 'add', path: '/partner/reviews/:placeId/configs/add' },
      EDIT: {
        name: 'edit',
        path: '/partner/reviews/:placeId/configs/edit/:configId'
      },
      ANSWERS: {
        name: 'answers',
        path: '/partner/reviews/:placeId/configs/answers/:configId'
      }
    }
  },
  REWARDS: {
    name: 'rewards',
    path: '/partner/rewards',
    default: '/partner/rewards/:placeId'
  },
  SIMPLE_AUTOPILOTS: {
    name: 'simpleAutopilots',
    path: '/partner/simple-autopilots'
  },
  TAGS: {
    name: 'tags',
    path: '/partner/tags',
    default: '/partner/tags/:placeId'
  },
  TRANSACTIONS: {
    name: 'payments',
    path: '/partner/payments',
    default: '/partner/payments/:placeId'
  }
};

export const adminRoutes = {
  ACTIVITY: { name: 'activity', path: '/admin/activity' },
  AGENDA: { name: 'agenda', path: '/admin/agenda-jobs' },
  ANNOUNCEMENTS: {
    name: 'announcements',
    path: '/admin/announcements',
    subroutes: {
      ADD: { name: 'add', path: '/admin/announcements/add' },
      EDIT: { name: 'edit', path: '/admin/announcements/edit/:announcementId' }
    }
  },
  AUTOPILOT_LOGS: { name: 'autopilotLogs', path: '/admin/autopilot-logs' },
  BATCHES: {
    name: 'paymentSettlements',
    path: '/admin/pay-batches',
    subroutes: {
      SETTLEMENTS: { name: 'paymentSettlementsPlace', path: '/admin/pay-batches/:payBatchId/pay-settlements' }
    }
  },
  DASHBOARD: { name: 'dashboard', path: '/admin/dashboard' },
  EMAILS: {
    name: 'partnerEmails',
    path: '/admin/partner-emails',
    subroutes: {
      ADD: { name: 'add', path: '/admin/partner-emails/add' },
      EDIT: { name: 'edit', path: '/admin/partner-emails/edit/:emailId' }
    }
  },
  INDUSTRIES: {
    name: 'industries',
    path: '/admin/industries',
    subroutes: {
      ADD: { name: 'add', path: '/admin/industries/add' },
      EDIT: { name: 'edit', path: '/admin/industries/edit/:industryId' }
    }
  },
  ONBOARDINGS: {
    name: 'onboardings',
    path: '/admin/onboardings',
    subroutes: { VIEW: { name: 'details', path: '/admin/onboardings/view/:onboardingId' } }
  },
  PACKAGES: {
    name: 'packages',
    path: '/admin/packages',
    subroutes: {
      ADD: { name: 'add', path: '/admin/packages/create' },
      EDIT: { name: 'edit', path: '/admin/packages/edit/:packageId' }
    }
  },
  PARTNERS: {
    name: 'partners',
    path: '/admin/partners',
    subroutes: {
      ADD: { name: 'add', path: '/admin/partners/add' },
      EDIT: { name: 'edit', path: '/admin/partners/edit/:partnerId' }
    }
  },
  PLACES: {
    name: 'places',
    path: '/admin/places',
    subroutes: {
      ADD: { name: 'add', path: '/admin/places/add/:partnerId' },
      EDIT: {
        name: 'edit',
        path: '/admin/places/edit/:placeId',
        subroutes: {
          ADMINS: {
            name: 'placeAdmins',
            path: '/admin/places/edit/:placeId/admins',
            subroutes: {
              ADD: { name: 'addAdmin', path: '/admin/places/edit/:placeId/admins/add' }
            }
          }
        }
      }
    }
  },
  PROFILE: { name: 'editProfile', path: '/admin/profile/edit' },
  RECEIPTS: { name: 'receipts', path: '/admin/receipts' },
  REWARDS: { name: 'rewards', path: '/admin/rewards' },
  USERS: {
    name: 'users',
    path: '/admin/users',
    subroutes: {
      ADD: { name: 'add', path: '/admin/users/add/:partnerId' },
      EDIT: { name: 'edit', path: '/admin/users/edit/:userId' }
    }
  },
  TAGS: {
    name: 'tags',
    path: '/admin/tags'
  },
  TRANSACTIONS: {
    name: 'payments',
    path: '/admin/payments'
  },
  TRIGGERS: { name: 'triggers', path: '/admin/triggers' }
};

export const authRoutes = {
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_EMAIL: '/forgot-password/email-sent',
  RESET_PASSWORD: '/auth/reset-password/:token',
  PASSWORD_CHANGED: '/auth/password-changed/:type',
  ACTIVATION: '/auth/activation/:token',
  ACTIVATION_SENT: '/auth/email-sent',
  VALIDATE_EMAIL: '/validate-email/:token',
  UNSUBSCRIBE: '/unsubscribe',
  UNSUBSCRIBE_USER: '/unsubscribe/:userToken',
  UNSUBSCRIBE_PLACE: '/unsubscribe/:userToken/:placeToken',
  ONBOARD: '/onboard',
  ONBOARD_CREATED: '/onboard/:id',
  READ_EMAIL: '/emails/:emailId/read/:userId'
};
