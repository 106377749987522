import * as React from 'react';

import { CardComponent } from '../../../shared';
import {
  PlaceFormComponent,
  PlacePhotosComponent,
  PlaceSettingsComponent,
  PlaceFeatureFlagsComponent,
  PaymentsSettingsComponent
} from './forms';
import { PlaceDTO, PartnerDTO, IPackage, UserDTO } from '../../../../appRedux/types';
import { msg } from '../../../../utils';

interface IProps {
  config: {
    pending: boolean;
    place_save_error: string | null;
    loaded: PlaceDTO | null;
    load_pending: boolean;
    load_error: string | null;
    loadedPartner: PartnerDTO | null;
    alternative_address_flag: boolean | null;
    place_admins_list: Array<UserDTO>;
    add?: (place: PlaceDTO, partnerId: string) => any;
    get?: (placeId: string) => any;
    edit?: (place: PlaceDTO) => any;
    editForPartner?: (place: PlaceDTO) => any;
    getPartner?: (partnerId: string) => any;
    deleteMedia?: (placeId: string, mediaId: string) => any;
    availablePackages: IPackage[];
    fetchAvailablePackages: () => any;
    changePlacePackage: (place: PlaceDTO) => any;
    getFlagsForOnboarding: () => any;
    resetPinCode: (isSAdmin: boolean, placeId: string, newPinCode: string, oldPinCode?: string) => any;
    getAdminsForPlaceAction: (placeId: string, includePartner?: boolean) => any;
    redirectToPlaceAdminsAction: (placeId: string, forSAdmin: boolean) => any;
  };
  match?: any;
  partnerId: string;
  editMode: boolean;
  forSAdmin: boolean;
  handleChange?: any;
  output?: boolean;
  photos?: { url: string; handleUpload: (event: string, type: string) => any };
}
interface IState {}

export default class PlacesEditComponent extends React.Component<IProps, IState> {
  componentDidMount() {
    if (this.props.editMode && this.props.match?.params.placeId && this.props.config.get) {
      this.props.config.get(this.props.match.params.placeId);
    }
    if (!this.props.editMode && this.props.match?.params.partnerId && this.props.config.getPartner) {
      this.props.config.getPartner(this.props.match.params.partnerId);
    }
    if (!this.props.editMode) {
      this.props.config.getFlagsForOnboarding();
    }
  }

  savePlaceHandler = (place: PlaceDTO) => {
    const { config } = this.props;
    if (!this.props.editMode) {
      if (this.props.forSAdmin && this.props.config.loadedPartner && config.add) {
        config.add(place, this.props.config.loadedPartner._id);
      }
    } else {
      if (this.props.forSAdmin && config.edit) {
        config.edit(place);
      } else if (config.editForPartner) {
        config.editForPartner(place);
      }
    }
  };

  placeChangeHandler = (place: PlaceDTO) => {
    if (this.props.handleChange) {
      this.props.handleChange(place, 'step2');
    }
  };

  redirectToPlaceAdminsAction = () => {
    this.props.config.redirectToPlaceAdminsAction(this.props.match.params.placeId, this.props.forSAdmin);
  };

  renderPlaceForm = () => {
    const propsConfig = this.props.config;
    const placeData = this.props.editMode ? propsConfig.loaded : new PlaceDTO();
    if (this.props.config.alternative_address_flag && !this.props.editMode && placeData) {
      placeData.featureFlags = {
        alternativeAddress: this.props.config.alternative_address_flag
      };
    }
    return (
      <CardComponent
        title={this.props.editMode ? msg('cardTitle.editPlace', 'Edit place') : msg('cardTitle.addPlace', 'Add place')}
        pending={propsConfig.load_pending}
        error={!!propsConfig.load_error}
        headerIcon={this.props.editMode ? 'edit' : 'add_location'}
        needsTitle={true}
        buttons={
          this.props.editMode && this.props.forSAdmin
            ? [
                {
                  label: msg('cardTitle.placeAdmins', 'Place admins'),
                  icon: 'manage_accounts',
                  onClick: this.redirectToPlaceAdminsAction
                }
              ]
            : undefined
        }
      >
        <PlaceFormComponent
          place={placeData}
          save={this.savePlaceHandler}
          pending={propsConfig.pending}
          output={this.props.output}
          onChange={this.placeChangeHandler}
          forSAdmin={this.props.forSAdmin}
          availablePackages={this.props.config.availablePackages}
          fetchAvailablePackages={this.props.config.fetchAvailablePackages}
          changePlacePackage={this.props.config.changePlacePackage}
        />
      </CardComponent>
    );
  };

  renderPlacesPhotos = () => {
    const propsConfig = this.props.config;
    const placeData = propsConfig.loaded;
    if (
      (this.props.editMode && propsConfig.loaded && !propsConfig.load_pending && placeData) ||
      (this.props.output && this.props.photos && placeData)
    ) {
      return (
        <CardComponent
          title={msg('cardTitle.editPlacePhotos', 'Edit place photos')}
          pending={propsConfig.load_pending}
          error={!!propsConfig.load_error}
          headerIcon="photo_library"
          needsTitle={true}
        >
          <PlacePhotosComponent
            place={placeData}
            get={propsConfig.get}
            delete={propsConfig.deleteMedia}
            forSAdmin={this.props.forSAdmin}
            photos={this.props.photos}
          />
        </CardComponent>
      );
    }
    return null;
  };

  renderPlaceSettings = () => {
    const { loaded, load_pending } = this.props.config;
    const placeData = this.props.editMode && loaded ? loaded : new PlaceDTO();
    if (this.props.editMode && loaded && !load_pending && (loaded.featureFlags?.becomeMember || this.props.forSAdmin)) {
      return (
        <PlaceSettingsComponent
          placeId={loaded ? loaded._id : null}
          partnerId={loaded ? loaded.partnerId : null}
          forSAdmin={this.props.forSAdmin}
          qrHash={placeData.qrHash}
          integration={placeData.integration}
          externalApp={placeData.externalApp}
          settings={placeData.settings}
          save={this.savePlaceHandler}
          pending={load_pending}
          allowReceipts={loaded.allowReceipts && loaded.featureFlags.sendReceipts}
          featureFlags={loaded.featureFlags}
          resetPinCode={this.props.config.resetPinCode}
        />
      );
    }
    return null;
  };

  renderFeatureFlags = () => {
    const { loaded, load_pending, pending, place_save_error } = this.props.config;
    const placeData = this.props.editMode ? loaded : new PlaceDTO();
    if (this.props.editMode && loaded && !load_pending) {
      return (
        <PlaceFeatureFlagsComponent
          place={placeData}
          place_save_pending={pending}
          place_save_error={place_save_error}
          save={this.savePlaceHandler}
        />
      );
    }
    return null;
  };

  renderPaymentsSettings = () => {
    const { loaded, load_pending } = this.props.config;
    const placeData = this.props.editMode ? loaded : new PlaceDTO();
    if (this.props.editMode && loaded && !load_pending && (loaded.featureFlags?.transactions || this.props.forSAdmin)) {
      return (
        <PaymentsSettingsComponent
          forSAdmin={this.props.forSAdmin}
          place={placeData}
          place_admins_list={this.props.config.place_admins_list}
          getAdminsForPlaceAction={this.props.config.getAdminsForPlaceAction}
          save={this.savePlaceHandler}
        />
      );
    }
    return null;
  };

  render() {
    return (
      <div>
        {this.renderPlaceForm()}
        {this.renderPlacesPhotos()}
        {this.renderPlaceSettings()}
        {this.renderPaymentsSettings()}
        {this.props.forSAdmin && this.renderFeatureFlags()}
      </div>
    );
  }
}
