import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

import { msg, tlist } from '../../../../../utils';
import { SimpleSelectComponent } from '../../../../shared';
import { actionsTypeOptions } from '../../../../../appRedux/autopilot/AutopilotSelections';
import { GiveRewardAction, GivePointsAction, ChangeMemberStatusAction, SendMessageAction } from './auxilaries';
import { colors } from '../../../../../assets/styles';

function renderCondition(listItem: any, onCellChange: any, index: any, onClick: any, rewards: any, errors: any) {
  switch (listItem.type) {
    case 0:
      return (
        <GiveRewardAction
          listItem={listItem}
          onValueChange={onCellChange}
          index={index}
          onClick={onClick}
          rewards={rewards}
          errors={errors}
        />
      );
    case 1:
      return (
        <GivePointsAction
          listItem={listItem}
          onValueChange={onCellChange}
          index={index}
          onClick={onClick}
          errors={errors}
        />
      );
    case 2:
      return (
        <ChangeMemberStatusAction
          listItem={listItem}
          onValueChange={onCellChange}
          index={index}
          onClick={onClick}
          errors={errors}
        />
      );
    case 3:
      return <SendMessageAction listItem={listItem} onValueChange={onCellChange} index={index} onClick={onClick} />;
    default:
      return undefined;
  }
}

function createChannelsArray(array: any, value: any) {
  let intValue = parseInt(value, 10);
  if (!array.includes(intValue)) {
    array.push(intValue);
  } else {
    const index = array.indexOf(intValue);
    array.splice(index, 1);
  }
  return array;
}

function renderChannelCheckboxes(listItem: any, onValueChange: any, index: any) {
  return (
    <div
      className="row"
      style={{
        backgroundColor: index % 2 === 0 ? colors.white : colors.lightGray,
        marginRight: -35,
        marginLeft: -35
      }}
    >
      <div className="col-sm-3">
        <h5>{msg('autopilot.channelsLegend', 'Choose sending channel')}:</h5>
      </div>
      <div className="col-sm-6">
        <FormGroup row={true} style={{ display: 'flex', justifyContent: 'center' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={listItem.channels.indexOf(2) > -1}
                color="primary"
                onChange={(e: any) => {
                  const value = createChannelsArray(listItem.channels, e.target.value);
                  onValueChange(value, index, 'channels');
                }}
                value="2"
              />
            }
            label={msg('notificationChannels.email', 'Email')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={listItem.channels.indexOf(0) > -1}
                color="primary"
                onChange={(e: any) => {
                  const value = createChannelsArray(listItem.channels, e.target.value);
                  onValueChange(value, index, 'channels');
                }}
                value="0"
              />
            }
            label={msg('notificationChannels.sms', 'SMS')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={listItem.channels.indexOf(1) > -1}
                color="primary"
                onChange={(e: any) => {
                  const value = createChannelsArray(listItem.channels, e.target.value);
                  onValueChange(value, index, 'channels');
                }}
                value="1"
              />
            }
            label={msg('notificationChannels.notification', 'In-app notification')}
          />
        </FormGroup>
      </div>
    </div>
  );
}

export function AutopilotActionsForm({ listItem, onCellChange, index, onClick, rewards, errors }: any) {
  return (
    <div>
      <div
        className="row"
        style={{
          backgroundColor: index % 2 === 0 ? colors.white : colors.lightGray,
          paddingTop: 6,
          paddingRight: 20,
          paddingLeft: 20,
          marginRight: -35,
          marginLeft: -35
        }}
      >
        <div className="col-sm-3" style={{ marginRight: 10 }}>
          <SimpleSelectComponent
            label={msg('autopilot.actionType', 'Action type')}
            name="actionType"
            options={tlist(actionsTypeOptions)}
            id="actionType"
            value={listItem.type}
            onChange={(e: any) => onCellChange(e.target.value, index, 'type')}
            required={true}
            needsAllLabel={false}
            arrayOptions={false}
            formatError={errors[listItem._id]}
          />
        </div>
        {renderCondition(listItem, onCellChange, index, onClick, rewards, errors)}
        <button
          type="button"
          data-rel="tooltip"
          className={`btn btn-danger`}
          onClick={onClick}
          style={{ width: 27, padding: 5, height: 27, marginTop: 18, marginLeft: -13 }}
          title={msg('general.delete', 'Delete')}
          id={listItem._id}
        >
          <i className="material-icons">delete</i>
        </button>
      </div>
      {listItem.type === 3 && renderChannelCheckboxes(listItem, onCellChange, index)}
    </div>
  );
}
