import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AppStore, PackagesStore } from '../../../appRedux';
import { CardComponent } from '../../shared';
import { msg } from '../../../utils';
import { EditPackageForm } from './EditPackageForm';
import { IPackage } from '../../../appRedux/types';

interface ICreatePackageStateProps { }
interface ICreatePackageActionsProps {
  createPackageAction: (newPackage: IPackage) => void
}
type ICreatePackageProps = ICreatePackageStateProps & ICreatePackageActionsProps & RouteComponentProps<{ id: string }>;
export const CreatePackage = (props: ICreatePackageProps) => {
  const { createPackageAction } = props;

  const onSave = (newPackage: IPackage) => {
    createPackageAction(newPackage);
  };

  const packageDetails: any = {
    name: '',
    moments: {
      day: '',
      week: '',
      month: '',
    }
  };

  return (
    <CardComponent
      title={msg('cardTitle.createPackage', 'Create package')}
      headerIcon="card_giftcard"
      needsTitle={true}
    >
      {
        <EditPackageForm packageDetails={packageDetails} onSave={onSave} />
      }
    </CardComponent>
  );
};

const mapStateToProps = (state: AppStore.states): ICreatePackageStateProps => ({
});
const dispatchToProps: ICreatePackageActionsProps = {
  createPackageAction: PackagesStore.actions.createPackageAction
};

export const CreatePackageContainer = withRouter(connect(
  mapStateToProps,
  dispatchToProps
)(CreatePackage));
