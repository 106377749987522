import translationsActions, { ITranslationsActions } from './actions';
import translationsReducer from './reducer';
import { LANGUAGES } from './types';

/*
  TranslationsStore definition as a namespace that contains actions, reducers, actionTypes and translations state
*/
export namespace TranslationsStore {
  function getInitialState() {
    const translationsLocalStorage = localStorage.getItem('translations');
    if (translationsLocalStorage === null || translationsLocalStorage === undefined) {
      return {
        initial_language: LANGUAGES.RO,
        hasDoneInit: false
      };
    } else {
      return { ...JSON.parse(translationsLocalStorage), hasDoneInit: false };
    }
  }

  export const initialState: IState = getInitialState();

  export function setLocalStorage(state: IState | null) {
    if (state !== null) {
      localStorage.removeItem('translations');
      localStorage.setItem('translations', JSON.stringify(state));
    } else {
      localStorage.removeItem('translations');
    }
  }

  export type IState = {
    initial_language: string;
    hasDoneInit: boolean;
  };

  export enum ActionTypes {
    CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
    INIT = 'INIT',
    INIT_SUCCESS = 'INIT_SUCCESS',
    INIT_FAILED = 'INIT_FAILED'
  }

  export const actions = translationsActions;
  export const reducer = translationsReducer;
  export interface IActions extends ITranslationsActions {}
}

export * from './actions';
