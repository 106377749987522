import { TranslationsStore } from './';

/*
  translationsReducer = reducer for all actionTypes for Translations
*/
function translationsReducer(
  state: TranslationsStore.IState = TranslationsStore.initialState,
  action: IAction<any>
): TranslationsStore.IState {
  switch (action.type) {
    case TranslationsStore.ActionTypes.CHANGE_LANGUAGE: {
      const nextState = {
        ...state,
        initial_language: action.payload
      };
      return nextState;
    }
    case TranslationsStore.ActionTypes.INIT: {
      return state;
    }
    case TranslationsStore.ActionTypes.INIT_SUCCESS: {
      const nextState = {
        ...state,
        hasDoneInit: true
      };
      TranslationsStore.setLocalStorage(nextState);
      return nextState;
    }
    case TranslationsStore.ActionTypes.INIT_FAILED: {
      const nextState = {
        ...state,
        hasDoneInit: false
      };
      return nextState;
    }
    default:
      return state;
  }
}

export default translationsReducer;
