import moment from 'moment';
import * as React from 'react';

import { Hidden } from '@material-ui/core';

import { FeatureFlagsDTO, StatisticsSuperAdminDTO } from '../../../../appRedux/types';
import background from '../../../../assets/images/background.jpg';
import logoPlaceholder from '../../../../assets/images/logoPlaceholder.jpg';
import timeTreeLogo from '../../../../assets/images/timetreeLogo.png';
import { colors } from '../../../../assets/styles';
import { msg } from '../../../../utils';

interface IProps {
  config: {
    name: string;
    forPartner: boolean;
    created?: any;
    placeCity?: string;
    placeIndustry?: string;
    subtitle?: string;
    shortname: string;
    noOfPartners?: any;
    noOfUsers?: any;
    noOfIndustries?: any;
    logo?: any;
    cover?: any;
    statistics?: StatisticsSuperAdminDTO;
    webOnlyStatistics?: StatisticsSuperAdminDTO;
    featureFlags?: FeatureFlagsDTO;
  };
  forSAdmin: boolean;
}
interface IState {}

export default class DashboardHeader extends React.Component<IProps, IState> {
  renderSuperAdminStatistics = (data: StatisticsSuperAdminDTO, lastOne: boolean = false) => {
    const dataArray = Object.entries(data);
    const featureFlags = this.props.config.featureFlags;
    const forSAdmin = this.props.forSAdmin;
    const statistics = dataArray.map(([key, value], index) => {
      switch (key) {
        case 'autopilots':
          if (!forSAdmin && !featureFlags?.simpleAutopilots) {
            return null;
          }
          break;
        case 'events':
          if (!forSAdmin && !featureFlags?.moments) {
            return null;
          }
          break;
        case 'rewards':
          if (!forSAdmin && (!featureFlags?.checkin || !featureFlags?.useCoins)) {
            return null;
          }
          break;
        case 'checkins':
          if (!forSAdmin && !featureFlags?.checkin) {
            return null;
          }
          break;
        case 'paymentsNo':
          if (!forSAdmin && !featureFlags?.transactions) {
            return null;
          }
          break;
        default:
          if (!forSAdmin && !featureFlags?.becomeMember) {
            return null;
          }
          break;
      }
      return (
        <div
          key={index}
          style={{
            paddingRight: 10,
            paddingLeft: 10,
            borderLeft: lastOne ? '1px solid white' : '0px',
            borderRight: !lastOne && index !== dataArray.length - 1 ? '1px solid white' : '0px',
            display: 'inline-block'
          }}
        >
          <h4 style={{ color: colors.white, filter: 'brightness(1)', marginBottom: 0, marginTop: 0 }}>{value}</h4>
          <h6
            style={{
              color: colors.white,
              filter: 'brightness(0.9)',
              marginTop: 0,
              marginBottom: 0,
              textTransform: 'capitalize'
            }}
          >
            {msg(`dashboardHeader.${key}`, 'Partners registered')}
          </h6>
        </div>
      );
    });
    return statistics;
  };

  render() {
    return (
      <div style={{ minHeight: 275, marginTop: this.props.forSAdmin ? 0 : -20, marginBottom: -15 }}>
        <div
          className="social-header"
          style={{
            minHeight: 275,
            background:
              !this.props.forSAdmin && this.props.config.cover && this.props.config.cover.url
                ? `url(${encodeURI(this.props.config.cover.url)}), url(${background})`
                : `url(${background})`,
            position: 'relative',
            marginBottom: 25,
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
            gridTemplateRows: '2fr 1fr'
          }}
        >
          <div
            className="social-cover"
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundColor: 'rgba(0,0,0,0.7)'
            }}
          />

          <Hidden smDown={true}>
            <div
              className="social-desc"
              style={{
                gridColumn: 1,
                gridRow: 1
              }}
            >
              <div className="col-sm-8" style={{ position: 'relative', left: 60, top: 60 }}>
                <h2 style={{ color: colors.white, filter: 'brightness(1)', marginBottom: 0, paddingBottom: 0 }}>
                  {this.props.config.name}
                </h2>
                <h5 style={{ color: colors.white, filter: 'brightness(0.9)', marginTop: 0, paddingTop: 0 }}>
                  {this.props.forSAdmin
                    ? this.props.config.subtitle
                    : this.props.config.forPartner
                    ? msg('dashboardHeader.partnerSubtitle', 'ReGage Partner since')
                    : msg('dashboardHeader.placeSubtitle', `${process.env.REACT_APP_NAME} Place since`, {
                        name: process.env.REACT_APP_NAME || ''
                      })}
                  {this.props.config.created ? moment(this.props.config.created).format('D MMMM YYYY') : null}
                </h5>
              </div>
            </div>
          </Hidden>
          <div
            className="social-stats"
            style={{
              gridColumn: 1,
              gridRow: 2,
              alignSelf: 'center'
            }}
          >
            {this.props.config.statistics && (
              <div
                className="text-align-mobile"
                style={{
                  marginBottom: 20,
                  fontSize: 12,
                  position: 'relative',
                  left: 70,
                  top: 15
                }}
              >
                {this.renderSuperAdminStatistics(this.props.config.statistics)}
                {!this.props.forSAdmin && this.props.config.webOnlyStatistics && (
                  <Hidden smDown={true}>
                    {this.renderSuperAdminStatistics(this.props.config.webOnlyStatistics, true)}
                  </Hidden>
                )}
              </div>
            )}
          </div>
          <div
            className="social-avatar"
            style={{
              placeSelf: 'center',
              gridColumn: 2,
              gridRow: '1 / span 2',
              color: colors.white,
              marginTop: 20,
              zIndex: 10
            }}
          >
            <div
              className="social-header-logo"
              style={{
                height: 110,
                width: 110,
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: 100,
                border: '2px solid #fff',
                background:
                  !this.props.forSAdmin && this.props.config.logo && this.props.config.logo.url
                    ? `url(${encodeURI(this.props.config.logo.url)}), #eee`
                    : this.props.forSAdmin
                    ? `url(${timeTreeLogo})`
                    : `url(${logoPlaceholder})`
              }}
            />
            <h4 className="text-center">@{this.props.config.shortname}</h4>
            {!this.props.forSAdmin && !this.props.config.forPartner && (
              <h6 className="text-center" style={{ opacity: 0.8, textTransform: 'capitalize', marginTop: -5 }}>
                {this.props.config.placeIndustry} in {this.props.config.placeCity}
              </h6>
            )}
          </div>
        </div>
      </div>
    );
  }
}
