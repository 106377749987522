import * as React from 'react';
import Toggle from 'react-toggle';
import { colors } from '../../assets/styles';

import { msg } from '../../utils';

interface IProps {
  data: {
    fields: any;
    list: Array<any>;
    badges?: any;
    actions?: Array<{
      label: any;
      onClick: (event: any) => any;
      icon: string;
      btn: string;
      id?: string;
    }>;
    pending: Boolean;
    error: string | null;
    forAutopilot?: boolean;
    forPartnerAutopilot?: boolean;
    idforChangeStatus?: string;
    auxilaryId?: string;
    handleChangeStatus?: (fields: any) => void;
  };
  disabled?: string;
  compareIds?: string[];
  disabledIds?: string[];
}

interface IState {}
export default class ListComponent extends React.Component<IProps, IState> {
  fields: Array<any> = [];
  badges: Array<any> = [];
  actions: Array<any>;

  constructor(props: IProps) {
    super(props);
    const { fields, badges, actions } = this.props.data;
    Object.keys(fields).forEach(key => {
      this.fields.push({
        key: key,
        value: fields[key]
      });
    });
    // tslint:disable-next-line: no-unused-expression
    badges &&
      Object.keys(badges).forEach(key => {
        this.badges.push({
          key: key,
          value: badges[key]
        });
      });
    this.actions = actions || [];
  }

  componentDidUpdate(prevProps: any) {
    if (this.props && this.props !== prevProps) {
      this.fields = [];
      this.actions = [];
      this.badges = [];
      Object.keys(this.props.data.fields).forEach(key => {
        this.fields.push({
          key: key,
          value: this.props.data.fields[key]
        });
      });
      // tslint:disable-next-line: no-unused-expression
      this.props.data.badges &&
        Object.keys(this.props.data.badges).forEach(key => {
          this.badges.push({
            key: key,
            value: this.props.data.badges[key]
          });
        });
      this.actions = this.props.data.actions || [];
    }
  }

  getHeader() {
    return (
      <tr>
        {this.fields.map(item => {
          return <th key={item.key}>{item.value}</th>;
        })}
        {this.badges?.map(item => {
          return <th key={item.key}>{item.value}</th>;
        })}
        {this.actions.length ? <th style={{ width: 120 }}>{msg('general.actions', 'Actions')}</th> : null}
      </tr>
    );
  }

  getBody() {
    return this.props.data.list.map((listItem, key) => {
      return (
        <tr key={key}>
          {this.fields.map((fieldItem, i) => {
            if ((this.props.data.forAutopilot && i === 1) || (this.props.data.forPartnerAutopilot && i === 5)) {
              return (
                <td key={i}>
                  <Toggle
                    checked={listItem[fieldItem.key]}
                    disabled={this.props.disabled === 'all' ? true : false}
                    onChange={(event: any) => {
                      let status = event.target.checked;
                      let dataToSend = {};
                      if (this.props.data.handleChangeStatus) {
                        if (this.props.data.idforChangeStatus && this.props.data.auxilaryId) {
                          dataToSend = {
                            ...listItem,
                            isActive: status,
                            placeId: this.props.data.idforChangeStatus,
                            partnerId: this.props.data.auxilaryId
                          };
                        } else if (this.props.data.idforChangeStatus) {
                          dataToSend = {
                            isActive: status,
                            _id: listItem._id,
                            partnerId: this.props.data.idforChangeStatus
                          };
                        } else {
                          dataToSend = {
                            ...listItem,
                            isActive: status
                          };
                        }
                        this.props.data.handleChangeStatus(dataToSend);
                      }
                    }}
                  />
                </td>
              );
            } else if (typeof listItem[fieldItem.key] === 'object' && listItem[fieldItem.key].type === 'custom') {
              return <td key={i}>{listItem[fieldItem.key].render()}</td>;
            } else {
              return <td key={i}>{listItem[fieldItem.key] && listItem[fieldItem.key].toString()}</td>;
            }
          })}
          {this.badges?.map((fieldItem, i) => {
            return (
              <td key={i}>
                <div
                  className="sentToBadgeValue"
                  style={{ backgroundColor: listItem[fieldItem.key] ? colors.green : colors.red }}
                >
                  {listItem[fieldItem.key] ? 'Yes' : 'No'}
                </div>
              </td>
            );
          })}
          {this.actions.length ? (
            <td className="td-actions text-left">
              {this.actions.map((action, aKey) => {
                let disable = false;
                const label = msg('general.delete', 'Delete');
                switch (this.props.disabled) {
                  case '1': {
                    disable = this.props.data.list.length === 1 && action.label === label ? true : false;
                    break;
                  }
                  case '2': {
                    disable = this.props.compareIds && this.props.compareIds.includes(listItem._id) ? false : true;
                    break;
                  }
                  case 'all': {
                    disable = true;
                    break;
                  }
                  default:
                    disable = false;
                }

                if (this.props.disabledIds) {
                  disable = this.props.disabledIds.indexOf(listItem._id) !== -1;
                }

                return (
                  <button
                    key={aKey}
                    type="button"
                    data-rel="tooltip"
                    className={`btn ${action.btn}`}
                    onClick={action.onClick}
                    title={action.label}
                    id={listItem._id}
                    disabled={disable}
                  >
                    <i className="material-icons">{action.icon}</i>
                  </button>
                );
              })}
            </td>
          ) : null}
        </tr>
      );
    });
  }

  render() {
    const isData: Boolean = !!(
      this.fields &&
      this.fields.length &&
      this.props.data &&
      this.props.data.list &&
      this.props.data.list.length
    );
    return (
      <div className="table-responsive">
        {isData ? (
          <table className="table table-hover">
            <thead className="text-primary">{this.getHeader()}</thead>
            <tbody>{this.getBody()}</tbody>
          </table>
        ) : (
          <h3>{msg('general.noAvailableData', 'There is no available data.')}</h3>
        )}
      </div>
    );
  }
}
