import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/*
  @function reduxContainer => function that connects component to Redux store
    @accepts WrappedComponent : React component to be connected to Redux
             mapStateToProps : function that maps Redux state to component props
             dispatchToProps : function that maps Redux dispatch actions to component props
    @returns React Component wrapped with Redux store
*/
export function reduxContainer(
  WrappedComponent: React.ComponentClass<any>,
  mapStateToProps: any,
  dispatchToProps: any
) {
  function mapDispatchToProps(dispatch: Dispatch<any>) {
    return bindActionCreators(dispatchToProps, dispatch);
  }

  return withRouter(connect<{}, {}, {}>(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedComponent) as any);
}
