import JoditEditor from 'jodit-react';
import { IJodit } from 'jodit/types/types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Server } from '../../utils';

interface IAPIMediaUploadResponse {
  id: string;
  type: string;
  url: string;
  createdAt: string;
  mimetype: string;
}

interface IJoditConfig {
  mediaUrl: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

interface IProps {
  content: string;
  config: IJoditConfig;
  onChange: (value: string) => void;
}

const getEditorWidth = (element: any) => {
  const cs = getComputedStyle(element);
  const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
  const borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
  const width = element.offsetWidth - paddingX - borderX;
  return width;
};

const transformImgSizeRelative = (originalContent: string, parentWidth: number) => {
  const widthPattern = new RegExp('width:\\W(\\d+)px;', 'gmi');
  const heightPattern = new RegExp('height:\\W(\\d+)px;', 'gmi');
  const newContent = originalContent
    .replace(widthPattern, (_, rawWidth) => {
      const imageWidth = parseInt(rawWidth, 10);
      const newWidth = Math.min(Math.round((imageWidth / parentWidth) * 100), 100);
      return `width: ${newWidth}%;`;
    })
    .replace(heightPattern, '');
  return newContent;
};

const JoditComponent = ({ content, config, onChange }: IProps) => {
  const [initialContent, setInitialContent] = useState(content);
  const tokenTimeout = useRef<any>(null);
  const editor = useRef<IJodit | null>(null);
  const editorWidth = useRef<number>(0);

  const getConfig = ({ mediaUrl, style, disabled }: IJoditConfig) => {
    const isImage = (mimetype: string): boolean => !!mimetype.match(/^image/);
    const requestMetadata = getMetadata(mediaUrl);

    return {
      readonly: false,
      toolbarAdaptive: false,
      buttons: [
        'source',
        '|',
        'bold',
        'italic',
        '|',
        'ul',
        'ol',
        '|',
        'brush',
        'paragraph',
        '|',
        'image',
        'link',
        '|',
        'align',
        '|',
        'undo',
        'redo',
        '|'
      ],
      colorPickerDefaultTab: 'color' as 'color',
      link: {
        openInNewTabCheckbox: false,
        noFollowCheckbox: false
      },
      toolbarInlineDisableFor: ['img', 'a'],
      imageDefaultWidth: 600,
      resizer: {
        forImageChangeAttributes: false
      },
      enableDragAndDropFileToEditor: true,
      uploader: {
        url: requestMetadata.baseURL,
        headers: requestMetadata.headers,
        withCredentials: requestMetadata.withCredentials,
        isSuccess(response: IAPIMediaUploadResponse): boolean {
          return !!response[0].url;
        },
        process(response: IAPIMediaUploadResponse): any {
          const files = [response[0]];
          return {
            baseurl: '',
            files: files.map(f => f.url),
            isImages: files.map(f => isImage(f.mimetype))
          };
        },
        prepareData(formdata: FormData): any {
          // @TODO: find a better way to specify to Jodit the field name of the file
          // as a workaround, we manually move the file to the desired form field name
          formdata.append('file', formdata.get('files[0]') || '');
          formdata.delete('files[0]');
        }
      },
      events: {
        afterInit: (instance: IJodit) => {
          editor.current = instance;
        }
      },
      style,
      disabled
    };
  };

  const changeHandler = (newContent: string) => {
    if (onChange) {
      const parsedContent = transformImgSizeRelative(newContent, editorWidth.current);
      onChange(parsedContent);
    }
  };

  useEffect(() => {
    if (editor.current) {
      let _joditWorkspace: any = document.querySelector('.jodit-wysiwyg');
      editorWidth.current = getEditorWidth(_joditWorkspace);
      editor.current.events.on('resize', () => {
        editorWidth.current = getEditorWidth(_joditWorkspace);
      });
    }
    getTokenHandler();
    return () => {
      if (editor.current && tokenTimeout.current) {
        clearTimeout(tokenTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (content && !initialContent) {
      setInitialContent(content);
    }
  }, [content]);

  const getTokenHandler = async () => {
    const token = await Server.getAccessToken();
    if (editor.current) {
      editor.current.uploader.options.headers = {
        Authorization: `Bearer ${token}`
      };
    }
    const decodedToken = Server.decodeJwtToken(token + '');
    const interval = decodedToken.exp * 1000 - Date.now() - 2000;
    tokenTimeout.current = setTimeout(getTokenHandler, interval);
  };

  const getMetadata = (mediaUrl: string) => {
    const requestMetadata = Server.getMetadata('post', mediaUrl, {}, true);
    // ContentType header needs to be undefined in order for the browser
    // to set it correctly (to include the multipart/form-data boundary)
    delete requestMetadata.headers?.['Content-Type'];
    return requestMetadata;
  };

  const joditConfig = useMemo(() => getConfig({ ...config }), []);

  return (
    <JoditEditor value={initialContent} config={joditConfig} onChange={changeHandler} onBlur={setInitialContent} />
  );
};

export default JoditComponent;
