import * as _ from 'lodash';
import React from 'react';

import { IndustryDTO } from '../../../../../appRedux/types';
import { IValidator, msg, Validator } from '../../../../../utils';
import { ButtonComponent, TextFieldComponent } from '../../../../shared';
import { IndustryPhotoComponent } from './';

interface IProps {
  industry?: IndustryDTO | null;
  save?: (industry: any) => void;
  pending: boolean;
  output?: boolean;
  error: string | null;
  onChange?: (industry: any) => void;
  editable?: boolean;
  getIndustry: (industryId: string) => any;
}
interface IState {
  industry: IndustryDTO;
  placeTypes: Array<any>;
  isDirty: boolean;
}

class IndustryFormComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      industry: props.industry || new IndustryDTO(),
      placeTypes: (props.industry && props.industry.placeTypes) || [{ _id: new Date().valueOf(), name: '' }],
      isDirty: false
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.industry && !_.isEqual(prevProps.industry, this.props.industry)) {
      this.setState({ industry: this.props.industry, placeTypes: this.props.industry.placeTypes });
    }
  }

  saveHandler = () => {
    let { industry, placeTypes } = this.state;
    const { save, validator } = this.props;
    const { isValid } = validator!;
    if (isValid && isValid() && save) {
      placeTypes = placeTypes.map((placeType: any) => {
        return {
          name: placeType.name
        };
      });
      const sendData = {
        name: industry.name,
        placeTypes: placeTypes,
        _id: this.props.editable ? industry._id : null
      };
      save(sendData);
    }
  };

  handleChange = (event: any) => {
    const fieldKey = event.target.getAttribute('id');
    const fieldValue = event.target.value;
    const industry = this.state.industry;
    const { isDirty } = this.props.validator!;

    if (isDirty) {
      isDirty(fieldKey);
    }
    industry[fieldKey] = fieldValue;
    this.setState({ industry, isDirty: true });
    if (this.props.output && this.props.onChange) {
      this.props.onChange(industry);
    }
  };

  handlePlaceTypeChange = (value: any, index: any, dataType: any) => {
    const placeTypesNewState = this.state.placeTypes.map((item: any, i: any) => {
      if (i === index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    this.setState({ placeTypes: placeTypesNewState });
  };

  addPlaceTypeAction = () => {
    const placeTypes = this.state.placeTypes;
    let newPlaceType = {
      _id: new Date().valueOf(),
      name: ''
    };
    placeTypes.push(newPlaceType);
    this.setState({ placeTypes });
  };

  deletePlaceTypeAction = async (event: any) => {
    const placeTypes = this.state.placeTypes;
    const id = event.currentTarget.getAttribute('id');
    placeTypes.forEach((item: any, index: any) => {
      if (item._id.toString() === id) {
        placeTypes.splice(index, 1);
      }
    });
    this.setState({ placeTypes });
  };

  render() {
    const { industry } = this.state;
    const { errors } = this.props.validator!;

    return (
      <div>
        <div className="row">
          <div className="col-sm-8">
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-5">
                <TextFieldComponent
                  label={msg('industry.name', 'Industry Name')}
                  id="name"
                  value={industry.name}
                  onChange={this.handleChange}
                  inType="text"
                  minLength={1}
                  maxLength={40}
                  formatError={errors.name}
                  required={true}
                  ref="name"
                  validator={[
                    {
                      type: 'checkIfAlpha',
                      params: industry.name,
                      msg: msg('formValidation.alphaCharacters', 'Use only letters!')
                    }
                  ]}
                />
              </div>
              <div className="col-sm-4" />
            </div>
            <div className="row">
              <legend style={{ marginTop: 10, marginBottom: 10, marginLeft: 15 }}>
                {msg('industry.subindustryLegend', 'Choose subindustries for this industry:')}
              </legend>
            </div>
            {this.state.placeTypes.map((listItem, key) => (
              <div className="row" key={key}>
                <div className="col-sm-1" />
                <div className="col-sm-5" style={{ marginRight: 10 }}>
                  <TextFieldComponent
                    label={msg('industry.placeTypeName', 'Subindustry Name')}
                    id={`subIndustryName${key}`}
                    value={listItem.name}
                    onChange={(e: any) => this.handlePlaceTypeChange(e.target.value, key, 'name')}
                    inType="text"
                    minLength={1}
                    maxLength={40}
                    formatError={errors[`subIndustryName${key}`]}
                    required={true}
                    ref={`subIndustryName${key}`}
                    validator={[
                      {
                        type: 'checkIfAlpha',
                        params: listItem.name,
                        msg: msg('formValidation.alphaCharacters', 'Use only letters!')
                      }
                    ]}
                  />
                </div>
                <button
                  type="button"
                  data-rel="tooltip"
                  className={`btn btn-danger`}
                  onClick={this.deletePlaceTypeAction}
                  style={{ width: 27, padding: 5, height: 27, marginTop: 18, marginLeft: -13 }}
                  title={msg('general.delete', 'Delete')}
                  id={listItem._id}
                >
                  <i className="material-icons">delete</i>
                </button>
              </div>
            ))}
            <div className="row">
              <div className="col-md-6">
                <div className="form-group form-button">
                  <ButtonComponent
                    label={msg('industry.addNewPlaceType', 'Add new subindustry')}
                    icon="library_add"
                    action={this.addPlaceTypeAction}
                    pending={this.props.pending}
                  />
                </div>
              </div>
            </div>
          </div>
          {this.props.editable && (
            <div className="col-sm-4">
              <IndustryPhotoComponent industry={industry} get={this.props.getIndustry} />
            </div>
          )}
        </div>
        {!this.props.output && (
          <div>
            <div className="row" style={{ margin: 10 }}>
              <div className="col-md-3" />
              <div className="col-md-6" style={{ textAlign: 'center' }}>
                <div className="form-group form-button">
                  <ButtonComponent
                    label={msg('general.save', 'Save')}
                    icon="save"
                    action={this.saveHandler}
                    pending={this.props.pending}
                  />
                </div>
              </div>
              <div className="col-md-3" />
            </div>
            <div className="row">
              <div className="col-md-6 category form-category">
                <small>*</small>
                {msg('errors.fieldsRequired', 'Required fields')}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Validator(IndustryFormComponent);
