import React from 'react';

import { AdvancedListComponent, CardComponent } from '../../../shared';
import { getFormatDate, msg } from '../../../../utils';
import { FeatureFlagsDTO, PaginationDTO, PlaceDTO, PlaceStatisticsDTO } from '../../../../appRedux/types';
import { colors } from '../../../../assets/styles';

interface IProps {
  config: {
    list: PaginationDTO<PlaceStatisticsDTO>;
    pending: boolean;
    error: string | null;
    get: (limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }) => any;
    redirect: (placeId: string) => any;
  };
}
interface IState {}

const featureFlags = Object.keys(new FeatureFlagsDTO());

export default class AdminDashboardStatistic extends React.Component<IProps, IState> {
  getStatistics = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    this.props.config.get(limit, skip, sort, criteria);
  };

  getEncodedFeatureFlags = (item: PlaceDTO) => {
    return featureFlags.map((label: string) => (item.featureFlags[label] ? '1' : '0')).join('  ');
  };

  parseStatistics = (list: PaginationDTO<PlaceStatisticsDTO>): any => {
    const results: any[] = [];
    if (list.results) {
      list.results.forEach(item => {
        results.push({
          ...item,
          name: item.place.name,
          _id: item.place._id,
          isActive: {
            type: 'custom',
            render: () => {
              const value = item.place.isActive;
              const backgroundColor = value ? colors.green : colors.red;
              return (
                <div className="badge" style={{ backgroundColor }}>
                  {value ? 'YES' : 'NO'}
                </div>
              );
            }
          },
          created: getFormatDate(item.place?.created, 'hour'),
          settings: {
            memberRegistration: item.place.settings?.memberRegistration,
            pointsForCheckin: item.place.settings?.pointsForCheckin,
            convertAmountRule: item.place.settings?.convertAmountRule
              ? `${item.place.settings?.convertAmountRule}%`
              : undefined
          },
          featureFlags: this.getEncodedFeatureFlags(item.place),
          uniqueNo: item.place.uniqueNo
        });
      });
    }
    return { ...list, results };
  };

  redirectToEditPlaceAction = (event: any) => {
    const placeId = event.currentTarget.getAttribute('id');
    this.props.config.redirect(placeId);
  };

  getActions = () => {
    return [
      {
        label: msg('general.edit', 'Edit'),
        btn: 'btn-success',
        icon: 'change_history',
        onClick: this.redirectToEditPlaceAction
      }
    ];
  };

  render() {
    const statistics = this.parseStatistics(this.props.config.list);
    return (
      <CardComponent
        title={msg('sidebar.places', 'Places')}
        error={!!this.props.config.error}
        headerIcon="location_city"
        needsTitle={true}
      >
        <AdvancedListComponent
          fields={{
            uniqueNo: msg('place.uniqueNo', 'Number'),
            created: msg('members.created', 'Created'),
            name: msg('place.name', 'Name'),
            featureFlags: msg('place.featureFlags', 'Feature flags'),
            'settings.memberRegistration': msg('place.memberRegistration', 'Member'),
            'settings.pointsForCheckin': msg('place.pointsForCheckin', 'Check-in'),
            'settings.convertAmountRule': msg('place.convertAmountRule', 'Convert'),
            packageName: msg('place.packageName', 'Package'),
            isActive: msg('userTypes.active', 'Active'),
            members: msg('sidebar.members', 'Members'),
            checkins: msg('sidebar.checkins', 'Check-ins'),
            rewards: msg('sidebar.rewards', 'Rewards'),
            events: msg('sidebar.events', 'Events'),
            autopilots: msg('place.autopilot', 'Autopilot'),
            triggeres: msg('dashboardHeader.triggeres', 'Triggers')
          }}
          list={statistics}
          sort={{
            fields: [
              'name',
              'created',
              'uniqueNo',
              'members',
              'isActive',
              'settings.memberRegistration',
              'settings.pointsForCheckin',
              'settings.convertAmountRule'
            ],
            default: 'created,-1'
          }}
          pending={this.props.config.pending}
          error={this.props.config.error}
          get={this.getStatistics}
          limit={30}
          search={['name']}
          actions={this.getActions()}
        />
      </CardComponent>
    );
  }
}
