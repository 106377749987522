import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { AppStore } from '../../../appRedux';
import { AgendaJobsStore } from '../../../appRedux/agenda-jobs';
import { AgendaJobDTO, PaginationDTO } from '../../../appRedux/types';
import { getFormatDate, msg } from '../../../utils';
import { CardComponent, InfiniteScrollListComponent } from '../../shared';

interface IProps {
  agenda_jobs_list: PaginationDTO<AgendaJobDTO>;
  agenda_jobs_list_pending: boolean;
  agenda_jobs_list_error: string | null;
  getAgendaJobsAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }): any;
  clearAgendaJobsError(): any;
}

const AgendaJobsContainer = (props: IProps) => {
  const listRef = useRef<any>();

  useEffect(() => {
    return () => {
      props.clearAgendaJobsError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAgendaJobs = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    props.getAgendaJobsAction(limit, skip, sort, criteria);
  };

  const parseList = (list: PaginationDTO<AgendaJobDTO>) => {
    return {
      ...list,
      results: list?.results
        ? list.results.map((item: AgendaJobDTO) => {
            return {
              lastRunAt: item.lastRunAt ? getFormatDate(item.lastRunAt) : 'N/A',
              name: {
                type: 'custom',
                render: () => {
                  const value = item.name;
                  return (
                    <div className="linkedData" onClick={() => setFilterHandler(item.name, 0)}>
                      {value}
                    </div>
                  );
                }
              },
              nextRunAt: item.nextRunAt ? getFormatDate(item.nextRunAt) : 'N/A',
              repeatInterval: item.repeatInterval || 'N/A',
              title: {
                type: 'custom',
                render: () => {
                  const value = item.data?.event?.title || item.data?.announcement?.title;
                  return value ? (
                    <div className="linkedData" onClick={() => setSearchHandler(value)}>
                      {value}
                    </div>
                  ) : (
                    <div>N/A</div>
                  );
                }
              },
              place: {
                type: 'custom',
                render: () => {
                  const value = item.data?.event?.placeNames[0];
                  return value ? (
                    <div className="linkedData" onClick={() => setSearchHandler(value)}>
                      {value}
                    </div>
                  ) : (
                    <div>N/A</div>
                  );
                }
              }
            };
          })
        : []
    };
  };

  const getFilters = () => {
    const agendaJobs = [
      'runAutopilotVerification12',
      'runAutopilotVerification15',
      'runAutopilotVerification17',
      'runCheckActiveCheckins',
      'runCheckRaffleSchedule',
      'runCheckTransactions',
      'runDailyReport',
      'runStatistics',
      'runResetRewardsCounter',
      'runUpgradeUserTags',
      'runDowngradeUserTags',
      'dispatchAnnouncement',
      'dispatchEvent',
      'startEvent'
    ];
    let filterValues = [{ _id: '', name: msg('agendaJobs.allJobs', 'All jobs') }];
    agendaJobs.forEach(item => filterValues.push({ _id: item, name: item }));
    const filters = [
      {
        field: 'name',
        value: filterValues,
        default: ''
      }
    ];
    return filters;
  };

  const setSearchHandler = (value?: string) => {
    listRef.current?.setSearchByValue(value);
  };

  const setFilterHandler = (value: string, index: number) => {
    listRef.current?.setFilterByValue(value, index);
  };

  const getFields = () => {
    const fields = {
      lastRunAt: msg('agendaJobs.lastRunAt', 'Last run'),
      name: msg('agendaJobs.name', 'Name'),
      nextRunAt: msg('agendaJobs.nextRunAt', 'Next run'),
      repeatInterval: msg('agendaJobs.repeatInterval', 'Repeat interval'),
      title: msg('agendaJobs.title', 'Extra data - Title'),
      place: msg('agendaJobs.place', 'Extra data - Place')
    };
    return fields;
  };

  return (
    <CardComponent
      title={msg('agendaJobs.cardTitle', 'Agenda Jobs')}
      error={!!props.agenda_jobs_list_error}
      headerIcon="pending_actions"
      needsTitle={true}
    >
      <InfiniteScrollListComponent
        wrappedComponentRef={(ref: any) => (listRef.current = ref)}
        fields={getFields()}
        list={parseList(props.agenda_jobs_list)}
        pending={props.agenda_jobs_list_pending}
        error={props.agenda_jobs_list_error}
        get={getAgendaJobs}
        limit={30}
        sort={{
          fields: ['nextRunAt', 'lastRunAt'],
          default: 'nextRunAt,-1'
        }}
        search={['name', 'data.event.placeNames', 'data.event.title', 'data.announcement.title']}
        filters={getFilters()}
      />
    </CardComponent>
  );
};

function mapStateToProps(state: AppStore.states) {
  return {
    agenda_jobs_list: state.agendaJobs.agenda_jobs_list,
    agenda_jobs_list_pending: state.agendaJobs.agenda_jobs_list_pending,
    agenda_jobs_list_error: state.agendaJobs.agenda_jobs_list_error
  };
}

const dispatchToProps = {
  getAgendaJobsAction: AgendaJobsStore.actions.getAgendaJobsAction,
  clearAgendaJobsError: AgendaJobsStore.actions.clearAgendaJobsError
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(AgendaJobsContainer));
