import moment from 'moment';

export const getFormatDate = (date: any, type = 'full') => {
  let formatDate = moment(date).format('DD.MM.YYYY HH:mm:ss');
  if (type === 'date') {
    formatDate = moment(date).format('DD.MM.YYYY');
  }
  if (type === 'hour') {
    formatDate = moment(date).format('DD.MM.YYYY HH:mm');
  }
  return formatDate;
};

export const getDateInterval = (numberOfDays: number = 30) => {
  const currentDate = new Date().setHours(23, 59, 59);
  const before = currentDate - 24 * 60 * 60 * 1000 * numberOfDays;
  const dateBefore = new Date(before).setHours(0, 0, 0);
  return { startTime: dateBefore, endTime: currentDate };
};
