import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AppStore, AuthStore, reduxContainer, TranslationsStore } from '../../../appRedux';
import backgroundImage from '../../../assets/images/background.jpg';
import { authRoutes } from '../../layouts';
import { FooterAuthComponent, NavAuthComponent, UnsubscribeComponent } from './components';

interface IProps extends AuthStore.IState, TranslationsStore.IState {
  match: any;
}
interface IState {}

class UnsubscribeContainer extends React.Component<IProps & AuthStore.IActions & TranslationsStore.IActions, IState> {
  render() {
    const navAuthConfig = {
      title: 'ReGage Partner',
      initial_language: this.props.initial_language,
      changeLanguage: this.props.changeLanguageAction
    };

    const configUser = {
      pending: this.props.unsubscribe_user_pending,
      error: this.props.unsubscribe_user_error,
      action: this.props.unsubcribeUserAction
    };

    return (
      <div className="partner-auth off-canvas-sidebar">
        <NavAuthComponent config={navAuthConfig} />
        <div className="wrapper wrapper-full-page">
          <div className="full-page login-page" filter-color="black">
            <div className="content" style={{ paddingTop: '28vh' }}>
              <Switch>
                <Route
                  exact={true}
                  path={authRoutes.UNSUBSCRIBE_USER}
                  render={({ match }) => <UnsubscribeComponent match={match} config={configUser} />}
                />
                <Route
                  exact={true}
                  path={authRoutes.UNSUBSCRIBE_PLACE}
                  render={({ match }) => <UnsubscribeComponent match={match} config={configUser} />}
                />
              </Switch>
            </div>
            <FooterAuthComponent />
            <div className="full-page-background" style={{ backgroundImage: `url(${backgroundImage})` }} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    unsubscribe_user_pending: state.auth.unsubscribe_user_pending,
    unsubscribe_user_error: state.auth.unsubscribe_user_error,
    initial_language: state.translations.initial_language
  };
}

const dispatchToProps = {
  unsubcribeUserAction: AuthStore.actions.unsubcribeUserAction,
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction
};

export default reduxContainer(UnsubscribeContainer, mapStateToProps, dispatchToProps);
