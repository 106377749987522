import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AppStore, reduxContainer, AuthStore, TranslationsStore } from '../../../appRedux';
import { NavAuthComponent, FooterAuthComponent, ResetPasswordComponent } from './components';

import backgroundImage from '../../../assets/images/background.jpg';

interface IProps extends AuthStore.IState, TranslationsStore.IState {
  auth?: any;
}

interface IState {}

class ResetPasswordContainer extends React.Component<
  IProps & RouteComponentProps & AuthStore.IActions & TranslationsStore.IActions,
  IState
> {
  resetPasswordHandler = (token: string, password: string) => {
    this.props.resetPasswordAction(token, password);
  };

  render() {
    const navAuthConfig = {
      title: 'ReGage Partner',
      initial_language: this.props.initial_language,
      changeLanguage: this.props.changeLanguageAction
    };
    return (
      <div className="partner-auth off-canvas-sidebar">
        <NavAuthComponent config={navAuthConfig} />
        <div className="wrapper wrapper-full-page">
          <div className="full-page login-page" filter-color="black">
            <div className="content" style={{ paddingTop: '28vh' }}>
              <ResetPasswordComponent match={this.props.match} resetPasswordAction={this.resetPasswordHandler} />
            </div>
            <FooterAuthComponent />
            <div className="full-page-background" style={{ backgroundImage: `url(${backgroundImage})` }} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    auth: state.auth,
    initial_language: state.translations.initial_language
  };
}

const dispatchToProps = {
  resetPasswordAction: AuthStore.actions.resetPasswordAction,
  redirectAction: AuthStore.actions.redirectAction,
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction
};

export default reduxContainer(ResetPasswordContainer, mapStateToProps, dispatchToProps);
