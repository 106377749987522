import * as React from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { CSSProperties, useEffect, useState } from 'react';

interface IProps {
  initialColor?: string;
  closeColorPicker(): void;
  handleColorChange(color: string): void;
}

const ColorPickerComponent = (props: IProps) => {
  const [currentColor, setCurrentColor] = useState<string>(props.initialColor || '');

  useEffect(() => {
    if (props.initialColor) {
      setCurrentColor(props.initialColor);
    }
    // tslint:disable-next-line: align
  }, [props.initialColor]);

  const handleColorChangeComplete = (color: ColorResult) => {
    props.handleColorChange(color.hex);
  };

  return (
    <div>
      <div style={styles.cover as CSSProperties} onClick={props.closeColorPicker} />
      <div style={styles.popover as CSSProperties}>
        <ChromePicker
          color={currentColor}
          onChange={(color: ColorResult) => setCurrentColor(color.hex)}
          onChangeComplete={handleColorChangeComplete}
          disableAlpha={true}
        />
      </div>
    </div>
  );
};

export default ColorPickerComponent;

const styles = {
  popover: {
    position: 'absolute',
    zIndex: 2
  },
  cover: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
};
