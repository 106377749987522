import * as React from 'react';

import { TextFieldComponent, SimpleSelectComponent, ButtonComponent, RewardPictureInput } from '../../../../shared';
import { msg, Validator, IValidator } from '../../../../../utils';
import Toggle from 'react-toggle';
import { RewardDTO, RewardType, TagDTO } from '../../../../../appRedux/types';
import voucherIcon from '../../../../../assets/images/voucher-icon.png';
import discountIcon from '../../../../../assets/images/discount-icon.png';
import { Checkbox } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface IProps {
  data: {
    fields: { [key: string]: string };
    list: Array<any>;
    actions?: Array<{
      label: string;
      onClick: (index: number, reward?: RewardDTO) => (event: React.ChangeEvent | React.MouseEvent) => void;
      icon: string;
      btn: string;
      id?: string;
      isDisabled?: (reward: RewardDTO) => boolean;
    }>;
    pending?: boolean;
    error?: string | null;
  };
  onCellChange: (value: string | RewardType, index: Number, dataType: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
  forSAdmin: boolean;
  rewards: any;
  config: any;
  industry: string;
  addRewardAction: () => any;
  saveAction: () => any;
  inheritRewardsAction: (event: any) => Promise<any>;
  output?: boolean;
  mediaURL?: string;
  showLabelInfo?: boolean;
  dirty?: boolean;
  showDetails?: boolean;
}

interface IState {}
class EditableListComponent extends React.Component<IProps & IValidator, IState> {
  getWidth = (field: string) => {
    switch (field) {
      case 'title':
        return '35%';
      case 'points':
        return '10%';
      case 'type':
        return '12%';
      case 'amount':
        return '15%';
      default:
        return '10%';
    }
  };

  getHeader() {
    return (
      <tr style={{ width: '100%' }}>
        {Object.keys(this.props.data.fields).map((key, index) => {
          const value = `${this.props.data.fields[key]} *`;
          const styles =
            key === 'isActive' ? { textAlign: 'center', width: this.getWidth(key) } : { width: this.getWidth(key) };
          let content = <div>{value}</div>;
          if (index === 0) {
            content = (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: 50, textAlign: 'center', marginRight: 30 }}>
                  {msg('general.picture', 'Picture')}
                </div>
                <div>{value}</div>
              </div>
            );
          }
          return (
            <th key={key} style={styles as CSSProperties}>
              {content}
            </th>
          );
        })}
        {this.props.data?.actions?.length ? (
          <th style={{ width: this.getWidth('actions') }}>{msg('general.actions', 'Actions')}</th>
        ) : null}
      </tr>
    );
  }

  saveHandler = () => {
    const { isValid } = this.props.validator!;
    if (isValid()) {
      this.props.saveAction();
    }
  };

  change = (key: number, fieldKey: string, type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.validator) {
      const { isDirty } = this.props.validator!;
      if (isDirty) {
        isDirty(`${type}${key}`);
      }
    }
    this.props.onCellChange(e.target.value, key, fieldKey);
  };

  uploadPicture = (key: number) => (res: any) => {
    if (res && res.data && res.data[0]) {
      this.props.onCellChange(res.data[0], key, 'media');
    }
  };

  getBody() {
    const { errors } = this.props.validator!;
    return this.props.data.list.map((listItem, key) => (
      <tr key={key}>
        <td colSpan={7} style={{ padding: 0 }}>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                {Object.keys(this.props.data.fields).map((fieldKey, i) => {
                  switch (fieldKey) {
                    case 'title':
                      return (
                        <td key={i} style={{ ...customStyles.cell, width: this.getWidth(fieldKey) }}>
                          <RewardPictureInput
                            id={`title${key}`}
                            placeId={listItem.placeId}
                            industryId={this.props.industry}
                            forSAdmin={this.props.forSAdmin}
                            value={listItem[fieldKey]}
                            media={
                              listItem.type === RewardType.VOUCHER
                                ? voucherIcon
                                : listItem.type === RewardType.DISCOUNT
                                ? discountIcon
                                : listItem?.media?.url || null
                            }
                            mediaURL={this.props.mediaURL}
                            readOnly={this.props.readOnly}
                            onChange={this.change(key, fieldKey, 'title')}
                            uploadPicture={this.uploadPicture(key)}
                            marginPicture={30}
                            formatError={errors[`title${key}`]}
                            ref={`title${key}`}
                            validatorIgnore={!listItem.dirty}
                            validator={[
                              {
                                type: 'isLength',
                                params: { min: 2 },
                                msg: msg('formValidation.inputLength', 'Input too short!')
                              },
                              {
                                type: 'isLength',
                                msg: msg('formValidation.fieldRequired', 'Field required!'),
                                params: { min: 1 }
                              }
                            ]}
                            disabled={listItem.type === RewardType.VOUCHER}
                          />
                        </td>
                      );
                    case 'points':
                      return (
                        <td key={i} style={{ ...customStyles.cell, width: this.getWidth(fieldKey) }}>
                          {listItem.isFree ? (
                            <div style={{ fontSize: 16, fontWeight: 400, marginTop: 5 }}> Gratuit</div>
                          ) : (
                            <TextFieldComponent
                              id={`coins${key}`}
                              value={listItem[fieldKey] ?? ''}
                              onChange={this.change(key, fieldKey, 'coins')}
                              inType="text"
                              required={false}
                              maxLength={4}
                              placeholder={msg('reward.coinsPlaceholder', 'Number of coins')}
                              style={{ padding: 0 }}
                              readOnly={this.props.readOnly}
                              formatError={errors[`coins${key}`]}
                              ref={`coins${key}`}
                              validatorIgnore={!listItem.dirty}
                              validator={[
                                {
                                  type: 'isNumeric',
                                  msg: msg('formValidation.onlyNumbers', 'Use only numbers!')
                                },
                                {
                                  type: 'isLength',
                                  msg: msg('formValidation.fieldRequired', 'Field required!'),
                                  params: { min: 1 }
                                }
                              ]}
                            />
                          )}
                        </td>
                      );
                    case 'isFree':
                      return (
                        <td key={i} style={{ ...customStyles.cell, width: this.getWidth(fieldKey) }}>
                          <Checkbox
                            checked={!!listItem[fieldKey]}
                            onChange={this.change(key, fieldKey, 'isFree')}
                            color="primary"
                          />
                        </td>
                      );
                    case 'type':
                      return (
                        <td key={i} style={{ ...customStyles.cell, width: this.getWidth(fieldKey) }}>
                          <SimpleSelectComponent
                            name="rewardType"
                            options={[
                              { _id: 0, name: msg('rewardTypes.discount', 'Discount') },
                              { _id: 1, name: msg('rewardTypes.product', 'Product') },
                              { _id: 2, name: msg('rewardTypes.voucher', 'Voucher') }
                            ]}
                            id={`type${key}`}
                            value={listItem[fieldKey]}
                            onChange={this.change(key, fieldKey, 'type')}
                            required={false}
                            needsAllLabel={false}
                            placeholder={msg('reward.typePlaceholder', 'Select type')}
                            arrayOptions={false}
                            style={{ padding: 0 }}
                            readOnly={this.props.readOnly}
                            formatError={errors[`type${key}`]}
                            ref={`type${key}`}
                            validatorIgnore={!listItem.dirty}
                            validator={[
                              {
                                type: 'checkIfSelected',
                                params: listItem[fieldKey],
                                msg: msg('formValidation.fieldRequired', 'Field required!')
                              }
                            ]}
                          />
                        </td>
                      );
                    case 'amount':
                      if (listItem.type === RewardType.VOUCHER) {
                        return (
                          <td
                            key={i}
                            style={{ ...customStyles.cell, width: this.getWidth(fieldKey), textAlign: 'center' }}
                          >
                            <TextFieldComponent
                              id={`amount${key}`}
                              value={listItem[fieldKey] || ''}
                              onChange={this.change(key, fieldKey, 'amount')}
                              inType="text"
                              required={false}
                              placeholder={msg('reward.valuePlaceholder', 'RON Value')}
                              style={{ padding: 0 }}
                              readOnly={this.props.readOnly}
                              formatError={errors[`amount${key}`]}
                              ref={`amount${key}`}
                              validatorIgnore={!listItem.dirty}
                              validator={[
                                {
                                  type: 'isNumeric',
                                  msg: msg('formValidation.onlyNumbers', 'Use only numbers!')
                                },
                                {
                                  type: 'isLength',
                                  msg: msg('formValidation.fieldRequired', 'Field required!'),
                                  params: { min: 1 }
                                }
                              ]}
                            />
                          </td>
                        );
                      } else {
                        return (
                          <td
                            key={i}
                            style={{ ...customStyles.cell, width: this.getWidth(fieldKey), textAlign: 'center' }}
                          />
                        );
                      }
                    case 'isActive':
                      if (!this.props.output) {
                        return (
                          <td
                            key={i}
                            style={{ ...customStyles.cell, width: this.getWidth(fieldKey), textAlign: 'center' }}
                          >
                            <Toggle
                              onChange={this.change(key, fieldKey, 'value')}
                              checked={!!listItem.isActive}
                              disabled={false}
                            />
                          </td>
                        );
                      }
                      return null;
                    default:
                      return null;
                  }
                })}
                {this.props.data?.actions?.length ? (
                  <td className="td-actions" style={{ ...customStyles.cell, width: this.getWidth('actions') }}>
                    {this.props.data.actions.map((action, actionKey) => {
                      return (
                        <button
                          key={actionKey}
                          type="button"
                          className={`btn ${action.btn} popover-btn`}
                          onClick={action.onClick(key, listItem)}
                          title={action.label}
                          id={listItem._id}
                          disabled={this.props.disabled || (action.isDisabled && !action.isDisabled(listItem))}
                        >
                          <i className="material-icons">{action.icon}</i>
                        </button>
                      );
                    })}
                  </td>
                ) : null}
              </tr>
              {this.props.showDetails && (
                <tr>
                  <td colSpan={7} className="reward-stats">
                    <div className="col-sm-6 col-lg-5">
                      <div style={{ float: 'left' }}> {msg('reward.stock', 'Stock')}:</div>
                      <div style={customStyles.stats}>
                        {listItem.stocks.dayCount} / {listItem.stocks.dayStock || msg('reward.unlimited', 'unlimited')}{' '}
                        {msg('intervalTypes.dayLowercase', 'day')}
                      </div>
                      <div style={customStyles.stats}>
                        {listItem.stocks.weekCount} /{' '}
                        {listItem.stocks.weekStock || msg('reward.unlimited', 'unlimited')}{' '}
                        {msg('intervalTypes.weekLowercase', 'week')}
                      </div>
                      <div style={customStyles.stats}>
                        {listItem.stocks.monthCount} /{' '}
                        {listItem.stocks.monthStock || msg('reward.unlimited', 'unlimited')}{' '}
                        {msg('intervalTypes.monthLowercase', 'month')}
                      </div>
                    </div>
                    {!!listItem.memberTags?.length && (
                      <div className="col-sm-3">
                        {msg('tags.memberTags', 'Member tags')}:{' '}
                        {listItem.memberTags.map((item: TagDTO) => item.name).join(', ')}
                      </div>
                    )}
                    {!!listItem.userTags?.length && (
                      <div className="col-sm-3">
                        {msg('tags.userTags', 'User tags')}:{' '}
                        {listItem.userTags.map((item: TagDTO) => item.name).join(', ')}
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </td>
      </tr>
    ));
  }

  renderLabelInfo = () => (
    <div style={{ margin: 10 }}>
      <div style={customStyles.label}>
        {msg(
          'reward.info1',
          'Rewards are a type of bonus that you can offer to your members of your place. Members can buy rewards with the coins accumulated until now. You can add how many rewards you want.'
        )}
      </div>
      <div style={customStyles.label}>{msg('reward.info2', 'Exemple: A juice at price of 50 coins.')}</div>
      <div style={customStyles.label}>
        {msg('reward.info3', 'Find more about rewards: ')}
        <a href="https://help.regage.io/#/rewards" target="_blank" rel="noopener noreferrer">
          {msg('reward.linkText', 'Help Rewards')}
        </a>
      </div>
    </div>
  );

  render() {
    const isData: Boolean = !!(Object.keys(this.props.data?.fields || {}).length && this.props.data?.list?.length);
    return (
      <div className="table-responsive">
        {this.props.forSAdmin &&
          !this.props.output &&
          this.props.rewards &&
          !this.props.config.industry_pending &&
          this.props.industry !== null &&
          this.props.industry !== '' && (
            <div>
              {isData ? (
                <table className="table table-hover">
                  <thead className="text-primary">{this.getHeader()}</thead>
                  <tbody>{this.getBody()}</tbody>
                </table>
              ) : null}
              <div className="row" style={{ margin: 10 }}>
                <div className={this.props.rewards.length === 0 ? 'col-md-4' : 'col-md-2'} />
                <div className="col-md-4" style={{ textAlign: 'center' }}>
                  <div className="form-group form-button">
                    <ButtonComponent
                      label={msg('cardTitle.addReward', 'Add new reward')}
                      icon="card_giftcard"
                      action={this.props.addRewardAction}
                      pending={false}
                    />
                  </div>
                </div>
                <div className={this.props.rewards.length === 0 ? 'col-md-4' : 'col-md-2'} />
                {this.props.rewards.length > 0 && (
                  <div className="col-md-3">
                    <div className="form-group form-button">
                      <ButtonComponent
                        label={msg('general.save', 'Save')}
                        icon="save"
                        action={this.saveHandler}
                        pending={this.props.config.reward_save_pending}
                      />
                    </div>
                  </div>
                )}
                <div className="col-md-2" />
              </div>
            </div>
          )}

        {!this.props.forSAdmin && !this.props.output && (
          <div className="row" style={{ margin: 10 }}>
            {isData ? (
              <table className="table table-hover">
                <thead className="text-primary">{this.getHeader()}</thead>
                <tbody>{this.getBody()}</tbody>
              </table>
            ) : null}
            <div className="col-md-2" />
            {this.props.rewards.length === 0 && (
              <div className="col-md-4" style={{ textAlign: 'center' }}>
                <div className="form-group form-button">
                  <ButtonComponent
                    label={msg('reward.inheritIndustryRewards', 'Inherit Rewards')}
                    action={this.props.inheritRewardsAction}
                    pending={this.props.config.rewards_list_pending}
                  />
                </div>
              </div>
            )}
            <div className="col-md-4" style={{ textAlign: 'center' }}>
              <div className="form-group form-button">
                <ButtonComponent
                  label={msg('cardTitle.addReward', 'Add new reward')}
                  icon="card_giftcard"
                  action={this.props.addRewardAction}
                  pending={false}
                />
              </div>
            </div>
            {this.props.rewards.length > 0 && (
              <div className="col-md-4" style={{ textAlign: 'center' }}>
                <div className="form-group form-button">
                  <ButtonComponent
                    label={msg('general.save', 'Save')}
                    icon="save"
                    action={this.saveHandler}
                    pending={this.props.config.reward_save_pending}
                    disabled={!(this.props.dirty ?? true)}
                  />
                </div>
              </div>
            )}
            <div className="col-md-2" />
          </div>
        )}
        {this.props.output && (
          <div className="row" style={{ margin: 10 }}>
            {this.props.showLabelInfo && this.renderLabelInfo()}
            <div className="row" style={{ margin: 10 }}>
              {isData && (
                <table className="table table-hover">
                  <thead className="text-primary">{this.getHeader()}</thead>
                  <tbody>{this.getBody()}</tbody>
                </table>
              )}
              <div className="col-md-4" />
              <div className="col-md-4" style={{ textAlign: 'center' }}>
                <div className="form-group form-button">
                  <ButtonComponent
                    label={msg('cardTitle.addReward', 'Add new reward')}
                    icon="card_giftcard"
                    action={this.props.addRewardAction}
                    pending={this.props.config.rewards_list_pending}
                  />
                </div>
              </div>
              <div className="col-md-4" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Validator(EditableListComponent);

const customStyles: any = {
  label: {
    margin: '8px 0px',
    fontSize: 16
  },
  cell: {
    padding: '12px 8px'
  },
  stats: {
    float: 'left',
    padding: '0px 35px'
  }
};
