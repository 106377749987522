import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { AppStore, TranslationsStore } from '../../../appRedux';
import backgroundImage from '../../../assets/images/background.jpg';
import { msg } from '../../../utils';
import { FooterAuthComponent, NavAuthComponent } from './components';
import styles from './components/styles/Auth.module.css';

interface IProps {
  initial_language: string;
  changeLanguageAction: (option: string) => any;
}

const NotFoundContainer = (props: IProps) => {
  const navAuthConfig = {
    title: 'ReGage',
    initial_language: props.initial_language,
    changeLanguage: props.changeLanguageAction
  };

  return (
    <div className="partner-auth off-canvas-sidebar">
      <NavAuthComponent config={navAuthConfig} />
      <div className="wrapper wrapper-full-page">
        <div className="full-page login-page" filter-color="black">
          <div className="content" style={{ paddingTop: '30vh' }}>
            <div className="container">
              <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                <div className="card card-login">
                  <div className="card-header text-center" data-background-color="green">
                    <h4 className="card-title">{msg('general.pageNotFound', 'Page not found')}</h4>
                  </div>
                  <div
                    className={`card-content ${styles.content}`}
                    style={{ marginLeft: 20, marginTop: -5, textAlign: 'center' }}
                  >
                    {msg('general.pageNotFoundMessage', 'Unfortunately, the page you were looking for was not found.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterAuthComponent />
          <div className="full-page-background" style={{ backgroundImage: `url(${backgroundImage})` }} />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: AppStore.states) {
  return {
    initial_language: state.translations.initial_language
  };
}

const dispatchToProps = {
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(NotFoundContainer));
