import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PartnerEmailsListContainer, PartnerEmailContainer } from './';
import { partnerRoutes, adminRoutes } from '../../layouts';

const PartnerEmailRouter = () => {
  return (
    <Switch>
      <Route exact={true} path={partnerRoutes.EMAILS.path} render={() => <PartnerEmailsListContainer />} />
      <Route path={partnerRoutes.EMAILS.subroutes.ADD.path} render={() => <PartnerEmailContainer />} />
      <Route path={partnerRoutes.EMAILS.subroutes.EDIT.path} render={() => <PartnerEmailContainer />} />
      <Route exact={true} path={adminRoutes.EMAILS.path} render={() => <PartnerEmailsListContainer />} />
      <Route path={adminRoutes.EMAILS.subroutes.ADD.path} render={() => <PartnerEmailContainer />} />
      <Route path={adminRoutes.EMAILS.subroutes.EDIT.path} render={() => <PartnerEmailContainer />} />
    </Switch>
  );
};

export default PartnerEmailRouter;
