import partnerEmailsActions, { IPartnerEmailsActions } from './actions';
import partnerEmailsReducer from './reducer';
import { PaginationDTO, PartnerEmailDTO } from '../types';

/*
  PartnerEmailsStore definition as a namespace that contains actions, reducers, actionTypes and partnerEmails state
*/
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PartnerEmailsStore {
  export type IState = {
    partner_emails_list: PaginationDTO<PartnerEmailDTO>;
    partner_emails_list_pending: boolean;
    partner_emails_list_error: string | null;
    save_partner_email_pending: boolean;
    save_partner_email_error: string | null;
    partner_email: PartnerEmailDTO | null;
    partner_email_pending: boolean;
    partner_email_error: string | null;
    read_partner_email_pending: boolean;
    read_partner_email_error: string | null;
    delete_attachment_pending: boolean;
    delete_attachment_error: string | null;
  };

  export const initialState: IState = {
    partner_emails_list: new PaginationDTO(),
    partner_emails_list_pending: false,
    partner_emails_list_error: null,
    save_partner_email_pending: false,
    save_partner_email_error: null,
    partner_email: null,
    partner_email_pending: false,
    partner_email_error: null,
    read_partner_email_pending: false,
    read_partner_email_error: null,
    delete_attachment_pending: false,
    delete_attachment_error: null
  };

  export enum ActionTypes {
    GET_PARTNER_EMAILS = 'GET_PARTNER_EMAILS',
    GET_PARTNER_EMAILS_SUCCESS = 'GET_PARTNER_EMAILS_SUCCESS',
    GET_PARTNER_EMAILS_FAILED = 'GET_PARTNER_EMAILS_FAILED',
    SAVE_PARTNER_EMAIL = 'SAVE_PARTNER_EMAIL',
    SAVE_PARTNER_EMAIL_SUCCESS = 'SAVE_PARTNER_EMAIL_SUCCESS',
    SAVE_PARTNER_EMAIL_FAILED = 'SAVE_PARTNER_EMAIL_FAILED',
    GET_PARTNER_EMAIL = 'GET_PARTNER_EMAIL',
    GET_PARTNER_EMAIL_SUCCESS = 'GET_PARTNER_EMAIL_SUCCESS',
    GET_PARTNER_EMAIL_FAILED = 'GET_PARTNER_EMAIL_FAILED',
    READ_PARTNER_EMAIL = 'READ_PARTNER_EMAIL',
    READ_PARTNER_EMAIL_SUCCESS = 'READ_PARTNER_EMAIL_SUCCESS',
    READ_PARTNER_EMAIL_FAILED = 'READ_PARTNER_EMAIL_FAILED',
    DELETE_ATTACHMENT = 'DELETE_ATTACHMENT',
    DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS',
    DELETE_ATTACHMENT_FAILED = 'DELETE_ATTACHMENT_FAILED',
    CLEAR_EMAILS_ERROR = 'CLEAR_EMAILS_ERROR',
    CLEAR_PARTNER_EMAIL = 'CLEAR_PARTNER_EMAIL'
  }

  export const actions = partnerEmailsActions;
  export const reducer = partnerEmailsReducer;
  export interface IActions extends IPartnerEmailsActions {}
}

export * from './actions';
