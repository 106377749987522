import React from 'react';

import { msg } from '../../../../../../../utils/index';
import { TextFieldComponent } from '../../../../../../shared/index';

export function SendMessageAction({ listItem, onValueChange, index, onClick }: any) {
  return (
    <div>
      <div className="col-sm-4" style={{ marginRight: 10 }}>
        <TextFieldComponent
          label={msg('autopilot.actionMessage', 'Message')}
          id="actionMessage"
          value={listItem.value}
          onChange={(e: any) => onValueChange(e.target.value, index, 'value')}
          inType="text"
          minLength={1}
          maxLength={200}
          required={true}
        />
      </div>
    </div>
  );
}
