import { PartnerEmailsStore } from '.';
import { PaginationDTO } from '../types';

/*
  partnerEmailsReducer = reducer for all actionTypes for PartnerEmails
*/
function partnerEmailsReducer(
  state: PartnerEmailsStore.IState = PartnerEmailsStore.initialState,
  action: IAction<any>
): PartnerEmailsStore.IState {
  switch (action.type) {
    case PartnerEmailsStore.ActionTypes.GET_PARTNER_EMAILS: {
      return {
        ...state,
        partner_emails_list_pending: true,
        partner_emails_list_error: null
      };
    }
    case PartnerEmailsStore.ActionTypes.GET_PARTNER_EMAILS_SUCCESS: {
      return {
        ...state,
        partner_emails_list_pending: false,
        partner_emails_list_error: null,
        partner_emails_list: action.payload
      };
    }
    case PartnerEmailsStore.ActionTypes.GET_PARTNER_EMAILS_FAILED: {
      return {
        ...state,
        partner_emails_list_pending: false,
        partner_emails_list: new PaginationDTO(),
        partner_emails_list_error: action.payload
      };
    }
    case PartnerEmailsStore.ActionTypes.SAVE_PARTNER_EMAIL: {
      return {
        ...state,
        save_partner_email_pending: true,
        save_partner_email_error: null
      };
    }
    case PartnerEmailsStore.ActionTypes.SAVE_PARTNER_EMAIL_SUCCESS: {
      return {
        ...state,
        save_partner_email_pending: false,
        save_partner_email_error: null,
        partner_email: action.payload
      };
    }
    case PartnerEmailsStore.ActionTypes.SAVE_PARTNER_EMAIL_FAILED: {
      return {
        ...state,
        save_partner_email_pending: false,
        partner_email: null,
        save_partner_email_error: action.payload
      };
    }
    case PartnerEmailsStore.ActionTypes.GET_PARTNER_EMAIL: {
      return {
        ...state,
        partner_email_pending: true,
        partner_email_error: null
      };
    }
    case PartnerEmailsStore.ActionTypes.GET_PARTNER_EMAIL_SUCCESS: {
      return {
        ...state,
        partner_email_pending: false,
        partner_email_error: null,
        partner_email: action.payload
      };
    }
    case PartnerEmailsStore.ActionTypes.GET_PARTNER_EMAIL_FAILED: {
      return {
        ...state,
        partner_email_pending: false,
        partner_email: null,
        partner_email_error: action.payload
      };
    }
    case PartnerEmailsStore.ActionTypes.READ_PARTNER_EMAIL: {
      return {
        ...state,
        read_partner_email_pending: true,
        read_partner_email_error: null
      };
    }
    case PartnerEmailsStore.ActionTypes.READ_PARTNER_EMAIL_SUCCESS: {
      return {
        ...state,
        read_partner_email_pending: false,
        read_partner_email_error: null
      };
    }
    case PartnerEmailsStore.ActionTypes.READ_PARTNER_EMAIL_FAILED: {
      return {
        ...state,
        delete_attachment_pending: false,
        delete_attachment_error: action.payload
      };
    }
    case PartnerEmailsStore.ActionTypes.DELETE_ATTACHMENT: {
      return {
        ...state,
        delete_attachment_pending: true,
        delete_attachment_error: null
      };
    }
    case PartnerEmailsStore.ActionTypes.DELETE_ATTACHMENT_SUCCESS: {
      return {
        ...state,
        delete_attachment_pending: false,
        delete_attachment_error: null
      };
    }
    case PartnerEmailsStore.ActionTypes.DELETE_ATTACHMENT_FAILED: {
      return {
        ...state,
        read_partner_email_pending: false,
        read_partner_email_error: action.payload
      };
    }
    case PartnerEmailsStore.ActionTypes.CLEAR_EMAILS_ERROR: {
      return {
        ...state,
        partner_emails_list_error: null
      };
    }
    case PartnerEmailsStore.ActionTypes.CLEAR_PARTNER_EMAIL: {
      return {
        ...state,
        partner_email: null
      };
    }
    default:
      return state;
  }
}

export default partnerEmailsReducer;
