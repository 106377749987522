export class ScheduleDTO {
  opened: boolean = false;
  intervals: IntervalDTO[] = [new IntervalDTO(defaultInterval)];
}

export class IntervalDTO {
  startHour: number = 10;
  startMinutes: number = 0;
  endHour: number = 22;
  endMinutes: number = 0;

  constructor(interval: IntervalDTO) {
    this.startHour = interval.startHour;
    this.startMinutes = interval.startMinutes;
    this.endHour = interval.endHour;
    this.endMinutes = interval.endMinutes;
  }
}

const defaultInterval = { startHour: 10, startMinutes: 0, endHour: 22, endMinutes: 0 };
