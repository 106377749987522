import React from 'react';

import { ListComponent, CardComponent, ButtonComponent, AutocompleteSelectComponent } from '../../../shared';
import { UserDTO, ValueDTO } from '../../../../appRedux/types';
import { AlertUtil, msg } from '../../../../utils';

interface IProps {
  partnerId: string;
  config: {
    list: Array<UserDTO>;
    error: string | null;
    list_pending: boolean;
    admin_save_pending: boolean;
    get: (partnerId: string) => any;
    redirectToEdit: (userId: string) => any;
    redirectToAdd: (partnerId: string) => any;
    removeAdmin: (partnerId: string, userId: string) => any;
    add: (userId: string, partnerId: string) => any;
  };
}
interface IState {
  user: ValueDTO;
}

export default class UsersListComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      user: {
        _id: '',
        value: '',
        label: ''
      }
    };
  }

  componentDidMount() {
    const { config } = this.props;
    config.get(this.props.partnerId);
  }

  addUser = () => {
    this.props.config.add(this.state.user._id, this.props.partnerId);
  };

  addAction = (event: any) => {
    this.props.config.redirectToAdd(event.currentTarget.getAttribute('id'));
  };

  editAction = (event: any) => {
    this.props.config.redirectToEdit(event.currentTarget.getAttribute('id'));
  };

  removeAdminAction = async (event: any) => {
    const id = event.currentTarget.getAttribute('id');
    const hasConfirmation = await AlertUtil.revokeRights(
      msg('alertMessages.adminRightsRevokeConfimation', 'You are about to revoke the administrator rights of an user!')
    );
    if (hasConfirmation) {
      this.props.config.removeAdmin(this.props.partnerId, id);
      AlertUtil.simple(
        // tslint:disable-next-line:quotemark
        msg('alertMessages.adminRightsBeingRevoked', "The administrator's rights are being revoked..."),
        'info'
      );
    }
  };

  handleChange = (type: string) => (value: ValueDTO | any) => {
    let { user } = this.state;
    switch (type) {
      case 'USER':
        user = value;
        break;
      default:
        break;
    }
    this.setState({ user });
  };

  getUsersListData() {
    const { config } = this.props;
    return {
      fields: {
        lastname: msg('accountInfo.lastname', 'Last name'),
        firstname: msg('accountInfo.firstname', 'First name'),
        email: msg('accountInfo.email', 'Email')
        // phone: msg('accountInfo.phone', 'Phone')
      },
      actions: [
        {
          label: msg('general.edit', 'Edit'),
          btn: 'btn-success',
          icon: 'edit',
          onClick: this.editAction
        },
        {
          label: msg('cardTitle.revokeRights', 'Revoke rights'),
          btn: 'btn-info',
          icon: 'remove_circle_outline',
          ref: 'removeAdmin',
          onClick: this.removeAdminAction
        }
      ],
      list: config.list,
      pending: config.list_pending,
      error: config.error
    };
  }

  render() {
    const usersListData = this.getUsersListData();
    return (
      <CardComponent
        title={msg('cardTitle.partnerAdmins', 'Partner Administrators')}
        error={!!this.props.config.error}
        pending={this.props.config.list_pending}
        headerIcon="person_pin_circle"
        needsTitle={true}
      >
        {!this.props.config.list_pending && <ListComponent data={usersListData} disabled={'1'} />}
        <div className="row" style={{ marginBottom: 6 }}>
          <div className="col-sm-6">
            <h4>{msg('searchOptions.searchExistentUser', 'Search existent user')}:</h4>
          </div>
        </div>
        <div className="row" style={{ marginBottom: 6 }}>
          <div className="col-sm-6">
            <AutocompleteSelectComponent
              searchFor="USERS"
              value={this.state.user}
              onChange={this.handleChange('USER')}
            />
          </div>
          <div className="col-sm-6" style={{ float: 'right', top: -20 }}>
            <div className="form-group form-button">
              <ButtonComponent
                disabled={(this.state.user && !this.state.user.value) || this.state.user === null}
                pending={this.props.config.admin_save_pending}
                label={msg('general.add', 'Add')}
                icon="person"
                action={this.addUser}
                id={this.props.partnerId}
              />
            </div>
          </div>
        </div>
        <div className="row" style={{ margin: 10 }}>
          <div className="col-md-3" />
          <div className="col-md-6" style={{ textAlign: 'center' }}>
            <div className="form-group form-button">
              <ButtonComponent
                label={msg('cardTitle.addNewPartnerAdmin', 'Add new partner admin')}
                icon="person_add"
                action={this.addAction}
                id={this.props.partnerId}
              />
            </div>
          </div>
          <div className="col-md-3" />
        </div>
      </CardComponent>
    );
  }
}
