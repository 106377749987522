import classnames from 'classnames';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AppStore, reduxContainer, TranslationsStore } from '../../../appRedux';
import backgroundImage from '../../../assets/images/background.jpg';
import { msg } from '../../../utils';
import { FooterAuthComponent, NavAuthComponent } from './components';
import styles from './components/styles/Auth.module.css';

interface IProps extends TranslationsStore.IState, RouteComponentProps<{ type: string }> {}
interface IState {
  showCard: boolean;
  headerUp: boolean;
  visible: boolean;
}

class PasswordChangedContainer extends React.Component<IProps & TranslationsStore.IActions, IState> {
  responseSuccess: boolean;
  constructor(props: IProps & TranslationsStore.IActions) {
    super(props);
    this.state = {
      showCard: false,
      headerUp: false,
      visible: false
    };
    this.responseSuccess = this.props.match.params.type === 'success';
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showCard: true });
      // tslint:disable-next-line:align
    }, 800);
    setTimeout(() => {
      this.setState({ visible: true });
      // tslint:disable-next-line:align
    }, 1000);
  }

  render() {
    const navAuthConfig = {
      title: 'ReGage Partner',
      initial_language: this.props.initial_language,
      changeLanguage: this.props.changeLanguageAction
    };
    let cardClasses = classnames(`card card-login ${styles.card}`, { 'card-hidden': !this.state.showCard });
    let headerClasses = classnames(`card-header text-center ${styles.header}`, {
      [styles.up]: this.state.headerUp
    });

    return (
      <div className="partner-auth off-canvas-sidebar">
        <NavAuthComponent config={navAuthConfig} />
        <div className="wrapper wrapper-full-page">
          <div className="full-page login-page" filter-color="black">
            <div className="content" style={{ paddingTop: '30vh' }}>
              <div className="container">
                <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                  <div className={cardClasses}>
                    <div className={headerClasses} data-background-color="green">
                      <h4 className="card-title">
                        {this.responseSuccess
                          ? msg('passwords.passwordChanged', 'Password changed')
                          : msg('passwords.changePassword', 'Change password')}
                      </h4>
                    </div>
                    <div className={`card-content ${styles.content}`} style={{ marginLeft: 20, textAlign: 'center' }}>
                      {this.responseSuccess
                        ? msg('passwords.passwordChangedSuccess', 'Your password has been reset!')
                        : msg('passwords.passwordTokenExpired', 'Password changed')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FooterAuthComponent />
            <div className="full-page-background" style={{ backgroundImage: `url(${backgroundImage})` }} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    initial_language: state.translations.initial_language
  };
}

const dispatchToProps = {
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction
};

export default reduxContainer(PasswordChangedContainer, mapStateToProps, dispatchToProps);
