export interface IDictionary<T> {
  [key: string]: T
}

export interface INumericDictionary<T> {
  [key: number]: T
}

export const getServerName = (url: string) => {
  const firstSplit = url.split('.')?.[0] || '';
  const splitArray = firstSplit.split('/');
  return splitArray?.[splitArray.length - 1];
};
