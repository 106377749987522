import * as React from 'react';
import { DateRange, DateRangeInput } from '@blueprintjs/datetime';

export interface IDateRangePickerExampleState {
  allowSingleDayRange?: boolean;
  contiguousCalendarMonths?: boolean;
  closeOnSelection: boolean;
  dateRange?: any;
  disabled: boolean;
  singleMonthOnly: boolean;
  reverseMonthAndYearMenus?: boolean;
  shortcuts?: boolean;
}

interface IProps {
  style?: any;
  getRangeData: (startDate: any, endDate: any) => any;
}

export default class DateRangePickerComponent extends React.PureComponent<IProps, IDateRangePickerExampleState> {
  public state: IDateRangePickerExampleState = {
    allowSingleDayRange: true,
    contiguousCalendarMonths: false,
    closeOnSelection: true,
    dateRange: [null, null],
    disabled: false,
    reverseMonthAndYearMenus: false,
    shortcuts: true,
    singleMonthOnly: false
  };

  public getDateRange = (dateRange: any) => {
    this.props.getRangeData(dateRange[0], dateRange[1]);
  };

  public render() {
    return (
      <div style={this.props.style}>
        <DateRangeInput
          formatDate={(date: any) =>
            date.toLocaleDateString('en-EN', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })
          }
          parseDate={str => new Date(str)}
          onChange={dateRange => {
            this.handleDateChange(dateRange);
            this.getDateRange(dateRange);
          }}
          value={this.state.dateRange}
          allowSingleDayRange={this.state.allowSingleDayRange}
          closeOnSelection={this.state.closeOnSelection}
          contiguousCalendarMonths={this.state.contiguousCalendarMonths}
          shortcuts={this.state.shortcuts}
          singleMonthOnly={this.state.singleMonthOnly}
        />
      </div>
    );
  }

  private handleDateChange = (dateRange: DateRange) => this.setState({ dateRange });
}
