import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { TransactionsListContainer } from '.';
import { partnerRoutes, adminRoutes } from '../../layouts';

const TransactionsRouter = () => {
  return (
    <Switch>
      <Route
        exact={true}
        path={adminRoutes.TRANSACTIONS.path}
        render={() => <TransactionsListContainer isSAdmin={true} />}
      />
      <Route
        exact={true}
        path={partnerRoutes.TRANSACTIONS.default}
        render={() => <TransactionsListContainer isSAdmin={false} />}
      />
    </Switch>
  );
};

export default TransactionsRouter;
