import { OnboardStore } from './';
import { PaginationDTO } from '../types';

/*
  onboardReducer = reducer for all actionTypes for Partners
*/
function onboardReducer(
  state: OnboardStore.IState = OnboardStore.initialState,
  action: IAction<any>
): OnboardStore.IState {
  switch (action.type) {
    case OnboardStore.ActionTypes.SEND_ONBOARDING: {
      return {
        ...state,
        send_onboarding_pending: true,
        send_onboarding_error: null
      };
    }
    case OnboardStore.ActionTypes.SEND_ONBOARDING_SUCCESS: {
      return {
        ...state,
        send_onboarding_pending: false,
        onboarding: action.payload
      };
    }
    case OnboardStore.ActionTypes.SEND_ONBOARDING_FAILED: {
      return {
        ...state,
        send_onboarding_pending: false,
        send_onboarding_error: action.payload
      };
    }
    case OnboardStore.ActionTypes.GET_ALL_ONBOARDINGS: {
      return {
        ...state,
        get_all_onboardings_pending: true,
        get_all_onboardings_error: null
      };
    }
    case OnboardStore.ActionTypes.GET_ALL_ONBOARDINGS_SUCCESS: {
      return {
        ...state,
        get_all_onboardings_pending: false,
        onboardings: action.payload
      };
    }
    case OnboardStore.ActionTypes.GET_ALL_ONBOARDINGS_FAILED: {
      return {
        ...state,
        get_all_onboardings_pending: false,
        get_all_onboardings_error: action.payload,
        onboardings: new PaginationDTO()
      };
    }
    case OnboardStore.ActionTypes.GET_ONBOARDING: {
      return {
        ...state,
        get_onboarding_pending: true,
        get_onboarding_error: null
      };
    }
    case OnboardStore.ActionTypes.GET_ONBOARDING_SUCCESS: {
      return {
        ...state,
        get_onboarding_pending: false,
        onboarding: action.payload
      };
    }
    case OnboardStore.ActionTypes.GET_ONBOARDING_FAILED: {
      return {
        ...state,
        get_onboarding_pending: false,
        get_onboarding_error: action.payload,
        onboarding: null
      };
    }
    case OnboardStore.ActionTypes.UPDATE_ONBOARDING: {
      return {
        ...state,
        update_onboarding_pending: true,
        update_onboarding_error: null
      };
    }
    case OnboardStore.ActionTypes.UPDATE_ONBOARDING_SUCCESS: {
      return {
        ...state,
        update_onboarding_pending: false,
        onboarding: action.payload
      };
    }
    case OnboardStore.ActionTypes.UPDATE_ONBOARDING_FAILED: {
      return {
        ...state,
        update_onboarding_pending: false,
        update_onboarding_error: action.payload
      };
    }
    case OnboardStore.ActionTypes.DELETE_ONBOARDING: {
      return {
        ...state,
        delete_onboarding_pending: true,
        delete_onboarding_error: null
      };
    }
    case OnboardStore.ActionTypes.DELETE_ONBOARDING_SUCCESS: {
      return {
        ...state,
        delete_onboarding_pending: false
      };
    }
    case OnboardStore.ActionTypes.DELETE_ONBOARDING_FAILED: {
      return {
        ...state,
        delete_onboarding_pending: false,
        delete_onboarding_error: action.payload
      };
    }
    case OnboardStore.ActionTypes.CREATE_PARTNER_FROM_ONBOARDING: {
      return {
        ...state,
        create_onboarding_partner_pending: true,
        create_onboarding_partner_error: null
      };
    }
    case OnboardStore.ActionTypes.CREATE_PARTNER_FROM_ONBOARDING_SUCCESS: {
      return {
        ...state,
        create_onboarding_partner_pending: false,
        onboarding: action.payload
      };
    }
    case OnboardStore.ActionTypes.CREATE_PARTNER_FROM_ONBOARDING_FAILED: {
      return {
        ...state,
        create_onboarding_partner_pending: false,
        create_onboarding_partner_error: action.payload
      };
    }
    case OnboardStore.ActionTypes.CREATE_PLACE_FROM_ONBOARDING: {
      return {
        ...state,
        create_onboarding_place_pending: true,
        create_onboarding_place_error: null
      };
    }
    case OnboardStore.ActionTypes.CREATE_PLACE_FROM_ONBOARDING_SUCCESS: {
      return {
        ...state,
        create_onboarding_place_pending: false,
        onboarding: action.payload
      };
    }
    case OnboardStore.ActionTypes.CREATE_PLACE_FROM_ONBOARDING_FAILED: {
      return {
        ...state,
        create_onboarding_place_pending: false,
        create_onboarding_place_error: action.payload
      };
    }
    case OnboardStore.ActionTypes.CREATE_USER_FROM_ONBOARDING: {
      return {
        ...state,
        create_onboarding_user_pending: true,
        create_onboarding_user_error: null
      };
    }
    case OnboardStore.ActionTypes.CREATE_USER_FROM_ONBOARDING_SUCCESS: {
      return {
        ...state,
        create_onboarding_user_pending: false,
        onboarding: action.payload
      };
    }
    case OnboardStore.ActionTypes.CREATE_USER_FROM_ONBOARDING_FAILED: {
      return {
        ...state,
        create_onboarding_user_pending: false,
        create_onboarding_user_error: action.payload
      };
    }
    case OnboardStore.ActionTypes.ACTIVATE_PLACE_FROM_ONBOARDING: {
      return {
        ...state,
        activate_onboarding_place_pending: true,
        activate_onboarding_place_error: null
      };
    }
    case OnboardStore.ActionTypes.ACTIVATE_PLACE_FROM_ONBOARDING_SUCCESS: {
      return {
        ...state,
        activate_onboarding_place_pending: false,
        onboarding: action.payload
      };
    }
    case OnboardStore.ActionTypes.ACTIVATE_PLACE_FROM_ONBOARDING_FAILED: {
      return {
        ...state,
        activate_onboarding_place_pending: false,
        activate_onboarding_place_error: action.payload
      };
    }
    case OnboardStore.ActionTypes.SEND_EMAIL_TO_PARTNER: {
      return {
        ...state,
        send_email_to_partner_pending: true,
        send_email_to_partner_error: null
      };
    }
    case OnboardStore.ActionTypes.SEND_EMAIL_TO_PARTNER_SUCCESS: {
      return {
        ...state,
        send_email_to_partner_pending: false,
        onboarding: action.payload
      };
    }
    case OnboardStore.ActionTypes.SEND_EMAIL_TO_PARTNER_FAILED: {
      return {
        ...state,
        send_email_to_partner_pending: false,
        send_email_to_partner_error: action.payload
      };
    }
    case OnboardStore.ActionTypes.GET_ONBOARDING_FLAGS: {
      return {
        ...state,
        onboarding_flags_pending: true,
        onboarding_flags_error: null
      };
    }
    case OnboardStore.ActionTypes.GET_ONBOARDING_FLAGS_SUCCESS: {
      return {
        ...state,
        onboarding_flags_pending: false,
        alternative_address_flag: action.payload.alternativeAddress
      };
    }
    case OnboardStore.ActionTypes.GET_ONBOARDING_FLAGS_FAILED: {
      return {
        ...state,
        onboarding_flags_pending: false,
        onboarding_flags_error: action.payload
      };
    }
    default:
      return state;
  }
}

export default onboardReducer;
