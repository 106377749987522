import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { AppStore, PackagesStore } from '../../../appRedux';
import { CardComponent } from '../../shared';
import { msg } from '../../../utils';
import { EditPackageForm } from './EditPackageForm';
import { IPackage } from '../../../appRedux/types';

interface IEditPackageStateProps {
  packageDetails: IPackage | null;
  pending: boolean;
}
interface IEditPackageActionsProps {
  getPackageAction: (packageId: string) => void;
  editPackageAction: (packageId: string, newPackage: IPackage) => void;
}
type IEditPackageProps = IEditPackageStateProps & IEditPackageActionsProps & RouteComponentProps<{ packageId: string }>;
export const EditPackage = (props: IEditPackageProps) => {
  const packageId = props.match.params.packageId;
  const { packageDetails, pending } = props;
  const { getPackageAction, editPackageAction } = props;

  useEffect(() => {
    getPackageAction(packageId);
    // tslint:disable-next-line: align
  }, [packageId, getPackageAction]);

  const onSave = (newPackage: IPackage) => {
    editPackageAction(packageId, newPackage);
  };

  return (
    <CardComponent title={msg('cardTitle.editPackage', 'Edit package')} headerIcon="card_giftcard" needsTitle={true}>
      {pending ? (
        <span>Loading</span>
      ) : !packageDetails ? null : (
        <EditPackageForm packageDetails={packageDetails} onSave={onSave} />
      )}
    </CardComponent>
  );
};

const mapStateToProps = (state: AppStore.states): IEditPackageStateProps => ({
  packageDetails: state.packages.package_details,
  pending: state.packages.package_details_pending
});
const dispatchToProps: IEditPackageActionsProps = {
  getPackageAction: PackagesStore.actions.getPackageAction,
  editPackageAction: PackagesStore.actions.editPackageAction
};

export const EditPackageContainer = withRouter(connect(mapStateToProps, dispatchToProps)(EditPackage));
