import classnames from 'classnames';
import * as React from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import Lock from '@material-ui/icons/Lock';

import { colors } from '../../../../../assets/styles';
import { IValidator, msg, Validator } from '../../../../../utils';
import { ButtonComponent, TextFieldComponent } from '../../../../shared';
import styles from '../styles/Auth.module.css';

interface IState {
  showCard: boolean;
  headerUp: boolean;
  visible: boolean;
  showNewPassword: boolean;
  showRepeatPassword: boolean;
  message: {
    newPasswordInput: string;
    repeatPasswordInput: string;
  };
  passwords: {
    newPasswordInput: string;
    repeatPasswordInput: string;
  };
}

interface IProps {
  match: any;
  resetPasswordAction: any;
}

class ResetPasswordComponent extends React.Component<IProps & IValidator, IState> {
  newPasswordInput: HTMLInputElement;
  repeatPasswordInput: HTMLInputElement;

  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      showCard: false,
      headerUp: false,
      visible: false,
      showNewPassword: false,
      showRepeatPassword: false,
      message: {
        newPasswordInput: '',
        repeatPasswordInput: ''
      },
      passwords: {
        newPasswordInput: '',
        repeatPasswordInput: ''
      }
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showCard: true });
      // tslint:disable-next-line:align
    }, 800);
    setTimeout(() => {
      this.setState({ visible: true });
      // tslint:disable-next-line:align
    }, 1000);
  }

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showNewPassword: !state.showNewPassword }));
  };

  handleClickShowRepeatPassword = () => {
    this.setState(state => ({ showRepeatPassword: !state.showRepeatPassword }));
  };

  handleChange = (event: any) => {
    const fieldKey = event.target.getAttribute('id');
    const { passwords } = this.state;
    passwords[fieldKey] = event.target.value;
    this.setState({ passwords });
  };

  isValid = () => {
    let ok = true;
    const message = this.state.message;
    let keysObjectChangePassword: any = {
      newPasswordInput: '',
      repeatPasswordInput: ''
    };
    const validationKeysUser = Object.getOwnPropertyNames(keysObjectChangePassword);
    Object.keys(this).forEach((key, index) => {
      if (validationKeysUser.includes(key)) {
        if (this[key].value === '' || this[key].value === null) {
          message[key] = msg('formValidation.fieldRequired', 'Field required!');
          ok = false;
        }
      }
    });
    this.setState({ message: message });
    return ok;
  };

  resetPasswordHandler = () => {
    const { isValid } = this.props.validator!;
    if (isValid()) {
      this.props.resetPasswordAction(this.props.match.params.token, this.state.passwords.newPasswordInput);
    }
  };

  triggerChangePasswordByEnterPress = (event: any) => {
    let e = event || window.event;
    if (e.keyCode === 13) {
      this.resetPasswordHandler();
    }
  };

  render() {
    let cardClasses = classnames(`card card-login ${styles.card}`, { 'card-hidden': !this.state.showCard });
    let headerClasses = classnames(`card-header text-center ${styles.header}`, {
      [styles.up]: this.state.headerUp
    });
    const { errors } = this.props.validator!;

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form>
              <div className={cardClasses}>
                <div className={headerClasses} data-background-color="green">
                  <h4 className="card-title">{msg('passwords.setNewPassword', 'Set new password')}</h4>
                </div>
                <div className={`card-content ${styles.content}`} style={{ paddingBottom: 0 }}>
                  <div className="card-actions" style={{ top: -29, color: colors.red }}>
                    {msg('errors.errorOccured', 'An error has occured, please try again!')}
                  </div>
                  <div className="form-group" style={{ marginLeft: 20, marginTop: -5 }}>
                    <TextFieldComponent
                      label={msg('passwords.newPassword', 'New password')}
                      id="newPasswordInput"
                      inType={this.state.showNewPassword ? 'text' : 'password'}
                      onKeyUp={(event: any) => this.triggerChangePasswordByEnterPress(event)}
                      onChange={this.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      }
                      formatError={errors.newPassword}
                      validator={[
                        {
                          type: 'equals',
                          msg: msg('formValidation.passwordsMismatch', 'Passwords are not the same!'),
                          params: `${this.state.passwords.repeatPasswordInput}`
                        }
                      ]}
                      value={this.state.passwords.newPasswordInput}
                      required={true}
                      ref="newPassword"
                    />
                  </div>
                  <div className="form-group" style={{ marginLeft: 20, marginTop: -5 }}>
                    <TextFieldComponent
                      label={msg('passwords.confirmPassword', 'Confirm password')}
                      id="repeatPasswordInput"
                      inType={this.state.showRepeatPassword ? 'text' : 'password'}
                      onKeyUp={(event: any) => this.triggerChangePasswordByEnterPress(event)}
                      onChange={this.handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      }
                      formatError={errors.newPassword}
                      value={this.state.passwords.repeatPasswordInput}
                      required={true}
                    />
                  </div>
                </div>
                <div className="footer text-center">
                  <ButtonComponent
                    action={this.resetPasswordHandler}
                    label={msg('passwords.changePassword', 'Change password')}
                    icon="swap_horiz"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Validator(ResetPasswordComponent);
