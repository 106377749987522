import * as React from 'react';

import { AppStore, IndustriesStore, reduxContainer } from '../../../../appRedux';
import { AdvancedListComponent, CardComponent } from '../../../shared';
import { AlertUtil, msg } from '../../../../utils';
import { PaginationDTO } from '../../../../appRedux/types';

interface IProps extends IndustriesStore.IState {}
interface IState {}

class IndustriesListComponent extends React.Component<IProps & IndustriesStore.IActions, IState> {
  isData() {
    return (
      this.props.industries_list && this.props.industries_list.results && this.props.industries_list.results.length
    );
  }

  parseIndustries(list: PaginationDTO<any>): any {
    if (!this.isData()) {
      return [];
    }
    return {
      ...list,
      results: list.results.map(item => {
        return {
          name: item.name,
          placeTypes: item.placeTypes
            .map(function(k: any) {
              return k.name;
            })
            .join(', '),
          autopilotSchema: item.autopilotSchema,
          isActive: item.isActive,
          _id: item._id
        };
      })
    };
  }

  editAction = (event: any) => {
    this.props.redirectToEditAction(event.currentTarget.getAttribute('id'));
  };

  deleteAction = async (event: any) => {
    const id = event.currentTarget.getAttribute('id');
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.industryDeleteConfirmation', 'You are about to delete an industry!')
    );
    if (hasConfirmation) {
      this.props.deleteIndustryAction(id);
    }
  };

  handleChangeStatus = (itemId: string) => (event: any) => {
    this.props.changeIndustryStatus({ _id: itemId, isActive: event.target.checked });
  };

  getActions() {
    return [
      {
        label: msg('general.edit', 'Edit'),
        btn: 'btn-success',
        icon: 'edit',
        onClick: this.editAction
      },
      {
        label: msg('general.delete', 'Delete'),
        btn: 'btn-danger',
        icon: 'delete',
        ref: 'deleteIndustry',
        onClick: this.deleteAction
      }
    ];
  }

  render() {
    const industries = this.parseIndustries(this.props.industries_list);
    return (
      <CardComponent
        title={msg('sidebar.industries', 'Industries')}
        error={!!this.props.industries_list_error}
        headerIcon="list"
        needsTitle={true}
      >
        <AdvancedListComponent
          fields={{
            name: msg('industry.name', 'Name'),
            placeTypes: msg('industry.subindustries', 'Subindustries'),
            autopilotSchema: msg('industry.autopilots', 'Autopilots')
          }}
          actions={this.getActions()}
          list={industries}
          pending={this.props.industries_list_pending}
          error={this.props.industries_list_error}
          get={this.props.getIndustriesListAction}
          limit={30}
          sort={{
            fields: ['name'],
            default: 'name,1'
          }}
          search={['name']}
          toggles={[{ name: msg('place.isActive', 'Active'), onChange: this.handleChangeStatus, field: 'isActive' }]}
        />
      </CardComponent>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    industries_list: state.industries.industries_list,
    industries_list_pending: state.industries.industries_list_pending,
    industries_list_error: state.industries.industries_list_error,
    industry_delete_pending: state.industries.industry_delete_pending,
    industry_delete_error: state.industries.industry_delete_error,
    industry_save_pending: state.industries.industry_save_pending
  };
}

const dispatchToProps = {
  getIndustriesListAction: IndustriesStore.actions.getIndustriesListAction,
  redirectToEditAction: IndustriesStore.actions.redirectToEditAction,
  deleteIndustryAction: IndustriesStore.actions.deleteIndustryAction,
  changeIndustryStatus: IndustriesStore.actions.changeIndustryStatus
};

export default reduxContainer(IndustriesListComponent, mapStateToProps, dispatchToProps);
