import { Server, AlertUtil, logger, composedCriteriaBuilder } from '../../utils';

import { IAutopilotEventsApiRequest, IAutopilotEventsApiResponse } from './api.definitions';

export const FilterAutopilotLogsData = (filter: IAutopilotEventsApiRequest) => (dispatch: any) => {
  dispatch(AutopilotLogsDataFilteredStarted(filter));
  let url = `admin//autopilot-logs?limit=${filter.limit}`;
  if (filter.skip) {
    url += `&skip=${filter.skip}`;
  }
  if (filter.sort) {
    url += `&sort=${filter.sort}`;
  }
  if (filter.criteria) {
    url += composedCriteriaBuilder(filter.criteria);
  }
  Server.get(url)
    .then((response: any) => {
      dispatch(AutopilotLogsDataFilteredDone(response.data));
    })
    .catch(error => {
      logger.err('Filter autopilot logs data, route:/admin/autopilot-logs', 'GET');
      AlertUtil.updateContent('Due to an error, the autopilot log list cannot be loaded', 'error');

      return dispatch(AutopilotLogsDataFilteredError(Server.errorParse(error)));
    });
};

export const AUTOPILOT_LOGS_DATA_FILTERED_STARTED = 'autopilot-logs/data-filtered-started';

export interface IAutopilotLogsDataFilteredStarted extends IAction<IAutopilotEventsApiRequest> {}

export const AutopilotLogsDataFilteredStarted = (payload: IAutopilotEventsApiRequest) => ({
  type: AUTOPILOT_LOGS_DATA_FILTERED_STARTED,
  payload
});

export const AUTOPILOT_LOGS_DATA_FILTERED_DONE = 'autopilot-logs/data-filtered-done';

export interface IAutopilotLogsDataFilteredDone extends IAction<IAutopilotEventsApiResponse> {}

export const AutopilotLogsDataFilteredDone = (payload: IAutopilotEventsApiResponse) => ({
  type: AUTOPILOT_LOGS_DATA_FILTERED_DONE,
  payload
});

export const AUTOPILOT_LOGS_DATA_FILTERED_ERROR = 'autopilot-logs/data-filtered-error';

export interface IAutopilotLogsDataFilteredError extends IAction<any> {}

export const AutopilotLogsDataFilteredError = (payload: any) => ({
  type: AUTOPILOT_LOGS_DATA_FILTERED_ERROR,
  payload
});

export type TAutopilotLogsActions =
  | IAutopilotLogsDataFilteredStarted
  | IAutopilotLogsDataFilteredDone
  | IAutopilotLogsDataFilteredError;
