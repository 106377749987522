import { isEqual } from 'lodash';
import React, { ChangeEvent } from 'react';
import Toggle from 'react-toggle';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import {
    FeatureFlagsDTO, PlaceDTO, TipsType, TransactionSettingsDTO, UserDTO
} from '../../../../../appRedux/types';
import { colors } from '../../../../../assets/styles';
import {
    AlertUtil, EMAIL_VALIDATION_REGEX, IValidator, msg, msgHTML, Validator
} from '../../../../../utils';
import {
    ButtonComponent, CardComponent, MultipleSelectComponent, TextFieldComponent
} from '../../../../shared';

interface IProps {
  forSAdmin: boolean;
  place: PlaceDTO | null;
  place_admins_list: Array<UserDTO>;
  getAdminsForPlaceAction: (placeId: string, includePartner?: boolean) => any;
  save?: (settings: any) => void;
}

interface IState {
  featureFlags: FeatureFlagsDTO;
  transactionSettings: TransactionSettingsDTO;
  isDirty: boolean;
}

class PaymentsSettingsComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      featureFlags: { ...props.place?.featureFlags } || new FeatureFlagsDTO(),
      transactionSettings: props.place?.transactionSettings
        ? this.parseInitialSettings()
        : new TransactionSettingsDTO(),
      isDirty: false
    };
  }

  componentDidMount() {
    if (this.props.place?._id) {
      this.props.getAdminsForPlaceAction(this.props.place._id, this.props.forSAdmin);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.place?.featureFlags && !isEqual(this.props.place?.featureFlags, prevProps.place?.featureFlags)) {
      this.setState({ featureFlags: { ...this.props.place?.featureFlags } });
    }
  }

  parseInitialSettings = () => {
    const initialTransactionSettings = this.props.place?.transactionSettings || new TransactionSettingsDTO();
    const tipValues =
      initialTransactionSettings.tipsValues?.length && initialTransactionSettings?.tipsType === TipsType.PERCENT
        ? initialTransactionSettings.tipsValues
        : initialTransactionSettings.tipsValues?.length
        ? initialTransactionSettings.tipsValues.map(value => Number(value) / 100)
        : [];
    return {
      ...initialTransactionSettings,
      tipsValues: tipValues,
      feePerTransactionAmount: (initialTransactionSettings.feePerTransactionAmount || 0) / 100,
      feePerSettlement: (initialTransactionSettings.feePerSettlement || 0) / 100
    };
  };

  handleChangeFlag = (event: ChangeEvent<HTMLInputElement>) => {
    const fieldKey = event.target.getAttribute('id') || '';
    const fieldValue = event.target.checked;
    const featureFlags = this.state.featureFlags;
    featureFlags[fieldKey] = fieldValue;
    if (fieldKey === 'transactionsWithCoins' && !fieldValue) {
      const { isDirty } = this.props.validator!;
      isDirty('maxDivisionCoinsRule');
      isDirty('ronToCoinsRule');
    } else if (fieldKey === 'transactions' && !fieldValue) {
      featureFlags.transactionsWithCoins = false;
      featureFlags.tips = false;
    }
    this.setState({ featureFlags, isDirty: true });
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const transactionSettings = this.state.transactionSettings;
    const fieldKey = event.target.getAttribute('id') || '';
    const fieldValue = event.target.value;
    const regex = /^\d+(\.\d{0,2})?$/;
    if (!fieldValue || regex.test(fieldValue)) {
      const { isDirty } = this.props.validator!;
      isDirty(fieldKey);
      transactionSettings[fieldKey] = fieldValue;
    }
    this.setState({ transactionSettings, isDirty: true });
  };

  handleChangeString = (event: ChangeEvent<HTMLInputElement>) => {
    const transactionSettings = this.state.transactionSettings;
    transactionSettings[event.target.getAttribute('id') || ''] = event.target.value;
    this.setState({ transactionSettings, isDirty: true });
  };

  handleChangeTipsType = (event: ChangeEvent<HTMLInputElement>) => {
    const transactionSettings = this.state.transactionSettings;
    transactionSettings.tipsType = Number(event.target.value);
    this.setState({ transactionSettings, isDirty: true });
  };

  handleChangeTipsValues = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const transactionSettings = this.state.transactionSettings;
    if (!transactionSettings.tipsValues) {
      transactionSettings.tipsValues = [];
    }
    const regex = /^\d+(\.\d{0,2})?$/;
    if (!event.target.value || regex.test(event.target.value)) {
      const { isDirty } = this.props.validator!;
      isDirty(`tipsValues${index}`);
      transactionSettings.tipsValues[index] = event.target.value;
    }
    this.setState({ transactionSettings, isDirty: true });
  };

  parseAdminsForEmails = (admins: UserDTO[]) => {
    return admins ? admins.map(item => ({ label: item.email, value: item.email })) : [];
  };

  handleEmails = (newValues: { label: string; value: string; __isNew__?: boolean }[]) => {
    const newEmails = newValues ? newValues.map(item => item.label?.toLowerCase().trim()) : [];
    this.setState(prevState => ({
      transactionSettings: { ...prevState.transactionSettings, reportEmails: newEmails },
      isDirty: true
    }));
  };

  checkValidOption = (input: string) => {
    return !!input.match(EMAIL_VALIDATION_REGEX);
  };

  handleSave = () => {
    if (this.state.featureFlags.tips && !this.state.transactionSettings.tipsType?.toString()) {
      AlertUtil.simple(msg('placeSettings.noTipsTypeWarning', 'You must choose a type for the tips!'), 'warning');
      return;
    }
    if (this.state.featureFlags.transactionReports && !this.state.transactionSettings.reportEmails?.length) {
      AlertUtil.simple(
        msg('placeSettings.noEmailsWarning', 'You must select at least an email address for reports!'),
        'warning'
      );
      return;
    }
    const { isValid } = this.props.validator!;
    if (isValid()) {
      const currentTransactionSettings = this.state.transactionSettings;
      const newTransactionSettings = {
        ...currentTransactionSettings,
        tipsValues: currentTransactionSettings.tipsValues?.length
          ? currentTransactionSettings.tipsValues?.map(value =>
              this.state.transactionSettings.tipsType === TipsType.PERCENT ? Number(value) : Number(value) * 100
            )
          : undefined,
        feePerTransactionAmount: Number(currentTransactionSettings.feePerTransactionAmount) * 100,
        feePerSettlement: Number(currentTransactionSettings.feePerSettlement) * 100
      };
      const { tabletFeatureFlags } = this.props.place || {};
      if (tabletFeatureFlags?.transactions && !this.state.featureFlags.transactions) {
        tabletFeatureFlags.transactions = false;
      }
      const sendData = {
        _id: this.props.place?._id,
        featureFlags: this.state.featureFlags,
        tabletFeatureFlags,
        transactionSettings: newTransactionSettings
      };
      if (this.props.save) {
        this.props.save(sendData);
        this.setState({ isDirty: false });
      }
    }
  };

  renderEmailReports = () => {
    const { featureFlags, transactionSettings } = this.state;
    return (
      <>
        <legend style={{ marginTop: 15, marginBottom: 15 }}>
          {msg('placeSettings.emailReports', 'Email reports')}:
        </legend>
        <div style={styles.row}>
          <div>{msg('placeSettings.reportEmails', 'Emails to send reports to')}:</div>
          <div className="col-sm-6">
            <MultipleSelectComponent
              label="emails"
              options={this.parseAdminsForEmails(this.props.place_admins_list)}
              id="emails"
              value={transactionSettings.reportEmails?.map(item => ({ label: item, value: item }))}
              onChange={this.handleEmails}
              maxToShow={10}
              selectProps={{
                menuPortalTarget: document.body,
                menuPosition: 'fixed',
                isValidNewOption: this.checkValidOption
              }}
              isCreatable={true}
            />
          </div>
        </div>
        <div style={styles.row}>
          <div>{msg('featureFlags.transactionReports', 'Payment email reports')}:</div>
          <div style={styles.text}>
            <Toggle
              id="transactionReports"
              onChange={this.handleChangeFlag}
              checked={featureFlags.transactionReports}
            />
          </div>
        </div>
      </>
    );
  };

  renderCompanyData = () => {
    const { transactionSettings } = this.state;
    const { errors } = this.props.validator!;
    const fields = ['iban', 'bic', 'cui', 'regCom', 'companyName'];
    return (
      <>
        <legend style={{ marginTop: 15, marginBottom: 10, fontSize: 18 }}>
          {msg('placeSettings.companyData', 'Company data:')}
        </legend>
        {fields.map(field => (
          <div key={field} style={styles.row}>
            <div className="col-lg-5 col-md-8 col-xs-12" style={{ padding: 0 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ whiteSpace: 'nowrap' }}>{msg(`placeSettings.${field}`, `${field}:`)}</div>
                <TextFieldComponent
                  id={field}
                  value={(transactionSettings[field] ?? '') + ''}
                  onChange={this.handleChangeString}
                  minLength={1}
                  formatError={errors[field]}
                  ref={field}
                  style={{ marginLeft: 10 }}
                  // validator={
                  //   featureFlags.transactions && field !== 'regCom'
                  //     ? [
                  //         {
                  //           type: 'isLength',
                  //           msg: msg('formValidation.fieldRequired', 'Field required!'),
                  //           params: { min: 1 }
                  //         }
                  //       ]
                  //     : []
                  // }
                />
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  renderSaveButton = () => (
    <div style={{ margin: 10 }}>
      <div className="col-md-3" />
      <div className="col-md-6" style={{ textAlign: 'center' }}>
        <div className="form-group form-button">
          <ButtonComponent
            label={msg('general.save', 'Save')}
            icon="save"
            action={this.handleSave}
            disabled={!this.state.isDirty}
          />
        </div>
      </div>
      <div className="col-md-3" />
    </div>
  );

  render() {
    const { errors } = this.props.validator!;
    const { featureFlags, transactionSettings } = this.state;
    return (
      <CardComponent
        title={msg('placeSettings.paymentsSettings', 'Payments settings')}
        headerIcon="credit_card"
        needsTitle={true}
      >
        {this.props.forSAdmin ? (
          <>
            {this.state.featureFlags.transactions && !this.props.place?.tabletFeatureFlags?.transactions && (
              <div style={{ marginBottom: 10, color: colors.red }}>
                {msg(
                  `placeSettings.activateTabletFlag`,
                  'You need to enable transactions feature flag for TABLET in order to create transactions!'
                )}
              </div>
            )}
            <legend style={{ marginTop: 5, marginBottom: 15 }}>Feature flags:</legend>
            <div style={styles.row}>
              <div>{msg('featureFlags.transactions', 'Transactions')}:</div>
              <div style={{ marginLeft: 10 }}>
                <Toggle id="transactions" onChange={this.handleChangeFlag} checked={featureFlags.transactions} />
              </div>
            </div>
            <div style={styles.row}>
              <div>{msg('featureFlags.transactionsWithCoins', 'transactionsWithCoins')}:</div>
              <div style={{ marginLeft: 10 }}>
                <Toggle
                  id="transactionsWithCoins"
                  onChange={this.handleChangeFlag}
                  checked={featureFlags.transactionsWithCoins}
                  disabled={!featureFlags.transactions}
                />
              </div>
            </div>
            <legend style={{ marginTop: 20, marginBottom: 0 }}>{msg('placeSettings.settings', 'Settings')}:</legend>
            <div style={styles.row}>
              <div>{msg('placeSettings.maxDivisionCoinsRule', 'Maximum percent to pay with coins')}</div>
              <TextFieldComponent
                id="maxDivisionCoinsRule"
                inType="text"
                style={styles.input}
                value={transactionSettings?.maxDivisionCoinsRule?.toString() || ''}
                onChange={this.handleChange}
                maxLength={3}
                formatError={errors.maxDivisionCoinsRule}
                ref="maxDivisionCoinsRule"
                validator={
                  featureFlags.transactionsWithCoins
                    ? [
                        {
                          type: 'isInt',
                          msg: msg('formValidation.onlyPositiveNumbers', 'Use only positive numbers!'),
                          params: { min: 1, max: 100 }
                        },
                        {
                          type: 'isInt',
                          msg: msg('formValidation.maxPercentage', 'Maximum percent is 100'),
                          params: { max: 100 }
                        }
                      ]
                    : []
                }
              />
              %.
            </div>
            <div style={styles.row}>
              <div>{msg('placeSettings.coinsToRonRule', 'Coin to ron rule')}</div>
              <TextFieldComponent
                id="ronToCoinsRule"
                inType="text"
                style={styles.input}
                value={transactionSettings?.ronToCoinsRule?.toString() || ''}
                onChange={this.handleChange}
                formatError={errors.ronToCoinsRule}
                ref="ronToCoinsRule"
                validator={
                  featureFlags.transactionsWithCoins
                    ? [
                        {
                          type: 'isInt',
                          msg: msg('formValidation.onlyPositiveNumbers', 'Use only positive numbers!'),
                          params: { min: featureFlags.transactionsWithCoins ? 1 : 0 }
                        }
                      ]
                    : []
                }
              />
              bani. (
              {msg(
                'placeSettings.coinsToRonRuleExample',
                `1 coin = ${transactionSettings?.ronToCoinsRule / 100 || 0}`,
                {
                  amount: transactionSettings?.ronToCoinsRule > 0 ? transactionSettings?.ronToCoinsRule / 100 + '' : '0'
                }
              )}
              )
            </div>
            <div style={styles.row}>
              <div>{msgHTML('placeSettings.paymentConversionRule', 'Payment amount to coins convert ratio:')}</div>
              <TextFieldComponent
                id="convertToCoinsRule"
                inType="text"
                value={transactionSettings?.convertToCoinsRule?.toString() || ''}
                onChange={this.handleChange}
                minLength={1}
                style={styles.input}
                formatError={errors.convertToCoinsRule}
                ref="convertToCoinsRule"
                validator={
                  featureFlags?.useCoins
                    ? [
                        {
                          type: 'isInt',
                          msg: msg('formValidation.onlyPositiveNumbers', 'Use only positive numbers!'),
                          params: { min: 0 }
                        }
                      ]
                    : []
                }
              />
              <div>
                %.{' '}
                {msgHTML(
                  'placeSettings.paymentConversionExample',
                  `For payments of 100 RON, users will receive ${transactionSettings?.convertToCoinsRule} coins`,
                  {
                    amount:
                      transactionSettings?.convertToCoinsRule > 0 ? transactionSettings?.convertToCoinsRule + '' : '0'
                  }
                )}
              </div>
            </div>
            <legend style={{ marginTop: 15, marginBottom: 15 }}>{msg('placeSettings.tips', 'Tips')}:</legend>
            <div style={styles.row}>
              <div>{msg('featureFlags.tips', 'Tips')}:</div>
              <div style={{ marginLeft: 10 }}>
                <Toggle
                  id="tips"
                  onChange={this.handleChangeFlag}
                  checked={featureFlags.tips}
                  disabled={!featureFlags.transactions}
                />
              </div>
            </div>
            <div style={styles.row}>
              <div style={{ marginTop: 15, marginBottom: 10 }}>{msg('placeSettings.tipsType', 'Tips type:')}</div>
              <RadioGroup
                onChange={this.handleChangeTipsType}
                value={transactionSettings.tipsType?.toString() ? transactionSettings.tipsType : ''}
                row={true}
                style={{ display: 'flex', justifyContent: 'space-evenly', marginLeft: 20 }}
              >
                <FormControlLabel
                  disabled={!featureFlags.tips}
                  value={TipsType.AMOUNT}
                  control={<Radio color="primary" />}
                  label={msg('placeSettings.tipsAmount', 'Fixed amount')}
                />
                <FormControlLabel
                  disabled={!featureFlags.tips}
                  value={TipsType.PERCENT}
                  control={<Radio color="primary" />}
                  label={msg('placeSettings.tipsPercent', 'Percentage')}
                />
              </RadioGroup>
            </div>
            <div style={styles.row}>
              {msg('placeSettings.availableTips', 'The available amounts for this type of tips are')}
              <TextFieldComponent
                inType="text"
                value={
                  transactionSettings.tipsValues?.[0] !== undefined ? transactionSettings.tipsValues?.[0] + '' : ''
                }
                onChange={this.handleChangeTipsValues(0)}
                minLength={1}
                style={styles.input}
                formatError={errors.tipsValues0}
                ref="tipsValues0"
                validator={
                  featureFlags.tips
                    ? [
                        {
                          type: 'isLength',
                          msg: msg('formValidation.fieldRequired', 'Field required!'),
                          params: { min: 1 }
                        }
                      ]
                    : []
                }
              />
              ,
              <TextFieldComponent
                inType="text"
                value={
                  transactionSettings.tipsValues?.[1] !== undefined ? transactionSettings.tipsValues?.[1] + '' : ''
                }
                onChange={this.handleChangeTipsValues(1)}
                minLength={1}
                style={styles.input}
                formatError={errors.tipsValues1}
                ref="tipsValues1"
                validator={
                  featureFlags.tips
                    ? [
                        {
                          type: 'isLength',
                          msg: msg('formValidation.fieldRequired', 'Field required!'),
                          params: { min: 1 }
                        }
                      ]
                    : []
                }
              />
              {msg('placeSettings.and', ' and ')}
              <TextFieldComponent
                inType="text"
                value={
                  transactionSettings.tipsValues?.[2] !== undefined ? transactionSettings.tipsValues?.[2] + '' : ''
                }
                onChange={this.handleChangeTipsValues(2)}
                minLength={1}
                style={styles.input}
                formatError={errors.tipsValues2}
                ref="tipsValues2"
                validator={
                  featureFlags.tips
                    ? [
                        {
                          type: 'isLength',
                          msg: msg('formValidation.fieldRequired', 'Field required!'),
                          params: { min: 1 }
                        }
                      ]
                    : []
                }
              />
              {transactionSettings.tipsType === TipsType.PERCENT
                ? msg('placeSettings.paymentPercent', '% out of the paid amount.')
                : msg('placeSettings.lei', ' lei.')}
            </div>
            <legend style={{ marginTop: 15, marginBottom: 15 }}>
              {msg('placeSettings.settlements', 'Settlements')}:
            </legend>
            <div style={styles.row}>
              {msg('placeSettings.feesPerTransaction', 'The fee per transaction is')}
              <TextFieldComponent
                id="feePerTransactionAmount"
                value={
                  transactionSettings.feePerTransactionAmount !== undefined
                    ? transactionSettings.feePerTransactionAmount + ''
                    : ''
                }
                onChange={this.handleChange}
                minLength={1}
                style={styles.input}
                formatError={errors.feePerTransactionAmount}
                ref="feePerTransactionAmount"
                validator={
                  featureFlags.transactions
                    ? [
                        {
                          type: 'isLength',
                          msg: msg('formValidation.fieldRequired', 'Field required!'),
                          params: { min: 1 }
                        }
                      ]
                    : []
                }
              />
              {msg('placeSettings.andOr', 'RON and/or')}
              <TextFieldComponent
                id="feePerTransactionPercent"
                value={
                  transactionSettings.feePerTransactionPercent !== undefined
                    ? transactionSettings.feePerTransactionPercent + ''
                    : ''
                }
                onChange={this.handleChange}
                minLength={1}
                style={styles.input}
                formatError={errors.feePerTransactionPercent}
                ref="feePerTransactionPercent"
                validator={
                  featureFlags.transactions
                    ? [
                        {
                          type: 'isLength',
                          msg: msg('formValidation.fieldRequired', 'Field required!'),
                          params: { min: 1 }
                        }
                      ]
                    : []
                }
              />
              %.
            </div>
            <div style={styles.row}>
              {msg('placeSettings.feePerSettlement', 'The fee per settlement is')}
              <TextFieldComponent
                id="feePerSettlement"
                value={
                  transactionSettings.feePerSettlement !== undefined ? transactionSettings.feePerSettlement + '' : ''
                }
                onChange={this.handleChange}
                minLength={1}
                style={styles.input}
                formatError={errors.feePerSettlement}
                ref="feePerSettlement"
                validator={
                  featureFlags.transactions
                    ? [
                        {
                          type: 'isLength',
                          msg: msg('formValidation.fieldRequired', 'Field required!'),
                          params: { min: 1 }
                        }
                      ]
                    : []
                }
              />
              {msg('placeSettings.lei', ' RON.')}
            </div>
            <div style={styles.row}>
              {msg('placeSettings.settlementBuffer', 'The settlement interval is')}
              <TextFieldComponent
                id="settlementBuffer"
                value={(transactionSettings.settlementBuffer ?? '') + ''}
                onChange={this.handleChange}
                minLength={1}
                style={styles.input}
                formatError={errors.settlementBuffer}
                ref="settlementBuffer"
                validator={
                  featureFlags.transactions
                    ? [
                        {
                          type: 'isLength',
                          msg: msg('formValidation.fieldRequired', 'Field required!'),
                          params: { min: 0 }
                        }
                      ]
                    : []
                }
              />
              {msg('periodTypes.days', ' days')}.
            </div>
            {this.renderCompanyData()}
            {this.renderEmailReports()}
            {this.renderSaveButton()}
          </>
        ) : (
          <>
            <div style={styles.textMargin}>
              {msgHTML('placeSettings.paymentsActive', 'This place has payments enabled.')}
            </div>
            <div style={styles.text}>
              {featureFlags.transactionsWithCoins
                ? msgHTML('placeSettings.paymentsWithCoinsActive', 'This place has payments with coins enabled.')
                : msgHTML('placeSettings.paymentsWithCoinsInactive', 'This place has payments with coins disabled.')}
            </div>
            <div style={styles.textMargin}>
              {msgHTML(
                'placeSettings.maxDivisionCoinsRulePartner',
                `Maximum percent to pay with coins: ${transactionSettings?.maxDivisionCoinsRule || 0}%.`,
                {
                  amount: transactionSettings?.maxDivisionCoinsRule + '' || '0'
                }
              )}
            </div>
            <div style={styles.text}>
              {msgHTML(
                'placeSettings.coinsToRonRulePartner',
                `Coin to ron rule: ${transactionSettings?.ronToCoinsRule || 0} bani.`,
                {
                  amount: transactionSettings?.ronToCoinsRule + '' || '0'
                }
              )}{' '}
              (
              {msg(
                'placeSettings.coinsToRonRuleExample',
                `1 coin = ${transactionSettings?.ronToCoinsRule / 100 || 0}`,
                {
                  amount: transactionSettings?.ronToCoinsRule > 0 ? transactionSettings?.ronToCoinsRule / 100 + '' : '0'
                }
              )}
              )
            </div>
            <div style={styles.text}>
              {msgHTML(
                'placeSettings.paymentConversionRulePartner',
                `Payment amount to coins convert ratio: ${transactionSettings?.convertToCoinsRule || 0} %.`,
                {
                  amount: transactionSettings?.convertToCoinsRule + '' || '0'
                }
              )}{' '}
              {msgHTML(
                'placeSettings.paymentConversionExample',
                `For payments of 100 RON, users will receive ${transactionSettings?.convertToCoinsRule} coins`,
                {
                  amount:
                    transactionSettings?.convertToCoinsRule > 0 ? transactionSettings?.convertToCoinsRule + '' : '0'
                }
              )}
            </div>
            {featureFlags.tips && (
              <>
                <div style={styles.textMargin}>
                  {msgHTML('placeSettings.tipsActive', 'This place has tips enabled.')}
                </div>
                {transactionSettings.tipsValues?.length ? (
                  <div style={styles.text}>
                    {transactionSettings.tipsType === TipsType.AMOUNT
                      ? msg(
                          'placeSettings.availableAmount',
                          `The available amounts for this type of tips are ${transactionSettings.tipsValues?.join(
                            ', '
                          )} lei`,
                          { amount: transactionSettings.tipsValues?.join(', ') || '' }
                        )
                      : msg(
                          'placeSettings.availablePercent',
                          `The available amounts for this type of tips are ${transactionSettings.tipsValues?.join(
                            ', '
                          )}% out of the total paid amount.`,
                          { amount: transactionSettings.tipsValues?.join(', ') || '' }
                        )}
                  </div>
                ) : null}
              </>
            )}
            {transactionSettings.feePerTransactionAmount || transactionSettings.feePerTransactionPercent ? (
              <div style={styles.textMargin}>
                {msg(
                  'placeSettings.feePerTransactionReadonly',
                  `The fees per transaction are ${transactionSettings.feePerTransactionAmount || 0} RON and/or ${
                    transactionSettings.feePerTransactionPercent || 0
                  }%.`,
                  {
                    amount: transactionSettings.feePerTransactionAmount?.toString() || '0',
                    percent: transactionSettings.feePerTransactionPercent?.toString() || '0'
                  }
                )}
              </div>
            ) : null}
            {transactionSettings.feePerSettlement ? (
              <div style={styles.text}>
                {msg('placeSettings.feePerSettlement', 'The fee per settlement is')}{' '}
                {transactionSettings.feePerSettlement} {msg('placeSettings.lei', ' RON.')}
              </div>
            ) : null}
            <div style={styles.text}>
              {msg('placeSettings.settlementBuffer', 'The settlement interval is')}{' '}
              {transactionSettings.settlementBuffer} {msg('periodTypes.days', ' days')}.
            </div>
            {this.renderCompanyData()}
            {this.renderEmailReports()}
            {this.renderSaveButton()}
            <div className="row">
              <div className="col-md-6 category" style={styles.text}>
                <small>*</small>
                {msg(
                  'placeSettings.changePaymentsSupport',
                  'In order to change these settings you need to contact support.'
                )}
              </div>
            </div>
          </>
        )}
      </CardComponent>
    );
  }
}

export default Validator(PaymentsSettingsComponent);

const styles = {
  row: {
    display: 'flex',
    marginLeft: 10,
    alignItems: 'center',
    fontSize: 14
  },
  input: {
    width: 50,
    marginLeft: 8,
    marginRight: 8
  },
  text: {
    marginLeft: 10,
    padding: '5px 0'
  },
  textMargin: {
    margin: '15px 0 5px 10px'
  }
};
