import { store } from '../appRedux';

class AuthUtil {
  /*
    Class that implements authentication utilitary functions
  */

  /*
    @function isPartnerAdmin => checks if an authenticating user is a partner admin
      @returns boolean => true if authenticating user is a partner admin, false if not
  */

  isPartnerAdmin = () => {
    const auth = store.getState().auth;
    const user = auth.user;
    return user && user.access && user.access.partners.length >= 1;
  };

  getPartnersForUser = () => {
    const auth = store.getState().auth;
    const user = auth.user;
    return user?.access?.partners || [];
  };

  isPartnerAdminForCurrentPlace = (partnerId: string, checkMultiplePlaces: boolean = true) => {
    const auth = store.getState().auth;
    const user = auth.user;
    const currentPartner = user?.access?.partners?.find(
      (item: { _id: string; name: string; places: { _id: string; name: string }[] }) => item._id === partnerId
    );
    return !!currentPartner && (!checkMultiplePlaces || currentPartner.places?.length >= 2);
  };

  /*
    @function isPlaceAdmin => checks if an authenticating user is a place admin
      @returns boolean => true if authenticating user is a place admin, false if not
  */

  isPlaceAdmin = () => {
    const auth = store.getState().auth;
    const user = auth.user;
    return user && user.access && user.access.places.length >= 1;
  };

  getPlacesForUser = () => {
    const auth = store.getState().auth;
    const user = auth.user;
    return user && user.access ? user.access.places : [];
  };

  /*
    @function isSuperAdmin => checks if an authenticating user is a super admin
      @accepts auth : object that contains the information of the authenticating user
      @returns boolean => true if authenticating user is a super admin, false if not
  */

  isSuperAdmin = () => {
    const auth = store.getState().auth;
    const user = auth.user;
    return user?.access?.isSAdmin;
  };

  isPaymentsAccount = () => {
    const auth = store.getState().auth;
    const user = auth.user;
    return user?.email === process.env.REACT_APP_PAYMENTS_EMAIL && user?.access?.isSAdmin;
  };

  isUserOwner = () => {
    const auth = store.getState().auth;
    const user = auth.user;
    return user?.access?.isOwner;
  };

  isPartnerOwner = (partnerId: string) => {
    const auth = store.getState().auth;
    const user = auth.user;
    return !!user?.access?.partners.find((partner: any) => partner._id === partnerId && partner.isOwner);
  };

  isSupport = () => {
    const auth = store.getState().auth;
    const user = auth.user;
    return user?.access?.isSupport;
  };

  isMobileUser = () => {
    const auth = store.getState().auth;
    const user = auth.user;
    const isSpecial = user?.access?.isSAdmin || user?.access?.isOwner || user?.access?.isSupport;
    const hasPlaces = user?.access?.partners.length || user?.access?.places.length;
    return !isSpecial && !hasPlaces;
  };
}

const authUtil = new AuthUtil();
export default authUtil;
