import moment from 'moment';
import { Dispatch } from 'redux';
import { ReceiptsStore } from '.';
import { ReceiptDTO, PaginationDTO } from '../types';
import { Server, AlertUtil, msg, logger, composedCriteriaBuilder } from '../../utils';
import { PlacesStore } from '..';

export interface IReceiptsActions {
  getReceiptListAction(
    isSAdmin: boolean,
    placeId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  getReceiptAction(placeId: string, receiptId: string): any;
  editReceiptAction(
    isSAdmin: boolean,
    placeId: string,
    receiptId: string,
    receipt: ReceiptDTO,
    showAlert?: boolean
  ): any;
  deleteReceiptAction(isSAdmin: boolean, placeId: string, receiptId: string, showAlert?: boolean): any;
  changeReceiptsAllowed(placeId: string, allowReceipts: boolean): any;
  exportReceiptsInExcel(placeId: string, startDate: number, endDate: number): any;
  clearPlaceReceiptsError(): any;
}

class ReceiptsActions implements IReceiptsActions {
  getReceiptListAction(
    isSAdmin: boolean,
    placeId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: ReceiptsStore.ActionTypes.GET_RECEIPT_LIST
      });
      if (!limit) {
        limit = 10;
      }
      let url;
      if (isSAdmin) {
        url = `admin/receipts?limit=${limit}`;
      } else {
        url = `places/${placeId}/receipts?limit=${limit}`;
      }
      if (skip) {
        url += `&skip=${skip}`;
      }
      if (sort) {
        url += `&sort=${sort}`;
      }
      if (criteria) {
        url += composedCriteriaBuilder(criteria);
      }
      logger.msg('Get receipts action, route:/places/placeId/receipts', 'GET');
      Server.get(url)
        .then((response: any) => {
          dispatch({
            type: ReceiptsStore.ActionTypes.GET_RECEIPT_LIST_SUCCESS,
            payload: response.data as PaginationDTO<ReceiptDTO>
          });
        })
        .catch(error => {
          logger.err('Get receipts action, route:/places/placeId/receipts', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.receipts.getReceiptsError', 'Due to an error, the receipt list could not be loaded!'),
            'error'
          );
          dispatch({
            type: ReceiptsStore.ActionTypes.GET_RECEIPT_LIST_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getReceiptAction(placeId: string, receiptId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: ReceiptsStore.ActionTypes.GET_RECEIPT
      });
      logger.msg('Get receipt action, route:/places/placeId/receipt/receiptId', 'GET');
      Server.get(`places/${placeId}/receipt/${receiptId}`)
        .then((response: any) => {
          dispatch({
            type: ReceiptsStore.ActionTypes.GET_RECEIPT_SUCCESS,
            payload: response.data as ReceiptDTO
          });
        })
        .catch(error => {
          logger.err('Get receipt action, route:/places/placeId/receipt/receiptId', 'GET');
          dispatch({
            type: ReceiptsStore.ActionTypes.GET_RECEIPT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  editReceiptAction(isSAdmin: boolean, placeId: string, receiptId: string, receipt: any, showAlert?: boolean) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: ReceiptsStore.ActionTypes.EDIT_RECEIPT
      });
      if (showAlert) {
        AlertUtil.simple(
          msg('reduxMessages.receipts.editReceiptPending', 'The receipt is being updated, please wait...'),
          'info'
        );
      }
      let url;
      if (isSAdmin) {
        url = `admin/receipts/${receiptId}`;
        logger.msg('Edit receipt action, route:/admin/receipts/receiptId', 'PUT');
      } else {
        url = `places/${placeId}/receipt/${receiptId}`;
        logger.msg('Edit receipt action, route:/places/placeId/receipt/receiptId', 'PUT');
      }
      Server.put(url, receipt)
        .then((response: any) => {
          dispatch({
            type: ReceiptsStore.ActionTypes.EDIT_RECEIPT_SUCCESS,
            payload: response.data as ReceiptDTO
          });
          if (showAlert) {
            AlertUtil.updateContent(
              msg('reduxMessages.receipts.editReceiptSuccess', 'The receipt was successfully updated!'),
              'success'
            );
          }
        })
        .catch(error => {
          switch (error.response?.data?.ERROR?.code) {
            case 'STATUS_CHANGED': {
              logger.err('Edit receipt action, route:/places/placeId/receipt/receiptId', 'PUT');
              AlertUtil.fireOnce(
                msg(
                  'reduxMessages.receipts.editReceiptErrorStatusChanged',
                  'The receipt status has already been updated from another device!'
                ),
                'error'
              );
              dispatch({
                type: ReceiptsStore.ActionTypes.EDIT_RECEIPT_FAILED_STATUS_CHANGED,
                payload: { parsedError: Server.errorParse(error), receipt: error.response?.data?.ERROR?.receipt }
              });
              break;
            }
            case 'NOT_FOUND': {
              AlertUtil.simple(
                msg(
                  'reduxMessages.receipts.editReceiptErrorMemberNotFound',
                  'The user is no longer a member of this place. The receipt will be deleted!'
                ),
                'error'
              );
              dispatch(ReceiptsStore.actions.deleteReceiptAction(isSAdmin, placeId, receiptId));
              break;
            }
            case 'RULE_NOT_FOUND': {
              AlertUtil.simple(
                msg(
                  'reduxMessages.receipts.editReceiptErrorRuleNotFound',
                  'Your location does not have a valid conversion rule. You can configure a rule in the location settings.'
                ),
                'error',
                3000
              );
              break;
            }
            default: {
              logger.err('Edit receipt action, route:/places/placeId/receipt/receiptId', 'PUT');
              AlertUtil.simple(
                msg('reduxMessages.receipts.editReceiptError', 'Due to an error, the receipt could not be updated!'),
                'error'
              );
              dispatch({
                type: ReceiptsStore.ActionTypes.EDIT_RECEIPT_FAILED,
                payload: Server.errorParse(error)
              });
            }
          }
        });
    };
  }

  deleteReceiptAction(isSAdmin: boolean, placeId: string, receiptId: string, showAlert?: boolean) {
    return (dispatch: Dispatch<any>) => {
      if (showAlert) {
        AlertUtil.simple(
          msg('reduxMessages.receipts.deleteReceiptPending', 'The receipt is being deleted, please wait...'),
          'info'
        );
      }
      dispatch({
        type: ReceiptsStore.ActionTypes.DELETE_RECEIPT
      });
      let url;
      if (isSAdmin) {
        url = `admin/receipts/${receiptId}`;
        logger.msg('Delete receipt action, route:/admin/receipts/receiptId', 'DELETE');
      } else {
        url = `places/${placeId}/receipt/${receiptId}`;
        logger.msg('Delete receipt action, route:/places/placeId/receipt/receiptId', 'DELETE');
      }
      Server.delete(url)
        .then((response: any) => {
          dispatch({
            type: ReceiptsStore.ActionTypes.DELETE_RECEIPT_SUCCESS,
            payload: response.data as ReceiptDTO
          });
          if (showAlert) {
            AlertUtil.updateContent(
              msg('reduxMessages.receipts.deleteReceiptSuccess', 'The receipt has been successfully deleted!'),
              'success'
            );
          }
        })
        .catch(error => {
          logger.err('Delete receipt action, route:/places/placeId/receipt/receiptId', 'DELETE');
          if (showAlert) {
            AlertUtil.updateContent(
              msg('reduxMessages.receipts.deleteReceiptError', 'Due to an error, the receipt could not be deleted!'),
              'error'
            );
          }
          dispatch({
            type: ReceiptsStore.ActionTypes.DELETE_RECEIPT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  changeReceiptsAllowed(placeId: string, allowReceipts: boolean) {
    return (dispatch: Dispatch<any>) => {
      logger.msg('Update receipts allowed action, route:/places/placeId/allow-receipts', 'PUT');
      AlertUtil.simple(
        msg('reduxMessages.receipts.changeStatusPending', 'The receipts status is being updated, please wait...'),
        'info'
      );
      Server.put(`places/${placeId}/allow-receipts`, { allowReceipts })
        .then((response: any) => {
          dispatch({
            type: ReceiptsStore.ActionTypes.ALLOW_RECEIPTS_SUCCESS,
            payload: response.data as boolean
          });
          dispatch(PlacesStore.actions.setPlaceAction(response.data));
          AlertUtil.updateContent(
            msg('reduxMessages.receipts.changeStatusSuccess', 'The receipts status was successfully updated!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Update receipts allowed action, route:/places/placeId/allow-receipts', 'PUT');
          AlertUtil.updateContent(
            msg(
              'reduxMessages.receipts.changeStatusError',
              'Due to an error, the receipts status could not be updated!'
            ),
            'error'
          );
          dispatch({
            type: ReceiptsStore.ActionTypes.ALLOW_RECEIPTS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  exportReceiptsInExcel(placeId: string, startDate: number, endDate: number) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: ReceiptsStore.ActionTypes.EXPORT_RECEIPTS
      });
      const startDateFormat = moment(startDate).format('DD-MM-YYYY');
      const endDateFormat = moment(endDate).format('DD-MM-YYYY');
      const name = `Report Receipts ${startDateFormat} - ${endDateFormat}.xlsx`;
      let url = `/places/${placeId}/report-receipts?startDate=${startDate}&endDate=${endDate}`;
      logger.msg('Export receipts in excel action, route:/places/placeId/report-receipts', 'GET');
      Server.downloadReports(url, name)
        .then((response: any) => {
          dispatch({
            type: ReceiptsStore.ActionTypes.EXPORT_RECEIPTS_SUCCESS,
            payload: response
          });
        })
        .catch(error => {
          logger.err('Export receipts in excel action, route:/places/placeId/report-receipts', 'GET');
          dispatch({
            type: ReceiptsStore.ActionTypes.EXPORT_RECEIPTS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  clearPlaceReceiptsError() {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: ReceiptsStore.ActionTypes.CLEAR_RECEIPTS_ERROR });
    };
  }
}

const receiptsActions = new ReceiptsActions();
export default receiptsActions;
