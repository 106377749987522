import React from 'react';

import { msg } from '../../../../../utils';

import mobilePhonePreview from '../../../../../assets/images/mobile-placeholder.png';
import { EventType } from '../../../../../appRedux/types';

function renderPreview(text: any) {
  return { __html: text };
}

function getTitle(event: any) {
  return event.visibleType === EventType.EVENT
    ? msg('eventTypes.eventTitle', 'Event title')
    : event.visibleType === EventType.NEWS
    ? msg('eventTypes.newsTitle', 'News title')
    : msg('eventTypes.promotionTitle', 'Promotion title');
}

export const EventPreview = (props: any) => {
  const { event, styles } = props;

  return (
    <div style={{ height: 540, width: 540, position: 'relative', margin: '10px -100px', zIndex: -1 }}>
      <img src={mobilePhonePreview} style={{ margin: 'auto', width: 530, height: 530, marginTop: -40 }} alt="" />
      <div className={styles.phonePreviewWrap}>
        <div>
          <div className={styles.phonePreviewTitle}>
            <i className="material-icons" style={{ position: 'absolute', left: 0, top: -2 }}>
              keyboard_arrow_left
            </i>
            {}
            {event.title && event.title !== '' ? event.title : getTitle(event)}
          </div>
        </div>
        <div dangerouslySetInnerHTML={renderPreview(event.content)} className={styles.phonePreviewContent} />
      </div>
    </div>
  );
};
