import { Dispatch } from 'redux';
import { push } from 'connected-react-router';

import { AnnouncementsStore } from '.';
import { Server, composedCriteriaBuilder, AlertUtil, msg, logger } from '../../utils';
import { PaginationDTO, AnnouncementDTO } from '../types';
import { generatePath } from 'react-router-dom';
import { adminRoutes } from '../../modules/layouts/routes';

/*
  IAnnouncementsActions interface definition, which contains every redux action asociated with Announcements State.
*/
export interface IAnnouncementsActions {
  getAnnouncementsAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }): any;
  addAnnouncementAction(announcement: AnnouncementDTO): any;
  getAnnouncementAction(announcementId: string): any;
  editAnnouncementAction(announcement: AnnouncementDTO): any;
  redirectToAnnouncementContent(announcementId?: string): any;
  deleteAnnouncementAction(announcementId: string): any;
}

/*
  class AnnouncementsActions that implements redux actions defined in IAnnouncementsActions interface
*/
class AnnouncementsActions implements IAnnouncementsActions {
  getAnnouncementsAction(limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: AnnouncementsStore.ActionTypes.GET_ANNOUNCEMENTS });
      try {
        if (!limit) {
          limit = 30;
        }
        let url = `announcements?limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get announcements action, route:/announcements', 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: AnnouncementsStore.ActionTypes.GET_ANNOUNCEMENTS_SUCCESS,
          payload: response.data as PaginationDTO<AnnouncementDTO>
        });
      } catch (error) {
        logger.err('Get announcements action, route:/announcements', 'GET');
        AlertUtil.updateContent(
          msg(
            'reduxMessages.announcements.getAnnouncementsError',
            'Due to an error, the announcements list could not be loaded!'
          ),
          'error'
        );
        dispatch({
          type: AnnouncementsStore.ActionTypes.GET_ANNOUNCEMENTS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  addAnnouncementAction(announcement: AnnouncementDTO) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: AnnouncementsStore.ActionTypes.SAVE_ANNOUNCEMENT
      });
      AlertUtil.simple('The announcement is being created, please wait!', 'info');
      logger.msg('Add announcement action, route:/announcements', 'POST');
      await Server.post(`announcements`, announcement)
        .then((response: any) => {
          dispatch({
            type: AnnouncementsStore.ActionTypes.SAVE_ANNOUNCEMENT_SUCCESS,
            payload: response.data as AnnouncementDTO
          });
          AlertUtil.updateContent('The announcement was successfully created!', 'success');
          dispatch(push(adminRoutes.ANNOUNCEMENTS.path));
        })
        .catch(error => {
          logger.err('Add announcement action, route:/announcements', 'POST');
          AlertUtil.updateContent('Due to an error, the announcement could not be created!', 'error');
          dispatch({
            type: AnnouncementsStore.ActionTypes.SAVE_ANNOUNCEMENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  getAnnouncementAction(announcementId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: AnnouncementsStore.ActionTypes.GET_ANNOUNCEMENT
      });
      logger.msg('Get announcement action, route:/announcements/announcementId', 'GET');
      await Server.get(`announcements/${announcementId}`)
        .then((response: any) => {
          dispatch({
            type: AnnouncementsStore.ActionTypes.GET_ANNOUNCEMENT_SUCCESS,
            payload: response.data as AnnouncementDTO
          });
        })
        .catch(error => {
          logger.err('GET announcement action error, route:/announcements/announcementId', 'GET');
          dispatch({
            type: AnnouncementsStore.ActionTypes.GET_ANNOUNCEMENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  editAnnouncementAction(announcement: AnnouncementDTO) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: AnnouncementsStore.ActionTypes.EDIT_ANNOUNCEMENT
      });
      AlertUtil.simple('The announcement is being updated, please wait!', 'info');
      logger.msg('Edit announcement action, route:/announcements/:announcementId', 'PUT');
      await Server.put(`announcements/${announcement._id}`, announcement)
        .then((response: any) => {
          dispatch({
            type: AnnouncementsStore.ActionTypes.EDIT_ANNOUNCEMENT_SUCCESS,
            payload: response.data as AnnouncementDTO
          });
          AlertUtil.updateContent('The announcement was successfully updated!', 'success');
          dispatch(push(adminRoutes.ANNOUNCEMENTS.path));
        })
        .catch(error => {
          logger.err('Edit announcement action, route:/announcements/:announcementId', 'PUT');
          AlertUtil.updateContent('Due to an error, the announcement could not be updated!', 'error');
          dispatch({
            type: AnnouncementsStore.ActionTypes.EDIT_ANNOUNCEMENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  deleteAnnouncementAction(announcementId: string) {
    return async (dispatch: Dispatch<any>, getState: () => any) => {
      dispatch({
        type: AnnouncementsStore.ActionTypes.DELETE_ANNOUNCEMENT
      });
      logger.msg('Delete announcement action, route:/announcements/announcementId', 'DELETE');
      AlertUtil.simple(msg('alertMessages.eventBeingDeleted', 'Promotional material is being deleted...'), 'info');
      await Server.delete(`announcements/${announcementId}`)
        .then((response: any) => {
          dispatch({
            type: AnnouncementsStore.ActionTypes.DELETE_ANNOUNCEMENT_SUCCESS
          });
          const { limit, skip } = getState().announcements.announcements_list;
          dispatch(AnnouncementsStore.actions.getAnnouncementsAction(limit, skip, 'dispatch,-1'));
        })
        .catch(error => {
          logger.err('Delete announcement action error, route:/announcements/announcementId', 'DELETE');
          dispatch({
            type: AnnouncementsStore.ActionTypes.DELETE_ANNOUNCEMENT_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  redirectToAnnouncementContent(announcementId?: string) {
    return (dispatch: Dispatch<any>) => {
      if (announcementId) {
        const path = generatePath(adminRoutes.ANNOUNCEMENTS.subroutes.EDIT.path, { announcementId });
        dispatch(push(path));
      } else {
        dispatch(push(adminRoutes.ANNOUNCEMENTS.subroutes.ADD.path));
      }
    };
  }
}

const announcementsActions = new AnnouncementsActions();
export default announcementsActions;
