import { PayBatchesStore } from '.';
import { PaginationDTO } from '../types';

function payBatchesReducer(
  state: PayBatchesStore.IState = PayBatchesStore.initialState,
  action: IAction<any>
): PayBatchesStore.IState {
  switch (action.type) {
    case PayBatchesStore.ActionTypes.GET_PAY_BATCHES: {
      return {
        ...state,
        pay_batches_list_pending: true,
        pay_batches_list_error: null
      };
    }
    case PayBatchesStore.ActionTypes.GET_PAY_BATCHES_SUCCESS: {
      return {
        ...state,
        pay_batches_list: action.payload,
        pay_batches_list_pending: false,
        pay_batches_list_error: null
      };
    }
    case PayBatchesStore.ActionTypes.GET_PAY_BATCHES_FAILED: {
      return {
        ...state,
        pay_batches_list: new PaginationDTO(),
        pay_batches_list_pending: false,
        pay_batches_list_error: action.payload
      };
    }
    case PayBatchesStore.ActionTypes.CREATE_PAY_BATCH: {
      return {
        ...state,
        create_pay_batch_pending: true,
        create_pay_batch_error: null
      };
    }
    case PayBatchesStore.ActionTypes.CREATE_PAY_BATCH_SUCCESS: {
      return {
        ...state,
        create_pay_batch_pending: false,
        create_pay_batch_error: null
      };
    }
    case PayBatchesStore.ActionTypes.CREATE_PAY_BATCH_FAILED: {
      return {
        ...state,
        create_pay_batch_pending: false,
        create_pay_batch_error: action.payload
      };
    }
    case PayBatchesStore.ActionTypes.DOWNLOAD_PAY_BATCH: {
      return {
        ...state,
        download_pay_batch_pending: true,
        download_pay_batch_error: null
      };
    }
    case PayBatchesStore.ActionTypes.DOWNLOAD_PAY_BATCH_SUCCESS: {
      return {
        ...state,
        download_pay_batch_pending: false,
        download_pay_batch_error: null
      };
    }
    case PayBatchesStore.ActionTypes.DOWNLOAD_PAY_BATCH_FAILED: {
      return {
        ...state,
        download_pay_batch_pending: false,
        download_pay_batch_error: action.payload
      };
    }
    case PayBatchesStore.ActionTypes.MARK_PAY_BATCH_SENT: {
      return {
        ...state,
        mark_pay_batch_sent_pending: true,
        mark_pay_batch_sent_error: null
      };
    }
    case PayBatchesStore.ActionTypes.MARK_PAY_BATCH_SENT_SUCCESS: {
      return {
        ...state,
        mark_pay_batch_sent_pending: false,
        mark_pay_batch_sent_error: null
      };
    }
    case PayBatchesStore.ActionTypes.MARK_PAY_BATCH_SENT_FAILED: {
      return {
        ...state,
        mark_pay_batch_sent_pending: false,
        mark_pay_batch_sent_error: action.payload
      };
    }
    case PayBatchesStore.ActionTypes.ADD_PAY_BATCH_CONFIRMATION: {
      return {
        ...state,
        add_pay_batch_confirmation_pending: true,
        add_pay_batch_confirmation_error: null
      };
    }
    case PayBatchesStore.ActionTypes.ADD_PAY_BATCH_CONFIRMATION_SUCCESS: {
      return {
        ...state,
        add_pay_batch_confirmation_pending: false,
        add_pay_batch_confirmation_error: null
      };
    }
    case PayBatchesStore.ActionTypes.ADD_PAY_BATCH_CONFIRMATION_FAILED: {
      return {
        ...state,
        add_pay_batch_confirmation_pending: false,
        add_pay_batch_confirmation_error: action.payload
      };
    }
    case PayBatchesStore.ActionTypes.DELETE_PAY_BATCH: {
      return {
        ...state,
        delete_pay_batch_pending: true,
        delete_pay_batch_error: null
      };
    }
    case PayBatchesStore.ActionTypes.DELETE_PAY_BATCH_SUCCESS: {
      return {
        ...state,
        delete_pay_batch_pending: false,
        delete_pay_batch_error: null
      };
    }
    case PayBatchesStore.ActionTypes.DELETE_PAY_BATCH_FAILED: {
      return {
        ...state,
        delete_pay_batch_pending: false,
        delete_pay_batch_error: action.payload
      };
    }
    case PayBatchesStore.ActionTypes.GET_AVAILABLE_PLACES: {
      return {
        ...state,
        available_places_list_pending: true,
        available_places_list_error: null
      };
    }
    case PayBatchesStore.ActionTypes.GET_AVAILABLE_PLACES_SUCCESS: {
      return {
        ...state,
        available_places_list: action.payload,
        available_places_list_pending: false,
        available_places_list_error: null
      };
    }
    case PayBatchesStore.ActionTypes.GET_AVAILABLE_PLACES_FAILED: {
      return {
        ...state,
        available_places_list: [],
        available_places_list_pending: false,
        available_places_list_error: action.payload
      };
    }
    case PayBatchesStore.ActionTypes.CLEAR_PAY_BATCHES_ERROR: {
      return {
        ...state,
        pay_batches_list_error: null
      };
    }
    default:
      return state;
  }
}

export default payBatchesReducer;
