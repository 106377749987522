import * as React from 'react';
import { Badge } from 'react-bootstrap';

import { msg } from '../../utils';

interface IProps {
  data: {
    fields: any;
    list: Array<any>;
    actions?: Array<{
      label: any;
      onClick: (event: any) => any;
      icon: string;
      btn: string;
      id?: string;
    }>;
    badgeColors: Array<any>;
    pending: Boolean;
  };
}

interface IState {}
export default class StatisticsListComponent extends React.Component<IProps, IState> {
  fields: Array<any> = [];
  actions: Array<any>;

  constructor(props: IProps) {
    super(props);
    Object.keys(this.props.data.fields).forEach(key => {
      this.fields.push({
        key: key,
        value: this.props.data.fields[key]
      });
    });
    this.actions = this.props.data.actions || [];
  }

  getHeader() {
    return (
      <tr>
        {this.fields.map(item => {
          return <th key={item.key}>{item.value}</th>;
        })}
        {this.actions.length ? <th style={{ width: 120 }}>{msg('general.actions', 'Actions')}</th> : null}
      </tr>
    );
  }

  getBody() {
    return this.props.data.list.map((listItem, key) => {
      return (
        <tr key={key}>
          {this.fields.map((fieldItem, i) => {
            if (!listItem[fieldItem.key]) {
              listItem[fieldItem.key] = 0;
            }
            if (i === 0) {
              return (
                <td key={i}>
                  <Badge style={{ backgroundColor: this.props.data.badgeColors[key] }}>
                    {listItem[fieldItem.key].toString()}
                  </Badge>
                </td>
              );
            } else {
              return <td key={i}>{listItem[fieldItem.key].toString()}</td>;
            }
          })}
          {this.actions.length ? (
            <td className="td-actions text-left">
              {this.actions.map((action, aKey) => {
                return (
                  <button
                    key={aKey}
                    type="button"
                    data-rel="tooltip"
                    className={`btn ${action.btn}`}
                    onClick={action.onClick}
                    title={action.label}
                    id={listItem._id}
                  >
                    <i className="material-icons">{action.icon}</i>
                  </button>
                );
              })}
            </td>
          ) : null}
        </tr>
      );
    });
  }

  render() {
    const isData: Boolean = !!(
      this.fields &&
      this.fields.length &&
      this.props.data &&
      this.props.data.list &&
      this.props.data.list.length
    );
    return (
      <div className="table-responsive">
        {isData ? (
          <table className="table table-hover">
            <thead className="text-primary">{this.getHeader()}</thead>
            <tbody>{this.getBody()}</tbody>
          </table>
        ) : (
          <h3>{msg('general.noAvailableData', 'There is no available data.')}</h3>
        )}
      </div>
    );
  }
}
