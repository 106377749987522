import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { matchPath } from 'react-router-dom';

import runtimeEnv from '@mars/heroku-js-runtime-env';
import * as Sentry from '@sentry/browser';

import { AuthStore, store } from '../appRedux';
import { UserDeviceDTO } from '../appRedux/types';
import { authRoutes } from '../modules/layouts/routes';
import { AlertUtil, msg } from './';

/*
  Error codes definitions
*/
export enum ERROR_CODES {
  INCORECT_PASSWORD = 'INCORECT_PASSWORD',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  NO_ACTIVATION_FOUND = 'NO_ACTIVATION_FOUND',
  EXISTENT_USER = 'EXISTENT_USER',
  UNAUTHORIZED = '1001'
}

/*
  Set backend server url.
*/
const env = runtimeEnv();

const defOrigin = env.REACT_APP_API_URL || 'https://api-stage.regage.io';

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://f0b4ebd04fd34bbbbbef81ed1c0a4a14@sentry.io/1828890',
    ignoreErrors: ['status code 401', 'Network Error'],
  });
  Sentry.setTag('API_URL', defOrigin);
  Sentry.setTag('environment', env.HEROKU_APP_NAME || 'development');
}

const CancelToken = axios.CancelToken;
const axiosInstance = axios.create();

/*
  Class that implements all server functions.
*/
class Server {
  origin?: string;
  pendingRequests: Map<string, any>;
  tokenPromise: Promise<string> | null;

  constructor() {
    const storageOrigin = localStorage.getItem('origin');
    this.origin = storageOrigin || defOrigin;
    this.pendingRequests = new Map();
    this.tokenPromise = null;
  }

  setOrigin(origin: string) {
    if (origin) {
      this.origin = origin;
    }
  }

  get(url: string, skipToken: boolean = false) {
    return this.call('get', url, null, skipToken);
  }

  post(
    url: string,
    data: any,
    skipToken: boolean = false,
    isFile: boolean = false,
    onUploadProgress?: (progressEvent: any) => void
  ) {
    return this.call('post', url, data, skipToken, isFile, onUploadProgress);
  }

  put(url: string, data: any, skipToken: boolean = false) {
    return this.call('put', url, data, skipToken);
  }

  delete(url: string, data?: any) {
    return this.call('delete', url, data);
  }

  login(username: string, password: string, deviceInfo: UserDeviceDTO) {
    return this.post('auth/login', { username, password, deviceInfo, type: 'DASHBOARD' })
      .then((user: any) => {
        return user.data;
      })
      .catch((error) => {
        throw (error.response.data && error.response.data.ERROR) || error.response.data;
      });
  }

  logout() {
    return this.get(`auth/logout?type=DASHBOARD`)
      .then(response => {
        return response;
      })
      .catch((error: Error) => {
        throw error;
      });
  }

  searchForUsers = (input: string) => {
    return this.get(`admin/search-user/${input}?pagination=false`);
  };

  searchForIndustries = (input: string) => {
    return this.get(`industries/name/${input}`);
  };

  downloadReports = async (url: string, name: string = 'Report') => {
    const file = this.origin + url;
    const token = await this.getAccessToken();
    const headers = { Authorization: `Bearer ${token}` };
    return new Promise((resolve, reject) => {
      fetch(file, { headers })
        .then(async (response: any) => {
          const clone = response.clone();
          let json;
          try {
            json = await clone.json();
            if (!json.ok) {
              throw json;
            }
            return json;
          } catch (error) {
            if (response.ok && !json) {
              return await response.blob();
            }
            throw error;
          }
        })
        .then((blobby) => {
          let objectUrl = window.URL.createObjectURL(blobby);
          let anchor = document.createElement('a');
          anchor.href = objectUrl;
          anchor.download = name;
          anchor.click();
          window.URL.revokeObjectURL(objectUrl);
          resolve({ ok: true });
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  };

  getGenericUrl = (url: string) => {
    const lastPos = url.indexOf('?');
    if (lastPos > -1) {
      return url.substr(0, lastPos);
    }
    return url;
  };

  cancelPreviousCall = (url: string, requestedMethod?: string) => {
    if (this.pendingRequests.has(url)) {
      const { cancelFunction, method } = this.pendingRequests.get(url);
      if (method === requestedMethod) {
        cancelFunction();
      }
      this.pendingRequests.delete(url);
    }
  };

  deletePendingRequest = (url: string) => {
    if (this.pendingRequests.has(url)) {
      this.pendingRequests.delete(url);
    }
  };

  handleSessionExpired = () => {
    const pathname = window.location.pathname;
    const isAuthPage = Object.values(authRoutes).find((route: string) => {
      const match = matchPath(pathname, { exact: true, path: route });
      return !!match;
    });
    if (isAuthPage) {
      store.dispatch({ type: AuthStore.ActionTypes.LOGOUT });
    } else {
      AlertUtil.simple(msg('reduxMessages.auth.sessionExpired', 'Session expired!'), 'error');
      store.dispatch(AuthStore.actions.logoutAction());
    }
  };

  async call(
    method: Method,
    url: string,
    data: any,
    skipToken: boolean = false,
    isFile: boolean = false,
    onUploadProgress?: (progressEvent: any) => void
  ) {
    const axiosRequest = this.getMetadata(method, url, data, isFile);
    return new Promise(async (resolve, reject) => {
      let token;
      if (!skipToken) {
        try {
          token = await this.getAccessToken();
        } catch (err) {
          return reject(err);
        }
      }
      if (token) {
        axiosRequest.headers = {
          ...axiosRequest.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      if (isFile) {
        axiosRequest.onUploadProgress = onUploadProgress;
      }
      const genericUrl = this.getGenericUrl(url);
      this.cancelPreviousCall(genericUrl, method);
      axiosRequest.cancelToken = new CancelToken((c) => {
        this.pendingRequests.set(genericUrl, { cancelFunction: c, method });
      });
      axiosInstance
        .request(axiosRequest)
        .then((result: any) => {
          this.deletePendingRequest(genericUrl);
          resolve(result);
        })
        .catch((err: any) => {
          if (axios.isCancel(err)) {
            return;
          }
          const parsedError = this.handleError(err);
          this.deletePendingRequest(genericUrl);
          if (parsedError) {
            reject(parsedError);
          } else {
            AlertUtil.fireOnce(msg('reduxMessages.auth.sessionExpired', 'Session expired!'), 'error');
          }
        });
    });
  }

  getMetadata(method: Method, url: string, data: any, isFile: boolean = false) {
    let axiosRequest: AxiosRequestConfig = {};
    axiosRequest = {
      baseURL: `${this.origin}/${url}`,
      method,
      data,
      withCredentials: true,
    };
    if (isFile) {
      axiosRequest.headers = {
        'Content-Type': 'multipart/form-data',
      };
    }

    return axiosRequest;
  }

  getAccessToken = () => {
    const authState = store.getState()?.auth;
    const token = authState.token;
    if (!token) {
      return new Promise((resolve) => resolve(''));
    } else {
      const decodedToken = this.decodeJwtToken(token);
      const timeDif = decodedToken.exp * 1000 - Date.now();
      if (timeDif > 2000) {
        return new Promise((resolve) => resolve(token));
      }
    }
    if (this.tokenPromise) {
      return this.tokenPromise;
    }
    this.tokenPromise = new Promise((resolve, reject) => {
      const refresh_token = authState.refresh_token;
      axiosInstance
        .post(`${this.origin}/auth/token`, {
          token,
          refreshToken: refresh_token,
        })
        .then((response: AxiosResponse) => {
          store.dispatch({
            type: AuthStore.ActionTypes.GET_ACCESS_TOKEN_SUCCESS,
            payload: response?.data,
          });
          resolve(response?.data?.token);
        })
        .catch((err: any) => {
          store.dispatch({
            type: AuthStore.ActionTypes.GET_ACCESS_TOKEN_FAILED,
            payload: err?.data,
          });
          if (err.message !== 'Network Error') {
            this.handleSessionExpired();
          }
          reject(err);
        })
        .finally(() => {
          this.tokenPromise = null;
        });
    });
    return this.tokenPromise;
  };

  decodeJwtToken = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  };

  handleError(err: any) {
    const { response } = err;
    if (!response) {
      console.warn('Server error', err);
      return err;
    }
    const { data } = err.response;
    if (data.message === 'jwt expired' || data.message === 'invalid signature') {
      store.dispatch({ type: AuthStore.ActionTypes.LOGOUT });
      return null;
    }
    return err;
  }

  errorParse(error: any) {
    const response = error?.response || error || {};
    const data = response.data || response;
    return data.ERROR || data;
  }
}

const server = new Server();
export default server;
