import { ActivityStore, IActivityActions } from '.';
import { PaginationDTO } from '../types';
import { IFetchPlaceActivityResponse, IFetchCheckinActivityResponse } from './api';

function activityReducer(
  state: ActivityStore.IState = ActivityStore.initialState,
  action: IActivityActions
): ActivityStore.IState {
  switch (action.type) {
    case ActivityStore.ActionTypes.GET_PLACE_ACTIVITY:
      return {
        ...state,
        place_activity_list_pending: true,
        place_activity_list_error: null,
      };
    case ActivityStore.ActionTypes.GET_PLACE_ACTIVITY_SUCCESS:
      return {
        ...state,
        place_activity_list: action.payload,
        place_activity_list_pending: false,
        place_activity_list_error: null,
      };
    case ActivityStore.ActionTypes.GET_PLACE_ACTIVITY_FAILED:
      return {
        ...state,
        place_activity_list: new PaginationDTO() as IFetchPlaceActivityResponse,
        place_activity_list_pending: true,
        place_activity_list_error: action.payload,
      };
    case ActivityStore.ActionTypes.OPEN_CHECKIN_ACTIVITY:
      return {
        ...state,
        selected_checkin: action.payload
      };
    case ActivityStore.ActionTypes.CLOSE_CHECKIN_ACTIVITY:
      return {
        ...state,
        selected_checkin: null,
        checkin_activity_list: new PaginationDTO() as IFetchCheckinActivityResponse
      };
    case ActivityStore.ActionTypes.GET_CHECKIN_ACTIVITY:
      return {
        ...state,
        checkin_activity_list_pending: true,
        checkin_activity_list_error: null,
      };
    case ActivityStore.ActionTypes.GET_CHECKIN_ACTIVITY_SUCCESS:
      return {
        ...state,
        checkin_activity_list: action.payload,
        checkin_activity_list_pending: false,
        checkin_activity_list_error: null,
      };
    case ActivityStore.ActionTypes.GET_CHECKIN_ACTIVITY_FAILED:
      return {
        ...state,
        checkin_activity_list: new PaginationDTO() as IFetchCheckinActivityResponse,
        checkin_activity_list_pending: true,
        checkin_activity_list_error: action.payload,
      };
    case ActivityStore.ActionTypes.GET_USER_ACTIVITY: {
      return {
        ...state,
        user_activity_list_pending: true,
        user_activity_list_error: null
      };
    }
    case ActivityStore.ActionTypes.GET_USER_ACTIVITY_SUCCESS: {
      return {
        ...state,
        user_activity_list_pending: false,
        user_activity_list_error: null,
        user_activity_list: action.payload
      };
    }
    case ActivityStore.ActionTypes.GET_USER_ACTIVITY_FAILED: {
      return {
        ...state,
        user_activity_list_pending: false,
        user_activity_list_error: action.payload,
      };
    }
    case ActivityStore.ActionTypes.CLEAR_ACTIVITY_ERROR: {
      return {
        ...state,
        place_activity_list_error: null,
      };
    }
    default:
      return state;
  }
}

export default activityReducer;
