import * as React from 'react';
import { Bounce } from 'react-activity';
import { CardComponent, InfiniteScrollListComponent } from '../../../shared';
import { msg, getFormatDate } from '../../../../utils';
import { PaginationDTO, ReviewDTO } from '../../../../appRedux/types';
import { ReviewModalComponent } from './';

interface IProps {
  match: any;
  config: {
    review: ReviewDTO | null;
    review_pending: boolean;
    reviews: PaginationDTO<any>;
    reviews_error: string | null;
    reviews_pending: boolean;
    getReviews: (
      placeId: string,
      reviewConfigId: string,
      limit?: Number,
      skip?: Number,
      sort?: string,
      criteria?: { [key: string]: string }
    ) => any;
    getReview: (placeId: string, reviewId: string) => any;
    clearReviewModalData(): any;
    clearPlaceReviewsError(): any;
  };
}
interface IState {
  modal: any;
}

export default class ReviewsListComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      modal: {
        show: false
      }
    };
  }

  componentWillUnmount() {
    this.props.config.clearPlaceReviewsError();
  }

  parseReviews = (list: PaginationDTO<ReviewDTO>): any => {
    const results: any[] = [];
    if (list.results) {
      list.results.forEach(item => {
        results.push({
          ...item,
          created: getFormatDate(item.created),
          user:
            item.userId && item.user
              ? item.user.lastname + ' ' + item.user.firstname
              : msg('reviews.anonymous', 'Anonymous')
        });
      });
    }
    return { ...list, results };
  };

  getReviews = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    const placeId = this.props.match?.params?.placeId;
    const reviewConfigId = this.props.match?.params?.configId;
    return this.props.config.getReviews(placeId, reviewConfigId, limit, skip, sort, criteria);
  };

  toggleModal = () => {
    const show = this.state.modal.show;
    this.setState({
      modal: {
        show: !show
      }
    });
    if (this.state.modal.show) {
      this.props.config.clearReviewModalData();
    }
  };

  getActions = () => {
    return [
      {
        label: msg('reviews.seeAdditionalInfo', 'See additional information'),
        btn: 'btn-info',
        icon: 'info',
        onClick: (event: any) => {
          const reviewId = event.currentTarget.getAttribute('id');
          const placeId = this.props.match?.params?.placeId;
          this.props.config.getReview(placeId, reviewId);
          this.toggleModal();
        }
      }
    ];
  };

  render() {
    const reviews = this.parseReviews(this.props.config.reviews);
    return (
      ((this.props.config.reviews || !this.props.config.reviews_pending) && (
        <CardComponent
          title={msg('sidebar.reviews', 'Polls')}
          error={!!this.props.config.reviews_error}
          headerIcon="thumbs_up_down"
          needsTitle={true}
        >
          {this.state.modal.show && (
            <ReviewModalComponent
              title={msg('reviews.reviewDetails', 'Poll details')}
              review={this.props.config.review}
              review_pending={this.props.config.review_pending}
              toggleModal={this.toggleModal}
            />
          )}
          <div>
            <InfiniteScrollListComponent
              list={reviews}
              fields={{
                created: msg('reviews.data', 'Date and Time'),
                user: msg('reviews.user', 'User'),
                coinsReceived: msg('reviews.coinsReceived', 'Coins allocated')
              }}
              sort={{
                fields: ['created', 'user'],
                default: 'created,-1'
              }}
              pending={this.props.config.reviews_pending}
              error={this.props.config.reviews_error}
              search={['user.lastname', 'user.firstname']}
              get={this.getReviews}
              actions={this.getActions()}
            />
          </div>
        </CardComponent>
      )) || (
        <div className="w-screen h-screen flex items-center justify-center">
          <Bounce color="#5eb562" />
        </div>
      )
    );
  }
}
