import * as React from 'react';
import moment from 'moment';

import { msg } from '../../../../utils';
import { CardComponent } from '../../../shared';
import { EventFormComponent } from './forms';
import { RewardStatisticsComponent } from '.';
import {
  EventDTO,
  PlaceDTO,
  SelectValueDTO,
  EventType,
  PaginationDTO,
  ScheduleDTO,
  RaffleIntervalDTO
} from '../../../../appRedux/types';

interface IProps {
  config: {
    place: PlaceDTO | null;
    pending: boolean;
    loaded: EventDTO | null;
    load_pending: boolean;
    load_error: string | null;
    add: (event: any, id: string) => any;
    get: (eventId: string, id: string) => any;
    edit: (event: any, id: string) => any;
    places_list?: SelectValueDTO[];
    unavailableMomentsDays: string[];
    fetchUnavailableMomentsDays: (placeId: PlaceDTO['_id']) => any;
    members_list: PaginationDTO<any>;
    members_list_pending: boolean;
    members_list_error: string | null;
    campaign_members: PaginationDTO<any>;
    campaign_members_pending: boolean;
    campaign_members_error: string | null;
    getMembers: (
      placeId: string,
      limit?: Number,
      skip?: Number,
      sort?: string,
      criteria?: { [key: string]: string },
      places?: string[]
    ) => any;
    getMemberStatsForCampaign: (
      placeId: string,
      eventId: string,
      limit?: Number,
      skip?: Number,
      sort?: string,
      criteria?: { [key: string]: string }
    ) => any;
    raffle_preview: RaffleIntervalDTO[] | null;
    raffle_preview_pending: boolean;
    update_raffle_preview_pending: boolean;
    update_raffle_preview_error: string | null;
    getRafflePreview: (
      placeId: string,
      config: { raffleStart: string; raffleEnd: string; totalPrizes: number; schedule: ScheduleDTO[] }
    ) => any;
    getRaffleIntervals: (placeId: string, eventId: string) => any;
    editRaffleIntervals: (placeId: string, eventId: string, intervals: RaffleIntervalDTO[], editDate: number) => any;
    clearEventData: () => any;
    clearEventStatsModalData: () => any;
    clearRaffleIntervals: () => any;
  };
  match?: any;
  editMode: boolean;
  partnerId: string;
  allowMultiplePlaces: boolean;
}
interface IState {
  showModal: boolean;
}

export default class EventEditComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  componentDidMount() {
    const placeId = this.props.match.params.placeId || '';
    if (this.props.editMode && this.props.match && this.props.match.params.eventId) {
      this.props.config.get(this.props.match.params.eventId, placeId);
    }
    this.props.config.fetchUnavailableMomentsDays(placeId);
    this.props.config.getMembers(placeId);
  }

  componentWillUnmount() {
    this.props.config.clearEventData();
  }

  saveEventHandler = (event: any) => {
    const { config } = this.props;
    const placeId = config.place?._id || '';
    let parsedEvent: any = {};
    const currentPlace = config.places_list?.find((place: SelectValueDTO) => place._id === placeId) || {
      _id: placeId,
      name: config.place?.name
    };
    parsedEvent = {
      ...event,
      placeId: placeId,
      placeNames: [currentPlace.name],
      type: parseInt(event.type, 10),
      status: 1,
      partnerId: this.props.partnerId
    };
    parsedEvent.startDate = moment(event.startDate).valueOf();
    parsedEvent.endDate = moment(event.endDate).valueOf();
    if (parsedEvent.type === EventType.PROMOTION) {
      parsedEvent.rewards = event.rewards;
    }
    if (!this.props.editMode) {
      config.add(parsedEvent, placeId);
    } else {
      config.edit(event, placeId);
    }
  };

  toggleModal = () => {
    const show = this.state.showModal;
    this.setState({ showModal: !show });
    if (this.state.showModal) {
      this.props.config.clearEventStatsModalData();
    }
  };

  renderEventForm = () => {
    const propsConfig = this.props.config;
    const eventData =
      (this.props.editMode && propsConfig.loaded) || propsConfig.loaded
        ? new EventDTO(propsConfig.loaded)
        : new EventDTO();

    return (
      <CardComponent
        title={
          this.props.editMode
            ? msg('cardTitle.editEvent', 'Edit promotional material')
            : msg('cardTitle.addEvent', 'Add promotional material')
        }
        pending={propsConfig.load_pending}
        error={!!propsConfig.load_error}
        headerIcon={this.props.editMode ? 'edit' : 'add_circle_outline'}
        needsTitle={true}
        buttons={
          this.props.editMode && eventData?.type === EventType.PROMOTION
            ? [
                {
                  label: msg('event.rewardStatistics', 'Rewards statistics'),
                  onClick: this.toggleModal
                }
              ]
            : undefined
        }
      >
        {this.state.showModal && (
          <RewardStatisticsComponent
            members={propsConfig.campaign_members}
            placeId={eventData.placeId}
            event={eventData}
            members_pending={propsConfig.campaign_members_pending}
            members_error={propsConfig.campaign_members_error}
            getMembers={propsConfig.getMemberStatsForCampaign}
            toggleModal={this.toggleModal}
          />
        )}
        <form className="form-horizontal">
          <div className="col-sm-12">
            <EventFormComponent
              event={eventData}
              save={this.saveEventHandler}
              pending={propsConfig.pending}
              editable={this.props.editMode}
              partnerId={this.props.partnerId}
              places_list={this.props.config.places_list}
              allowMultiplePlaces={this.props.allowMultiplePlaces}
              place={this.props.config.place}
              unavailableMomentsDays={this.props.config.unavailableMomentsDays}
              members_list={this.props.config.members_list}
              members_list_pending={this.props.config.members_list_pending}
              members_list_error={this.props.config.members_list_error}
              getMembers={this.props.config.getMembers}
              raffle_preview={this.props.config.raffle_preview}
              raffle_preview_pending={this.props.config.raffle_preview_pending}
              update_raffle_preview_pending={this.props.config.update_raffle_preview_pending}
              update_raffle_preview_error={this.props.config.update_raffle_preview_error}
              getRafflePreview={this.props.config.getRafflePreview}
              getRaffleIntervals={this.props.config.getRaffleIntervals}
              editRaffleIntervals={this.props.config.editRaffleIntervals}
              clearRaffleIntervals={this.props.config.clearRaffleIntervals}
            />
          </div>
        </form>
      </CardComponent>
    );
  };

  render() {
    return <div>{this.renderEventForm()}</div>;
  }
}
