import autopilotActions, { IAutopilotActions } from './actions';
import autopilotReducer from './reducer';

/*
  AutopilotStore definition as a namespace that contains actions, reducers, actionTypes and autopilot state
*/
export namespace AutopilotStore {
  export type IState = {
    autopilot_list: Array<any>;
    autopilot_list_pending: boolean;
    autopilot_list_error: string | null;
    autopilot: any | null;
    autopilot_pending: boolean;
    autopilot_error: string | null;
    autopilot_saved: any | null;
    autopilot_save_pending: boolean;
    autopilot_save_error: string | null;
    all_simple_autopilots_save_success: boolean;
    all_simple_autopilots_save_pending: boolean;
    all_simple_autopilots_save_error: string | null;
    autopilot_deleted: boolean;
    autopilot_delete_pending: boolean;
    autopilot_delete_error: string | null;
    autopilot_verification_error: string | null;
  };

  export const initialState: IState = {
    autopilot_list: [],
    autopilot_list_pending: false,
    autopilot_list_error: null,
    autopilot: null,
    autopilot_pending: false,
    autopilot_error: null,
    autopilot_saved: null,
    autopilot_save_pending: false,
    autopilot_save_error: null,
    all_simple_autopilots_save_success: false,
    all_simple_autopilots_save_pending: false,
    all_simple_autopilots_save_error: null,
    autopilot_deleted: false,
    autopilot_delete_pending: false,
    autopilot_delete_error: null,
    autopilot_verification_error: null
  };

  export enum ActionTypes {
    GET_AUTOPILOTS = 'GET_AUTOPILOTS',
    GET_AUTOPILOTS_SUCCESS = 'GET_AUTOPILOTS_SUCCESS',
    GET_AUTOPILOTS_FAILED = 'GET_AUTOPILOTS_FAILED',
    GET_AUTOPILOT = 'GET_AUTOPILOT',
    GET_AUTOPILOT_SUCCESS = 'GET_AUTOPILOT_SUCCESS',
    GET_AUTOPILOT_FAILED = 'GET_AUTOPILOT_FAILED',
    SAVE_AUTOPILOT = 'SAVE_AUTOPILOT',
    SAVE_AUTOPILOT_SUCCESS = 'SAVE_AUTOPILOT_SUCCESS',
    SAVE_AUTOPILOT_FAILED = 'SAVE_AUTOPILOT_FAILED',
    SAVE_ALL_SIMPLE_AUTOPILOTS = 'SAVE_ALL_SIMPLE_AUTOPILOTS',
    SAVE_ALL_SIMPLE_AUTOPILOTS_SUCCESS = 'SAVE_ALL_SIMPLE_AUTOPILOTS_SUCCESS',
    SAVE_ALL_SIMPLE_AUTOPILOTS_FAILED = 'SAVE_ALL_SIMPLE_AUTOPILOTS_FAILED',
    DELETE_AUTOPILOT = 'DELETE_AUTOPILOT',
    DELETE_AUTOPILOT_SUCCESS = 'DELETE_AUTOPILOT_SUCCESS',
    DELETE_AUTOPILOT_FAILED = 'DELETE_AUTOPILOT_FAILED',
    AUTOPILOT_VERIFICATION_FAILED = 'AUTOPILOT_VERIFICATION_FAILED'
  }

  export const actions = autopilotActions;
  export const reducer = autopilotReducer;
  export interface IActions extends IAutopilotActions {}
}

export * from './actions';
