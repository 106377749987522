import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { TriggersListContainer } from './';
import { adminRoutes } from '../../layouts';

const TriggersRouter = () => {
  return (
    <Switch>
      <Route exact={true} path={adminRoutes.TRIGGERS.path} render={() => <TriggersListContainer />} />
    </Switch>
  );
};

export default TriggersRouter;
