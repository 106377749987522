import feedbackActions, { IFeedbackActions } from './actions';
import feedbackReducer from './reducer';
import { PaginationDTO, ReviewDTO } from '../types';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FeedbackStore {
  export type IState = {
    feedback_list: PaginationDTO<ReviewDTO>;
    feedback_list_pending: boolean;
    feedback_list_error: string | null;
    allow_feedback: boolean | null;
    allow_feedback_pending: boolean;
    allow_feedback_error: any | null;
  };

  export const initialState: IState = {
    feedback_list: new PaginationDTO(),
    feedback_list_pending: false,
    feedback_list_error: null,
    allow_feedback: null,
    allow_feedback_pending: false,
    allow_feedback_error: null
  };

  export enum ActionTypes {
    GET_FEEDBACK_LIST = 'GET_FEEDBACK_LIST',
    GET_FEEDBACK_LIST_SUCCESS = 'GET_FEEDBACK_LIST_SUCCESS',
    GET_FEEDBACK_LIST_FAILED = 'GET_FEEDBACK_LIST_FAILED',
    ALLOW_FEEDBACK = 'ALLOW_FEEDBACK',
    ALLOW_FEEDBACK_SUCCESS = 'ALLOW_FEEDBACK_SUCCESS',
    ALLOW_FEEDBACK_FAILED = 'ALLOW_FEEDBACK_FAILED',
    CLEAR_FEEDBACK_ERROR = 'CLEAR_FEEDBACK_ERROR'
  }

  export const actions = feedbackActions;
  export const reducer = feedbackReducer;
  export interface IActions extends IFeedbackActions {}
}

export * from './actions';
