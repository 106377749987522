import { PackagesStore } from './';

function packagesReducer(
  state: PackagesStore.IState = PackagesStore.initialState,
  action: IAction<any>
): PackagesStore.IState {
  switch (action.type) {
    case PackagesStore.ActionTypes.GET_PACKAGES: {
      return {
        ...state,
        packages_list_pending: true,
        packages_list_error: null
      };
    }
    case PackagesStore.ActionTypes.GET_PACKAGES_SUCCESS: {
      return {
        ...state,
        packages_list_pending: false,
        packages_list_error: null,
        packages_list: action.payload
      };
    }
    case PackagesStore.ActionTypes.GET_PACKAGES_FAILED: {
      return {
        ...state,
        packages_list_pending: false,
        packages_list_error: action.payload
      };
    }
    case PackagesStore.ActionTypes.GET_PACKAGE:
      return {
        ...state,
        package_details_pending: true,
        package_details_error: null
      };
    case PackagesStore.ActionTypes.GET_PACKAGE_SUCCESS:
      return {
        ...state,
        package_details: action.payload,
        package_details_pending: false,
        package_details_error: null
      };
    case PackagesStore.ActionTypes.GET_PACKAGE_FAILED:
      return {
        ...state,
        package_details_pending: false,
        package_details_error: action.payload
      };
    default:
      return state;
  }
}

export default packagesReducer;
