import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import { msg } from '../../../utils';
import { InfiniteScrollListComponent } from '../../shared';
import {
  formatActivityMember,
  formatActivityChange,
  formatActivityDate,
  formatActivityAction,
  getActivityFilters
} from './common';
import { PaginationDTO } from '../../../appRedux/types';
import { IFetchCheckinActivityResponse } from '../../../appRedux/activity/api';
import { ActivityStore } from '../../../appRedux/activity';
import { AppStore } from '../../../appRedux';

// tslint:disable-next-line: max-line-length
const CheckinActivityHeader: React.FC<{ totalCoins: number }> = props => {
  const { totalCoins } = props;
  return (
    <>
      <div>{msg('activity.checkinActivity', 'Checkin activity')}</div>
      <div>{msg('activity.totalCoins', `${totalCoins} coins`, { coins: totalCoins + '' })}</div>
    </>
  );
};

type IFetchCheckinActivitiesResponse = PaginationDTO<any>;
const mapResponseToView = (entries: IFetchCheckinActivitiesResponse) => {
  if (!entries.results) {
    return entries;
  }

  return {
    ...entries,
    results: entries.results.map(entry => ({
      memberFullname: formatActivityMember(entry.member),
      action: formatActivityAction(entry),
      change: formatActivityChange(entry),
      date: formatActivityDate(entry),
      _id: entry.checkinId
    }))
  };
};

interface ICheckinActivityStateProps {
  checkinId: string;
  activities: IFetchCheckinActivityResponse;
  isLoading: boolean;
  error: any;
}
interface ICheckinActivityActionsProps {
  getCheckinActivityList: (checkin: string, options?: any) => any;
}
type ICheckinActivityProps = ICheckinActivityStateProps & ICheckinActivityActionsProps;

export const CheckinActivity = (props: ICheckinActivityProps) => {
  const { activities, checkinId, isLoading, error } = props;
  const { getCheckinActivityList } = props;

  const eventTypesFilters = getActivityFilters([
    '',
    'CHECKIN_REWARD_BOUGHT',
    'CHECKIN_BONUS_REDEEMED',
    'CHECKIN_COINS_ALLOCATED',
    'PAYMENT_COMPLETED',
    'PAYMENT_REFUNDED',
    'RECEIPT_COINS_ALLOCATED',
    'CHECKIN_PARTIAL_REDEEM',
    'PAYMENT_COINS_ALLOCATED',
    'PAYMENT_COMPLETED',
    'PAYMENT_REFUNDED',
    'PAYMENT_COINS_REFUNDED'
  ]);

  const fetchData = async (limit: number, skip: number, sort: string, criteria: any) => {
    const options = {
      limit,
      skip,
      sort,
      criteria
    };
    getCheckinActivityList(checkinId, options);
  };

  return (
    <InfiniteScrollListComponent
      fields={{
        date: msg('activity.dateTime', 'Date and Time'),
        action: msg('activity.action', 'Action'),
        change: msg('activity.change', 'Change'),
        memberFullname: msg('activity.member', 'Member')
      }}
      list={mapResponseToView(activities)}
      pending={isLoading}
      error={error}
      disableQueryParams={true}
      get={fetchData}
      sort={{
        fields: ['date'],
        default: 'date,-1'
      }}
      search={[]}
      filters={[
        {
          field: 'eventType',
          value: eventTypesFilters
        }
      ]}
      hasCardButton={true}
    />
  );
};

interface IModalCheckinActivityOwnProps {
  onClose: () => void;
}
type IModalCheckinActivityStateProps = ICheckinActivityStateProps;
type IModalCheckinActivityActionsProps = ICheckinActivityActionsProps & IModalCheckinActivityOwnProps;
type IModalCheckinActivityProps = IModalCheckinActivityStateProps &
  IModalCheckinActivityActionsProps &
  IModalCheckinActivityOwnProps;
export const ModalCheckinActivity = (props: IModalCheckinActivityProps) => {
  const { checkinId, activities, isLoading, error } = props;
  const { getCheckinActivityList, onClose } = props;
  const totalCoins = (props.activities.totalEarned || 0) - (props.activities.totalSpent || 0);
  return (
    <Modal show={true} onHide={onClose} dialogClassName="customModal">
      <Modal.Header>
        <Modal.Title style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CheckinActivityHeader totalCoins={totalCoins} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CheckinActivity
          checkinId={checkinId}
          activities={activities}
          isLoading={isLoading}
          error={error}
          getCheckinActivityList={getCheckinActivityList}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-success" onClick={onClose}>
          {msg('general.close', 'Close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state: AppStore.states): ICheckinActivityStateProps => ({
  checkinId: state.activity.selected_checkin as string,
  activities: state.activity.checkin_activity_list,
  isLoading: state.activity.checkin_activity_list_pending,
  error: state.activity.checkin_activity_list_error
});
// tslint:disable-next-line: max-line-length
const mapDispatchToProps = (
  dispatch: any,
  ownProps: IModalCheckinActivityProps
): IModalCheckinActivityActionsProps => ({
  getCheckinActivityList: (checkinId: string, options?: any) =>
    dispatch(ActivityStore.actions.getCheckinActivityList(checkinId, options)),
  onClose: ownProps.onClose
});

export const ModalCheckinActivityContainer = connect<
  IModalCheckinActivityStateProps,
  IModalCheckinActivityActionsProps,
  IModalCheckinActivityOwnProps,
  any
>(
  mapStateToProps,
  mapDispatchToProps
)(ModalCheckinActivity);
