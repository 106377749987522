import React from 'react';
import * as _ from 'lodash';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { ButtonComponent, TextFieldComponent } from '../../../../shared';
import { msg, Validator, IValidator } from '../../../../../utils';

interface IProps {
  user?: any | null;
  save?: (user: any) => void;
  output?: boolean;
  password_pending: boolean;
  user_edit_pending: boolean;
  onChange?: (user: any) => void;
  changePass?: (email: string, oldPassword: string, newPassword: string) => void;
  errors?: any;
}
interface IState {
  user: any;
  isDirty: boolean;
  passwords: {
    password: string;
    newPassword: string;
    newPasswordVerify: string;
  };
  passwordChangeCheck: boolean;
}

class ProfileFormComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      user: { ...props.user } || null,
      isDirty: false,
      passwords: {
        password: '',
        newPassword: '',
        newPasswordVerify: ''
      },
      passwordChangeCheck: false
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.user && !_.isEqual(prevProps.user, this.props.user)) {
      this.setState({ user: this.props.user });
    }
  }

  saveHandler = () => {
    const { user, passwords } = this.state;
    const { save, changePass, validator } = this.props;
    const { isValid } = validator!;
    if (this.state.isDirty && isValid && isValid() && save) {
      if (user.phone === '') {
        user.phone = null;
      }
      save(user);
    }
    if (changePass && passwords.newPassword !== '' && passwords.newPasswordVerify !== '') {
      changePass(user.email, passwords.password, passwords.newPassword);
    }
    this.setState({ isDirty: false });
  };

  handleChange = (event: any) => {
    const fieldKey = event.target.getAttribute('id');
    const fieldValue = event.target.value;
    const user = this.state.user;
    const passwords = this.state.passwords;
    const { isDirty } = this.props.validator!;
    if (isDirty) {
      isDirty(fieldKey);
    }
    if (fieldKey === 'password' || fieldKey === 'newPassword' || fieldKey === 'newPasswordVerify') {
      passwords[fieldKey] = fieldValue;
      this.setState({ passwords, isDirty: true });
    } else {
      user[fieldKey] = fieldValue;
      this.setState({ user, isDirty: true });
    }
    if (this.props.output && this.props.onChange) {
      this.props.onChange(user);
    }
  };

  handlePasswordChangeCheck = () => {
    const passwords = this.state.passwords;
    passwords.password = '';
    passwords.newPassword = '';
    passwords.newPasswordVerify = '';
    this.setState({
      passwordChangeCheck: !this.state.passwordChangeCheck
    });
  };

  showPassword() {
    const { errors } = this.props.validator!;
    return (
      <div>
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('passwords.oldPassword', 'Old password')}
              id="password"
              value={this.state.passwords.password}
              onChange={this.handleChange}
              inType="password"
              minLength={1}
              maxLength={50}
              required={true}
              formatError={errors.oldPassword}
            />
          </div>
          <div className="col-sm-6" />
        </div>
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('passwords.newPassword', 'New password')}
              id="newPassword"
              value={this.state.passwords.newPassword}
              onChange={this.handleChange}
              inType="password"
              minLength={1}
              maxLength={50}
              required={true}
              formatError={errors.newPassword}
              validator={[
                {
                  type: 'equals',
                  msg: msg('formValidation.passwordsMismatch', 'Passwords are not the same!'),
                  params: `${this.state.passwords.newPasswordVerify}`
                }
              ]}
              ref="newPassword"
            />
          </div>
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('passwords.confirmPassword', 'Confirm password')}
              id="newPasswordVerify"
              value={this.state.passwords.newPasswordVerify}
              onChange={this.handleChange}
              inType="password"
              minLength={1}
              maxLength={50}
              required={true}
              formatError={errors.newPassword}
            />
          </div>
          <div className="col-sm-1" />
        </div>
      </div>
    );
  }

  render() {
    const { user } = this.state;
    const { errors } = this.props.validator!;
    const newPasswordContent = this.state.passwordChangeCheck ? this.showPassword() : null;
    return (
      <div>
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('accountInfo.lastname', 'Last name')}
              id="lastname"
              value={user.lastname}
              onChange={this.handleChange}
              inType="text"
              minLength={1}
              maxLength={30}
              required={true}
              formatError={errors.lastname}
              validator={[
                { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } },
                {
                  type: 'checkIfAlpha',
                  msg: msg('formValidation.alphanumericCharacters', 'Use only alphanumeric characters!'),
                  params: user.lastname
                }
              ]}
              ref="lastname"
            />
          </div>
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('accountInfo.firstname', 'First name')}
              id="firstname"
              value={user.firstname}
              onChange={this.handleChange}
              inType="text"
              minLength={1}
              maxLength={30}
              required={true}
              formatError={errors.firstname}
              validator={[
                { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } },
                {
                  type: 'checkIfAlpha',
                  msg: msg('formValidation.alphanumericCharacters', 'Use only alphanumeric characters!'),
                  params: user.firstname
                }
              ]}
              ref="firstname"
            />
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('accountInfo.email', 'Email address')}
              id="email"
              value={user.email}
              onChange={this.handleChange}
              inType="text"
              minLength={1}
              maxLength={50}
              required={true}
              readOnly={true}
            />
          </div>
          <div className="col-sm-5">
            <TextFieldComponent
              label={msg('accountInfo.phone', 'Phone')}
              id="phone"
              value={user.phone || ''}
              onChange={this.handleChange}
              inType="text"
              minLength={10}
              maxLength={10}
              required={false}
              formatError={errors.phone}
              ref="phone"
              validator={[]}
            />
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row">
          <div className="col-sm-1" />
          <div className="col-md-3" style={{ marginBottom: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={this.state.passwordChangeCheck}
                  onChange={this.handlePasswordChangeCheck}
                  value="showPasswords"
                />
              }
              label={msg('passwords.changePassword', 'Change password')}
            />
          </div>
          <div className="col-md-8" />
        </div>
        {newPasswordContent}
        {!this.props.output && (
          <div>
            <div className="row" style={{ margin: 10 }}>
              <div className="col-md-3" />
              <div className="col-md-6" style={{ textAlign: 'center' }}>
                <div className="form-group form-button">
                  <ButtonComponent
                    label={msg('general.save', 'Save')}
                    icon="save"
                    action={this.saveHandler}
                    pending={
                      this.state.passwordChangeCheck ? this.props.password_pending : this.props.user_edit_pending
                    }
                    disabled={!this.state.isDirty}
                  />
                </div>
              </div>
              <div className="col-md-3" />
            </div>
            <div className="row">
              <div className="col-md-6 category form-category">
                <small>*</small>
                {msg('errors.fieldsRequired', 'Required fields')}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Validator(ProfileFormComponent);
