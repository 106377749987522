export const styles: { [name: string]: React.CSSProperties } = {
  dropzone: {
    position: 'relative',
    width: '100%',
    height: 50,
    border: '1px solid rgb(139, 141, 142)',
    borderStyle: 'solid',
    borderRadius: 3
  },
  progress: {
    position: 'absolute',
    backgroundColor: '#f3bb455c',
    height: '100%',
    transition: 'all 500ms ease-out',
    zIndex: 1
  },
  content: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 2,
    cursor: 'pointer'
  },
  message: {
    fontSize: 16,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    padding: '3px 10px'
  },
  icon: {
    fontSize: 30,
    marginLeft: 15,
    marginRight: 15
  }
};
