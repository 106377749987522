import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AppStore, reduxContainer, TranslationsStore, OnboardStore, IndustriesStore } from '../../../appRedux';
import { OnboardComponent, OnboardingsListComponent, OnboardingViewComponent } from './components';
import { SelectValueDTO } from '../../../appRedux/types';
import { adminRoutes, authRoutes } from '../../layouts';

interface IProps extends TranslationsStore.IState, OnboardStore.IState {
  sendOnboardingAction: (form: any) => any;
  send_onboarding_pending: boolean;
  getOnboardingAction: (onboardingId: string) => any;
  getOnboardingBySAdminAction: (onboardingId: string) => any;
  get_onboarding_pending: boolean;
  get_onboarding_error: string | null;
  updateOnboardingAction: (onboardingId: string, form: any) => any;
  update_onboarding_pending: boolean;
  onboarding: any;
  industries: Array<SelectValueDTO>;
  getAllOnboardingsAction: (limit?: Number, skip?: Number, sort?: string, criteria?: { [key: string]: string }) => any;
  deleteOnboardingAction: (onboardingId: string) => any;
  onboardings: any;
  get_all_onboardings_pending: boolean;
  get_all_onboardings_error: string | null;
  redirectToOnboardingView: (onboardingId: string) => any;
  createPartnerFromOnboarding: (onboardingId: string) => any;
  create_onboarding_partner_pending: boolean;
  createPlaceFromOnboarding: (onboardingId: string) => any;
  create_onboarding_place_pending: boolean;
  createUserFromOnboarding: (onboardingId: string) => any;
  sendEmailToPartner: (onboardingId: string) => any;
  send_email_to_partner_pending: boolean;
  create_onboarding_user_pending: boolean;
  initial_language: string;
  changeLanguageAction: () => any;
}

interface IState {}

class OnboardContainer extends React.Component<
  IProps & TranslationsStore.IActions & OnboardStore.IActions & IndustriesStore.IActions,
  IState
> {
  render() {
    const navAuthConfig = {
      title: 'ReGage Partner',
      initial_language: this.props.initial_language,
      changeLanguage: this.props.changeLanguageAction
    };
    const onboardConfig = {
      sendOnboardingAction: this.props.sendOnboardingAction,
      send_onboarding_pending: this.props.send_onboarding_pending,
      send_onboarding_error: this.props.send_onboarding_error,
      getOnboardingAction: this.props.getOnboardingAction,
      get_onboarding_pending: this.props.get_onboarding_pending,
      updateOnboardingAction: this.props.updateOnboardingAction,
      update_onboarding_pending: this.props.update_onboarding_pending,
      update_onboarding_error: this.props.update_onboarding_error,
      onboarding: this.props.onboarding,
      industries: this.props.industries,
      getShortIndustriesListAction: this.props.getShortIndustriesListAction,
      alternative_address_flag: this.props.alternative_address_flag,
      getFlagsForOnboarding: this.props.getFlagsForOnboarding
    };
    const onboardingsList = {
      getAllOnboardingsAction: this.props.getAllOnboardingsAction,
      onboardings: this.props.onboardings,
      get_all_onboardings_pending: this.props.get_all_onboardings_pending,
      get_all_onboardings_error: this.props.get_all_onboardings_error,
      deleteOnboardingAction: this.props.deleteOnboardingAction,
      redirectToOnboardingView: this.props.redirectToOnboardingView,
      getFlagsForOnboarding: this.props.getFlagsForOnboarding
    };
    const onboardingView = {
      onboarding: this.props.onboarding,
      get_onboarding_pending: this.props.get_onboarding_pending,
      get_onboarding_error: this.props.get_onboarding_error,
      getOnboardingBySAdminAction: this.props.getOnboardingBySAdminAction,
      createPartnerFromOnboarding: this.props.createPartnerFromOnboarding,
      create_onboarding_partner_pending: this.props.create_onboarding_partner_pending,
      createPlaceFromOnboarding: this.props.createPlaceFromOnboarding,
      create_onboarding_place_pending: this.props.create_onboarding_place_pending,
      createUserFromOnboarding: this.props.createUserFromOnboarding,
      create_onboarding_user_pending: this.props.create_onboarding_user_pending,
      activatePlaceFromOnboarding: this.props.activatePlaceFromOnboarding,
      activate_onboarding_place_pending: this.props.activate_onboarding_place_pending,
      sendEmailToPartner: this.props.sendEmailToPartner,
      send_email_to_partner_pending: this.props.send_email_to_partner_pending
    };
    return (
      <Switch>
        <Route
          exact={true}
          path={authRoutes.ONBOARD}
          render={() => <OnboardComponent config={onboardConfig} navAuthConfig={navAuthConfig} />}
        />
        <Route
          exact={true}
          path={authRoutes.ONBOARD_CREATED}
          render={() => <OnboardComponent config={onboardConfig} navAuthConfig={navAuthConfig} />}
        />
        <Route
          exact={true}
          path={adminRoutes.ONBOARDINGS.path}
          render={() => <OnboardingsListComponent config={onboardingsList} />}
        />
        <Route
          exact={true}
          path={adminRoutes.ONBOARDINGS.subroutes.VIEW.path}
          render={({ match }) => <OnboardingViewComponent match={match} config={onboardingView} />}
        />
      </Switch>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    initial_language: state.translations.initial_language,
    send_onboarding_pending: state.onboard.send_onboarding_pending,
    send_onboarding_error: state.onboard.send_onboarding_error,
    get_onboarding_pending: state.onboard.get_onboarding_pending,
    get_onboarding_error: state.onboard.get_onboarding_error,
    update_onboarding_pending: state.onboard.update_onboarding_pending,
    update_onboarding_error: state.onboard.update_onboarding_error,
    onboarding: state.onboard.onboarding,
    onboardings: state.onboard.onboardings,
    get_all_onboardings_pending: state.onboard.get_all_onboardings_pending,
    get_all_onboardings_error: state.onboard.get_all_onboardings_error,
    create_onboarding_partner_pending: state.onboard.create_onboarding_partner_pending,
    create_onboarding_place_pending: state.onboard.create_onboarding_place_pending,
    create_onboarding_user_pending: state.onboard.create_onboarding_user_pending,
    activate_onboarding_place_pending: state.onboard.activate_onboarding_place_pending,
    send_email_to_partner_pending: state.onboard.send_email_to_partner_pending,
    industries: state.industries.industries_short_list,
    alternative_address_flag: state.onboard.alternative_address_flag
  };
}

const dispatchToProps = {
  changeLanguageAction: TranslationsStore.actions.changeLanguageAction,
  sendOnboardingAction: OnboardStore.actions.sendOnboardingAction,
  getOnboardingAction: OnboardStore.actions.getOnboardingAction,
  getOnboardingBySAdminAction: OnboardStore.actions.getOnboardingBySAdminAction,
  updateOnboardingAction: OnboardStore.actions.updateOnboardingAction,
  getAllOnboardingsAction: OnboardStore.actions.getAllOnboardingsAction,
  deleteOnboardingAction: OnboardStore.actions.deleteOnboardingAction,
  redirectToOnboardingView: OnboardStore.actions.redirectToOnboardingView,
  createPartnerFromOnboarding: OnboardStore.actions.createPartnerFromOnboarding,
  createPlaceFromOnboarding: OnboardStore.actions.createPlaceFromOnboarding,
  createUserFromOnboarding: OnboardStore.actions.createUserFromOnboarding,
  activatePlaceFromOnboarding: OnboardStore.actions.activatePlaceFromOnboarding,
  sendEmailToPartner: OnboardStore.actions.sendEmailToPartner,
  getShortIndustriesListAction: IndustriesStore.actions.getShortIndustriesListAction,
  getFlagsForOnboarding: OnboardStore.actions.getFlagsForOnboarding
};

export default reduxContainer(OnboardContainer, mapStateToProps, dispatchToProps);
