import moment from 'moment';

export class AutopilotDTO {
  _id: string = '';
  title: string = '';
  expiration: string = '';
  delay: number = moment()
    .startOf('minute')
    .valueOf();
  actions: Array<any> = [
    { _id: new Date().valueOf(), type: '', value: '', reward: '', channels: [], customReward: '', media: {} }
  ];
  triggers: Array<any> = [{ _id: new Date().valueOf(), type: '', value: '', period: '', condition: '' }];
  updatedAt: string;
}

export enum AutopilotActionType {
  REWARD,
  POINTS,
  MEMBER_STATUS,
  MESSAGE,
  CUSTOM,
  VOUCHER
}

export interface IAutopilotAction {
  _id?: string;
  type: AutopilotActionType;
  value: any;
  media?: string;
}