import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { PlacesStore } from './';
import { AutopilotStore } from '../autopilot';
import { RewardsStore } from '../rewards';
import { PlaceDTO, PaginationDTO, SelectValueDTO } from '../types';
import { Server, composedCriteriaBuilder, AlertUtil, msg, logger } from '../../utils';
import { fetchUnavailableMomentsDays } from './api';
import { UsersStore } from '../users';
import { generatePath } from 'react-router-dom';
import { adminRoutes, partnerRoutes } from '../../modules/layouts/routes';
import { StatisticsStore } from '../statistics';
import { AxiosResponse } from 'axios';

/*
  IPlacesActions interface definition, which contains every redux action asociated with Places State.
*/
export interface IPlacesActions {
  /*
    General actions and for super admin
  */
  addPlaceAction(place: PlaceDTO, partnerId: string): any;
  deletePlaceAction(placeId: string, partnerId: string): any;
  deletePlaceMedia(placeId: string, mediaId: string): any;
  editPlaceAction(place: PlaceDTO): any;
  getPlaceAction(placeId: string): any;
  getPlacesListAction(): any;
  redirectToAddPlaceAction(partnerId: string): any;
  redirectToEditPlaceAction(placeId: string): any;
  selectPlaceTopbar(placeId: string): any;
  resetPinCode(isSAdmin: boolean, placeId: string, newPinCode: string, oldPinCode?: string): any;

  /*
    Actions specific for partner
  */
  editPlaceForPartnerAction(place: PlaceDTO): any;
  getPlacesForPartnerAction(
    partnerId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ): any;
  getNamesListForPlacesAction(placeId: string): any;

  redirectToPlaceDashboardAction(placeId: string): any;

  /*
    Actions specific for place
  */
  inheritAutopilotRules(placeId: string, partnerId: string): any;
  inheritRewardsAction(placeId: string): any;
  selectPlaceLogin(placeId: string): any;
  addPlaceAdminByEmail(email: string, placeId: string): any;
  redirectToPlaceAdminsAction(placeId: string, forSAdmin: boolean): any;
  setPlaceAction(place: PlaceDTO): any;
}

/*
  class PlacesActions that implements redux actions defined in IPlacesActions interface
*/
class PlacesActions implements IPlacesActions {
  /*
    @function addPlaceAction => Redux action that adds a new place on both redux and server
      @accepts place : PlaceDTO object that contains information completed on add place form
               partnerId : string that represents the id of the partner to whom you add the place
      @returns Promise
  */

  addPlaceAction(place: PlaceDTO, partnerId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: PlacesStore.ActionTypes.SAVE_PLACE
      });
      AlertUtil.simple(
        msg('reduxMessages.places.addPlacePending', 'The place is being created, please wait...'),
        'info'
      );

      logger.msg('Add place action, route:/partners/partnerId/addPlace', 'POST');
      await Server.post(`partners/${partnerId}/addPlace`, place)
        .then((response: any) => {
          dispatch({
            type: PlacesStore.ActionTypes.SAVE_PLACE_SUCCESS,
            payload: response.data as PlaceDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.places.addPlaceSuccess', 'The place was successfully created!'),
            'success'
          );
          const path = generatePath(adminRoutes.PARTNERS.subroutes.EDIT.path, { partnerId });
          dispatch(push(path));
        })
        .catch(error => {
          logger.err('Add place action, route:/partners/partnerId/addPlace', 'POST');
          AlertUtil.updateContent(
            msg('reduxMessages.places.addPlaceError', 'Due to an error, the place could not be created!'),
            'error'
          );
          dispatch({
            type: PlacesStore.ActionTypes.SAVE_PLACE_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function deletePlaceAction => Redux action that deletes an existing place on both redux and server
      @accepts placeId : string that represents the id of the place to be deleted
               partnerId : string that represents the id of the partner that has the soon to be deleted place
      @returns Promise
  */
  deletePlaceAction(placeId: string, partnerId: string) {
    return (dispatch: Dispatch<any>) => {
      AlertUtil.simple(msg('alertMessages.placeBeingDeleted', 'The place is being deleted...'), 'info');
      dispatch({
        type: PlacesStore.ActionTypes.DELETE_PLACE
      });
      logger.msg('Delete place action, route:/places/placeId', 'DELETE');
      Server.delete(`places/${placeId}`)
        .then(async () => {
          const getPlacesResponse: any = await Server.get(`partners/${partnerId}/places`);
          dispatch({
            type: PlacesStore.ActionTypes.DELETE_PLACE_SUCCESS,
            payload: getPlacesResponse.data as PlaceDTO
          });
          AlertUtil.updateContent(
            msg('alertMessages.placeDeleted', 'The place has been successfully deleted!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Delete place action, route:/places/placeId', 'DELETE');
          AlertUtil.updateContent(
            msg('reduxMessages.places.deletePlaceError', 'Due to an error, the place could not be deleted!'),
            'error'
          );
          dispatch({
            type: PlacesStore.ActionTypes.DELETE_PLACE_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function deletePlaceMedia => Redux action that deletes an image from a place photo gallery
      @accepts placeId : string that represents the id of the place from where to delete the image
               mediaId : string that represents the id of the image to be deleted
      @returns Promise
  */
  deletePlaceMedia(placeId: string, mediaId: string) {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: PlacesStore.ActionTypes.DELETE_PLACE_MEDIA
      });
      logger.msg('Delete place media action, route:/places/placeId/deleteMedia?mediaId', 'DELETE');
      Server.delete(`places/${placeId}/deleteMedia?mediaId=${mediaId}`)
        .then(async (response: any) => {
          dispatch({
            type: PlacesStore.ActionTypes.DELETE_PLACE_MEDIA_SUCCESS,
            payload: response.data
          });
        })
        .catch(error => {
          logger.err('Delete place media action, route:/places/placeId/deleteMedia?mediaId', 'DELETE');
          AlertUtil.simple(
            msg(
              'reduxMessages.places.deletePlaceMediaError',
              'Due to an error, the selected media could not be deleted!'
            ),
            'error',
            2000
          );
          dispatch({
            type: PlacesStore.ActionTypes.DELETE_PLACE_MEDIA_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function editPlaceAction => Redux action for editing an existing place from both redux and server
      @accepts place : PlaceDTO object that contains the new information that you want to overwrite
      @returns Promise
  */
  editPlaceAction(place: PlaceDTO) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: PlacesStore.ActionTypes.SAVE_PLACE
      });
      AlertUtil.simple(
        msg('reduxMessages.places.editPlacePending', 'The place is being updated, please wait...'),
        'info',
        3000
      );

      logger.msg('Edit place action, route:/places/placeId', 'PUT');
      await Server.put(`places/${place._id}`, place)
        .then((response: any) => {
          dispatch({
            type: PlacesStore.ActionTypes.SAVE_PLACE_SUCCESS,
            payload: response.data as PlaceDTO
          });
          dispatch(PlacesStore.actions.setPlaceAction(response.data));
          AlertUtil.updateContent(
            msg('reduxMessages.places.editPlaceSuccess', 'The place was successfully updated!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Edit place action, route:/places/placeId', 'PUT');
          if (error.response?.data?.ERROR?.code === 'VALIDATION_ERROR') {
            AlertUtil.updateContent(
              msg(
                'reduxMessages.places.editPlaceFeatureFlagsFailed',
                'The flag for coins cannot be disabled while the place has active autopilots or rewards!'
              ),
              'error'
            );
          } else if (error.response?.data?.ERROR?.code === 'VALIDATION_ERROR_TAGS') {
            AlertUtil.updateContent(
              msg(
                'reduxMessages.places.editPlaceFeatureFlagsFailedTags',
                'The flag for member tags cannot be disabled as long as the place has active tags!'
              ),
              'error'
            );
          } else {
            AlertUtil.updateContent(
              msg('reduxMessages.places.editPlaceError', 'Due to an error, the place could not be updated!'),
              'error'
            );
          }
          dispatch({
            type: PlacesStore.ActionTypes.SAVE_PLACE_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getPlaceAction => Redux action for getting information about an existing place
      @accepts placeId : string that represents the id of the place you want to get information about
      @returns Promise
  */
  getPlaceAction(placeId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: PlacesStore.ActionTypes.GET_PLACE
      });
      logger.msg('Get place action, route:/places/placeId', 'GET');
      Server.get(`places/${placeId}`)
        .then((response: any) => {
          dispatch({
            type: PlacesStore.ActionTypes.GET_PLACE_SUCCESS,
            payload: response.data as PlaceDTO
          });
          return response.data;
        })
        .catch(error => {
          logger.err('Get place action, route:/places/placeId', 'GET');
          AlertUtil.simple(
            msg('reduxMessages.places.getPlaceError', 'Due to an error, the place data could not be loaded!'),
            'error',
            2000
          );
          dispatch({
            type: PlacesStore.ActionTypes.GET_PLACE_FAILED,
            payload: Server.errorParse(error)
          });
          throw error;
        });
    };
  }

  /*
    @function getPlacesListAction => Redux action for getting the list of all existing places
      @returns Promise
  */
  getPlacesListAction() {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PlacesStore.ActionTypes.GET_PLACES });
      try {
        logger.msg('Get places list action, route:/places', 'GET');
        const response: any = await Server.get('places?pagination=false&sort=name,1');
        dispatch({
          type: PlacesStore.ActionTypes.GET_PLACES_SUCCESS,
          payload: response.data.results as Array<PlaceDTO>
        });
      } catch (error) {
        logger.err('Get places list action, route:/places', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.places.getPlacesError', 'Due to an error, the places list could not be loaded!'),
          'error',
          2000
        );
        dispatch({
          type: PlacesStore.ActionTypes.GET_PLACES_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  /*
    @function redirectToAddPlaceAction => Redux action for redirecting to add place form page
      @accepts partnerId : string that represents the id of the partner to whom you want to add the place
      @returns Promise
  */
  redirectToAddPlaceAction(partnerId: string) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(adminRoutes.PLACES.subroutes.ADD.path, { partnerId });
      dispatch(push(path));
    };
  }

  /*
    @function redirectToEditPlaceAction => Redux action for redirecting to edit place form page
      @accepts placeId : string that represents the id of the place you want to edit
      @returns Promise
  */
  redirectToEditPlaceAction(placeId: string) {
    return (dispatch: Dispatch<any>) => {
      const path = generatePath(adminRoutes.PLACES.subroutes.EDIT.path, { placeId });
      dispatch(push(path));
    };
  }

  /*
    @function selectPlaceTopbar => Redux action for saving the selected placeId from topbar and redirecting to
                                   specified pages based on this placeId
      @accepts placeId : string that represents the id of the place you selected in the topbar select component
      @returns Promise
  */

  selectPlaceTopbar = (placeId: string) => {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: PlacesStore.ActionTypes.SELECT_PLACE,
        payload: placeId
      });
      if (placeId) {
        dispatch(PlacesStore.actions.getPlaceAction(placeId));
        dispatch(StatisticsStore.actions.getStatisticsForOnePlaceAction(placeId));
        dispatch(PlacesStore.actions.redirectToPlaceDashboardAction(placeId));
      }
    };
  };

  /*
    @function editPlaceForPartnerAction => Redux action for editing an existing place for a partner
      @accepts place : PlaceDTO object that contains the new information that you want to overwrite
      @returns Promise
  */
  editPlaceForPartnerAction(place: PlaceDTO) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({
        type: PlacesStore.ActionTypes.SAVE_PLACE
      });
      AlertUtil.simple(
        msg('reduxMessages.places.editPlacePending', 'The place is being updated, please wait...'),
        'info'
      );
      logger.msg('Edit place for partner action, route:/places/placeId', 'PUT');
      await Server.put(`places/${place._id}`, place)
        .then((response: any) => {
          dispatch({
            type: PlacesStore.ActionTypes.SAVE_PLACE_SUCCESS,
            payload: response.data as PlaceDTO
          });
          AlertUtil.updateContent(
            msg('reduxMessages.places.editPlaceSuccess', 'The place was successfully updated!'),
            'success'
          );
        })
        .catch(error => {
          logger.err('Edit place for partner action, route:/places/placeId', 'PUT');
          AlertUtil.updateContent(
            msg('reduxMessages.places.editPlaceError', 'Due to an error, the place could not be updated!'),
            'error'
          );
          dispatch({
            type: PlacesStore.ActionTypes.SAVE_PLACE_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function getPlacesForPartnerAction => Redux action for getting the list of all existing places for a partner
      @accepts partnerId : string that represents the id of the partner from whom to get all places
      (optional) limit, skip, sort, criteria : params used for pagination
      @returns Promise
  */
  getPlacesForPartnerAction(
    partnerId: string,
    limit?: Number,
    skip?: Number,
    sort?: string,
    criteria?: { [key: string]: string }
  ) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PlacesStore.ActionTypes.GET_PARTNER_PLACES });
      try {
        if (!limit) {
          limit = 10;
        }
        let url = `partners/${partnerId}/places?limit=${limit}`;
        if (skip) {
          url += `&skip=${skip}`;
        }
        if (sort) {
          url += `&sort=${sort}`;
        }
        if (criteria) {
          url += composedCriteriaBuilder(criteria);
        }
        logger.msg('Get places of partner action, route:/partners/partnerId/places', 'GET');
        const response: any = await Server.get(url);
        dispatch({
          type: PlacesStore.ActionTypes.GET_PARTNER_PLACES_SUCCESS,
          payload: response.data as PaginationDTO<PlaceDTO>
        });
      } catch (error) {
        logger.err('Get places of partner action, route:/partners/partnerId/places', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.places.getPlacesError', 'Due to an error, the places list could not be loaded!'),
          'error',
          2000
        );
        dispatch({
          type: PlacesStore.ActionTypes.GET_PARTNER_PLACES_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  fetchUnavailableMomentsDaysAction(placeId: PlaceDTO['_id']) {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: PlacesStore.ActionTypes.GET_UNAVAILABLE_MOMENTS_DAYS, payload: placeId });
      fetchUnavailableMomentsDays(placeId)
        .then(results => {
          dispatch({
            type: PlacesStore.ActionTypes.GET_UNAVAILABLE_MOMENTS_DAYS_SUCCESS,
            payload: results
          });
        })
        .catch(error => {
          dispatch({
            type: PlacesStore.ActionTypes.GET_UNAVAILABLE_MOMENTS_DAYS_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }

  /*
    @function redirectToPlaceDashboardAction => Redux action for redirecting to place dashboard page
      @accepts placeId : string that represents the id of the place selected in the topbar
      @returns Promise
  */
  redirectToPlaceDashboardAction(placeId: string) {
    return (dispatch: Dispatch<any>) => {
      if (placeId) {
        const path = generatePath(partnerRoutes.DASHBOARD.default, { placeId });
        dispatch(push(path));
      }
    };
  }

  selectPlaceLogin = (placeId: string) => {
    return (dispatch: Dispatch<any>) => {
      dispatch({
        type: PlacesStore.ActionTypes.SELECT_PLACE,
        payload: placeId
      });
    };
  };

  /*
    @function inheritAutopilotRules => Redux action for inheritting default autopilot rules for a place
      @accepts placeId : string that represents the id of the place you want to inherit autopilot rules to
               partnerId : string that represents the id of the partner to whom the place belongs to
      @returns Promise
  */
  inheritAutopilotRules(placeId: string, partnerId: string) {
    return async (dispatch: Dispatch<any>) => {
      AlertUtil.simple(msg('alertMessages.autopilotsInherited', 'Autopilot rules are being inherited...'), 'info');
      dispatch({ type: PlacesStore.ActionTypes.INHERIT_AUTOPILOTS });
      try {
        logger.msg('Inherit autopilots action, route:/places/placeId/inherit-autopilot', 'GET');
        const response: any = await Server.get(`places/${placeId}/inherit-autopilot`);
        dispatch({
          type: PlacesStore.ActionTypes.INHERIT_AUTOPILOTS_SUCCESS,
          payload: response.data
        });
        dispatch(AutopilotStore.actions.getAutopilotListForPlaceAction(placeId, partnerId));
      } catch (error) {
        logger.err('Inherit autopilots action, route:/places/placeId/inherit-autopilot', 'GET');
        AlertUtil.updateContent(
          msg(
            'reduxMessages.autopilot.inheritAutopilotError',
            'Due to an error, the autopilot rules could not be inherited!'
          ),
          'error'
        );
        dispatch({
          type: PlacesStore.ActionTypes.INHERIT_AUTOPILOTS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  /*
    @function inheritRewardsAction => Redux action for inheritting default rewards for a place
      @accepts placeId : string that represents the id of the place you want to inherit rewards to
      @returns Promise
  */
  inheritRewardsAction(placeId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PlacesStore.ActionTypes.INHERIT_REWARDS });
      try {
        logger.msg('Inherit rewards action, route:/places/placeId/inherit-reward', 'GET');
        const response: any = await Server.get(`places/${placeId}/inherit-reward`);
        dispatch({
          type: PlacesStore.ActionTypes.INHERIT_REWARDS_SUCCESS,
          payload: response.data
        });
        dispatch(RewardsStore.actions.getRewardsListForPlaceAction(placeId));
      } catch (error) {
        logger.err('Inherit rewards action, route:/places/placeId/inherit-reward', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.rewards.inheritRewardsError', 'Due to an error, the rewards could not be inherited!'),
          'error',
          2000
        );
        dispatch({
          type: PlacesStore.ActionTypes.INHERIT_REWARDS_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  addPlaceAdminByEmail(email: string, placeId: string) {
    return async (dispatch: Dispatch<any>) => {
      try {
        logger.msg('Add place admin by email action, route:/places/placeId/adminByEmail', 'POST');
        dispatch({ type: PlacesStore.ActionTypes.ADD_PLACE_ADMIN_BY_EMAIL });
        AlertUtil.simple(msg('reduxMessages.users.givePlaceAdminPending', 'Adding place administrator'), 'info');
        await Server.post(`places/${placeId}/adminByEmail`, { email });
        dispatch({ type: PlacesStore.ActionTypes.ADD_PLACE_ADMIN_BY_EMAIL_SUCCESS });
        AlertUtil.simple(msg('reduxMessages.users.givePlaceAdminSuccess', 'Succesfully added place admin'), 'success');
        dispatch(UsersStore.actions.getAdminsForPlaceAction(placeId));
      } catch (error) {
        if (error.response?.data?.ERROR?.code === '1004') {
          AlertUtil.simple(msg('reduxMessages.users.givePlaceAdminErrorNotFound', 'User not found!'), 'error');
        } else {
          AlertUtil.simple(msg('reduxMessages.users.givePlaceAdminError', 'Error while adding place admin'), 'error');
        }
        logger.err('Add place admin by email action, route:/places/placeId/adminByEmail', 'POST');
        dispatch({ type: PlacesStore.ActionTypes.ADD_PLACE_ADMIN_BY_EMAIL_FAILED, payload: Server.errorParse(error) });
      }
    };
  }

  redirectToPlaceAdminsAction(placeId: string, forSAdmin: boolean) {
    if (forSAdmin) {
      return (dispatch: Dispatch<any>) => {
        const path = generatePath(adminRoutes.PLACES.subroutes.EDIT.subroutes.ADMINS.path, { placeId });
        dispatch(push(path));
      };
    } else {
      return (dispatch: Dispatch<any>) => {
        const path = generatePath(partnerRoutes.PLACE_ADMINS.default, { placeId });
        dispatch(push(path));
      };
    }
  }

  setPlaceAction(place: PlaceDTO) {
    return (dispatch: Dispatch<any>) => {
      dispatch({ type: PlacesStore.ActionTypes.SET_PLACE, payload: place });
    };
  }

  getNamesListForPlacesAction(placeId: string) {
    return async (dispatch: Dispatch<any>) => {
      dispatch({ type: PlacesStore.ActionTypes.GET_NAMES_LIST_OF_PLACES });
      try {
        logger.msg('Get places name list action, route:/places/placeId/names', 'GET');
        const response: any = await Server.get(`places/${placeId}/names`);
        dispatch({
          type: PlacesStore.ActionTypes.GET_NAMES_LIST_OF_PLACES_SUCCESS,
          payload: response.data as PaginationDTO<SelectValueDTO>
        });
      } catch (error) {
        logger.err('Get places name list action, route:/places/placeId/names', 'GET');
        AlertUtil.simple(
          msg('reduxMessages.places.getPlacesError', 'Due to an error, the places list could not be loaded!'),
          'error',
          2000
        );
        dispatch({
          type: PlacesStore.ActionTypes.GET_NAMES_LIST_OF_PLACES_FAILED,
          payload: Server.errorParse(error)
        });
      }
    };
  }

  resetPinCode(isSAdmin: boolean, placeId: string, newPinCode: string, oldPinCode?: string) {
    return async (dispatch: Dispatch<any>) => {
      logger.msg('Edit pin code action, route:/places/placeId/pin-code', 'PUT');
      dispatch({
        type: PlacesStore.ActionTypes.RESET_PIN_CODE
      });
      AlertUtil.simple(
        msg('reduxMessages.places.resetPinCode', 'The place pin code is being updated, please wait...'),
        'info',
        3000
      );
      let url;
      if (isSAdmin) {
        url = `admin/pin-code/${placeId}`;
      } else {
        url = `places/${placeId}/pin-code`;
      }
      const body = isSAdmin ? { pinCode: newPinCode } : { newPinCode, oldPinCode };
      await Server.put(url, body)
        .then((response: AxiosResponse) => {
          if (response.data.success) {
            dispatch({
              type: PlacesStore.ActionTypes.RESET_PIN_CODE_SUCCESS,
              payload: response.data
            });
            AlertUtil.fireOnce(
              msg('reduxMessages.places.resetPinCodeSuccess', 'The place pin code was successfully updated!'),
              'success'
            );
          } else {
            AlertUtil.fireOnce(
              msg(
                'reduxMessages.places.resetPinWrongCurrentCode',
                'The current pin code you introduced is not correct'
              ),
              'error'
            );
          }
        })
        .catch(error => {
          logger.err('Edit pin code action, route:/places/placeId/pin-code', 'PUT');
          AlertUtil.fireOnce(
            msg('reduxMessages.places.resetPinCodeError', 'Due to an error, the place pin code could not be updated!'),
            'error'
          );
          dispatch({
            type: PlacesStore.ActionTypes.RESET_PIN_CODE_FAILED,
            payload: Server.errorParse(error)
          });
        });
    };
  }
}

const placesActions = new PlacesActions();
export default placesActions;
