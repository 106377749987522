import * as React from 'react';
import { AlertUtil, msg } from '../../../../utils';
import { Bounce } from 'react-activity';
import * as _ from 'lodash';
import { UserFormComponent, StepComponent, FinalMessageComponent, SettingsComponent } from '.';
import { PlacePhotosComponent, PlaceFormComponent } from '../../places/components/forms';
import {
  RewardDTO,
  PlaceDTO,
  Step1DTO,
  SelectValueDTO,
  Step3SettingsDTO,
  Step3FlagsDTO
} from '../../../../appRedux/types';
import { ButtonComponent, CardComponent } from '../../../shared';
import { spinnerOverlayStyle } from '../../../shared/AdvancedList/AdvancedListStyles';
import { FooterAuthComponent, NavAuthComponent } from '../../auth/components';
import backgroundImage from '../../../../assets/images/background.jpg';
import { RewardsListComponent } from '../../rewards/components';
import { colors } from '../../../../assets/styles';

interface IProps {
  config: {
    sendOnboardingAction: (form: any) => any;
    send_onboarding_pending: boolean;
    send_onboarding_error: string | null;
    getOnboardingAction: (onboardingId: string) => any;
    get_onboarding_pending: boolean;
    updateOnboardingAction: (onboardingId: string, form: any) => any;
    update_onboarding_pending: boolean;
    update_onboarding_error: string | null;
    onboarding: any;
    industries: Array<SelectValueDTO>;
    getShortIndustriesListAction: () => any;
    alternative_address_flag: boolean | null;
    getFlagsForOnboarding: () => any;
  };
  navAuthConfig: {
    title: string;
    initial_language: string;
    changeLanguage: () => any;
  };
}
interface IState {
  onboarding: {
    step1: Step1DTO;
    step2: PlaceDTO;
    step3: {
      placeSettings: Step3SettingsDTO;
      placeFeatureFlags: Step3FlagsDTO;
      placeRewards: Array<RewardDTO>;
    };
    actualStep: number;
    version?: number;
  };
  stepNo: number;
  isDirty: boolean;
  initialRender: boolean;
  nextMove: number;
  changePassword: boolean;
}

export default class OnboardComponent extends React.Component<IProps, IState> {
  myRefs: any;
  constructor(props: IProps) {
    super(props);
    this.state = {
      onboarding: {
        step1: new Step1DTO(),
        step2: new PlaceDTO(),
        step3: { placeSettings: new Step3SettingsDTO(), placeFeatureFlags: new Step3FlagsDTO(), placeRewards: [] },
        actualStep: 1
      },
      stepNo: 1,
      initialRender: true,
      isDirty: false,
      nextMove: 0,
      changePassword: false
    };
    this.myRefs = {
      step1: React.createRef(),
      step2: React.createRef(),
      step3: { settings: React.createRef(), rewards: React.createRef() }
    };
  }

  componentDidMount() {
    const onboardingId = window.location.pathname.split('/')[2];
    if (onboardingId) {
      this.props.config.getOnboardingAction(onboardingId);
    }
    this.props.config.getShortIndustriesListAction();
    this.props.config.getFlagsForOnboarding();
  }

  // tslint:disable-next-line: member-ordering
  static getDerivedStateFromProps(props: IProps, state: IState) {
    const { send_onboarding_pending, update_onboarding_pending } = props.config;
    if (send_onboarding_pending || update_onboarding_pending) {
      return null;
    }
    const { send_onboarding_error, update_onboarding_error } = props.config;
    if (send_onboarding_error || update_onboarding_error) {
      return { nextMove: 0 };
    }
    if (props.config.onboarding && !state.isDirty) {
      let onboarding = state.onboarding;
      if (!_.isEqual(props.config.onboarding, state.onboarding)) {
        onboarding = _.cloneDeep(props.config.onboarding);
        if (onboarding.step2 && !onboarding.step2.name) {
          onboarding.step2 = new PlaceDTO();
        }
        if (!onboarding.step3) {
          onboarding.step3 = {
            placeFeatureFlags: new Step3FlagsDTO(),
            placeSettings: new Step3SettingsDTO(),
            placeRewards: []
          };
        }
      }
      if (props.config.alternative_address_flag) {
        onboarding.step2.featureFlags = {
          alternativeAddress: props.config.alternative_address_flag
        };
      }
      let { stepNo, nextMove, initialRender } = state;
      if (nextMove) {
        stepNo += nextMove;
        nextMove = 0;
      } else if (initialRender) {
        stepNo = onboarding.actualStep;
      }
      return { onboarding, stepNo, nextMove, initialRender: false, changePassword: true };
    }
    return null;
  }

  handleImageUpload = (event: string[], type: string) => {
    const { onboarding } = this.state;
    event.forEach((item: any) => {
      switch (type) {
        case 'MAP':
          onboarding.step2.currentMapPhoto = item.url;
          break;
        case 'LOGO':
          onboarding.step2.currentLogoPhoto = item;
          break;
        case 'COVER':
          onboarding.step2.currentCoverPhoto = item;
          break;
        default:
          onboarding.step2.media.push(item);
          break;
      }
    });
    this.setState({ onboarding, isDirty: true });
  };

  handleImageDelete = (id: number) => {
    const { onboarding } = this.state;
    const media = onboarding.step2.media.filter((img: any) => img.id !== id);
    onboarding.step2 = { ...onboarding.step2, media };
    this.setState({ onboarding, isDirty: true });
  };

  checkValidRewards = (rewards: Array<RewardDTO>) => {
    if (!rewards || rewards.length === 0) {
      return false;
    }
    return rewards.some(reward => reward.title);
  };

  checkIsValid = (currentStep: string) => {
    let valid = true;
    const currentRef = this.myRefs && this.myRefs[currentStep];
    if (currentRef?.current?.childRef?.current) {
      valid = currentRef?.current?.childRef?.current?.isValid();
    } else if (currentRef?.current) {
      valid = currentRef.current.isValid && currentRef.current.isValid();
    } else {
      Object.keys(currentRef).forEach(refName => {
        if (currentRef[refName]?.current?.childRef?.current) {
          valid = currentRef[refName]?.current?.childRef?.current?.isValid() && valid;
        } else if (currentRef[refName]?.current) {
          valid = currentRef[refName].current.isValid && currentRef[refName].current.isValid() && valid;
        }
      });
    }
    const step3 = this.state.onboarding.step3;
    if (currentStep === 'step3' && step3.placeFeatureFlags?.checkin && !this.checkValidRewards(step3.placeRewards)) {
      AlertUtil.simple(msg('onboard.step3Warning', 'You must have at least one reward completed'), 'warning');
      valid = false;
    } else if (
      this.state.onboarding.version === 2 &&
      currentStep === 'step3' &&
      (step3.placeFeatureFlags?.becomeMember === undefined ||
        (step3.placeFeatureFlags?.becomeMember && step3.placeFeatureFlags?.checkin === undefined) ||
        (step3.placeFeatureFlags?.checkin && step3.placeFeatureFlags?.sendReceipts === undefined) ||
        step3.placeFeatureFlags?.moments === undefined)
    ) {
      AlertUtil.simple(msg('onboard.step3FlagsWarning', 'Please complete the entire form!'), 'warning');
      valid = false;
    }
    return valid;
  };

  stepAction = (action: number) => () => {
    let { stepNo, isDirty, nextMove, onboarding } = this.state;
    const actualStep = this.props.config?.onboarding?.actualStep;
    const onboardingId = window.location.pathname.split('/')[2];
    const currentStep = `step${stepNo}`;
    if (action < 0 && !isDirty) {
      nextMove = action;
    } else if (action > 0 && !isDirty && stepNo < actualStep) {
      nextMove = action;
    } else if (this.checkIsValid(currentStep)) {
      const { sendOnboardingAction, updateOnboardingAction } = this.props.config;
      if (!onboardingId) {
        sendOnboardingAction({ [currentStep]: onboarding[currentStep] });
      } else {
        let value = onboarding[currentStep];
        if (this.checkValidRewards(value.placeRewards)) {
          const placeRewards = value.placeRewards.filter((reward: RewardDTO) => reward.dirty || reward.title);
          value.placeRewards = placeRewards;
        }
        updateOnboardingAction(onboardingId, { [currentStep]: value });
      }
      isDirty = false;
      nextMove = action;
    }
    this.setState({ isDirty, nextMove });
  };

  handleChange = (type: any) => (value: any, key?: string) => {
    let onboarding = { ...this.state.onboarding };
    const labels = type.split('.');
    if (labels.length === 2) {
      onboarding[labels[0]][labels[1]] = value;
    } else if (type === 'step3' && key) {
      onboarding[type][key] = value;
    } else {
      onboarding[type] = { ...onboarding[type], ...value };
    }
    this.setState({ onboarding, isDirty: true });
  };

  changeStepAction = (nextStep: number) => {
    const { stepNo } = this.state;
    const actualStep = this.props.config?.onboarding?.actualStep;
    if (nextStep !== stepNo && nextStep <= actualStep) {
      const nextMove = nextStep - this.state.stepNo;
      this.stepAction(nextMove)();
    }
  };

  renderLoading = () => (
    <div style={{ ...spinnerOverlayStyle }}>
      <h4 style={{ color: colors.white }}>{msg('general.loading', 'Loading...')}</h4>
      <Bounce color={colors.white} />
    </div>
  );

  renderBottomButtons = () => (
    <div style={{ display: 'flex', flex: 1 }}>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        {this.state.stepNo !== 1 && (
          <ButtonComponent
            label={msg('onboard.previous', 'Previous step')}
            icon="navigate_before"
            action={this.stepAction(-1)}
          />
        )}
      </div>
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
        {this.state.stepNo !== 4 && (
          <ButtonComponent
            label={msg('onboard.next', 'Next step')}
            icon="navigate_next"
            swapIconText={true}
            action={this.stepAction(1)}
          />
        )}
      </div>
    </div>
  );

  getOnboardingStatus = () => {
    const onboarding = this.props.config.onboarding;
    if (onboarding && onboarding.config) {
      if (
        onboarding?.config?.partnerId &&
        onboarding?.config?.placeId &&
        onboarding?.config?.autopilotsCreated &&
        onboarding?.config?.rewardsCreated &&
        onboarding?.config?.userId
      ) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { onboarding, stepNo, changePassword } = this.state;
    const { step1, step2, step3 } = onboarding;
    const extraId =
      this.props.config.onboarding && this.props.config.onboarding._id ? this.props.config.onboarding._id : null;
    if (this.props.config.get_onboarding_pending) {
      return this.renderLoading();
    }
    const actualStep = this.props.config?.onboarding?.actualStep;
    const onboardingStatus = this.getOnboardingStatus();
    return (
      <div className="partner-auth off-canvas-sidebar" style={backgroundStyle.container}>
        <NavAuthComponent config={this.props.navAuthConfig} />
        <div className="wrapper wrapper-full-page">
          <div className="full-page login-page" filter-color="black">
            <div className="content" style={{ paddingTop: '18vh' }}>
              <div className="container">
                <StepComponent stepNo={stepNo} actualStep={actualStep || 1} changeStepAction={this.changeStepAction} />
                {stepNo === 1 && (
                  <UserFormComponent
                    user={step1}
                    changePassword={changePassword}
                    handleChange={this.handleChange('step1')}
                    ref={this.myRefs.step1}
                  />
                )}
                {stepNo === 2 && (
                  <>
                    <CardComponent
                      title={msg('onboard.placeDetails', 'Place details')}
                      headerIcon="photo_library"
                      needsTitle={true}
                    >
                      <PlaceFormComponent
                        forSAdmin={false}
                        place={step2}
                        showIndustry={true}
                        industries={this.props.config.industries}
                        output={true}
                        onChange={this.handleChange('step2')}
                        ref={this.myRefs.step2}
                        extraId={extraId}
                        photos={{
                          url: `onboarding/media/${extraId}`,
                          handleUpload: this.handleImageUpload
                        }}
                      />
                    </CardComponent>
                    <CardComponent
                      title={msg('onboard.placePhotos', 'Place photos')}
                      headerIcon="photo_library"
                      needsTitle={true}
                    >
                      <PlacePhotosComponent
                        forSAdmin={false}
                        place={step2}
                        photos={{
                          url: `onboarding/media/${extraId}`,
                          handleUpload: this.handleImageUpload,
                          handleDelete: this.handleImageDelete
                        }}
                        extraId={extraId}
                      />
                    </CardComponent>
                  </>
                )}
                {stepNo === 3 &&
                  (onboarding.version === 2 && onboarding.step3.placeFeatureFlags ? (
                    <SettingsComponent
                      settings={step3}
                      extraId={extraId}
                      ref={this.myRefs.step3.settings}
                      forwardRef={this.myRefs.step3.rewards}
                      handleChange={this.handleChange('step3')}
                    />
                  ) : (
                    <RewardsListComponent
                      forSAdmin={false}
                      forPlace={false}
                      output={true}
                      config={{ rewards_list: step3?.placeRewards }}
                      handleChange={this.handleChange('step3')}
                      ref={this.myRefs.step3.rewards}
                      mediaURL={`onboarding/media/${extraId}`}
                      showLabelInfo={true}
                    />
                  ))}
                {stepNo === 4 && <FinalMessageComponent status={onboardingStatus} />}
                {this.renderBottomButtons()}
              </div>
            </div>
            <FooterAuthComponent />
            <div className="full-page-background" style={backgroundStyle.image} />
          </div>
        </div>
      </div>
    );
  }
}

const backgroundStyle = {
  container: {
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundPositionX: 'center',
    backgroundPositionY: 'top',
    backgroundColor: colors.black
  },
  image: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'inherit',
    height: '100vh'
  }
};
