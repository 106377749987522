import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Bounce } from 'react-activity';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Button, Modal } from 'react-bootstrap';

import { RaffleIntervalDTO } from '../../../../appRedux/types';
import { colors } from '../../../../assets/styles';
import { msg } from '../../../../utils';
import { ButtonComponent, TextFieldComponent } from '../../../shared';
import { spinnerOverlayStyle } from '../../../shared/AdvancedList/AdvancedListStyles';

interface IProps {
  placeId: string;
  eventId: string;
  raffle_preview: RaffleIntervalDTO[] | null;
  raffle_preview_pending: boolean;
  update_raffle_preview_pending: boolean;
  totalPrizes?: number;
  editMode: boolean;
  getRaffleIntervals: (placeId: string, eventId: string) => any;
  toggleModal: () => any;
  handleSave: (preview: RaffleIntervalDTO[], modalDate?: number) => any;
}

const localizer = momentLocalizer(moment);

const RaffleModal = (props: IProps) => {
  const [preview, setPreview] = useState<RaffleIntervalDTO[]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [modalDate, setModalDate] = useState<number>();
  const [errors, setErrors] = useState<any>({});
  let intervalId = useRef<any>(null);
  const messages = {
    previous: msg('pagination.back', 'Back'),
    next: msg('pagination.next', 'Next'),
    today: msg('event.today', 'Today'),
    week: msg('event.week', 'Stats'),
    agenda: msg('event.agenda', 'Configure')
  };

  useEffect(() => {
    if (props.editMode && process.env.REACT_APP_RAFFLE_REFRESH_INTERVAL) {
      intervalId.current = setInterval(intervalHandler, Number(process.env.REACT_APP_RAFFLE_REFRESH_INTERVAL));
    }
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
    // tslint:disable-next-line: align
  }, []);

  useEffect(() => {
    if (props.raffle_preview) {
      const newPreview = cloneDeep(props.raffle_preview);
      setPreview(newPreview);
    }
    // tslint:disable-next-line: align
  }, [props.raffle_preview]);

  const intervalHandler = () => {
    props.getRaffleIntervals(props.placeId, props.eventId);
  };

  const parsePreview = () => {
    return preview?.map((item: RaffleIntervalDTO, index: number) => {
      const endDate = new Date(item.date);
      endDate.setHours(endDate.getHours() + 1);
      return {
        id: index,
        key: index,
        start: new Date(item.date),
        end: endDate,
        title: item.prizes - (item.reallocated || 0),
        resource: {
          handleChange,
          participants: item.participants,
          winners: item.winners,
          reallocated: item.reallocated,
          used: item.used,
          editMode: props.editMode,
          error: errors[`prizeRule_${index}`]
        }
      };
    });
  };

  const handleChange = (fieldIndex: number, fieldValue: number) => {
    if (!isDirty) {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      setModalDate(moment().valueOf());
      setIsDirty(true);
    }
    if (fieldValue < 0) {
      setErrors({
        ...errors,
        [`prizeRule_${fieldIndex}`]: msg('formValidation.onlyPositiveNumbers', 'Use only positive numbers!')
      });
    } else {
      delete errors[`prizeRule_${fieldIndex}`];
    }
    const newPreview =
      preview?.map((item, index) => {
        if (index === fieldIndex) {
          item.prizes = fieldValue;
        }
        return item;
      }) || [];
    setPreview(newPreview);
  };

  const handleSave = () => {
    props.handleSave(preview, modalDate);
    setIsDirty(false);
    if (!props.editMode) {
      props.toggleModal();
    }
  };

  const getCurrentDate = () => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    return date;
  };

  const getDistributedPrizes = () => {
    return (
      preview?.reduce(
        (accumulator: number, current: RaffleIntervalDTO) => accumulator + current.prizes - (current.reallocated || 0),
        0
      ) || 0
    );
  };

  const getTimeRange = () => {
    let minHour = 24;
    let maxHour = 0;
    preview.forEach((item: RaffleIntervalDTO) => {
      const hour = new Date(item.date).getHours();
      if (hour < minHour) {
        minHour = hour;
      } else if (hour > maxHour) {
        maxHour = hour;
      }
    });
    const dateMin = new Date();
    dateMin.setHours(minHour ? minHour - 1 : 0);
    dateMin.setMinutes(0);
    const dateMax = new Date();
    dateMax.setHours(maxHour < 23 ? maxHour + 1 : maxHour);
    dateMax.setMinutes(0);
    return { min: dateMin, max: dateMax };
  };

  const distributedPrizes = getDistributedPrizes();
  const timeRange = getTimeRange();
  const lastInterval = preview.length ? moment(preview[preview.length - 1].date) : moment();
  const isInPast = lastInterval.isBefore(new Date());
  const totalWinners = preview?.reduce(
    (accumulator: number, current: RaffleIntervalDTO) => accumulator + (current?.winners || 0),
    0
  );
  const totalParticipants = preview?.reduce(
    (accumulator: number, current: RaffleIntervalDTO) => accumulator + (current?.participants || 0),
    0
  );
  return (
    <Modal show={true} onHide={props.toggleModal} dialogClassName="customEventModal">
      <Modal.Header>
        <Modal.Title style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>{msg('event.prizeDistribution', 'Raffle Prize Distribution')}</div>
          <div>
            <div>{msg('event.totalPrizes', 'Total number of prizes:') + ' ' + props.totalPrizes}</div>
            <div
              style={{
                color: props.totalPrizes === distributedPrizes || !distributedPrizes ? 'initial' : colors.red
              }}
            >
              {msg('event.distributedPrizes', 'Distributed prizes') + ' ' + distributedPrizes}
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: '60vh', overflowY: 'auto', overflowX: 'hidden' }}>
          {props.raffle_preview_pending && !props.raffle_preview ? (
            <div style={{ ...spinnerOverlayStyle }}>
              <h4 style={{ color: colors.white }}>{msg('general.loading', 'Loading...')}</h4>
              <Bounce color={colors.white} />
            </div>
          ) : preview?.length && props.raffle_preview ? (
            <>
              <div style={styles.legendContainer}>
                <span style={{ ...styles.legend, backgroundColor: colors.purple }} /> -{' '}
                {msg('event.legendPrizes', 'Prizes')}
                {props.editMode && (
                  <>
                    <span style={{ ...styles.legend, backgroundColor: colors.blue }} />
                    {` - ${msg('event.legendParticipants', 'Participants')} (total: ${totalParticipants})`}
                    <span style={{ ...styles.legend, backgroundColor: colors.green }} />
                    {` - ${msg('event.legendWinners', 'Winners')} (total: ${totalWinners})`}
                  </>
                )}
              </div>
              <div style={{ height: '55vh' }}>
                <Calendar
                  key="calendar"
                  localizer={localizer}
                  events={parsePreview()}
                  components={{
                    week: { event: CustomEvent }
                  }}
                  getNow={getCurrentDate}
                  defaultDate={isInPast ? new Date(lastInterval.format()) : new Date()}
                  messages={messages}
                  defaultView="agenda"
                  views={{
                    agenda: AgendaView,
                    week: true
                  }}
                  formats={{
                    eventTimeRangeFormat: () => ''
                  }}
                  min={timeRange.min}
                  max={timeRange.max}
                />
              </div>
            </>
          ) : (
            <h3 style={{ marginTop: 60 }}>{msg('general.noAvailableData', 'There is no available data.')}</h3>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-danger" onClick={props.toggleModal}>
          {msg('general.close', 'Close')}
        </Button>
        <ButtonComponent
          styles={{ marginTop: 0 }}
          label={msg('general.save', 'Save')}
          icon="save"
          action={handleSave}
          pending={props.update_raffle_preview_pending}
          disabled={
            props.totalPrizes !== distributedPrizes || (props.editMode && !isDirty) || !!Object.keys(errors).length
          }
        />
      </Modal.Footer>
    </Modal>
  );
};

export default RaffleModal;

const CustomEvent = (props: any) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'space-evenly' }}>
      <span style={{ ...styles.badge, backgroundColor: colors.purple, margin: 5 }}>{props.event.title}</span>
      {props.event.resource.editMode && (
        <>
          <span style={{ ...styles.badge, backgroundColor: colors.blue }}>{props.event.resource.participants}</span>
          <span style={{ ...styles.badge, backgroundColor: colors.green }}>{props.event.resource.winners}</span>
        </>
      )}
    </div>
  );
};

const AgendaView = (props: any) => {
  const editMode = props.events[0].resource.editMode;
  const onChange = (event: any) => {
    const fieldIndex = Number(event.target.getAttribute('id'));
    const fieldValue = Number(event.target.value);
    props.events[0].resource.handleChange(fieldIndex, fieldValue);
  };

  const renderDay = (events: any) =>
    events.map((event: any, idx: number) => {
      const pastEvent = moment().isSameOrAfter(event.start);
      return (
        <tr key={idx}>
          <td className="rbc-agenda-time-cell" style={{ verticalAlign: 'middle' }}>
            {timeRangeLabel(event)}
          </td>
          <td className="rbc-agenda-time-cell">
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <div className="col-md-3 col-xl-2" style={{ minWidth: 60 }}>
                <TextFieldComponent
                  id={event.id + ''}
                  type="number"
                  value={event.title}
                  onChange={onChange}
                  disabled={event.resource.editMode && pastEvent}
                  inputStyle={{ color: colors.purple }}
                  formatError={event.resource.error}
                />
              </div>
            </div>
          </td>
          {editMode && (
            <>
              <td style={{ verticalAlign: 'middle', color: colors.blue }}>
                {pastEvent ? event.resource.participants : 'N/A'}
              </td>
              <td style={{ verticalAlign: 'middle', color: colors.green }}>
                {pastEvent ? event.resource.winners : 'N/A'}
              </td>
              <td style={{ verticalAlign: 'middle' }}>{pastEvent ? event.resource.used : 'N/A'}</td>
              <td style={{ verticalAlign: 'middle' }}>{pastEvent ? event.resource.reallocated : 'N/A'}</td>
            </>
          )}
        </tr>
      );
    });

  const timeRangeLabel = (event: any) => {
    return `${moment(event.start).format('HH:mm')} – ${moment(event.end).format('HH:mm')}`;
  };

  const filteredEvents = props.events.filter((item: any) =>
    moment(item.start).startOf('day').isSame(props.date, 'day')
  );
  return (
    <div className="rbc-agenda-view">
      {filteredEvents.length !== 0 ? (
        <table className="rbc-agenda-table" style={{ borderTop: 'none' }}>
          <thead className="sticky-header sticky-border">
            <tr>
              <th className="rbc-header">{msg('event.time', 'Time')}</th>
              <th className="rbc-header">{msg('event.prizeDistribution', 'Prize Distribution')}</th>
              {editMode && (
                <>
                  <th className="rbc-header">{msg('event.legendParticipants', 'Participants')}</th>
                  <th className="rbc-header">{msg('event.legendWinners', 'Winners')}</th>
                  <th className="rbc-header">{msg('event.used', 'Used')}</th>
                  <th className="rbc-header">{msg('event.reallocated', 'Reallocated Prizes')}</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>{renderDay(filteredEvents)}</tbody>
        </table>
      ) : (
        <div style={{ fontSize: 18, padding: '10px 5px' }}>
          {msg('event.noEvents', 'There is no data for this day')}
        </div>
      )}
    </div>
  );
};

AgendaView.title = (start: any) => {
  return moment(start).format('DD-MM-YYYY');
};

AgendaView.navigate = (date: any, action: any) => {
  const sDate = moment(date).startOf('day');
  switch (action) {
    case 'PREV':
      return sDate.add(-1, 'day').toDate();
    case 'NEXT':
      return sDate.add(1, 'day').toDate();
    default:
      return date.toDate();
  }
};

const styles: { [key: string]: CSSProperties } = {
  badge: {
    textAlign: 'center',
    padding: 8,
    borderRadius: 15,
    minWidth: 32
  },
  legendContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 15
  },
  legend: {
    padding: 10,
    borderRadius: 15,
    margin: '0 5px 0 10px'
  }
};
