import React from 'react';
import ImageGallery from 'react-image-gallery';

import HighlightOff from '@material-ui/icons/HighlightOff';

import { PlaceDTO } from '../../../../../appRedux/types';
import coverPlaceholder from '../../../../../assets/images/coverPlaceholder.jpg';
import galleryPlaceholder from '../../../../../assets/images/galleryPlaceholder.jpg';
import logoPlaceholder from '../../../../../assets/images/logoPlaceholder.jpg';
import { AlertUtil, getRegularMedia, msg, parseImagesForGallery } from '../../../../../utils';
import { UploadComponent } from '../../../../shared';
import styles from '../../../../shared/photoStyles/Photos.module.css';

interface IProps {
  place?: PlaceDTO | null;
  get?: (placeId: string) => any;
  delete?: (placeId: string, mediaId: string) => any;
  forSAdmin: boolean;
  photos?: { url: string; handleUpload: any; handleDelete?: any };
  extraId?: string;
}
interface IState {
  place: PlaceDTO;
  galleryMedia: any;
}

export default class PlacePhotosComponent extends React.Component<IProps, IState> {
  _imageGallery: any;
  url: string;
  constructor(props: IProps) {
    super(props);
    this.state = {
      place: props.place || new PlaceDTO(),
      galleryMedia: (props.place && parseImagesForGallery(getRegularMedia(props.place))) || []
    };
    this.url = (props.photos && props.photos.url) || `media/place/${this.state.place._id}`;
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.place && this.props.place !== prevProps.place) {
      this.setState({
        place: this.props.place,
        galleryMedia: parseImagesForGallery(getRegularMedia(this.props.place))
      });
    }
  }

  deleteImage = async (index: number) => {
    let { place, galleryMedia } = this.state;
    const selectedMedia = galleryMedia.find((img: any) => img.index === index);
    const hasConfirmation = await AlertUtil.confirm(
      msg('alertMessages.galleryImageConfirmation', 'You are about to delete an image!')
    );
    if (hasConfirmation) {
      if (this.props.photos) {
        this.props.photos.handleDelete(selectedMedia.id);
      } else if (selectedMedia && this.props.delete) {
        this.props.delete(place._id, selectedMedia.id);
      }
    }
  };

  handleSuccess = (res: any, imageType: string) => {
    const { place } = this.state;
    if (this.props.photos) {
      this.props.photos.handleUpload(res.data, imageType);
      switch (imageType) {
        case 'LOGO':
          place.currentLogoPhoto = res.data[0];
          break;
        case 'COVER':
          place.currentCoverPhoto = res.data[0];
          break;
        case 'REGULAR':
          this.setState({ galleryMedia: parseImagesForGallery(getRegularMedia(place)) });
          break;
        default:
          break;
      }
    } else {
      switch (imageType) {
        case 'LOGO':
          place.currentLogoPhoto = res.data[0];
          break;
        case 'COVER':
          place.currentCoverPhoto = res.data[0];
          break;
        case 'REGULAR':
          const result = getRegularMedia(place);
          result.push(...res.data);
          const placePhotos = place.media;
          placePhotos.push(...res.data);
          this.setState({ galleryMedia: parseImagesForGallery(result), place: { ...place, media: placePhotos } });
          break;
        default:
          break;
      }
    }
    this.setState({ place });
  };

  render() {
    const { place, galleryMedia } = this.state;
    const extraId = this.props.extraId ? this.props.extraId : place._id;
    return (
      <>
        <div className={styles.images + ' flex items-stretch'}>
          <div className="row" style={{ width: '100%', margin: 0 }}>
            <div className="col-sm-6">
              <div className="row" style={{ textAlign: 'center' }}>
                <legend>{msg('place.logo', 'Place Logo')}</legend>
              </div>
              <div className={styles.logo}>
                {place.currentLogoPhoto ? (
                  <div
                    className="logo-image"
                    style={{
                      background: `url(${encodeURI(place.currentLogoPhoto.url)})`
                    }}
                  />
                ) : (
                  <div className="logo-image" style={{ background: `url(${logoPlaceholder})` }} />
                )}
              </div>
              <div className="flex justify-center">
                <UploadComponent
                  url={`${this.url}?type=LOGO`}
                  accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
                  extraData={{ extraId }}
                  messages={{
                    default: msg('general.uploadImage', 'Upload images by drag-and-drop or click!')
                  }}
                  onSuccess={res => {
                    this.handleSuccess(res, 'LOGO');
                  }}
                  crop={true}
                  cropAspect={1 / 1}
                  recommendedResolution="240x240"
                />
              </div>
            </div>
            <div className="col-sm-6 cover-margin">
              <div className="row" style={{ textAlign: 'center', margin: 0 }}>
                <legend>{msg('place.coverPhoto', 'Place Cover Photo')}</legend>
              </div>
              <div
                className={styles.cover}
                style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', maxWidth: 475 }}
              >
                {place.currentCoverPhoto ? (
                  <div
                    className="cover-image image-ratio-cover"
                    style={{
                      background: `url(${encodeURI(place.currentCoverPhoto.url)})`
                    }}
                  />
                ) : (
                  <div className="cover-image image-ratio-cover" style={{ background: `url(${coverPlaceholder})` }} />
                )}
              </div>
              <div className="flex justify-center">
                <UploadComponent
                  url={`${this.url}?type=COVER`}
                  accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
                  extraData={{ extraId }}
                  messages={{
                    default: msg('general.uploadImage', 'Upload images by drag-and-drop or click!')
                  }}
                  onSuccess={res => {
                    this.handleSuccess(res, 'COVER');
                  }}
                  crop={true}
                  cropAspect={2 / 1}
                  recommendedResolution="480x240"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: 25 }}>
          <div className="col-sm-3" />
          <div className="col-sm-5">
            <div className="row" style={{ textAlign: 'center' }}>
              <legend>{msg('general.photoGallery', 'Photo Gallery')}</legend>
            </div>
            <div className="row">
              <ImageGallery
                ref={(ref: any) => (this._imageGallery = ref)}
                startIndex={galleryMedia.length > 0 ? galleryMedia.length - 1 : 0}
                items={
                  galleryMedia.length > 0
                    ? galleryMedia
                    : [
                        {
                          original: galleryPlaceholder,
                          thumbnail: galleryPlaceholder
                        }
                      ]
                }
              />
            </div>
          </div>
          <div className="col-sm-4">
            <HighlightOff
              className="deleteMediaCross image-gallery-icon"
              onClick={() => this.deleteImage(this._imageGallery.getCurrentIndex())}
            />
          </div>
        </div>
        <div className="row" style={{ marginBottom: 10 }}>
          <div className="col-sm-3" />
          <div className="col-sm-5">
            <UploadComponent
              url={`${this.url}?type=REGULAR`}
              accept={{ 'image/*': ['.png', '.jpeg', '.jpg'] }}
              extraData={{ extraId }}
              messages={{
                default: msg('general.uploadImage', 'Upload images by drag-and-drop or click!')
              }}
              onSuccess={res => {
                this.handleSuccess(res, 'REGULAR');
              }}
              multiple={true}
            />
          </div>
          <div className="col-sm-4" />
        </div>
      </>
    );
  }
}
