import * as React from 'react';

import { Hidden } from '@material-ui/core';

import { getFormatDate, msg } from '../../../../../utils';
import { CardComponent, ChartComponent, StatisticsListComponent } from '../../../../shared';
import {
    generateColors, generateDataForChart, generateDataForPieChart
} from '../ChartFunctionsForPlaceDashboard';

interface IProps {
  placeId: string;
  partnerId: string;
  agg_statistics_error: any;
  agg_statistics_pending: any;
  agg_statistics: any;
  type: string;
  pieChartOptions: any;
  stackedBarOptions: any;
}

const RewardsTab = (props: IProps) => {
  const createChartTotalRewardsDistribution = () => {
    let data: any = {};
    props.agg_statistics?.forEach((item: any) => {
      item.rewards?.forEach((rewardItem: any) => {
        if (!data[rewardItem.title]) {
          data[rewardItem.title] = 0;
        }
        data[rewardItem.title] += rewardItem.bought;
      });
    });
    return {
      labels: Object.keys(data),
      datasets: generateDataForPieChart(Object.values(data))
    };
  };

  const createChartRewards = () => {
    const titles = {};
    props.agg_statistics?.forEach((item: any) => {
      item.rewards?.forEach((rewardItem: any) => {
        titles[rewardItem.title] = [];
      });
    });
    let labels: any[] = [];
    let data: any = {};
    props.agg_statistics?.forEach((item: any) => {
      Object.keys(titles)?.forEach((title: any) => {
        const rewardFound = item.rewards?.find((statReward: any) => statReward.title === title);
        if (!data[title]) {
          data[title] = [];
        }
        data[title].push(rewardFound?.bought || 0);
      });
      let date = getFormatDate(item.startDate, 'date');
      if (props.type !== 'DAY') {
        date += ` - ${getFormatDate(item.endDate, 'date')}`;
      }
      labels.push(date);
    });
    return {
      labels,
      datasets: generateDataForChart(data)
    };
  };

  const parseRewards = () => {
    let data = {};
    props.agg_statistics?.forEach((item: any) => {
      item.rewards?.forEach((reward: any) => {
        if (data[reward.title]) {
          data[reward.title].costs += reward.points;
          data[reward.title].distributed += reward.bought;
        } else {
          data[reward.title] = {
            title: reward.title,
            costs: reward.points,
            distributed: reward.bought
          };
        }
      });
    });
    return Object.values(data);
  };

  const getRewardsListData = () => {
    const list = parseRewards();
    const listOfColors: any[] = generateColors(list);
    return {
      fields: {
        title: msg('sidebar.rewards', 'Rewards'),
        costs: msg('general.rewardCost', 'Reward Cost (Coins)'),
        distributed: msg('general.distributed', 'Distributed')
      },
      list: list,
      badgeColors: listOfColors,
      pending: props.agg_statistics_pending,
      error: props.agg_statistics_error
    };
  };

  return (
    <div style={{ marginTop: 5 }}>
      <CardComponent
        title={msg('charts.rewardStatistics', 'Rewards statistics')}
        error={!!props.agg_statistics_error}
        pending={props.agg_statistics_pending}
        headerIcon="card_giftcard"
      >
        <Hidden smDown={true}>
          <div className="row">
            <div className="col-lg-3">
              <h4 style={{ textAlign: 'center' }}>
                {msg('charts.totalRewardsDistribution', 'Total rewards distribution')}
              </h4>
              <div style={{ marginTop: 20 }}>
                <ChartComponent
                  data={createChartTotalRewardsDistribution()}
                  chartType="PIE"
                  chartHeight={280}
                  options={props.pieChartOptions}
                />
              </div>
            </div>
            <div className="col-lg-9">
              <h4 style={{ textAlign: 'center' }}>{msg('charts.rewardsPer', 'Rewards distributed')}</h4>
              <ChartComponent
                chartHeight={100}
                data={createChartRewards()}
                chartType="BAR"
                options={props.stackedBarOptions}
              />
            </div>
          </div>
        </Hidden>
        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-lg-12">
            {!props.agg_statistics_pending && <StatisticsListComponent data={getRewardsListData()} />}
          </div>
        </div>
      </CardComponent>
    </div>
  );
};

export default RewardsTab;
