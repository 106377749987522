export const spinnerOverlayStyle: React.CSSProperties = {
  position: 'absolute',
  top: '40%',
  left: '42%',
  width: 150,
  backgroundColor: 'rgba(85, 85, 85, 0.8)',
  textAlign: 'center',
  padding: 20,
  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.44)',
  borderRadius: 6
};

export const sortIconStyle: React.CSSProperties = {
  fontSize: 23,
  position: 'relative',
  left: 5,
  top: 5
};

export const upIconStyle: React.CSSProperties = {
  fontSize: 23,
  position: 'relative',
  top: 5
};
