import { PlacesStore } from './';
import { PaginationDTO } from '../types';

/*
  placesReducer = reducer for all actionTypes for Places
*/
function placesReducer(state: PlacesStore.IState = PlacesStore.initialState, action: IAction<any>): PlacesStore.IState {
  switch (action.type) {
    case PlacesStore.ActionTypes.GET_PLACES: {
      return {
        ...state,
        places_list_pending: true,
        places_list_error: null
      };
    }
    case PlacesStore.ActionTypes.GET_PLACES_SUCCESS: {
      return {
        ...state,
        places_list_pending: false,
        places_list_error: null,
        places_list: action.payload
      };
    }
    case PlacesStore.ActionTypes.GET_PLACES_FAILED: {
      return {
        ...state,
        places_list_pending: false,
        places_list: new PaginationDTO(),
        places_list_error: action.payload
      };
    }
    case PlacesStore.ActionTypes.GET_NAMES_LIST_OF_PLACES: {
      return {
        ...state,
        names_of_places_list_pending: true,
        names_of_places_list_error: null
      };
    }
    case PlacesStore.ActionTypes.GET_NAMES_LIST_OF_PLACES_SUCCESS: {
      return {
        ...state,
        names_of_places_list_pending: false,
        names_of_places_list_error: null,
        names_of_places_list: action.payload
      };
    }
    case PlacesStore.ActionTypes.GET_NAMES_LIST_OF_PLACES_FAILED: {
      return {
        ...state,
        names_of_places_list_pending: false,
        names_of_places_list_error: action.payload,
        names_of_places_list: []
      };
    }
    case PlacesStore.ActionTypes.GET_PARTNER_PLACES: {
      return {
        ...state,
        partner_places_list_pending: true,
        partner_places_list_error: null
      };
    }
    case PlacesStore.ActionTypes.GET_PARTNER_PLACES_SUCCESS: {
      return {
        ...state,
        partner_places_list_pending: false,
        partner_places_list_error: null,
        partner_places_list: action.payload
      };
    }
    case PlacesStore.ActionTypes.GET_PARTNER_PLACES_FAILED: {
      return {
        ...state,
        partner_places_list_pending: false,
        partner_places_list: new PaginationDTO(),
        partner_places_list_error: action.payload
      };
    }
    case PlacesStore.ActionTypes.GET_PLACE: {
      return {
        ...state,
        place_pending: true,
        place_error: null
      };
    }
    case PlacesStore.ActionTypes.GET_PLACE_SUCCESS: {
      return {
        ...state,
        place_pending: false,
        place_error: null,
        place: action.payload
      };
    }
    case PlacesStore.ActionTypes.GET_PLACE_FAILED: {
      return {
        ...state,
        place_pending: false,
        place: null,
        place_error: action.payload
      };
    }
    case PlacesStore.ActionTypes.SAVE_PLACE: {
      return {
        ...state,
        place_save_pending: true,
        place_save_error: null,
        place_saved: null
      };
    }
    case PlacesStore.ActionTypes.SAVE_PLACE_SUCCESS: {
      const results = state.places_list.results
        ? state.places_list.results.map(place => (place._id === action.payload._id ? action.payload : place))
        : [];
      return {
        ...state,
        place_save_pending: false,
        place_save_error: null,
        place_saved: action.payload,
        places_list: {
          ...state.places_list,
          results
        }
      };
    }
    case PlacesStore.ActionTypes.SAVE_PLACE_FAILED: {
      return {
        ...state,
        place_save_pending: false,
        place_save_error: action.payload,
        place_saved: null
      };
    }
    case PlacesStore.ActionTypes.CHANGE_PLACE_STATUS: {
      return {
        ...state,
        place_change_status_pending: true,
        place_change_status_error: null,
        place_change_status: null
      };
    }
    case PlacesStore.ActionTypes.CHANGE_PLACE_STATUS_SUCCESS: {
      const index = state.places_list.results
        .map(x => {
          return x._id;
        })
        .indexOf(action.payload._id);
      let new_list: PaginationDTO<any> = state.places_list;
      new_list.results[index] = action.payload;
      return {
        ...state,
        place_change_status: action.payload,
        place_change_status_pending: false,
        place_change_status_error: null,
        places_list: { ...new_list }
      };
    }
    case PlacesStore.ActionTypes.CHANGE_PLACE_STATUS_FAILED: {
      return {
        ...state,
        place_change_status: null,
        place_change_status_pending: false,
        place_change_status_error: null
      };
    }
    case PlacesStore.ActionTypes.DELETE_PLACE_MEDIA: {
      return {
        ...state,
        place_delete_media_pending: true,
        place_delete_media_error: null
      };
    }
    case PlacesStore.ActionTypes.DELETE_PLACE_MEDIA_SUCCESS: {
      return {
        ...state,
        place_delete_media_pending: false,
        place_delete_media_error: null,
        place: action.payload
      };
    }
    case PlacesStore.ActionTypes.DELETE_PLACE_MEDIA_FAILED: {
      return {
        ...state,
        place_delete_media_pending: false,
        place_delete_media_error: action.payload
      };
    }
    case PlacesStore.ActionTypes.DELETE_PLACE: {
      return {
        ...state,
        place_delete_pending: true,
        place_delete_error: null,
        place_deleted: false
      };
    }
    case PlacesStore.ActionTypes.DELETE_PLACE_SUCCESS: {
      return {
        ...state,
        place_delete_pending: false,
        place_delete_error: null,
        place_deleted: true,
        places_list: action.payload
      };
    }
    case PlacesStore.ActionTypes.DELETE_PLACE_FAILED: {
      return {
        ...state,
        place_delete_pending: false,
        place_delete_error: action.payload,
        place_deleted: false
      };
    }
    case PlacesStore.ActionTypes.SELECT_PLACE: {
      const nextState = {
        ...state,
        selected_place: action.payload
      };
      const selPlace = action.payload;
      PlacesStore.setLocalStorage(selPlace);
      return nextState;
    }
    case PlacesStore.ActionTypes.INHERIT_AUTOPILOTS: {
      return {
        ...state,
        inherit_autopilots_pending: true,
        inherit_autopilots_error: null
      };
    }
    case PlacesStore.ActionTypes.INHERIT_AUTOPILOTS_SUCCESS: {
      return {
        ...state,
        inherit_autopilots_pending: false,
        inherit_autopilots_error: null,
        inherit_autopilots: true
      };
    }
    case PlacesStore.ActionTypes.INHERIT_AUTOPILOTS_FAILED: {
      return {
        ...state,
        inherit_autopilots_pending: false,
        inherit_autopilots_error: action.payload,
        inherit_autopilots: false
      };
    }
    case PlacesStore.ActionTypes.INHERIT_REWARDS: {
      return {
        ...state,
        inherit_rewards_pending: true,
        inherit_rewards_error: null
      };
    }
    case PlacesStore.ActionTypes.INHERIT_REWARDS_SUCCESS: {
      return {
        ...state,
        inherit_rewards_pending: false,
        inherit_rewards_error: null,
        inherit_rewards: true
      };
    }
    case PlacesStore.ActionTypes.INHERIT_REWARDS_FAILED: {
      return {
        ...state,
        inherit_rewards_pending: false,
        inherit_rewards_error: action.payload,
        inherit_rewards: false
      };
    }
    case PlacesStore.ActionTypes.GET_UNAVAILABLE_MOMENTS_DAYS: {
      return {
        ...state,
        unavailableMomentsDaysPending: true
      };
    }
    case PlacesStore.ActionTypes.GET_UNAVAILABLE_MOMENTS_DAYS_SUCCESS: {
      return {
        ...state,
        unavailableMomentsDays: action.payload,
        unavailableMomentsDaysPending: false
      };
    }
    case PlacesStore.ActionTypes.GET_UNAVAILABLE_MOMENTS_DAYS_FAILED: {
      return {
        ...state,
        unavailableMomentsDays: [],
        unavailableMomentsDaysPending: false
      };
    }
    case PlacesStore.ActionTypes.ADD_PLACE_ADMIN_BY_EMAIL: {
      return {
        ...state,
        add_place_admin_by_id_error: null
      };
    }
    case PlacesStore.ActionTypes.ADD_PLACE_ADMIN_BY_EMAIL_SUCCESS: {
      return {
        ...state,
        add_place_admin_by_id_error: null
      };
    }
    case PlacesStore.ActionTypes.ADD_PLACE_ADMIN_BY_EMAIL_FAILED: {
      return {
        ...state,
        add_place_admin_by_id_error: action.payload
      };
    }
    case PlacesStore.ActionTypes.SET_PLACE: {
      return {
        ...state,
        place: action.payload
      };
    }
    case PlacesStore.ActionTypes.RESET_PIN_CODE: {
      return {
        ...state,
        reset_pin_code_pending: true,
        reset_pin_code_error: null
      };
    }
    case PlacesStore.ActionTypes.RESET_PIN_CODE_SUCCESS: {
      return {
        ...state,
        reset_pin_code_pending: false,
        reset_pin_code_error: null
      };
    }
    case PlacesStore.ActionTypes.RESET_PIN_CODE_FAILED: {
      return {
        ...state,
        reset_pin_code_pending: false,
        reset_pin_code_error: action.payload
      };
    }
    default:
      return state;
  }
}

export default placesReducer;
