import { RewardsStore } from './';

/*
  rewardsReducer = reducer for all actionTypes for Rewards
*/
function rewardsReducer(
  state: RewardsStore.IState = RewardsStore.initialState,
  action: IAction<any>
): RewardsStore.IState {
  switch (action.type) {
    case RewardsStore.ActionTypes.GET_REWARDS: {
      return {
        ...state,
        rewards_list_pending: true,
        rewards_list_error: null
      };
    }
    case RewardsStore.ActionTypes.GET_REWARDS_SUCCESS: {
      return {
        ...state,
        rewards_list_pending: false,
        rewards_list_error: null,
        rewards_list: action.payload
      };
    }
    case RewardsStore.ActionTypes.GET_REWARDS_FAILED: {
      return {
        ...state,
        rewards_list_pending: false,
        rewards_list: [],
        rewards_list_error: action.payload
      };
    }
    case RewardsStore.ActionTypes.SAVE_REWARD: {
      return {
        ...state,
        reward_save_pending: true,
        reward_save_error: null,
        reward_saved: null
      };
    }
    case RewardsStore.ActionTypes.SAVE_REWARD_SUCCESS: {
      return {
        ...state,
        reward_save_pending: false,
        reward_save_error: null,
        reward_saved: action.payload,
        rewards_list: action.payload
      };
    }
    case RewardsStore.ActionTypes.SAVE_REWARD_FAILED: {
      return {
        ...state,
        reward_save_pending: false,
        reward_save_error: action.payload,
        reward_saved: null
      };
    }
    case RewardsStore.ActionTypes.DELETE_REWARD: {
      return {
        ...state,
        reward_delete_pending: true,
        reward_delete_error: null,
        reward_deleted: false
      };
    }
    case RewardsStore.ActionTypes.DELETE_REWARD_SUCCESS: {
      return {
        ...state,
        reward_delete_pending: false,
        reward_delete_error: null,
        reward_deleted: true,
        rewards_list: action.payload
      };
    }
    case RewardsStore.ActionTypes.DELETE_REWARD_FAILED: {
      return {
        ...state,
        reward_delete_pending: false,
        reward_delete_error: action.payload,
        reward_deleted: false
      };
    }
    default:
      return state;
  }
}

export default rewardsReducer;
