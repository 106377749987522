import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  formatActivityDate,
  formatActivityAction,
  formatActivityChange,
  formatActivityPlace,
  getActivityFilters
} from './common';
import { IFetchUserActivityResponse } from '../../../appRedux/activity/api';
import { msg } from '../../../utils';
import { ModalCheckinActivityContainer } from './CheckinActivity';
import { CardComponent, InfiniteScrollListComponent } from '../../shared';
import { AppStore } from '../../../appRedux';
import { connect } from 'react-redux';
import { ActivityStore } from '../../../appRedux/activity';

const eventTypesFilters = getActivityFilters([
  '',
  'AUTOPILOT_ACTION_REWARD_GIVEN',
  'AUTOPILOT_ACTION_POINTS_GIVEN',
  'AUTOPILOT_ACTION_MEMBER_STATUS_CHANGED',
  'USER_TAG_ADDED',
  'USER_TAG_REMOVED',
  'MEMBER_TAG_ADDED',
  'MEMBER_TAG_REMOVED',
  'AUTOPILOT_ACTION_MESSAGE_SENT',
  'MEMBERSHIP_CREATED',
  'MEMBERSHIP_CANCELED',
  'CHECKIN_CREATED'
]);

interface IActivityViewModel {
  memberFullname: string;
  action: string;
  change: string;
  date: string;
  isCheckin: boolean;
  _id: string | undefined;
}

const mapUserActivityToViewModel = (userActivity: IFetchUserActivityResponse) => {
  if (!userActivity.results) {
    return {} as any;
  }

  // const getPlace = (placeId: string) => userActivity.places[placeId] || {};

  return {
    ...userActivity,
    results: userActivity.results.map(activity => ({
      date: formatActivityDate(activity),
      action: formatActivityAction(activity),
      change: formatActivityChange(activity),
      place: formatActivityPlace(activity.place),
      isCheckin: activity.eventType === 'CHECKIN_CREATED',
      _id: activity.checkinId
    }))
  };
};

interface IUserActivityStateProps {
  activity: IFetchUserActivityResponse;
  isLoading: boolean;
  error: any;
  selectedCheckinId: string | null;
}
interface IUserActivityActionsProps {
  fetchUserActivity: (userId: string, options?: any) => any;
  openCheckinActivityList: (checkinId: string) => any;
  closeCheckinActivityList: () => any;
}
type IUserActivityProps = IUserActivityStateProps & IUserActivityActionsProps & RouteComponentProps<{ userId: string }>;
export const UserActivity = (props: IUserActivityProps) => {
  const { isLoading, error, selectedCheckinId } = props;
  const { openCheckinActivityList, closeCheckinActivityList } = props;
  const userActivity = mapUserActivityToViewModel(props.activity);

  const defaultSearchValue = () => '';
  const getActions = () => {
    return [
      {
        label: msg('activityChanges.viewTransactionHistory', 'View transaction history'),
        btn: 'btn-info',
        icon: 'change_history',
        isShown: (entity: IActivityViewModel) => !!entity.isCheckin,
        onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          const checkinId = event.currentTarget.getAttribute('id');

          if (!!checkinId) {
            openCheckinActivityList(checkinId);
          }
        }
      }
    ];
  };

  const getUserActivity = (limit?: number, skip?: number, sort?: string, criteria?: { [key: string]: string }) => {
    const userId = props.match.params.userId;
    const options = { limit, skip, sort, criteria };

    props.fetchUserActivity(userId, options);
  };

  const modal = selectedCheckinId ? (
    <ModalCheckinActivityContainer
      onClose={() => {
        closeCheckinActivityList();
      }}
    />
  ) : null;

  return (
    <CardComponent
      title={msg('cardTitle.userActivity', 'User activity')}
      error={!!error}
      headerIcon={'event'}
      needsTitle={true}
    >
      {modal}
      <InfiniteScrollListComponent
        fields={{
          date: msg('activity.dateTime', 'Date and time'),
          action: msg('activity.action', 'Action'),
          change: msg('activity.change', 'Change'),
          place: msg('activity.place', 'At place')
        }}
        list={userActivity}
        pending={isLoading}
        error={error}
        disableQueryParams={true}
        search={['name']}
        actions={getActions()}
        get={getUserActivity}
        defaultSearchValue={defaultSearchValue()}
        sort={{
          fields: ['date'],
          default: 'date,-1'
        }}
        filters={[
          {
            field: 'eventType',
            value: eventTypesFilters
          }
        ]}
      />
    </CardComponent>
  );
};

const mapStateToProps = (state: AppStore.states): IUserActivityStateProps => ({
  activity: state.activity.user_activity_list,
  isLoading: state.activity.user_activity_list_pending,
  error: state.activity.user_activity_list_error,
  selectedCheckinId: state.activity.selected_checkin
});
const dispatchToProps: IUserActivityActionsProps = {
  fetchUserActivity: ActivityStore.actions.fetchUserActivityAction,
  openCheckinActivityList: ActivityStore.actions.openCheckinActivityList,
  closeCheckinActivityList: ActivityStore.actions.closeCheckinActivityList
};

export const UserActivityContainer = withRouter(connect(mapStateToProps, dispatchToProps)(UserActivity));
