import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppStore, PartnersStore } from '../../../appRedux';
import { CardComponent } from '../../shared';
import { msg } from '../../../utils';
import { PartnerEmailDTO, SelectValueDTO } from '../../../appRedux/types';
import { PartnerEmailsStore } from '../../../appRedux/partner-emails';
import PartnerEmailForm from './components/PartnerEmailForm';
import SentToModalComponent from './components/SentToModalComponent';
import { adminRoutes } from '../../layouts/routes';

interface IProps {
  selected_partner: string;
  partner_email: PartnerEmailDTO | null;
  partner_email_pending: boolean;
  names_of_partners_list: SelectValueDTO[];
  addPartnerEmailAction(isSAdmin: boolean, partnerId: string, partnerEmail: PartnerEmailDTO): any;
  getNamesListForPartnersAction(): any;
  getPartnerEmailAction(isSAdmin: boolean, partnerId: string, partnerEmailId: string): any;
  clearPartnerEmailAction(): any;
  deleteAttachmentAction(isSAdmin: boolean, partnerId: string, attachment: any): any;
}

const PartnerEmailContainer = (props: IProps & RouteComponentProps<{ emailId: string }>) => {
  const [mounted, setMounted] = useState(false);
  const [isSAdmin, setIsSAdmin] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    props.getNamesListForPartnersAction();
    const SAdmin =
      props.match?.path === adminRoutes.EMAILS.subroutes.EDIT.path ||
      props.match?.path === adminRoutes.EMAILS.subroutes.ADD.path;
    if (SAdmin) {
      setIsSAdmin(true);
    }
    const emailId = props.match.params?.emailId;
    if (emailId) {
      setEditMode(true);
      props.getPartnerEmailAction(SAdmin, props.selected_partner, emailId);
    }
    setMounted(true);
    return () => props.clearPartnerEmailAction();
    // tslint:disable-next-line:align
  }, []);

  const parsePartners = () => {
    return props.names_of_partners_list.map(partner => {
      return { label: partner.name, value: partner._id };
    });
  };

  const saveHandler = (data: PartnerEmailDTO) => {
    const email = {
      ...data,
      sentTo: data.sentTo?.map(item => item.value)
    };
    props.addPartnerEmailAction(isSAdmin, props.selected_partner, email);
  };

  const toggleModal = () => {
    if (!showModal && props.partner_email) {
      props.getPartnerEmailAction(isSAdmin, props.selected_partner, props.partner_email?._id);
    }
    setShowModal(!showModal);
  };

  return (
    <CardComponent
      title={msg('partnerEmail.cardTitle', 'Partner Emails')}
      headerIcon="notifications"
      needsTitle={true}
      pending={!mounted || (props.partner_email_pending && !props.partner_email?._id)}
      buttons={
        editMode
          ? [
              {
                label: msg('partnerEmail.sentToList', 'Users List'),
                icon: 'people',
                onClick: toggleModal
              }
            ]
          : undefined
      }
    >
      <PartnerEmailForm
        editMode={editMode}
        isSAdmin={isSAdmin}
        saveHandler={saveHandler}
        partner_email={props.partner_email}
        selected_partner={props.selected_partner}
        partner_email_pending={props.partner_email_pending}
        getPartnerEmailAction={props.getPartnerEmailAction}
        names_of_partners_list={parsePartners()}
        deleteAttachmentAction={props.deleteAttachmentAction}
      />
      {showModal && (
        <SentToModalComponent
          toggleModal={toggleModal}
          sentTo={props.partner_email?.sentTo || []}
          pending={props.partner_email_pending}
        />
      )}
    </CardComponent>
  );
};

function mapStateToProps(state: AppStore.states) {
  return {
    selected_partner: state.partners.selected_partner,
    partner_email: state.partnerEmails.partner_email,
    partner_email_pending: state.partnerEmails.partner_email_pending,
    names_of_partners_list: state.partners.names_of_partners_list
  };
}

const dispatchToProps = {
  addPartnerEmailAction: PartnerEmailsStore.actions.addPartnerEmailAction,
  getNamesListForPartnersAction: PartnersStore.actions.getNamesListForPartnersAction,
  getPartnerEmailAction: PartnerEmailsStore.actions.getPartnerEmailAction,
  clearPartnerEmailAction: PartnerEmailsStore.actions.clearPartnerEmailAction,
  deleteAttachmentAction: PartnerEmailsStore.actions.deleteAttachmentAction
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(PartnerEmailContainer));
