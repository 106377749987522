import * as React from 'react';
import { Badge } from 'react-bootstrap';
import { colors } from '../../../../assets/styles';
import { msg } from '../../../../utils';
import { CardComponent } from '../../../shared';

const FinalMessageComponent = (props: any) => {
  const renderBadgeStatus = () => {
    if (props.status) {
      return (
        <div style={styles.statusContainer}>
          <div style={styles.status}>Status: </div>
          <Badge style={{ backgroundColor: colors.green, fontSize: 15, marginLeft: 5 }}>
            {msg('onboard.onboardingStatusCompleted', 'Completed')}
          </Badge>
        </div>
      );
    }
    return (
      <div style={styles.statusContainer}>
        <div style={styles.status}>Status: </div>
        <Badge style={{ backgroundColor: colors.red, fontSize: 15, marginLeft: 5 }}>
          {msg('onboard.onboardingStatusReview', 'In review')}
        </Badge>
      </div>
    );
  };

  return (
    <CardComponent title="" headerIcon="" needsTitle={false}>
      <div style={styles.title}>{msg('onboard.finalMessageTitle', 'Congratulations!')}</div>
      <div style={styles.text}>
        {msg(
          'onboard.finalMessageContent',
          'You have finish all the steps. If you want to edit some data, just go back to a specific step.'
        )}
      </div>
      <div style={styles.text}>
        {msg(
          'onboard.finalMessageContact',
          `You will be contacted by a representative ${process.env.REACT_APP_NAME}.`,
          {
            name: process.env.REACT_APP_NAME || 'ReGage'
          }
        )}
      </div>
      <div style={styles.text}>{msg('onboard.finalMessageThx', 'Thank you!')}</div>
      <div style={styles.badge}>{renderBadgeStatus()}</div>
    </CardComponent>
  );
};

export default FinalMessageComponent;

const styles: any = {
  title: {
    fontSize: 36,
    textAlign: 'center',
    margin: '50px 0px 50px 0px'
  },
  text: {
    fontSize: 18,
    textAlign: 'center',
    margin: '20px 0px'
  },
  badge: {
    display: 'flex',
    flexDirection: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  status: { fontSize: 18 }
};
