import React from 'react';

import { msg } from '../../../../utils';
import { SimpleSelectComponent, TextFieldComponent, MultipleSelectComponent } from '../../../shared';
import {
  AnnouncementConditionDTO,
  AnnouncementConditionTypes,
  AnnouncementConditionOperators,
  PlaceDTO
} from '../../../../appRedux/types';

interface IProps {
  index: number;
  listItem: AnnouncementConditionDTO;
  options: PlaceDTO[];
  onChange: (value: { name: string; value: string }, index: number) => any;
  onDelete: (index: number) => any;
  onChangeMultiField: (places: { label: string; value: string }[], index: number) => any;
}

interface IState {}

export class AnnouncementConditionComponent extends React.Component<IProps, IState> {
  parseOptions = () => {
    const results: { label: string; value: string }[] = [];
    const { options } = this.props;
    if (options && options.length) {
      options.forEach((place: PlaceDTO) => {
        results.push({ label: place.name, value: place._id });
      });
    }
    return results;
  };

  parseSelectedValues = () => {
    const results: { label: string; value: string }[] = [];
    const { listItem, options } = this.props;
    if (listItem.selectedValues && listItem.selectedValues.length && options && options.length) {
      listItem.selectedValues.forEach((placeId: string) => {
        const place: PlaceDTO | undefined = options.find((item: PlaceDTO) => item._id === placeId);
        if (place) {
          results.push({ label: place.name, value: place._id });
        }
      });
    }
    return results;
  };

  renderConditionAndValue = () => {
    const { listItem, index } = this.props;
    switch (parseInt(listItem.type, 10)) {
      case AnnouncementConditionTypes.CHECKINSNO:
      case AnnouncementConditionTypes.MEMBERSHIPSNO:
        return (
          <div>
            <div className="col-sm-3">
              <SimpleSelectComponent
                label={msg('announcement.operator', 'Condition operator')}
                options={[
                  { name: '<', _id: AnnouncementConditionOperators.$lt },
                  { name: '>', _id: AnnouncementConditionOperators.$gt },
                  { name: '=', _id: AnnouncementConditionOperators.$eq },
                  { name: '<=', _id: AnnouncementConditionOperators.$lte },
                  { name: '>=', _id: AnnouncementConditionOperators.$gte }
                ]}
                name="operator"
                id={`operator_${index}`}
                value={listItem.operator !== undefined ? listItem.operator : ''}
                onChange={(e: any) => this.props.onChange(e.target, index)}
                required={true}
                needsAllLabel={false}
                arrayOptions={false}
              />
            </div>
            <div className="col-sm-3">
              <TextFieldComponent
                id={`value_${index}`}
                inType="text"
                label="Value"
                value={listItem.value || ''}
                onChange={(e: any) => this.props.onChange({ name: 'value', value: e.target.value }, index)}
                required={true}
              />
            </div>
          </div>
        );
      case AnnouncementConditionTypes.PLACES:
        return (
          <div className="col-sm-6">
            <MultipleSelectComponent
              label="Places"
              options={this.parseOptions()}
              id="placeId"
              value={this.parseSelectedValues()}
              onChange={(places: { label: string; value: string }[]) => this.props.onChangeMultiField(places, index)}
            />
          </div>
        );
      default:
        return <div />;
    }
  };

  render() {
    const { listItem, index } = this.props;
    return (
      <div className="row">
        <div className="col-sm-3">
          <SimpleSelectComponent
            label={msg('announcement.condition', 'Condition type')}
            options={[
              {
                name: msg('announcement.checkinsNo', 'Checkins No. (per membeship)'),
                _id: AnnouncementConditionTypes.CHECKINSNO
              },
              {
                name: msg('announcement.membershipsNo', 'Memberships No.'),
                _id: AnnouncementConditionTypes.MEMBERSHIPSNO
              },
              { name: msg('announcement.places', 'Selected places'), _id: AnnouncementConditionTypes.PLACES }
            ]}
            name="type"
            id={`type_${index}`}
            value={listItem.type}
            onChange={(e: any) => this.props.onChange(e.target, index)}
            required={true}
            needsAllLabel={false}
            arrayOptions={false}
          />
        </div>
        {this.renderConditionAndValue()}
        <button
          type="button"
          data-rel="tooltip"
          className="btn btn-danger"
          onClick={() => this.props.onDelete(index)}
          style={{ width: 27, padding: 5, height: 27, marginTop: 18 }}
          title={msg('general.delete', 'Delete')}
          id={index + ''}
        >
          <i className="material-icons">delete</i>
        </button>
      </div>
    );
  }
}
