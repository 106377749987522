import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Bounce } from 'react-activity';
import { PaymentDTO, TransactionDTO, TransactionStatusTypes } from '../../../../appRedux/types';
import { AlertUtil, getFormatDate, msg, parseAmount } from '../../../../utils';
import imagePlaceholder from '../../../../assets/images/galleryPlaceholder.jpg';
import { ListComponent } from '../../../shared';
import { colors } from '../../../../assets/styles';

interface IProps {
  isSAdmin: boolean;
  forUser?: boolean;
  selected_place: string | null;
  transaction: TransactionDTO | null;
  transaction_pending: boolean;
  toggleModal: () => void;
  getStatusBadge: (status: string) => string;
  getTransactionStatus: (status: string) => string;
  rollbackTransactionAction(isSAdmin: boolean, transactionId: string, placeId?: string, pinCode?: string): any;
}

const TransactionDetailsModal = (props: IProps) => {
  const rollbackTransaction = async () => {
    if (props.isSAdmin) {
      const confirmed = await AlertUtil.confirm(
        msg('transactions.rollbackPermanent', 'This action cannot be undone!'),
        msg('transactions.rollback', 'Rollback'),
        msg('transactions.confirmRollback', 'Are you sure you want to roll back this transaction?')
      );
      if (confirmed) {
        props.rollbackTransactionAction(props.isSAdmin, props.transaction?._id || '', props.transaction?.placeId || '');
      }
    } else {
      const currentPin = await AlertUtil.fireWithInput(
        msg('transactions.rollbackPermanent', 'This action cannot be undone!'),
        msg('transactions.checkPin', 'In order to continue, you must confirm the current place pin code:'),
        '',
        (input: string) => (!input.length ? msg('formValidation.inputLength', 'Input too short!') : null),
        true
      );
      if (currentPin) {
        props.rollbackTransactionAction(
          props.isSAdmin,
          props.transaction?._id || '',
          props.selected_place || '',
          currentPin
        );
      }
    }
  };

  const parsePayments = (payments: PaymentDTO[]) => {
    return payments.map((payment: PaymentDTO) => {
      return {
        ...payment,
        created: getFormatDate(payment.created),
        errorData: {
          type: 'custom',
          render: () => (
            <>
              {payment?.actionCode || payment?.errorCode ? (
                <div>
                  {payment.actionCode ? (
                    <>
                      <div>
                        {msg('transactions.actionCode', 'Action code')} - {payment.actionCode}
                      </div>
                      <div>
                        {msg('transactions.actionCodeDescription', 'Action code description')} -{' '}
                        {payment.actionCodeDescription}
                      </div>
                    </>
                  ) : null}
                  {payment.errorCode ? (
                    <>
                      <div>
                        {msg('transactions.errorCode', 'Error code')} - {payment.errorCode}
                      </div>
                      <div>
                        {msg('transactions.errorMessage', 'Error message')} - {payment.errorMessage}
                      </div>
                    </>
                  ) : null}
                </div>
              ) : (
                'N/A'
              )}
            </>
          )
        }
      };
    });
  };

  return (
    <Modal show={true} onHide={props.toggleModal} dialogClassName="customEventModal" enforceFocus={false}>
      <Modal.Header>
        <Modal.Title>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {msg('transactions.detailsModal', 'Transaction details')}
            {!props.transaction_pending && props.transaction && (
              <div>
                <div
                  className="badge"
                  style={{
                    backgroundColor: props.getStatusBadge(props.transaction.status),
                    textTransform: 'uppercase'
                  }}
                >
                  {props.isSAdmin ? props.transaction.status : props.getTransactionStatus(props.transaction.status)}
                </div>
                <div
                  className="badge"
                  style={{
                    marginLeft: 10,
                    backgroundColor: props.transaction?.isSettled ? colors.darkGreen : colors.gray,
                    textTransform: 'uppercase'
                  }}
                >
                  {props.transaction?.isSettled
                    ? msg('paymentSettlements.settled', 'Settled')
                    : msg('paymentSettlements.unsettled', 'Unsettled')}
                </div>
              </div>
            )}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: 700, overflowX: 'auto' }}>
        {!props.transaction && props.transaction_pending ? (
          <div style={{ textAlign: 'center', margin: 20 }}>
            <Bounce color={colors.lightGreen} />
          </div>
        ) : props.transaction ? (
          <>
            <div className="row">
              <div className="col-sm-7" style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={
                    props.transaction.receiptId && props.transaction.receipt?.media
                      ? props.transaction.receipt.media
                      : imagePlaceholder
                  }
                  alt="placeholder"
                  style={{
                    maxWidth: '100%',
                    maxHeight: 500
                  }}
                />
              </div>
              <div className="col-sm-5">
                <h4>
                  <strong>{msg('transactions.created', 'Created')}</strong>: {getFormatDate(props.transaction.created)}
                </h4>
                <h4>
                  <strong>{msg('transactions.uniqueNo', 'Unique number')}</strong>: {props.transaction.uniqueNo}
                </h4>
                <h4>
                  <strong>{msg('transactions.requestedAmount', 'Requested amount')}</strong>:{' '}
                  {parseAmount(props.transaction.requestedAmount)}
                </h4>
                <h4>
                  <strong>{msg('transactions.amount', 'Amount')}</strong>:{' '}
                  {props.transaction.status === TransactionStatusTypes.FAILED
                    ? 'N/A'
                    : `${parseAmount(props.transaction.amount)}${
                        props.transaction.usedCoins
                          ? ' + ' + props.transaction.usedCoins + ' ' + msg('general.coins', 'coins')
                          : ''
                      }`}
                </h4>
                {!!props.transaction.tipsAmount && (
                  <h4>
                    <strong>{msg('transactions.tipsAmount', 'Tips amount')}</strong>:{' '}
                    {parseAmount(props.transaction.tipsAmount)}
                    {props.transaction.tabletProfile?.name && ` - ${props.transaction.tabletProfile?.name}`}
                  </h4>
                )}
                {props.isSAdmin && (
                  <h4>
                    <strong>{msg('transactions.conversion', 'Conversion rule')}</strong>:{' '}
                    {props.transaction.conversion ?? 'N/A'}
                  </h4>
                )}
                <h4>
                  <strong>{msg('transactions.receivedCoins', 'Coins received')}</strong>:{' '}
                  {props.transaction.receivedCoins ?? 'N/A'}
                </h4>
                {props.transaction.receipt?.ocrData && (
                  <h4>
                    <strong>{msg('transactions.ocrData', 'OCR Data')}</strong>:{' '}
                    {Object.keys(props.transaction.receipt.ocrData).map((key, index) => (
                      <div key={index}>
                        {key}: {props.transaction?.receipt?.ocrData && props.transaction?.receipt?.ocrData[key]}
                      </div>
                    ))}
                  </h4>
                )}
                {props.transaction.mobileUser && (
                  <>
                    <h4>
                      <strong>{msg('transactions.user', 'User')}</strong>:{' '}
                      {props.transaction.mobileUser.firstname + ' ' + props.transaction.mobileUser.lastname}
                    </h4>
                    <h4>
                      <strong>{msg('transactions.userEmail', 'User email')}</strong>:{' '}
                      {props.transaction.mobileUser.email}
                    </h4>
                  </>
                )}
                {props.transaction.tabletUser && (
                  <>
                    <h4>
                      <strong>{msg('transactions.tabletUser', 'Tablet user')}</strong>:{' '}
                      {props.transaction.tabletUser.firstname + ' ' + props.transaction.tabletUser.lastname}
                    </h4>
                    <h4>
                      <strong>{msg('transactions.tabletUserEmail', 'Tablet user email')}</strong>:{' '}
                      {props.transaction.tabletUser.email}
                    </h4>
                  </>
                )}
                {props.transaction.mobileSignType && (
                  <h4>
                    <strong>{msg('transactions.mobileSignType', 'Mobile sign type')}</strong>:{' '}
                    {props.transaction.mobileSignType}
                  </h4>
                )}
                {props.transaction.failedCode && (
                  <h4>
                    <strong>{msg('transactions.failedCode', 'Failed code')}</strong>: {props.transaction.failedCode}
                  </h4>
                )}
              </div>
            </div>
            {props.transaction.payments?.length ? (
              <div className="row" style={{ margin: '10px 0 0 0' }}>
                <h4>
                  <strong>{msg('transactions.paymentHistory', 'Payment history')}:</strong>
                </h4>
                <ListComponent
                  data={{
                    fields: {
                      created: msg('transactions.created', 'Created'),
                      externalId: msg('transactions.externalId', 'External ID'),
                      internalStatus: msg('transactions.internalStatus', 'Transaction internal status'),
                      externalStatus: msg('transactions.externalStatus', 'Transaction external status'),
                      errorData: msg('transactions.errorDetails', 'Error details')
                    },
                    list: parsePayments(props.transaction.payments),
                    pending: false,
                    error: null
                  }}
                />
              </div>
            ) : null}
          </>
        ) : (
          <div>{msg('general.noAvailableData', 'There is no available data.')}</div>
        )}
      </Modal.Body>
      <Modal.Footer style={{ margin: '25px 15px 0' }}>
        <div className="row">
          {!props.forUser &&
            !props.transaction_pending &&
            props.transaction?.status === TransactionStatusTypes.APPROVED &&
            !props.transaction?.isSettled && (
              <Button
                className="btn btn-danger col-sm-3"
                onClick={rollbackTransaction}
                disabled={props.transaction.isSettled}
              >
                {msg('transactions.rollback', 'Rollback')}
              </Button>
            )}
          <Button className="btn btn-success" onClick={props.toggleModal}>
            {msg('general.close', 'Close')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TransactionDetailsModal;
