export const delayOptions = [
  {
    _id: 'now',
    name: 'delayOptions.startingNow',
    default: 'Starting now'
  },
  {
    _id: 'tomorrow',
    name: 'delayOptions.tomorrow',
    default: 'Tomorrow'
  },
  {
    _id: 'three_days',
    name: 'delayOptions.inThreeDays',
    default: 'In 3 days'
  },
  {
    _id: 'week',
    name: 'delayOptions.inAWeek',
    default: 'In a week'
  },
  {
    _id: 'pick_date',
    name: 'delayOptions.customDate',
    default: 'At this date'
  }
];

export const triggerTypeOptions = [
  {
    _id: 0,
    name: 'triggerOptions.timeElapsed',
    default: 'Time Elapsed'
  },
  {
    _id: 1,
    name: 'triggerOptions.date',
    default: 'Date'
  },
  {
    _id: 2,
    name: 'triggerOptions.pointsReached',
    default: 'Coins Reached'
  },
  {
    _id: 3,
    name: 'triggerOptions.checkinsReached',
    default: 'Check-ins Reached'
  },
  {
    _id: 4,
    name: 'triggerOptions.memberRegistration',
    default: 'Member Registration'
  },
  {
    _id: 5,
    name: 'triggerOptions.lessThanCheckins',
    default: 'Less Check-ins than'
  },
  {
    _id: 6,
    name: 'triggerOptions.everyXCheckins',
    default: 'Every Number of Check-ins'
  }
  // removing Proximity Condition for the moment as it doesn't do much
  // {
  //   _id: 7,
  //   name: 'triggerOptions.handleProximity',
  //   default: 'Proximity'
  // }
];

export const actionsTypeOptions = [
  {
    _id: 0,
    name: 'actionOptions.giveReward',
    default: 'Give Reward'
  },
  {
    _id: 1,
    name: 'actionOptions.giveCoins',
    default: 'Give coins'
  },
  {
    _id: 2,
    name: 'actionOptions.changeStatus',
    default: 'Change Member Status'
  },
  {
    _id: 3,
    name: 'actionOptions.sendMessage',
    default: 'Send Message'
  }
];

export const conditionsForTypeZero = [
  {
    _id: 0,
    name: 'conditionsForTypeZero.sinceLastCheckin',
    default: 'Since last check-in'
  },
  {
    _id: 3,
    name: 'conditionsForTypeZero.sinceLatestNewCheckin',
    default: 'Since latest new check-in'
  },
  {
    _id: 1,
    name: 'conditionsForTypeZero.beforeBirthDay',
    default: 'Before birthday'
  },
  {
    _id: 2,
    name: 'conditionsForTypeZero.sinceLastTriggeredDate',
    default: 'Since last triggered date'
  },
  {
    _id: 4,
    name: 'conditionsForTypeZero.sinceBecomingMember',
    default: 'Since becoming a member'
  }
];

export const memberStatus = [
  {
    _id: 0,
    name: 'memberTypes.inactive',
    default: 'Inactive'
  },
  {
    _id: 1,
    name: 'memberTypes.new',
    default: 'New'
  },
  {
    _id: 2,
    name: 'memberTypes.regular',
    default: 'Regular'
  },
  {
    _id: 3,
    name: 'memberTypes.vip',
    default: 'VIP'
  },
  {
    _id: 4,
    name: 'memberTypes.flagged',
    default: 'Flagged'
  }
];
