import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';

import { delayOptions } from '../../../../../appRedux/autopilot/AutopilotSelections';
import { AutopilotDTO } from '../../../../../appRedux/types';
import { getFormatDate, IValidator, msg, tlist, Validator } from '../../../../../utils';
import {
    ButtonComponent, DateTimePickerComponent, SimpleSelectComponent, TextFieldComponent
} from '../../../../shared';
import { AutopilotActionsForm, AutopilotConditionForm } from './';

interface IProps {
  autopilot?: AutopilotDTO | null;
  save?: (autopilot: any) => void;
  pending: boolean;
  output?: boolean;
  onChange?: (autopilot: any) => void;
  editable?: boolean;
  auxilaries: any;
}
interface IState {
  autopilot: AutopilotDTO;
  triggers: Array<any>;
  actions: Array<any>;
  isDirty: boolean;
  autopilotExpires: boolean;
  expirationValue: any;
  expirationPeriodType: any;
  delayOption: any;
  message: {
    title: any;
    delay: any;
    expiration: any;
  };
  saveButton: boolean;
}

class AutopilotFormComponent extends React.Component<IProps & IValidator, IState> {
  constructor(props: IProps & IValidator) {
    super(props);
    this.state = {
      autopilot: props.autopilot || new AutopilotDTO(),
      triggers:
        props.autopilot && props.autopilot.triggers
          ? this.parseIncomingTriggers(props.autopilot.triggers)
          : [{ _id: new Date().valueOf(), type: '', value: '', condition: '', period: '' }],
      actions:
        props.autopilot && props.autopilot.actions
          ? this.parseIncomingActions(props.autopilot.actions)
          : [{ _id: new Date().valueOf(), type: '', value: '', reward: '', channels: [], customReward: '' }],
      isDirty: false,
      delayOption: 'now',
      autopilotExpires:
        props.autopilot && (props.autopilot.expiration === null || props.autopilot.expiration === '') ? false : true,
      expirationValue:
        this.props.editable && props.autopilot ? this.getExpirationDate(props.autopilot.expiration, true) : 1,
      expirationPeriodType:
        this.props.editable && props.autopilot
          ? this.getExpirationDate(props.autopilot.expiration, false)
          : msg('periodTypes.days', 'days'),
      message: {
        title: '',
        delay: '',
        expiration: ''
      },
      saveButton: true
    };
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.autopilot && prevProps.autopilot !== this.props.autopilot) {
      this.setState({
        autopilot: this.props.autopilot
        // triggers: this.parseIncomingTriggers(nextProps.autopilot.triggers),
        // actions: this.parseIncomingActions(nextProps.autopilot.actions)
      });
    }
  }

  parseIncomingTriggers = (triggers: any) => {
    let parsedTriggers = triggers.map((trigger: any) => {
      if (trigger.type === 0) {
        let period = trigger.value.split(' ')[1].toString();
        if (period === 'minutes') {
          period = msg('periodTypes.minutes', 'minutes');
        } else if (period === 'hours') {
          period = msg('periodTypes.hours', 'hours');
        } else if (period === 'days') {
          period = msg('periodTypes.days', 'days');
        } else if (period === 'weeks') {
          period = msg('periodTypes.weeks', 'weeks');
        } else if (period === 'months') {
          period = msg('periodTypes.months', 'months');
        }
        return {
          _id: trigger._id,
          type: trigger.type,
          value: trigger.value.split(' ')[0].toString(),
          condition: trigger.condition,
          period: period
        };
      } else if (
        trigger.type === 1 ||
        trigger.type === 2 ||
        trigger.type === 3 ||
        trigger.type === 5 ||
        trigger.type === 6 ||
        trigger.type === 7
      ) {
        return {
          _id: trigger._id,
          type: trigger.type,
          value: trigger.value,
          period: '',
          condition: ''
        };
      } else if (trigger.type === 4) {
        return {
          _id: trigger._id,
          type: trigger.type,
          value: '',
          period: '',
          condition: ''
        };
      } else if (!trigger.type) {
        return { _id: new Date().valueOf(), type: '', value: '', condition: '', period: '' };
      }
      return 0;
    });
    return parsedTriggers;
  };

  parseIncomingActions = (actions: any) => {
    let parsedActions = actions.map((action: any) => {
      if (action.type === 0) {
        if (action.value.length === 24) {
          return {
            _id: action._id,
            type: action.type,
            value: '',
            reward: action.value,
            message: action.message,
            channels: [],
            customReward: ''
          };
        } else {
          return {
            _id: action._id,
            type: action.type,
            value: '',
            reward: 'custom',
            message: action.message,
            channels: [],
            customReward: action.value,
            media: action.media
          };
        }
      } else if (action.type === 1 || action.type === 2) {
        return {
          _id: action._id,
          type: action.type,
          value: action.value,
          message: action.message,
          reward: '',
          channels: [],
          customReward: ''
        };
      } else if (action.type === 3) {
        return {
          _id: action._id,
          type: action.type,
          value: action.value.message,
          reward: '',
          channels: action.value.channels,
          customReward: ''
        };
      } else if (!action.type) {
        return { _id: new Date().valueOf() + 1, type: '', value: '', reward: '', channels: [], customReward: '' };
      }
      return 0;
    });
    return parsedActions;
  };

  parseRewards = (rewards: any) => {
    let parsedRewards = [];
    rewards.forEach((reward: any) => {
      if (reward.isActive) {
        parsedRewards.push({
          _id: reward._id,
          name: reward.title
        });
      }
    });
    parsedRewards.push({ _id: 'custom', name: msg('reward.customReward', 'Custom Reward') });
    return parsedRewards;
  };

  saveHandler = () => {
    const { autopilot, triggers, actions } = this.state;
    let isRecurrent = false;
    let errors: string[] = [];

    let parsedTriggers = triggers.map((trigger: any) => {
      if (trigger.type === 0) {
        if (
          trigger.value === null ||
          trigger.value === '' ||
          trigger.period === null ||
          trigger.period === '' ||
          trigger.condition === null ||
          trigger.condition === ''
        ) {
          errors.push(
            msg('formValidation.triggerTypeRequired', 'Please fill all the required fields for the trigger type')
          );
        }
        let value = trigger.value + ' ';
        if (trigger.period === msg('periodTypes.minutes', 'minutes')) {
          value += 'minutes';
        } else if (trigger.period === msg('periodTypes.hours', 'hours')) {
          value += 'hours';
        } else if (trigger.period === msg('periodTypes.days', 'days')) {
          value += 'days';
        } else if (trigger.period === msg('periodTypes.weeks', 'weeks')) {
          value += 'weeks';
        } else if (trigger.period === msg('periodTypes.months', 'months')) {
          value += 'months';
        }
        if (isNaN(parseInt(trigger.value, 10))) {
          errors.push(msg('formValidation.onlyNumbers', 'Use only numbers!'));
        }
        if (parseInt(trigger.condition, 10) === 2) {
          isRecurrent = true;
        }
        return {
          type: parseInt(trigger.type, 10),
          value: value,
          condition: parseInt(trigger.condition, 10)
        };
      } else if (trigger.type === 1) {
        if (trigger.value === null || trigger.value === '') {
          return {
            type: parseInt(trigger.type, 10),
            value: moment().valueOf()
          };
        }
        return {
          type: parseInt(trigger.type, 10),
          value: moment(trigger.value).valueOf()
        };
      } else if (
        trigger.type === 2 ||
        trigger.type === 3 ||
        trigger.type === 5 ||
        trigger.type === 6 ||
        trigger.type === 7
      ) {
        if (trigger.value === null || trigger.value === '') {
          errors.push(
            msg('formValidation.triggerTypeRequired', 'Please fill all the required fields for the trigger type')
          );
        } else if (isNaN(parseInt(trigger.value, 10))) {
          errors.push(msg('formValidation.onlyNumbers', 'Use only numbers!'));
        }
        if (trigger.type === 7 && trigger.value > 2000) {
          errors.push(msg('formValidation.proximityCondition', 'Please choose a value less than 2000'));
        }
        return {
          type: parseInt(trigger.type, 10),
          value: parseInt(trigger.value, 10)
        };
      } else if (trigger.type === 4) {
        return {
          type: parseInt(trigger.type, 10),
          value: 'Member registration'
        };
      } else {
        return {
          type: '',
          value: ''
        };
      }
    });
    let parsedActions = actions.map((action: any) => {
      if (action.type === 0) {
        if (action.reward === null || action.reward === '') {
          errors.push(msg('formValidation.rewardRequired', 'Please choose a reward'));
        }
        if (action.reward === 'custom') {
          if (action.customReward === null || action.customReward === '') {
            errors.push(msg('formValidation.rewardRequired', 'Please choose a reward'));
          }
          return {
            type: parseInt(action.type, 10),
            value: action.customReward,
            message: action.message,
            media: action.media,
            isCustom: true
          };
        } else {
          return {
            type: parseInt(action.type, 10),
            value: action.reward,
            message: action.message
          };
        }
      } else if (action.type === 1) {
        if (action.value === null || action.value === '') {
          errors.push(msg('formValidation.rewardRequired', 'Please choose a reward'));
        } else if (isNaN(parseInt(action.value, 10))) {
          errors.push(msg('formValidation.onlyNumbers', 'Use only numbers!'));
        }
        return {
          type: parseInt(action.type, 10),
          value: parseInt(action.value, 10),
          message: action.message
        };
      } else if (action.type === 2) {
        if (action.value === null || action.value === '') {
          errors.push(msg('formValidation.dropdownRequired', 'Please select a value from the dropdown'));
        }
        return {
          type: parseInt(action.type, 10),
          value: action.value
        };
      } else if (action.type === 3) {
        if (action.value.message === null || action.value.message === '' || action.channels.length === 0) {
          errors.push(msg('formValidation.messageRequired', 'Please choose a message'));
        }
        return {
          type: parseInt(action.type, 10),
          value: {
            message: action.value,
            channels: action.channels
          }
        };
      } else {
        return {
          type: '',
          value: ''
        };
      }
    });
    parsedTriggers.forEach((trigger: any) => {
      if (trigger.type === '' || trigger.type === null || trigger.type === undefined) {
        errors.push(msg('formValidation.triggerRequired', 'Please select a trigger'));
      }
      if (trigger.type === 6 || trigger.type === 7) {
        isRecurrent = true;
      }
      if (trigger.type === 0 && trigger.condition === 2) {
        isRecurrent = true;
      }
    });
    parsedActions.forEach((action: any) => {
      if (action.type === '' || action.type === null || action.type === undefined) {
        errors.push(msg('formValidation.actionRequired', 'Please select an action'));
      }
    });
    let delay = undefined;
    if (typeof autopilot.delay === 'object') {
      delay = moment(autopilot.delay).valueOf();
    } else {
      delay = autopilot.delay;
    }
    let expiration = undefined;
    if (this.state.autopilotExpires) {
      if (isNaN(parseInt(this.state.expirationValue, 10))) {
        errors.push(msg('formValidation.onlyNumbers', 'Use only numbers!'));
      }
      switch (this.state.expirationPeriodType) {
        case msg('periodTypes.minutes', 'minutes'):
          expiration = this.state.expirationValue * 60000;
          break;
        case msg('periodTypes.hours', 'hours'):
          expiration = this.state.expirationValue * 3600000;
          break;
        case msg('periodTypes.days', 'days'):
          expiration = this.state.expirationValue * 86400000;
          break;
        case msg('periodTypes.weeks', 'weeks'):
          expiration = this.state.expirationValue * 604800000;
          break;
        case msg('periodTypes.months', 'months'):
          expiration = this.state.expirationValue * 2629743830;
          break;
        default:
          break;
      }
    }
    const sendData = {
      title: autopilot.title,
      delay: delay,
      expiration: this.state.autopilotExpires ? expiration : null,
      triggers: parsedTriggers,
      recurrent: isRecurrent,
      actions: parsedActions,
      _id: autopilot._id !== '' ? autopilot._id : null
    };
    if (!errors.length) {
      if (this.props.save) {
        this.props.save(sendData);
      }
    } else {
      errors.forEach((item: string) => {
        toast.error(item);
      });
    }
  };

  getExpirationDate = (expirationTimestamp: any, valueOrPeriod: boolean) => {
    const expirationTmstp = parseInt(expirationTimestamp, 10);
    if (expirationTmstp / 2629743830 >= 1) {
      if (valueOrPeriod) {
        return Math.round(expirationTmstp / 2629743830);
      } else {
        return msg('periodTypes.months', 'months');
      }
    } else if (expirationTmstp / 604800000 >= 1) {
      if (valueOrPeriod) {
        return Math.round(expirationTmstp / 604800000);
      } else {
        return msg('periodTypes.weeks', 'weeks');
      }
    } else if (expirationTmstp / 86400000 >= 1) {
      if (valueOrPeriod) {
        return Math.round(expirationTmstp / 86400000);
      } else {
        return msg('periodTypes.days', 'days');
      }
    } else if (expirationTmstp / 3600000 >= 1) {
      if (valueOrPeriod) {
        return Math.round(expirationTmstp / 3600000);
      } else {
        return msg('periodTypes.hours', 'hours');
      }
    } else {
      if (valueOrPeriod) {
        return Math.round(expirationTmstp / 60000);
      } else {
        return msg('periodTypes.minutes', 'minutes');
      }
    }
  };

  handleGetActivationDate = (value: any) => {
    let autopilot = this.state.autopilot;
    autopilot.delay = value;
    this.setState(() => ({ autopilot, saveButton: false }));
  };

  handleChange = (event: any) => {
    const fieldKey = event.target.getAttribute('id');
    const fieldValue = event.target.value;
    const autopilot = this.state.autopilot;
    const message = this.state.message;
    message[fieldKey] = '';
    autopilot[fieldKey] = fieldValue;
    this.setState({ autopilot, isDirty: true, saveButton: false });
    if (this.props.output && this.props.onChange) {
      this.props.onChange(autopilot);
    }
  };

  handleDelayOptionChange = (event: any) => {
    const delayOption = event.target.value;
    const autopilot = this.state.autopilot;
    if (delayOption === 'now') {
      autopilot.delay = moment().startOf('minute').valueOf();
    } else if (delayOption === 'tomorrow') {
      autopilot.delay = moment().startOf('hour').add(1, 'days').valueOf();
    } else if (delayOption === 'three_days') {
      autopilot.delay = moment().startOf('hour').add(3, 'days').valueOf();
    } else if (delayOption === 'week') {
      autopilot.delay = moment().startOf('hour').add(1, 'weeks').valueOf();
    }
    this.setState(() => ({ delayOption, autopilot, saveButton: false }));
  };

  handleExpirationValueChange = (event: any) => {
    const { value } = event.target;
    this.setState({ expirationValue: value, saveButton: false });
  };

  handleExpirationPeriodChange = (event: any) => {
    const value = event.target.value;
    this.setState(() => ({ expirationPeriodType: value, saveButton: false }));
  };

  handleSelectChanges = (event: any) => {
    const autopilot = this.state.autopilot;
    autopilot[event.target.name] = event.target.value;
    this.setState({ autopilot: autopilot, saveButton: false });
  };

  handleAutopilotExpiresCheck = () => {
    const message = this.state.message;
    const autopilot = this.state.autopilot;
    message.expiration = '';
    autopilot.expiration = '';
    this.setState({
      autopilotExpires: !this.state.autopilotExpires,
      message: message,
      autopilot: autopilot,
      saveButton: false
    });
  };

  handleTriggerChange = (value: any, index: any, dataType: any) => {
    const { isDirty } = this.props.validator!;
    const triggersNewState = this.state.triggers.map((item: any, i: any) => {
      if (i === index) {
        let items = ['type', '_id', 'value'];
        if (index === 0) {
          items = ['type', '_id', 'period', 'condition', 'value'];
        }
        for (let key of items) {
          isDirty(item[key]);
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });
    this.setState({ triggers: triggersNewState, saveButton: false });
  };

  handleActionsChange = (value: any, index: any, dataType: any) => {
    const { isDirty } = this.props.validator!;
    const actionsNewState = this.state.actions.map((item: any, i: any) => {
      let items = ['._id', 'type'];
      if (i === index) {
        for (let key of items) {
          isDirty(item[key]);
        }
        if (index === 2 || index === 0) {
          isDirty(`reward_${item._id}`);
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });
    this.setState({ actions: actionsNewState, saveButton: false });
  };

  addTriggerAction = () => {
    const triggers = this.state.triggers;
    let newTrigger = {
      _id: new Date().valueOf(),
      type: '',
      value: '',
      period: '',
      condition: ''
    };
    triggers.push(newTrigger);
    this.setState({ triggers: triggers, saveButton: false });
  };

  addAction = () => {
    const actions = this.state.actions;
    let newAction = {
      _id: new Date().valueOf(),
      type: '',
      value: '',
      reward: '',
      channels: [],
      customReward: ''
    };
    actions.push(newAction);
    this.setState({ actions: actions, saveButton: false });
  };

  deleteTriggerAction = async (event: any) => {
    const triggers = this.state.triggers;
    const id = event.currentTarget.getAttribute('id');
    triggers.forEach((item: any, index: any) => {
      if (item._id.toString() === id) {
        triggers.splice(index, 1);
      }
    });
    this.setState({ triggers, saveButton: false });
  };

  deleteActionsAction = async (event: any) => {
    const actions = this.state.actions;
    const id = event.currentTarget.getAttribute('id');
    actions.forEach((item: any, index: any) => {
      if (item._id.toString() === id) {
        actions.splice(index, 1);
      }
    });
    this.setState({ actions, saveButton: false });
  };
  listReturner = () => {
    return this.state.triggers;
  };

  render() {
    const { autopilot, message } = this.state;
    const { errors } = this.props.validator!;
    const rewards = this.parseRewards(this.props.auxilaries);
    const hasProximityTrigger = this.state.triggers.find((trigger: any) => trigger.type === 7);
    return (
      <div>
        <div className="row">
          <legend style={{ marginTop: 8, marginBottom: 10 }}>{msg('autopilot.basicInfo', 'Basic Information:')}</legend>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <TextFieldComponent
              label={msg('autopilot.title', 'Title')}
              id="title"
              value={autopilot.title}
              onChange={this.handleChange}
              inType="text"
              minLength={1}
              maxLength={60}
              formatError={errors.title}
              ref="title"
              required={true}
              validator={[
                { type: 'isLength', msg: msg('formValidation.inputLength', 'Input too short!'), params: { min: 2 } }
              ]}
            />
          </div>
          {!this.props.editable && (
            <div className="col-sm-3">
              <SimpleSelectComponent
                label={msg('autopilot.delay', 'Becomes active')}
                name="delay"
                options={tlist(delayOptions)}
                id="delay"
                value={this.state.delayOption}
                onChange={this.handleDelayOptionChange}
                required={true}
                formatError={message.delay}
                needsAllLabel={false}
                arrayOptions={false}
              />
            </div>
          )}
          {!this.props.editable && this.state.delayOption !== 'pick_date' ? (
            <div className="col-sm-3">
              <TextFieldComponent
                id="delayValue"
                value={getFormatDate(autopilot.delay)}
                onChange={this.handleChange}
                inType="text"
                required={false}
                disabled={true}
              />
            </div>
          ) : !this.props.editable && this.state.delayOption === 'pick_date' ? (
            <div className="col-sm-3">
              <DateTimePickerComponent
                date={autopilot.delay}
                disablePast={true}
                label={msg('general.chooseDate', 'Choose a date')}
                getSelectedDate={this.handleGetActivationDate}
              />
            </div>
          ) : this.props.editable ? (
            <div className="col-sm-6">
              <DateTimePickerComponent
                date={autopilot.delay}
                label={msg('autopilot.becomesActive', 'Rule becomes active at this date')}
                getSelectedDate={this.handleGetActivationDate}
              />
            </div>
          ) : null}
        </div>
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col-sm-3" style={{ marginBottom: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={this.state.autopilotExpires}
                  onChange={this.handleAutopilotExpiresCheck}
                  value="autopilotExpires"
                />
              }
              label={msg('autopilot.expirationCheck', 'Rule becomes inactive in:')}
            />
            <Tooltip
              disableFocusListener={true}
              title={msg(
                'autopilot.checkTooltip',
                'If left unchecked, the created autopilot rule will have unlimited availability.'
              )}
            >
              <Info style={{ position: 'absolute', top: '10px' }} />
            </Tooltip>
          </div>
          {this.state.autopilotExpires && (
            <div>
              <div className="col-sm-2" style={{ marginTop: -13 }}>
                <TextFieldComponent
                  label={msg('autopilot.triggerValue', 'Value')}
                  id="expirationValue"
                  value={!isNaN(this.state.expirationValue) ? this.state.expirationValue.toString() : ''}
                  onChange={this.handleExpirationValueChange}
                  inType="text"
                  minLength={1}
                  maxLength={4}
                  required={true}
                  formatError={errors.expirationValue}
                  ref="expirationValue"
                  validator={[{ type: 'isNumeric', msg: msg('formValidation.onlyNumbers', 'Use only numbers!') }]}
                />
              </div>
              <div className="col-sm-2" style={{ marginTop: -13 }}>
                <SimpleSelectComponent
                  label={msg('autopilot.triggerPeriod', 'Period type')}
                  name="expirationPeriodType"
                  options={[
                    msg('periodTypes.minutes', 'minutes'),
                    msg('periodTypes.hours', 'hours'),
                    msg('periodTypes.days', 'days'),
                    msg('periodTypes.weeks', 'weeks'),
                    msg('periodTypes.months', 'months')
                  ]}
                  id="expirationPeriodType"
                  value={this.state.expirationPeriodType}
                  onChange={this.handleExpirationPeriodChange}
                  required={true}
                  needsAllLabel={false}
                  arrayOptions={true}
                />
              </div>
              {this.props.editable && (
                <div className="col-sm-4" style={{ marginTop: 10 }}>
                  {msg('general.expirationMessage', 'since autopilot creation date.')}
                </div>
              )}
            </div>
          )}
        </div>
        {autopilot.title && (typeof autopilot.delay === 'number' || typeof autopilot.delay === 'object') && (
          <div>
            <div className="row">
              <legend style={{ marginTop: 10, marginBottom: 10 }}>
                {msg('autopilot.triggersLegend', 'Choose conditions that trigger rule:')}
              </legend>
            </div>
            {this.state.triggers.map((listItem, key) => {
              let content = (
                <AutopilotConditionForm
                  key={key}
                  listItem={listItem}
                  onCellChange={this.handleTriggerChange}
                  index={key}
                  onClick={this.deleteTriggerAction}
                  errors={errors}
                />
              );
              return content;
            })}
            {!hasProximityTrigger && (
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group form-button">
                    <ButtonComponent
                      label={msg('autopilot.addNewTrigger', 'Add new condition')}
                      action={this.addTriggerAction}
                      pending={this.props.pending}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {autopilot.title &&
          (typeof autopilot.delay === 'number' || typeof autopilot.delay === 'object') &&
          this.state.triggers.length >= 1 && (
            <div>
              <div className="row">
                <legend style={{ marginTop: 10, marginBottom: 10 }}>
                  {msg('autopilot.actionLegend', 'Choose actions that execute after rule is triggered:')}
                </legend>
              </div>
              {this.state.actions.map(
                (listItem, key) => (
                  // let content = (
                  <AutopilotActionsForm
                    key={key}
                    listItem={listItem}
                    onCellChange={this.handleActionsChange}
                    index={key}
                    onClick={this.deleteActionsAction}
                    rewards={rewards}
                    errors={errors}
                  />
                )
                // );
                // return content;
              )}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group form-button">
                    <ButtonComponent
                      label={msg('autopilot.addNewAction', 'Add new action')}
                      action={this.addAction}
                      pending={this.props.pending}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        {!this.props.output &&
          autopilot.title &&
          (typeof autopilot.delay === 'number' || typeof autopilot.delay === 'object') &&
          this.state.triggers.length >= 1 &&
          this.state.actions.length >= 1 && (
            <div>
              <div className="row" style={{ margin: 10 }}>
                <div className="col-md-3" />
                <div className="col-md-6" style={{ textAlign: 'center' }}>
                  <div className="form-group form-button">
                    <ButtonComponent
                      label={msg('general.save', 'Save')}
                      icon="save"
                      action={this.saveHandler}
                      pending={this.props.pending}
                      disabled={this.state.saveButton}
                    />
                  </div>
                </div>
                <div className="col-md-3" />
              </div>
              <div className="row">
                <div className="col-md-6 category form-category">
                  <small>*</small>
                  {msg('errors.fieldsRequired', 'Required fields')}
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default Validator(AutopilotFormComponent);
