export class PaginationDTO<T> {
  // tslint:disable-next-line:variable-name
  page_number: number;
  // tslint:disable-next-line:variable-name
  total_record_count: number;
  limit: number;
  skip: number;
  results: T[];
  perMonth?: boolean;
  stats?: {
    [key: string]: number;
  };
}
