export interface IAutopilotEventsApiRequest {
  sort: string;
  limit: number;
  skip: number;
  criteria: { search: any; filters: any };
}

export enum AutopilotEventType {
  VerificationStart = 'AUTOPILOT_VERIFICATION_START',
  VerificationEnd = 'AUTOPILOT_VERIFICATION_END',
  TriggerCreated = 'AUTOPILOT_TRIGGER_CREATED',
  RewardGiven = 'AUTOPILOT_ACTION_REWARD_GIVEN',
  PointsGiven = 'AUTOPILOT_ACTION_POINTS_GIVEN',
  MemberStatusChanged = 'AUTOPILOT_ACTION_MEMBER_STATUS_CHANGED',
  MessageSent = 'AUTOPILOT_ACTION_MESSAGE_SENT',
  CheckinCreated = 'CHECKIN_CREATED',
  PromotionStart = 'PROMOTION_VERIFICATION_START',
  PromotionEnd = 'PROMOTION_VERIFICATION_END'
}

export interface IAutopilotEventsApiResponse {
  skip: number;
  limit: number;
  page_number: number;
  total_record_count: number;

  results: IAutopilotEventsBase[];

  members: { [id: string]: IAutopilotEventsMember };
  autopilots: { [id: string]: IAutopilotEventsAutopilot };
  places: { [id: string]: IAutopilotEventsPlace };
}

// Used for VerificationStart and VerificationEnd event types
export interface IAutopilotEventsBase {
  _id: string;
  eventType: AutopilotEventType;
  date: string;
}

export interface IAutopilotEventsTriggerCreated extends IAutopilotEventsBase {
  autopilotId: string;
  memberId: string;
  placeId: string;
  action: string;
}

export interface IAutopilotEventsRewardGiven extends IAutopilotEventsBase {
  memberId: string;
  autopilotId: string;
  triggerId: string;
  placeId: string;
  rewardType: string;
  rewardPoints: string;
  rewardTitle: string;
}

export interface IAutopilotEventsPointsGiven extends IAutopilotEventsBase {
  memberId: string;
  autopilotId: string;
  triggerId: string;
  placeId: string;
  points: number;
}

export interface IAutopilotEventsMemberStatusChanged extends IAutopilotEventsBase {
  memberId: string;
  autopilotId: string;
  triggerId: string;
  placeId: string;
  memberStatus: number;
}

export interface IAutopilotEventsMessageSent extends IAutopilotEventsBase {
  channels: number[];
  placeId: string;
  memberId: string;
  autopilotId: string;
  triggerId: string;
  message: string;
}

export interface ICheckinCreated extends IAutopilotEventsBase {
  date: string;
  _id: string;
  placeId: string;
  memberId: string;
  checkinId: string;
  points: number;
}

export interface IAutopilotEventsMember {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  birthday: string;
}

export interface IAutopilotEventsAutopilot {
  title: string;
}

export interface IAutopilotEventsPlace {
  name: string;
}
