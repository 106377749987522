import classnames from 'classnames';
import * as React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { msg } from '../../../../../utils/';
import { ButtonComponent } from '../../../../shared';
import styles from '../styles/Auth.module.css';

interface IState {
  showCard: boolean;
  headerUp: boolean;
  visible: boolean;
  selectedPartner: any;
}

interface IProps {
  config: {
    partners: any;
    get_access_pending: boolean;
    selectPartnerLogin: (partnerId: string) => any;
    redirectAction: (route: string) => any;
    selectPlace: (placeId: string) => any;
  };
}

export default class ChoosePartnerComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showCard: false,
      headerUp: false,
      visible: false,
      selectedPartner: null
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showCard: true });
      // tslint:disable-next-line:align
    }, 800);
    setTimeout(() => {
      this.setState({ visible: true });
      // tslint:disable-next-line:align
    }, 1000);
  }

  handleRadioChange = (event: any) => {
    const value = event.target.value;
    const foundPartner = this.props.config.partners.find((partner: any) => partner.name === value);
    this.props.config.selectPartnerLogin(foundPartner._id);
    this.setState({ selectedPartner: value });
  };

  redirectAction = () => {
    const foundPartner = this.props.config.partners.find((partner: any) => partner.name === this.state.selectedPartner);
    const placeId = foundPartner.places[0]._id;
    this.props.config.selectPlace(placeId);
    this.props.config.redirectAction(`/partner/dashboard/${placeId}`);
  };

  render() {
    let cardClasses = classnames(`card card-login ${styles.card}`, { 'card-hidden': !this.state.showCard });
    let headerClasses = classnames(`card-header text-center ${styles.header}`, {
      [styles.up]: this.state.headerUp
    });
    const partners = this.props.config.partners && this.props.config.partners.length ? this.props.config.partners : [];
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
            <form>
              <div className={cardClasses}>
                <div className={headerClasses} data-background-color="green">
                  <h4 className="card-title">{msg('login.choosePartner', 'GO TO PARTNER')}</h4>
                </div>
                <div
                  className={`card-content ${styles.content}`}
                  style={{ marginLeft: 15, maxHeight: '45vh', overflowY: 'auto' }}
                >
                  {partners && (
                    <RadioGroup
                      name="selPartner"
                      onChange={this.handleRadioChange}
                      value={this.state.selectedPartner || ''}
                      row={false}
                    >
                      {partners.map((partner: any, index: number) => {
                        return (
                          <FormControlLabel
                            key={index}
                            value={partner.name}
                            control={<Radio color="primary" />}
                            label={partner.name}
                            style={{ width: 'auto', color: 'black' }}
                          />
                        );
                      })}
                    </RadioGroup>
                  )}
                </div>
                {this.state.selectedPartner && (
                  <div className="footer text-center">
                    <ButtonComponent
                      pending={this.props.config.get_access_pending}
                      action={this.redirectAction}
                      label={msg('login.goToPartner', 'GO TO PARTNER')}
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
