import { UsersStore } from './';
import { PaginationDTO } from '../types';

/*
  usersReducer = reducer for all actionTypes for Users
*/
function usersReducer(state: UsersStore.IState = UsersStore.initialState, action: IAction<any>): UsersStore.IState {
  switch (action.type) {
    case UsersStore.ActionTypes.GET_USERS: {
      return {
        ...state,
        users_list_pending: true,
        users_list_error: null
      };
    }
    case UsersStore.ActionTypes.GET_USERS_SUCCESS: {
      return {
        ...state,
        users_list_pending: false,
        users_list_error: null,
        users_list: action.payload
      };
    }
    case UsersStore.ActionTypes.GET_USERS_FAILED: {
      return {
        ...state,
        users_list_pending: false,
        users_list: new PaginationDTO(),
        users_list_error: action.payload
      };
    }
    case UsersStore.ActionTypes.GET_USER: {
      return {
        ...state,
        user_pending: true,
        user_error: null
      };
    }
    case UsersStore.ActionTypes.GET_USER_SUCCESS: {
      return {
        ...state,
        user_pending: false,
        user_error: null,
        user_b: action.payload
      };
    }
    case UsersStore.ActionTypes.GET_USER_FAILED: {
      return {
        ...state,
        user_pending: false,
        user_b: null,
        user_error: action.payload
      };
    }
    case UsersStore.ActionTypes.SAVE_USER: {
      return {
        ...state,
        user_save_pending: true,
        user_save_error: null,
        user_saved: null
      };
    }
    case UsersStore.ActionTypes.SAVE_USER_SUCCESS: {
      const nextState = {
        ...state,
        user_save_pending: false,
        user_save_error: null,
        user_saved: action.payload
      };
      return nextState;
    }
    case UsersStore.ActionTypes.SAVE_USER_FAILED: {
      return {
        ...state,
        user_save_pending: false,
        user_save_error: action.payload,
        user_saved: null
      };
    }
    case UsersStore.ActionTypes.DELETE_USER: {
      return {
        ...state,
        user_delete_pending: true,
        user_delete_error: null
      };
    }
    case UsersStore.ActionTypes.DELETE_USER_SUCCESS: {
      return {
        ...state,
        user_delete_pending: false,
        user_delete_error: null
      };
    }
    case UsersStore.ActionTypes.DELETE_USER_FAILED: {
      return {
        ...state,
        user_delete_pending: false,
        user_delete_error: action.payload
      };
    }
    case UsersStore.ActionTypes.CHANGE_USER_PASSWORD: {
      return {
        ...state,
        user_change_password_pending: true,
        user_change_password_error: null,
        user_change_password: null
      };
    }
    case UsersStore.ActionTypes.CHANGE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        user_change_password_pending: false,
        user_change_password_error: null,
        user_change_password: action.payload
      };
    }
    case UsersStore.ActionTypes.CHANGE_USER_PASSWORD_FAILED: {
      return {
        ...state,
        user_change_password_pending: false,
        user_change_password_error: action.payload,
        user_change_password: null
      };
    }
    case UsersStore.ActionTypes.GET_ADMINS: {
      return {
        ...state,
        admins_list_pending: true,
        admins_list_error: null
      };
    }
    case UsersStore.ActionTypes.GET_ADMINS_SUCCESS: {
      return {
        ...state,
        admins_list_pending: false,
        admins_list_error: null,
        admins_list: action.payload
      };
    }
    case UsersStore.ActionTypes.GET_ADMINS_FAILED: {
      return {
        ...state,
        admins_list_pending: false,
        admins_list: [],
        admins_list_error: action.payload
      };
    }
    case UsersStore.ActionTypes.SAVE_ADMIN: {
      return {
        ...state,
        admin_save_pending: true,
        admin_save_error: null,
        admin_saved: null
      };
    }
    case UsersStore.ActionTypes.SAVE_ADMIN_SUCCESS: {
      return {
        ...state,
        admin_save_pending: false,
        admin_save_error: null,
        admin_saved: action.payload,
        admins_list: action.payload
      };
    }
    case UsersStore.ActionTypes.SAVE_ADMIN_FAILED: {
      return {
        ...state,
        admin_save_pending: false,
        admin_save_error: action.payload,
        admin_saved: null
      };
    }
    case UsersStore.ActionTypes.REMOVE_ADMIN: {
      return {
        ...state,
        admin_removed_pending: true,
        admin_removed_error: null,
        admin_removed: false
      };
    }
    case UsersStore.ActionTypes.REMOVE_ADMIN_SUCCESS: {
      return {
        ...state,
        admin_removed_pending: false,
        admin_removed_error: null,
        admin_removed: true,
        admins_list: action.payload
      };
    }
    case UsersStore.ActionTypes.REMOVE_ADMIN_FAILED: {
      return {
        ...state,
        admin_removed_pending: false,
        admin_removed_error: action.payload,
        admin_removed: false
      };
    }
    case UsersStore.ActionTypes.GET_PLACE_ADMINS: {
      return {
        ...state,
        place_admins_list_pending: true,
        place_admins_list_error: null
      };
    }
    case UsersStore.ActionTypes.GET_PLACE_ADMINS_SUCCESS: {
      return {
        ...state,
        place_admins_list_pending: false,
        place_admins_list_error: null,
        place_admins_list: action.payload
      };
    }
    case UsersStore.ActionTypes.GET_PLACE_ADMINS_FAILED: {
      return {
        ...state,
        place_admins_list_pending: false,
        place_admins_list: [],
        place_admins_list_error: action.payload
      };
    }
    case UsersStore.ActionTypes.REMOVE_PLACE_ADMIN: {
      return {
        ...state,
        place_admin_removed_pending: true,
        place_admin_removed_error: null,
        place_admin_removed: false
      };
    }
    case UsersStore.ActionTypes.REMOVE_PLACE_ADMIN_SUCCESS: {
      return {
        ...state,
        place_admin_removed_pending: false,
        place_admin_removed_error: null,
        place_admin_removed: true,
        place_admins_list: action.payload
      };
    }
    case UsersStore.ActionTypes.REMOVE_PLACE_ADMIN_FAILED: {
      return {
        ...state,
        place_admin_removed_pending: false,
        place_admin_removed_error: action.payload,
        place_admin_removed: false
      };
    }
    case UsersStore.ActionTypes.GET_PROFILES: {
      return {
        ...state,
        profile_list_pending: true,
        profile_list_error: null
      };
    }
    case UsersStore.ActionTypes.GET_PROFILES_SUCCESS: {
      return {
        ...state,
        profile_list: action.payload,
        profile_list_pending: false,
        profile_list_error: null
      };
    }
    case UsersStore.ActionTypes.GET_PROFILES_FAILED: {
      return {
        ...state,
        profile_list_pending: false,
        profile_list_error: action.payload
      };
    }
    case UsersStore.ActionTypes.SAVE_PROFILE: {
      return {
        ...state,
        profile_save_pending: true,
        profile_save_error: null
      };
    }
    case UsersStore.ActionTypes.SAVE_PROFILE_SUCCESS: {
      return {
        ...state,
        profile_save_pending: false,
        profile_save_error: null
      };
    }
    case UsersStore.ActionTypes.SAVE_PROFILE_FAILED: {
      return {
        ...state,
        profile_save_pending: false,
        profile_save_error: action.payload
      };
    }
    case UsersStore.ActionTypes.DELETE_PROFILE: {
      return {
        ...state,
        profile_delete_pending: true,
        profile_delete_error: null
      };
    }
    case UsersStore.ActionTypes.DELETE_PROFILE_SUCCESS: {
      return {
        ...state,
        profile_delete_pending: false,
        profile_delete_error: null
      };
    }
    case UsersStore.ActionTypes.DELETE_PROFILE_FAILED: {
      return {
        ...state,
        profile_delete_pending: false,
        profile_delete_error: action.payload
      };
    }
    default:
      return state;
  }
}

export default usersReducer;
