import { CheckinsStore } from './';
import { PaginationDTO } from '../types';

/*
  autopilotReducer = reducer for all actionTypes for Autopilot
*/
function checkinsReducer(
  state: CheckinsStore.IState = CheckinsStore.initialState,
  action: IAction<any>
): CheckinsStore.IState {
  switch (action.type) {
    case CheckinsStore.ActionTypes.GET_CHECKINS: {
      return {
        ...state,
        checkins_list_pending: true,
        checkins_list_error: null
      };
    }
    case CheckinsStore.ActionTypes.GET_CHECKINS_SUCCESS: {
      return {
        ...state,
        checkins_list_pending: false,
        checkins_list_error: null,
        checkins_list: action.payload
      };
    }
    case CheckinsStore.ActionTypes.GET_CHECKINS_FAILED: {
      return {
        ...state,
        checkins_list_pending: false,
        checkins_list: new PaginationDTO(),
        checkins_list_error: action.payload
      };
    }
    case CheckinsStore.ActionTypes.GET_CHECKIN: {
      return {
        ...state,
        checkin_pending: true,
        checkin_error: null
      };
    }
    case CheckinsStore.ActionTypes.GET_CHECKIN_SUCCESS: {
      return {
        ...state,
        checkin_pending: false,
        checkin_error: null,
        checkin: action.payload
      };
    }
    case CheckinsStore.ActionTypes.GET_CHECKIN_FAILED: {
      return {
        ...state,
        checkin_pending: false,
        checkin: null,
        checkin_error: action.payload
      };
    }
    case CheckinsStore.ActionTypes.DELETE_CHECKIN: {
      return {
        ...state,
        checkin_delete_pending: true,
        checkin_delete_error: null,
        checkin_deleted: false
      };
    }
    case CheckinsStore.ActionTypes.DELETE_CHECKIN_SUCCESS: {
      return {
        ...state,
        checkin_delete_pending: false,
        checkin_delete_error: null,
        checkin_deleted: true,
        checkins_list: action.payload
      };
    }
    case CheckinsStore.ActionTypes.DELETE_CHECKIN_FAILED: {
      return {
        ...state,
        checkin_delete_pending: false,
        checkin_delete_error: action.payload,
        checkin_deleted: false
      };
    }
    case CheckinsStore.ActionTypes.GET_CHANGES: {
      return {
        ...state,
        changes_list_pending: true,
        changes_list_error: null
      };
    }
    case CheckinsStore.ActionTypes.GET_CHANGES_SUCCESS: {
      return {
        ...state,
        changes_list_pending: false,
        changes_list_error: null,
        changes_list: action.payload
      };
    }
    case CheckinsStore.ActionTypes.GET_CHANGES_FAILED: {
      return {
        ...state,
        changes_list_pending: false,
        changes_list: new PaginationDTO(),
        changes_list_error: action.payload
      };
    }
    default:
      return state;
  }
}

export default checkinsReducer;
