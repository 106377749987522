import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  AppStore,
  AutopilotStore,
  IndustriesStore,
  RewardsStore,
  PlacesStore,
  PartnersStore,
  reduxContainer
} from '../../../appRedux';
import { AutopilotEditComponent, PlaceAutopilotListComponent } from './components';
import { AuthUtil } from '../../../utils';
import { partnerRoutes } from '../../layouts';

interface IProps
  extends AutopilotStore.IState,
    IndustriesStore.IState,
    PartnersStore.IState,
    RewardsStore.IState,
    PlacesStore.IState {}
interface IState {}

class AutopilotContainer extends React.Component<
  IProps & AutopilotStore.IActions & IndustriesStore.IActions & RewardsStore.IActions & PlacesStore.IActions,
  IState
> {
  render() {
    if (
      !AuthUtil.isSuperAdmin() &&
      this.props.place &&
      (!AuthUtil.isSupport() || !this.props.place.featureFlags?.simpleAutopilots)
    ) {
      return <Redirect to="/partner/dashboard" />;
    }

    const autopilotEditProps = {
      pending: this.props.autopilot_save_pending,
      loaded: this.props.autopilot,
      load_pending: this.props.autopilot_pending,
      load_error: this.props.autopilot_error,
      addForPlace: this.props.addAutopilotRuleForPlaceAction,
      get: this.props.getAutopilotRuleAction,
      editForPlace: this.props.updateAutopilotRuleForPlaceAction,
      industries: this.props.industries_list,
      rewards: this.props.rewards_list,
      getRewardsPlace: this.props.getRewardsListForPlaceAction
    };
    const placeAutopilotProps = {
      autopilot_list: this.props.autopilot_list,
      autopilot_list_pending: this.props.autopilot_list_pending,
      autopilot_list_error: this.props.autopilot_list_error,
      autopilot_save_pending: this.props.autopilot_save_pending,
      autopilot_delete_pending: this.props.autopilot_delete_pending,
      getAutopilotListForPlaceAction: this.props.getAutopilotListForPlaceAction,
      deleteAutopilotRuleForPlaceAction: this.props.deleteAutopilotRuleForPlaceAction,
      updateAutopilotStatusForPlaceAction: this.props.updateAutopilotStatusForPlaceAction,
      redirectToAddAutopilotRuleActionForPlace: this.props.redirectToAddAutopilotRuleActionForPlace,
      redirectToEditAutopilotRuleForPlaceAction: this.props.redirectToEditAutopilotRuleForPlaceAction,
      inheritAutopilotAction: this.props.inheritAutopilotRules
    };

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Switch>
              <Route
                path={partnerRoutes.AUTOPILOT.subroutes.ADD.path}
                render={({ match }) => (
                  <AutopilotEditComponent
                    match={match}
                    editMode={false}
                    config={autopilotEditProps}
                    partnerId={this.props.selected_partner}
                  />
                )}
              />
              <Route
                path={partnerRoutes.AUTOPILOT.subroutes.EDIT.path}
                render={({ match }) => (
                  <AutopilotEditComponent
                    match={match}
                    editMode={true}
                    config={autopilotEditProps}
                    partnerId={this.props.selected_partner}
                  />
                )}
              />
              <Route
                exact={true}
                path={partnerRoutes.AUTOPILOT.default}
                render={({ match }) => (
                  <PlaceAutopilotListComponent
                    match={match}
                    config={placeAutopilotProps}
                    partnerId={this.props.selected_partner}
                  />
                )}
              />
              <Redirect from="*" to={`/partner/autopilot/${this.props.selected_place}`} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    autopilot: state.autopilot.autopilot,
    autopilot_pending: state.autopilot.autopilot_pending,
    autopilot_error: state.autopilot.autopilot_error,
    autopilot_save_pending: state.autopilot.autopilot_save_pending,
    autopilot_delete_pending: state.autopilot.autopilot_delete_pending,
    industries_list: state.industries.industries_list,
    autopilot_list: state.autopilot.autopilot_list,
    autopilot_list_pending: state.autopilot.autopilot_list_pending,
    autopilot_list_error: state.autopilot.autopilot_list_error,
    rewards_list: state.rewards.rewards_list,
    place: state.places.place,
    selected_place: state.places.selected_place,
    selected_partner: state.partners.selected_partner
  };
}

const dispatchToProps = {
  getAutopilotRuleAction: AutopilotStore.actions.getAutopilotRuleAction,
  addAutopilotRuleForPlaceAction: AutopilotStore.actions.addAutopilotRuleForPlaceAction,
  updateAutopilotRuleForPlaceAction: AutopilotStore.actions.updateAutopilotRuleForPlaceAction,
  deleteAutopilotRuleForPlaceAction: AutopilotStore.actions.deleteAutopilotRuleForPlaceAction,
  updateAutopilotStatusForPlaceAction: AutopilotStore.actions.updateAutopilotStatusForPlaceAction,
  redirectToAddAutopilotRuleActionForPlace: AutopilotStore.actions.redirectToAddAutopilotRuleActionForPlace,
  redirectToEditAutopilotRuleForPlaceAction: AutopilotStore.actions.redirectToEditAutopilotRuleForPlaceAction,
  getRewardsListForPlaceAction: RewardsStore.actions.getRewardsListForPlaceAction,
  inheritAutopilotRules: PlacesStore.actions.inheritAutopilotRules,
  getAutopilotListForPlaceAction: AutopilotStore.actions.getAutopilotListForPlaceAction
};

export default reduxContainer(AutopilotContainer, mapStateToProps, dispatchToProps);
