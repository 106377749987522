import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  AppStore,
  PartnersStore,
  PlacesStore,
  IndustriesStore,
  UsersStore,
  reduxContainer,
  PackagesStore,
  OnboardStore
} from '../../../appRedux';
import { PartnersListComponent, PartnerEditComponent } from './components';
import { IPackage } from '../../../appRedux/types';
import { adminRoutes } from '../../layouts';

interface IProps
  extends PartnersStore.IState,
    IndustriesStore.IState,
    PlacesStore.IState,
    UsersStore.IState,
    OnboardStore.IState {
  availablePackages: IPackage[];
  fetchAvailablePackages: () => any;
  changePlacePackage: () => any;
}
interface IState {}

class PartnersContainer extends React.Component<
  IProps &
    PartnersStore.IActions &
    PlacesStore.IActions &
    IndustriesStore.IActions &
    UsersStore.IActions &
    OnboardStore.IActions,
  IState
> {
  componentDidMount() {
    this.props.getShortIndustriesListAction();
  }

  render() {
    const partnerEditProps = {
      partner: {
        saved: this.props.partner_saved,
        pending: this.props.partner_save_pending,
        error: this.props.partner_save_error,
        loaded: this.props.partner,
        load_pending: this.props.partner_pending,
        load_error: this.props.partner_error,
        add: this.props.addPartnerAction,
        get: this.props.getPartnerAction,
        edit: this.props.editPartnerAction,
        editPlaceStatusAction: this.props.editPlaceStatusAction
      },
      places: {
        get: this.props.getPlacesForPartnerAction,
        list: this.props.partner_places_list,
        pending: this.props.partner_places_list_pending,
        error: this.props.partner_places_list_error,
        deleteAsSAdmin: this.props.deletePlaceAction,
        redirectToEditSAdmin: this.props.redirectToEditPlaceAction,
        redirectToAddSAdmin: this.props.redirectToAddPlaceAction,
        redirectToPlaceAdminsAction: this.props.redirectToPlaceAdminsAction,
        edit: this.props.editPlaceForPartnerAction,
        place_save_pending: this.props.place_save_pending,
        availablePackages: this.props.availablePackages,
        fetchAvailablePackages: this.props.fetchAvailablePackages,
        changePlacePackage: this.props.changePlacePackage
      },
      extras: {
        industries: this.props.industries_short_list,
        alternative_address_flag: this.props.alternative_address_flag,
        getFlagsForOnboarding: this.props.getFlagsForOnboarding
      },
      admins: {
        list: this.props.admins_list,
        error: this.props.admins_list_error,
        list_pending: this.props.admins_list_pending,
        admin_save_pending: this.props.admin_save_pending,
        get: this.props.getAdminsForPartnerAction,
        redirectToEdit: this.props.redirectToEditUserAction,
        redirectToAdd: this.props.redirectToAddPartnerAdminAction,
        removeAdmin: this.props.removePartnerAdminAction,
        add: this.props.addPartnerAdminByIdAction
      }
    };

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <Switch>
              <Route exact={true} path={adminRoutes.PARTNERS.path} render={() => <PartnersListComponent />} />
              <Route
                path={adminRoutes.PARTNERS.subroutes.ADD.path}
                render={() => <PartnerEditComponent editMode={false} config={partnerEditProps} />}
              />
              <Route
                path={adminRoutes.PARTNERS.subroutes.EDIT.path}
                render={({ match }) => <PartnerEditComponent match={match} editMode={true} config={partnerEditProps} />}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: AppStore.states) {
  return {
    partner: state.partners.partner,
    partner_pending: state.partners.partner_pending,
    partner_error: state.partners.partner_error,
    partner_saved: state.partners.partner_saved,
    partner_save_pending: state.partners.partner_save_pending,
    partner_save_error: state.partners.partner_save_error,
    partner_places_list: state.places.partner_places_list,
    partner_places_list_error: state.places.partner_places_list_error,
    partner_places_list_pending: state.places.partner_places_list_pending,
    industries_short_list: state.industries.industries_short_list,
    admins_list: state.users.admins_list,
    admins_list_pending: state.users.admins_list_pending,
    admins_list_error: state.users.admins_list_error,
    admin_save_pending: state.users.admin_save_pending,
    place_save_pending: state.places.place_save_pending,
    availablePackages: state.packages?.packages_list.results || [],
    alternative_address_flag: state.onboard.alternative_address_flag
  };
}

const dispatchToProps = {
  addPartnerAction: PartnersStore.actions.addPartnerAction,
  getPartnerAction: PartnersStore.actions.getPartnerAction,
  editPartnerAction: PartnersStore.actions.editPartnerAction,
  getPlacesForPartnerAction: PlacesStore.actions.getPlacesForPartnerAction,
  redirectToEditPlaceAction: PlacesStore.actions.redirectToEditPlaceAction,
  redirectToAddPlaceAction: PlacesStore.actions.redirectToAddPlaceAction,
  redirectToPlaceAdminsAction: PlacesStore.actions.redirectToPlaceAdminsAction,
  deletePlaceAction: PlacesStore.actions.deletePlaceAction,
  getShortIndustriesListAction: IndustriesStore.actions.getShortIndustriesListAction,
  getAdminsForPartnerAction: UsersStore.actions.getAdminsForPartnerAction,
  redirectToAddPartnerAdminAction: UsersStore.actions.redirectToAddPartnerAdminAction,
  redirectToEditUserAction: UsersStore.actions.redirectToEditUserAction,
  addPartnerAdminByIdAction: UsersStore.actions.addPartnerAdminByIdAction,
  removePartnerAdminAction: UsersStore.actions.removePartnerAdminAction,
  editPlaceForPartnerAction: PlacesStore.actions.editPlaceForPartnerAction,
  editPlaceStatusAction: PartnersStore.actions.editPlaceStatusAction,
  fetchAvailablePackages: PackagesStore.actions.getPackagesListAction,
  changePlacePackage: PlacesStore.actions.editPlaceAction,
  getFlagsForOnboarding: OnboardStore.actions.getFlagsForOnboarding
};

export default reduxContainer(PartnersContainer, mapStateToProps, dispatchToProps);
