import axios from 'axios';

export function getFormattedAddress(lat: any, long: any) {
  return axios
    .get(
      // tslint:disable-next-line:max-line-length
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyD5hma0D7oCwJU2t0kWKyukAS4hOJxr1k4`
    )
    .then((response: any) => {
      let address: any;
      let parts = ['locality', 'route', 'street_number'];
      [0, 1, 2].some(i => {
        const result = getResults(response, i);
        address = getParts(parts, result);
        return address.route;
      });
      return address;
    })
    .catch((error: any) => {
      return error;
    });
}

export const getAddressFromGeosuggest = (locationInfo: any) => {
  let address: any;
  let parts = ['locality', 'route', 'street_number'];
  address = getParts(parts, locationInfo);
  return address;
};

function getParts(findParts: any, address: any) {
  let result = {};
  findParts.forEach((p: any) => {
    address.forEach((part: any) => {
      if (part && part.types && part.types.includes(p)) {
        result[p] = part.short_name;
      }
    });
  });
  return result;
}

function getResults(response: any, index: number) {
  return (response.data && response.data.results[index] && response.data.results[index].address_components) || [];
}
